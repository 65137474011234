import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import {
  advocate,
  district,
  state,
  documentMaster,
  profileComplete,
  community,
  gender,
  practicingin,
  taluk,
  title,
  formType,
  cms,
} from "../../constants/config";
import { CallService } from "../../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  FORMTYPES,
  TITLE,
} from "../../constants/defaultValues";
import DropDown from "../../components/custom/DropDown";
import FileDisplay from "../../components/custom/FileDisplay";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import { Formik, Form, Field } from "formik";
import { withNamespaces } from "react-i18next";
import moment from "moment";

// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import "react-tagsinput/react-tagsinput.css";

import "rc-switch/assets/index.css";
// import "react-datepicker/dist/react-datepicker.css";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  ProfilePersonalValidation,
  ProfileProfessionalValidation,
  ProfileQualificationValidation,
  ProfileAddressValidation,
  ProfileFormTypeValidation,
} from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/logo_tnpcb.png";
// actions
import { editProfile } from "../../store/actions";
import {
  getFileSizeText,
  checkIfImage,
  getAllowedExtensionText,
} from "../../helpers/Utils";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.toggleImageView = this.toggleImageView.bind(this);
    this.state = {
      activeFirstTab: 1,
      email: "",
      name: "",
      idx: 1,
      buttonAction: false,
      tabs: [],
      ApplicationId: sessionStorage.getItem("user_id"),
      Status: sessionStorage.getItem("Status"),
      IsProfileCompleted: sessionStorage.getItem("IsProfileCompleted"),
      Language: sessionStorage.getItem("lng"),
      loading: false,
      ProvisionAddress: false,
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
      },
      Profile: {
        IsIntegratedDegree: "",
        FormType: "",
        Title: "",
        Name: "",
        IsMarried: "",
        Gender: "",
        MobileNumber: sessionStorage.getItem("MobileNumber"),
        Dob: null,
        EmailID: "",
        FatherOrHusbandName: "",
        Community: "",
        Photo: "",
        //  AttestedCopy: "",
        BarCouncilNumber: "",
        BarCouncilCertificate: "",
        BarCouncilExperienceCertificate: "",
        PracticingSince: null,
        EnrolmentDate: null,
        LandlineNumber: "",
        Fax: "",
        PANNumber: "",
        IncomeTaxAssessee: "",
        //  Remarks: "",
        Degrees: [
          // {
          //   Institution: "",
          //   Name: "",
          //   Url: "",
          //   YearOfPassing: "",
          // },
        ],
        ResidencyAddress: {
          Address: {
            Line1: "",
            Line2: "",
            Line3: "",
            Pincode: "",
            District: null,
            Taluk: null,
            State: null,
            Country: null,
          },
          // ProofType: "",
          // ProofUrl: "",
          // IDProofNumber: "",
        },
        ProvisionalAddress: {
          Address: {
            Line1: "",
            Line2: "",
            Line3: "",
            Pincode: "",
            District: null,
            Taluk: null,
            State: null,
            Country: null,
          },
          //   ProofType: "",
          //  ProofUrl: "",
          //  IDProofNumber: "",
        },
      },
      Communities: [],
      PracticingInList: [],
      DistrictList: [],
      TalukList: [],
      titles: [],
      formTypes: [],
      AddressProofTypeList: [],
      State: [],
      ProofTypeList: [
        {
          value: "AADHAR",
          label: "AADHAR",
        },
        {
          value: "Passport",
          label: "Passport",
        },
        {
          value: "Voter ID",
          label: "Voter ID",
        },
        {
          value: "Ration Card",
          label: "Ration Card",
        },
        {
          value: "Driving License",
          label: "Driving License",
        },
      ],
      ProfileValues: {},
    };
  }
  componentDidMount() {
    this.GetCMS("eligibility");
    this.GetAllCommunity();
    this.GetAllGender();
    this.GetAllState();
    this.GetAllDistrict();
    this.GetAllTaluk("");
    this.getAllTitles();
    this.getAllFormTypes();
    this.GetAllPracticingIn();
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
      }
    }
    this.setTab(1);
    if (
      this.state.ApplicationId === "" ||
      this.state.ApplicationId === null ||
      this.state.ApplicationId === undefined
    ) {
    } else {
      this.GetOneApplication(sessionStorage.getItem("ApplicationId"));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
      }
    }
  }
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Disclaimer"),
        },
        {
          id: 2,
          Name: this.props.t("Personal"),
        },
        {
          id: 3,
          Name: this.props.t("Professional"),
        },
        {
          id: 4,
          Name: this.props.t("Qualification"),
        },
        {
          id: 5,
          Name: this.props.t("Address"),
        },
      ],
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  GetOneApplication() {
    CallService(
      advocate.GetOne + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneApplicationResponse
    );
  }

  GetOneApplicationResponse = (data) => {
    var Dob = data.result ? (data.result ? data.result.Dob : "") : "";
    var date = moment(new Date(Dob)).format("DD/MM/YYYY");

    var since = data.result
      ? data.result
        ? data.result.PracticingSince
        : ""
      : "";

    var PracticingSince = moment(new Date(since)).format("DD/MM/YYYY");

    var DegreeValue = [];
    for (var i = 0; i < data.result.Degrees.length; i++) {
      var deg = {};
      deg.Institution = data.result.Degrees[i]
        ? data.result.Degrees[i].Institution
        : "";
      deg.Name = data.result.Degrees[i] ? data.result.Degrees[i].Name : "";
      deg.Url = data.result.Degrees[i] ? data.result.Degrees[i].Url : "";
      deg.YearOfPassing = data.result.Degrees[i]
        ? data.result.Degrees[i].YearOfPassing
        : "";
      DegreeValue.push(deg);
    }

    var PrefillProfile = {
      Name: data.result.Name,
      Gender: data.result.Gender,
      IsMarried: data.result.IsMarried,
      MobileNumber: sessionStorage.getItem("MobileNumber"),
      Dob: date,
      EmailID: data.result.EmailID,
      FatherOrHusbandName: data.result ? data.result.FatherOrHusbandName : "",
      Community: data.result.Community,
      Photo: data.result.Photo,
      //  AttestedCopy: data.result.AttestedCopy ? data.result.AttestedCopy : "",
      BarCouncilNumber: data.result.BarCouncilNumber,
      BarCouncilCertificate: data.result.BarCouncilCertificate,
      BarCouncilExperienceCertificate:
        data.result.BarCouncilExperienceCertificate,
      PracticingSince: PracticingSince,
      PracticingIn: data.result
        ? data.result
          ? data.result.PracticingIn
            ? data.result.PracticingIn.map((In) => {
                return In._id;
              })
            : null
          : null
        : null,
      LandlineNumber: data.result.LandlineNumber,
      Fax: data.result.Fax,
      PANNumber: data.result.PANNumber ? data.result.PANNumber : "",
      IncomeTaxAssessee: data.result.IncomeTaxAssessee,
      //  Remarks: data.result.Remarks,
      Degrees: DegreeValue,
      Title: data.result.Title,
      FormType: data.result.FormType,
      IsIntegratedDegree: data.result.IsIntegratedDegree,
      ResidencyAddress: {
        Address: {
          Line1: data.result.ResidencyAddress.Address.Line1,
          Line2: data.result.ResidencyAddress.Address.Line2,
          Line3: data.result.ResidencyAddress.Address.Line3,
          Pincode: data.result.ResidencyAddress.Address.Pincode,
          District: data.result.ResidencyAddress.Address.District,
          Taluk: data.result.ResidencyAddress.Address.Taluk,
          State: data.result.ResidencyAddress.Address.State,
        },
        //  ProofType: data.result.ResidencyAddress.ProofType,
        // ProofUrl: data.result.ResidencyAddress.ProofUrl,
        //  IDProofNumber: data.result.ResidencyAddress.IDProofNumber,
      },
      ProvisionalAddress: {
        Address: {
          Line1: data.result.ProvisionalAddress.Address.Line1,
          Line2: data.result.ProvisionalAddress.Address.Line2,
          Line3: data.result.ProvisionalAddress.Address.Line3,
          Pincode: data.result.ProvisionalAddress.Address.Pincode,
          District: data.result.ProvisionalAddress.Address.District,
          Taluk: data.result.ProvisionalAddress.Address.Taluk,
          State: data.result.ProvisionalAddress.Address.State,
        },
        //  ProofType: data.result.ProvisionalAddress.ProofType,
        //  ProofUrl: data.result.ProvisionalAddress.ProofUrl,
        //  IDProofNumber: data.result.ProvisionalAddress.IDProofNumber,
      },
      Aadhar: data.result.Aadhar,
      EnrolmentDate: data.result.EnrolmentDate
        ? moment(new Date(data.result.EnrolmentDate)).format("DD/MM/YYYY")
        : null,
      GovtServiceStartDate: data.result.GovtServiceStartDate
        ? moment(new Date(data.result.GovtServiceStartDate)).format(
            "DD/MM/YYYY"
          )
        : null,
      GovtServiceEndDate: data.result.GovtServiceEndDate
        ? moment(new Date(data.result.GovtServiceEndDate)).format("DD/MM/YYYY")
        : null,
      GovtServicePost: data.result.GovtServicePost,
      //  GovtServiceStartUrl: data.result.GovtServiceStartUrl,
      //  GovtServiceEndUrl: data.result.GovtServiceEndUrl,
    };
    this.setState({
      Profile: PrefillProfile,
      // startDate: date,
    });
  };
  handleFormTypeSubmit = (values) => {
    console.log("handlePersonalSubmit", values);

    if (values.FormType === FORMTYPES.Form2) {
      if (
        values.GovtServicePost &&
        values.GovtServiceStartDate &&
        values.GovtServiceEndDate
      ) {
        this.setState({
          FormTypesValues: values,
          activeFirstTab: 2,
        });
      } else {
        toastr.error("", "Please fill mandatory field");
      }
    } else {
      this.setState({
        FormTypesValues: values,
        activeFirstTab: 2,
      });
    }
  };

  handlePersonalSubmit = (values) => {
    var PersonalValues = this.state.FormTypesValues;
    console.log("PersonalValues", PersonalValues);
    if (PersonalValues) {
      PersonalValues.Title = values.Title;
      PersonalValues.Name = values.Name;
      PersonalValues.IsMarried = values.IsMarried;
      PersonalValues.Gender = values.Gender;
      PersonalValues.FatherOrHusbandName = values.FatherOrHusbandName;
      PersonalValues.Dob = values.Dob;
      PersonalValues.Community = values.Community;
      PersonalValues.Photo = values.Photo;
      PersonalValues.EmailID = values.EmailID;
      PersonalValues.Aadhar = values.Aadhar;
      PersonalValues.AadharCopy = values.AadharCopy;
      // PersonalValues.AttestedCopy = values.AttestedCopy;

      this.setState({
        PersonalValues: PersonalValues,
        activeFirstTab: 3,
      });
    } else {
      toastr.error("", "Please fill form type tab field");
    }
  };
  handleProfessionalSubmit = (values) => {
    var ProfessionalValues = this.state.PersonalValues;
    let faxlandRegux =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    var FaxVal = values.Fax;
    var LandlineVal = values.LandlineNumber;
    var IsValid = false;

    if (ProfessionalValues) {
      if (ProfessionalValues.FormType === FORMTYPES.Form1) {
        if (
          values.BarCouncilNumber === "" ||
          values.BarCouncilExperienceCertificate === ""
        ) {
          toastr.error("", "Please fill mandatory field");
        } else {
          if (values.Fax !== "") {
            if (FaxVal.match(faxlandRegux)) {
              if (values.LandlineNumber !== "") {
                if (LandlineVal.match(faxlandRegux)) {
                  if (values.Fax !== "" && values.LandlineNumber !== "") {
                    if (FaxVal.match(faxlandRegux)) {
                      if (LandlineVal.match(faxlandRegux)) {
                        IsValid = true;
                      } else {
                        toastr.error("", "Invalid landline number");
                      }
                    } else {
                      toastr.error("", "Invalid fax number");
                    }
                  } else {
                    IsValid = true;
                  }
                } else {
                  toastr.error("", "Invalid landline number");
                }
              } else {
                if (values.Fax === "" && values.LandlineNumber !== "") {
                  if (LandlineVal.match(faxlandRegux)) {
                    IsValid = true;
                  } else {
                    toastr.error("", "Invalid landline number");
                  }
                } else {
                  IsValid = true;
                }
              }
            } else {
              toastr.error("", "Invalid fax number");
            }
          } else if (values.LandlineNumber !== "") {
            if (LandlineVal.match(faxlandRegux)) {
              if (values.Fax === "" && values.LandlineNumber !== "") {
                if (LandlineVal.match(faxlandRegux)) {
                  IsValid = true;
                } else {
                  toastr.error("", "Invalid landline number");
                }
              } else {
                IsValid = true;
              }
            } else {
              toastr.error("", "Invalid landline number");
            }
          } else {
            IsValid = true;
          }
        }
      } else {
        if (values.Fax !== "") {
          if (FaxVal.match(faxlandRegux)) {
            if (values.LandlineNumber !== "") {
              if (LandlineVal.match(faxlandRegux)) {
                if (values.Fax !== "" && values.LandlineNumber !== "") {
                  if (FaxVal.match(faxlandRegux)) {
                    if (LandlineVal.match(faxlandRegux)) {
                      IsValid = true;
                    } else {
                      toastr.error("", "Invalid landline number");
                    }
                  } else {
                    toastr.error("", "Invalid fax number");
                  }
                } else {
                  IsValid = true;
                }
              } else {
                toastr.error("", "Invalid landline number");
              }
            } else {
              if (values.Fax === "" && values.LandlineNumber !== "") {
                if (LandlineVal.match(faxlandRegux)) {
                  IsValid = true;
                } else {
                  toastr.error("", "Invalid landline number");
                }
              } else {
                IsValid = true;
              }
            }
          } else {
            toastr.error("", "Invalid fax number");
          }
        } else if (values.LandlineNumber !== "") {
          if (LandlineVal.match(faxlandRegux)) {
            if (values.Fax === "" && values.LandlineNumber !== "") {
              if (LandlineVal.match(faxlandRegux)) {
                IsValid = true;
              } else {
                toastr.error("", "Invalid landline number");
              }
            } else {
              IsValid = true;
            }
          } else {
            toastr.error("", "Invalid landline number");
          }
        } else {
          IsValid = true;
        }
      }
    } else {
      toastr.error("", "Please fill personal tab field");
      this.setState({
        activeFirstTab: 1,
      });
    }

    if (IsValid) {
      ProfessionalValues.BarCouncilNumber = values.BarCouncilNumber;
      ProfessionalValues.BarCouncilCertificate = values.BarCouncilCertificate;
      ProfessionalValues.BarCouncilExperienceCertificate =
        values.BarCouncilExperienceCertificate;
      ProfessionalValues.EnrolmentDate = values.EnrolmentDate;
      ProfessionalValues.PracticingIn = values.PracticingIn;
      ProfessionalValues.LandlineNumber = values.LandlineNumber
        ? values.LandlineNumber
        : null;
      ProfessionalValues.Fax = values.Fax;
      ProfessionalValues.IncomeTaxAssessee = values.IncomeTaxAssessee;
      ProfessionalValues.PANNumber = values.PANNumber;
      ProfessionalValues.PANCopy = values.PANCopy;

      this.setState({
        ProfessionalValues: ProfessionalValues,
        activeFirstTab: 4,
      });
    }
  };
  handleQualificationSubmit = (values) => {
    console.log("handleQualificationSubmit", values);
    var isValidAll = true;
    var QualificationValues = this.state.ProfessionalValues;
    if (QualificationValues) {
      for (var i = 0; i < values.Degrees.length; i++) {
        if (
          values.Degrees[i] &&
          values.Degrees[i].Institution &&
          values.Degrees[i].Url &&
          values.Degrees[i].YearOfPassing &&
          values.Degrees[i].Name
        ) {
          isValidAll = true;
        } else {
          isValidAll = false;
          break;
        }
      }

      if (isValidAll) {
        QualificationValues.Degrees = values.Degrees;
        QualificationValues.IsIntegratedDegree = values.IsIntegratedDegree;
        this.setState({
          QualificationValues: QualificationValues,
          activeFirstTab: 5,
        });
      } else {
        toastr.error("", "Please fill mandatory field");
      }
    } else {
      toastr.error(
        "",
        "Please fill Form tab, personal tab and professional tab field"
      );
      this.setState({
        activeFirstTab: 1,
      });
    }
  };
  handleValidSubmit = (values) => {
    var handleFormValues = this.state.QualificationValues;
    if (handleFormValues) {
      if (values) {
        handleFormValues.ResidencyAddress = values.ResidencyAddress;
        handleFormValues.ProvisionalAddress = values.ProvisionAddress
          ? values.ResidencyAddress
          : values.ProvisionalAddress;
      }
      if (handleFormValues.ProvisionalAddress.Address.Line1 === "") {
        toastr.error("", "Official Address line 1 is mandatory");
      } else if (
        handleFormValues.ProvisionalAddress.Address.District === null ||
        handleFormValues.ResidencyAddress.Address.District === null
      ) {
        toastr.error("", "Residence and Official District field is mandatory");
      } else if (
        handleFormValues.ProvisionalAddress.Address.Taluk === null ||
        handleFormValues.ResidencyAddress.Address.Taluk === null
      ) {
        toastr.error("", "Residence and Official Taluk field is mandatory");
      } else if (handleFormValues.ProvisionalAddress.Pincode === "") {
        toastr.error("", "Official pincode is mandatory");
      } else {
        if (!this.state.buttonAction) {
          var submitValues = handleFormValues;

          submitValues.ResidencyAddress.Address.State = submitValues
            .ResidencyAddress.Address.State._id
            ? submitValues.ResidencyAddress.Address.State._id
            : submitValues.ResidencyAddress.Address.State;
          submitValues.ProvisionalAddress.Address.State = submitValues
            .ProvisionalAddress.Address.State._id
            ? submitValues.ProvisionalAddress.Address.State._id
            : submitValues.ProvisionalAddress.Address.State;
          submitValues.IsMarried =
            submitValues.Title === TITLE.THIRUMATHI
              ? true
              : submitValues.Title === TITLE.SELVI
              ? false
              : this.state.IsMarried;
          submitValues.Dob = moment(submitValues.Dob * 1000).unix();
          submitValues.PracticingSince = moment(
            submitValues.PracticingSince * 1000
          ).unix();
          submitValues.EnrolmentDate = moment(
            submitValues.EnrolmentDate * 1000
          ).unix();
          submitValues.PracticingIn = submitValues.PracticingIn.map((obj) =>
            obj.value ? obj.value : obj
          );
          submitValues.GovtServiceStartDate = moment(
            submitValues.GovtServiceStartDate * 1000
          ).unix();
          submitValues.GovtServiceEndDate = moment(
            submitValues.GovtServiceEndDate * 1000
          ).unix();
          this.setState({
            //  buttonAction: true,
            loading: true,
            ProfileDetails: submitValues,
          });

          let formData = new FormData();
          var FormDataValue = false;
          for (var i = 0; i < handleFormValues.Degrees.length; i++) {
            if (typeof handleFormValues.Degrees[i].Url === "object") {
              formData.append(
                `DegreeCopy${i}`,
                handleFormValues.Degrees[i].Url
              );
              FormDataValue = true;
            }
          }
          if (typeof handleFormValues.Photo === "object") {
            formData.append("Photo", handleFormValues.Photo);
            FormDataValue = true;
          }
          if (typeof handleFormValues.AadharCopy === "object") {
            formData.append("AadharCopy", handleFormValues.AadharCopy);
            FormDataValue = true;
          }
          if (typeof handleFormValues.PANCopy === "object") {
            formData.append("PANCopy", handleFormValues.PANCopy);
            FormDataValue = true;
          }

          if (typeof handleFormValues.BarCouncilCertificate === "object") {
            formData.append(
              "BarCouncilCertificate",
              handleFormValues.BarCouncilCertificate
            );
            FormDataValue = true;
          }
          if (
            typeof handleFormValues.BarCouncilExperienceCertificate === "object"
          ) {
            formData.append(
              "BarCouncilExperienceCertificate",
              handleFormValues.BarCouncilExperienceCertificate
            );
            FormDataValue = true;
          }

          if (FormDataValue === true) {
            CallService(
              documentMaster.FileUpload,
              MethodType.POST,
              false,
              formData,
              "",
              this.DocumentUploaded,
              true
            );
          } else {
            this.manageApplication(handleFormValues);
          }
        }
      }
    } else {
      toastr.error(
        "",
        "Please fill personal tab, professional tab & qualification tab field"
      );
      this.setState({
        activeFirstTab: 1,
      });
    }
  };
  DocumentUploaded = (data) => {
    console.log("DocumentUploaded", data);
    if (data.result) {
      var details = this.state.ProfileDetails;
      for (var i = 0; i < this.state.ProfileDetails.Degrees.length; i++) {
        details.Degrees[i].Url = data.result["DegreeCopy" + i]
          ? data.result["DegreeCopy" + i]
          : details.Degrees[i].Url;
      }

      details.Photo = data.result.Photo ? data.result.Photo : details.Photo;
      details.AadharCopy = data.result.AadharCopy
        ? data.result.AadharCopy
        : details.AadharCopy;
      details.PANCopy = data.result.PANCopy
        ? data.result.PANCopy
        : details.PANCopy;

      details.BarCouncilCertificate = data.result.BarCouncilCertificate
        ? data.result.BarCouncilCertificate
        : details.BarCouncilCertificate;
      details.BarCouncilExperienceCertificate = data.result
        .BarCouncilExperienceCertificate
        ? data.result.BarCouncilExperienceCertificate
        : details.BarCouncilExperienceCertificate;

      this.manageApplication(details);
      this.setState({
        buttonAction: false,
      });
    } else {
      this.setState({
        loading: false,
        buttonAction: false,
      });
      toastr.error("", "File Upload Failed");
    }
    this.setState({
      buttonAction: false,
    });
  };
  manageApplication = (values) => {
    CallService(
      this.state.ApplicationId
        ? profileComplete.Update + this.state.ApplicationId
        : profileComplete.Create,
      this.state.ApplicationId ? MethodType.PATCH : MethodType.PUT,
      false,
      values,
      "",
      this.manageApplicationResponse
    );
    this.setState({
      loading: false,
    });
  };
  manageApplicationResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result.IsProfileCompleted) {
        this.setState({
          loading: false,
          buttonAction: false,
        });
        toastr.success("", "Profile completed Successfully");
        this.props.history.push("/advocatelogin");
      } else {
        this.setState({
          loading: false,
          buttonAction: false,
        });
        toastr.error("", "Profile not created");
        this.props.history.push("/advocatelogin");
      }
    } else if (data.statusCode === "409") {
      if (data.exception && data.exception) {
        toastr.error("", data.exception.replace(/([A-Z])/g, " $1").trim());
        this.setState({
          loading: false,
          buttonAction: false,
          activeFirstTab: 1,
        });
      }
    } else {
      toastr.error("", "Something went wrong");
      this.toggleCustomJustified(1);
    }
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllTaluk(districtId) {
    let { ListRequestModel } = this.state;
    ListRequestModel.District = districtId;
    ListRequestModel.pageLimit = 1000;

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  }

  GetAllTalukResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllState() {
    CallService(
      state.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllStateResponse
    );
  }
  GetAllStateResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        StateList: data.result.map(function (a) {
          return { value: a._id, label: "தமிழ்நாடு" };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        StateList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }

    let { Profile } = this.state;
    Profile.ResidencyAddress.Address.State = data.result[0];
    Profile.ProvisionalAddress.Address.State = data.result[0];
  };
  GetAllCommunity() {
    CallService(
      community.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllCommunityResponse
    );
  }

  GetAllCommunityResponse = (data) => {
    this.setState({
      Communities: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };

  GetAllGender() {
    CallService(
      gender.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllGenderResponse
    );
  }

  GetAllGenderResponse = (data) => {
    this.setState({
      GenderList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  getAllTitles() {
    CallService(
      title.getAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.getAllTitleResponse
    );
  }

  getAllTitleResponse = (data) => {
    this.setState({
      titles: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  getAllFormTypes() {
    CallService(
      formType.getAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.getAllFormTypesResponse
    );
  }

  getAllFormTypesResponse = (data) => {
    this.setState({
      formTypes: [{ value: data.result[0]._id, label: "Register Yourself" }],
      // formTypes: data.result.map(function (a) {
      //   return { value: a._id, label: a.Name };
      // }),
    });
  };
  GetAllPracticingIn() {
    CallService(
      practicingin.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllPracticingInResponse
    );
  }

  GetAllPracticingInResponse = (data) => {
    this.setState({
      PracticingInList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  toggleImageView(rowId) {
    if (rowId) {
      this.setState({
        imageViewModal: !this.state.imageViewModal,
        ImageViewId: rowId,
      });
    } else {
      this.setState({
        imageViewModal: !this.state.imageViewModal,
      });
    }
  }

  GetCMS = (searchString) => {
    let requestModel = {
      pageNumber: 1,
      pageLimit: 999999999999,
      searchString: searchString,
    };
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      requestModel,
      "",
      this.GetCMSResponse
    );
  };

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        EligibilityContent: data.result && data.result[0],
      });
    }
  };

  toggleEligibilityModal = () => {
    this.setState({
      manageEligibilityModal: !this.state.manageEligibilityModal,
    });
  };

  CheckEligibilty = (
    formType,
    gender,
    community,
    enrollmentDate,
    serviceStartDate,
    serviceEndDate
  ) => {
    let requestObj = {};
    requestObj.FormType = formType;
    if (formType === FORMTYPES.Form1) {
      requestObj.Community = community;
      requestObj.EnrolmentDate = enrollmentDate;
      requestObj.Gender = gender;
      if (
        requestObj.Gender &&
        requestObj.Community &&
        requestObj.EnrolmentDate
      ) {
        CallService(
          profileComplete.ValidateProfile,
          MethodType.POST,
          false,
          requestObj,
          "",
          this.CheckEligibiltyResponse
        );
      }
    } else if (formType === FORMTYPES.Form2) {
      requestObj.GovtServiceStartDate = serviceStartDate;
      requestObj.GovtServiceEndDate = serviceEndDate;
      if (requestObj.GovtServiceStartDate && requestObj.GovtServiceEndDate) {
        CallService(
          profileComplete.ValidateProfile,
          MethodType.POST,
          false,
          requestObj,
          "",
          this.CheckEligibiltyResponse
        );
      }
    }
  };

  CheckEligibiltyResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        EligibilityStatus: data.result,
      });
    }
  };

  render() {
    const initialProfileValues = this.state.Profile;
    console.log("activeFirstTab", this.state.activeFirstTab);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb
              //   title={this.props.t("Law Admin")}
              breadcrumbItem={this.props.t("Profile")}
            />
            <Row>
              <Col lg="12" className="text-right">
                <span
                  className="text-info text-underline cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleEligibilityModal();
                  }}
                >
                  {this.props.t("EligibilityCriteria")}{" "}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
              </Col>
            </Row>

            {this.state.IsProfileCompleted === "true" ? (
              ""
            ) : (
              <Alert color="primary" style={{ textAlign: "center" }}>
                {this.props.t("Please complete your Profile")}
              </Alert>
            )}

            {/* <h4 className="card-title mb-4">Change UserName</h4> */}
            <Nav tabs className="nav-tabs-custom nav-justified">
              {this.state.tabs.map((t) => {
                return (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={{
                        active: this.state.activeFirstTab === t.id,
                      }}
                      onClick={() => {
                        this.toggleCustomJustified(t.id);
                      }}
                    >
                      <span className="d-none d-sm-block">{t.Name}</span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <Card>
              <CardBody>
                <Formik
                  initialValues={initialProfileValues}
                  validationSchema={ProfileFormTypeValidation}
                  onSubmit={this.handleFormTypeSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={1}>
                          <Row
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {/* #region Form Type based fields */}
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  isDisabled={
                                    this.state.IsProfileCompleted === "true"
                                      ? true
                                      : false
                                  }
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  labelClassName="requiredField"
                                  label={this.props.t("Form Type")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="FormType"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.FormType &&
                                    (typeof values.FormType === "string"
                                      ? this.state.formTypes.find(
                                          (r) => r.value === values.FormType
                                        )
                                      : this.state.formTypes.find(
                                          (r) => r.value === values.FormType._id
                                        ))
                                  }
                                  options={this.state.formTypes}
                                  ClearAction={() => {
                                    setFieldValue("FormType", undefined);
                                    this.CheckEligibilty(
                                      undefined,
                                      values.Gender,
                                      values.Community,
                                      values.EnrolmentDate,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "FormType",
                                      entity ? entity.value : null
                                    );
                                    this.CheckEligibilty(
                                      entity.value,
                                      values.Gender,
                                      values.Community,
                                      values.EnrolmentDate,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  errors={errors.FormType}
                                />
                              </FormGroup>
                            </Colxx>
                            {values.FormType === FORMTYPES.Form2 ? (
                              <Fragment>
                                {" "}
                                <Colxx xxs="6">
                                  <FormGroup className="form-group has-float-label">
                                    <Label className="requiredField">
                                      {" "}
                                      {this.props.t(
                                        "Date of joining government service"
                                      )}
                                    </Label>
                                    <DatePicker
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      maxDate={new Date()}
                                      autoComplete="off"
                                      showYearDropdown
                                      showMonthDropdown
                                      value={
                                        this.state.Profile.GovtServiceStartDate
                                      }
                                      selected={this.state.GovtServiceStartDate}
                                      className="form-control"
                                      onChange={(date) => {
                                        this.setState({
                                          GovtServiceStartDate: date,
                                        });
                                        values.GovtServiceStartDate = date;
                                        this.CheckEligibilty(
                                          values.FormType,
                                          values.Gender,
                                          values.Community,
                                          values.EnrolmentDate,
                                          date,
                                          values.GovtServiceEndDate
                                        );
                                      }}
                                      placeholderText="dd/mm/yyyy"
                                      name="startDate"
                                      dateFormat="dd/MM/yyyy"
                                      errors={errors.GovtServiceStartDate}
                                    />
                                    {errors.GovtServiceStartDate && (
                                      <div className="invalid-feedback d-block">
                                        {errors.GovtServiceStartDate}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="6">
                                  <FormGroup className="form-group has-float-label">
                                    <Label className="requiredField">
                                      {" "}
                                      {this.props.t("Date of retirement")}
                                    </Label>
                                    <DatePicker
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      autoComplete="off"
                                      showYearDropdown
                                      showMonthDropdown
                                      value={
                                        this.state.Profile.GovtServiceEndDate
                                      }
                                      selected={this.state.GovtServiceEndDate}
                                      className="form-control"
                                      onChange={(date) => {
                                        this.setState({
                                          GovtServiceEndDate: date,
                                        });
                                        values.GovtServiceEndDate = date;
                                        this.CheckEligibilty(
                                          values.FormType,
                                          values.Gender,
                                          values.Community,
                                          values.EnrolmentDate,
                                          values.GovtServiceStartDate,
                                          date
                                        );
                                      }}
                                      placeholderText="dd/mm/yyyy"
                                      name="startDate"
                                      dateFormat="dd/MM/yyyy"
                                      errors={errors.GovtServiceEndDate}
                                    />
                                    {errors.GovtServiceEndDate && (
                                      <div className="invalid-feedback d-block">
                                        {errors.GovtServiceEndDate}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="6">
                                  <FormGroup className="form-group has-float-label">
                                    <Label className="requiredField">
                                      {this.props.t(
                                        "Post held at the time of retirement"
                                      )}
                                    </Label>
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      autoComplete="off"
                                      type="text"
                                      autoFocus
                                      className="form-control"
                                      name="GovtServicePost"
                                    />
                                    {errors.GovtServicePost && (
                                      <div className="invalid-feedback d-block">
                                        {errors.GovtServicePost}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Colxx>
                              </Fragment>
                            ) : (
                              " "
                            )}
                            {/* #endregion */}
                            {/* {values.FormType === FORMTYPES.Form1 ? (
                              <Colxx xxs="6" style={{ zIndex: 0 }}>
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t(
                                      "Bar Council Experience Certificate"
                                    )}
                                  </Label>
                                  <CustomInput
                                    key={"BarCouncilExperienceCertificate"}
                                    type="file"
                                    name={"BarCouncilExperienceCertificate"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "BarCouncilExperienceCertificate",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  <p className="text-semi-muted">
                                    {getFileSizeText(500,"KB")}
                                    <p className="text-semi-muted">
                                      {getAllowedExtensionText("pdf",[
                                        "png",
                                        "doc",
                                        "pdf",
                                        "jpg",
                                        "jpeg",
                                      ])}
                                      {this.state.Profile
                                        .BarCouncilExperienceCertificate ? (
                                        <p
                                          style={{
                                            color: "blue",
                                            cursor: "Pointer",
                                            textAlign: "right",
                                          }}
                                        >
                                          <FileDisplay
                                            Value={
                                              this.state.Profile
                                                .BarCouncilExperienceCertificate
                                            }
                                          />
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </p>
                                  {errors.BarCouncilExperienceCertificate && (
                                    <div className="invalid-feedback d-block">
                                      {errors.BarCouncilExperienceCertificate}
                                    </div>
                                  )}{" "}
                                </FormGroup>
                              </Colxx>
                            ) : (
                              ""
                            )} */}
                          </Row>

                          <Row>
                            <Colxx xxs="12">
                              <p style={{ textAlign: "right" }}>
                                Fields marked with
                                <span style={{ color: "red" }}>
                                  &nbsp;*
                                </span>{" "}
                                are mandatory
                              </p>
                              {this.state.ApplicationId === "" ||
                              this.state.ApplicationId === null ||
                              this.state.ApplicationId === undefined ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  style={{ float: "right" }}
                                >
                                  Next
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    this.toggleCustomJustified(2);
                                  }}
                                  color="primary"
                                  style={{ float: "right" }}
                                >
                                  Next
                                </Button>
                              )}
                            </Colxx>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialProfileValues}
                  validationSchema={ProfilePersonalValidation}
                  onSubmit={this.handlePersonalSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={2}>
                          <Row
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  isDisabled={
                                    this.state.IsProfileCompleted === "true"
                                      ? true
                                      : false
                                  }
                                  labelClassName="requiredField"
                                  label={this.props.t("Title")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Title"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.Title &&
                                    (typeof values.Title === "string"
                                      ? this.state.titles.find(
                                          (r) => r.value === values.Title
                                        )
                                      : this.state.titles.find(
                                          (r) => r.value === values.Title._id
                                        ))
                                  }
                                  options={this.state.titles}
                                  ClearAction={() => {
                                    setFieldValue("Title", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Title",
                                      entity ? entity.value : null
                                    );
                                  }}
                                  errors={errors.Title}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("Name")}
                                </Label>

                                {this.state.IsProfileCompleted === "true" ? (
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name="Name"
                                    disabled
                                  />
                                ) : (
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name="Name"
                                  />
                                )}

                                {errors.Name && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Name}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                {/* <DropDown
                                  isDisabled={
                                    this.state.IsProfileCompleted === "true"
                                      ? true
                                      : false
                                  }
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  labelClassName="requiredField"
                                  label={this.props.t("Gender")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Gender"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.Gender &&
                                    (typeof values.Gender === "string"
                                      ? this.state.GenderList.find(
                                          (r) => r.value === values.Gender
                                        )
                                      : this.state.GenderList.find(
                                          (r) => r.value === values.Gender._id
                                        ))
                                  }
                                  options={this.state.GenderList}
                                  ClearAction={() => {
                                    setFieldValue("Gender", undefined);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Gender",
                                      entity ? entity.value : null
                                    );
                                    this.CheckEligibilty(
                                      values.FormType,
                                      entity.value,
                                      values.Community,
                                      values.EnrolmentDate,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  errors={errors.Gender}
                                /> */}
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <Label className="requiredField">
                                {this.props.t("Married")}
                              </Label>
                              {this.state.IsProfileCompleted === "true" ? (
                                <FormGroup className="form-group has-float-label">
                                  <div
                                    style={{ marginTop: "10px" }}
                                    onChange={(e) => {
                                      this.setState({
                                        IsMarried: e.target.value,
                                      });
                                      values.IsMarried = e.target.value;
                                    }}
                                  >
                                    <Label>{this.props.t("Yes")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      disabled
                                      type="radio"
                                      name="IsMarried"
                                      value={true}
                                      checked={
                                        this.state.Profile.IsMarried === true
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          values.IsMarried = "true";
                                        }
                                      }}
                                    />
                                    &nbsp; &emsp;
                                    <Label>{this.props.t("No")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      disabled
                                      type="radio"
                                      name="IsMarried"
                                      value={false}
                                      checked={
                                        this.state.Profile.IsMarried === false
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          values.IsMarried = "false";
                                        }
                                      }}
                                    />
                                  </div>
                                  {errors.IsMarried && (
                                    <div className="invalid-feedback d-block">
                                      {errors.IsMarried}
                                    </div>
                                  )}
                                </FormGroup>
                              ) : (values.Title === "" &&
                                  values.IsMarried === "") ||
                                values.Title === TITLE.THIRU ? (
                                <FormGroup className="form-group has-float-label">
                                  <div
                                    onChange={(e) => {
                                      this.setState({
                                        IsMarried: e.target.value,
                                      });
                                      values.IsMarried = e.target.value;
                                    }}
                                    style={{ marginTop: "10px" }}
                                    //  onChange={this.handleRadioButton}
                                  >
                                    <Label>{this.props.t("Yes")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      type="radio"
                                      value={true}
                                      name="IsMarried"
                                    />{" "}
                                    &nbsp; &emsp;
                                    <Label>{this.props.t("No")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      type="radio"
                                      value={false}
                                      name="IsMarried"
                                    />
                                  </div>
                                  {errors.IsMarried && (
                                    <div className="invalid-feedback d-block">
                                      {errors.IsMarried}
                                    </div>
                                  )}
                                </FormGroup>
                              ) : (
                                <FormGroup className="form-group has-float-label">
                                  <div
                                    onChange={(e) => {
                                      this.setState({
                                        IsMarried: e.target.value,
                                      });
                                      values.IsMarried = e.target.value;
                                    }}
                                    style={{ marginTop: "10px" }}
                                    //  onChange={this.handleRadioButton}
                                  >
                                    <Label>{this.props.t("Yes")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      type="radio"
                                      value={true}
                                      name="IsMarried"
                                      checked={
                                        values.Title === TITLE.THIRUMATHI
                                      }
                                    />{" "}
                                    &nbsp; &emsp;
                                    <Label>{this.props.t("No")}</Label>
                                    &nbsp; &nbsp;
                                    <input
                                      type="radio"
                                      value={false}
                                      name="IsMarried"
                                      checked={values.Title === TITLE.SELVI}
                                    />
                                  </div>
                                  {errors.IsMarried && (
                                    <div className="invalid-feedback d-block">
                                      {errors.IsMarried}
                                    </div>
                                  )}
                                </FormGroup>
                              )}{" "}
                            </Colxx>

                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Mobile Number")}
                                </Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  type="MobileNumber"
                                  autoFocus
                                  className="form-control"
                                  name="MobileNumber"
                                  disabled
                                />
                                {errors.MobileNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.MobileNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                {values.Title === TITLE.THIRUMATHI ? (
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t("Husband’s Name")}
                                  </Label>
                                ) : (
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t("Father’s Name")}
                                  </Label>
                                )}

                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="nope"
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="FatherOrHusbandName"
                                />
                                {errors.FatherOrHusbandName && (
                                  <div className="invalid-feedback d-block">
                                    {errors.FatherOrHusbandName}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Date Of Birth")}
                                </Label>

                                <DatePicker
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="nope"
                                  selected={this.state.startDate}
                                  className="form-control"
                                  value={this.state.Profile.Dob}
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) => {
                                    this.setState({
                                      startDate: date,
                                    });
                                    values.Dob = date;
                                    //  values.Dob = new Date(date).getTime();
                                  }}
                                  placeholderText="dd/mm/yyyy"
                                  name="startDate"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                />

                                {errors.Dob && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Dob}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  labelClassName="requiredField"
                                  label={this.props.t("Community")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Community"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.Community &&
                                    (typeof values.Community === "string"
                                      ? this.state.Communities.find(
                                          (r) => r.value === values.Community
                                        )
                                      : this.state.Communities.find(
                                          (r) =>
                                            r.value === values.Community._id
                                        ))
                                  }
                                  options={this.state.Communities}
                                  ClearAction={() => {
                                    setFieldValue("Community", undefined);
                                    this.CheckEligibilty(
                                      values.FormType,
                                      values.Gender,
                                      undefined,
                                      values.EnrolmentDate,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Community",
                                      entity ? entity.value : undefined
                                    );
                                    this.CheckEligibilty(
                                      values.FormType,
                                      values.Gender,
                                      entity.value,
                                      values.EnrolmentDate,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  errors={errors.Community}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6" style={{ zIndex: 0 }}>
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Photo")}
                                </Label>
                                <CustomInput
                                  id="Photo"
                                  key={"Photo"}
                                  type="file"
                                  name={"Photo"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "Photo",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <p className="text-semi-muted">
                                  {getFileSizeText(5, "MB")}

                                  <p className="text-semi-muted">
                                    {getAllowedExtensionText(["pdf"])}
                                    {this.state.Profile.Photo ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={this.state.Profile.Photo}
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </p>

                                {errors.Photo && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Photo}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Email ID")}
                                </Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="nope"
                                  type="Email"
                                  autoFocus
                                  className="form-control"
                                  name="EmailID"
                                />
                                {errors.EmailID && (
                                  <div className="invalid-feedback d-block">
                                    {errors.EmailID}
                                  </div>
                                )}{" "}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Aadhar Number")}
                                </Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="nope"
                                  type="number"
                                  autoFocus
                                  className="IncrementHide form-control"
                                  name="Aadhar"
                                />
                                {errors.Aadhar && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Aadhar}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6" style={{ zIndex: 0 }}>
                              <FormGroup className="form-group has-float-label">
                                <Label>
                                  {" "}
                                  {this.props.t(
                                    "Copy of Aadhar (Self Attested)"
                                  )}
                                </Label>
                                <CustomInput
                                  key={"AadharCopy"}
                                  type="file"
                                  name={"AadharCopy"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "AadharCopy",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <p className="text-semi-muted">
                                  {getFileSizeText(5, "MB")}

                                  <p className="text-semi-muted">
                                    {getAllowedExtensionText("pdf", [
                                      "png",
                                      "doc",
                                      "pdf",
                                      "jpg",
                                      "jpeg",
                                    ])}
                                    {this.state.Profile.AadharCopy ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={this.state.Profile.AadharCopy}
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </p>
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" style={{ zIndex: 0 }}>
                              {/* <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Attested Copy")}
                                </Label>
                                <CustomInput
                                  key={"AttestedCopy"}
                                  type="file"
                                  name={"AttestedCopy"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "AttestedCopy",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <p className="text-semi-muted">
                                  {getFileSizeText(500,"KB")}

                                  <p className="text-semi-muted">
                                    {getAllowedExtensionText("pdf",[
                                      "png",
                                      "doc",
                                      "pdf",
                                      "jpg",
                                      "jpeg",
                                    ])}
                                    {this.state.Profile.AttestedCopy ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={
                                            this.state.Profile.AttestedCopy
                                          }
                                        />
                                      </p>
                                    ) : (
                                        ""
                                      )}
                                  </p>
                                </p>
                                {errors.AttestedCopy && (
                                  <div className="invalid-feedback d-block">
                                    {errors.AttestedCopy}
                                  </div>
                                )}{" "}
                              </FormGroup> */}
                              <p style={{ textAlign: "right" }}>
                                Fields marked with
                                <span style={{ color: "red" }}>
                                  &nbsp;*
                                </span>{" "}
                                are mandatory
                              </p>
                              {this.state.ApplicationId === "" ||
                              this.state.ApplicationId === null ||
                              this.state.ApplicationId === undefined ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  style={{ float: "right" }}
                                >
                                  Next
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    this.toggleCustomJustified(3);
                                  }}
                                  color="primary"
                                  style={{ float: "right" }}
                                >
                                  Next
                                </Button>
                              )}
                            </Colxx>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>
                <Formik
                  initialValues={initialProfileValues}
                  validationSchema={ProfileProfessionalValidation}
                  onSubmit={this.handleProfessionalSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, touched, setFieldValue }) => (
                    <Form
                      autoComplete="nope"
                      className="av-tooltip tooltip-label-bottom"
                    >
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={3}>
                          <Row
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label
                                  className={
                                    this.state.FormTypesValues &&
                                    this.state.FormTypesValues.FormType ===
                                      FORMTYPES.Form2
                                      ? ""
                                      : "requiredField"
                                  }
                                >
                                  {" "}
                                  {this.props.t("Bar Council Enrolment Number")}
                                </Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="BarCouncilNumber"
                                />
                                {errors.BarCouncilNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.BarCouncilNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6" style={{ zIndex: 0 }}>
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t(
                                    "Bar Council Certificate (Self Attested)"
                                  )}
                                </Label>
                                <CustomInput
                                  key={"BarCouncilCertificate"}
                                  type="file"
                                  name={"BarCouncilCertificate"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "BarCouncilCertificate",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <p className="text-semi-muted">
                                  {getFileSizeText(5, "MB")}
                                  <p className="text-semi-muted">
                                    {getAllowedExtensionText("pdf", [
                                      "png",
                                      "doc",
                                      "pdf",
                                      "jpg",
                                      "jpeg",
                                    ])}
                                    {this.state.Profile
                                      .BarCouncilCertificate ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={
                                            this.state.Profile
                                              .BarCouncilCertificate
                                          }
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </p>
                                {errors.BarCouncilCertificate && (
                                  <div className="invalid-feedback d-block">
                                    {errors.BarCouncilCertificate}
                                  </div>
                                )}{" "}
                              </FormGroup>
                            </Colxx>
                            {this.state.FormTypesValues &&
                            this.state.FormTypesValues.FormType ===
                              FORMTYPES.Form1 ? (
                              <Colxx xxs="6" style={{ zIndex: 0 }}>
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t(
                                      "Bar Council Experience Certificate"
                                    )}
                                  </Label>
                                  <CustomInput
                                    key={"BarCouncilExperienceCertificate"}
                                    type="file"
                                    name={"BarCouncilExperienceCertificate"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "BarCouncilExperienceCertificate",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  <p className="text-semi-muted">
                                    {getFileSizeText(5, "MB")}
                                    <p className="text-semi-muted">
                                      {getAllowedExtensionText("pdf", [
                                        "png",
                                        "doc",
                                        "pdf",
                                        "jpg",
                                        "jpeg",
                                      ])}
                                      {this.state.Profile
                                        .BarCouncilExperienceCertificate ? (
                                        <p
                                          style={{
                                            color: "blue",
                                            cursor: "Pointer",
                                            textAlign: "right",
                                          }}
                                        >
                                          <FileDisplay
                                            Value={
                                              this.state.Profile
                                                .BarCouncilExperienceCertificate
                                            }
                                          />
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </p>
                                  {errors.BarCouncilExperienceCertificate && (
                                    <div className="invalid-feedback d-block">
                                      {errors.BarCouncilExperienceCertificate}
                                    </div>
                                  )}{" "}
                                </FormGroup>
                              </Colxx>
                            ) : (
                              ""
                            )}
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Enrolment Date")}
                                </Label>
                                <DatePicker
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  autoComplete="off"
                                  value={this.state.Profile.EnrolmentDate}
                                  selected={this.state.EnrolmentDate}
                                  className="form-control"
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) => {
                                    this.setState({
                                      EnrolmentDate: date,
                                    });
                                    values.EnrolmentDate = date;
                                    // values.EnrolmentDate = new Date(
                                    //    date
                                    //  ).getTime();

                                    // values.PracticingSince = new Date(date)
                                    //   .setHours(0, 0, 0, 0)
                                    //   .getTime();

                                    // var d = new Date(date);
                                    // d.setHours(
                                    //   d.getHours() - 5,
                                    //   d.getMinutes() - 30
                                    // );
                                    this.CheckEligibilty(
                                      values.FormType,
                                      values.Gender,
                                      values.Community,
                                      date,
                                      values.GovtServiceStartDate,
                                      values.GovtServiceEndDate
                                    );
                                  }}
                                  placeholderText="dd/mm/yyyy"
                                  name="startDate"
                                  dateFormat="dd/MM/yyyy"
                                  errors={errors.EnrolmentDate}
                                  maxDate={new Date()}
                                />
                                {errors.EnrolmentDate && (
                                  <div className="invalid-feedback d-block">
                                    {errors.EnrolmentDate}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  isMulti="true"
                                  labelClassName="requiredField"
                                  label={this.props.t("Practicing In")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="PracticingIn"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values &&
                                    values.PracticingIn &&
                                    this.state.PracticingInList.filter((a) => {
                                      return values.PracticingIn.some((r) => {
                                        return (
                                          a.value ===
                                          (typeof r === "object"
                                            ? r.value
                                              ? r.value
                                              : r.value
                                            : r)
                                        );
                                      });
                                    }).map(function (o) {
                                      return o;
                                    })
                                  }
                                  ClearAction={() => {
                                    setFieldValue("PracticingIn", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "PracticingIn",
                                      entity ? entity : ""
                                    );
                                  }}
                                  options={this.state.PracticingInList}
                                  errors={errors.PracticingIn}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Landline Number")}</Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  //  placeholder="xxx xxx xxxx"
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  className="IncrementHide form-control"
                                  name="LandlineNumber"
                                />
                                {errors.LandlineNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.LandlineNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Fax")}</Label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  //  placeholder=
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  className="IncrementHide form-control"
                                  name="Fax"
                                />
                                {errors.Fax && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Fax}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="4">
                              <Label className="requiredField">
                                {this.props.t("Income Tax Assessee")}
                              </Label>{" "}
                              <FormGroup>
                                <Field
                                  autoComplete="off"
                                  //  style={{ marginTop: "39px" }}
                                  type="checkbox"
                                  autoFocus
                                  name="IncomeTaxAssessee"
                                />
                              </FormGroup>
                              {errors.IncomeTaxAssessee && (
                                <div className="invalid-feedback d-block">
                                  {errors.IncomeTaxAssessee}
                                </div>
                              )}
                            </Colxx>

                            {/* <Colxx xxs="3">
                              <FormGroup className="form-group has-float-label"></FormGroup>
                              
                            </Colxx> */}

                            {values.IncomeTaxAssessee ? (
                              <Fragment>
                                <Colxx xxs="4">
                                  <FormGroup className="form-group has-float-label">
                                    <Label className="requiredField">
                                      {this.props.t("PAN Number")}
                                    </Label>
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      placeholder="XXXXX0000X"
                                      autoComplete="off"
                                      type="text"
                                      autoFocus
                                      className="form-control"
                                      name="PANNumber"
                                    />
                                    {errors.PANNumber && touched.PANNumber ? (
                                      <div className="invalid-feedback d-block">
                                        {errors.PANNumber}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="4" style={{ zIndex: 0 }}>
                                  <FormGroup className="form-group has-float-label">
                                    <Label className="requiredField">
                                      {" "}
                                      {this.props.t("PAN Card (Self Attested)")}
                                    </Label>
                                    <CustomInput
                                      key={"PANCopy"}
                                      type="file"
                                      name={"PANCopy"}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "PANCopy",
                                          event.target.files[0]
                                        );
                                      }}
                                    />
                                    <p className="text-semi-muted">
                                      {getFileSizeText(5, "MB")}

                                      <p className="text-semi-muted">
                                        {getAllowedExtensionText("pdf", [
                                          "png",
                                          "doc",
                                          "pdf",
                                          "jpg",
                                          "jpeg",
                                        ])}
                                        {this.state.Profile.PANCopy ? (
                                          <p
                                            style={{
                                              color: "blue",
                                              cursor: "Pointer",
                                              textAlign: "right",
                                            }}
                                          >
                                            <FileDisplay
                                              Value={this.state.Profile.PANCopy}
                                            />
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </p>
                                    {errors.PANCopy && (
                                      <div className="invalid-feedback d-block">
                                        {errors.PANCopy}
                                      </div>
                                    )}{" "}
                                  </FormGroup>
                                </Colxx>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Row>
                          <Colxx xxs="12">
                            {/* <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Remarks")}</Label>
                                <Field
                                  component="textarea"
                                  autoFocus
                                  className="form-control"
                                  name="Remarks"
                                />
                                {errors.Remarks && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Remarks}
                                  </div>
                                )}
                              </FormGroup> */}
                            <p style={{ textAlign: "right" }}>
                              Fields marked with
                              <span style={{ color: "red" }}> &nbsp;*</span> are
                              mandatory
                            </p>
                            {this.state.ApplicationId === "" ||
                            this.state.ApplicationId === null ||
                            this.state.ApplicationId === undefined ? (
                              <Button
                                type="submit"
                                color="primary"
                                style={{ float: "right" }}
                              >
                                Next
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  this.toggleCustomJustified(4);
                                }}
                                color="primary"
                                style={{ float: "right" }}
                              >
                                Next
                              </Button>
                            )}
                          </Colxx>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialProfileValues}
                  //  validationSchema={ProfileQualificationValidation}
                  onSubmit={this.handleQualificationSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={4}>
                          <Row
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <div
                              onChange={(e) => {
                                var clearDegrees = this.state.Profile.Degrees;

                                clearDegrees.splice(
                                  0,
                                  this.state.Profile.Degrees.length
                                );

                                if (e.target.value === "true") {
                                  var degrees = this.state.Profile.Degrees;
                                  degrees.push({
                                    Institution: "",
                                    Name: "",
                                    Url: "",
                                    YearOfPassing: "",
                                  });
                                } else {
                                  degrees = this.state.Profile.Degrees;
                                  degrees.push(
                                    {
                                      Institution: "",
                                      Name: "",
                                      Url: "",
                                      YearOfPassing: "",
                                    },
                                    {
                                      Institution: "",
                                      Name: "",
                                      Url: "",
                                      YearOfPassing: "",
                                    }
                                  );
                                }

                                this.setState({
                                  Degrees: degrees,
                                  IsIntegratedDegree: e.target.value,
                                });
                                values.IsIntegratedDegree = e.target.value;
                              }}
                            >
                              {this.state.IsProfileCompleted === "true" ? (
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t(
                                      "Integrated Degree (5 Years)"
                                    )}
                                  </Label>
                                  &nbsp; &nbsp;
                                  <input
                                    disabled
                                    type="radio"
                                    name="IsIntegratedDegree"
                                    value={true}
                                    checked={
                                      this.state.Profile.IsIntegratedDegree ===
                                      true
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        values.IsIntegratedDegree = "true";
                                      }
                                    }}
                                  />
                                  &nbsp; &emsp;
                                  <Label>
                                    {this.props.t(
                                      "Non-Integrated Degree (3 Years)"
                                    )}
                                  </Label>
                                  &nbsp; &nbsp;
                                  <input
                                    disabled
                                    type="radio"
                                    name="IsIntegratedDegree"
                                    value={false}
                                    checked={
                                      this.state.Profile.IsIntegratedDegree ===
                                      false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        values.IsIntegratedDegree = "false";
                                      }
                                    }}
                                  />
                                </FormGroup>
                              ) : (
                                <div>
                                  <Label>
                                    {this.props.t(
                                      "Integrated Degree (5 Years)"
                                    )}
                                  </Label>
                                  &nbsp; &nbsp;
                                  <input
                                    type="radio"
                                    value={true}
                                    name="IsIntegratedDegree"
                                  />{" "}
                                  &nbsp; &emsp;
                                  <Label>
                                    {this.props.t(
                                      "Non-Integrated Degree (3 Years)"
                                    )}
                                  </Label>
                                  &nbsp; &nbsp;
                                  <input
                                    type="radio"
                                    value={false}
                                    name="IsIntegratedDegree"
                                  />
                                </div>
                              )}
                            </div>

                            <Row>
                              {this.state.Profile
                                ? this.state.Profile.Degrees.length > 0
                                  ? this.state.Profile.Degrees.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            <Colxx xxs="3">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {this.props.t("Institution")}
                                                </Label>
                                                <Field
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  autoComplete="off"
                                                  type="text"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].Institution`}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `Degrees[${Index}].Institution`,
                                                      e.target.value
                                                    );
                                                    values.Institution =
                                                      e.target.value;
                                                  }}
                                                />

                                                {errors.Institution && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Institution}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="2">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {this.props.t("Degree")}
                                                </Label>
                                                <Field
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  autoComplete="off"
                                                  type="text"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].Name`}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `Degrees[${Index}].Name`,
                                                      e.target.value
                                                    );
                                                    values.Degree =
                                                      e.target.value;
                                                  }}
                                                />

                                                {errors.Degree && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Degree}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {values.IsIntegratedDegree ===
                                                  "true"
                                                    ? this.props.t(
                                                        "Copy Of Law Degree Certificate (Self Attested)"
                                                      )
                                                    : this.props.t(
                                                        "Copy Of Graduation Degree Certificate (Self Attested)"
                                                      )}
                                                </Label>
                                                <CustomInput
                                                  key={"Url"}
                                                  type="file"
                                                  name={"Url"}
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      `Degrees[${Index}].Url`,
                                                      event.target.files[0]
                                                    );

                                                    values.Url =
                                                      event.target.files[0];
                                                  }}
                                                />
                                                <p className="text-semi-muted">
                                                  {getFileSizeText(5, "MB")}

                                                  <p className="text-semi-muted">
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "png",
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {this.state.Profile &&
                                                    this.state.Profile
                                                      .Degrees &&
                                                    this.state.Profile.Degrees[
                                                      Index
                                                    ].Url ? (
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state.Profile
                                                              .Degrees[Index]
                                                              .Url
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>

                                                {errors.Url && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Url}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="3">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {this.props.t(
                                                    "Year Of Passing"
                                                  )}
                                                </Label>
                                                <Field
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  autoComplete="off"
                                                  type="number"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].YearOfPassing`}
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      `Degrees[${Index}].YearOfPassing`,
                                                      event.target.value
                                                    );
                                                    values.YearOfPassing =
                                                      event.target.value;
                                                  }}
                                                />
                                                {errors.YearOfPassing && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.YearOfPassing}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>

                                            {/* <div
                                            style={{
                                              marginTop: "33px",
                                            }}
                                          >
                                            {this.state.Profile.Degrees.length >
                                              1 && (
                                              <i
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                className="bx bx-minus-circle cursorPointer"
                                                onClick={() => {
                                                  var selectedProduct = this
                                                    .state.Profile.Degrees;

                                                  selectedProduct.splice(
                                                    Index,
                                                    1
                                                  );
                                                  this.setState({
                                                    selectedProduct: selectedProduct,
                                                  });
                                                }}
                                              />
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            {this.state.Profile.Degrees
                                            .length ===
                                            Index + 1 && (
                                              <i
                                                className="bx bx-plus-circle cursorPointer"
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  var selectedProduct = this
                                                    .state.Profile.Degrees;
                                                  selectedProduct.push({
                                                    Institution: "",
                                                    Name: "",
                                                    Url: "",
                                                    YearOfPassing: "",
                                                  });
                                                  this.setState({
                                                    selectedProduct: selectedProduct,
                                                  });
                                                }}
                                              />
                                            )}
                                          </div> */}
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""}
                            </Row>
                          </Row>
                          <p style={{ textAlign: "right" }}>
                            Fields marked with
                            <span style={{ color: "red" }}> &nbsp;*</span> are
                            mandatory
                          </p>
                          {this.state.ApplicationId === "" ||
                          this.state.ApplicationId === null ||
                          this.state.ApplicationId === undefined ? (
                            <Button
                              type="submit"
                              color="primary"
                              style={{ float: "right" }}
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.toggleCustomJustified(5);
                              }}
                              color="primary"
                              style={{ float: "right" }}
                            >
                              Next
                            </Button>
                          )}
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>
                <Formik
                  initialValues={initialProfileValues}
                  validationSchema={ProfileAddressValidation}
                  onSubmit={this.handleValidSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={5}>
                          <Row>
                            <Colxx xxs="6">
                              <label>
                                <h5>{this.props.t("Residence Address")}</h5>
                              </label>
                            </Colxx>

                            <Colxx xxs="2">
                              <div>
                                <label>
                                  <h5>{this.props.t("Official Address")}</h5>
                                </label>
                              </div>
                            </Colxx>
                            <Colxx xxs="3">
                              <Label style={{ float: "right" }}>
                                {this.props.t("Same as Residence Address")} :
                              </Label>
                            </Colxx>
                            <Colxx xxs="1">
                              <Field
                                style={{ marginTop: "4px" }}
                                type="checkbox"
                                autoFocus
                                name="ProvisionAddress"
                              />
                            </Colxx>
                          </Row>
                          <Row
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <Colxx xxs="6">
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Address Line 1")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="off"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name="ResidencyAddress.Address.Line1"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.Line1",
                                        e.target.value
                                      );
                                      values.ResidencyAddressLine1 =
                                        e.target.value;
                                    }}
                                  />

                                  {errors.ResidencyAddressLine1 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ResidencyAddressLine1}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Address Line 2")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name="ResidencyAddress.Address.Line2"
                                  />
                                  {errors.Line2 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Line2}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Address Line 3")}
                                  </Label>

                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name="ResidencyAddress.Address.Line3"
                                  />
                                  {errors.Line3 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Line3}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>

                              {/* <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>Street</Label>
                                  <Field
                                    autoFocus
                                    className="form-control"
                                    name="ResidencyStreet"
                                  />
                                  {errors.ResidencyStreet && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ResidencyStreet}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>Area</Label>
                                  <Field
                                    autoFocus
                                    className="form-control"
                                    name="ResidencyArea"
                                  />
                                  {errors.ResidencyArea && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ResidencyArea}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx> */}

                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("State")}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="ResidencyState"
                                    isDisabled={true}
                                    isSearchable
                                    isClearable={true}
                                    value={
                                      values &&
                                      values.ResidencyAddress &&
                                      values.ResidencyAddress.Address &&
                                      values.ResidencyAddress.Address.State &&
                                      (typeof values.ResidencyAddress.Address
                                        .State === "string"
                                        ? this.state.StateList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .State
                                          )
                                        : this.state.StateList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .State._id
                                          ))
                                    }
                                    options={this.state.StateList}
                                    ClearAction={() => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.State",
                                        null
                                      );
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.State",
                                        entity ? entity.value : null
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("District")}
                                    components={{ Input: CustomSelectInput }}
                                    //    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable
                                    isClearable={true}
                                    value={
                                      values.ResidencyAddress.Address
                                        .District &&
                                      (typeof values.ResidencyAddress.Address
                                        .District === "string"
                                        ? this.state.DistrictList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .District
                                          )
                                        : this.state.DistrictList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .District._id
                                          ))
                                    }
                                    options={this.state.DistrictList}
                                    ClearAction={() => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.District",
                                        null
                                      );
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.District",
                                        entity ? entity.value : null
                                      );

                                      values.ResidencyDistrict = entity
                                        ? entity.value
                                        : null;

                                      this.GetAllTaluk(
                                        entity ? entity.value : ""
                                      );
                                    }}
                                    // errors={errors.ResidencyDistrict}
                                  />
                                  {errors.ResidencyDistrict && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ResidencyDistrict}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("Taluk")}
                                    components={{ Input: CustomSelectInput }}
                                    //    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable
                                    isClearable={true}
                                    value={
                                      values &&
                                      values.ResidencyAddress &&
                                      values.ResidencyAddress.Address &&
                                      values.ResidencyAddress.Address.Taluk &&
                                      (typeof values.ResidencyAddress.Address
                                        .Taluk === "string"
                                        ? this.state.TalukList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .Taluk
                                          )
                                        : this.state.TalukList.find(
                                            (r) =>
                                              r.value ===
                                              values.ResidencyAddress.Address
                                                .Taluk._id
                                          ))
                                    }
                                    options={this.state.TalukList}
                                    ClearAction={() => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.Taluk",
                                        null
                                      );
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.Taluk",
                                        entity ? entity.value : null
                                      );

                                      values.ResidencyTaluk = entity
                                        ? entity.value
                                        : null;
                                    }}
                                    errors={errors.ResidencyTaluk}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("PIN Code")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="number"
                                    autoFocus
                                    className="IncrementHide form-control"
                                    name="ResidencyAddress.Address.Pincode"
                                    placeholder="Pincode"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "ResidencyAddress.Address.Pincode",
                                        e.target.value
                                      );
                                      values.ResidencyPincode = e.target.value;
                                    }}
                                  />
                                  {errors.ResidencyPincode && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ResidencyPincode}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                            </Colxx>

                            <Colxx xxs="6">
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Address Line 1")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    //  name="ProvisionLine1"
                                    name={
                                      values.ProvisionAddress
                                        ? "ResidencyAddress.Address.Line1"
                                        : "ProvisionalAddress.Address.Line1"
                                    }
                                    // onChange={(e) => {
                                    //   setFieldValue(
                                    //     "ProvisionalAddress.Address.Line1",
                                    //     e.target.value
                                    //   );

                                    //   values.ProvisionAddressLine1 = values.ProvisionAddress
                                    //     ? values.ResidencyAddressLine1
                                    //     : e.target.value;
                                    // }}
                                  />
                                  {errors.ProvisionAddressLine1 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ProvisionAddressLine1}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Address Line 2")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name={
                                      values.ProvisionAddress
                                        ? "ResidencyAddress.Address.Line2"
                                        : "ProvisionalAddress.Address.Line2"
                                    }
                                  />
                                  {errors.ProvisionLine2 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ProvisionLine2}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Address Line 3")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="text"
                                    autoFocus
                                    className="form-control"
                                    name={
                                      values.ProvisionAddress
                                        ? "ResidencyAddress.Address.Line3"
                                        : "ProvisionalAddress.Address.Line3"
                                    }
                                  />
                                  {errors.Line3 && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Line3}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>

                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("State")}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="Provisionalstate"
                                    isSearchable
                                    isDisabled={true}
                                    isClearable={true}
                                    value={
                                      values.ProvisionAddress
                                        ? values.ResidencyAddress &&
                                          values.ResidencyAddress.Address &&
                                          values.ResidencyAddress.Address
                                            .State &&
                                          (typeof values.ResidencyAddress
                                            .Address.State === "string"
                                            ? this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.State
                                              )
                                            : this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.State._id
                                              ))
                                        : values.ProvisionalAddress &&
                                          values.ProvisionalAddress.Address &&
                                          values.ProvisionalAddress.Address
                                            .State &&
                                          (typeof values.ProvisionalAddress
                                            .Address.State === "string"
                                            ? this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.State
                                              )
                                            : this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.State._id
                                              ))
                                    }
                                    options={this.state.StateList}
                                    ClearAction={() => {
                                      setFieldValue("ProvisionalAddress", null);
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ProvisionalAddress.Address.State",
                                        entity ? entity.value : null
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("District")}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable
                                    isClearable={true}
                                    value={
                                      values.ProvisionAddress
                                        ? values.ResidencyAddress.Address
                                            .District &&
                                          (typeof values.ResidencyAddress
                                            .Address.District === "string"
                                            ? this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.District
                                              )
                                            : this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.District._id
                                              ))
                                        : values.ProvisionalAddress.Address
                                            .District &&
                                          (typeof values.ProvisionalAddress
                                            .Address.District === "string"
                                            ? this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.District
                                              )
                                            : this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.District._id
                                              ))
                                    }
                                    options={this.state.DistrictList}
                                    ClearAction={() => {
                                      setFieldValue(
                                        "ProvisionalAddress.Address.District",
                                        null
                                      );
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ProvisionalAddress.Address.District",

                                        values.ProvisionAddress
                                          ? values.ResidencyAddress.Address
                                              .District
                                          : entity.value
                                      );
                                      this.GetAllTaluk(
                                        entity ? entity.value : ""
                                      );
                                    }}
                                    //  errors={errors.ResidencyDistrict}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    labelClassName="requiredField"
                                    label={this.props.t("Taluk")}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable
                                    isClearable={true}
                                    value={
                                      values.ProvisionAddress
                                        ? values.ResidencyAddress &&
                                          values.ResidencyAddress.Address &&
                                          values.ResidencyAddress.Address
                                            .Taluk &&
                                          (typeof values.ResidencyAddress
                                            .Address.Taluk === "string"
                                            ? this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.Taluk
                                              )
                                            : this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ResidencyAddress
                                                    .Address.Taluk._id
                                              ))
                                        : values.ProvisionalAddress &&
                                          values.ProvisionalAddress.Address &&
                                          values.ProvisionalAddress.Address
                                            .Taluk &&
                                          (typeof values.ProvisionalAddress
                                            .Address.Taluk === "string"
                                            ? this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.Taluk
                                              )
                                            : this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values.ProvisionalAddress
                                                    .Address.Taluk._id
                                              ))
                                    }
                                    options={this.state.TalukList}
                                    ClearAction={() => {
                                      setFieldValue(
                                        "ProvisionalAddress.Address.Taluk",
                                        null
                                      );
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "ProvisionalAddress.Address.Taluk",
                                        values.ProvisionAddress
                                          ? values.ResidencyAddress.Address
                                              .Taluk
                                          : entity.value
                                      );
                                    }}
                                    //  errors={errors.ResidencyDistrict}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("PIN Code")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    autoComplete="nope"
                                    type="number"
                                    autoFocus
                                    className="IncrementHide form-control"
                                    name={
                                      values.ProvisionAddress
                                        ? "ResidencyAddress.Address.Pincode"
                                        : "ProvisionalAddress.Address.Pincode"
                                    }
                                    placeholder="Pincode"
                                  />
                                </FormGroup>
                              </Colxx>
                            </Colxx>
                          </Row>
                          <p style={{ textAlign: "right" }}>
                            Fields marked with
                            <span style={{ color: "red" }}> &nbsp;*</span> are
                            mandatory
                          </p>
                        </TabPane>
                      </TabContent>

                      {this.state.activeFirstTab === 5 ? (
                        this.state.ApplicationId === "" ||
                        this.state.ApplicationId === null ||
                        this.state.ApplicationId === undefined ? (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            color="success"
                            style={{ float: "right" }}
                          >
                            Submit
                          </Button>
                        ) : (
                          ""
                        )
                      ) : (
                        " "
                      )}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            <Modal
              isOpen={this.state.imageViewModal}
              toggle={this.toggleImageView}
            >
              <ModalHeader toggle={this.toggleImageView}>View</ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <img
                      alt="docView"
                      src={this.state.ImageViewId}
                      title="documentview"
                      className="Img-Height"
                      width="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </Row>
                  <Row>
                    <Fragment>
                      <Colxx style={{ textAlign: "right" }}>
                        <a
                          className="LinkStyle"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.state.ImageViewId}
                        >
                          {"Download"}
                        </a>
                      </Colxx>
                    </Fragment>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="form-second-btn"
                    onClick={() => this.toggleImageView("")}
                  >
                    Close
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.loading}
              style={{ marginTop: "20%" }}
            >
              <ModalBody>
                <Fragment>
                  <span>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                  &nbsp; Processing... Please wait... Please login once
                  completed.
                </Fragment>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.manageEligibilityModal}
              toggle={this.toggleEligibilityModal}
              size="xl"
            >
              <ModalHeader toggle={this.toggleEligibilityModal}>
                {this.props.t("EligibilityCriteria")}
              </ModalHeader>

              <ModalBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.EligibilityContent
                      ? this.state.EligibilityContent.Content
                      : "",
                  }}
                ></div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="danger"
                  className="form-second-btn"
                  onClick={() => this.toggleEligibilityModal()}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(withNamespaces()(UserProfile))
);
