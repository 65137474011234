import moment from "moment";
import { format } from "date-fns";
import { fileUpload } from "../constants/config";
import { CallService } from "../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  monthNames,
} from "../constants/defaultValues";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export const getAllowedExtensionText = (single, extension) => {
  if (single === "pdf") return "Allowed format is pdf";
  else if (extension)
    return extension.length > 1
      ? "Allowed formats are " + extension.join(", ")
      : extension.length === 1
      ? "Allowed format is " + extension
      : "";
  else return "";
};
export const getFileSizeText = (size, type) => {
  console.log("getFileSizeText", size);
  return size ? "File size should be less than " + size + type : "";
};
export const checkIfImage = (url) => {
  if (url) {
    if (typeof url === "string") {
      var value = url.split(".");
      var length = value.length;
      if (
        value[length - 1].toLowerCase().includes("png") ||
        value[length - 1].toLowerCase().includes("jpg") ||
        value[length - 1].toLowerCase().includes("jpeg")
      )
        return true;
      else return false;
    } else return false;
  } else return false;
};
export const DateFormat = (date) => {
  if (date) {
    let regex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;
    if (!date.match(regex)) {
      return "";
    } else {
      var dateParts = date.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return dateObject.getTime() / 1000;
    }
  }
};
export const AddressFormat = (Address) => {
  var parser = new DOMParser();
  return parser.parseFromString(
    (Address.AddressLine1 ? Address.AddressLine1 : "") +
      (Address.AddressLine2 ? ", " + Address.AddressLine2 : "") +
      (Address.AddressLine3 ? ", " + Address.AddressLine3 : "") +
      (Address.TalukItem
        ? ", " + Address.TalukItem.Name
        : Address.OtherTaluk
        ? ", " + Address.OtherTaluk
        : "") +
      (Address.DistrictItem
        ? ", " + Address.DistrictItem.Name
        : Address.OtherDistrict
        ? ", " + Address.OtherDistrict
        : "") +
      (Address.StateItem
        ? ", " + Address.StateItem.Name
        : Address.DistrictItem
        ? `, Tamil Nadu`
        : "") +
      (Address.Pincode ? ", " + Address.Pincode : ""),
    "text/html"
  ).body.textContent;
};

export const getDateTimeString = (d, time) => {
  var dateObj = new Date(parseInt(d * 1000));
  var month = padValue(dateObj.getMonth() + 1);
  var day = padValue(dateObj.getDate());
  var year = padValue(dateObj.getFullYear());
  var hours = padValue(dateObj.getHours());
  var minutes = padValue(dateObj.getMinutes());
  var seconds = padValue(dateObj.getSeconds());
  var AMPM = "AM";

  var iHourCheck = parseInt(hours);

  if (iHourCheck > 12) {
    AMPM = "PM";
    hours = padValue(iHourCheck - 12);
  } else if (iHourCheck === 0) {
    hours = "00";
  }

  var newdate = "";
  if (time)
    newdate =
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      AMPM;
  else newdate = day + "/" + month + "/" + year;
  return newdate;
};
function padValue(value) {
  return value < 10 ? "0" + value : value;
}
export const getDayInEnglish = (date) => {
  var Date = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  for (var i = 0; i < Date.length; i++) {
    if (date === i) {
      return Date[i];
    }
  }
};
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const ViewFile = (file) => {
  var reader = new FileReader();
  // it's onload event and you forgot (parameters)
  reader.onload = function (e) {
    var image = document.createElement("img");
    // the result image data
    image.src = e.target.result;
    document.body.appendChild(image);
  };
  // you have to declare the file loading
  reader.readAsDataURL(file);
};

export function getUtcUnix() {
  const utcDateTime = new Date(moment.utc());
  return Math.trunc(utcDateTime.getTime() / 1000);
}

export const uploadFile = (file, callback) => {
  let url = "";
  let formData = new FormData();
  formData.append("file", file);
  CallService(
    fileUpload.upload,
    MethodType.POST,
    false,
    formData,
    "",
    uploadFileResponse,
    true
  );
  function uploadFileResponse(data) {
    console.log("uploadFileResponse", data);
    if (data.result) {
      url = data?.result?.file;
      callback(url);
    } else {
      toastr.error("", "File Upload Failed");
    }
  }
};

export const SetObjectPropertyVal = (propertyName, val, object) => {
  var schema = object; // a moving reference to internal objects within obj
  var pList = propertyName.split(".");
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  schema[pList[len - 1]] = val;
};

export const ClearStorageValues = () => {
  sessionStorage.clear();
};

export const formatDate = (date, formatStr) => {
  return format(date, formatStr);
};

export const getMonthName = (monthNo) => {
  return monthNames[monthNo];
};

export const INVALID_CHARS = [
  `'`,
  `"`,
  `;`,
  `>`,
  `<`,
  `=`,
  `(`,
  `)`,
  `&`,
  `/`,
  `\\`,
];

export const INVALID_CHARS_REGEX = /[`'";><=()&/\\]/gi;

export const checkIfPdf = (url) => {
  if (url) {
    if (typeof url === "string") {
      var value = url.split(".");
      var length = value.length;
      if (value[length - 1].toLowerCase().includes("pdf")) return true;
      else return false;
    } else return false;
  } else return false;
};

export const allow_file_formats = (
  url,
  allow_formats,
  setFieldError,
  field
) => {
  if (url) {
    if (typeof url === "string") {
      var value = url.split(".")[1];
      if (allow_formats.includes(value)) return true;
      else {
        setFieldError(`${field}`, "Invalid file format");
        toastr.error("", "Invalid file format");
        return false;
      }
    }
  }
};
