import ApplicationsList from "./list";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  newapplication,
  renewalApplication,
  areaExtensionApplication,
  duplicateCertificateApplication,
  existingNotaryApplication,
} from "../../constants/config";
import { TYPES } from "../../constants/defaultValues";

export class NotaryApplications extends Component {
  render() {
    return (
      <Fragment>
        <ApplicationsList
          type={TYPES.REGISTRATION}
          title="Notary Application List"
          apis={newapplication}
        />
      </Fragment>
    );
  }
}
export class RenewalApplications extends Component {
  render() {
    return (
      <Fragment>
        <ApplicationsList
          type={TYPES.RENEWAL}
          title="Renewal Application List"
          apis={renewalApplication}
        />
      </Fragment>
    );
  }
}

export class AreaExtensionApplications extends Component {
  render() {
    return (
      <Fragment>
        <ApplicationsList
          type={TYPES.EXTENSION}
          title="Area Extension Application List"
          apis={areaExtensionApplication}
        />
      </Fragment>
    );
  }
}

export class DuplicateCertificateApplications extends Component {
  render() {
    return (
      <Fragment>
        <ApplicationsList
          type={TYPES.DUPLICATE}
          title="Duplicate Certificate Application List"
          apis={duplicateCertificateApplication}
        />
      </Fragment>
    );
  }
}
export class ExistingNotaryApplications extends Component {
  render() {
    return (
      <Fragment>
        <ApplicationsList
          type={TYPES.EXISTINGNOTARY}
          title="Existing Notary Application List"
          apis={existingNotaryApplication}
        />
      </Fragment>
    );
  }
}
export const NotaryApplicationsList = withRouter(
  withNamespaces()(NotaryApplications)
);
export const RenewalApplicationsList = withRouter(
  withNamespaces()(RenewalApplications)
);
export const AreaExtensionApplicationsList = withRouter(
  withNamespaces()(AreaExtensionApplications)
);
export const DuplicateCertificateApplicationsList = withRouter(
  withNamespaces()(DuplicateCertificateApplications)
);
export const ExistingNotaryApplicationsList = withRouter(
  withNamespaces()(ExistingNotaryApplications)
);
