import { Container } from "reactstrap";

//import Charts

// Pages Components
import React, { Component } from "react";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { Row } from "reactstrap";
import Chart from "react-google-charts";
import { Colxx } from "../../components/Common/CustomBootstrap";
import IconCard from "../../components/custom/IconCard";
import { admin } from "../../constants/config";
import { getDayInEnglish } from "../../helpers/Utils";

//import Loader from "../pages/loader";

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Count: {
        OpenCases: 0,
        TodaysHearings: 0,
        TomorrowsHearings: 0,
        HearingsInTwoDays: 0,
        Delayed: 0,
        Pending: 0,
      },
      CategoryCount: [],
      TodayDate: "",
      TomorrowDate: "",
      In2Day: "",
      TodayDateInEnglish: "",
      TomorrowDateInEnglish: "",
      In2DayInEnglish: "",
      color: "",
    };
  }
  componentDidMount() {
    if (sessionStorage.getItem("user_token")) {
      this.GetDashboardCount();
      this.GetCategoryCount();
      var today = new Date();

      var englishtmrow = new Date();
      var englishDayafterTmrow = new Date();
      this.setState({
        TodayDateInEnglish: getDayInEnglish(today.getDay()),
      });
      var DayAfterTomorrow = "";
      var dd = today.getDate();
      var TomorrowDate = englishtmrow.getDate() + 1;
      console.log(englishtmrow);
      englishtmrow.setDate(TomorrowDate);

      this.setState({
        TomorrowDateInEnglish: getDayInEnglish(englishtmrow.getDay()),
      });
      var DayafterTmrow = englishDayafterTmrow.getDate() + 2;
      englishDayafterTmrow.setDate(DayafterTmrow);

      this.setState({
        In2DayInEnglish: getDayInEnglish(englishDayafterTmrow.getDay()),
      });
      var Dayafter = today.getDate() + 2;
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (TomorrowDate < 10) {
        TomorrowDate = "0" + TomorrowDate;
      }
      if (Dayafter < 10) {
        Dayafter = "0" + Dayafter;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;
      TomorrowDate = TomorrowDate + "/" + mm + "/" + yyyy;
      DayAfterTomorrow = Dayafter + "/" + mm + "/" + yyyy;

      this.setState({
        TodayDate: today,
        TomorrowDate: TomorrowDate,
        In2Day: DayAfterTomorrow,
      });
    } else {
      this.props.history.push("/LoginHome");
    }
  }
  GetDashboardCount() {
    this.setState({
      loading: true,
    });
    CallService(
      admin.DashboradCount,
      MethodType.GET,
      false,
      "",
      "",
      this.GetDashboardCountResponse,
      true
    );
  }
  GetDashboardCountResponse = (data) => {
    this.setState({
      loading: false,
    });
    if (data.result && data.statusCode === StatusCodes.Success) {
      this.setState({
        Count: data.result,
      });
      console.log(this.state.Count);
    }
  };
  GetCategoryCount() {
    this.setState({
      loading: true,
    });
    // CallService(
    //   admin.CategoryCount,
    //   MethodType.GET,
    //   false,
    //   "",
    //   "",
    //   this.GetCategoryCountResponse,
    //   true
    // );
  }
  GetCategoryCountResponse = (data) => {
    console.log(data.result);
    this.setState({
      loading: false,
    });
    if (data.result && data.statusCode === StatusCodes.Success) {
      var Category = [];
      data.result.forEach((item) => {
        var obj = {};
        if (item.hasOwnProperty("_id") && item.hasOwnProperty("Count")) {
          let fieldName = item._id.Name.replace(" ", "");
          obj[`${fieldName}`] = item.Count;
          //obj[`ColorCode`] = item._id.ColorCode;
          Category.push(obj);
        }
      });
      var ColorCodeCategory = [];
      data.result.forEach((item) => {
        var obj = {};
        if (item.hasOwnProperty("_id") && item.hasOwnProperty("Count")) {
          obj[`color`] = item._id.ColorCode;
          ColorCodeCategory.push(obj);
        }
      });
      console.log(ColorCodeCategory);
      var colorcodeObj = {};
      for (var i = 0; i < ColorCodeCategory.length; i++) {
        colorcodeObj[i] = ColorCodeCategory[i];
      }
      console.log(colorcodeObj);
      this.setState({
        CategoryCount: Category,
        colorcodeCount: colorcodeObj,
      });
    }
  };

  gotoCaseList(status) {
    this.props.history.push("/case" + status);
  }

  handleDelayedReplies = () => {
    this.props.history.push("/case/DelayedReplies");
  };
  handlePendingAction = () => {
    this.props.history.push("/case/PendingAction");
  };
  getDashboardCategory() {
    var colorcodeCount = this.state.colorcodeCount;
    var piedata = [["Task", "Hours per Day"]];
    this.state.CategoryCount.forEach((item) => {
      for (var key in item) {
        if (item.hasOwnProperty(key)) {
          piedata.push([key, item[key]]);
        }
      }
    });
    console.log(piedata);
    // this.state.CategoryCount.forEach((items,index) => {
    //   console.log(index)
    // })
    return (
      <Chart
        height={"300px"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={piedata}
        options={{
          // title: 'My Daily Activities',
          // Just add this option
          is3D: true,
          pieSliceText: "value",
          //legend: "none",
          slices: colorcodeCount,
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
  render() {
    // let { OpenCases } = this.state.Count;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            <Row>
              <Colxx xxs="12">
                <Row className="icon-cards-row mb-2">
                  <Colxx
                    onClick={() => {
                      this.gotoCaseList("opencases");
                    }}
                    xxs="6"
                    sm="4"
                    md="3"
                    lg="3"
                    key={`icon_card_3`}
                  >
                    <IconCard
                      color="linear-gradient(0deg,#FFFF99 0,#f2f22a)"
                      title="Open Cases"
                      icon="iconsminds-letter-sent"
                      // ApplicationValue={
                      //   this.state.dashboardCountData.SeekingClarificationCount
                      // }
                      // cardColor="SeekingClarificationBackground"
                      CardStyling="OpenCaseCard"
                      value={this.state.Count ? this.state.Count.OpenCases : 0}
                    // className="mb-4"
                    />
                  </Colxx>
                  <Colxx
                    onClick={() => {
                      this.gotoCaseList("todayhearing");
                    }}
                    xxs="6"
                    sm="4"
                    md="3"
                    lg="3"
                    key={`icon_card_0`}
                  >
                    <IconCard
                      color="linear-gradient(0deg,#d0f0c0 0,#6bcd3a)"
                      title="Today's Hearing"
                      icon="iconsminds-scale"
                      // ApplicationValue={this.state.dashboardCountData.DraftCount}
                      // cardColor="DraftBackground"
                      HearingDate={
                        this.state.TodayDate +
                        " (" +
                        this.state.TodayDateInEnglish +
                        ")"
                      }
                      value={
                        this.state.Count ? this.state.Count.TodaysHearings : 0
                      }
                    //className="mb-4"
                    />
                  </Colxx>
                  <Colxx
                    onClick={() => {
                      this.gotoCaseList("tomorrowhearing");
                    }}
                    xxs="6"
                    sm="4"
                    md="3"
                    lg="3"
                    key={`icon_card_1`}
                  >
                    <IconCard
                      color="linear-gradient(0deg,#FED8B1 0,#db7c19)"
                      title="Tomorrow's Hearing"
                      icon="iconsminds-add-file"
                      // ApplicationValue={this.state.dashboardCountData.ApprovedCount}
                      // cardColor="ApprovedBackground"
                      HearingDate={
                        this.state.TomorrowDate +
                        " (" +
                        this.state.TomorrowDateInEnglish +
                        ")"
                      }
                      value={
                        this.state.Count ? this.state.Count.TomorrowHearings : 0
                      }
                    //className="mb-4"
                    />
                  </Colxx>
                  <Colxx
                    onClick={() => {
                      this.gotoCaseList("hearingin2days");
                    }}
                    xxs="6"
                    sm="4"
                    md="3"
                    lg="3"
                    key={`icon_card_2`}
                  >
                    <IconCard
                      color={"linear-gradient(0deg,#D3D3D3 0,#837b7b)"}
                      title="Hearing in 2 days"
                      icon="iconsminds-files"
                      // ApplicationValue={this.state.dashboardCountData.RejectedCount}
                      // cardColor="RejectedBackground"
                      HearingDate={
                        this.state.In2Day +
                        " (" +
                        this.state.In2DayInEnglish +
                        ")"
                      }
                      value={
                        this.state.Count
                          ? this.state.Count.After2DaysHearings
                          : 0
                      }
                    //className="mb-4"
                    />
                  </Colxx>
                </Row>
                <br></br>
                <Row>
                  <Colxx xxs="12">
                    <Row className="icon-cards-row mb-2">
                      <Colxx
                        onClick={this.handleDelayedReplies}
                        xxs="6"
                        key={`icon_card_3`}
                      >
                        <IconCard
                          color="linear-gradient(0deg,#FFCCCB 0,#ea7e7c)"
                          title="Delayed Replies"
                          icon="iconsminds-letter-sent"
                          // ApplicationValue={
                          //   this.state.dashboardCountData.SeekingClarificationCount
                          // }
                          // cardColor="SeekingClarificationBackground"
                          CardStyling="Delayed Replies"
                          value={
                            this.state.Count
                              ? this.state.Count.DelayedReplies
                              : 0
                          }
                        // className="mb-4"
                        />
                      </Colxx>
                      <Colxx
                        onClick={this.handlePendingAction}
                        xxs="6"
                        key={`icon_card_4`}
                      >
                        <IconCard
                          color="linear-gradient(0deg,#ADD8E6 0,#598ea0)"
                          title="My Action Pending"
                          icon="iconsminds-letter-sent"
                          // ApplicationValue={
                          //   this.state.dashboardCountData.SeekingClarificationCount
                          // }
                          // cardColor="SeekingClarificationBackground"
                          CardStyling="Pending my action"
                          value={
                            this.state.Count
                              ? this.state.Count.PendingActions
                              : 0
                          }
                        // className="mb-4"
                        />
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
                <br />
                <Row>
                  <Colxx xxs="6" sm="6" lg="6" md="6">
                    <Row>
                      <Colxx xxs="4" sm="4" lg="4" md="4"></Colxx>
                      <Colxx xxs="3" sm="3" lg="3" md="3">
                        <label>Case Status</label>
                      </Colxx>
                      <Colxx xxs="4" sm="4" lg="4" md="4"></Colxx>
                    </Row>
                    <Chart
                      height={"300px"}
                      chartType="PieChart"
                      // loader={<div>Loading Chart</div>}
                      data={[
                        ["Task", "Hours per Day"],
                        [
                          "Open Cases",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts.Open
                            : 0,
                        ],
                        [
                          "Pending with Officers",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts
                              .PendingWithOfficer
                            : 0,
                        ],
                        [
                          "Adjourned Cases",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts.Adjourned
                            : 0,
                        ],
                        [
                          "Dismissed Case",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts.CaseDismissed
                            : 0,
                        ],
                        [
                          "JudgementPassed Cases",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts.JudgementPassed
                            : 0,
                        ],
                        [
                          "Transferred Case",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts.CaseTransferred
                            : 0,
                        ],
                        [
                          "JudgementReserved Cases",
                          this.state.Count.CaseStatusCounts
                            ? this.state.Count.CaseStatusCounts
                              .JudgementReserved
                            : 0,
                        ],
                      ]}
                      options={{
                        // title: 'My Daily Activities',
                        // Just add this option
                        is3D: true,
                        pieSliceText: "value",
                        //legend: "none",
                        slices: {
                          0: { color: "orange" },
                          1: { color: "#afce04" },
                          2: { color: "#102a65" },
                          3: { color: "#0398fc" },
                          4: { color: "#f8fc03" },
                          5: { color: "#fc037b" },
                          6: { color: "#ad03fc" },
                          7: { color: "#fc0314" },
                          8: {
                            color: "#00C851",
                          },
                        },
                      }}
                      rootProps={{ "data-testid": "2" }}
                    />
                  </Colxx>

                  <Colxx xxs="6" sm="6" lg="6" md="6">
                    {/* {
                  JSON.stringify(
                    
                      this.state.CategoryCount.map(category=>(
                        [category.Name,category.Count]
                        ))
                    
                  )
                } */}

                    <Row>
                      <Colxx xxs="4" sm="4" lg="4" md="4"></Colxx>
                      <Colxx xxs="3" sm="3" lg="3" md="3">
                        <label>Case Cateory</label>
                      </Colxx>
                      <Colxx xxs="4" sm="4" lg="4" md="4"></Colxx>
                    </Row>
                    <Chart
                      height={"300px"}
                      chartType="PieChart"
                      // loader={<div>Loading Chart</div>}
                      data={[
                        ["Task", "Hours per Day"],
                        [
                          "Service Cases",
                          this.state.Count.CaseCategoryCounts
                            ? this.state.Count.CaseCategoryCounts
                              .Servicematterrelatedcases
                            : 0,
                        ],
                        [
                          "Industry Cases",
                          this.state.Count.CaseCategoryCounts
                            ? this.state.Count.CaseCategoryCounts
                              .Industriesrelatedcases
                            : 0,
                        ],
                        [
                          "Non-Industry Cases",
                          this.state.Count.CaseCategoryCounts
                            ? this.state.Count.CaseCategoryCounts
                              .Non_industriesrelatedcases
                            : 0,
                        ],
                      ]}
                      options={{
                        // title: 'My Daily Activities',
                        // Just add this option
                        is3D: true,
                        pieSliceText: "value",
                        //legend: "none",
                        slices: {
                          0: { color: "orange" },
                          1: { color: "#afce04" },
                          2: { color: "#102a65" },
                          3: { color: "#0398fc" },
                          4: { color: "#f8fc03" },
                          5: { color: "#fc037b" },
                          6: { color: "#ad03fc" },
                          7: { color: "#fc0314" },
                          8: {
                            color: "#00C851",
                          },
                        },
                      }}
                      rootProps={{ "data-testid": "2" }}
                    />
                  </Colxx>
                </Row>
              </Colxx>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Dashboard);
