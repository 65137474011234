import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Button,
  Spinner,
  Label,
  FormGroup,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { Formik, Form, Field } from "formik";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { authenctication } from "../../constants/config";

// action
import { userForgetPassword } from "../../store/actions";
import { forgetPassword } from "../../helpers/validations";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import logoImg from "../../assets/images/logo.png";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
import {
  Base64ToUint8Array,
  decryptBox,
  encryptBox,
  formatEncryptedField,
  Uint8ArrayToBase64,
} from "../../helpers/encryption";
nacl.util = naclUtil;

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      time: {},
      seconds: 0,
      captchaVerified: false,

      ForgetPassword: {
        MobileNumbe: "",
        OTP: "",
        Password: "",
      },
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);

    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    // this.props.apiError("");
    // this.props.registerUserFailed("");
    setTimeout(() => {
      this.getSeed();
    }, 1000);
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.timer = 0;
    }
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState(
        {
          ServerPublicKey: data.result.ServerPublicKey,
          SeedId: data.result._id,
        },
        () => {
          this.getCaptcha();
        }
      );
    }
  };
  getCaptcha() {
    const { publicKey: clientPublicKey, secretKey: clientPrivateKey } =
      nacl.box.keyPair();

    const clientPublicKeyText = Uint8ArrayToBase64(clientPublicKey);
    const clientPrivateKeyText = Uint8ArrayToBase64(clientPrivateKey);
    CallService(
      authenctication.GetCaptcha,
      MethodType.POST,
      false,
      { ClientPublicKey: clientPublicKeyText },
      "",
      this.getCaptchaResponse
    );
    this.setState({
      captchaClientPublicKey: clientPublicKeyText,
      captchaClientPrivateKey: clientPrivateKeyText,
    });
    // const { publicKey: clientPublicKey } = nacl.box.keyPair();

    // const clientPublicKeyText = Uint8ArrayToBase64(clientPublicKey);
    // CallService(
    //   authenctication.GetCaptcha,
    //   MethodType.POST,
    //   false,
    //   { ClientPublicKey: clientPublicKeyText },
    //   "",
    //   this.getCaptchaResponse
    // );
  }
  getCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      const captcha = data.result.Captcha;
      const nonce = data.result.Nonce;
      const serverPublicKey = data.result.ServerPublicKey;
      const decipherText = decryptBox(
        captcha,
        nonce,
        serverPublicKey,
        this.state.captchaClientPrivateKey
      );
      var CaptchaDetails = {
        CaptchaId: data.result._id,
        CaptchaServerPublicKey: serverPublicKey,
        Captcha: decipherText,
      };
      this.setState(
        {
          CaptchaDetails: CaptchaDetails,
          Captcha: decipherText,
        },
        () => {
          this.createCaptcha();
        }
      );
    }
  };

  createCaptcha() {
    var canvas = this.canvasRef.current;
    if (canvas) {
      var context = canvas.getContext("2d");
      if (context) {
        context.clearRect(0, 0, 100, 100);
        var actualCaptcha = "";
        if (this.state.Captcha) {
          actualCaptcha = this.state.Captcha;
        } else {
          var charsArray = "0123456789";
          var lengthOtp = 6;
          var captcha = [];
          for (var i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) === -1)
              captcha.push(charsArray[index]);
            else i--;
          }
          actualCaptcha = captcha.join("");
        }
        context.font = "18px Georgia";
        context.fillText(actualCaptcha, 0, 30);
        this.setState({
          captchatxt: actualCaptcha,
        });
      }
    }
  }
  onUserLogin = (values) => {
    console.log(values);

    if (values.captcha === this.state.captchatxt) {
      if (this.state.captchaVerified) {
        if (!this.state.loading) {
          if (values.Password === values.ConfirmPassword) {
            if (values.MobileNumber !== "" && values.OTP !== "") {
              this.setState({ loading: true, captchaVerified: false });
              this.AuthenticateUser(values);
            }
          } else {
            toastr.error(
              "",
              "Your password and confirmation password do not match"
            );
          }
        }
      } else {
        CallService(
          authenctication.HandleCaptcha,
          MethodType.POST,
          false,
          {
            CaptchaText: values.captcha,
            CaptchaId:
              this.state.CaptchaDetails && this.state.CaptchaDetails.CaptchaId,
          },
          "",
          this.HandleCaptchaResponse
        );
      }
    } else {
      this.setState({ loading: false });
      toastr.error("", "Please Check captcha");
    }
  };

  HandleCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        "Captcha verified successfully. Please login to continue."
      );
      this.setState({
        captchaVerified: true,
      });
    }
  };
  AuthenticateUser = (values) => {
    var apis;
    this.props.location.pathname === "/interview_forgot_password"
      ? (apis = authenctication.InterviewResetPassword)
      : this.props.location.pathname === "/advocate_forgot_password"
      ? (apis = authenctication.AdvocateResetPassword)
      : this.props.location.pathname === "/official_forgot_password"
      ? (apis = authenctication.OfficalResetPassword)
      : (apis = authenctication.OfficalResetPassword);

    sessionStorage.setItem("MobileNumber", values.MobileNumber);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);

    console.log(values);

    var PasswordEncrpt = values;

    const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
    const password = values.Password;
    const { cipherText, nonceText, clientPublicKeyText } = encryptBox(
      password,
      serverPublicKey
    );

    PasswordEncrpt.Password = formatEncryptedField(
      this.state.SeedId,
      cipherText,
      nonceText,
      clientPublicKeyText
    );
    PasswordEncrpt.CaptchaId =
      this.state.CaptchaDetails && this.state.CaptchaDetails.CaptchaId;

    delete PasswordEncrpt.ConfirmPassword;
    delete PasswordEncrpt.captcha;
    CallService(
      apis,
      MethodType.POST,
      false,
      PasswordEncrpt,
      "",
      this.AuthenticateUserResponse
    );
  };

  // onKeyUpValue(event) {
  //   return false;
  // }

  AuthenticateUserResponse = (data) => {
    this.setState({ loading: false });
    if (data.statusCode === StatusCodes.Success) {
      this.props.location.pathname === "/interview_forgot_password"
        ? this.props.history.push("/interviewboardlogin")
        : this.props.location.pathname === "/advocate_forgot_password"
        ? this.props.history.push("/advocatelogin")
        : this.props.location.pathname === "/official_forgot_password"
        ? this.props.history.push("/officiallogin")
        : this.props.history.push("/advocatelogin");
      toastr.success("", "Password updated sucessfully");

      // window.location.reload();
    } else {
      toastr.warning("", "Invalid OTP or Mobile Number");
    }
  };
  getOtp = (val) => {
    if (val.MobileNumber) {
      let mobileRegex = /^\d{10}$/;
      let emailRegux = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/;
      var mobileno = val.MobileNumber.toString();
      if (mobileno.match(mobileRegex) || mobileno.match(emailRegux)) {
        this.setState(
          {
            loading: false,
            seconds: 120,
            SendOtp: true,
          },
          () => {
            this.startTimer();
          }
        );
        var values = {};
        values.MobileNumber = val.MobileNumber;
        values.Type = "RESET_PASSWORD";
        CallService(
          authenctication.getOTP,
          MethodType.POST,
          false,
          values,
          "",
          this.OtpResponse
        );
      } else toastr.warning("", "Invalid mobile number / email id");
    }
  };
  resendOTP = (MobileNumber) => {
    if (MobileNumber) {
      let mobileRegex = /^\d{10}$/;
      var mobileno = MobileNumber.toString();
      if (mobileno.match(mobileRegex)) {
        this.setState({ loading: false, seconds: 120, SendOtp: true }, () => {
          this.startTimer();
        });
        CallService(
          authenctication.getOTP,
          MethodType.POST,
          false,
          { MobileNumber: MobileNumber },
          "",
          this.OtpResponse
        );
      } else toastr.warning("", "Not a valid number");
    } else toastr.warning("", "Not a valid number");
  };
  OtpResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Otp will be sent to user if registered");

      this.setState({ loading: false }, () => this.createCaptcha());
    } else {
      toastr.error("", data.stringResult);
    }
  };
  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  render() {
    const initialValues = {
      MobileNumber: "",
      Password: "",
      ConfirmPassword: "",
    };
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <br></br>
            <br></br>
            <br></br>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card
                  style={{
                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                  }}
                  className="mt-5 overflow-hidden"
                >
                  <div className="bg-soft-primary">
                    <Row>
                      <Col>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            {this.props.t(
                              "Government of Tamil Nadu - Law Department"
                            )}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="75"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="p-2">
                      <h4
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Label>{this.props.t("Forgot Password")}</Label>
                      </h4>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={forgetPassword}
                        onSubmit={this.onUserLogin}
                        validateOnBlur={false}
                        validateOnChange={true}
                      >
                        {({ values, touched, errors, setFieldValue }) => (
                          <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-float-label">
                              <Label>
                                {this.props.t("Mobile Number / Email ID")}
                              </Label>
                              <Field
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                name="MobileNumber"
                                placeholder={this.props.t(
                                  "Mobile Number / Email ID"
                                )}
                                //  onKeyUp={this.onKeyUpValue.bind(this)}
                              />
                              {/* {errors.MobileNumber && touched.MobileNumber ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MobileNumber}
                                </div>
                              ) : (
                                ""
                              )} */}
                            </FormGroup>

                            {!this.state.SendOtp ? (
                              <Fragment>
                                <div className="mt-4">
                                  <button
                                    color="primary"
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    // className={`btn-shadow btn-multiple-state`}
                                    size="lg"
                                    onClick={() => this.getOtp(values)}
                                  >
                                    {this.props.t("Send OTP")}
                                  </button>
                                </div>
                              </Fragment>
                            ) : (
                              ""
                            )}

                            {this.state.SendOtp ? (
                              this.state.seconds > 0 ? (
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#007bff",
                                    float: "right",
                                  }}
                                >
                                  {this.state.time && this.state.time.m < 10
                                    ? "0"
                                    : ""}
                                  {this.state.time && this.state.time.m}:{" "}
                                  {this.state.time && this.state.time.s < 10
                                    ? "0"
                                    : ""}
                                  {this.state.time && this.state.time.s}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#007bff",
                                    textDecoration: "underline",
                                    float: "right",
                                    zIndex: 3,
                                  }}
                                  onClick={() =>
                                    this.resendOTP(values.MobileNumber)
                                  }
                                >
                                  {this.props.t("Resend OTP")}
                                </span>
                              )
                            ) : (
                              ""
                            )}
                            <br />

                            {this.state.SendOtp ? (
                              <Fragment>
                                <FormGroup className="form-group has-float-label">
                                  <Label>OTP</Label>{" "}
                                  <span style={{ float: "right" }}>
                                    Please verify your OTP
                                  </span>
                                  <Field
                                    autoComplete="off"
                                    className="form-control"
                                    type="text"
                                    name="OTP"
                                    onKeyPress={(event) => {
                                      if (INVALID_CHARS.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      event.target.value =
                                        event.target.value &&
                                        event.target.value.replace(
                                          INVALID_CHARS_REGEX,
                                          ""
                                        );
                                      setFieldValue("OTP", event.target.value);
                                    }}
                                  />
                                  {errors.OTP && touched.OTP ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.OTP}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>

                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Password")}</Label>
                                  <Field
                                    autoComplete="off"
                                    type="password"
                                    className="form-control"
                                    name="Password"
                                    placeholder={this.props.t("Password")}
                                  />
                                  {errors.Password && touched.Password ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.Password}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Confirm Password")}
                                  </Label>
                                  <Field
                                    autoComplete="off"
                                    type="password"
                                    className="form-control"
                                    name="ConfirmPassword"
                                    placeholder={this.props.t(
                                      "Confirm Password"
                                    )}
                                  />
                                  {errors.ConfirmPassword &&
                                  touched.ConfirmPassword ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.ConfirmPassword}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                                <div className="form-group">
                                  <label style={{ marginBottom: "-5%" }}>
                                    Type the number shown in the box below
                                  </label>
                                  <Row>
                                    <div className={"col-6"}>
                                      <label className="captchaformcanvas">
                                        <canvas
                                          className="MobileCanvas"
                                          width="150"
                                          height="33"
                                          ref={this.canvasRef}
                                        />
                                        <div className="captacharefresh">
                                          <i
                                            onClick={() => {
                                              this.getCaptcha();
                                            }}
                                            className="mdi mdi-refresh"
                                          ></i>
                                        </div>
                                      </label>
                                    </div>
                                    <div className={"col-6 mt-2"}>
                                      <input
                                        className="form-control captchaform"
                                        type="text"
                                        autoComplete="off"
                                        name="captcha"
                                        required
                                        onChange={(e) => {
                                          setFieldValue(
                                            "captcha",
                                            e.target.value
                                          );
                                        }}
                                        // validate={this.validatePassword}
                                      />
                                    </div>
                                  </Row>
                                </div>

                                <div className="mt-4">
                                  <button
                                    type="submit"
                                    className={`btn btn-primary btn-block waves-effect waves-light ${
                                      this.state.loading ? "disabled" : ""
                                    }`}
                                  >
                                    {this.state.loading ? (
                                      <span>
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : this.state.captchaVerified ? (
                                      this.props.t("Login")
                                    ) : (
                                      this.props.t("Verify")
                                    )}
                                  </button>
                                  {/* <button
                                    type="submit"
                                    color="primary"
                                    className={`btn btn-primary btn-block waves-effect waves-light ${
                                      this.state.loading ? "show-spinner" : ""
                                    }`}
                                    size="lg"
                                  >
                                    <span className="spinner d-inline-block">
                                      <span className="bounce1" />
                                      <span className="bounce2" />
                                      <span className="bounce3" />
                                    </span>
                                    <span className="label">
                                      {this.props.t("Login")}
                                    </span>
                                  </button> */}
                                </div>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="/advocatelogin"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  {/* <p>
                    © {new Date().getFullYear()} Law Admin. Crafted with{" "}
                    <a
                      href="https://vertace.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Vertace
                    </a>
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(
    withNamespaces()(ForgetPasswordPage)
  )
);
