import React, { Component, Fragment } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType } from "../../constants/defaultValues";
import {
  renewalApplication,
  duplicateCertificateApplication,
  areaExtensionApplication,
  newapplication,
} from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import ListPageHeader from "../../components/custom/ListPageHeader";
import ViewDetails from "../../pages/ViewDetails/modal";
import PaymentForm from "../../pages/PaymentGateway/PaymentForm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
import Logo from "../../assets/images/logo.png";
import LogoWaterMark from "../../assets/images/tn_water_mark.png";

class AdvocateApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      tabs: [],
      activeFirstTab: 1,
      data: [],
      Language: sessionStorage.getItem("lng"),
      CurrentList:
        this.props.location.pathname === "/renewal"
          ? "Renewal Application List"
          : this.props.location.pathname === "/areaextension"
          ? "Area Extension Application List"
          : this.props.location.pathname === "/duplicate"
          ? "Duplicate Certificate Application List"
          : "Application List",
      columns: [
        {
          width: "20%",
          name: <strong>{this.props.t("Sub - Registration Number")}</strong>,
          selector: "SRApplicationNo",
          sortable: true,
          cell: (row) => (
            <span>{row.SRApplicationNo ? row.SRApplicationNo : ""}</span>
          ),
        },
        {
          width: "20%",
          name: <strong>{this.props.t("Application Number")}</strong>,
          selector: "ApplicationNo",
          sortable: true,
          cell: (row) => (
            <span>{row.ApplicationNo ? row.ApplicationNo : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: true,
          cell: (row) => <span>{row.Applicant ? row.Applicant.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name(in tamil)",
          sortable: true,
          cell: (row) => (
            <span>{row.Applicant ? row.Applicant.TamilName : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("District")}</strong>,
          selector: "District",
          sortable: true,
          cell: (row) => (
            <span>
              {row.District
                ? this.state.Language === "ta"
                  ? row.District.TamilName
                  : row.District.Name
                : ""}
            </span>
          ),
        },

        {
          name: <strong>{this.props.t("Taluks")}</strong>,
          selector: "Taluks",
          sortable: true,
          cell: (row) => (
            <span>
              {row
                ? row.Taluks
                  ? row.Taluks.map((t) => {
                      return this.state.Language === "ta"
                        ? t.TamilName
                        : t.Name;
                    }).join(",  ")
                  : "-"
                : "-"}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Submitted On")}</strong>,
          selector: "SubmittedOn",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn
                ? moment(new Date(row.SubmittedOn * 1000)).format(
                    "DD/MM/YYYY hh:mm a"
                  )
                : ""}
            </span>
          ),
        },
        {
          widht: "8%",
          name: <strong>{this.props.t("View")}</strong>,
          selector: "View",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={() => this.toggleViewModal(row)}
              >
                View
              </Button>
            </Row>
          ),
        },
        {
          width: "20%",
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "Status",
          sortable: true,
          cell: (row) => (
            <span>
              {row.CanTakeAction && row.Actions.length ? (
                row.Actions.map((option) => {
                  if (option.ButtonName === "Make Payment")
                    return (
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => this.PaymentInitiate(row)}
                      >
                        {this.props.t("Make Payment")}
                      </Button>
                    );
                })
              ) : (
                <div>
                  {row.Status === "Payment Pending" ? (
                    <Button
                      size="sm"
                      outline
                      color="primary"
                      onClick={() => this.PaymentInitiate(row)}
                    >
                      {this.props.t("Make Payment")}
                    </Button>
                  ) : (
                    row.Status
                  )}
                </div>
              )}
            </span>
          ),
        },
      ],
      reqBody: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
    };
  }
  componentDidMount() {
    this.GetAll();
    this.setTab(1);
  }
  toggleViewModal = (row) => {
    if (row) {
      var rowValues = row;
      rowValues.GenerateCertificate = "";
      this.setState({
        viewValues: rowValues,
        activeFirstTab: 1,
        viewModal: !this.state.viewModal,
      });
    } else {
      this.setState({
        viewModal: !this.state.viewModal,
      });
    }
  };

  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Applicant"),
        },
        {
          id: 2,
          Name: this.props.t("Personal"),
        },
        {
          id: 3,
          Name: this.props.t("Professional"),
        },
        {
          id: 4,
          Name: this.props.t("Qualification"),
        },
        {
          id: 5,
          Name: this.props.t("Residence Address"),
        },
        {
          id: 6,
          Name: this.props.t("Professional Address"),
        },
      ],
    });
  };
  GetAll() {
    this.setState({
      isLoading: true,
    });
    const { reqBody } = this.state;

    var apis;
    this.props.location.pathname === "/renewal"
      ? (apis = renewalApplication)
      : this.props.location.pathname === "/areaextension"
      ? (apis = areaExtensionApplication)
      : this.props.location.pathname === "/duplicate"
      ? (apis = duplicateCertificateApplication)
      : (apis = newapplication);

    CallService(
      apis.GetAll,
      MethodType.POST,
      false,
      reqBody,
      "",
      this.GetAllResponse
    );
  }

  GetAllResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        data: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  handleSubmit = (values) => {
    if (!this.state.buttonAction) {
      this.setState({
        buttonAction: true,
      });
      this.manage(values);
    }
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        reqBody: {
          ...this.state.reqBody,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAll();
      }
    );
  };

  HandleRowClicked = (row) => {
    if (row) {
      this.setState({
        datum: row,
        manageModal: !this.state.manageModal,
      });
    }
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        reqBody: {
          ...this.state.reqBody,
          pageNumber: page,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.reqBody.searchString === "" ||
          this.state.reqBody.searchString.length > 2
        ) {
          this.GetAll();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      datum: row,
    });
  };
  PaymentInitiate = (row) => {
    var apis;

    if (this.state.CurrentList === "Renewal Application List") {
      apis = renewalApplication.GetMaking;
    } else if (
      this.state.CurrentList === "Duplicate Certificate Application List"
    ) {
      apis = duplicateCertificateApplication.GetMaking;
    } else if (this.state.CurrentList === "Area Extension Application List") {
      apis = areaExtensionApplication.GetMaking;
    }
    CallService(
      apis,
      MethodType.POST,
      false,
      { ApplicationId: row._id },
      "",
      this.PaymentInitiateResponse
    );
  };

  PaymentInitiateResponse = (data) => {
    if (data) {
      this.setState({
        InitiateResponse: data.result,
        MakingPaymentModal: true,
      });
    }
  };

  SubmitPayment = (message) => {
    let formData = new FormData();
    formData.append("msg", message);
    formData.append("isFromUrl", true);
    CallService(
      newapplication.GetPaymentResponse,
      MethodType.POST,
      false,
      formData,
      // { ApplicationId: this.state.AreaExtensionId },
      // { msg: message, isFromUrl: true },
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentStatus: data.result,
      });
    }
  };
  confirmMakingPayment = () => {
    this.setState({
      MakingPaymentModal: !this.state.MakingPaymentModal,
    });
  };
  DownloadFormAsPDF = () => {
    const advocate = this.state.viewValues;
    const payment = this.state.viewValues.PaymentTransaction;
    const paymentAmt = this.state.viewValues.Payment;

    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(15);
    y = y + 20;
    const logoWidth = 80; // Adjust as needed
    const logoHeight = 80; // Adjust as needed

    const logoX = (pdf.internal.pageSize.width - logoWidth) / 2; // Center horizontally
    const logoY = (pdf.internal.pageSize.height - logoHeight) / 2; // Center vertically

    pdf.addImage(Logo, "PNG", logoX, 0, logoWidth, 0);

    // Watermark logo
    pdf.addImage(
      LogoWaterMark,
      "PNG",
      (pdf.internal.pageSize.width - 400) / 2,
      (pdf.internal.pageSize.height - 400) / 2,
      400,
      400
    );

    y = y + 55;
    pdf.setFont("helvetica", "bold");
    pdf.text(
      "Government of Tamil Nadu - Law Department",
      230,
      y,
      null,
      null,
      "center"
    );
    pdf.setFont("helvetica", "bold");
    y = y + 20;

    pdf.text(
      "Web application for the Online Notary Appointment / Renewal of Practice",
      230,
      y,
      null,
      null,
      "center"
    );

    pdf.setFontSize(11);
    y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("Payment Transaction Receipt", 230, y, null, null, "center");
    pdf.line(175, y + 5, 290, y + 5);
    y = y + 25;
    pdf.setLineDashPattern([1.5]);
    pdf.setFont("helvetica", "bold");
    pdf.text("Status :-", 350, y, null, null, "right");
    pdf.setFont("helvetica", "normal");
    pdf.text(paymentAmt?.Status || "-", 400, y, null, null, "right");
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Application Number", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(this.state?.viewValues?.ApplicationNo || "-", 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Applicant Name", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(this.state.viewValues.Applicant.Name || "-", 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Application", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      paymentAmt?.ApplicationPath
        ? paymentAmt?.ApplicationPath?.replace(/([A-Z])/g, " $1")?.trim()
        : "-",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("District", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      typeof advocate.District === "object"
        ? advocate.District.Name
        : advocate.District,
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("Order Id", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(payment?.OrderId?.toString() || "-", 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Transaction Receipt Number", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      payment?.TransactionReceiptNumber?.toString() || "-",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Payment Amount", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(paymentAmt?.Amount?.toString() || "-", 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Transaction Date & Time", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      payment
        ? formatDate(
            fromUnixTime(payment.TransactionDateTime),
            datePickerFormat
          )
        : "",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;
    if (payment?.AdditionalInfo1) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 1", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment?.AdditionalInfo1 || "-", 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment?.AdditionalInfo2) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 2", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment?.AdditionalInfo2 || "-", 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment?.AdditionalInfo3) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 3", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment?.AdditionalInfo3 || "-", 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment?.AdditionalInfo4) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 4", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment?.AdditionalInfo4 || "-", 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment?.AdditionalInfo5) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 5", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment?.AdditionalInfo5 || "-", 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }

    // Add additional documents to the last page


    pdf.save(
      payment
        ? payment?.TransactionReceiptNumber + ".pdf"
        : "PaymentReceipt.pdf"
    );
  };

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4">
              <CardBody>
                <ListPageHeader
                  heading={`${this.props.t("Home")}.${this.props.t(
                    this.state.CurrentList
                  )}`}
                  onTextChange={this.SearchQueryChanged}
                  buttonClick={this.addBtnClick}
                  showButton={false}
                  searchValue={this.state.reqBody.searchString}
                />
                <ListPage
                  columns={this.state.columns}
                  data={this.state.data}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>
          </div>
          <Modal
            isOpen={this.state.MakingPaymentModal}
            toggle={() => this.confirmMakingPayment()}
          >
            <ModalHeader toggle={() => this.confirmMakingPayment()}>
              {this.props.t("Confirm Payment Applications")}
            </ModalHeader>
            <ModalBody>
              <Fragment>
                <Row className="mb-4">
                  <Label className="av-label ml-3">
                    {this.state.InitiateResponse &&
                    this.state.InitiateResponse.pmtData &&
                    this.state.InitiateResponse.pmtData.transactionAmount !==
                      "null" ? (
                      <h5 className="pr-2 pt-2">
                        Are you sure you want to make payment of Rs.
                        {this.state.InitiateResponse &&
                          this.state.InitiateResponse.pmtData &&
                          this.state.InitiateResponse.pmtData
                            .transactionAmount}{" "}
                        ?
                      </h5>
                    ) : (
                      <h5 className="pr-2 pt-2">
                        Are you sure you want to make payment ?
                      </h5>
                    )}
                  </Label>
                </Row>
              </Fragment>
            </ModalBody>
            <ModalFooter>
              <FormGroup className="float-sm-right ">
                <PaymentForm
                  SubmitPayment={this.SubmitPayment}
                  InitiateResponse={this.state.InitiateResponse}
                />
              </FormGroup>
            </ModalFooter>
          </Modal>

          <Modal
            size="xl"
            isOpen={this.state.viewModal}
            toggle={this.toggleViewModal}
          >
            <ModalHeader toggle={this.toggleViewModal}>
              {this.props.t("View Details")}
            </ModalHeader>
            <ModalBody>
              {this.state.viewValues &&
              this.state.viewValues.PaymentTransaction &&
              this.state.viewValues?.Payment?.Status === "Completed" ? (
                <Button
                  color="success"
                  className="mr-2 ml-2 float-sm-right"
                  onClick={() => this.DownloadFormAsPDF()}
                >
                  {this.props.t("View Payment Receipt")}
                </Button>
              ) : (
                ""
              )}
              <br></br>
              <br></br>
              <br></br>
              <Fragment>
                {<ViewDetails viewRowId={this.state.viewValues} />}
              </Fragment>
            </ModalBody>

            <ModalFooter>
              <Button
                color="danger"
                className="mr-2"
                onClick={() => this.toggleViewModal()}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Fragment>
    );
  }
}
export class RenewalApplicationList extends Component {
  render() {
    return (
      <Fragment>
        <AdvocateApplication title="Renewal Application List" />
      </Fragment>
    );
  }
}
export class DuplicateCertificateList extends Component {
  render() {
    return (
      <Fragment>
        <AdvocateApplication title="Duplicate Certificate Application List" />
      </Fragment>
    );
  }
}

export class AreaExtensionList extends Component {
  render() {
    return (
      <Fragment>
        <AdvocateApplication title="Area Extension Application List" />
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(AdvocateApplication));
