export const menu = [
  {
    id: "Home",
    icon: "bx bx-home",
    label: "Home",
    to: "/home",
    badge: "no",
  },
  {
    id: "About Us",
    icon: "mdi mdi-book-multiple",
    label: "About Us",
    to: "/about",
    badge: "no",
  },
  {
    id: "FAQ",
    icon: "bx bxs-user-badge",
    label: "FAQ",
    to: "/faq",
    badge: "no",
  },
  {
    id: "Contact Us",
    icon: "bx bx-phone",
    label: "Contact Us",
    to: "/contact",
    badge: "no",
  },
];
export const loginMenu = [
  {
    id: "Login",
    icon: "bx bxs-lock",
    label: "Login",
    class: "float-right",
    to: "#/",
    subs: [
      {
        id: "advocate",
        icon: "bx bx-network-chart",
        label: "Login as Applicant / Notary",
        to: "/advocatelogin",
        badge: "no",
      },
      {
        id: "Interview Board",
        icon: "mdi mdi-notebook-outline",
        label: "Login as Interview Board",
        to: "/interviewboardlogin",
        badge: "no",
      },
      {
        id: "Law Admin",
        icon: "mdi mdi-notebook-outline",
        label: "Login as Law Admin",
        to: "/lawadminlogin",
        badge: "no",
      },
      {
        id: "Competent Authority",
        icon: "mdi mdi-notebook-outline",
        label: "Login as Competent Authority",
        to: "/competentauthoritylogin",
        badge: "no",
      },
    ],
  },
];
export const superAdminMenu = [
  {
    id: "notaryapplication",
    icon: "bx bx-home",
    label: "Notary Application",
    to: "/notary_applications",
    badge: "no",
  },

  {
    id: "InterviewBoard",
    icon: "mdi mdi-notebook-outline",
    label: "Interview Board",
    to: "/interviewboard",
  },

  {
    id: "User",
    icon: "bx bx-user",
    label: "Competent Authority",
    to: "/user",
  },
  {
    id: "AnnualReturn",
    icon: "bx bxs-coin-stack",
    label: "Annual Return",
    to: "/annualreturn",
  },
  {
    id: "Master",
    icon: "bx bxs-user-badge",
    label: "Master",
    to: "#/",
    subs: [
      // {
      //   id: "LevelArea",
      //   icon: "bx bx-network-chart",
      //   label: "Level Area",
      //   to: "/level",
      //   badge: "no",
      // },
      // {
      //   id: "Designation",
      //   icon: "mdi mdi-notebook-outline",
      //   label: "Designation",
      //   to: "/Designation",
      //   badge: "no",
      // },
      {
        id: "WorkType",
        icon: "mdi mdi-book-multiple",
        label: "Work Type",
        to: "/worktype",
        badge: "no",
      },

      {
        id: "CMS",
        icon: "bx bx-spreadsheet",
        label: "CMS",
        to: "/cms",
      },
    ],
  },
  {
    id: "Settings",
    icon: "bx bx-cog",
    label: "Settings",
    to: "/settings",
  },
  {
    id: "Advocate",
    icon: "bx bxs-user",
    label: "Applicant",
    to: "/advocate",
  },
];

// eslint-disable-next-line no-sparse-arrays
export const advocateMenu = [
  {
    id: "Dashboard",
    icon: "bx bx-home",
    label: "Dashboard",
    to: "/dashboard",
    badge: "no",
  },
  {
    id: "NewNotaryApplication",
    icon: "bx bx-home",
    label: "New Notary Application",
    to: "/new_notary_application",
    badge: "no",
  },
  {
    id: "NotaryRenewal",
    icon: "bx bx-home",
    label: "Notary Renewal",
    to: "/notary_renewal",
    badge: "no",
  },

  {
    id: "AnnualReturn",
    icon: "bx bxs-coin-stack",
    label: "Annual Return",
    to: "/annualreturn",
  },
  {
    id: "RenewalApplicationList",
    icon: "bx bx-list-ul",
    label: "Renewal Application",
    to: "/renewal",
  },
  {
    id: "AreaExtensionApplicationList",
    icon: "bx bx-area",
    label: "Area Extension Application",
    to: "/areaextension",
  },
  {
    id: "DuplicationApplicationList",
    icon: "bx bx-duplicate",
    label: "Duplicate Certificate Application",
    to: "/duplicate",
  },
];

export const pdjMenu = [
  {
    id: "Application",
    icon: "bx bxs-user-badge",
    label: "Application",
    to: "#/",
    subs: [
      {
        id: "notaryapplication",
        icon: "bx bx-home",
        label: "Notary Application",
        to: "/notary_applications",
        badge: "no",
      },
      {
        id: "areaextensionapplication",
        icon: "bx bx-home",
        label: "Area Extension Application",
        to: "/area_extension_applications",
        badge: "no",
      },
      {
        id: "renewalapplication",
        icon: "bx bx-home",
        label: "Renewal Application",
        to: "/renewal_applications",
        badge: "no",
      },
    ],
  },
  {
    id: "AnnualReturn",
    icon: "bx bxs-coin-stack",
    label: "Annual Return",
    to: "/annualreturn",
  },
];

export const lawAdminMenu = [
  {
    id: "Application",
    icon: "bx bxs-user-badge",
    label: "Application",
    to: "#/",
    subs: [
      {
        id: "notaryapplication",
        icon: "bx bx-home",
        label: "Notary Application",
        to: "/notary_applications",
        badge: "no",
      },
      {
        id: "ExistingNotaryApplicationList",
        icon: "bx bx-duplicate",
        label: "Existing Notary Applications",
        to: "/existing_notary_applications",
      },
      {
        id: "AnnualReturn",
        icon: "bx bxs-coin-stack",
        label: "Annual Return",
        to: "/annual_return",
      },
      {
        id: "renewalapplication",
        icon: "bx bx-home",
        label: "Renewal Application",
        to: "/renewal_applications",
        badge: "no",
      },
      {
        id: "areaextensionapplication",
        icon: "bx bx-home",
        label: "Area Extension Application",
        to: "/area_extension_applications",
        badge: "no",
      },
      {
        id: "duplicatecertificateapplication",
        icon: "bx bx-home",
        label: "Duplicate Certificate Application",
        to: "/duplicate_certificate_applications",
        badge: "no",
      },
    ],
  },
  {
    id: "InterviewBoard",
    icon: "mdi mdi-notebook-outline",
    label: "Interview Board",
    to: "/interviewboard",
  },

  {
    id: "User",
    icon: "bx bx-user",
    label: "Competent Authority",
    to: "/user",
  },

  {
    id: "ArchivedApplication",
    icon: "bx bx-select-multiple",
    label: "Archived Applications",
    to: "/archived_application",
  },
  {
    id: "GazzetteReport",
    icon: "bx bxs-report",
    label: "Notary List",
    to: "/report",
  },
  {
    id: "Settings",
    icon: "bx bx-cog",
    label: "Settings",
    to: "/settings",
  },
  {
    id: "Fee",
    icon: "bx bx-money",
    label: "Fee",
    to: "/Fee",
  },
];
export const interviewBoardMenu = [
  {
    id: "notaryapplication",
    icon: "bx bx-home",
    label: "Notary Application",
    to: "/notary_applications",
    badge: "no",
  },
];
export const Menus = [
  {
    id: "notaryapplication",
    icon: "bx bx-home",
    label: "Notary Application",
    to: "/notary_applications",
    badge: "no",
  },
];
export const EmptyMenu = [];
