// export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL = "https://tnnotary.tn.gov.in/api/";
// export const BASE_URL = "http://localhost:8624/";

export const authenctication = {
  GetSeed: BASE_URL + "request_seed",
  UserNameValidate: BASE_URL + "validate/username",
  MobileNumberValidation: BASE_URL + "validate/mobile_number",
  stationLogin: BASE_URL + "station/signin",
  adminLogin: BASE_URL + "admin/signin",
  OfficialLogin: BASE_URL + "officer/sign_in",
  lawAdminLogin: BASE_URL + "law_admin/sign_in",
  advocateLogin: BASE_URL + "advocate/sign_in",
  interviewBoardLogin: BASE_URL + "interview_board/sign_in",
  competentAuthorityLogin: BASE_URL + "competant_authority/sign_in",
  superAdmin: BASE_URL + "superadmin/sign_in",
  registered: BASE_URL + "registered",
  ChangePassword: BASE_URL + "officer/changepassword",
  PDJChangePassword: BASE_URL + "officer/change_password",
  IBChangePassword: BASE_URL + "interview_board/change_password",
  AdvocateChangePassword: BASE_URL + "advocate/change_password",

  AdvocateResetPassword: BASE_URL + "advocate/reset_password",
  InterviewResetPassword: BASE_URL + "interview_board/reset_password",
  OfficalResetPassword: BASE_URL + "officer/reset_password",

  getOTP: BASE_URL + "otp/get",
  signUp: BASE_URL + "advocate/sign_up",
  verifyOTP: BASE_URL + "otp/verify",

  ChangeMobileNumber: BASE_URL + "change/mobile_number",
  changeEmailID: BASE_URL + "change/email_id",
  GetCaptcha: BASE_URL + "request_captcha",
  HandleCaptcha: BASE_URL + "handle_captcha",
};

export const designation = {
  GetAll: BASE_URL + "designation",
  Create: BASE_URL + "designation",
  Delete: BASE_URL + "designation/",
  Update: BASE_URL + "designation/",
  GetOne: BASE_URL + "designation/",
};

export const session = {
  GetAll: BASE_URL + "session",
  Create: BASE_URL + "session",
  Delete: BASE_URL + "session/",
  Update: BASE_URL + "session/",
  GetOne: BASE_URL + "session/",
  GetAllWithOutPagination: BASE_URL + "session",
};
export const worktype = {
  GetAll: BASE_URL + "work_type",
  Create: BASE_URL + "work_type",
  Delete: BASE_URL + "work_type/",
  Update: BASE_URL + "work_type/",
  GetOne: BASE_URL + "work_type/",
  GetAllWithOutPagination: BASE_URL + "work_type",
};
export const annualreturn = {
  GetAll: BASE_URL + "annual_return/get_notary",
  GetAllPDJ: BASE_URL + "annual_return/get_pdj",
  GetAllLawAdmin: BASE_URL + "annual_return/get_law_admin",
  Create: BASE_URL + "annual_return",
  Delete: BASE_URL + "annual_return/",
  Update: BASE_URL + "annual_return/",
  GetOne: BASE_URL + "annual_return/",
};

export const claimFee = {
  GetAll: BASE_URL + "claim_fee",
  Create: BASE_URL + "claim_fee",
  Delete: BASE_URL + "claim_fee/",
  Update: BASE_URL + "claim_fee/",
  GetOne: BASE_URL + "claim_fee/",
  FileUpload: BASE_URL + "fileupload",
};
export const fileUpload = {
  upload: "fileupload",
};
export const fee = {
  GetAll: BASE_URL + "fee_type",
  Create: BASE_URL + "fee_type",
  Delete: BASE_URL + "fee_type/",
  Update: BASE_URL + "fee_type/",
  GetOne: BASE_URL + "fee_type/",
  OfficerForward: BASE_URL + "officers/forforward",
};

export const level = {
  GetAll: BASE_URL + "level",
  GetOne: BASE_URL + "level/",
  Create: BASE_URL + "level",
  Update: BASE_URL + "level/",
  GetPaginated: BASE_URL + "level",
  GetChildernLevel: "level/children/",
  Delete: BASE_URL + "level/",
  levelnextlevel: BASE_URL + "level/nextlevel/",
  GetAllAreaLevel: BASE_URL + "parentlevel",
  GetAllLevel: BASE_URL + "level/hierarchy",
  CreateLevelPosting: BASE_URL + "posting/update",
  GetAllPostingByLevel: BASE_URL + "posting/getall/",
  Getlevel: BASE_URL + "level",
};
export const area = {
  GetAll: BASE_URL + "area",
  GetOne: BASE_URL + "area/",
  Create: BASE_URL + "area",
  Update: BASE_URL + "area/",
  GetPaginated: BASE_URL + "area",
  Delete: BASE_URL + "area/",
  DeleteAll: BASE_URL + "area/deleteall/",
  GetPopulatedAreaDetail: BASE_URL + "area/parents/",
  Areanextlevels: BASE_URL + "area/nextareas/",
  GetMasterRegions: BASE_URL + "area/masterregions",
  GetOneNextLevel: BASE_URL + "area/nextlevels/",
  Regionnextlevels: BASE_URL + "area/nextlevelarea/",
};

export const areatype = {
  GetAllLevel: BASE_URL + "level",
  Create: BASE_URL + "level",
  Delete: BASE_URL + "level/",
  Update: BASE_URL + "level/",
  GetOne: BASE_URL + "level/",
};
export const cases = {
  GetAll: BASE_URL + "case",
  Create: BASE_URL + "case",
  Delete: BASE_URL + "case/",
  Update: BASE_URL + "case/",
  GetOne: BASE_URL + "case/",
};
export const casestatus = {
  GetAll: BASE_URL + "case_status",
  Create: BASE_URL + "case_status",
  Delete: BASE_URL + "case_status/",
  Update: BASE_URL + "case_status/",
  GetOne: BASE_URL + "case_status/",
};

export const casetype = {
  GetAll: BASE_URL + "case_type",
  Create: BASE_URL + "case_type",
  Delete: BASE_URL + "case_type/",
  Update: BASE_URL + "case_type/",
  GetOne: BASE_URL + "case_type/",
};
export const department = {
  GetAll: BASE_URL + "department",
  Create: BASE_URL + "department",
  Delete: BASE_URL + "department/",
  Update: BASE_URL + "department/",
  GetOne: BASE_URL + "department/",
};

export const court = {
  GetAll: BASE_URL + "court",
  Create: BASE_URL + "court",
  Delete: BASE_URL + "court/",
  Update: BASE_URL + "court/",
  GetOne: BASE_URL + "court/",
};

export const roles = {
  GetAll: BASE_URL + "role",
  Create: BASE_URL + "role",
  Delete: BASE_URL + "role/",
  Update: BASE_URL + "role/",
  GetOne: BASE_URL + "role/",
};

export const industrycategory = {
  GetAll: BASE_URL + "industry_category",
  Create: BASE_URL + "industry_category",
  Delete: BASE_URL + "industry_category/",
  Update: BASE_URL + "industry_category/",
  GetOne: BASE_URL + "industry_category/",
};

export const organization = {
  GetAll: BASE_URL + "organization",
  Create: BASE_URL + "organization",
  Delete: BASE_URL + "organization/",
  Update: BASE_URL + "organization/",
  GetOne: BASE_URL + "organization/",
};
export const industryclassification = {
  GetAll: BASE_URL + "industry_classification",
  Create: BASE_URL + "industry_classification",
  Delete: BASE_URL + "industry_classification/",
  Update: BASE_URL + "industry_classification/",
  GetOne: BASE_URL + "industry_classification/",
};

export const departmenttype = {
  GetAll: BASE_URL + "department_type",
  Create: BASE_URL + "department_type",
  Delete: BASE_URL + "department_type/",
  Update: BASE_URL + "department_type/",
  GetOne: BASE_URL + "department_type/",
};

export const petitioner = {
  GetAll: BASE_URL + "petitioner",
  Create: BASE_URL + "petitioner",
  Delete: BASE_URL + "petitioner/",
  Update: BASE_URL + "petitioner/",
  GetOne: BASE_URL + "petitioner/",
};

export const respondent = {
  GetAll: BASE_URL + "respondent",
  Create: BASE_URL + "respondent",
  Delete: BASE_URL + "respondent/",
  Update: BASE_URL + "respondent/",
  GetOne: BASE_URL + "respondent/",
  UpdateAll: BASE_URL + "case_entity/updatemany",
};
export const Case = {
  GetAll: BASE_URL + "case",
  Create: BASE_URL + "case",
  Delete: BASE_URL + "case/",
  Update: BASE_URL + "case/",
  GetOne: BASE_URL + "case/",
  Entity: BASE_URL + "case_entity",
  Flow: BASE_URL + "officer_case_flow",
  Document: BASE_URL + "case_hearing_document",
};

export const hearing = {
  GetAll: BASE_URL + "hearing",
  Create: BASE_URL + "hearing",
  Delete: BASE_URL + "hearing/",
  Update: BASE_URL + "hearing/",
  GetOne: BASE_URL + "hearing/",
};
export const state = {
  GetAll: BASE_URL + "state",
  GetAllWithOutPagination: BASE_URL + "state",
  Create: BASE_URL + "state",
  Delete: BASE_URL + "state/",
  Update: BASE_URL + "state/",
  GetOne: BASE_URL + "state/",
};
export const district = {
  GetAll: BASE_URL + "district",
  GetAllWithOutPagination: BASE_URL + "district",
  Create: BASE_URL + "district",
  Delete: BASE_URL + "district/",
  Update: BASE_URL + "district/",
  GetOne: BASE_URL + "district/",
};
export const advocate = {
  GetAll: BASE_URL + "advocate",
  Create: BASE_URL + "advocate",
  Delete: BASE_URL + "advocate/",
  Update: BASE_URL + "advocate/",
  GetOne: BASE_URL + "advocate/",
};
export const additional = {
  GetAll: BASE_URL + "additional",
  Create: BASE_URL + "additional",
  Delete: BASE_URL + "additional/",
  Update: BASE_URL + "additional/",
  GetOne: BASE_URL + "additional/",
};

export const affidavit = {
  GetAll: BASE_URL + "affidavit",
  Create: BASE_URL + "affidavit",
  Delete: BASE_URL + "affidavit/",
  Update: BASE_URL + "affidavit/",
  GetOne: BASE_URL + "affidavit/",
};
export const counterAffidavit = {
  GetAll: BASE_URL + "counterAffidavit",
  Create: BASE_URL + "counterAffidavit",
  Delete: BASE_URL + "counterAffidavit/",
  Update: BASE_URL + "counterAffidavit/",
  GetOne: BASE_URL + "counterAffidavit/",
};
export const judge = {
  GetAll: BASE_URL + "judge",
  Create: BASE_URL + "judge",
  Delete: BASE_URL + "judge/",
  Update: BASE_URL + "judge/",
  GetOne: BASE_URL + "judge/",
};
export const officer = {
  GetAll: BASE_URL + "officer",
  Create: BASE_URL + "officer",
  Delete: BASE_URL + "officer/",
  Update: BASE_URL + "officer/",
  GetOne: BASE_URL + "officer/",
  GetForForward: BASE_URL + "officers/forforward",
};

export const documentMaster = {
  GetAll: BASE_URL + "document",
  GetOne: BASE_URL + "document/",
  Create: BASE_URL + "document",
  Update: BASE_URL + "document/",
  GetPaginated: BASE_URL + "document",
  Delete: BASE_URL + "document/",
  DocumentMaping: BASE_URL + "documents_required",
  GetAllDocumentMaping: BASE_URL + "documents_required/unique",
  FileUpload: BASE_URL + "fileupload",
  UrltoBase64: BASE_URL + "urltobase64",
};

export const entity = {
  GetAll: BASE_URL + "entity",
  Create: BASE_URL + "entity",
  Delete: BASE_URL + "entity/",
  Update: BASE_URL + "entity/",
  GetOne: BASE_URL + "entity/",
};
export const casecategory = {
  GetAll: BASE_URL + "case_category",
  Create: BASE_URL + "case_category",
  Delete: BASE_URL + "case_category/",
  Update: BASE_URL + "case_category/",
  GetOne: BASE_URL + "case_category/",
};
export const admin = {
  DashboradCount: BASE_URL + "dashboard/casecounts",
};
export const industrytype = {
  GetAll: BASE_URL + "industry_type",
  Create: BASE_URL + "industry_type",
  Delete: BASE_URL + "industry_type/",
  Update: BASE_URL + "industry_type/",
  GetOne: BASE_URL + "industry_type/",
};

export const station = {
  GetAll: BASE_URL + "station",
  Create: BASE_URL + "station",
  Delete: BASE_URL + "station/",
  Update: BASE_URL + "station/",
  GetOne: BASE_URL + "station/",
};
export const person = {
  GetAll: BASE_URL + "person",
  Create: BASE_URL + "person",
  Delete: BASE_URL + "person/",
  Update: BASE_URL + "person/",
  GetOne: BASE_URL + "person/",
};
export const log = {
  GetAll: BASE_URL + "fencing_log",
  GetOne: BASE_URL + "fencing_log/",
  Log: BASE_URL + "stationwise/fencing_log",
};

export const application = {
  GetAll: BASE_URL + "application",
  Create: BASE_URL + "application",
  Delete: BASE_URL + "application/",
  Update: BASE_URL + "application/",
  GetOne: BASE_URL + "application/",
};
export const applicationProcess = {
  Update: BASE_URL + "application_process/",
};

export const taluk = {
  GetAll: BASE_URL + "taluk",
  GetAllWithOutPagination: BASE_URL + "taluk",
  Create: BASE_URL + "taluk",
  Delete: BASE_URL + "taluk/",
  Update: BASE_URL + "taluk/",
  GetOne: BASE_URL + "taluk/",
};
export const interviewboard = {
  GetAll: BASE_URL + "interview_board",
  Create: BASE_URL + "interview_board",
  Delete: BASE_URL + "interview_board/",
  Update: BASE_URL + "interview_board/",
  GetOne: BASE_URL + "interview_board/",
};
export const user = {
  UserNameValidate: BASE_URL + "validate/username",
  GetAll: BASE_URL + "user",
  Create: BASE_URL + "user",
  Delete: BASE_URL + "user/",
  Update: BASE_URL + "user/",
  GetOne: BASE_URL + "user/",
};
export const profile = {
  GetAll: BASE_URL + "profile",
  Create: BASE_URL + "profile",
  Delete: BASE_URL + "profile/",
  Update: BASE_URL + "profile/",
  GetOne: BASE_URL + "profile/",
  logout: BASE_URL + "logout",
};
export const cms = {
  GetAll: BASE_URL + "cms",
  Create: BASE_URL + "cms",
  Delete: BASE_URL + "cms/",
  Update: BASE_URL + "cms/",
  GetOne: BASE_URL + "cms/",
};
export const community = {
  GetAll: BASE_URL + "community",
  GetAllWithOutPagination: BASE_URL + "community",
  Create: BASE_URL + "community",
  Delete: BASE_URL + "community/",
  Update: BASE_URL + "community/",
  GetOne: BASE_URL + "community/",
};
export const gender = {
  GetAll: BASE_URL + "gender",
  GetAllWithOutPagination: BASE_URL + "gender",
  Create: BASE_URL + "gender",
  Delete: BASE_URL + "gender/",
  Update: BASE_URL + "gender/",
  GetOne: BASE_URL + "gender/",
};
export const practicingin = {
  GetAll: BASE_URL + "type",
  GetAllWithOutPagination: BASE_URL + "type",
  Create: BASE_URL + "type",
  Delete: BASE_URL + "type/",
  Update: BASE_URL + "type/",
  GetOne: BASE_URL + "type/",
};

export const profileComplete = {
  GetAll: BASE_URL + "advocate/complete_profile",
  Create: BASE_URL + "advocate/complete_profile",
  Delete: BASE_URL + "advocate/complete_profile/",
  Update: BASE_URL + "advocate/complete_profile/",
  GetOne: BASE_URL + "advocate/complete_profile/",
  ValidateProfile: BASE_URL + "validate/profile",
};

export const newapplication = {
  GetMaking: BASE_URL + "new_application/make_payment",
  GetPayment: BASE_URL + "payment/get_details",
  GetAll: BASE_URL + "new_application",
  Create: BASE_URL + "new_application",
  CreateArea: BASE_URL + "extension_application",
  CreateRenewal: BASE_URL + "renewal_application",
  CreateDuplicate: BASE_URL + "duplicate_application",
  Delete: BASE_URL + "new_application/",
  Update: BASE_URL + "new_application/",
  UpdateGOs: BASE_URL + "update_gos/",
  GetOne: BASE_URL + "new_application/",
  GetSubmitted: BASE_URL + "new_application/submit/",
  ChangeStatus: BASE_URL + "new_application/change_status",
  Recommend: BASE_URL + "new_application/recommend",
  SelectiveRecommended: BASE_URL + "new_application/selective_recommend",
  Forward: "new_application/forward",
  ScheduleInterview: "new_application/schedule",
  ReScheduleInterview: "new_application/reschedule",
  SendCallLetter: "new_application/send_call_letter",
  PassAll: "new_application/interview_pass_all",
  FailAll: "new_application/interview_fail_multiple",
  EnablePayment: "new_application/enable_payment",
  ApproveAll: "new_application/approve_all",
  GetAllForwardedApplications: "/get/applications/",
  ApprovedStatus: "advocate/applications/",
  ResubmitApplication: "new_application/resubmit",
};

export const formType = {
  getAll: BASE_URL + "form_type",
  getAllWithOutPagination: BASE_URL + "form_type",
  create: BASE_URL + "form_type",
  delete: BASE_URL + "form_type/",
  update: BASE_URL + "form_type/",
  getOne: BASE_URL + "form_type/",
};
export const title = {
  getAll: BASE_URL + "title",
  getAllWithOutPagination: BASE_URL + "title",
  create: BASE_URL + "title",
  delete: BASE_URL + "title/",
  update: BASE_URL + "title/",
  getOne: BASE_URL + "title/",
};
export const renewalApplication = {
  // GetMaking: BASE_URL + "renewal_application/make_payment/",
  GetMaking: BASE_URL + "renewal_application/make_payment",
  GetAll: BASE_URL + "renewal_application",
  Create: BASE_URL + "renewal_application",
  Approve: BASE_URL + "renewal_application/approve",
  Reject: BASE_URL + "renewal_application/reject",
  GetOne: BASE_URL + "renewal_application/",
  Forward: BASE_URL + "renewal_application/forward",
  Recommend: BASE_URL + "renewal_application/recommend",
  NotRecommend: BASE_URL + "renewal_application/not_recommend",
  EnablePayment: BASE_URL + "renewal_application/enable_payment",
};

export const areaExtensionApplication = {
  //  GetMaking: BASE_URL + "area_extension_application/make_payment/",
  GetMaking: BASE_URL + "area_extension_application/make_payment",
  GetAll: BASE_URL + "extension_application",
  Create: BASE_URL + "extension_application",
  Approve: BASE_URL + "extension_application/approve",
  Reject: BASE_URL + "extension_application/reject",
  GetOne: BASE_URL + "extension_application/",
  Forward: BASE_URL + "extension_application/forward",
  Recommend: BASE_URL + "extension_application/recommend",
  NotRecommend: BASE_URL + "extension_application/not_recommend",
  EnablePayment: BASE_URL + "extension_application/enable_payment",
};

export const duplicateCertificateApplication = {
  //  GetMaking: BASE_URL + "duplicate_certificate_application/make_payment/",
  GetMaking: BASE_URL + "duplicate_certificate_application/make_payment",
  GetAll: BASE_URL + "duplicate_application",
  Create: BASE_URL + "duplicate_application",
  Approve: BASE_URL + "duplicate_application/approve",
  Reject: BASE_URL + "duplicate_application/reject",
  GetOne: BASE_URL + "duplicate_application/",
};

export const existingNotary = {
  GetAll: BASE_URL + "existing_notary_application",
  Create: BASE_URL + "existing_notary_application",
  Approve: BASE_URL + "existing_notary_application/",
  Reject: BASE_URL + "existing_notary_application/",
  GetOne: BASE_URL + "existing_notary_application/",
  GetDetail: BASE_URL + "existing_notary_application/get",
  UpdateDetail: BASE_URL + "existing_notary_application/update",
};
export const settings = {
  Archive: BASE_URL + "new_application/archive",
  GetAllArchived: BASE_URL + "archived_applications",
  GetAll: BASE_URL + "settings",
  Create: BASE_URL + "settings",
  Update: BASE_URL + "settings/",
  Approve: BASE_URL + "settings/",
  Reject: BASE_URL + "settings/",
  GetOne: BASE_URL + "settings/",
};

export const existingNotaryApplication = {
  GetAll: BASE_URL + "existing_notary_application",
  Create: BASE_URL + "existing_notary_application",
  Approve: BASE_URL + "existing_notary_application/approve",
  Reject: BASE_URL + "existing_notary_application/reject",
  GetOne: BASE_URL + "existing_notary_application/",
};

export const report = {
  GetAll: BASE_URL + "gazette_report",
};
export const eligibility = {
  GetAll: BASE_URL + "check_eligibility",
};
export const gos = {
  GetAll: BASE_URL + "gos",
};
export const uploadCOP = {
  Upload: BASE_URL + "upload_cop",
};
export const dummyPayment = {
  GetAll: BASE_URL + "dummy_payment",
};
export const payment = {
  Response: BASE_URL + "payment/get_details",
};
