import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
import FileDisplay from "../../components/custom/FileDisplay";
import { Formik, Form, Field } from "formik";
//i18n
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withNamespaces } from "react-i18next";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  CustomInput,
  Label,
} from "reactstrap";
import { TITLE } from "../../constants/defaultValues";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import {
  areaExtensionApplication,
  fileUpload,
  gos,
  newapplication,
  renewalApplication,
} from "../../constants/config";
import { CallService } from "../../helpers/ServiceCall";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  formatDate,
  getMonthName,
  checkIfImage,
  checkIfPdf,
  getAllowedExtensionText,
} from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
let apis = {};

class ApplicationsList extends Component {
  constructor(props) {
    super(props);
    this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    this.state = {
      tabs: [],
      activeFirstTab: 1,
      state: "",
      Language: sessionStorage.getItem("lng"),
      Role:
        JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name,
      GOS: [],
    };
  }

  componentDidMount() {
    apis = this.props?.apis;
    this.setState({
      Role:
        JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name,
    });
    if (this.props?.viewRowId) {
      console.log("this.props?.viewRowId", this.props?.viewRowId);
      this.setState(
        {
          viewValues: this.props?.viewRowId,
        },
        () => {
          this.setTab(1);
        }
      );
    }
    if (
      this.props?.location.pathname === "/renewal_applications" ||
      this.props?.location.pathname === "/duplicate_certificate_applications" ||
      this.props?.location.pathname === "/area_extension_applications" ||
      this.props?.location.pathname === "/areaextension" ||
      this.props?.location.pathname === "/renewal" ||
      this.props?.location.pathname === "/duplicate" ||
      this.props?.location.pathname === "/existing_notary_applications" ||
      this.props?.location.pathname === "/archived_application"
    ) {
      this.getAllGO(
        this.props?.viewRowId &&
          this.props?.viewRowId.CreatedBy &&
          this.props?.viewRowId.CreatedBy._id
      );
    }
    this.setState({
      tabs: [
        {
          id: 1,
          Name:
            this.state.viewValues &&
            this.state.viewValues.GenerateCertificate === "GenerateCertificate"
              ? this.props?.t("Application")
              : this.props?.location.pathname ===
                "/existing_notary_applications"
              ? this.props?.t("Existing Notary")
              : this.props?.t("Applicant"),
        },
        {
          id: 2,
          Name: this.props?.t("Personal"),
        },
        {
          id: 3,
          Name: this.props?.t("Professional"),
        },
        {
          id: 4,
          Name: this.props?.t("Qualification"),
        },
        {
          id: 5,
          Name: this.props?.t("Residence Address"),
        },
        {
          id: 6,
          Name: this.props?.t("Professional Address"),
        },
        {
          id: 10,
          Name: this.props?.t("Additional Details"),
        },
        // {
        //   id:
        //     this.state.viewValues &&
        //     this.state.viewValues.AdditionalDocuments &&
        //     this.state.viewValues.AdditionalDocuments.length > 0
        //       ? 8
        //       : "",
        //   Name:
        //     this.state.viewValues &&
        //     this.state.viewValues.AdditionalDocuments &&
        //     this.state.viewValues.AdditionalDocuments.length > 0
        //       ? this.props?.t("Additional Documents")
        //       : "",
        // },
      ],
    });
  }

  getAllGO = (id) => {
    var ID = {
      UserID: id,
    };
    CallService(
      gos.GetAll,
      MethodType.POST,
      false,
      ID,
      "",
      this.getAllGOResponse
    );
  };

  getAllGOResponse = (data) => {
    if (data.result) {
      this.setState({
        GOS: data.result,
      });
    }
  };

  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };
  setTab = (no) => {
    if (
      this.props?.location.pathname === "/notary_applications" ||
      //  this.props?.location.pathname === "/existing_notary_applications" ||
      this.props?.location.pathname === "/annual_return" ||
      this.props?.location.pathname === "/report"
    ) {
      var tabsValues = this.state.tabs;

      if (
        this.state.viewValues &&
        this.state.viewValues.AdditionalDocuments &&
        this.state.viewValues.AdditionalDocuments.length > 0
      ) {
        var AddDoc = {
          id: 8,
          Name: this.props?.t("Additional Documents"),
        };
        tabsValues.push(AddDoc);
        this.setState({
          tabs: tabsValues,
        });
      }
    } else {
      this.setState({
        tabs: [
          {
            id: 1,
            Name:
              this.state.viewValues &&
              this.state.viewValues.GenerateCertificate ===
                "GenerateCertificate"
                ? this.props?.t("Application")
                : this.props?.location.pathname ===
                  "/existing_notary_applications"
                ? this.props?.t("Existing Notary")
                : this.props?.t("Applicant"),
          },
          {
            id: 2,
            Name: this.props?.t("Personal"),
          },
          {
            id: 3,
            Name: this.props?.t("Professional"),
          },
          {
            id: 4,
            Name: this.props?.t("Qualification"),
          },
          {
            id: 5,
            Name: this.props?.t("Residence Address"),
          },
          {
            id: 6,
            Name: this.props?.t("Professional Address"),
          },

          {
            id: 7,
            Name: this.props?.t("Government Orders"),
          },

          {
            id: 9,
            Name: this.props?.t("Late Renewal Details"),
          },
          {
            id: 10,
            Name: this.props.t("Additional Details"),
          },
          {
            id:
              this.state.viewValues &&
              this.state.viewValues.AdditionalDocuments &&
              this.state.viewValues.AdditionalDocuments.length > 0
                ? 8
                : "",
            Name:
              this.state.viewValues &&
              this.state.viewValues.AdditionalDocuments &&
              this.state.viewValues.AdditionalDocuments.length > 0
                ? this.props?.t("Additional Documents")
                : "",
          },
        ],
      });
    }
  };
  togglePhotoViewModal(img, title) {
    if (img) {
      this.setState({
        DocumentView: img,
        DocumentViewModal: !this.state?.DocumentViewModal,
        ImageGallery: img,
        Title: title,
      });
    } else {
      this.setState({
        DocumentViewModal: !this.state?.DocumentViewModal,
      });
    }
  }
  properties = {
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: false,

    onChange: (oldIndex, newIndex) => {
      this.setState({
        currentImage: this.state?.ImageGallery,
      });
    },
  };

  DownloadFormAsPDF = () => {
    const Application = this?.state?.viewValues;
    console.log("Application", Application);
    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    y = y + 20;

    pdf.setFontSize(11);
    pdf.text(this.props?.t("Applicant Details"), 230, y, null, null, "center");
    pdf.line(195, y + 5, 265, y + 5);

    y = y + 40;

    if (
      Application &&
      Application?.Applicant &&
      Application?.Applicant?.Photo
    ) {
      // pdf.addImage(Application?.Applicant?.Photo, "PNG", 320, y, 80, 50);
    }

    pdf.text("Application Number", 50, y, null, null);
    pdf.text(
      Application?.SRApplicationNo || Application?.ApplicationNo,
      175,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);

    pdf.line(175, y + 5, 300, y + 5);
    y = y + 20;

    if (
      Application &&
      Application?.NotaryApplication &&
      Application?.NotaryApplication?.SerialNumber
    ) {
      pdf.text("Serial Number", 50, y, null, null);
      pdf.text(
        Application?.NotaryApplication?.SerialNumber,
        175,
        y,
        null,
        null
      );
      pdf.line(175, y + 5, 300, y + 5);
      y = y + 20;
    }

    pdf.text(this.props?.t("Username"), 50, y, null, null);
    pdf.text(
      Application?.CreatedBy ? Application?.CreatedBy?.Username : "",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 300, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Name"), 50, y, null, null);

    pdf.text(Application?.Applicant?.Title.Name, 175, y, null, null);
    pdf.text(". ", 195, y, null, null);
    pdf.text(Application?.Applicant?.Name, 200, y, null, null);
    pdf.line(175, y + 5, 300, y + 5);
    y = y + 20;

    pdf.text("Submitted On", 50, y, null, null);
    pdf.text(
      Application?.SubmittedOn
        ? formatDate(fromUnixTime(Application?.SubmittedOn), datePickerFormat)
        : "",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 300, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("District"), 50, y, null, null);

    pdf.text(
      Application
        ? Application?.District
          ? typeof Application?.District === "object"
            ? this.state.Language === "en"
              ? Application?.District.Name
              : Application?.District.TamilName
            : Application?.NotaryApplication
            ? Application?.NotaryApplication?.District
              ? this.state.Language === "ta"
                ? Application?.NotaryApplication?.District.TamilName
                : Application?.NotaryApplication?.District.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Taluks"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Taluks
          ? Application?.Taluks?.map((t) => {
              return t
                ? this.state.Language === "en"
                  ? t.Name
                  : t.TamilName
                : "";
            }).join(",  ")
          : Application?.NotaryApplication
          ? Application?.NotaryApplication?.Taluks
            ? Application?.NotaryApplication?.Taluks?.map((t) => {
                return t
                  ? this.state.Language === "ta"
                    ? t.TamilName
                    : t.Name
                  : "";
              }).join(",  ")
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    if (
      Application &&
      Application?.NotaryApplication &&
      Application?.NotaryApplication?.ValidFrom
    ) {
      pdf.text("Issued Date", 50, y, null, null);
      pdf.text(
        formatDate(
          fromUnixTime(Application?.NotaryApplication?.ValidFrom / 1000),
          datePickerFormat
        ),

        175,
        y,
        null,
        null
      );
      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }

    if (
      Application &&
      Application?.NotaryApplication &&
      Application?.NotaryApplication?.ValidTo
    ) {
      pdf.text("Expiry Date", 50, y, null, null);
      pdf.text(
        formatDate(
          fromUnixTime(Application?.NotaryApplication?.ValidTo / 1000),
          datePickerFormat
        ),

        175,
        y,
        null,
        null
      );
      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }
    if (
      Application &&
      Application?.NotaryApplication &&
      Application?.NotaryApplication?.GONumber
    ) {
      pdf.text("Go Number", 50, y, null, null);
      pdf.text(Application?.NotaryApplication?.GONumber, 175, y, null, null);
      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }

    pdf.text(this.props?.t("Gender"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.Gender
            ? Application?.Applicant?.Gender.Name
              ? Application?.Applicant?.Gender.Name.en
                ? Application?.Applicant?.Gender.Name.en
                : Application?.Applicant?.Gender.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Married"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.IsMarried
            ? "Yes"
            : "No"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Father Name"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.FatherOrHusbandName
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Date of Birth"), 50, y, null, null);
    pdf.text(
      Application?.Applicant?.Dob
        ? formatDate(
            fromUnixTime(Application?.Applicant?.Dob / 1000),
            datePickerFormat
          )
        : "",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Community"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.Community
            ? Application?.Applicant?.Community.Name
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Mobile Number"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.MobileNumber
            ? Application?.Applicant?.MobileNumber
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Email Id"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.EmailID
            ? Application?.Applicant?.EmailID
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Aadhar Number"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.Aadhar
            ? Application?.Applicant?.Aadhar
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Form Type"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.FormType?.Name
            ? Application?.Applicant?.FormType?.Name
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    if (
      (Application &&
        Application?.Applicant &&
        Application?.Applicant?.FormType &&
        Application?.Applicant?.FormType?.Name === "Form-II") ||
      Application?.Applicant?.FormType?.Name === "படிவம்-II"
    ) {
      pdf.text(
        this.props?.t("Date of Joining Goverment Service"),
        50,
        y,
        null,
        null
      );
      pdf.text(
        Application?.Applicant?.GovtServiceStartDate
          ? formatDate(
              fromUnixTime(Application?.Applicant?.GovtServiceStartDate / 1000),
              datePickerFormat
            )
          : "",
        250,
        y,
        null,
        null
      );

      pdf.line(250, y + 5, 400, y + 5);
      y = y + 20;

      pdf.text(
        this.props?.t("Date of Retirement / Relief from Goverment Service"),
        50,
        y,
        null,
        null
      );
      pdf.text(
        Application?.Applicant?.GovtServiceEndDate
          ? formatDate(
              fromUnixTime(Application?.Applicant?.GovtServiceEndDate / 1000),
              datePickerFormat
            )
          : "",
        250,
        y,
        null,
        null
      );

      pdf.line(250, y + 5, 400, y + 5);
      y = y + 20;

      pdf.text(
        this.props?.t("Post held at the time of retirement / relief"),
        50,
        y,
        null,
        null
      );
      pdf.text(
        Application?.Applicant ? Application?.Applicant?.GovtServicePost : "",
        250,
        y,
        null,
        null
      );

      pdf.line(250, y + 5, 400, y + 5);
      y = y + 20;
    }

    pdf.text(this.props?.t("Bar Council Enrolment Number"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.BarCouncilNumber
            ? Application?.Applicant?.BarCouncilNumber
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );
    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    if (Application?.Applicant?.EnrolmentDate) {
      pdf.text(this.props?.t("Enrolment Date"), 50, y, null, null);
      pdf.text(
        Application?.Applicant?.EnrolmentDate
          ? formatDate(
              fromUnixTime(Application?.Applicant?.EnrolmentDate / 1000),
              datePickerFormat
            )
          : "",
        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }
    pdf.text(this.props?.t("Practicing In"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.PracticingIn.length > 0
            ? Application?.Applicant?.PracticingIn?.map((t) => {
                return t ? t.Name : "";
              }).join(",  ")
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    if (
      Application &&
      Application?.CreatedBy &&
      Application?.CreatedBy.LandlineNumber
    ) {
      pdf.text(this.props?.t("Landline Number"), 50, y, null, null);
      pdf.text(
        Application?.CreatedBy.LandlineNumber,

        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }

    if (Application && Application?.CreatedBy && Application?.CreatedBy.Fax) {
      pdf.text(this.props?.t("Fax"), 50, y, null, null);
      pdf.text(
        Application?.CreatedBy.Fax,

        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }

    pdf.text(this.props?.t("Income Tax Assessee"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.IncomeTaxAssessee === true
            ? "Yes"
            : "No"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("PAN Number"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.PANNumber
            ? Application?.Applicant?.PANNumber
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    pdf.addPage("a4", "p");
    y = 0;
    y = y + 40;
    pdf.setLineDashPattern([1.5]);
    for (var i = 0; i < Application?.CreatedBy?.Degrees?.length; i++) {
      pdf.text(this.props?.t("Institution"), 50, y, null, null);
      pdf.text(
        Application
          ? Application
            ? Application?.CreatedBy
              ? Application?.CreatedBy?.Degrees[i]
                ? Application?.CreatedBy?.Degrees[i]?.Institution
                : "-"
              : "-"
            : "-"
          : "-",
        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
      pdf.text(this.props?.t("Degree"), 50, y, null, null);
      pdf.text(
        Application
          ? Application
            ? Application?.CreatedBy
              ? Application?.CreatedBy?.Degrees[i]
                ? Application?.CreatedBy?.Degrees[i]?.Name
                : "-"
              : "-"
            : "-"
          : "-",
        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;

      pdf.text(this.props?.t("Year of Passing"), 50, y, null, null);
      pdf.text(
        Application
          ? Application?.CreatedBy
            ? Application?.CreatedBy?.Degrees[i]
              ? Application?.CreatedBy?.Degrees[i]?.YearOfPassing?.toString()
              : "-"
            : "-"
          : "-",
        175,
        y,
        null,
        null
      );

      pdf.line(175, y + 5, 400, y + 5);
      y = y + 20;
    }

    pdf.text(this.props?.t("Residency Address"), 50, y, null, null);

    pdf.line(50, y + 5, 120, y + 5);

    y = y + 20;

    pdf.text(this.props?.t("Address Line 1"), 50, y, null, null);
    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ResidencyAddress
            ? Application?.CreatedBy.ResidencyAddress
              ? Application?.CreatedBy.ResidencyAddress.Address.Line1
              : "-"
            : "-"
          : Application?.Applicant?.ResidencyAddress
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.Line1
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Address Line 2"), 50, y, null, null);

    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ResidencyAddress
            ? Application?.CreatedBy.ResidencyAddress
              ? Application?.CreatedBy.ResidencyAddress.Address.Line2
                ? Application?.CreatedBy.ResidencyAddress.Address.Line2
                : "-"
              : "-"
            : "-"
          : Application?.Applicant?.ResidencyAddress
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.Line2
              ? Application?.Applicant?.ResidencyAddress.Address.Line2
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Address Line 3"), 50, y, null, null);
    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ResidencyAddress
            ? Application?.CreatedBy.ResidencyAddress
              ? Application?.CreatedBy.ResidencyAddress.Address.Line3
                ? Application?.CreatedBy.ResidencyAddress.Address.Line3
                : "-"
              : "-"
            : "-"
          : Application?.Applicant?.ResidencyAddress
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.Line3
              ? Application?.Applicant?.ResidencyAddress.Address.Line3
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text(this.props?.t("State"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.State
              ? this.state.Language === "ta"
                ? "தமிழ்நாடு"
                : Application?.Applicant?.ResidencyAddress.Address.State.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("District"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.District
              ? this.state.Language === "ta"
                ? Application?.Applicant?.ResidencyAddress.Address.District
                    .TamilName
                : Application?.Applicant?.ResidencyAddress.Address.District.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Taluk"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.Taluk
              ? this.state.Language === "ta"
                ? Application?.Applicant?.ResidencyAddress.Address.Taluk
                    .TamilName
                : Application?.Applicant?.ResidencyAddress.Address.Taluk.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Pincode"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ResidencyAddress
            ? Application?.Applicant?.ResidencyAddress.Address.Pincode
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Provisional Address"), 50, y, null, null);

    pdf.line(50, y + 5, 130, y + 5);

    y = y + 20;

    pdf.text(this.props?.t("Address Line 1"), 50, y, null, null);
    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ProvisionalAddress
            ? Application?.CreatedBy.ProvisionalAddress
              ? Application?.CreatedBy.ProvisionalAddress.Address.Line1
              : "-"
            : "-"
          : Application?.Applicant?.ProvisionalAddress
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.Line1
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Address Line 2"), 50, y, null, null);
    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ProvisionalAddress
            ? Application?.CreatedBy.ProvisionalAddress
              ? Application?.CreatedBy.ProvisionalAddress.Address.Line2
                ? Application?.CreatedBy.ProvisionalAddress.Address.Line2
                : "-"
              : "-"
            : "-"
          : Application?.Applicant?.ProvisionalAddress
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.Line2
              ? Application?.Applicant?.ProvisionalAddress.Address.Line2
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Address Line 3"), 50, y, null, null);
    pdf.text(
      Application
        ? typeof Application?.CreatedBy === "object"
          ? Application?.CreatedBy && Application?.CreatedBy.ProvisionalAddress
            ? Application?.CreatedBy.ProvisionalAddress
              ? Application?.CreatedBy.ProvisionalAddress.Address.Line3
                ? Application?.CreatedBy.ProvisionalAddress.Address.Line3
                : "-"
              : "-"
            : "-"
          : Application?.Applicant?.ProvisionalAddress
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.Line3
              ? Application?.Applicant?.ProvisionalAddress.Address.Line3
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text(this.props?.t("State"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.State
              ? this.state.Language === "ta"
                ? "தமிழ்நாடு"
                : Application?.Applicant?.ProvisionalAddress.Address.State.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("District"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.District
              ? this.state.Language === "ta"
                ? Application?.Applicant?.ProvisionalAddress.Address.District
                    .TamilName
                : Application?.Applicant?.ProvisionalAddress.Address.District
                    .Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Taluk"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.Taluk
              ? this.state.Language === "ta"
                ? Application?.Applicant?.ProvisionalAddress.Address.Taluk
                    .TamilName
                : Application?.Applicant?.ProvisionalAddress.Address.Taluk.Name
              : "-"
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text(this.props?.t("Pincode"), 50, y, null, null);
    pdf.text(
      Application
        ? Application?.Applicant
          ? Application?.Applicant?.ProvisionalAddress
            ? Application?.Applicant?.ProvisionalAddress.Address.Pincode
            : "-"
          : "-"
        : "-",
      175,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);
    y = y + 20;
    pdf.save(
      this.state?.viewValues
        ? (this.state?.viewValues &&
            this.state?.viewValues?.SRApplicationNoText) ||
            Application?.ApplicationNo + ".pdf"
        : "View Details.pdf"
    );
    if (Application?.ReasonDocuments?.length > 0) {
      Application.ReasonDocuments.forEach((doc, index) => {
        window.open(doc?.Url);
        // Uncomment this if the documents are images
        // pdf.addImage(doc?.Url, "PNG", 50, y, 100, 100);
        // y = y + 110;
      });
    }
  };
  fileUpload = (values) => {
    if (values.AppointmentGO) {
      this.setState({ loading: true });
      let formData = new FormData();
      if (values.AppointmentGO) {
        formData.append("AppointmentGO", values.AppointmentGO);
      }
      CallService(
        fileUpload.upload,
        MethodType.POST,
        false,
        formData,
        "",
        this.uploadFileResponse,
        true
      );
    }
    if (values.LastRenewalGO) {
      this.setState({ loading: true });
      let formData = new FormData();
      if (values.LastRenewalGO) {
        formData.append("LastRenewalGO", values.LastRenewalGO);
      }
      CallService(
        fileUpload.upload,
        MethodType.POST,
        false,
        formData,
        "",
        this.uploadFileResponse,
        true
      );
    }
    if (values.LastAreaExtendedGO) {
      this.setState({ loading: true });
      let formData = new FormData();
      if (values.LastAreaExtendedGO) {
        formData.append("LastAreaExtendedGO", values.LastAreaExtendedGO);
      }
      CallService(
        fileUpload.upload,
        MethodType.POST,
        false,
        formData,
        "",
        this.uploadFileResponse,
        true
      );
    }
  };
  uploadFileResponse = (data) => {
    console.log("data", data);
    if (data.result) {
      var url = data.result;
      this.handleUpdateGO(url);
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload Failed");
    }
  };

  handleUpdateGO = (values) => {
    console.log("values", values);
    let UserID =
      this.props?.viewRowId &&
      this.props?.viewRowId.CreatedBy &&
      this.props?.viewRowId.CreatedBy._id;
    if (values.AppointmentGO) {
      this.setState({ loading: true });
      CallService(
        newapplication.UpdateGOs + UserID,
        MethodType.PATCH,
        false,
        { Type: "New", GO: values.AppointmentGO },
        "",
        this.handleUpdateGOResponse
      );
    }
    if (values.LastRenewalGO) {
      this.setState({ loading: true });
      CallService(
        newapplication.UpdateGOs + UserID,
        MethodType.PATCH,
        false,
        { Type: "Renewal", GO: values.LastRenewalGO },
        "",
        this.handleUpdateGOResponse
      );
    }
    if (values.LastAreaExtendedGO) {
      this.setState({ loading: true });
      CallService(
        newapplication.UpdateGOs + UserID,
        MethodType.PATCH,
        false,
        { Type: "Area", GO: values.LastAreaExtendedGO },
        "",
        this.handleUpdateGOResponse
      );
    }
  };
  handleUpdateGOResponse = (data) => {
    console.log("data", data);
    if (data.result) {
      var url = data.result;
      toastr.success("", "GO's updated successfully");
      window.location.reload();
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "Something went weong");
    }
  };
  render() {
    console.log("Role of the renewal Application", this.state.viewValues);
    return (
      <Fragment>
        <Fragment>
          <Button
            className="form-second-btn float-right"
            type="button"
            onClick={() => this.DownloadFormAsPDF()}
            color="success"
          >
            Download as PDF{" "}
          </Button>

          <br></br>
          <br></br>
          <br></br>

          {this.props?.location.pathname === "/annual_return" ||
          this.props?.location.pathname === "/annualreturn" ||
          (this.state.viewValues &&
            this.state.viewValues.GenerateCertificate) ===
            "GenerateCertificate" ? (
            ""
          ) : (this.state.viewValues &&
              this.state.viewValues.Status === "Approved") ||
            (this.state.viewValues &&
              this.state.viewValues.Status === "Interview Cleared") ? (
            <div className="col-12 pointer text-uppercase text-center">
              <Alert color="success">
                {this.props?.t(
                  this.state.viewValues ? this.state.viewValues.Status : ""
                )}
              </Alert>
            </div>
          ) : this.state.viewValues &&
            this.state.viewValues.Status === "Rejected" ? (
            <div className="col-12 pointer text-uppercase text-center">
              <Alert color="danger">
                {this.props?.t(
                  this.state.viewValues ? this.state.viewValues.Status : ""
                )}
              </Alert>
            </div>
          ) : (
            <div className="col-12 pointer text-uppercase text-center">
              <Alert color="primary">
                {this.props?.t(
                  this.state.viewValues ? this.state.viewValues.Status : ""
                )}
              </Alert>
            </div>
          )}

          <Nav tabs className="nav-tabs-custom nav-justified">
            {this.state.tabs?.map((t) => {
              return (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={{
                      active: this.state.activeFirstTab === t.id,
                    }}
                    onClick={() => {
                      this.toggleCustomJustified(t.id);
                    }}
                  >
                    <span className="d-none d-sm-block">{t.Name}</span>
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <br></br>

          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={1}>
              {this.state.viewValues &&
              this.state.viewValues.GenerateCertificate ===
                "GenerateCertificate" ? (
                this.state.viewValues &&
                this.state.viewValues.ActionTaken &&
                this.state.viewValues.ActionTaken[
                  this.state.viewValues.ActionTaken.length - 1
                ].On ? (
                  moment(
                    new Date(
                      this.state.viewValues.ActionTaken[
                        this.state.viewValues.ActionTaken.length - 1
                      ].On
                    )
                  )
                ) : (
                  ""
                )
              ) : this.props?.location.pathname ===
                "/existing_notary_applications" ? (
                <Row className="mb-4">
                  <Colxx xxs="12">
                    {this.state?.viewValues?.SRApplicationNo && (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props.t("Sub - Registration Number")}
                          </label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state?.viewValues?.SRApplicationNo
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    )}
                    {this.state?.viewValues?.ApplicationNo && (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Application Number")}</label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state?.viewValues?.ApplicationNo
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    )}

                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Serial Number")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state.viewValues.NotaryApplication
                              ? this.state.viewValues.NotaryApplication
                                  .SerialNumber
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Submitted On")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication
                                .SubmittedOnDate
                              ? moment(
                                  new Date(
                                    this.state.viewValues.NotaryApplication?.SubmittedOnDate
                                  )
                                ).format("DD/MM/YYYY hh:mm a")
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Issued Date")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication?.ValidFrom
                              ? moment(
                                  new Date(
                                    this.state.viewValues.NotaryApplication?.ValidFrom
                                  )
                                ).format("DD/MM/YYYY hh:mm a")
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Expiry Date")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication?.ValidTo
                              ? moment(
                                  new Date(
                                    this.state.viewValues.NotaryApplication?.ValidTo
                                  )
                                ).format("DD/MM/YYYY hh:mm a")
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("GO Number")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state.viewValues.NotaryApplication
                              ? this.state.viewValues.NotaryApplication
                                  ?.GONumber
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    {/* <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Grounds")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state.viewValues.NotaryApplication
                              ? this.state.viewValues.NotaryApplication?.District
                                ? this.state.Language === "ta"
                                  ? this.state.viewValues.NotaryApplication
                                      .District.TamilName
                                  : this.state.viewValues.NotaryApplication
                                      .District.Name
                                : "-"
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row> */}
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Taluks")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues &&
                        this.state.viewValues.NotaryApplication
                          ? this.state.viewValues.NotaryApplication?.Taluks
                            ? this.state.viewValues.NotaryApplication?.Taluks?.map(
                                (t) => {
                                  return t
                                    ? this.state.Language === "ta"
                                      ? t.TamilName
                                      : t.Name
                                    : "";
                                }
                              ).join(",  ")
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    {/* <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Grounds")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state.viewValues.NotaryApplication
                              ? this.state.viewValues.NotaryApplication?.Grounds
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row> */}
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("GO")}</label>
                      </Colxx>
                      <div>{":  "}</div>
                      {this.state.viewValues &&
                      this.state.viewValues?.NotaryApplication &&
                      this.state.viewValues?.NotaryApplication?.GO?.includes(
                        ".pdf"
                      ) ? (
                        <Colxx xxs="5">
                          <FileDisplay
                            Value={this.state.viewValues?.NotaryApplication?.GO}
                          />
                        </Colxx>
                      ) : (
                        <Fragment>
                          <Colxx xxs="4">
                            <img
                              alt="noimg"
                              id="userimg"
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100px",
                                objectFit: "contain",
                              }}
                              src={
                                this.state.viewValues &&
                                this.state?.viewValues?.NotaryApplication
                                  ? this.state.viewValues?.NotaryApplication?.GO
                                  : ""
                              }
                              onClick={() =>
                                this.togglePhotoViewModal(
                                  this.state.viewValues?.NotaryApplication?.GO,
                                  "GO"
                                )
                              }
                            />
                          </Colxx>
                        </Fragment>
                      )}
                    </Row>

                    {/* {this.state.viewValues
             && this.state.viewValues.RenewalApplications
             ? 
               this.state.viewValues.RenewalApplications.length > 0
                 ? this.state.viewValues.RenewalApplications?.map((val, i) => {
                     return (
                       <Fragment>
                         <Row>
                           <Colxx xxs="5" className="printLabel">
                             <label>{this.props?.t("Submitted On")}</label>
                           </Colxx>
                           {":  "}
                           <Colxx xxs="5">
                           {this.state.viewValues
                   ? this.state.viewValues.RenewalApplications[i]
                     ? this.state.viewValues.RenewalApplications[i]
                         .SubmittedOnDate
                       ? moment(
                           new Date(
                             this.state.viewValues.RenewalApplications[i].SubmittedOnDate
                           )
                         ).format("DD/MM/YYYY hh:mm a")
                       : "-"
                     : "-"
                   : "-"}
                           </Colxx>
                         </Row>
                         <Row>
                           <Colxx xxs="5" className="printLabel">
                             <label>{this.props?.t("Submitted On")}</label>
                           </Colxx>
                           {":  "}
                           <Colxx xxs="5">
                           {this.state.viewValues
                   ? this.state.viewValues.RenewalApplications
                     ? this.state.viewValues.RenewalApplications[i]
                         .SubmittedOnDate
                       ? moment(
                           new Date(
                             this.state.viewValues.RenewalApplications[i].SubmittedOnDate
                           )
                         ).format("DD/MM/YYYY hh:mm a")
                       : "-"
                     : "-"
                   : "-"}
                           </Colxx>
                         </Row>
                         <Row>
                           <Colxx xxs="5" className="printLabel">
                             <label>{this.props?.t("Valid From")}</label>
                           </Colxx>
                           {":  "}
                           <Colxx xxs="5">
                           {this.state.viewValues
                   ? this.state.viewValues.RenewalApplications
                     ? this.state.viewValues.RenewalApplications[i]
                         .ValidFrom
                       ? moment(
                           new Date(
                             this.state.viewValues.RenewalApplications[i].ValidFrom
                           )
                         ).format("DD/MM/YYYY hh:mm a")
                       : "-"
                     : "-"
                   : "-"}
                           </Colxx>
                         </Row>

                         <Row>
                           <Colxx xxs="5" className="printLabel">
                             <label>{this.props?.t("Valid To")}</label>
                           </Colxx>
                           {":  "}
                           <Colxx xxs="5">
                           {this.state.viewValues
                   ? this.state.viewValues.RenewalApplications
                     ? this.state.viewValues.RenewalApplications[i]
                         .ValidTo
                       ? moment(
                           new Date(
                             this.state.viewValues.RenewalApplications[i].ValidTo
                           )
                         ).format("DD/MM/YYYY hh:mm a")
                       : "-"
                     : "-"
                   : "-"}
                           </Colxx>
                         </Row>
                         <Row>
                           <Colxx xxs="5" className="printLabel">
                             <label>{this.props?.t("GO")}</label>
                           </Colxx>
                           {":  "}
 
                           {this.state.viewValues &&
                           this.state.viewValues.RenewalApplications ? (
                             <Colxx xxs="4">
                               <img
                                 alt="noimg"
                                 id="userimg"
                                 style={{
                                   width: "100%",
                                   height: "100px",
                                   objectFit: "contain",
                                   cursor: "pointer",
                                 }}
                                 src={
                                   this.state.viewValues &&
                                   this.state.viewValues.RenewalApplications
                                     ? this.state.viewValues.RenewalApplications[i].GO
                                         
                                     : ""
                                 }
                                 onClick={() =>
                                   this.togglePhotoViewModal(
                                     this.state.viewValues &&
                                       this.state.viewValues.RenewalApplications &&
                                       this.state.viewValues.RenewalApplications[i].GO,
                                         
                                     "Renewal Application GO"
                                   )
                                 }
                               />
                             </Colxx>
                           ) : (
                             ""
                           )}
                         </Row>
                       </Fragment>
                     );
                   })
                 : ""               
                           
             : ""}  */}
                  </Colxx>
                </Row>
              ) : (
                <Row className="mb-4">
                  <Colxx xxs="12">
                    {this.state?.viewValues?.SRApplicationNo && (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props.t("Sub - Registration Number")}
                          </label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state?.viewValues?.SRApplicationNo
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    )}
                    {this.state?.viewValues?.ApplicationNo && (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Application Number")}</label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state?.viewValues?.ApplicationNo
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    )}

                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Submitted On")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? moment(
                                new Date(
                                  this.state.viewValues.SubmittedOn * 1000
                                )
                              ).format("DD/MM/YYYY hh:mm a")
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>

                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Username")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.CreatedBy
                            ? this.state.viewValues.CreatedBy.Username
                              ? this.state.viewValues.CreatedBy.Username
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Name")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.Applicant
                            ? this.state.viewValues.Applicant?.Title
                              ? this.state.viewValues.Applicant?.Title.Name +
                                ". "
                              : ""
                            : ""
                          : ""}
                        {this.state.viewValues
                          ? this.state.viewValues.Applicant
                            ? this.state.viewValues.Applicant?.Name
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Name(in tamil)")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.Applicant
                            ? this.state.viewValues.Applicant?.TamilName
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("District")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.District
                            ? this.state.Language === "en"
                              ? this.state.viewValues.District.Name
                              : this.state.viewValues.District.TamilName
                            : this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication?.District
                              ? this.state.Language === "ta"
                                ? this.state.viewValues.NotaryApplication
                                    .District.TamilName
                                : this.state.viewValues.NotaryApplication
                                    .District.Name
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Taluks")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.Taluks
                            ? this.state.viewValues.Taluks?.map((t) => {
                                return t
                                  ? this.state.Language === "en"
                                    ? t.Name
                                    : t.TamilName
                                  : "";
                              }).join(",  ")
                            : this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication?.Taluks
                              ? this.state.viewValues.NotaryApplication?.Taluks?.map(
                                  (t) => {
                                    return t
                                      ? this.state.Language === "ta"
                                        ? t.TamilName
                                        : t.Name
                                      : "";
                                  }
                                ).join(",  ")
                              : "-"
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                    {this.props?.location.pathname ===
                    "/area_extension_applications" ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props?.t("Reason")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues.Reason
                              ? this.state.viewValues.Reason
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.props?.location.pathname ===
                      "/renewal_applications" ||
                    this.props?.location.pathname === "/renewal" ? (
                      <Fragment>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Issued Date")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.ValidFrom
                                ? moment(
                                    this.state.viewValues.ValidFrom
                                  ).format("DD-MM-YYYY")
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>

                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Expiry Date")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.ValidTo
                                ? moment(this.state.viewValues.ValidTo).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                      </Fragment>
                    ) : (
                      ""
                    )}
                    {/* <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Grounds")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues.Grounds
                            ? this.state.viewValues.Grounds
                            : this.state.viewValues &&
                              this.state.viewValues.NotaryApplication
                            ? this.state.viewValues.NotaryApplication?.Grounds
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row> */}

                    {/* <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props?.t("Remarks")}</label>
                      </Colxx>
                      {":  "}
                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state.viewValues.Remarks
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row> */}
                    {this.state.viewValues &&
                    this.state.viewValues.InterviewDetails &&
                    this.state.viewValues.InterviewDetails.Date ? (
                      <Fragment>
                        <h5>{this.props?.t("Interview Details")}</h5>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Date")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.InterviewDetails
                                ? this.state.viewValues.InterviewDetails.Date
                                  ? moment(
                                      this.state.viewValues.InterviewDetails
                                        .Date
                                    ).format("DD-MM-YYYY")
                                  : "-"
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Session")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.InterviewDetails
                                ? this.state.viewValues.InterviewDetails.Session
                                  ? this.state.viewValues.InterviewDetails
                                      .Session.Name
                                  : "-"
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Location")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.InterviewDetails
                                ? this.state.viewValues.InterviewDetails
                                    .Location
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </Colxx>
                </Row>
              )}
            </TabPane>
          </TabContent>

          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={2}>
              <Row className="mb-4">
                <Colxx xxs="12">
                  {this.state?.viewValues?.SRApplicationNo && (
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>
                          {this.props.t("Sub - Registration Number")}
                        </label>
                      </Colxx>
                      {":  "}

                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state?.viewValues?.SRApplicationNo
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                  )}
                  {this.state?.viewValues?.ApplicationNo && (
                    <Row>
                      <Colxx xxs="5" className="printLabel">
                        <label>{this.props.t("Application Number")}</label>
                      </Colxx>
                      {":  "}

                      <Colxx xxs="5">
                        {this.state.viewValues
                          ? this.state.viewValues
                            ? this.state?.viewValues?.ApplicationNo
                            : "-"
                          : "-"}
                      </Colxx>
                    </Row>
                  )}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Name")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.Name
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Name(in tamil)")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.TamilName
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Gender")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.Gender
                            ? this.state.viewValues.Applicant?.Gender.Name
                              ? this.state.viewValues.Applicant?.Gender.Name.en
                                ? this.state.viewValues.Applicant?.Gender.Name
                                    .en
                                : this.state.viewValues.Applicant?.Gender.Name
                              : "-"
                            : "-"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Married")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.IsMarried
                            ? "Yes"
                            : "No"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      {this.state.viewValues &&
                      this.state.viewValues.Applicant &&
                      this.state.viewValues.Applicant?.Title &&
                      this.state.viewValues.Applicant?.Title._id ===
                        TITLE.THIRUMATHI ? (
                        <label>{this.props?.t("Husband Name")}</label>
                      ) : (
                        <label>{this.props?.t("Father Name")}</label>
                      )}
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.FatherOrHusbandName
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Date Of Birth")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant
                            ? moment(
                                this.state.viewValues.Applicant?.Dob
                              ).format("DD-MM-YYYY")
                            : "-"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Community")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.Community
                            ? this.state.viewValues.Applicant?.Community.Name
                            : "-"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Mobile Number")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.MobileNumber
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Email ID")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.EmailID
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Aadhar Number")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.Aadhar
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>{" "}
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Form Type")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.viewValues
                        ? this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant?.FormType
                            ? this.state.viewValues.Applicant?.FormType?.Name
                            : "-"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>
                  {this.state.viewValues ? (
                    this.state.viewValues.Applicant ? (
                      this.state.viewValues.Applicant?.FormType ? (
                        this.state.viewValues.Applicant?.FormType?.Name ===
                          "Form-II" ||
                        this.state.viewValues.Applicant?.FormType?.Name ===
                          "படிவம்-II" ? (
                          <Fragment>
                            <Row>
                              <Colxx xxs="5" className="printLabel">
                                <label>
                                  {this.props?.t(
                                    "Date of Joining Goverment Service"
                                  )}
                                </label>
                              </Colxx>
                              {":  "}
                              <Colxx xxs="5">
                                {this.state.viewValues
                                  ? this.state.viewValues.Applicant
                                    ? this.state.viewValues.Applicant
                                        .GovtServiceStartDate
                                      ? moment(
                                          this.state.viewValues.Applicant
                                            .GovtServiceStartDate
                                        ).format("DD-MM-YYYY")
                                      : "-"
                                    : "-"
                                  : "-"}
                              </Colxx>
                            </Row>
                            <Row>
                              <Colxx xxs="5" className="printLabel">
                                <label>
                                  {this.props?.t(
                                    "Date of Retirement / Relief from Goverment Service"
                                  )}
                                </label>
                              </Colxx>
                              {":  "}
                              <Colxx xxs="5">
                                {this.state.viewValues
                                  ? this.state.viewValues.Applicant
                                    ? this.state.viewValues.Applicant
                                        .GovtServiceEndDate
                                      ? moment(
                                          this.state.viewValues.Applicant
                                            .GovtServiceEndDate
                                        ).format("DD-MM-YYYY")
                                      : "-"
                                    : "-"
                                  : "-"}
                              </Colxx>
                            </Row>
                            <Row>
                              <Colxx xxs="5" className="printLabel">
                                <label>
                                  {this.props?.t(
                                    "Post held at the time of retirement / relief"
                                  )}
                                </label>
                              </Colxx>
                              {":  "}
                              <Colxx xxs="5">
                                {this.state.viewValues
                                  ? this.state.viewValues.Applicant
                                    ? this.state.viewValues.Applicant
                                        .GovtServicePost
                                      ? this.state.viewValues.Applicant
                                          .GovtServicePost
                                      : "-"
                                    : "-"
                                  : "-"}
                              </Colxx>
                            </Row>
                          </Fragment>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <Colxx xxs="12">
                    <Row>
                      <Colxx xxs="4" className="printLabel">
                        <label>{this.props?.t("Photo")}</label>
                      </Colxx>
                      {this.state.viewValues &&
                      this.state.viewValues.Applicant ? (
                        this.state.viewValues.Applicant?.AadharCopy ? (
                          <Colxx xxs="4" className="printLabel">
                            <label>
                              {this.props?.t("Copy of Aadhaar (Self Attested)")}
                            </label>
                          </Colxx>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Row>
                  </Colxx>
                  <Colxx xxs="12">
                    <Row>
                      {this.state.viewValues &&
                      this.state.viewValues?.Applicant &&
                      this.state.viewValues?.Applicant?.Photo &&
                      this.state.viewValues?.Applicant?.Photo?.includes(
                        ".pdf"
                      ) ? (
                        <Colxx xxs="5">
                          <FileDisplay
                            Value={this.state.viewValues?.Applicant?.Photo}
                          />
                        </Colxx>
                      ) : (
                        <Colxx xxs="4">
                          <img
                            alt="noimg"
                            id="userimg"
                            style={{
                              width: "100%",
                              height: "100px",
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            src={
                              this.state.viewValues?.Applicant
                                ? this.state.viewValues?.Applicant?.Photo
                                : ""
                            }
                            onClick={() =>
                              this.togglePhotoViewModal(
                                this.state.viewValues.Applicant &&
                                  this.state.viewValues.Applicant?.Photo,
                                "Photo"
                              )
                            }
                          />
                        </Colxx>
                      )}

                      {this.state.viewValues &&
                      this.state.viewValues.Applicant &&
                      this.state.viewValues?.Applicant?.AadharCopy &&
                      this.state.viewValues?.Applicant?.AadharCopy?.includes(
                        ".pdf"
                      ) ? (
                        <Fragment>
                          <Colxx xxs="5">
                            <FileDisplay
                              Value={
                                this.state.viewValues?.Applicant?.AadharCopy
                              }
                            />
                          </Colxx>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Colxx xxs="4">
                            <img
                              alt="noimg"
                              id="userimg"
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100px",
                                objectFit: "contain",
                              }}
                              src={
                                this.state.viewValues?.Applicant
                                  ? this.state.viewValues?.Applicant?.AadharCopy
                                  : ""
                              }
                              onClick={() =>
                                this.togglePhotoViewModal(
                                  this.state.viewValues?.Applicant?.AadharCopy,
                                  "Copy Of Aadhar"
                                )
                              }
                            />
                          </Colxx>
                        </Fragment>
                      )}
                    </Row>
                  </Colxx>
                </Colxx>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={3}>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Bar Council Enrolment Number")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.BarCouncilNumber
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Enrolment Date")}</label>
                </Colxx>
                {":  "}

                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant
                        ? moment(
                            this.state.viewValues.Applicant?.EnrolmentDate
                          ).format("DD-MM-YYYY")
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Practicing In")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.PracticingIn.length > 0
                        ? this.state.viewValues.Applicant?.PracticingIn?.map(
                            (t) => {
                              return t ? t.Name : "";
                            }
                          ).join(",  ")
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Landline Number")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.CreatedBy
                      ? this.state.viewValues.CreatedBy.LandlineNumber
                        ? this.state.viewValues.CreatedBy.LandlineNumber
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Fax")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.CreatedBy
                      ? this.state.viewValues.CreatedBy.Fax
                        ? this.state.viewValues.CreatedBy.Fax
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Income Tax Assessee")}</label>
                </Colxx>
                {":  "}

                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.IncomeTaxAssessee ===
                        true
                        ? "Yes"
                        : "No"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("PAN Number")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.PANNumber
                        ? this.state.viewValues.Applicant?.PANNumber
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              <Colxx xxs="12">
                <Row>
                  <Colxx xxs="4" className="printLabel">
                    <label>{this.props?.t("Bar Council Certificate")}</label>
                  </Colxx>
                  {this.state.viewValues && this.state.viewValues.Applicant ? (
                    this.state.viewValues.Applicant?.PANCopy ? (
                      <Colxx xxs="4" className="printLabel">
                        <label>
                          {this.props?.t("PAN Card (Self Attested)")}
                        </label>
                      </Colxx>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Row>
              </Colxx>
              <Colxx xxs="12">
                <Row>
                  {this.state.viewValues &&
                  this.state.viewValues?.Applicant?.BarCouncilCertificate &&
                  this.state.viewValues?.Applicant?.BarCouncilCertificate?.includes(
                    ".pdf"
                  ) ? (
                    <Colxx xxs="5">
                      <FileDisplay
                        Value={
                          this.state.viewValues?.Applicant
                            ?.BarCouncilCertificate
                        }
                      />
                    </Colxx>
                  ) : (
                    <Colxx xxs="4">
                      <img
                        alt="noimg"
                        id="userimg"
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        src={
                          this.state.viewValues?.Applicant
                            ? this.state.viewValues?.Applicant
                                ?.BarCouncilCertificate
                            : ""
                        }
                        onClick={() =>
                          this.togglePhotoViewModal(
                            this.state.viewValues?.Applicant &&
                              this.state.viewValues?.Applicant
                                ?.BarCouncilCertificate,
                            "Bar Council Certificate"
                          )
                        }
                      />
                    </Colxx>
                  )}

                  {this.state.viewValues &&
                  this.state.viewValues.Applicant &&
                  this.state.viewValues.Applicant?.PANCopy &&
                  this.state.viewValues.Applicant?.PANCopy?.includes(".pdf") ? (
                    <Colxx xxs="5">
                      <FileDisplay
                        Value={this.state.viewValues?.Applicant?.PANCopy}
                      />
                    </Colxx>
                  ) : (
                    "-"
                  )}
                </Row>
              </Colxx>
            </TabPane>
          </TabContent>
          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={4}>
              {this.state.viewValues &&
              this.state.viewValues?.CreatedBy &&
              this.state.viewValues?.CreatedBy?.Degrees &&
              this.state.viewValues?.CreatedBy?.Degrees?.length > 0
                ? this.state.viewValues?.CreatedBy?.Degrees?.map((val, i) => {
                    return (
                      <Fragment>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <strong>
                              {this.state.viewValues
                                ? this.state.viewValues.CreatedBy
                                  ? this.state.viewValues.CreatedBy
                                      .IsIntegratedDegree
                                    ? this.props?.t(
                                        "Integrated Degree (5 Years)"
                                      )
                                    : this.props?.t(
                                        "Non-Integrated Degree (3 Years)"
                                      )
                                  : "-"
                                : "-"}
                            </strong>
                          </Colxx>

                          <Colxx xxs="5"></Colxx>
                        </Row>

                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Institution")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.CreatedBy
                                ? this.state.viewValues.CreatedBy.Degrees[i]
                                  ? this.state.viewValues.CreatedBy.Degrees[i]
                                      .Institution
                                  : "-"
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Degree")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues.CreatedBy
                                ? this.state.viewValues.CreatedBy.Degrees[i]
                                  ? this.state.viewValues.CreatedBy.Degrees[i]
                                      .Name
                                  : "-"
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>{this.props?.t("Year Of Passing")}</label>
                          </Colxx>
                          {":  "}
                          <Colxx xxs="5">
                            {this.state.viewValues
                              ? this.state.viewValues?.CreatedBy
                                ? this.state.viewValues?.CreatedBy?.Degrees[i]
                                  ? this.state.viewValues?.CreatedBy?.Degrees[i]
                                      .YearOfPassing
                                  : "-"
                                : "-"
                              : "-"}
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xxs="5" className="printLabel">
                            <label>
                              {this.state.viewValues.CreatedBy
                                ?.IsIntegratedDegree
                                ? this.props?.t(
                                    "Copy Of Law Degree (Self Attested)"
                                  )
                                : this.props?.t(
                                    "Copy Of Graduation Degree (Self Attested)"
                                  )}
                            </label>
                          </Colxx>
                          {":  "}

                          {this.state.viewValues &&
                          this.state.viewValues?.CreatedBy?.Degrees?.[
                            i
                          ]?.Url?.includes(".pdf") ? (
                            <Colxx xxs="5">
                              <FileDisplay
                                Value={
                                  this.state.viewValues?.CreatedBy?.Degrees[i]
                                    ?.Url
                                }
                              />
                            </Colxx>
                          ) : this.state.viewValues?.CreatedBy?.Degrees[i]
                              ?.Url ? (
                            <Colxx xxs="4">
                              <img
                                alt="noimg"
                                id="userimg"
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                                src={
                                  this.state.viewValues &&
                                  this.state.viewValues?.CreatedBy
                                    ? this.state.viewValues?.CreatedBy?.Degrees[
                                        i
                                      ].Url
                                    : ""
                                }
                                onClick={() =>
                                  this.togglePhotoViewModal(
                                    this.state.viewValues &&
                                      this.state.viewValues?.CreatedBy &&
                                      this.state.viewValues?.CreatedBy?.Degrees[
                                        i
                                      ]?.Url,
                                    "Copy Of Law Degree"
                                  )
                                }
                              />
                            </Colxx>
                          ) : (
                            "-"
                          )}
                        </Row>
                      </Fragment>
                    );
                  })
                : ""}
            </TabPane>
          </TabContent>
          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={5}>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 1")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                          ? this.state.viewValues.CreatedBy.ResidencyAddress
                              .Address.Line1
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                        ? this.state.viewValues.Applicant?.ResidencyAddress
                            .Address.Line1
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 2")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                          ? this.state.viewValues.CreatedBy.ResidencyAddress
                              .Address.Line2
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                          .Address.Line2
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 3")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                          ? this.state.viewValues.CreatedBy.ResidencyAddress
                              .Address.Line3
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                          .Address.Line3
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("State")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                        ? this.state.viewValues.Applicant?.ResidencyAddress
                            .Address.State
                          ? this.state.Language === "ta"
                            ? "தமிழ்நாடு"
                            : this.state.viewValues.Applicant?.ResidencyAddress
                                .Address.State.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("District")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                        ? this.state.viewValues.Applicant?.ResidencyAddress
                            .Address.District
                          ? this.state.Language === "ta"
                            ? this.state.viewValues.Applicant?.ResidencyAddress
                                .Address.District.TamilName
                            : this.state.viewValues.Applicant?.ResidencyAddress
                                .Address.District.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Taluk")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                        ? this.state.viewValues.Applicant?.ResidencyAddress
                            .Address.Taluk
                          ? this.state.Language === "ta"
                            ? this.state.viewValues.Applicant?.ResidencyAddress
                                .Address.Taluk.TamilName
                            : this.state.viewValues.Applicant?.ResidencyAddress
                                .Address.Taluk.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Pincode")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                          ? this.state.viewValues.CreatedBy.ResidencyAddress
                              .Address.Pincode
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                          .Address.Pincode
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              {/* <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Proof Type")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                            .ProofType
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                          .ProofType
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Proof ID Number")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy.ResidencyAddress
                        ? this.state.viewValues.CreatedBy.ResidencyAddress
                            .IDProofNumber
                        : "-"
                      : this.state.viewValues.Applicant?.ResidencyAddress
                      ? this.state.viewValues.Applicant?.ResidencyAddress
                          .IDProofNumber
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Copy of Proof")}</label>
                </Colxx>
                {":  "}
                {this.state.viewValues && this.state.viewValues.CreatedBy ? (
                  <Colxx xxs="4">
                    <img
                      alt="noimg"
                      id="userimg"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      src={
                        this.state.viewValues.Applicant
                          ? this.state.viewValues.Applicant &&
                            this.state.viewValues.Applicant?.ResidencyAddress &&
                            this.state.viewValues.Applicant?.ResidencyAddress
                              .ProofUrl
                          : ""
                      }
                      onClick={() =>
                        this.togglePhotoViewModal(
                          this.state.viewValues &&
                            this.state.viewValues.Applicant &&
                            this.state.viewValues.Applicant?.ResidencyAddress &&
                            this.state.viewValues.Applicant?.ResidencyAddress
                              .ProofUrl,
                          "Copy of Proof"
                        )
                      }
                    />
                  </Colxx>
                ) : (
                  ""
                )}
              </Row> */}
            </TabPane>
          </TabContent>

          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={6}>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 1")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ProvisionalAddress
                        ? this.state.viewValues.CreatedBy.ProvisionalAddress
                          ? this.state.viewValues.CreatedBy.ProvisionalAddress
                              .Address.Line1
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ProvisionalAddress
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                          .Address.Line1
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 2")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy
                        ? this.state.viewValues.CreatedBy &&
                          this.state.viewValues.CreatedBy.ProvisionalAddress
                          ? this.state.viewValues.CreatedBy.ProvisionalAddress
                              .Address.Line2
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ProvisionalAddress
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                          .Address.Line2
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Address Line 3")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? typeof this.state.viewValues.CreatedBy === "object"
                      ? this.state.viewValues.CreatedBy &&
                        this.state.viewValues.CreatedBy.ProvisionalAddress
                        ? this.state.viewValues.CreatedBy.ProvisionalAddress
                          ? this.state.viewValues.CreatedBy.ProvisionalAddress
                              .Address.Line3
                          : "-"
                        : "-"
                      : this.state.viewValues.Applicant?.ProvisionalAddress
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                          .Address.Line3
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("State")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .Address.State
                          ? this.state.Language === "ta"
                            ? "தமிழ்நாடு"
                            : this.state.viewValues.Applicant
                                ?.ProvisionalAddress.Address.State.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("District")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .Address.District
                          ? this.state.Language === "ta"
                            ? this.state.viewValues.Applicant
                                ?.ProvisionalAddress.Address.District.TamilName
                            : this.state.viewValues.Applicant
                                ?.ProvisionalAddress.Address.District.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Taluk")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .Address.Taluk
                          ? this.state.Language === "ta"
                            ? this.state.viewValues.Applicant
                                ?.ProvisionalAddress.Address.Taluk.TamilName
                            : this.state.viewValues.Applicant
                                ?.ProvisionalAddress.Address.Taluk.Name
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Pincode")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .Address.Pincode
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              {/* <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Proof Type")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .ProofType
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>{" "}
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Proof ID Number")}</label>
                </Colxx>
                {":  "}
                <Colxx xxs="5">
                  {this.state.viewValues
                    ? this.state.viewValues.Applicant
                      ? this.state.viewValues.Applicant?.ProvisionalAddress
                        ? this.state.viewValues.Applicant?.ProvisionalAddress
                            .IDProofNumber
                        : "-"
                      : "-"
                    : "-"}
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="5" className="printLabel">
                  <label>{this.props?.t("Copy of Proof")}</label>
                </Colxx>
                {":  "}

                {this.state.viewValues && this.state.viewValues.CreatedBy ? (
                  <Colxx xxs="4">
                    <img
                      alt="noimg"
                      id="userimg"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      src={
                        this.state.viewValues
                          ? this.state.viewValues.Applicant &&
                            this.state.viewValues.Applicant
                              .ProvisionalAddress &&
                            this.state.viewValues.Applicant?.ProvisionalAddress
                              .ProofUrl
                          : ""
                      }
                      onClick={() =>
                        this.togglePhotoViewModal(
                          this.state.viewValues &&
                            this.state.viewValues.Applicant &&
                            this.state.viewValues.Applicant
                              .ProvisionalAddress &&
                            this.state.viewValues.Applicant?.ProvisionalAddress
                              .ProofUrl,
                          "Copy of Proof"
                        )
                      }
                    />
                  </Colxx>
                ) : (
                  ""
                )}
              </Row> */}
            </TabPane>
          </TabContent>

          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={7}>
              <Formik onSubmit={this.fileUpload} initialValues={{}}>
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    {this.state.GOS && this.state.GOS.AppointmentDate ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props?.t("Appointment Date")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.GOS
                            ? this.state.GOS.AppointmentDate
                              ? moment(this.state.GOS.AppointmentDate).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}
                    {this.state.GOS && this.state.GOS.AppointmentGO ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props?.t("Appointment GO")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.Role === "law_admin" ? (
                            <FormGroup className="form-group has-float-label">
                              <CustomInput
                                key={"Url"}
                                type="file"
                                name={"Url"}
                                onChange={(event) => {
                                  setFieldValue(
                                    `AppointmentGO`,
                                    event.target.files[0]
                                  );
                                }}
                              />
                              <p className="text-semi-muted">
                                {getAllowedExtensionText("pdf", [
                                  "png",
                                  "doc",
                                  "pdf",
                                  "jpg",
                                  "jpeg",
                                ])}
                                {this.state.GOS &&
                                this.state.GOS.AppointmentGO ? (
                                  <p
                                    style={{
                                      color: "blue",
                                      cursor: "Pointer",
                                      textAlign: "right",
                                    }}
                                  >
                                    <FileDisplay
                                      Value={this.state.GOS.AppointmentGO}
                                    />
                                  </p>
                                ) : (
                                  ""
                                )}
                              </p>

                              {/* <p className="text-semi-muted">
                                              Allowed formats are png, doc, pdf,
                                              jpg and jpeg
                                              {this.state.Application &&
                                              this.state.Application?.Degrees &&
                                              this.state.Application?.Degrees[
                                                Index
                                              ].Url ? (
                                                <p
                                                  style={{
                                                    cursor: "Pointer",
                                                    color: "blue",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <FileDisplay
                                                    Value={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  /> */}
                              {/* <span
                                                    onClick={() =>
                                                      this.toggleImageView(
                                                        this.state.Application
                                                          .Degrees[Index].Url
                                                      )
                                                    }
                                                  >
                                                    View |{" "}
                                                  </span>
                                                  <a
                                                    className="LinkStyle"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  >
                                                    Download{" "}
                                                  </a> */}
                              {/* </p>
                                              ) : (
                                                ""
                                              )}
                                            </p> */}
                            </FormGroup>
                          ) : (
                            <FileDisplay Value={this.state.GOS.AppointmentGO} />
                          )}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}
                    {this.state.GOS && this.state.GOS.LastRenewedDate ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props?.t("Last Renewed Date")}</label>
                        </Colxx>
                        <div>{":  "}</div>
                        <Fragment>
                          <Colxx xxs="4">
                            {this.state.GOS
                              ? this.state.GOS.LastRenewedDate
                                ? moment(this.state.GOS.LastRenewedDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"
                              : "-"}
                          </Colxx>
                        </Fragment>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.GOS && this.state.GOS.LastRenewalGO ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props?.t("Last Renewal GO")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {" "}
                          {this.state.Role === "law_admin" ? (
                            <FormGroup className="form-group has-float-label">
                              <CustomInput
                                key={"Url"}
                                type="file"
                                name={"Url"}
                                onChange={(event) => {
                                  setFieldValue(
                                    `LastRenewalGO`,
                                    event.target.files[0]
                                  );
                                }}
                              />
                              <p className="text-semi-muted">
                                {getAllowedExtensionText("pdf", [
                                  "png",
                                  "doc",
                                  "pdf",
                                  "jpg",
                                  "jpeg",
                                ])}
                                {this.state.GOS &&
                                this.state.GOS.LastRenewalGO ? (
                                  <p
                                    style={{
                                      color: "blue",
                                      cursor: "Pointer",
                                      textAlign: "right",
                                    }}
                                  >
                                    <FileDisplay
                                      Value={this.state.GOS.LastRenewalGO}
                                    />
                                  </p>
                                ) : (
                                  ""
                                )}
                              </p>

                              {/* <p className="text-semi-muted">
                                              Allowed formats are png, doc, pdf,
                                              jpg and jpeg
                                              {this.state.Application &&
                                              this.state.Application?.Degrees &&
                                              this.state.Application?.Degrees[
                                                Index
                                              ].Url ? (
                                                <p
                                                  style={{
                                                    cursor: "Pointer",
                                                    color: "blue",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <FileDisplay
                                                    Value={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  /> */}
                              {/* <span
                                                    onClick={() =>
                                                      this.toggleImageView(
                                                        this.state.Application
                                                          .Degrees[Index].Url
                                                      )
                                                    }
                                                  >
                                                    View |{" "}
                                                  </span>
                                                  <a
                                                    className="LinkStyle"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  >
                                                    Download{" "}
                                                  </a> */}
                              {/* </p>
                                              ) : (
                                                ""
                                              )}
                                            </p> */}
                            </FormGroup>
                          ) : (
                            <FileDisplay Value={this.state.GOS.LastRenewalGO} />
                          )}
                          {/* <FileDisplay Value={this.state.GOS.LastRenewalGO} /> */}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.GOS && this.state.GOS.LastAreaExtendedDate ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props?.t("Last Area Extended Date")}
                          </label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.GOS
                            ? this.state.GOS.LastAreaExtendedDate
                              ? moment(
                                  this.state.GOS.LastAreaExtendedDate * 1000
                                ).format("DD-MM-YYYY")
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.GOS && this.state.GOS.LastAreaExtendedGO ? (
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props?.t("Last Area Extended GO")}
                          </label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {" "}
                          {this.state.Role === "law_admin" ? (
                            <FormGroup className="form-group has-float-label">
                              <CustomInput
                                key={"Url"}
                                type="file"
                                name={"Url"}
                                onChange={(event) => {
                                  setFieldValue(
                                    `LastAreaExtendedGO`,
                                    event.target.files[0]
                                  );
                                }}
                              />
                              <p className="text-semi-muted">
                                {getAllowedExtensionText("pdf", [
                                  "png",
                                  "doc",
                                  "pdf",
                                  "jpg",
                                  "jpeg",
                                ])}
                                {this.state.GOS &&
                                this.state.GOS.LastAreaExtendedGO ? (
                                  <p
                                    style={{
                                      color: "blue",
                                      cursor: "Pointer",
                                      textAlign: "right",
                                    }}
                                  >
                                    <FileDisplay
                                      Value={this.state.GOS.LastAreaExtendedGO}
                                    />
                                  </p>
                                ) : (
                                  ""
                                )}
                              </p>

                              {/* <p className="text-semi-muted">
                                              Allowed formats are png, doc, pdf,
                                              jpg and jpeg
                                              {this.state.Application &&
                                              this.state.Application?.Degrees &&
                                              this.state.Application?.Degrees[
                                                Index
                                              ].Url ? (
                                                <p
                                                  style={{
                                                    cursor: "Pointer",
                                                    color: "blue",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <FileDisplay
                                                    Value={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  /> */}
                              {/* <span
                                                    onClick={() =>
                                                      this.toggleImageView(
                                                        this.state.Application
                                                          .Degrees[Index].Url
                                                      )
                                                    }
                                                  >
                                                    View |{" "}
                                                  </span>
                                                  <a
                                                    className="LinkStyle"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  >
                                                    Download{" "}
                                                  </a> */}
                              {/* </p>
                                              ) : (
                                                ""
                                              )}
                                            </p> */}
                            </FormGroup>
                          ) : (
                            <FileDisplay
                              Value={this.state.GOS.LastAreaExtendedGO}
                            />
                          )}
                        </Colxx>
                      </Row>
                    ) : (
                      ""
                    )}
                    {this.state.Role === "law_admin" && (
                      <FormGroup className="float-sm-right ">
                        <Button type="submit" style={{ background: "green" }}>
                          {"Update GO"}
                        </Button>
                      </FormGroup>
                    )}
                  </Form>
                )}
              </Formik>
              {/*      
{this.state.viewValues && this.state.viewValues.NotaryApplication && 
              this.state.viewValues.NotaryApplication?.GO ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Existing Notary Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.NotaryApplication?.GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )}

              {this.state.viewValues && this.state.viewValues.RenewalApplications && 
              this.state.viewValues.RenewalApplications.length > 0 ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Renewal Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.RenewalApplications[0].GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )}

{this.state.viewValues && this.state.viewValues.AreaExtensionApplications && 
              this.state.viewValues.AreaExtensionApplications.length > 0 ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Area Extension Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.AreaExtensionApplications[0].GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )} */}
            </TabPane>
          </TabContent>
          {this.state.viewValues &&
          this.state.viewValues.AdditionalDocuments &&
          this.state.viewValues.AdditionalDocuments.length > 0 ? (
            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId={8}>
                {this.state.viewValues &&
                this.state.viewValues.AdditionalDocuments &&
                this.state.viewValues.AdditionalDocuments.length > 0
                  ? this.state.viewValues.AdditionalDocuments?.map((val, i) => {
                      return (
                        <Fragment>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props?.t("Name")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.AdditionalDocuments
                                    ? this.state.viewValues.AdditionalDocuments[
                                        i
                                      ]
                                      ? this.state.viewValues
                                          .AdditionalDocuments[i].Name
                                      : "-"
                                    : "-"
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props?.t("Documents")}</label>
                            </Colxx>
                            {":  "}

                            {this.state.viewValues &&
                            this.state.viewValues.AdditionalDocuments ? (
                              <Colxx xxs="4">
                                {/* <img
                                  alt="noimg"
                                  id="userimg"
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                  }}
                                  src={
                                    this.state.viewValues &&
                                    this.state.viewValues.AdditionalDocuments &&
                                    this.state.viewValues.AdditionalDocuments
                                      .length > 0
                                      ? this.state.viewValues
                                          .AdditionalDocuments[i].Url
                                      : ""
                                  }
                                  onClick={() =>
                                    this.togglePhotoViewModal(
                                      this.state.viewValues &&
                                        this.state.viewValues
                                          .AdditionalDocuments[i].Url,
                                      "Documents"
                                    )
                                  }
                                /> */}
                                {this.state.viewValues &&
                                this.state.viewValues.AdditionalDocuments &&
                                this.state.viewValues.AdditionalDocuments
                                  .length > 0 ? (
                                  checkIfImage(
                                    this.state.viewValues &&
                                      this.state.viewValues
                                        .AdditionalDocuments &&
                                      this.state.viewValues.AdditionalDocuments
                                        .length > 0
                                      ? this.state.viewValues
                                          .AdditionalDocuments[i].Url
                                      : ""
                                  ) ? (
                                    <img
                                      alt="docView"
                                      src={
                                        this.state.viewValues &&
                                        this.state.viewValues
                                          .AdditionalDocuments &&
                                        this.state.viewValues
                                          .AdditionalDocuments.length > 0
                                          ? this.state.viewValues
                                              .AdditionalDocuments[i].Url
                                          : ""
                                      }
                                      onClick={() =>
                                        this.togglePhotoViewModal(
                                          this.state.viewValues &&
                                            this.state.viewValues
                                              .AdditionalDocuments[i].Url,
                                          "Documents"
                                        )
                                      }
                                      title="documentview"
                                      className="Img-Height"
                                      height="100px"
                                      width="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  ) : checkIfPdf(
                                      this.state.viewValues &&
                                        this.state.viewValues
                                          .AdditionalDocuments &&
                                        this.state.viewValues
                                          .AdditionalDocuments.length > 0
                                        ? this.state.viewValues
                                            .AdditionalDocuments[i].Url
                                        : ""
                                    ) ? (
                                    // <p
                                    //   onClick={() =>
                                    //     this.togglePhotoViewModal(
                                    //       this.state.viewValues &&
                                    //         this.state.viewValues
                                    //           .AdditionalDocuments[i].Url,
                                    //       "Documents"
                                    //     )
                                    //   }
                                    // >
                                    <FileDisplay
                                      // onLoad={this.hideSpinner}
                                      // key={this.state.reload}
                                      // title="documentview"
                                      // width="100%"
                                      // height="100px"
                                      // className="Img-Height"
                                      Value={
                                        this.state.viewValues &&
                                        this.state.viewValues
                                          .AdditionalDocuments &&
                                        this.state.viewValues
                                          .AdditionalDocuments.length > 0
                                          ? this.state.viewValues
                                              .AdditionalDocuments[i].Url
                                          : ""
                                      }
                                    />
                                  ) : (
                                    // </p>
                                    <img
                                      alt="Noimg"
                                      id="userimg"
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "contain",
                                        cursor: "pointer",
                                      }}
                                      src={
                                        this.state.viewValues &&
                                        this.state.viewValues
                                          .AdditionalDocuments &&
                                        this.state.viewValues
                                          .AdditionalDocuments.length > 0
                                          ? this.state.viewValues
                                              .AdditionalDocuments[i].Url
                                          : ""
                                      }
                                      onClick={() =>
                                        this.togglePhotoViewModal(
                                          this.state.viewValues &&
                                            this.state.viewValues
                                              .AdditionalDocuments[i].Url,
                                          "Documents"
                                        )
                                      }
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </Colxx>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Fragment>
                      );
                    })
                  : ""}
              </TabPane>
            </TabContent>
          ) : (
            ""
          )}
          {console.log(
            "this.state?.viewValues?.Reason",
            this.state?.viewValues
          )}
          {this.state?.viewValues ? (
            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId={9}>
                {this.state?.viewValues?.Reason ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Reason")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">{this.state?.viewValues?.Reason}</Colxx>
                  </Row>
                ) : (
                  ""
                )}
                {this.state?.viewValues?.Reason &&
                this.state?.viewValues?.ReasonDocuments?.length > 0 ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Reason Documents")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      <FileDisplay
                        Value={
                          this.state?.viewValues?.ReasonDocuments?.[0]?.Url
                        }
                      />
                    </Colxx>
                  </Row>
                ) : (
                  ""
                )}
                {/* {this.state.GOS && this.state.GOS.LastRenewedDate ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Last Renewed Date")}</label>
                    </Colxx>
                    <div>{":  "}</div>
                    <Fragment>
                      <Colxx xxs="4">
                        {this.state.GOS
                          ? this.state.GOS.LastRenewedDate
                            ? moment(this.state.GOS.LastRenewedDate).format(
                                "DD-MM-YYYY"
                              )
                            : "-"
                          : "-"}
                      </Colxx>
                    </Fragment>
                  </Row>
                ) : (
                  ""
                )}

                {this.state.GOS && this.state.GOS.LastRenewalGO ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Last Renewal GO")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      <FileDisplay Value={this.state.GOS.LastRenewalGO} />
                    </Colxx>
                  </Row>
                ) : (
                  ""
                )}

                {this.state.GOS && this.state.GOS.LastAreaExtendedDate ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Last Area Extended Date")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {this.state.GOS
                        ? this.state.GOS.LastAreaExtendedDate
                          ? moment(
                              this.state.GOS.LastAreaExtendedDate * 1000
                            ).format("DD-MM-YYYY")
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row>
                ) : (
                  ""
                )}

                {this.state.GOS && this.state.GOS.LastAreaExtendedGO ? (
                  <Row>
                    <Colxx xxs="5" className="printLabel">
                      <label>{this.props?.t("Last Area Extended GO")}</label>
                    </Colxx>
                    {":  "}
                    <Colxx xxs="5">
                      {" "}
                      <FileDisplay Value={this.state.GOS.LastAreaExtendedGO} />
                    </Colxx>
                  </Row>
                ) : (
                  ""
                )} */}
                {/*      
{this.state.viewValues && this.state.viewValues.NotaryApplication && 
              this.state.viewValues.NotaryApplication?.GO ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Existing Notary Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.NotaryApplication?.GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )}

              {this.state.viewValues && this.state.viewValues.RenewalApplications && 
              this.state.viewValues.RenewalApplications.length > 0 ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Renewal Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.RenewalApplications[0].GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )}

{this.state.viewValues && this.state.viewValues.AreaExtensionApplications && 
              this.state.viewValues.AreaExtensionApplications.length > 0 ? (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props?.t("Area Extension Applications")}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    {" "}
                    <FileDisplay Value={this.state.viewValues.AreaExtensionApplications[0].GO} />
                  </Colxx>
                </Row>
              ) : (
                  ""
                )} */}
              </TabPane>
            </TabContent>
          ) : (
            ""
          )}
          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId={10}>
              {console.log("viewValues", this.state.viewValues, this.state.GOS)}
              {this.state?.viewValues?.NOC && (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props.t(`NOC`)}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    <FileDisplay Value={this.state?.viewValues?.NOC} />
                  </Colxx>
                </Row>
              )}
              {this.state?.viewValues?.GO && (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props.t(`GO`)}</label>
                  </Colxx>
                  {":  "}

                  <Colxx xxs="5">
                    <FileDisplay Value={this.state?.viewValues?.GO} />
                  </Colxx>
                </Row>
              )}
              {this.state?.viewValues?.Letter && (
                <Row>
                  <Colxx xxs="5" className="printLabel">
                    <label>{this.props.t(`GO`)}</label>
                  </Colxx>
                  {":  "}
                  <Colxx xxs="5">
                    <FileDisplay Value={this.state?.viewValues?.Letter} />
                  </Colxx>
                </Row>
              )}
              {this.state?.viewValues?.history?.map((item) => {
                if (
                  item?.diff?.Comments &&
                  item?.diff?.Comments.length > 0 &&
                  item?.diff?.Comments?.[1]
                ) {
                  return (
                    <>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props?.t(
                              `${item?.diff?.Status?.[1] || ""} Reason`
                            )}
                          </label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.diff?.Comments?.[1],
                            }}
                          />
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props?.t(
                              `${item?.diff?.Status?.[1] || ""} On`
                            )}
                          </label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {moment(item?.timestamp).format("DD/MM/YYYY")}
                        </Colxx>
                      </Row>
                    </>
                  );
                }
              })}
            </TabPane>
          </TabContent>
        </Fragment>

        <Modal
          className="viewDocumentModal"
          isOpen={this.state.DocumentViewModal}
          size="md"
          toggle={this.togglePhotoViewModal}
        >
          <ModalHeader toggle={this.togglePhotoViewModal}>
            <b>{this.state.Title}</b>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Colxx xxs="12">
                <Fragment>
                  {this.state.ImageGallery ? (
                    checkIfImage(this.state.ImageGallery) ? (
                      <img
                        alt="docView"
                        src={this.state.ImageGallery}
                        title="documentview"
                        className="Img-Height"
                        height="300px"
                        width="100%"
                        style={{ objectFit: "contain" }}
                      />
                    ) : checkIfPdf(this.state.ImageGallery) ? (
                      <iframe
                        onLoad={this.hideSpinner}
                        key={this.state.reload}
                        title="documentview"
                        width="100%"
                        height="300px"
                        className="Img-Height"
                        src={encodeURI(
                          `https://docs.google.com/viewer?url=${this.state.ImageGallery}&embedded=true`
                        )}
                      ></iframe>
                    ) : (
                      <iframe
                        onLoad={this.hideSpinner}
                        key={this.state.reload}
                        title="documentview"
                        width="100%"
                        height="300px"
                        className="Img-Height"
                        src={encodeURI(
                          `https://view.officeapps.live.com/op/embed.aspx?src=${this.state.ImageGallery}`
                        )}
                      ></iframe>
                    )
                  ) : (
                    ""
                  )}
                  {/* <div className="each-slide">
                    <img
                      alt="noimg"
                      id="userimg"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      src={this.state.ImageGallery}
                    />
                  </div> */}
                </Fragment>
              </Colxx>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormGroup>
              <a href={this.state.ImageGallery}>
                <Button
                  type="submit"
                  className="default   btn-sm mb-2 btn-addon"
                  color="primary"
                >
                  Download
                </Button>{" "}
              </a>

              <Button
                className="default   btn-sm mb-2 btn-addon"
                color="danger"
                onClick={() => this.togglePhotoViewModal()}
              >
                Close
              </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(ApplicationsList));
