import * as Yup from "yup";

import {
  userNameRegex,
  FORMTYPES,
  QUALIFICATIONTYPES,
  COMMUNITY,
} from "../constants/defaultValues";

export const DesignationValidation = Yup.object().shape({
  Rank: Yup.string().required("Please enter valid Rank!"),
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const AdvocateValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Designation: Yup.string().required("Please select the designation"),
  MobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  Password: Yup.string().required("Please enter password!"),
});
export const otpValidation = Yup.object().shape({
  Username: Yup.string().required("Please enter valid username!"),
  // .matches(
  //   userNameRegex,
  //   "Username must contain one upper case letter, one lower case letter, one digit and should be minimum of eight characters"
  // ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  // .matches(
  //   "^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$",
  //   "Username must contain one upper case English letter, one lower case English letter, one digit, ([0-9]) one special character, (@$_]) minimum eight in length"
  // ),
  Name: Yup.string().required("Please enter valid Name!"),
  Captcha: Yup.string().required("Please enter valid captcha!"),
  MobileNumber: Yup.string()
    .max(10, "Please enter maximum 10 numbers")
    .min(10, "Please enter minimum 10 numbers")
    .required("Please enter Mobile Number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
  MobileNumberVerificationOTP: Yup.string()
    .max(6, "Invalid OTP")
    .required("Please enter valid mobile verification otp"),
  EmailVerificationOTP: Yup.string()
    .max(6, "Invalid OTP")
    .required("Please enter valid email verification otp"),
  Password: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  // .min(6, "Minimum 6 digits"),
  ConfirmPassword: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  // .min(6, "Minimum 6 digits"),
});

export const forgetPassword = Yup.object().shape({
  // MobileNumber: Yup.string()
  //   .max(10, "Please enter maximum 10 numbers")
  //   .min(10, "Please enter minimum 10 numbers")
  //   .required("Please enter Mobile Number")
  //   .matches(
  //     /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
  //     "Invalid Mobile Number"
  //   ),
  OTP: Yup.string().max(6, "Invalid OTP").required("Please enter valid OTP"),
  Password: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  //  .min(6, "Minimum 6 digits"),
  ConfirmPassword: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  // .min(6, "Minimum 6 digits"),
});

export const PersonValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  MobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),

  District: Yup.string().required("Please select your district!"),
  State: Yup.string().required("Please select your state!"),
  Gender: Yup.string().required("Please select your gender!"),
  Case: Yup.mixed().required("Please select a case!"),
});
export const FeeValidation = Yup.object().shape({
  MaximumAmount: Yup.string().required("Please enter Amount!"),
  Court: Yup.string().required("Please select the Court"),
  CaseType: Yup.string().required("Please select the Case Type"),
});
export const ClaimValidation = Yup.object().shape({
  Amount: Yup.string().required("Please enter Amount!"),
  Case: Yup.string().required("Please select the Case"),
  Description: Yup.string().required("Please select the Case Type"),
});
export const CaseBasicValidation = Yup.object().shape({
  MainPrayerOfTheCase: Yup.string().required(
    "Please enter valid Main prayer of the case!"
  ),
  Court: Yup.string().required("Please choose valid Court!"),
  CaseType: Yup.string().required("Please choose valid Case Type!"),
  CaseNo: Yup.string().required("Please enter valid Case No!"),
  Area: Yup.string().required("Please choose valid Area!"),
});
export const CaseValidation = Yup.object().shape({
  CaseNumber: Yup.string().required(
    "Please enter valid CaseNumber of the case!"
  ),
  FiledDate: Yup.mixed().required("Please enter Filed Date"),
  Subject: Yup.string().required("Please enter Subject!"),
});
export const CaseStatusValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});

export const OrganizationValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});

export const CaseTypeValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const DepartmentValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const CourtValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  AddressLine1: Yup.string().required("Please enter valid Address!"),
  District: Yup.string().required("Please choose District!"),
  State: Yup.string().required("Please choose State!"),
});

export const RolesValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});

export const IndustryCategoryValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const IndustryTypeValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const CaseCategoryValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const DepartmentTypeValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const IndustryClassificationValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});

export const PetitionerValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  MobileNo: Yup.string().required("Please enter valid Description!"),
  Organization: Yup.string().required("Please select the Organization"),
});
export const stationValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter name!"),
  MobileNumber: Yup.string().required("Please enter mobile number!"),
  Password: Yup.string().required("Please enter password!"),
});
export const organizationValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  MobileNumber: Yup.string().required("Please enter valid Description!"),
  IndustryCategory: Yup.string().required(
    "Please select the Industry Cagegory"
  ),
  IndustryClassification: Yup.string().required(
    "Please select the Industry Classification"
  ),
});
export const RespondentValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Department: Yup.string().required("Please select the department"),
  Designation: Yup.string().required("Please select the designation"),
  MobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  Password: Yup.string().required("Please enter password!"),
});
export const HearingValidation = Yup.object().shape({
  HearingOn: Yup.string().required("Please enter valid Date!"),
  Description: Yup.string().required("Please enter valid Description!"),
  // RespondentAdvocate: Yup.string().required("Please select the advocate"),
});
export const StateValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  ShortCode: Yup.string().required("Please enter valid ShortCode!"),
});
export const AdditionalValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  SubmittedOn: Yup.string().required("Please enter valid Date!"),
  Url: Yup.string().required("Please enter valid Document!"),
});
export const AffidavitValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  SubmittedOn: Yup.string().required("Please enter valid Date!"),
  Url: Yup.string().required("Please enter valid Document!"),
});
export const CounterAffidavitValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  SubmittedOn: Yup.string().required("Please enter valid Date!"),
  Url: Yup.string().required("Please enter valid Document!"),
});
export const DistrictValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  ShortCode: Yup.string().required("Please enter valid ShortCode!"),
  State: Yup.string().required("Please select the State!"),
});
export const JudgeValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter valid Name!"),
  Description: Yup.string().required("Please enter valid Description!"),
});
export const officerValidation = Yup.object().shape({
  Username: Yup.string().required("Please enter valid username!"),
  //  .matches(
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
  //   userNameRegex,
  //   "Username must contain one upper case letter, one lower case letter, one digit and should be minimum of eight characters"
  // ),
  Name: Yup.string().required("Please enter Name!"),

  // Gender: Yup.string().required("Please select your Gender!"),
  District: Yup.string().required("Please select your district!"),
  // MobileNumber: Yup.string()
  //   .required("Please enter mobile number")
  //   .max(10, "Maximum 10 numbers")
  //   .matches(
  //     /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
  //     "Invalid Mobile Number"
  //   ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  Password: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),

  // .min(6, "Minimum 6 characters"),
});
export const changePasswordValidation = Yup.object().shape({
  OldPassword: Yup.string()
    .required("Please enter valid password!")

    .min(6, "Minimum 6 digits"),
  NewPassword: Yup.string()
    .required("Please enter valid password")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  //  .min(6, "Minimum 6 digits"),
  ConfirmPassword: Yup.string()
    .required("Please enter valid password")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
  // .min(6, "Minimum 6 digits"),
});
export const SendToValidation = Yup.object().shape({
  Text: Yup.string().required("Please enter valid Text!"),
  Date: Yup.number().required("Please enter valid Date!"),
});
export const RespondToValidation = Yup.object().shape({
  Text: Yup.string().required("Please enter valid Text!"),
});
export const RaiseCounterAffidavitValidation = Yup.object().shape({
  Remarks: Yup.string().required("Please enter valid Remarks!"),
  FiledDate: Yup.string().required("Please enter valid Date!"),
  // Url: Yup.string().required("Please enter valid Document!"),
});
export const HearingStatusValidation = Yup.object().shape({
  // Status: Yup.string().required("Please select valid status!"),
  // IsFinalOrder: Yup.string().required("Please select valid order status!"),
  // OrderedOn: Yup.string().required("Please enter valid order date!"),
  // OrderText: Yup.string().required("Please enter valid order!"),
  // OrderCopy: Yup.string().required("Please upload valid order copy!"),
});

export const ApplicationFormValidation = Yup.object().shape({
  //  Grounds: Yup.string().required("Please enter Grounds!"),
  // FatherName: Yup.string().required("Please enter Father Name!"),
  // DOB: Yup.string().required("Please select Date of Birth"),
  //  BarCouncilNumber: Yup.string().required("Please enter Bar Council Number"),
  // District: Yup.string().required("Please select your district!"),
  // Taluk: Yup.string().required("Please select your taluk!"),
  // MobileNumber: Yup.string()
  //   .required("Please enter mobile number")
  //   .matches(
  //     /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
  //     "Invalid Mobile Number"
  //   ),
  // EmailID: Yup.string()
  //   .required("Please enter email id")
  //   .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  //  Address: Yup.string().required("Please enter Address!"),
});
export const InterviewBoardValidation = Yup.object().shape({
  Username: Yup.string().required("Please enter valid username!"),
  // .matches(
  //   // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
  //   userNameRegex,
  //   "Username must contain one upper case letter, one lower case letter, one digit and should be minimum of eight characters"
  // ),
  Name: Yup.string().required("Please enter name!"),
  MobileNumber: Yup.string()
    .required("Please enter mobile number")
    .max(10, "Please enter maximum 10 numbers")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  Districts: Yup.string().required("Please select district!"),
  Password: Yup.string()
    .required("Please enter valid password!")
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})",
      "Password must contain atleast one upper case English letter, (?=.?[A-Z]), atleast one lower case English letter, (?=.?[a-z]), at least one digit, (?=.?[0-9]), at least one special character, (?=.*[#?!@$%^&*-]), minimum eight in length .{8,} (with the anchors)."
    ),
});

export const ProfileFormTypeValidation = Yup.object().shape({
  FormType: Yup.string().required("Please select form type!"),
});

export const ProfilePersonalValidation = Yup.object().shape({
  Name: Yup.string().required("Please enter Name!"),
  Title: Yup.string().required("Please select your Title!"),
  //  IsMarried: Yup.string().required("Please select married!"),
  Gender: Yup.string().required("Please select your Gender!"),
  FatherOrHusbandName: Yup.string().required("Please enter Father Name!"),
  Dob: Yup.string().required("Please select your Date of Birth!").nullable(),
  Community: Yup.string().required("Please select your Community!"),
  Photo: Yup.mixed()
    .required("Please upload your photo")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  Aadhar: Yup.string()
    .max(12, "Maximum 12 numbers")
    .min(12, "Minimum 12 numbers")
    .required("Please enter AADHAR number"),
  //  FormType: Yup.string().required("Please select form type!"),
});

export const ProfileProfessionalValidation = Yup.object().shape({
  //  BarCouncilNumber: Yup.string().required("Please enter Bar Council Number"),
  BarCouncilCertificate: Yup.mixed()
    .required("Please upload your bar council certificate")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
  PANCopy: Yup.mixed()
    .required("Please upload your PAN copy")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
  // PracticingSince: Yup.string()
  //   .required("Please select your Practicing Since")
  //   .nullable(),
  PracticingIn: Yup.string().required("Please select your Practicing In"),
  // LandlineNumber: Yup.string()
  //   .matches(
  //     /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  //     "Invalid Landline Number"
  //   )
  //   .required("Please enter Landline Number"),
  IncomeTaxAssessee: Yup.string().required(
    "Please select income tax assessee!"
  ),
  PANNumber: Yup.string()
    .required("Please enter PAN number")
    .min(10, "Minimum 10 numbers")
    .max(10, "Maximum 10 numbers")
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN Number"),
  // Fax: Yup.string()
  //   .required("Please enter Fax number")
  //   .matches(
  //     /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  //     "Invalid Fax Number"
  //   ),
  EnrolmentDate: Yup.string()
    .required("Please enter enrolment date")
    .nullable(),
});

export const ProfileQualificationValidation = Yup.object().shape({
  Institution: Yup.string().required("Please fill institution "),
  Url: Yup.mixed()
    .required("Please upload your copy of degree")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
  Degree: Yup.string().required("Please enter your degree "),
  YearOfPassing: Yup.string()
    .required("Please enter year of passing ")
    .matches(/^(19|20)\d{2}$/, "Invalid year"),
});
export const ProfileAddressValidation = Yup.object().shape({
  ResidencyAddressLine1: Yup.string().required(
    "Please enter Residency Address Line 1"
  ),

  // ResidencyDistrict: Yup.string().required("Please select district"),
  // ResidencyTaluk: Yup.string().required("Please select taluk"),
  ResidencyPincode: Yup.string()
    .max(6, "Maximum 6 numbers")
    .min(6, "Minimum 6 numbers")
    .required("Please enter pincode"),

  // ResidencyProofType: Yup.string().required("Please select your proof type"),
  // ResidencyIDProofNumber: Yup.string().required(
  //   "Please select your id proof number"
  // ),

  // ResidencyCopyOfProofID: Yup.mixed()
  //   .required("Please upload your copy of proof id")
  //   .test("fileSize", "File too large", (value) =>
  //     typeof value === "string" ? true : value && value.size / 1024 <= 5000
  //   )
  //   .test(
  //     "fileFormat",
  //     "Allowed formats are " + ["pdf"].join(", "),
  //     (value) =>
  //       typeof value === "string"
  //         ? true
  //         : value &&
  //           ["doc", "docx", "pdf", "png", "jpg", "jpeg"].includes(
  //             value?.name?.toLowerCase().split(".")[
  //               value?.name?.toLowerCase().split(".").length - 1
  //             ]
  //           )
  //   ),
});

export const sheduleInterviewValidation = Yup.object().shape({
  // From: Yup.number().required("Please enter from."),
  // To: Yup.number().required("Please enter to"),
  // Session: Yup.string().required("Please select session"),
  // Location: Yup.string().required("Please enter location"),
  // Date: Yup.string().required("Please select date!"),
});
export const AnnualReturnValidation = Yup.object().shape({
  Year: Yup.string().required("Please select year!").nullable(),
});

export const ExistingNotatryValidation = Yup.object().shape({
  // SerialNumber: Yup.string().required("Please enter valid serial number!"),
  // SubmittedOnDate: Yup.string()
  //   .required("Please select submitted on date!")
  //   .nullable(),
  ValidFrom: Yup.string()
    .required("Please select valid appointment date!")
    .nullable(),
  ValidTo: Yup.string().required("Please select valide expiry date").nullable(),
  GONumber: Yup.string().required("Please select appointment GO number!"),
  GO: Yup.mixed()
    .required("Please upload your appointment GO Copy")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),

  District: Yup.string().required("Please select your district!").nullable(),
  Taluks: Yup.string().required("Please select your taluk!").nullable(),
});
export const SettingsValidation = Yup.object().shape({
  AllowNewApplications: Yup.string()
    .required("Please select this field!")
    .nullable(),
  AllowAreaExtensionApplications: Yup.string()
    .required("Please select this field!")
    .nullable(),
  AllowRenewalApplications: Yup.string()
    .required("Please select this field!")
    .nullable(),
  AllowDuplicateApplications: Yup.string()
    .required("Please select this field!")
    .nullable(),
  AllowExistingNotaryApplications: Yup.string()
    .required("Please select this field!")
    .nullable(),
});

export const profileTab1Validation = Yup.object().shape({
  FormType: Yup.string().required("Form Type is required"),
  GovtServiceStartDate: Yup.number().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form2 ? true : false;
    },
    then: Yup.number().required("Date of Joining is required"),
    otherwise: Yup.number(),
  }),
  GovtServiceEndDate: Yup.number().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form2 ? true : false;
    },
    then: Yup.number().required("Date of Retirement is required"),
    otherwise: Yup.number(),
  }),
  GovtServicePost: Yup.string().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form2 ? true : false;
    },
    then: Yup.string().required(
      "Posting held at the time of Retirement is required"
    ),
    otherwise: Yup.string(),
  }),
  GovtServiceStartUrl: Yup.mixed().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form2 ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your government service intitial document")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
  GovtServiceEndUrl: Yup.mixed().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form2 ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your government service end document")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
  // ExperienceCertificate: Yup.mixed().when("FormType", {
  //   is: (FormType) => {
  //     return FormType === FORMTYPES.Form2 ? true : false;
  //   },
  //   then: Yup.mixed()
  //     .required("Please upload your experience certificate")
  //     .test("fileSize", "File too large", (value) =>
  //       typeof value === "string" ? true : value && value.size / 1024 <= 5000
  //     )
  //     .test(
  //       "fileFormat",
  //       "Allowed formats are " + ["pdf"].join(", "),
  //       (value) =>
  //         typeof value === "string"
  //           ? true
  //           : value &&
  //           ["doc", "docx", "pdf", "png", "jpg", "jpeg"].includes(
  //             value?.name?.toLowerCase().split(".")[
  //             value?.name?.toLowerCase().split(".").length - 1
  //             ]
  //           )
  //     ),
  //   otherwise: Yup.mixed(),
  // }),
});

export const profileTab2Validation = Yup.object().shape({
  Name: Yup.string().required("Please enter Name!"),
  Title: Yup.string().required("Please select your Title!"),
  Gender: Yup.string().required("Please select your Gender!"),
  FatherOrHusbandName: Yup.string().required("Please enter Father Name!"),
  // Dob: Yup.string().required("Please provide your Date of Birth!"),
  Community: Yup.string().required("Please select your Community!"),
  CommunityCertificate: Yup.string().when("Community", {
    is: (Community) => {
      return Community === COMMUNITY.General ? false : true;
    },
    then: Yup.string()
      .required("Please upload your community certificate")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.string(),
  }),
  Photo: Yup.mixed()
    .required("Please upload your photo")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["png", "jpg", "jpeg"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["png", "jpg", "jpeg"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),

  EmailID: Yup.string()
    .required("Email ID is required")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i, "Invalid email id"),
  Aadhar: Yup.string().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.string().required("Please enter Aadhaar number"),
    // .matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, "Invalid Aadhaar Number"),
    otherwise: Yup.string(),
  }),
  AadharCopy: Yup.mixed().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your Aadhaar Copy")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
});

export const profileTab3Validation = Yup.object().shape({
  BarCouncilNumber: Yup.string().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.string().required("Bar Council Enrolment Number is required"),
    otherwise: Yup.string(),
  }),

  BarCouncilCertificate: Yup.mixed().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your bar council certificate")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
  BarCouncilExperienceCertificate: Yup.mixed().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your bar council experience certificate")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
  PracticingIn: Yup.string().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.string().required("Practicing In"),
    otherwise: Yup.string(),
  }),
  PANNumber: Yup.string().when(["FormType", "IncomeTaxAssessee"], {
    is: (FormType, IncomeTaxAssessee) => {
      return FormType === FORMTYPES.Form1 && IncomeTaxAssessee ? true : false;
    },
    then: Yup.string().required("Please enter PAN number"),
    // .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/i, "Invalid PAN Number"),
    otherwise: Yup.string(),
    // .matches(
    //   /[A-Z]{5}[0-9]{4}[A-Z]{1}/i,
    //   "Invalid PAN Number"
    // ),
  }),
  PANCopy: Yup.mixed().when(["FormType", "IncomeTaxAssessee"], {
    is: (FormType, IncomeTaxAssessee) => {
      return FormType === FORMTYPES.Form1 && IncomeTaxAssessee ? true : false;
    },
    then: Yup.mixed()
      .required("Please upload your PAN Copy")
      .test("fileSize", "File too large", (value) =>
        typeof value === "string" ? true : value && value.size / 1024 <= 5000
      )
      .test(
        "fileFormat",
        "Allowed formats are " + ["pdf"].join(", "),
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          const allowedExtensions = ["pdf"];
          const filename = value?.name?.toLowerCase();
          const lastDotIndex = filename?.lastIndexOf(".");
          const extension = filename?.substring(lastDotIndex + 1);
          const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
          const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
          return (
            allowedExtensions?.includes(extension) &&
            !hasMultipleExtensions &&
            filenameWithoutExtension.length > 0
          );
        }
      ),
    otherwise: Yup.mixed(),
  }),
  EnrolmentDate: Yup.string().when("FormType", {
    is: (FormType) => {
      return FormType === FORMTYPES.Form1 ? true : false;
    },
    then: Yup.string().required("Please provide Enrolment date"),
    otherwise: Yup.string(),
  }),
});

export const profileTab4Validation = Yup.object().shape({
  QualificationType: Yup.string().required(
    "Please select your Qualification Type"
  ),
  Degrees: Yup.array().when(["QualificationType"], {
    is: (QualificationType) => {
      return QualificationType === QUALIFICATIONTYPES.ThreeYearDegree
        ? true
        : false;
    },
    then: Yup.array()
      .of(
        Yup.object().shape({
          Institution: Yup.string().required("Please provide Institution"),
          Name: Yup.string().required("Please provide Degree"),
          Url: Yup.string().required("Please upload document"),
          YearOfPassing: Yup.string()
            .required("Please provide Year of Passing")
            .matches(/^(19|20)\d{2}$/, "Please provide valid year"),
        })
      )
      .min(1, "Please provide the qualification detail"),
    otherwise: Yup.array()
      .of(
        Yup.object().shape({
          Institution: Yup.string().required("Please provide Institution"),
          Name: Yup.string().required("Please provide Degree"),
          Url: Yup.string().required("Please upload document"),
          YearOfPassing: Yup.string()
            .required("Please provide Year of Passing")
            .matches(/^(19|20)\d{2}$/, "Please provide valid year"),
        })
      )
      .min(1, "Please provide the qualification detail"),
  }),
});

export const profileTab5Validation = Yup.object().shape({
  ResidencyAddress: Yup.object().shape({
    Address: Yup.object().shape({
      Line1: Yup.string().required("Address Line1 is required"),
      State: Yup.string().required("State is required"),
      District: Yup.string().required("District is required"),
      Taluk: Yup.string().required("Taluk is required"),
      Pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pin code"),
    }),
  }),
  ProvisionalAddress: Yup.object().shape({
    Address: Yup.object().shape({
      Line1: Yup.string().required("Address Line1 is required"),
      State: Yup.string().required("State is required"),
      District: Yup.string().required("District is required"),
      Taluk: Yup.string().required("Taluk is required"),
      Pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pin code"),
    }),
  }),
});

export const nocValidation = Yup.object().shape({
  NOC: Yup.mixed()
    .required("Please upload your NOC")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
});
const allowedExtensions = /^(jpg|jpeg|png|gif|pdf|docx)$/;
export const COPValidation = Yup.object().shape({
  CoPCopy: Yup.mixed()
    .required("Please upload your COP Copy")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string" ? true : value && value.size / 1024 <= 5000
    )
    .test(
      "fileFormat",
      "Allowed formats are " + ["pdf"].join(", "),
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        const allowedExtensions = ["pdf"];
        const filename = value?.name?.toLowerCase();
        const lastDotIndex = filename?.lastIndexOf(".");
        const extension = filename?.substring(lastDotIndex + 1);
        const filenameWithoutExtension = filename?.substring(0, lastDotIndex);
        const hasMultipleExtensions = filenameWithoutExtension?.includes(".");
        return (
          allowedExtensions?.includes(extension) &&
          !hasMultipleExtensions &&
          filenameWithoutExtension.length > 0
        );
      }
    ),
});
export const changeMobileValidation = Yup.object().shape({
  OTP: Yup.string().required("Please enter valid otp!"),
  OldMobileNumber: Yup.string()
    .required("Please enter old mobile number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
  MobileNumber: Yup.string()
    .required("Please enter new mobile number")
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      "Invalid Mobile Number"
    ),
});
export const changeEmailId = Yup.object().shape({
  OTP: Yup.string().required("Please enter valid otp!"),
  EmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
  OldEmailID: Yup.string()
    .required("Please enter email id")
    .matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/, "Invalid Email ID."),
});
export const RenewalFormValidation = Yup.object().shape({
  LastRenewalDate: Yup.string().required("Please enter this field!"),
  RenewalGONumber: Yup.string().required("Please enter this field!"),
  RenewalGODate: Yup.string().required("Please enter this field!"),
  NotaryAppointmentGONumber: Yup.string().required("Please enter this field!"),
  NotaryAppointmentGODate: Yup.string().required("Please enter this field!"),
  NotaryAppointmentDate: Yup.string().required("Please enter this field!"),
  Throughout: Yup.string().required("Please enter this field!"),
  ValidFrom: Yup.string().required("Please enter this field!"),
  ValidTo: Yup.string().required("Please enter this field!"),
  RenewalBefore: Yup.string().required("Please enter this field!"),
  Reason: Yup.string().required("Please enter this field!"),
  Name: Yup.string().required("Please enter this field!"),
  Place: Yup.string().required("Please enter this field!"),
});
