import React from "react";
import { Container, Row, Col } from "reactstrap";
import logofull from "../../assets/images/TNeGA.png";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Law Admin.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                <img
                  alt="tnega"
                  src={logofull}
                  style={{ width: "25px", objectFit: "contain" }}
                />
                <u>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://tnega.tn.gov.in/"
                    target="blank"
                  >
                    {" "}
                    TNeGA
                  </a>
                </u>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
