import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withNamespaces } from "react-i18next";
import { Row, Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat, TITLE } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
import FileDisplay from "../../components/custom/FileDisplay";
class Form1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  async savePDF() {
    this.setState({
      loading: true,
    });

    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    if (this.props.match) {
      options.scrollX = -7;
    }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    var dataURL1 = "";
    const printArea = document.getElementById("PrintForm");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");
    const printArea1 = document.getElementById("PrintForm1");
    await html2canvas(printArea1, options).then((canvas) => {
      dataURL1 = canvas.toDataURL();
    });
    pdf.addImage(dataURL1, "JPEG", 0, 0, width, height, "", "FAST");
    var pagePromises = [];

    Promise.all(pagePromises).then(() => {
      pdf.save(
        this.props.Application
          ? this.state.Application && this.state.Application.Title + ".pdf"
          : "saved.pdf"
      );
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 10000);
  }

  DownloadFormAsPDF = () => {
    const Application = this.props.Application.Applicant
      ? this.props.Application.Applicant
      : this.props.Application;
    const declarationDetail = this.props.Application.DeclarationFields;

    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    pdf.text("(FORM I)", 230, y, null, null, "center");
    y = y + 10;
    pdf.text("[See Rule 4 (2)]", 230, y, null, null, "center");

    y = y + 30;

    if (Application?.Base64Photo) {
      // pdf.addImage(Application?.Base64Photo, "PNG", 320, y, 80, 50);
    }

    pdf.text("1.Name of the applicant", 50, y, null, null);
    pdf.text(Application.Name, 150, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(140, y + 5, 300, y + 5);

    y = y + 25;

    pdf.text(
      Application.Title?._id === TITLE.THIRUMATHI
        ? "2. Husband's Name"
        : "2. Father's Name",
      50,
      y,
      null,
      null
    );

    //   pdf.text("2. Father's/Husband's name", 50, y, null, null);
    pdf.text(Application.FatherOrHusbandName, 125, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(125, y + 5, 300, y + 5);

    y = y + 25;
    pdf.text("3. Date of Birth", 50, y, null, null);
    pdf.text(
      Application.Dob
        ? formatDate(fromUnixTime(Application.Dob / 1000), datePickerFormat)
        : "",
      110,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(105, y + 5, 300, y + 5);

    y = y + 25;
    pdf.text("4. Whether SC/ST/OBC/General", 50, y, null, null);
    pdf.text(
      Application.Community ? Application.Community.Name : "",
      185,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(175, y + 5, 300, y + 5);

    y = y + 25;
    pdf.text("5. Address (residence)", 50, y, null, null);

    pdf.text(
      Application && Application.FullResidencyAddress
        ? Application.FullResidencyAddress
        : "",
      // "5,14th Street,Tansi Nagar,Velachery,Chennai,Tamilnadu",
      // "158/19, T1, friends apartments, periyar pathai, choolaimedu,1,Alandur,Chennai",
      // "158/19, T1, friends apartments, choolaimedu,1,Alandur,Chennai",
      140,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(140, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text("Pin", 60, y, null, null);
    pdf.text(
      Application.ResidencyAddress &&
        Application.ResidencyAddress.Address &&
        Application.ResidencyAddress.Address.Pincode
        ? Application.ResidencyAddress.Address.Pincode
        : "",
      90,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(80, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text("Telephone/Mobile", 60, y, null, null);
    pdf.text(Application.MobileNumber, 140, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(130, y + 5, 200, y + 5);

    pdf.text("Fax", 205, y, null, null);
    pdf.text(Application.Fax ? Application.Fax : "", 230, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(220, y + 5, 280, y + 5);

    pdf.text("E-Mail", 285, y, null, null);
    pdf.text(
      Application.EmailID ? Application.EmailID : "",
      315,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(315, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text("5. Address (Official)", 50, y, null, null);
    pdf.text(
      Application.FullProvisionalAddress
        ? Application.FullProvisionalAddress
        : "",
      //  "5,14th Street,Tansi Nagar,Velachery,Chennai,Tamilnadu",
      //  "158/19, T1, friends apartments, periyar pathai, choolaimedu,1,Alandur,Chennai",
      //   "158/19, T1, friends apartments, choolaimedu,1,Alandur,Chennai",
      140,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(125, y + 5, 400, y + 5);

    y = y + 25;
    pdf.text("Pin", 60, y, null, null);
    pdf.text(
      Application.ProvisionalAddress &&
        Application.ProvisionalAddress.Address &&
        Application.ProvisionalAddress.Address.Pincode
        ? Application.ProvisionalAddress.Address.Pincode
        : "",
      90,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(80, y + 5, 150, y + 5);

    pdf.text("Aadhaar No", 150, y, null, null);
    pdf.text(Application.Aadhar ? Application.Aadhar : "", 205, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(195, y + 5, 300, y + 5);

    pdf.text("PAN No", 300, y, null, null);
    pdf.text(
      Application.PANNumber ? Application.PANNumber : "",
      350,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(335, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text("Telephone/Mobile", 60, y, null, null);
    pdf.text(
      Application.MobileNumber ? Application.MobileNumber : "",
      140,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(130, y + 5, 200, y + 5);

    pdf.text("Fax", 205, y, null, null);
    pdf.text(Application.Fax ? Application.Fax : "", 230, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(220, y + 5, 280, y + 5);

    pdf.text("E-Mail", 280, y, null, null);
    pdf.text(
      Application.EmailID ? Application.EmailID : "",
      310,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(310, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text(
      "6. Educational Qualifications (Please upload self-attested scanned copies).",
      50,
      y,
      null,
      null
    );

    y = y + 25;
    pdf.text(
      "7.  Enrolment number and date of the Bar Council (Please upload self-attestedcopy)",
      50,
      y,
      null,
      null
    );

    y = y + 25;
    pdf.text("8.  Practicing in", 50, y, null, null);
    pdf.setLineDashPattern([1.5]);
    pdf.line(125, y + 5, 420, y + 5);

    y = y + 30;
    pdf.text("Civil side", 75, y, null, null);
    pdf.text(
      Application.PracticingIn &&
        Application.PracticingIn.length > 0 &&
        Application.PracticingIn.find((x) =>
          x.Name.toLowerCase().includes("civil")
        )
        ? "Yes"
        : "No",
      135,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(125, y + 5, 420, y + 5);

    y = y + 30;
    pdf.text("Criminal side", 75, y, null, null);
    pdf.text(
      Application.PracticingIn &&
        Application.PracticingIn.length > 0 &&
        Application.PracticingIn.find((x) =>
          x.Name.toLowerCase().includes("criminal")
        )
        ? "Yes"
        : "No",
      135,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(125, y + 5, 420, y + 5);

    y = y + 30;
    pdf.text("Taxation side", 75, y, null, null);
    pdf.text(
      Application.PracticingIn &&
        Application.PracticingIn.length > 0 &&
        Application.PracticingIn.find((x) =>
          x.Name.toLowerCase().includes("taxation")
        )
        ? "Yes"
        : "No",
      140,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(130, y + 5, 420, y + 5);

    y = y + 30;
    pdf.text("Revenue Courts", 75, y, null, null);
    pdf.text(
      Application.PracticingIn &&
        Application.PracticingIn.length > 0 &&
        Application.PracticingIn.find((x) =>
          x.Name.toLowerCase().includes("revenue")
        )
        ? "Yes"
        : "No",
      150,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(140, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text("9.  Whether Income-tax assessee", 50, y, null, null);
    pdf.text(Application.IncomeTaxAssessee ? "Yes" : "No", 220, y, null, null);

    pdf.line(200, y + 5, 420, y + 5);

    y = y + 25;
    pdf.text(
      "10. The application of (name of the applicant in block letters",
      50,
      y,
      null,
      null
    );

    y = y + 25;
    pdf.text("Showeth", 60, y, null, null);
    pdf.text(Application.Name.toUpperCase(), 100, y, null, null);

    pdf.line(100, y + 5, 170, y + 5);
    pdf.addPage("a4", "p");
    y = 0;
    y = y + 25;
    pdf.text(
      "1.That the applicant is a person eligible for appointment as a notary under",
      75,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text(
      "the Notaries Act, 1952, and clause (a) of rule 3 of the Notaries Rules,",
      85,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text("1956;", 85, y, null, null);
    y = y + 20;
    pdf.text(
      "2. That the applicant practices as an Advocate",
      75,
      y,
      null,
      null
    );

    pdf.text(
      declarationDetail && declarationDetail.AreaOfPracticeAsAnAdvocate
        ? declarationDetail.AreaOfPracticeAsAnAdvocate
        : "",
      250,
      y,
      null,
      null
    );
    pdf.setLineDashPattern([1.5]);
    pdf.line(250, y + 5, 400, y + 5);
    y = y + 15;
    pdf.text(
      "(herein state the name of the local area and name of court where he intends to",
      85,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text("practice as an Advocate)", 85, y, null, null);

    y = y + 20;
    pdf.text(
      "3. That the number of notaries practicing in the local area is insufficient for",
      75,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text(
      "the requirements thereof (Statement to be added stating grounds for",
      85,
      y,
      null,
      null
    );

    y = y + 10;
    pdf.text("Requirement of more Notaries)", 85, y, null, null);

    pdf.setLineDashPattern([1.5]);
    pdf.line(200, y + 2, 400, y + 2);
    y = y + 10;
    pdf.text(
      declarationDetail && declarationDetail.IntendedAreaOfPractice
        ? declarationDetail.IntendedAreaOfPractice
        : "",
      90,
      y,
      null,
      null
    );

    pdf.setLineDashPattern([1.5]);
    pdf.line(85, y + 5, 400, y + 5);

    y = y + 25;
    pdf.text(
      "4. That no previous application of the applicant has been rejected or",
      75,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text(
      "Withdrawn by him, within the preceding six months;",
      85,
      y,
      null,
      null
    );

    y = y + 40;
    pdf.text(
      "The applicant, therefore, prays that the Government be pleased to appoint ",
      75,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text(
      "and admit him as a notary under and by virtue of the Notaries Act, 1952 (53 of ",
      75,
      y,
      null,
      null
    );
    y = y + 10;
    pdf.text(
      "1952), and clause (a) of rule 3 of the Notaries Rules, 1956, to practice ",
      75,
      y,
      null,
      null
    );

    y = y + 10;
    pdf.text("in ", 75, y, null, null);
    pdf.text(
      declarationDetail && declarationDetail.Name ? declarationDetail.Name : "",
      90,
      y,
      null,
      null
    );

    pdf.setLineDashPattern([1.5]);
    pdf.line(85, y + 5, 400, y + 5);
    y = y + 15;
    pdf.text(
      "(Mention here the name of the local area where he/she intends to practice as Notary).",
      75,
      y,
      null,
      null
    );

    y = y + 50;
    pdf.text("Dated", 75, y, null, null);
    pdf.text(new Date().getDate().toString(), 110, y, null, null);
    pdf.line(100, y + 5, 150, y + 5);
    pdf.text("day of", 150, y, null, null);
    pdf.text(getMonthName(new Date().getMonth()), 180, y, null, null);
    pdf.line(170, y + 5, 230, y + 5);
    pdf.text("20", 230, y, null, null);
    pdf.text(
      new Date().getFullYear().toString().substring(2, 4),
      250,
      y,
      null,
      null
    );
    pdf.line(240, y + 5, 300, y + 5);

    y = y + 75;
    // pdf.text("Signature of the applicant", 300, y, null, null);

    pdf.save(
      this.props.Application
        ? this.props.Application &&
            this.props.Application.ApplicationNoText + ".pdf"
        : "Form2.pdf"
    );

    // pdf.save("Form1.pdf");
  };

  render() {
    const Application =
      this.props.Application && this.props.Application.Applicant
        ? this.props.Application.Applicant
        : this.props.Application;
    const declarationDetail =
      this.props.Application && this.props.Application.DeclarationFields;

    return (
      <Fragment>
        <Card>
          <CardBody>
            <Row>
              <Colxx xxs="10"></Colxx>
              <Colxx xxs="2" className="float-right">
                <Button
                  className="form-second-btn"
                  type="button"
                  onClick={() => this.DownloadFormAsPDF()}
                  color="success"
                  disabled={this.state.loading}
                >
                  Download as PDF{" "}
                </Button>
              </Colxx>
              <Colxx xxs="12" id="PrintForm">
                <Row>
                  <Colxx xxs="12 text-center mt-1">
                    <span>FORM I</span>
                  </Colxx>
                  <Colxx xxs="12 text-center  mt-1">
                    <span>[See rule 4 (2)]</span>
                  </Colxx>
                  <Colxx xxs="8">
                    {/* <Colxx xxs="12 mb-2"> */}
                    <Colxx xxs="6 float-left">1. Name</Colxx>
                    <Colxx xxs="6" className="text-underline float-left">
                      {Application && Application.Name}
                    </Colxx>
                    {/* <Colxx xxs="6 float-left">2. Name(in tamil)</Colxx>
                    <Colxx xxs="6" className="text-underline float-left">
                      {Application && Application.TamilName}
                    </Colxx> */}
                    {/* </Colxx> */}
                    {/* <Colxx xxs="12 mb-2"> */}
                    <Colxx xxs="6 float-left">
                      {" "}
                      3.{" "}
                      {Application.Title?._id === TITLE.THIRUMATHI
                        ? "Husband’s Name"
                        : "Father's Name"}
                    </Colxx>
                    <Colxx xxs="6" className="text-underline float-left">
                      {Application && Application.FatherOrHusbandName}
                    </Colxx>
                    {/* </Colxx>
                    <Colxx xxs="12 mb-2"> */}
                    <Colxx xxs="6 float-left">4. Date of birth</Colxx>
                    <Colxx xxs="6" className="text-underline float-left">
                      {Application && Application.Dob
                        ? formatDate(
                            fromUnixTime(Application.Dob / 1000),
                            datePickerFormat
                          )
                        : ""}
                    </Colxx>
                    {/* </Colxx>
                    <Colxx xxs="12 mb-2"> */}
                    <Colxx xxs="6 float-left">
                      5. Whether SC/ST/OBC/General
                    </Colxx>
                    <Colxx xxs="6" className="text-underline float-left">
                      {Application &&
                        Application.Community &&
                        Application.Community.Name}
                    </Colxx>
                  </Colxx>
                  {/* </Colxx> */}
                  <Colxx xxs="4" className="text-center">
                    {/* {Application.Photo.includes(".pdf") ? (
                      <FileDisplay value={Application.Photo} />
                    ) : ( */}
                    <span>
                      <img
                        alt="noimg"
                        id="userimg"
                        className="applicantImage"
                        src={Application ? Application?.Photo : ""}
                        // onClick={() =>
                        //   this.togglePhotoViewModal(
                        //     this.state.Application.Photo,
                        //     "Photo"
                        //   )
                        // }
                      />
                    </span>
                    {/* )} */}
                  </Colxx>
                  <Colxx xxs="8">
                    <Colxx xxs="6 float-left">6.Address(Residence)</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {" "}
                      {Application && Application.FullResidencyAddress}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6 pl-4 float-left">Pin</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {" "}
                      {Application &&
                        Application.ResidencyAddress.Address.Pincode &&
                        Application.ResidencyAddress.Address.Pincode}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="2 pl-4 float-left ">Telephone/Mobile</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {Application && Application.MobileNumber}
                    </Colxx>
                    <Colxx xxs="2 float-left ">Fax</Colxx>
                    <Colxx
                      xxs="2 float-left"
                      className={
                        Application && Application.Fax ? "text-underline" : ""
                      }
                    >
                      {" "}
                      {Application && Application.Fax ? Application.Fax : "-"}
                    </Colxx>
                    <Colxx xxs="2 float-left "> E-mail</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {" "}
                      {Application && Application.EmailID}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6 pl-4 float-left">Address(Official)</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {" "}
                      {Application && Application.FullProvisionalAddress}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="2 pl-4 float-left ">Pin</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {Application &&
                        Application.ProvisionalAddress &&
                        Application.ProvisionalAddress.Address &&
                        Application.ProvisionalAddress.Address.Pincode}
                    </Colxx>
                    <Colxx xxs="2 float-left ">Aadhaar No</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {" "}
                      {Application && Application.Aadhar}
                    </Colxx>
                    <Colxx xxs="2 float-left "> PAN No</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {" "}
                      {Application && Application.PANNumber}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="2 pl-4 float-left ">Telephone/Mobile</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {Application && Application.MobileNumber}
                    </Colxx>
                    <Colxx xxs="2 float-left ">Fax</Colxx>
                    <Colxx
                      xxs="2 float-left"
                      className={
                        Application && Application.Fax ? "text-underline" : ""
                      }
                    >
                      {" "}
                      {Application && Application.Fax ? Application.Fax : "-"}
                    </Colxx>
                    <Colxx xxs="2 float-left"> E-mail</Colxx>
                    <Colxx xxs="2 float-left text-underline">
                      {" "}
                      {Application && Application.EmailID}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="10 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      7. Educational Qualifications (Please upload self-attested
                      scanned copies)
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="10 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      8. Enrolment number and date of the Bar Council (Please
                      upload self-attested copy){" "}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="10 mt-2">
                    <Colxx xxs="6 float-left"> 9. Practicing in </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6 pl-4 float-left"> Civil side</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application &&
                      Application.PracticingIn &&
                      Application.PracticingIn.length > 0 &&
                      Application.PracticingIn.find((x) =>
                        x.Name.toLowerCase().includes("civil")
                      )
                        ? "Yes"
                        : "No"}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6 pl-4 float-left"> Criminal side</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application &&
                      Application.PracticingIn &&
                      Application.PracticingIn.length > 0 &&
                      Application.PracticingIn.find((x) =>
                        x.Name.toLowerCase().includes("criminal")
                      )
                        ? "Yes"
                        : "No"}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6  pl-4 float-left"> Taxation side</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application &&
                      Application.PracticingIn &&
                      Application.PracticingIn.length > 0 &&
                      Application.PracticingIn.find((x) =>
                        x.Name.toLowerCase().includes("taxation")
                      )
                        ? "Yes"
                        : "No"}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6  pl-4 float-left"> Revenue Courts</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application &&
                      Application.PracticingIn &&
                      Application.PracticingIn.length > 0 &&
                      Application.PracticingIn.find((x) =>
                        x.Name.toLowerCase().includes("revenue")
                      )
                        ? "Yes"
                        : "No"}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="6 float-left">
                      {" "}
                      10. Whether Income-tax assessee
                    </Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application && Application.IncomeTaxAssessee
                        ? "Yes"
                        : "No"}
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="8 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      11. The application of (name of the applicant in block
                      letters)
                    </Colxx>
                    <Colxx xxs="6 float-left">Showeth</Colxx>
                    <Colxx xxs="6 float-left text-underline">
                      {Application && Application.Name
                        ? Application.Name.toUpperCase()
                        : ""}
                    </Colxx>
                  </Colxx>
                </Row>
              </Colxx>
              <Colxx xxs="12">
                <hr />
              </Colxx>
              <Colxx xxs="12" id="PrintForm1">
                <Row>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      1. That the applicant is a person eligible for appointment
                      as a notary under the Notaries Act, 1952, and clause (a)
                      of rule 3 of the Notaries Rules, 1956;
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      2. That the applicant practices as an Advocate{" "}
                      <span className="text-underline">
                        {" "}
                        {declarationDetail &&
                        declarationDetail.AreaOfPracticeAsAnAdvocate
                          ? declarationDetail.AreaOfPracticeAsAnAdvocate
                          : ""}
                      </span>
                      &nbsp;(herein state the name of the local area and name of
                      court where he intends to practice as an Advocate)
                    </Colxx>
                  </Colxx>
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      3. That the number of notaries practicing in the local
                      area is insufficient for the requirements thereof
                      (Statement to be added stating grounds for Requirement of
                      more Notaries)
                      <br />
                      <span className="text-underline">
                        {declarationDetail &&
                        declarationDetail.IntendedAreaOfPractice
                          ? declarationDetail.IntendedAreaOfPractice
                          : ""}{" "}
                      </span>
                    </Colxx>
                  </Colxx>

                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      4. That no previous application of the applicant has been
                      rejected or Withdrawn by him, within the preceding six
                      months;
                    </Colxx>
                  </Colxx>
                  <br />
                  <br />
                  <br />
                  <Colxx xxs="12 mt-2">
                    <Colxx xxs="12 float-left">
                      {" "}
                      The applicant, therefore, prays that the Government be
                      pleased to appoint and admit him as a notary under and by
                      virtue of the Notaries Act, 1952 (53 of 1952), and clause
                      (a) of rule 3 of the Notaries Rules, 1956, to practice in{" "}
                      <span className="text-underline">
                        {declarationDetail && declarationDetail.Name
                          ? declarationDetail.Name
                          : ""}{" "}
                      </span>{" "}
                      (Mention here the name of the local area where he/she
                      intends to practice as Notary).
                    </Colxx>
                  </Colxx>

                  {/* <Colxx xxs="12 mt-5 text-right">
                    <span>Signature of the applicant</span>
                  </Colxx> */}
                  <Colxx xxs="2"></Colxx>
                  <Colxx xxs="12 pl-2 mt-5">
                    <span>
                      Dated{" "}
                      <span className="text-underline">
                        {" "}
                        {new Date().getDay()}{" "}
                      </span>{" "}
                      day of{" "}
                      <span className="text-underline">
                        {" "}
                        {getMonthName(new Date().getMonth())}{" "}
                      </span>{" "}
                      <span className="text-underline">
                        {" "}
                        {new Date().getFullYear()}{" "}
                      </span>
                    </span>
                  </Colxx>
                </Row>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Form1));
