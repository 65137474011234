import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import i18n from "../../../i18n";
import { withNamespaces } from "react-i18next";
import Switch from "react-switch";
// falgs
// import usFlag from "../../../assets/images/flags/us.jpg";
// import spain from "../../../assets/images/flags/spain.jpg";
// import germany from "../../../assets/images/flags/germany.jpg";
// import italy from "../../../assets/images/flags/italy.jpg";
// import russia from "../../../assets/images/flags/russia.jpg";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      // flag: usFlag
      lang: sessionStorage.getItem("lng"),
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction = this.changeLanguageAction.bind(this);
  }
  changeLanguageAction = (lng) => {
    //set language as i18n
    var lang = "ta";
    if (lng) {
      lang = "en";
    }
    i18n.changeLanguage(lang);
    sessionStorage.setItem("lng", lang);
    this.setState({
      lang: lang,
    });
    window.location.reload();
  };

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  componentDidMount() {
    if (!sessionStorage.getItem("lng")) sessionStorage.setItem("lng", "en");
    i18n.changeLanguage(sessionStorage.getItem("lng"));
  }
  render() {
    return (
      <React.Fragment>
        {/* <div className="align-middle m-1">{sessionStorage.getItem("lng") === "ta" ? "Tamil" : "English"}</div> */}
        {/* <Switch
          onChange={this.changeLanguageAction}
          checked={sessionStorage.getItem("lng") === "en" ? true : false}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                paddingRight: 2,
                color: "white",
              }}
            >
              Ta
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                paddingRight: 2,
                color: "white",
              }}
            >
              En
            </div>
          }
        /> */}
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle className="btn header-item waves-effect" tag="button">
            <span className="align-middle">
              {sessionStorage.getItem("lng") === "ta" ? (
                <span style={{ color: "white" }}>
                  தமிழ் &nbsp;
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </span>
              ) : (
                <span style={{ color: "white" }}>
                  English &nbsp;
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </span>
              )}
            </span>
          </DropdownToggle>
          <DropdownMenu className="language-switch">
            <DropdownItem
              tag="a"
              //  href="#"
              onClick={() => this.changeLanguageAction(true)}
              className={`notify-item ${
                this.state.lng === "en" ? "active" : "none"
              }`}
            >
              <span className="align-middle">English</span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              //  href="#"
              onClick={() => this.changeLanguageAction(false)}
              className={`notify-item ${
                this.state.lng === "ta" ? "active" : "none"
              }`}
            >
              <span className="align-middle">தமிழ்</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(LanguageDropdown);
