import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withNamespaces } from "react-i18next";
import { Row, Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { fromUnixTime } from "date-fns";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import FileDisplay from "../../components/custom/FileDisplay";

class RenewalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  async savePDF() {
    this.setState({
      loading: true,
    });

    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    if (this.props.match) {
      options.scrollX = -7;
    }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    const printArea = document.getElementById("PrintForm");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");

    var pagePromises = [];

    Promise.all(pagePromises).then(() => {
      pdf.save(
        this.props.Application
          ? this.state.Application && this.state.Application.Title + ".pdf"
          : "saved.pdf"
      );
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 10000);
  }

  DownloadFormAsPDF = () => {
    const Application = this.props.Application.ApplicationFields
      ? this.props.Application.ApplicationFields
      : this.props.Application;
    console.log(this.props.Application.ApplicationFields);
    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    pdf.text("FORM XVI", 230, y, null, null, "center");
    y = y + 10;
    pdf.text("[See rule 8B]", 230, y, null, null, "center");
    y = y + 30;

    pdf.text("To,", 50, y, null, null);
    y = y + 10;

    pdf.text("Secretary to Government,", 50, y, null, null);
    y = y + 10;

    pdf.text("Law Department,", 50, y, null, null);
    y = y + 10;

    pdf.text("Secretariat,", 50, y, null, null);
    y = y + 10;

    pdf.text("Chennai - 9.", 50, y, null, null);
    y = y + 20;

    pdf.text(
      "Subject- Request for renewal of Certificate of Practice w.e.f.",
      230,
      y,
      null,
      null,
      "center"
    );

    pdf.text(
      Application.ValidFrom
        ? formatDate(
            fromUnixTime(Application.ValidFrom / 1000),
            datePickerFormat
          )
        : "",
      350,
      y,
      null,
      null
    );
    pdf.line(350, y + 5, 400, y + 5);
    y = y + 20;

    pdf.text("(G.O.(Ms) No. )", 120, y, null, null);
    pdf.text(Application.LastRenewalGONumber, 183, y, null, null);
    pdf.line(180, y + 5, 240, y + 5);

    pdf.text(", Law Department, dated", 242, y, null, null);
    pdf.text(
      Application.LastRenewedDate
        ? formatDate(
            fromUnixTime(Application.LastRenewedDate / 1000),
            datePickerFormat
          )
        : "",
      340,
      y,
      null,
      null
    );
    pdf.line(335, y + 5, 400, y + 5);

    y = y + 20;

    pdf.text(
      "{Note: Please mention the G.O. number by which your Certificate of Practice was lastly renewed.}",
      50,
      y,
      null,
      null
    );
    y = y + 20;

    pdf.text("Sir,", 50, y, null, null);
    y = y + 20;

    pdf.text(
      "The applicant was appointed as Notary vide G.O.(Ms) No.- ,",
      50,
      y,
      null,
      null
    );
    pdf.text(Application.NotaryAppointmentGONumber, 270, y, null, null);

    pdf.line(270, y + 5, 340, y + 5);

    pdf.text("Law Department,", 345, y, null, null);
    y = y + 20;
    pdf.text("dated", 50, y, null, null);

    pdf.text(
      Application.NotaryAppointmentGODate
        ? formatDate(
            fromUnixTime(Application.NotaryAppointmentGODate / 1000),
            datePickerFormat
          )
        : "",
      80,
      y,
      null,
      null
    );
    pdf.line(80, y + 5, 135, y + 5);

    pdf.text("w.e.f.", 140, y, null, null);

    pdf.text(
      Application.NotaryAppointmentDate
        ? formatDate(
            fromUnixTime(Application.NotaryAppointmentDate / 1000),
            datePickerFormat
          )
        : "",
      165,
      y,
      null,
      null
    );
    pdf.line(165, y + 5, 220, y + 5);
    pdf.text("to practice as such, in and throughout ", 230, y, null, null);
    pdf.line(370, y + 5, 400, y + 5);

    y = y + 20;

    pdf.text(Application.Areas, 50, y, null, null);
    pdf.line(50, y + 5, 400, y + 5);

    y = y + 20;

    pdf.text("You are requested to renew the same w.e.f.", 50, y, null, null);

    pdf.text(
      Application.ValidFrom
        ? formatDate(
            fromUnixTime(Application.ValidFrom / 1000),
            datePickerFormat
          )
        : "",
      220,
      y,
      null,
      null
    );
    pdf.line(220, y + 5, 265, y + 5);

    pdf.text("to", 270, y, null, null);

    pdf.text(
      Application.ValidTo
        ? formatDate(fromUnixTime(Application.ValidTo / 1000), datePickerFormat)
        : "",
      285,
      y,
      null,
      null
    );
    pdf.line(285, y + 5, 330, y + 5);

    pdf.text("at the earliest", 340, y, null, null);

    y = y + 30;

    // pdf.text("Name of the Applicant", 330, y, null, null);
    // y = y + 10;

    // pdf.text(Application.Name, 380, y, null, null);
    pdf.text("Name of the Applicant", 400, y, null, null, "right");
    y = y + 10;

    pdf.text(Application.Name, 400, y, null, null, "right");

    y = y + 30;
    pdf.text("Date: ", 50, y, null, null);
    pdf.text(
      Application.Date
        ? formatDate(fromUnixTime(Application.Date), datePickerFormat)
        : "",
      80,
      y,
      null,
      null
    );

    y = y + 20;
    pdf.text("Place: ", 50, y, null, null);
    pdf.text(Application.Place, 80, y, null, null);

    pdf.save(
      this.props.Application
        ? this.props.Application &&
            this.props.Application.ApplicationNoText + ".pdf"
        : "Form2.pdf"
    );
  };

  render() {
    const Application = this.props.Application.ApplicationFields;
    const ApplicationReason = this.props.Application;

    return (
      <Fragment>
        {console.log("Application")}
        {console.log(Application)}
        {console.log("ApplicationReason", ApplicationReason)}
        <Card>
          <CardBody>
            <Row>
              <Colxx xxs="12">
                <h4>Renewal Form</h4>
              </Colxx>
              <Colxx xxs="9"></Colxx>
              <Colxx xxs="3">
                <Button
                  className="form-second-btn"
                  type="button"
                  onClick={() => this.DownloadFormAsPDF()}
                  color="success"
                  disabled={this.state.loading}
                >
                  Download as PDF{" "}
                </Button>
              </Colxx>
              <Colxx xxs="12" id="PrintForm">
                <div
                // style={{
                //   width: "210mm",
                //   height: "297mm",
                // }}
                >
                  <Row>
                    <Colxx xxs="12 text-center mt-1">
                      <span>FORM XVI</span>
                    </Colxx>
                    <Colxx xxs="12 text-center  mt-1">
                      <span>[See rule 8B] </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>To, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Secretary to Government, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Law Department, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Secretariat, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Chennai - 9. </span>
                    </Colxx>
                    <Colxx xxs="12 text-center  mt-2">
                      <span>
                        Subject- Request for renewal of Certificate of Practice
                        w.e.f.{" "}
                        <span
                          className={
                            Application && Application.LastRenewedDate
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.LastRenewedDate
                            ? moment(
                                new Date(Application.LastRenewedDate)
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </span>
                      </span>
                    </Colxx>
                    <Colxx xxs="12 text-center  mt-2">
                      <span>
                        {" "}
                        (G.O.(Ms) No.{" "}
                        <span
                          className={
                            Application && Application.LastRenewalGONumber
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.LastRenewalGONumber
                            ? Application.LastRenewalGONumber
                            : "-"}
                        </span>
                        , Law Department, dated{" "}
                        <span
                          className={
                            Application && Application.LastRenewedDate
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.LastRenewedDate
                            ? moment(
                                new Date(Application.LastRenewedDate)
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </span>{" "}
                        ){" "}
                      </span>
                    </Colxx>
                    <Colxx xxs="12 text-center  mt-2">
                      <span>
                        {" "}
                        {`{Note: Please mention the G.O. number by which your Certificate of Practice was lastly renewed.}`}{" "}
                      </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Sir, </span>
                    </Colxx>
                    <Colxx xxs="12 mt-2">
                      <span>
                        The applicant was appointed as Notary vide G.O.(Ms) No.-{" "}
                        <span
                          className={
                            Application && Application.NotaryAppointmentGONumber
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.NotaryAppointmentGONumber
                            ? Application.NotaryAppointmentGONumber
                            : "-"}
                        </span>
                        , Law Department, dated{" "}
                        <span
                          className={
                            Application && Application.NotaryAppointmentGODate
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.NotaryAppointmentGODate
                            ? moment(
                                new Date(Application.NotaryAppointmentGODate)
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </span>{" "}
                        w.e.f.{" "}
                        <span
                          className={
                            Application && Application.NotaryAppointmentDate
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.NotaryAppointmentDate
                            ? moment(
                                new Date(Application.NotaryAppointmentDate)
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </span>{" "}
                        to practice as such, in and throughout{" "}
                        <span
                          className={
                            Application && Application.Areas
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.Areas
                            ? Application.Areas
                            : "-"}
                        </span>
                        . You are requested to renew the same w.e.f.{" "}
                        <span
                          className={
                            Application && Application.ValidFrom
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.ValidFrom
                            ? moment(new Date(Application.ValidFrom)).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </span>{" "}
                        to{" "}
                        <span
                          className={
                            Application && Application.ValidTo
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.ValidTo
                            ? moment(new Date(Application.ValidTo)).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </span>{" "}
                        at the earliest.{" "}
                      </span>
                    </Colxx>
                    {/* <Colxx xxs="12 mt-2">
                      <span>
                        {`2. The applicant ought to have applied for renewal on or 
                       before `}
                        <span
                          className={
                            Application && Application.RenewalBefore
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.RenewalBefore
                            ? Application.RenewalBefore
                            : "-"}
                        </span>
                        {".  "}
                        {`{Here state the date which is six months 
                        prior to the date of expiry of Certificate of Practice}.`}

                        <strong>
                          {`{Example: If Certificate of Practice expires on 1.1.2020,
                         then 1.7.2019 should be mentioned}.`}{" "}
                        </strong>
                        {`The applicant also states that due to `}

                        <span
                          className={
                            Application && Application.Reason
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.Reason
                            ? Application.Reason
                            : "-"}
                        </span>
                        {". "}
                        {`{Here state the reasons 
                          for the delay} {Note: If the reason is ill-health, medical
                           certificate should be scanned and attached), he was unable to submit the 
                           application in time.  Hence the delay may be condoned.`}
                      </span>
                    </Colxx>
                    <Colxx xxs="12 mt-2">
                      <span>
                        <strong>
                          {`(Para 2 above is applicable only if there is delay in submission of application)`}
                        </strong>
                      </span>
                    </Colxx> */}
                    {ApplicationReason.Reason &&
                      ApplicationReason.ReasonDocuments?.[0] && (
                        <Colxx xxs="6 text-center  mt-2">
                          <Row>
                            <Colxx xxs="6 text-center  mt-3">
                              <Row>
                                <Colxx xxs="12 text-left  mt-3">
                                  <span>
                                    {`Reason: `}
                                    <span
                                      className={
                                        ApplicationReason &&
                                        ApplicationReason.Reason
                                          ? "text-underline"
                                          : ""
                                      }
                                    >
                                      {" "}
                                      {ApplicationReason &&
                                      ApplicationReason.Reason
                                        ? ApplicationReason.Reason
                                        : "-"}
                                    </span>
                                  </span>
                                </Colxx>
                              </Row>
                              <Row>
                                <Colxx xxs="12 text-left  mt-3">
                                  <span>
                                    {`Reason Document: `}
                                    <span
                                      className={
                                        ApplicationReason &&
                                        ApplicationReason?.ReasonDocuments?.[0]
                                          ? "text-underline"
                                          : ""
                                      }
                                    >
                                      {" "}
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={
                                            ApplicationReason
                                              .ReasonDocuments?.[0]
                                          }
                                        />
                                      </p>
                                    </span>
                                  </span>
                                </Colxx>
                              </Row>
                            </Colxx>
                          </Row>
                        </Colxx>
                      )}

                    <Colxx xxs="6 text-center  mt-2">
                      <Row>
                        <Colxx xxs="12 text-left  mt-2">
                          <span>
                            {`Date: `}
                            <span
                              className={
                                Application && Application.Date
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application && Application.Date
                                ? moment(
                                    new Date(Application.Date * 1000)
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </span>
                          </span>
                        </Colxx>
                        <Colxx xxs="12 text-left  mt-2">
                          <span>
                            {`Place: `}

                            <span
                              className={
                                Application && Application.Place
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application && Application.Place
                                ? Application.Place
                                : "-"}
                            </span>
                          </span>
                        </Colxx>
                      </Row>
                    </Colxx>
                    <Colxx xxs="6 text-right  mt-2 float-right">
                      <Row>
                        <Colxx xxs="12 text-right  mt-2">
                          <span>{`Name of the Applicant`}</span>
                        </Colxx>
                        <Colxx xxs="12 text-right  mt-2">
                          <span
                            className={
                              Application && Application.Name
                                ? "text-underline"
                                : ""
                            }
                          >
                            {" "}
                            {Application && Application.Name
                              ? Application.Name
                              : "-"}
                          </span>
                        </Colxx>
                      </Row>
                    </Colxx>
                  </Row>
                </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(RenewalForm));
