import React, { Component, Fragment } from "react";
import { Colxx } from "../Common/CustomBootstrap";
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";
import { checkIfImage } from "../../helpers/Utils";
import loader from "../../assets/images/Loader.gif";

export default class FileDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DocumentViewModal: false,
      DocumentView: null,
    };
  }
  ToggleViewModal = (url) => {
    this.setState({
      DocumentViewModal: !this.state.DocumentViewModal,
      DocumentView: url,
    });
  };
  render() {
    console.log(
      "FileDisplay",
      this.state.DocumentView,
      checkIfImage(this.state.DocumentView)
    );
    return (
      <Fragment>
        <div className={this.props.className}>
          {this.props.Value && (
            <span
              style={{ cursor: "Pointer" }}
              className="rdt_Table LinkStyle"
              onClick={() => this.ToggleViewModal(this.props.Value)}
            >
              View
            </span>
          )}
          {this.props.Value && " | "}
          {this.props.Value && (
            <a
              className="LinkStyle"
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.Value}
            >
              Download
            </a>
          )}
        </div>
        <Modal
          className="viewDocumentModal"
          isOpen={this.state.DocumentViewModal}
          size="lg"
          toggle={this.ToggleViewModal}
        >
          <ModalHeader toggle={this.ToggleViewModal}>
            <b>View</b>
          </ModalHeader>
          <ModalBody>
            <Row>
              {this.state.DocumentView ? (
                checkIfImage(this.state.DocumentView) ? (
                  ""
                ) : (
                  <div id="mydiv">
                    <img alt="loader" src={loader} className="ajax-loader" />
                  </div>
                )
              ) : (
                ""
              )}
              <Colxx xxs="12">
                <iframe
                  onLoad={this.hideSpinner}
                  title="documentview"
                  width="100%"
                  height="600px"
                  src={`https://docs.google.com/viewer?url=${this.state.DocumentView}&embedded=true`}
                ></iframe>
              </Colxx>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormGroup>
              <a href={this.state.DocumentView} target="_blank">
                <Button
                  type="submit"
                  className="default   btn-sm mb-2 btn-addon"
                  color="primary"
                >
                  Download
                </Button>{" "}
              </a>
              <Button
                className="default   btn-sm mb-2 btn-addon"
                color="danger"
                onClick={() => this.ToggleViewModal()}
              >
                Close
              </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
