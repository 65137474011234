/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const servicePath = "https://api.coloredstrategies.com";
export const userNameRegex = /^[a-zA-Z0-9]*$/;
export const INVALID_CHARS = [
  `'`,
  `"`,
  `;`,
  `>`,
  `<`,
  `=`,
  `(`,
  `)`,
  `&`,
  `/`,
  `\\`,
];

export const MethodType = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
  FORMDATA: "FORMDATA",
};

export const StatusCodes = {
  Error: "500",
  InvalidData: "422",
  Success: "200",
  Unauthorized: "401",
  InvalidMediaType: "415",
  Forbidden: "403",
  NotFound: "404",
  Duplicate: "409",
};

export const masterListPageTableTheme = {
  rows: {
    height: "35px",
    cursor: "pointer",
  },
};

export const ApplicationStatus = {
  Draft: "Draft",
  Approved: "Approved",
  SeekingClarification: "Seeking Clarifications",
  Submitted: "Action Requested",
  Rejected: "Rejected",
};

export const Roles = {
  Admin: "admin",
  Officer: "Officer",
  DistrictAdmin: "DistrictAdmin",
  TnegaAdmin: "TnegaAdmin",
  Public: "Public",
};

export const RespondingDateThreshold = {
  info: 50,
  warning: 75,
};

export const STATUSES = {
  SelectiveRecommended: "Selective Recommend",
  Recommended: "Recommended",
  InterviewCallLetterSent: "Interview Call Letter Sent",
  Forwarded: "Forwarded",
  Scheduled: "Scheduled",
  Rescheduled: "Rescheduled",
  InterviewCleared: "Interview Cleared",
  InterviewFailed: "Interview Failed",
  PaymentPending: "Payment Pending",
  PaymentCompleted: "Payment Completed",
  Approved: "Approved",
  Rejected: "Rejected",
  NotRecommend: "Not Recommended",
};
export const TYPES = {
  REGISTRATION: "REGISTRATION",
  RENEWAL: "RENEWAL",
  DUPLICATE: "DUPLICATE",
  EXTENSION: "EXTENSION",
  EXISTINGNOTARY: "EXISTINGNOTARY",
};
export const FORMTYPES = {
  Form1: "5fae4254a3ac0e0f4830935d",
  Form2: "5fae4254a3ac0e0f4830935c",
};
export const TITLE = {
  THIRUMATHI: "5fae22d5a3ac0e0f4830935a",
  SELVI: "5fae22d5a3ac0e0f48309359",
  THIRU: "5fae22d5a3ac0e0f4830935b",
};

export const numberFormat = (value) =>
  // new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  // }).format(value);
  new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
    // style: "currency",
    // currency: "INR",
  }).format(value);

export const datePickerFormat = "dd/MM/yyyy";

export const QualificationTypes = [
  { value: "Three Years Law Degree", label: "3 Years Law Degree" },
  { value: "Five Years Law Degree", label: "5 Years Law Degree" },
];

export const QUALIFICATIONTYPES = {
  ThreeYearDegree: "Three Years Law Degree",
  FiveYearDegree: "Five Years Law Degree",
};

export const monthNames = [
  "Januray",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const COMMUNITY = {
  General: "5f7c0a9cd113d619c793d8c6",
};
export const GENDER = {
  Male: "5f7d565dedee1f15cfa89944",
  Female: "5f7d56a8edee1f15cfa89946",
  Others: "5f7d56afedee1f15cfa89947",
};
