import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Card, CardBody } from "reactstrap";
import { cms } from "../../constants/config";

import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      Advocates: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      HtmlContent: "",
    };
  }
  componentDidMount() {
    this.GetCMS("contact");
  }

  GetCMS(search) {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = search;
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        HtmlContent: data.result && data.result[0],
      });
    }
  };

  addBtnClick = () => {
    this.toggleManageModal();
  };

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <CardBody>
                <br></br>
                <div
                  className="card-body accordion-content"
                  dangerouslySetInnerHTML={{
                    __html: this.state.HtmlContent
                      ? this.state.HtmlContent.Content
                      : "",
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Contact;
