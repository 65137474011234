import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
import { withNamespaces } from "react-i18next";
import { getDateTimeString } from "../../../helpers/Utils";
import { CallService } from "../../../helpers/ServiceCall";
import { Case } from "../../../constants/config";
import { MethodType } from "../../../constants/defaultValues";

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      Cases: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllCase();
        }
      }
    );
  };
  GetAllCase() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      Case.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllCaseResponse
    );
  }

  GetAllCaseResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        Cases: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
          style={{ marginTop: "-0.5rem" }}
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => this.SearchQueryChanged(e)}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> Cases </h6>
                </Col>
                <div className="col-auto">
                  {/* <a href="#!" className="small"> View All</a> */}
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {this.state.Cases.map((r) => (
                <Link
                  to={`/case-manage/${r._id}`}
                  className="text-reset notification-item"
                >
                  <div className="media">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="mdi mdi-notebook-outline"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{r.CaseNo}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {r.CourtItem ? r.CourtItem.Name : ""}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {r.NextHearingDate
                            ? getDateTimeString(r.NextHearingDate, false)
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </SimpleBar>
            <div className="p-2 border-top">
              {/* <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="#"
              >
                {" "}
                {this.props.t('View all')}{" "}
              </Link> */}
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(SearchDropdown);
