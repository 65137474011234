import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo.png";
import logofull from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo.png";

//i18n
import { withNamespaces } from "react-i18next";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import SearchDropdown from "../CommonForBoth/TopbarDropdown/SearchDropdown";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventchanges: true,
      isSearch: false,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      Cases: [],
      curTime: new Date().toLocaleString("en-In"),
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu(event) {
    this.props.toggleMenuCallback();
    if (event) {
      document.getElementsByClassName("logo logo-light")[0].style.display =
        "none";
      document.getElementsByClassName("logo logo-dark")[0].style.display =
        "block";
      this.setState({
        eventchanges: false,
      });
    } else {
      document.getElementsByClassName("logo logo-light")[0].style.display =
        "block";
      document.getElementsByClassName("logo logo-dark")[0].style.display =
        "none";
      this.setState({
        eventchanges: true,
      });
    }
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({
        curTime: new Date().toLocaleString("en-In"),
      });
    }, 1000);
  }
  render() {
    var event = this.state.eventchanges;
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <span className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="50" />
                  </span>
                </Link>
                <Link to="/" className="logo logo-light bg-white ">
                  {/* <span className="logo-sm">
                     <img src={logoLightSvg} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="40" />
                  </span>
                  <span className={"text-white ml-2"}>
                    Tamil Nadu Pollution Control
                    </span> */}
                  <span className="logo-sm">
                    <img src={logofull} alt="TN Notary" height="55" />
                  </span>
                  <span className="logo-lg" style={{ marginLeft: "5rem" }}>
                    <img src={logofull} alt="TN Notary" height="73" />
                  </span>
                </Link>
              </span>

              <button
                type="button"
                onClick={() => this.toggleMenu(event)}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}
              <h5 className="position-relative mt-4">Law Admin</h5>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  {/* <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(e) => this.SearchQueryChanged(e)}
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Case"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
              {/* <SearchDropdown /> */}
              {/* <NotificationDropdown /> */}
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withNamespaces()(Header)
);
