import React, { Component, Fragment, createRef } from "react";
import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import {
  advocate,
  district,
  state,
  documentMaster,
  profileComplete,
  community,
  gender,
  practicingin,
  taluk,
  title,
  formType,
  cms,
  authenctication,
} from "../../constants/config";
import { CallService } from "../../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  FORMTYPES,
  TITLE,
  datePickerFormat,
  QualificationTypes,
  QUALIFICATIONTYPES,
  GENDER,
} from "../../constants/defaultValues";
import DropDown from "../../components/custom/DropDown";
import FileDisplay from "../../components/custom/FileDisplay";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import { Formik, Form, Field } from "formik";
import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "rc-switch/assets/index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  profileTab1Validation,
  profileTab2Validation,
  profileTab3Validation,
  profileTab4Validation,
  profileTab5Validation,
} from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { editProfile } from "../../store/actions";
// import { Configuration, OpenAIApi } from "openai";
import {
  getFileSizeText,
  getAllowedExtensionText,
  ClearStorageValues,
  INVALID_CHARS,
  INVALID_CHARS_REGEX,
  allow_file_formats,
} from "../../helpers/Utils";
import { subYears, fromUnixTime } from "date-fns";
import {
  Base64ToUint8Array,
  encryptBox,
  decryptBox,
  formatEncryptedField,
  Uint8ArrayToBase64,
} from "../../helpers/encryption";
// import { TamilKeyboard } from "cosmic-tamil-keyboard";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { Input } from "antd";
import Axios from "axios";
// const cheerio = require('cheerio');
// const htmlparser = require("htmlparser2");
class Profile extends Component {
  constructor(props) {
    super(props);
    this.keyboard = null;
    this.state = {
      visible: true,
      inputs: "",
      IsProfileCompleted: JSON.parse(
        sessionStorage.getItem("IsProfileCompleted")
          ? sessionStorage.getItem("IsProfileCompleted")
          : false
      ),
      UserId: sessionStorage.getItem("user_id"),
      ActiveTabId: 1,
      tabs: [
        {
          id: 1,
          Name: this.props.t("Form Type"),
        },
        {
          id: 2,
          Name: this.props.t("Personal"),
        },
        {
          id: 3,
          Name: this.props.t("Professional"),
        },
        {
          id: 4,
          Name: this.props.t("Qualification"),
        },
        {
          id: 5,
          Name: this.props.t("Address"),
        },
      ],
      showTamilKeyboard: false,
      AadhaarEdit: true,
      PANEdit: true,
      TamilName: "",
      Profile: {
        Gender: "",
        FormType: "",
        GovtServiceStartDate: "",
        GovtServiceEndDate: "",
        GovtServicePost: "",
        Married: "No",
        IsMarried: false,
        SameAsResidenceAddress: false,
        MobileNumber: sessionStorage.getItem("MobileNumber"),
        EmailID: sessionStorage.getItem("EmailID"),
        // EmailID: "",
        FatherOrHusbandName: "",
        Community: "",
        Degrees: [
          {
            Institution: "",
            Name: "",
            Url: "",
            YearOfPassing: 0,
          },
          {
            Institution: "",
            Name: "",
            Url: "",
            YearOfPassing: 0,
          },
        ],
        QualificationType: "",
        ResidencyAddress: {
          // Address: {
          // }
        },
        ProvisionalAddress: {
          // Address: {
          // }
        },
      },
      Language: sessionStorage.getItem("lng"),
      EligibilityStatus: true,
      Communities: [],
      PracticingInList: [],
      DistrictList: [],
      TalukList: [],
      CallCompletedAPI: false,
      StateList: [],
      titles: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
      },
      TitleBaseGender: "",
    };
    // this.keyboardRef = React.createRef();
    // this.inputRef = React.createRef();/
  }
  componentDidMount() {
    console.log("ProfileProfileProfile", this.state.Profile);
    window.scrollTo(0, 0);
    // this.handleChangeText();
    this.getSeed();
    this.GetCMS("eligibility");
    this.GetAllFormTypes();
    this.GetAllCommunity();
    this.GetAllGender();
    this.GetAllState();
    this.GetAllDistrict();
    this.GetAllTaluk("");
    this.GetAllTitles();
    this.GetAllPracticingIn();
    var user = JSON.parse(sessionStorage.getItem("user"));
    console.log("wertyui", user, user?.Applicant, user?.Applicant?.Aadhar);
    if (user && !user?.Applicant?.Aadhar) {
      // Set AadhaarEdit to false if IsProfileCompleted is falsy
      this.setState({
        AadhaarEdit: false,
        PANEdit: false,
      });
    }
    // if (this.state.IsProfileCompleted) {
    this.GetProfileDetail();
    this.keyboard = !this.state.IsProfileCompleted
      ? new Keyboard(".keyboard-container", {
          layout: {
            default: [
              "ஸ ஹ ஜ ஷ \u0BBE \u0BBE \u0BC0 \u0BC6 \u0BC7 \u0BC8 \u0BCB \u0BCC ் \u0BBF \u0BC2 \u0BC1 \u0BD7 \u0BF2 பு",
              "\u0B85 \u0B86 \u0B87 \u0B88 \u0B89 \u0B8A \u0B8E \u0B8F \u0B90 \u0B92 \u0B93 \u0B94",
              "{tab} \u0B95 \u0B99 \u0B9A \u0B9E \u0B9F \u0BA3 \u0BA4 \u0BA8 \u0BAA \u0BAE \u0BAF {tab}",
              "{lock} \u0BB5 \u0BB4 \u0BB3 \u0BB2 \u0BB0 \u0BB1 \u0BA9 \u0B95 \u0B99 \u0B9A \u0B9F {enter}",
              "{bksp} {space}",
            ],
          },
          display: {
            "{bksp}": "⌫",
            "{enter}": "Enter",
            "{tab}": "Tab",
            "{lock}": "Caps",
            "{shift}": "Shift",
            "{space}": "Space",
          },
          theme: "hg-theme-default hg-layout-numeric",
          mergeDisplay: true,
          debug: false,
          disableButtonHold: true,
          onChange: (input) => this.handleKeyboardChange(input),
          onKeyPress: (button) => this.onKeyPress(button),
        })
      : null;
    // }
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState(
        {
          ServerPublicKey: data.result.ServerPublicKey,
          SeedId: data.result._id,
        },
        () => {
          this.getSecondSeed();
        }
      );
    }
  };
  getSecondSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSecondSeedResponse
    );
  }
  getSecondSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState({
        SecondServerPublicKey: data.result.ServerPublicKey,
        SecondSeedId: data.result._id,
      });
    }
  };
  GetProfileDetail = () => {
    CallService(
      advocate.GetOne + this.state.UserId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetProfileDetailResponse
    );
  };

  GetProfileDetailResponse = (data) => {
    if (data.result) {
      let profileData = data.result;
      // profileData.QualificationType = profileData.IsIntegratedDegree
      //   ? QUALIFICATIONTYPES.Integrated
      //   : QUALIFICATIONTYPES.NonIntegrated;

      profileData.Married = profileData.IsMarried ? "Yes" : "No";
      profileData.GovtServiceEndDate = profileData.GovtServiceEndDate
        ? parseInt(profileData.GovtServiceEndDate)
        : 0;
      profileData.GovtServiceEndDateObj = fromUnixTime(
        profileData.GovtServiceEndDate / 1000
      );
      profileData.GovtServiceStartDate = profileData.GovtServiceStartDate
        ? parseInt(profileData.GovtServiceStartDate)
        : 0;
      profileData.GovtServiceStartDateObj = fromUnixTime(
        profileData.GovtServiceStartDate / 1000
      );

      profileData.Dob = profileData.Dob ? parseInt(profileData.Dob) : 0;
      profileData.DobObj = fromUnixTime(profileData.Dob / 1000);

      profileData.EnrolmentDate = profileData.EnrolmentDate
        ? parseInt(profileData.EnrolmentDate)
        : 0;
      profileData.EnrolmentDateObj = fromUnixTime(
        profileData.EnrolmentDate / 1000
      );
      profileData.PracticingIn = profileData.PracticingIn.map(
        (item) => item._id
      );
      if (profileData.Degrees.length === 0) {
        profileData.Degrees = [
          {
            Institution: "",
            Name: "",
            Url: "",
            YearOfPassing: 0,
          },
          {
            Institution: "",
            Name: "",
            Url: "",
            YearOfPassing: 0,
          },
        ];
      }
      this.setState({
        Profile: profileData,
        TamilName: profileData.TamilName,
      });
    }
  };

  toggleTab = (tabId) => {
    this.setState({
      ActiveTabId: tabId,
    });
  };

  GetCMS = (searchString) => {
    let requestModel = {
      pageNumber: 1,
      pageLimit: 999999999999,
      searchString: searchString,
    };
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      requestModel,
      "",
      this.GetCMSResponse
    );
  };

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        EligibilityContent: data.result && data.result[0],
      });
    }
  };

  toggleEligibilityModal = () => {
    this.setState({
      manageEligibilityModal: !this.state.manageEligibilityModal,
    });
  };

  GetAllFormTypes = () => {
    CallService(
      formType.getAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllFormTypesResponse
    );
  };

  GetAllFormTypesResponse = (data) => {
    this.setState({
      formTypes: [{ value: data.result[0]._id, label: "Register Yourself" }],
      // formTypes: data.result.map(function (a) {
      //   return { value: a._id, label: a.Name };
      // }),
    });
  };

  GetAllDistrict = () => {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  };

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllTaluk = (districtId) => {
    let { ListRequestModel } = this.state;
    ListRequestModel.District = districtId;
    ListRequestModel.pageLimit = 1000;

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  };

  GetAllTalukResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllState = () => {
    CallService(
      state.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllStateResponse
    );
  };

  translateWithGoogleTranslate = (message, sourceLang, targetLang) => {
    const url = `https://translate.google.com/m?hl=${sourceLang}&sl=${sourceLang}&tl=${targetLang}&ie=UTF-8&prev=_m&q=${encodeURIComponent(
      message
    )}`;

    try {
      const response = Axios.get(url);
      const chunks = [];
      console.log("");
      // const parser = new htmlparser.Parser(
      //   {
      //     ontext: (text) => {
      //       chunks.push(text);
      //     },
      //   },
      //   { decodeEntities: true }
      // );

      // parser.write(response.data);
      // parser.end();

      const translatedText = chunks.join("");

      return translatedText;
    } catch (error) {
      console.error("Translation Error:", error);
      throw error;
    }
  };
  handleChangeText = () => {
    const message = "jagatheeswaran";
    const sourceLang = "en";
    const targetLang = "ta";
    debugger;
    try {
      const translatedText = this.translateWithGoogleTranslate(
        message,
        sourceLang,
        targetLang
      );
      console.log("Translated Text:", translatedText);

      // You can append the translated text to your existing code as needed.
    } catch (error) {
      // Handle the error as needed.
      console.log("Translated Text", error);
    }
  };

  // Call the translation function

  GetAllStateResponse = (data) => {
    if (data.result) {
      //   this.setState(
      //     {
      //       StateList: data.result.map(function (a) {
      //         return { value: a._id, label: "தமிழ்நாடு" };
      //       }),
      //     },
      //     function () {
      //       let profileModel = this.state.Profile;

      //       profileModel.ResidencyAddress.Address.State = data.result[0]._id;
      //       profileModel.ProvisionalAddress.Address.State = data.result[0]._id;
      //       this.setState({
      //         Profile: profileModel,
      //       });
      //     }
      //   );
      // } else if (this.state.Language === "en") {
      this.setState(
        {
          StateList: data.result.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
        },
        function () {
          let profileModel = this.state.Profile;
          let addressModel = { State: data.result[0]._id };
          profileModel.ResidencyAddress.Address = addressModel;
          profileModel.ProvisionalAddress.Address = addressModel;
          this.setState({
            Profile: profileModel,
          });
        }
      );
    }
  };
  GetAllCommunity = () => {
    CallService(
      community.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllCommunityResponse
    );
  };

  GetAllCommunityResponse = (data) => {
    this.setState({
      Communities: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };

  GetAllGender = () => {
    CallService(
      gender.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllGenderResponse
    );
  };
  componentWillUnmount() {
    if (this.keyboard) {
      this.keyboard.destroy();
    }
  }

  onChange = (input) => {
    // Handle the input change event
    console.log("Input:", input);
  };

  GetAllGenderResponse = (data) => {
    if (this.state.TitleBaseGender !== "") {
      if (this.state.TitleBaseGender === TITLE.THIRU) {
        var idToRemove = GENDER.Female;

        var filterGender = data.result.filter((item) => item.id !== idToRemove);

        this.setState({
          GenderList: filterGender.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
        });
      } else if (
        this.state.TitleBaseGender === TITLE.THIRUMATHI ||
        this.state.TitleBaseGender === TITLE.SELVI
      ) {
        idToRemove = GENDER.Male;

        filterGender = data.result.filter((item) => item.id !== idToRemove);

        this.setState({
          GenderList: filterGender.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
        });
      }
    } else {
      this.setState({
        GenderList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllTitles = () => {
    CallService(
      title.getAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllTitleResponse
    );
  };

  GetAllTitleResponse = (data) => {
    this.setState({
      titles: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };

  GetAllPracticingIn = () => {
    CallService(
      practicingin.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllPracticingInResponse
    );
  };

  GetAllPracticingInResponse = (data) => {
    console.log("GetAllPracticingInResponse", data);
    this.setState({
      PracticingInList: data.result.map(function (a) {
        return { value: a.id, label: a.Name };
      }),
    });
  };

  CheckEligibilty = (
    formType,
    gender,
    community,
    enrollmentDate,
    serviceStartDate,
    serviceEndDate
  ) => {
    let requestObj = {};
    requestObj.FormType = formType;
    if (formType === FORMTYPES.Form1) {
      requestObj.Community = community;
      requestObj.EnrolmentDate = enrollmentDate;
      requestObj.Gender = gender;
      if (
        requestObj.Gender &&
        requestObj.Community &&
        requestObj.EnrolmentDate
      ) {
        CallService(
          profileComplete.ValidateProfile,
          MethodType.POST,
          false,
          requestObj,
          "",
          this.CheckEligibiltyResponse
        );
      }
    } else if (formType === FORMTYPES.Form2) {
      requestObj.GovtServiceStartDate = serviceStartDate;
      requestObj.GovtServiceEndDate = serviceEndDate;
      if (requestObj.GovtServiceStartDate && requestObj.GovtServiceEndDate) {
        console.log("requestObj", requestObj);
        CallService(
          profileComplete.ValidateProfile,
          MethodType.POST,
          false,
          requestObj,
          "",
          this.CheckEligibiltyResponse
        );
      }
    }
  };

  CheckEligibiltyResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        EligibilityStatus: data.result.Eligible,
        EligibilityMessage: data.exception,
      });
    }
  };
  handleProfileUpdate = (values, { errors }) => {
    console.log(values);
    let profile = this.state.Profile;
    profile = values;
    // this.setState({
    //   Profile: values
    // });
    if (this.state.EligibilityStatus) {
      if (this.ButtonPressed === "Next") {
        debugger;
        //#Call API for auto save
        const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
        if (this.state.ActiveTabId === 1) {
          this.setState({
            CheckAadhar: false,
            CheckEligible: false,
            ButtonClicked: "Next",
            CheckPAN: false,
          });
        }
        if (this.state.ActiveTabId === 2) {
          this.setState({
            CheckAadhar: true,
            CheckEligible: false,
            ButtonClicked: "Next",
            CheckPAN: false,
            // AadhaarEdit:
          });
        }
        if (this.state.ActiveTabId === 3) {
          this.setState({
            CheckAadhar: false,
            CheckPAN: true,
            CheckEligible: false,
            ButtonClicked: "Next",
          });
        }
        if (this.state.ActiveTabId === 4) {
          this.setState({
            CheckAadhar: false,
            CheckPAN: false,
            CheckEligible: true,
            ButtonClicked: "Next",
          });
        }
        if (this.state.ActiveTabId === 5) {
          this.setState({
            CheckAadhar: true,
            CheckEligible: true,
            ButtonClicked: "Next",
          });
        }

        this.setState({ ButtonClicked: "Next" });
        debugger;
        if (
          !this.state.AadhaarEdit &&
          values &&
          values.Aadhar &&
          this.state.CheckAadhar &&
          !/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(values.Aadhar.toString())
        ) {
          console.log("MAD ATAME ");
          toastr.warning("", "Invalid Aadhaar Number");
          return;
        }
        if (
          !this.state.AadhaarEdit &&
          values &&
          values.Aadhar &&
          this.state.CheckAadhar
        ) {
          const Aadhar = values?.Aadhar && values?.Aadhar?.toString();
          var { cipherText, nonceText, clientPublicKeyText } = encryptBox(
            Aadhar,
            serverPublicKey
          );
          values.Aadhar = formatEncryptedField(
            this.state.SeedId,
            cipherText,
            nonceText,
            clientPublicKeyText
          );
        }
        if (
          this.state.CheckPAN &&
          !this.state.PANEdit &&
          values &&
          values.PANNumber &&
          !/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(values?.PANNumber?.toString())
        ) {
          toastr.warning("", "Invalid PAN Number");
          return;
        }
        if (
          !this.state.PANEdit &&
          values &&
          values.PANNumber &&
          this.state.CheckPAN
        ) {
          const SecondServerPublicKey = Base64ToUint8Array(
            this.state.SecondServerPublicKey
          );
          const PANNumber = values.PANNumber && values.PANNumber.toString();
          var { cipherText, nonceText, clientPublicKeyText } = encryptBox(
            PANNumber,
            SecondServerPublicKey
          );
          values.PANNumber = formatEncryptedField(
            this.state.SecondSeedId,
            cipherText,
            nonceText,
            clientPublicKeyText
          );
        }
        let formData = new FormData();
        let hasFormData = false;

        values.Degrees.map((qualification, qIndex) => {
          if (typeof qualification.Url === "object") {
            formData.append("DegreeCopy" + qIndex, qualification.Url);
            hasFormData = true;
          }
          return "";
        });
        if (typeof values.Photo === "object" && typeof values.Photo !== null) {
          formData.append("Photo", values.Photo);
          hasFormData = true;
        }
        if (
          typeof values.CommunityCertificate === "object" &&
          typeof values.CommunityCertificate !== null
        ) {
          formData.append("CommunityCertificate", values.CommunityCertificate);
          hasFormData = true;
        }
        if (
          typeof values.AadharCopy === "object" &&
          typeof values.AadharCopy !== null
        ) {
          formData.append("AadharCopy", values.AadharCopy);
          hasFormData = true;
        }
        if (
          typeof values.PANCopy === "object" &&
          typeof values.PANCopy !== null
        ) {
          formData.append("PANCopy", values.PANCopy);
          hasFormData = true;
        }
        if (
          typeof values.BarCouncilCertificate === "object" &&
          typeof values.BarCouncilCertificate !== null
        ) {
          formData.append(
            "BarCouncilCertificate",
            values.BarCouncilCertificate
          );
          hasFormData = true;
        }
        if (
          typeof values.BarCouncilExperienceCertificate === "object" &&
          typeof values.BarCouncilExperienceCertificate !== null
        ) {
          formData.append(
            "BarCouncilExperienceCertificate",
            values.BarCouncilExperienceCertificate
          );
          hasFormData = true;
        }
        if (
          typeof values.GovtServiceStartUrl === "object" &&
          typeof values.GovtServiceStartUrl !== null
        ) {
          formData.append("GovtServiceStartUrl", values.GovtServiceStartUrl);
          hasFormData = true;
        }
        if (
          typeof values.GovtServiceEndUrl === "object" &&
          typeof values.GovtServiceEndUrl !== null
        ) {
          formData.append("GovtServiceEndUrl", values.GovtServiceEndUrl);
          hasFormData = true;
        }
        if (
          typeof values.ExperienceCertificate === "object" &&
          typeof values.ExperienceCertificate !== null
        ) {
          formData.append(
            "ExperienceCertificate",
            values.ExperienceCertificate
          );
          hasFormData = true;
        }
        this.toggleLoadingModal();
        if (hasFormData) {
          this.CurrentProfileValues = values;
          CallService(
            documentMaster.FileUpload,
            MethodType.POST,
            false,
            formData,
            "",
            this.DocumentUploaded,
            true
          );
        } else {
          this.ManageProfile(values);
        }
      } else if (this.ButtonPressed === "Prev") {
        debugger;
        this.GetProfileDetail();

        this.setState({ ButtonClicked: "Prev" });
        this.toggleTab(this.state.ActiveTabId - 1);
      } else if (this.ButtonPressed === "Submit") {
        this.setState({ ButtonClicked: "Submit" });

        const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
        this.setState({ CallCompletedAPI: true });
        if (values && values.Aadhar) {
          const Aadhar = values.Aadhar && values.Aadhar.toString();
          var { cipherText, nonceText, clientPublicKeyText } = encryptBox(
            Aadhar,
            serverPublicKey
          );
          values.Aadhar = formatEncryptedField(
            this.state.SeedId,
            cipherText,
            nonceText,
            clientPublicKeyText
          );
        }

        if (values && values.PANNumber) {
          const SecondServerPublicKey = Base64ToUint8Array(
            this.state.SecondServerPublicKey
          );

          const PANNumber = values.PANNumber && values.PANNumber.toString();
          var { cipherText, nonceText, clientPublicKeyText } = encryptBox(
            PANNumber,
            SecondServerPublicKey
          );
          values.PANNumber = formatEncryptedField(
            this.state.SecondSeedId,
            cipherText,
            nonceText,
            clientPublicKeyText
          );
        }
        let formData = new FormData();
        let hasFormData = false;

        values.Degrees.map((qualification, qIndex) => {
          if (typeof qualification.Url === "object") {
            formData.append("DegreeCopy" + qIndex, qualification.Url);
            hasFormData = true;
          }
          return "";
        });
        if (typeof values.Photo === "object") {
          formData.append("Photo", values.Photo);
          hasFormData = true;
        }
        if (typeof values.CommunityCertificate === "object") {
          formData.append("CommunityCertificate", values.CommunityCertificate);
          hasFormData = true;
        }
        if (typeof values.AadharCopy === "object") {
          formData.append("AadharCopy", values.AadharCopy);
          hasFormData = true;
        }
        if (typeof values.PANCopy === "object") {
          formData.append("PANCopy", values.PANCopy);
          hasFormData = true;
        }
        if (typeof values.BarCouncilCertificate === "object") {
          formData.append(
            "BarCouncilCertificate",
            values.BarCouncilCertificate
          );
          hasFormData = true;
        }
        if (typeof values.BarCouncilExperienceCertificate === "object") {
          formData.append(
            "BarCouncilExperienceCertificate",
            values.BarCouncilExperienceCertificate
          );
          hasFormData = true;
        }
        if (typeof values.GovtServiceStartUrl === "object") {
          formData.append("GovtServiceStartUrl", values.GovtServiceStartUrl);
          hasFormData = true;
        }
        if (typeof values.GovtServiceEndUrl === "object") {
          formData.append("GovtServiceEndUrl", values.GovtServiceEndUrl);
          hasFormData = true;
        }
        if (typeof values.ExperienceCertificate === "object") {
          formData.append(
            "ExperienceCertificate",
            values.ExperienceCertificate
          );
          hasFormData = true;
        }
        this.toggleLoadingModal();
        if (hasFormData) {
          this.CurrentProfileValues = values;
          CallService(
            documentMaster.FileUpload,
            MethodType.POST,
            false,
            formData,
            "",
            this.DocumentUploaded,
            true
          );
        } else {
          this.ManageProfile(values);
        }
      }
    }
  };

  DocumentUploaded = (data) => {
    debugger;
    if (data.statusCode === StatusCodes.Success) {
      let currentProfileValues = this.CurrentProfileValues;
      currentProfileValues.Degrees.map((qualification, qIndex) => {
        qualification.Url = data.result["DegreeCopy" + qIndex];
        return qualification;
      });
      currentProfileValues.Photo = data.result["Photo"];
      currentProfileValues.CommunityCertificate =
        data.result["CommunityCertificate"];
      currentProfileValues.AadharCopy = data.result["AadharCopy"];
      currentProfileValues.PANCopy = data.result["PANCopy"];
      currentProfileValues.BarCouncilCertificate =
        data.result["BarCouncilCertificate"];
      currentProfileValues.BarCouncilExperienceCertificate =
        data.result["BarCouncilExperienceCertificate"];
      currentProfileValues.GovtServiceStartUrl =
        data.result["GovtServiceStartUrl"];
      currentProfileValues.GovtServiceEndUrl = data.result["GovtServiceEndUrl"];
      currentProfileValues.ExperienceCertificate =
        data.result["ExperienceCertificate"];
      this.ManageProfile(currentProfileValues);
    } else {
      toastr.error("", "Something went wrong");
      this.toggleLoadingModal();
    }
  };

  ManageProfile = (values) => {
    values = {
      ...values,
      CallCompletedAPI: this.state.CallCompletedAPI,
      CheckAadhar: this.state.CheckAadhar,
      CheckEligible: this.state.CheckEligible,
      TamilName: this.state.TamilName,
      IncomeTaxAssessee: values?.IncomeTaxAssessee || false,
      PANNumber: values.PANNumber || null,
      PANCopy: values.PANCopy || "",
    };
    console.log("ManageProfile", values);
    CallService(
      this.state.IsProfileCompleted
        ? profileComplete.Update + this.state.UserId
        : profileComplete.Create,
      this.state.IsProfileCompleted ? MethodType.PATCH : MethodType.PUT,
      false,
      values,
      "",
      this.ManageProfileResponse
    );
  };
  ManageProfileResponse = (data) => {
    this.toggleLoadingModal();
    if (data.statusCode === StatusCodes.Success) {
      if (data.result.IsProfileCompleted) {
        ClearStorageValues();
        toastr.success("", "Profile completed Successfully");
        this.props.history.push("/advocatelogin");
      } else if (
        !data.result.IsProfileCompleted &&
        this.state.ButtonClicked === "Next"
      ) {
        this.toggleTab(this.state.ActiveTabId + 1);
      }
    } else if (data.statusCode === "409") {
      if (data.exception && data.exception) {
        toastr.error("", data.exception.replace(/([A-Z])/g, " $1").trim());
        this.toggleTab(1);
      }
    } else {
      toastr.error("", "Something went wrong");
    }
  };

  GetCurrentTabValidation = () => {
    switch (this.state.ActiveTabId) {
      case 1:
        return profileTab1Validation;
      case 2:
        return profileTab2Validation;
      case 3:
        return profileTab3Validation;
      case 4:
        return profileTab4Validation;
      case 5:
        return profileTab5Validation;
      default:
        return {};
    }
  };
  inputRef = createRef();

  keyboardRef = createRef();

  handleKeyboardChange = (data) => {
    console.log("DATATATA", data);
    this.setState({ TamilName: data });
  };
  onKeyPress = (button) => {
    if (button === "{bksp}" && this.state.TamilName.length > 0) {
      this.setState({
        TamilName: this.state.TamilName?.slice(0, -1),
      });
    }
  };
  toggleLoadingModal = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  render() {
    const currentValidationSchema = this.GetCurrentTabValidation();
    console.log("2345678", this.state.Profile);
    console.log("QualificationType", this.state.QualificationType);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <h4>
              {" "}
              <Label>PROFILE</Label>
            </h4>
            {/* <Breadcrumb breadcrumbItem={this.props.t("Profile")} /> */}
            <Row>
              {/* <Col lg="12" className="text-right">
                <span className="text-info text-underline cursor-pointer" onClick={(e) => {
                  e.preventDefault();
                  this.toggleEligibilityModal();
                }} >{this.props.t('EligibilityCriteria')} </span>
              </Col> */}
              <Col lg="12">
                {!this.state.IsProfileCompleted ? (
                  <Alert color="warning" className="text-center mb-0">
                    {this.props.t("Please complete your Profile")}
                  </Alert>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="p-2">
                    <Formik
                      initialValues={this.state.Profile}
                      validationSchema={currentValidationSchema}
                      onSubmit={this.handleProfileUpdate}
                      validateOnBlur={true}
                      validateOnChange={true}
                      enableReinitialize={true}
                    >
                      {({
                        errors,
                        values,
                        touched,
                        setFieldValue,
                        validateForm,
                        setFieldError,
                      }) => (
                        <Form className="av-tooltip tooltip-label-bottom">
                          <div
                            id="basic-pills-wizard"
                            className="twitter-bs-wizard"
                          >
                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                              {this.state.tabs.map((tab, tabIndex) => {
                                return (
                                  <Fragment key={tab.id}>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          this.state.ActiveTabId === tab.id
                                            ? " active "
                                            : ""
                                        }
                                        onClick={() => {
                                          debugger;
                                          if (this.state.ActiveTabId > tab.id) {
                                            this.toggleTab(tab.id);
                                          } else {
                                            validateForm().then((errors) => {
                                              if (
                                                Object.keys(errors).length ===
                                                  0 &&
                                                errors.constructor === Object &&
                                                this.state.EligibilityStatus
                                              ) {
                                                this.toggleTab(tab.id);
                                              }
                                            });
                                          }
                                        }}
                                      >
                                        <span className="step-number mr-2">
                                          {tabIndex + 1}
                                        </span>
                                        {tab.Name}
                                      </NavLink>
                                    </NavItem>
                                  </Fragment>
                                );
                              })}
                            </ul>
                            <TabContent
                              activeTab={this.state.ActiveTabId}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane tabId={1}>
                                <Row>
                                  <Col lg="5">
                                    <FormGroup>
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        // labelClassName="requiredField"
                                        label={
                                          <Fragment>
                                            {" "}
                                            <Label className="requiredField">
                                              {" "}
                                              {this.props.t("Form Type")}{" "}
                                            </Label>{" "}
                                            <span
                                              className="text-info text-underline cursor-pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.toggleEligibilityModal();
                                              }}
                                            >
                                              {this.props.t(
                                                "EligibilityCriteria"
                                              )}{" "}
                                            </span>
                                          </Fragment>
                                        }
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="FormType"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values.FormType &&
                                          (typeof values.FormType === "string"
                                            ? this.state.formTypes.find(
                                                (r) =>
                                                  r.value === values.FormType
                                              )
                                            : this.state.formTypes.find(
                                                (r) =>
                                                  r.value ===
                                                  values.FormType._id
                                              ))
                                        }
                                        options={this.state.formTypes}
                                        ClearAction={() => {
                                          setFieldValue("FormType", undefined);
                                          this.CheckEligibilty(
                                            undefined,
                                            values.Gender,
                                            values.Community,
                                            values.EnrolmentDate,
                                            values.GovtServiceStartDate,
                                            values.GovtServiceEndDate
                                          );
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "FormType",
                                            entity ? entity.value : undefined
                                          );
                                          this.CheckEligibilty(
                                            entity.value,
                                            values.Gender,
                                            values.Community,
                                            values.EnrolmentDate,
                                            values.GovtServiceStartDate,
                                            values.GovtServiceEndDate
                                          );
                                        }}
                                        errors={errors.FormType}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4"></Col>
                                </Row>
                                {values.FormType === FORMTYPES.Form2 ? (
                                  <Fragment>
                                    <Row>
                                      <Col lg="4">
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t("JoiningDate")}
                                          </Label>
                                          <DatePicker
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                            showYearDropdown
                                            showMonthDropdown
                                            selected={
                                              values.GovtServiceStartDateObj
                                            }
                                            className="form-control"
                                            onChange={(date) => {
                                              // this.setState({
                                              //   Profile: {
                                              //     ...this.state.Profile,
                                              //     GovtServiceStartDateObj: date
                                              //   }
                                              // });
                                              setFieldValue(
                                                "GovtServiceStartDate",
                                                date.getTime()
                                              );
                                              setFieldValue(
                                                "GovtServiceStartDateObj",
                                                date
                                              );
                                              this.CheckEligibilty(
                                                values.FormType,
                                                values.Gender,
                                                values.Community,
                                                values.EnrolmentDate,
                                                date.getTime(),
                                                values.GovtServiceEndDate
                                              );
                                            }}
                                            placeholderText={this.props.t(
                                              "JoiningDate"
                                            )}
                                            name="GovtServiceStartDate"
                                            dateFormat={datePickerFormat}
                                            errors={errors.GovtServiceStartDate}
                                            maxDate={new Date()}
                                            popperPlacement="top-start"
                                          />
                                          {errors.GovtServiceStartDate && (
                                            <div className="invalid-feedback d-block">
                                              {errors.GovtServiceStartDate}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {this.props.t("RetirementDate")}
                                          </Label>
                                          <DatePicker
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                            showYearDropdown
                                            showMonthDropdown
                                            selected={
                                              values.GovtServiceEndDateObj
                                            }
                                            className="form-control"
                                            onChange={(date) => {
                                              setFieldValue(
                                                "GovtServiceEndDate",
                                                date.getTime()
                                              );
                                              setFieldValue(
                                                "GovtServiceEndDateObj",
                                                date
                                              );
                                              this.CheckEligibilty(
                                                values.FormType,
                                                values.Gender,
                                                values.Community,
                                                values.EnrolmentDate,
                                                values.GovtServiceStartDate,
                                                date.getTime()
                                              );
                                            }}
                                            placeholderText={this.props.t(
                                              "RetirementDate"
                                            )}
                                            name="GovtServiceEndDate"
                                            dateFormat={datePickerFormat}
                                            errors={errors.GovtServiceEndDate}
                                            maxDate={new Date()}
                                            popperPlacement="top-start"
                                          />
                                          {errors.GovtServiceEndDate && (
                                            <div className="invalid-feedback d-block">
                                              {errors.GovtServiceEndDate}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {this.props.t(
                                              "Post held at the time of retirement / relief"
                                            )}
                                          </Label>
                                          <Field
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                            type="text"
                                            className="form-control"
                                            name="GovtServicePost"
                                            onKeyPress={(event) => {
                                              if (
                                                INVALID_CHARS.includes(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onBlur={(event) => {
                                              event.target.value =
                                                event?.target?.value &&
                                                event?.target?.value?.replace(
                                                  INVALID_CHARS_REGEX,
                                                  ""
                                                );
                                              setFieldValue(
                                                "GovtServicePost",
                                                event?.target?.value
                                              );
                                            }}
                                          />
                                          {errors.GovtServicePost && (
                                            <div className="invalid-feedback d-block">
                                              {errors.GovtServicePost}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col lg="4" style={{ zIndex: 0 }}>
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t(
                                              //  "Government service Initial document"
                                              "Order of Initial Appointment"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            id={`GovtServiceStartUrl`}
                                            key={"GovtServiceStartUrl"}
                                            type="file"
                                            name={`GovtServiceStartUrl`}
                                            onChange={(event) => {
                                              if (
                                                allow_file_formats(
                                                  event?.target?.files?.[0]
                                                    ?.name,
                                                  ["pdf"],
                                                  setFieldError,
                                                  "GovtServiceStartUrl"
                                                )
                                              ) {
                                                setFieldValue(
                                                  `GovtServiceStartUrl`,
                                                  event?.target?.files?.[0]
                                                );
                                              }
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(5, "MB")}
                                            <br />
                                            {getAllowedExtensionText("pdf", [
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {typeof values.GovtServiceStartUrl ===
                                            "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    values.GovtServiceStartUrl
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.GovtServiceStartUrl && (
                                            <div className="invalid-feedback d-block">
                                              {errors.GovtServiceStartUrl}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4" style={{ zIndex: 0 }}>
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t(
                                              // "Government service End document"
                                              "Retirement / Relieving Order"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            id={`GovtServiceEndUrl`}
                                            key={"GovtServiceEndUrl"}
                                            type="file"
                                            name={`GovtServiceEndUrl`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                `GovtServiceEndUrl`,
                                                event?.target?.files?.[0]
                                              );
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(5, "MB")}
                                            <br />
                                            {getAllowedExtensionText("pdf", [
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {typeof values.GovtServiceEndUrl ===
                                            "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    values.GovtServiceEndUrl
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.GovtServiceEndUrl && (
                                            <div className="invalid-feedback d-block">
                                              {errors.GovtServiceEndUrl}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      {/* <Col lg="4">
                                        <FormGroup>
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t(
                                              "Experience Certificate"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            id={`ExperienceCertificate`}
                                            key={"Url"}
                                            type="file"
                                            name={`ExperienceCertificate`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                `ExperienceCertificate`,
                                                event.target.files[0]
                                              );
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(500,"KB")}
                                            <br />
                                            {getAllowedExtensionText("pdf",[
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {typeof values.ExperienceCertificate ===
                                            "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    values.ExperienceCertificate
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.ExperienceCertificate && (
                                            <div className="invalid-feedback d-block">
                                              {errors.ExperienceCertificate}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col> */}
                                    </Row>
                                    <Row>
                                      <Col lg="12" className="text-center">
                                        <div className="invalid-feedback d-block">
                                          {!this.state.EligibilityStatus
                                            ? this.state.EligibilityMessage
                                            : ""}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Fragment>
                                ) : (
                                  ""
                                )}
                              </TabPane>
                              <TabPane tabId={2}>
                                <div>
                                  <Row
                                    className={
                                      !this.state.EligibilityStatus
                                        ? "d-block"
                                        : "d-none"
                                    }
                                  >
                                    <Col lg="12" className="text-center">
                                      <div className="invalid-feedback d-block">
                                        {!this.state.EligibilityStatus
                                          ? this.state.EligibilityMessage
                                          : ""}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <DropDown
                                          isDisabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                          labelClassName="requiredField"
                                          label={this.props.t("Title")}
                                          components={{
                                            Input: CustomSelectInput,
                                          }}
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          name="Title"
                                          isSearchable
                                          isClearable={true}
                                          value={
                                            values.Title &&
                                            (typeof values.Title === "string"
                                              ? this.state.titles.find(
                                                  (r) =>
                                                    r.value === values.Title
                                                )
                                              : this.state.titles.find(
                                                  (r) =>
                                                    r.value === values.Title._id
                                                ))
                                          }
                                          options={this.state.titles}
                                          ClearAction={() => {
                                            setFieldValue("Title", undefined);
                                          }}
                                          Action={(entity) => {
                                            setFieldValue(
                                              "Title",
                                              entity ? entity.value : undefined
                                            );
                                            if (entity) {
                                              if (
                                                entity.value ===
                                                TITLE.THIRUMATHI
                                              ) {
                                                setFieldValue(
                                                  "IsMarried",
                                                  true
                                                );
                                              } else if (
                                                entity.value === TITLE.SELVI
                                              ) {
                                                setFieldValue(
                                                  "IsMarried",
                                                  false
                                                );
                                              }
                                            }
                                            setFieldValue("Gender", "");

                                            this.setState(
                                              {
                                                TitleBaseGender: entity.value,
                                              },
                                              () => {
                                                this.GetAllGender();
                                              }
                                            );
                                          }}
                                          errors={errors.Title}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label className="requiredField">
                                          {this.props.t("Name")}
                                        </Label>

                                        <Field
                                          disabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                          autoComplete="nope"
                                          type="text"
                                          className="form-control"
                                          name="Name"
                                          onKeyPress={(event) => {
                                            if (
                                              INVALID_CHARS.includes(event.key)
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onBlur={(event) => {
                                            event.target.value =
                                              event?.target?.value &&
                                              event?.target?.value?.replace(
                                                INVALID_CHARS_REGEX,
                                                ""
                                              );
                                            setFieldValue(
                                              "Name",
                                              event?.target?.value
                                            );
                                          }}
                                        />

                                        {errors.Name && (
                                          <div className="invalid-feedback d-block">
                                            {errors.Name}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label className="requiredField">
                                          {this.props.t("Tamil Name")}
                                        </Label>
                                        <div className={"keyboard-container"}>
                                          <Input
                                            type="text"
                                            id="virtualkeyID"
                                            onPaste={(e) =>
                                              this.handleKeyboardChange(
                                                e.clipboardData.getData("text")
                                              )
                                            }
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            onCut={(e) =>
                                              this.handleKeyboardChange(e)
                                            }
                                            // onKeyPress={(e) =>
                                            //   this.keyboardRef.current.keypress(
                                            //     e
                                            //   )
                                            // }
                                            // onKeyDown={(e) =>
                                            //   this.keyboardRef.current.keyup(e)
                                            // }
                                            // onCut={(e) =>
                                            //   this.keyboardRef.current.handleCut(
                                            //     e
                                            //   )
                                            // }
                                            // onFocus={(e) => [
                                            //   this.keyboardRef.current.onfocus(
                                            //     e
                                            //   ),
                                            //   this.keyboardRef.current.anotherKeyBoardHide(
                                            //     [
                                            //       "virtualkeyID1",
                                            //       "virtualkeyID2",
                                            //       "virtualkeyID3",
                                            //     ]
                                            //   ),
                                            // ]}
                                            value={this.state.TamilName}
                                            ref={this.inputRef}
                                          />
                                        </div>
                                        {/* <TamilKeyboard
                                          keysound={true}
                                          visible={this.state.visible}
                                          // To 'set' keyboard input values to above input field
                                          keydatavalue={
                                            this.handleKeyboardChange
                                          }
                                          ref={this.keyboardRef}
                                          // 'inputid' same as above input field 'id'
                                          inputid={"virtualkeyID"}
                                          // 'inputref' same as above input field 'ref'
                                          inputref={this.inputRef}
                                        /> */}
                                        {errors.TamilName && (
                                          <div className="invalid-feedback d-block">
                                            {errors.TamilName}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <DropDown
                                          isDisabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                          labelClassName="requiredField"
                                          label={this.props.t("Gender")}
                                          components={{
                                            Input: CustomSelectInput,
                                          }}
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          name="Gender"
                                          isSearchable
                                          isClearable={true}
                                          value={
                                            values?.Gender &&
                                            (typeof values.Gender === "string"
                                              ? this.state.GenderList.find(
                                                  (r) =>
                                                    r.value === values.Gender
                                                )
                                              : this.state.GenderList.find(
                                                  (r) =>
                                                    r.value ===
                                                    values.Gender._id
                                                ))
                                          }
                                          options={this.state.GenderList}
                                          ClearAction={() => {
                                            setFieldValue("Gender", undefined);
                                          }}
                                          Action={(entity) => {
                                            setFieldValue(
                                              "Gender",
                                              entity ? entity.value : undefined
                                            );
                                            this.CheckEligibilty(
                                              values.FormType,
                                              entity.value,
                                              values.Community,
                                              values.EnrolmentDate,
                                              values.GovtServiceStartDate,
                                              values.GovtServiceEndDate
                                            );
                                          }}
                                          errors={errors.Gender}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label>
                                          {" "}
                                          {this.props.t("Married")}
                                        </Label>
                                        <div className="custom-control custom-switch">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checkbox"
                                            id="married"
                                            checked={values.IsMarried}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "IsMarried",
                                                e.target.checked
                                              );
                                            }}
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                                true ||
                                              values.Title === TITLE.SELVI ||
                                              values.Title === TITLE.THIRUMATHI
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="married"
                                          >
                                            {values.IsMarried ? "Yes" : "No"}
                                          </label>
                                        </div>
                                        {errors.IsMarried && (
                                          <div className="invalid-feedback d-block">
                                            {errors.IsMarried}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label className="requiredField">
                                          {" "}
                                          {this.props.t("Mobile Number")}
                                        </Label>
                                        <Field
                                          type="MobileNumber"
                                          autoFocus
                                          className="form-control"
                                          name="MobileNumber"
                                          disabled
                                          onKeyPress={(event) => {
                                            if (
                                              INVALID_CHARS.includes(event.key)
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onBlur={(event) => {
                                            event.target.value =
                                              event?.target?.value &&
                                              event?.target?.value?.replace(
                                                INVALID_CHARS_REGEX,
                                                ""
                                              );
                                            setFieldValue(
                                              "MobileNumber",
                                              event?.target?.value
                                            );
                                          }}
                                        />
                                        {errors.MobileNumber && (
                                          <div className="invalid-feedback d-block">
                                            {errors.MobileNumber}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        {values.Title === TITLE.THIRUMATHI ? (
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t("Husband’s Name")}
                                          </Label>
                                        ) : (
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t("Father’s Name")}
                                          </Label>
                                        )}

                                        <Field
                                          disabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                          autoComplete="nope"
                                          type="text"
                                          autoFocus
                                          className="form-control"
                                          name="FatherOrHusbandName"
                                          onKeyPress={(event) => {
                                            if (
                                              INVALID_CHARS.includes(event.key)
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onBlur={(event) => {
                                            event.target.value =
                                              event?.target?.value &&
                                              event?.target?.value?.replace(
                                                INVALID_CHARS_REGEX,
                                                ""
                                              );
                                            setFieldValue(
                                              "FatherOrHusbandName",
                                              event?.target?.value
                                            );
                                          }}
                                        />
                                        {errors.FatherOrHusbandName && (
                                          <div className="invalid-feedback d-block">
                                            {errors.FatherOrHusbandName}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {" "}
                                        {this.props.t("Date Of Birth")}
                                      </Label>
                                      <DatePicker
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="nope"
                                        selected={
                                          values.DobObj ? values.DobObj : ""
                                        }
                                        className="form-control"
                                        showYearDropdown
                                        showMonthDropdown
                                        onChange={(date) => {
                                          // this.setState({
                                          //   Profile: {
                                          //     ...this.state.Profile, DobObj: date
                                          //   }
                                          // });
                                          setFieldValue("Dob", date.getTime());
                                          setFieldValue("DobObj", date);
                                        }}
                                        placeholderText={this.props.t(
                                          "Date Of Birth"
                                        )}
                                        name="Dob"
                                        dateFormat={datePickerFormat}
                                        maxDate={subYears(new Date(), 18)}
                                        popperPlacement="top-start"
                                      />

                                      {errors.Dob && (
                                        <div className="invalid-feedback d-block">
                                          {errors.Dob}
                                        </div>
                                      )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {" "}
                                        {this.props.t("Photo")}
                                      </Label>
                                      <CustomInput
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        id="Photo"
                                        key={"Photo"}
                                        type="file"
                                        name={"Photo"}
                                        onChange={(event) => {
                                          if (
                                            allow_file_formats(
                                              event.target.files[0].name,
                                              ["png", "jpg", "jpeg"],
                                              setFieldError,
                                              "Photo"
                                            )
                                          ) {
                                            setFieldValue(
                                              "Photo",
                                              event.target.files[0]
                                            );
                                          }
                                        }}
                                      />
                                      <p className="text-semi-muted">
                                        {getFileSizeText(5, "MB")}
                                        <br />
                                        {getAllowedExtensionText("png", [
                                          "png",
                                          "jpg",
                                          "jpeg",
                                        ])}
                                        {this.state.IsProfileCompleted ===
                                        true ? (
                                          ""
                                        ) : (
                                          <p
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            Please upload your recent passport
                                            size photograph
                                          </p>
                                        )}
                                        {this.state.Profile.Photo &&
                                        typeof this.state.Profile.Photo ===
                                          "string" ? (
                                          <span
                                            style={{
                                              color: "blue",
                                              cursor: "Pointer",
                                              float: "right",
                                            }}
                                          >
                                            <FileDisplay
                                              Value={this.state.Profile.Photo}
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </p>

                                      {errors.Photo && (
                                        <div className="invalid-feedback d-block">
                                          {errors.Photo}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t(
                                          "Community (If SC/ST/MBC/BC/DC please upload self attested community certificate)"
                                        )}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="Community"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values.Community &&
                                          (typeof values.Community === "string"
                                            ? this.state.Communities.find(
                                                (r) =>
                                                  r.value === values.Community
                                              )
                                            : this.state.Communities.find(
                                                (r) =>
                                                  r.value ===
                                                  values.Community._id
                                              ))
                                        }
                                        options={this.state.Communities}
                                        ClearAction={() => {
                                          setFieldValue("Community", undefined);
                                          this.CheckEligibilty(
                                            values.FormType,
                                            values.Gender,
                                            undefined,
                                            values.EnrolmentDate,
                                            values.GovtServiceStartDate,
                                            values.GovtServiceEndDate
                                          );
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "Community",
                                            entity ? entity.value : undefined
                                          );
                                          this.CheckEligibilty(
                                            values.FormType,
                                            values.Gender,
                                            entity.value,
                                            values.EnrolmentDate,
                                            values.GovtServiceStartDate,
                                            values.GovtServiceEndDate
                                          );
                                        }}
                                        errors={errors.Community}
                                      />
                                    </Col>
                                    <Col lg="6">
                                      <Label
                                        className={
                                          values.Community ===
                                          "5f7c0a9cd113d619c793d8c6"
                                            ? ""
                                            : "requiredField"
                                        }
                                      >
                                        {" "}
                                        {this.props.t(
                                          "Copy of Community Certificate (Self Attested)"
                                        )}
                                      </Label>
                                      <CustomInput
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        id="CommunityCertificate"
                                        key={"CommunityCertificate"}
                                        type="file"
                                        name={"CommunityCertificate"}
                                        onResize={(event) => {
                                          console.log("", event);
                                        }}
                                        onChange={(event) => {
                                          if (
                                            allow_file_formats(
                                              event.target.files[0].name,
                                              ["pdf"],
                                              setFieldError,
                                              "CommunityCertificate"
                                            )
                                          ) {
                                            setFieldValue(
                                              "CommunityCertificate",
                                              event.target.files[0]
                                            );
                                          }
                                        }}
                                      />
                                      <p className="text-semi-muted">
                                        {getFileSizeText(5, "MB")}
                                        <br />
                                        {getAllowedExtensionText("pdf")}

                                        {this.state.Profile
                                          .CommunityCertificate &&
                                        typeof this.state.Profile
                                          .CommunityCertificate === "string" ? (
                                          <span
                                            style={{
                                              color: "blue",
                                              cursor: "Pointer",
                                              float: "right",
                                            }}
                                          >
                                            <FileDisplay
                                              Value={
                                                this.state.Profile
                                                  .CommunityCertificate
                                              }
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </p>

                                      {errors.CommunityCertificate && (
                                        <div className="invalid-feedback d-block">
                                          {errors.CommunityCertificate}
                                        </div>
                                      )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {" "}
                                        {this.props.t("Email ID")}
                                      </Label>
                                      <Field
                                        disabled
                                        autoComplete="nope"
                                        type="email"
                                        autoFocus
                                        className="form-control"
                                        name="EmailID"
                                      />
                                      {errors.EmailID && (
                                        <div className="invalid-feedback d-block">
                                          {errors.EmailID}
                                        </div>
                                      )}{" "}
                                    </Col>

                                    {values.FormType === FORMTYPES.Form1 ? (
                                      <Fragment>
                                        <Col lg="6">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-start",
                                              gap: "5px",
                                            }}
                                          >
                                            <Label className="requiredField">
                                              {" "}
                                              {this.props.t("Aadhaar Number")}
                                            </Label>
                                            {this.state.AadhaarEdit &&
                                            values?.Aadhar?.length !== 0 ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "blue",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    console.log(
                                                      "Aadhar_edit",
                                                      this.state?.AadhaarEdit &&
                                                        values?.Aadhar?.length
                                                    );
                                                    this.state.AadhaarEdit = false;
                                                    setFieldValue("Aadhar", "");
                                                  }}
                                                >
                                                  Edit
                                                </p>
                                                <p>
                                                  (To change Aadhar number click
                                                  on edit)
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Field
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : this.state.AadhaarEdit ===
                                                  true
                                                ? true
                                                : false
                                            }
                                            autoComplete="nope"
                                            type="text"
                                            className="IncrementHide form-control"
                                            name="Aadhar"
                                            maxLength="12"
                                            onKeyPress={(event) => {
                                              if (
                                                INVALID_CHARS.includes(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onBlur={(event) => {
                                              event.target.value =
                                                event?.target?.value &&
                                                event?.target?.value?.replace(
                                                  INVALID_CHARS_REGEX,
                                                  ""
                                                );
                                              setFieldValue(
                                                "Aadhar",
                                                event?.target?.value
                                              );
                                            }}
                                          />

                                          {errors.Aadhar && (
                                            <div className="invalid-feedback d-block">
                                              {errors.Aadhar}
                                            </div>
                                          )}
                                        </Col>
                                        <Col lg="6">
                                          <Label className="requiredField">
                                            {" "}
                                            {this.props.t(
                                              "Copy of Aadhar (Self Attested)"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            id="AadharCopy"
                                            key={"AadharCopy"}
                                            type="file"
                                            name={"AadharCopy"}
                                            onChange={(event) => {
                                              if (
                                                allow_file_formats(
                                                  event.target.files[0].name,
                                                  ["pdf"],
                                                  setFieldError,
                                                  "CommunityCertificate"
                                                )
                                              ) {
                                                setFieldValue(
                                                  "AadharCopy",
                                                  event.target.files[0]
                                                );
                                              }
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(5, "MB")}
                                            <br />
                                            {getAllowedExtensionText("pdf", [
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {this.state.Profile.AadharCopy &&
                                            typeof this.state.Profile
                                              .AadharCopy === "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    this.state.Profile
                                                      .AadharCopy
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.AadharCopy && (
                                            <div className="invalid-feedback d-block">
                                              {errors.AadharCopy}
                                            </div>
                                          )}
                                        </Col>
                                      </Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                </div>
                              </TabPane>
                              <TabPane tabId={3}>
                                <div>
                                  <Row
                                    className={
                                      !this.state.EligibilityStatus
                                        ? "d-block"
                                        : "d-none"
                                    }
                                  >
                                    <Col lg="12" className="text-center">
                                      <div className="invalid-feedback d-block">
                                        {!this.state.EligibilityStatus
                                          ? this.state.EligibilityMessage
                                          : ""}
                                      </div>
                                    </Col>
                                  </Row>
                                  {values.FormType === FORMTYPES.Form1 ? (
                                    <Fragment>
                                      <Row>
                                        <Col lg="6">
                                          <Label
                                            className={
                                              values.FormType ===
                                              FORMTYPES.Form2
                                                ? ""
                                                : "requiredField"
                                            }
                                          >
                                            {" "}
                                            {this.props.t(
                                              "Bar Council Enrolment Number"
                                            )}
                                          </Label>
                                          <Field
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                            type="text"
                                            className="form-control"
                                            name="BarCouncilNumber"
                                          />
                                          {errors.BarCouncilNumber && (
                                            <div className="invalid-feedback d-block">
                                              {errors.BarCouncilNumber}
                                            </div>
                                          )}
                                        </Col>
                                        <Col lg="6">
                                          <Label
                                            className={
                                              values.FormType ===
                                              FORMTYPES.Form2
                                                ? ""
                                                : "requiredField"
                                            }
                                          >
                                            {" "}
                                            {this.props.t(
                                              "Bar Council Certificate (Self Attested)"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            id="BarCouncilCertificate"
                                            key={"BarCouncilCertificate"}
                                            type="file"
                                            name={"BarCouncilCertificate"}
                                            onChange={(event) => {
                                              if (
                                                allow_file_formats(
                                                  event.target.files[0].name,
                                                  ["pdf"],
                                                  setFieldError,
                                                  "BarCouncilCertificate"
                                                )
                                              ) {
                                                setFieldValue(
                                                  "BarCouncilCertificate",
                                                  event.target.files[0]
                                                );
                                              }
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(5, "MB")}
                                            <br />
                                            {getAllowedExtensionText("pdf", [
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {this.state.Profile
                                              .BarCouncilCertificate &&
                                            typeof this.state.Profile
                                              .BarCouncilCertificate ===
                                              "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    this.state.Profile
                                                      .BarCouncilCertificate
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.BarCouncilCertificate && (
                                            <div className="invalid-feedback d-block">
                                              {errors.BarCouncilCertificate}
                                            </div>
                                          )}{" "}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="6">
                                          <Label
                                            className={
                                              values.FormType ===
                                              FORMTYPES.Form2
                                                ? ""
                                                : "requiredField"
                                            }
                                          >
                                            {" "}
                                            {this.props.t("Enrolment Date")}
                                          </Label>
                                          <DatePicker
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                            selected={values.EnrolmentDateObj}
                                            className="form-control"
                                            showYearDropdown
                                            showMonthDropdown
                                            onChange={(date) => {
                                              setFieldValue(
                                                "EnrolmentDate",
                                                date.getTime()
                                              );
                                              setFieldValue(
                                                "EnrolmentDateObj",
                                                date
                                              );
                                              this.CheckEligibilty(
                                                values.FormType,
                                                values.Gender,
                                                values.Community,
                                                date.getTime(),
                                                values.GovtServiceStartDate,
                                                values.GovtServiceEndDate
                                              );
                                            }}
                                            placeholderText={this.props.t(
                                              "Enrolment Date"
                                            )}
                                            name="startDate"
                                            dateFormat={datePickerFormat}
                                            errors={errors.EnrolmentDate}
                                            maxDate={new Date()}
                                          />
                                          {errors.EnrolmentDate && (
                                            <div className="invalid-feedback d-block">
                                              {errors.EnrolmentDate}
                                            </div>
                                          )}
                                        </Col>
                                        <Col lg="6">
                                          {values.FormType ===
                                          FORMTYPES.Form1 ? (
                                            <Fragment>
                                              <Label
                                                className={
                                                  values.FormType ===
                                                  FORMTYPES.Form2
                                                    ? ""
                                                    : "requiredField"
                                                }
                                              >
                                                {" "}
                                                {this.props.t(
                                                  "Experience Certificate"
                                                )}
                                              </Label>
                                              <CustomInput
                                                disabled={
                                                  this.state
                                                    .IsProfileCompleted === true
                                                    ? true
                                                    : false
                                                }
                                                id="BarCouncilExperienceCertificate"
                                                key={
                                                  "BarCouncilExperienceCertificate"
                                                }
                                                type="file"
                                                name={
                                                  "BarCouncilExperienceCertificate"
                                                }
                                                onChange={(event) => {
                                                  if (
                                                    allow_file_formats(
                                                      event.target.files[0]
                                                        .name,
                                                      ["pdf"],
                                                      setFieldError,
                                                      "BarCouncilExperienceCertificate"
                                                    )
                                                  ) {
                                                    setFieldValue(
                                                      "BarCouncilExperienceCertificate",
                                                      event.target.files[0]
                                                    );
                                                  }
                                                }}
                                              />
                                              <p className="text-semi-muted">
                                                {getFileSizeText(5, "MB")}
                                                <br />
                                                {getAllowedExtensionText(
                                                  "pdf",
                                                  [
                                                    "png",
                                                    "doc",
                                                    "pdf",
                                                    "jpg",
                                                    "jpeg",
                                                  ]
                                                )}
                                                {this.state.Profile
                                                  .BarCouncilExperienceCertificate &&
                                                typeof this.state.Profile
                                                  .BarCouncilExperienceCertificate ===
                                                  "string" ? (
                                                  <span
                                                    style={{
                                                      color: "blue",
                                                      cursor: "Pointer",
                                                      float: "right",
                                                    }}
                                                  >
                                                    <FileDisplay
                                                      Value={
                                                        this.state.Profile
                                                          .BarCouncilExperienceCertificate
                                                      }
                                                    />
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </p>
                                              {errors.BarCouncilExperienceCertificate && (
                                                <div className="invalid-feedback d-block">
                                                  {
                                                    errors.BarCouncilExperienceCertificate
                                                  }
                                                </div>
                                              )}{" "}
                                            </Fragment>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                      </Row>
                                    </Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {values.FormType === FORMTYPES.Form1 ? (
                                    <Row>
                                      <Col lg="12">
                                        <DropDown
                                          isDisabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                          isMulti="true"
                                          labelClassName="requiredField"
                                          label={this.props.t("Practicing In")}
                                          components={{
                                            Input: CustomSelectInput,
                                          }}
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          name="PracticingIn"
                                          isSearchable
                                          isClearable={true}
                                          value={
                                            values &&
                                            values.PracticingIn &&
                                            this.state.PracticingInList.filter(
                                              (a) => {
                                                console.log(
                                                  "PracticingInList",
                                                  a
                                                );
                                                return (
                                                  console.log(
                                                    "PracticingInList44",
                                                    values.PracticingIn
                                                  ),
                                                  values.PracticingIn.some(
                                                    (r) => {
                                                      return (
                                                        console.log(
                                                          "PracticingInList44",
                                                          r
                                                        ),
                                                        a.value ===
                                                          (typeof r === "object"
                                                            ? r.value
                                                              ? r.name
                                                              : r.name
                                                            : r)
                                                      );
                                                    }
                                                  )
                                                );
                                              }
                                            ).map(function (o) {
                                              return o;
                                            })
                                          }
                                          ClearAction={() => {
                                            setFieldValue(
                                              "PracticingIn",
                                              undefined
                                            );
                                          }}
                                          Action={(entity) => {
                                            setFieldValue(
                                              "PracticingIn",
                                              entity
                                                ? entity.map((v) => {
                                                    return v.value;
                                                  })
                                                : ""
                                            );
                                          }}
                                          options={this.state.PracticingInList}
                                          errors={errors.PracticingIn}
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  <Row>
                                    <Col lg="6">
                                      <Label>
                                        {this.props.t("Landline Number")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="IncrementHide form-control"
                                        name="LandlineNumber"
                                      />
                                      {errors.LandlineNumber && (
                                        <div className="invalid-feedback d-block">
                                          {errors.LandlineNumber}
                                        </div>
                                      )}
                                    </Col>
                                    <Col lg="6">
                                      <Label>{this.props.t("Fax")}</Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="IncrementHide form-control"
                                        name="Fax"
                                      />
                                      {errors.Fax && (
                                        <div className="invalid-feedback d-block">
                                          {errors.Fax}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <br />
                                  {values.FormType === FORMTYPES.Form1 ? (
                                    <Row>
                                      <Col lg="4">
                                        <Label className="requiredField">
                                          {this.props.t("Income Tax Assessee")}
                                        </Label>{" "}
                                        <div className="custom-control custom-switch">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checkbox"
                                            id="IncomeTaxAssessee"
                                            checked={values.IncomeTaxAssessee}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "IncomeTaxAssessee",
                                                e.target.checked
                                              );
                                            }}
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="IncomeTaxAssessee"
                                          >
                                            {values.IncomeTaxAssessee
                                              ? "Yes"
                                              : "No"}
                                          </label>
                                        </div>
                                        {errors.IncomeTaxAssessee && (
                                          <div className="invalid-feedback d-block">
                                            {errors.IncomeTaxAssessee}
                                          </div>
                                        )}
                                      </Col>
                                      <Fragment>
                                        <Col lg="4">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              gap: "5px",
                                            }}
                                          >
                                            <Label className={"requiredField"}>
                                              {this.props.t("PAN Number")}
                                            </Label>
                                            {console.log(
                                              "PANNumber",
                                              values,
                                              this.state
                                            )}
                                            {this.state.PANEdit &&
                                            values?.PANNumber?.length !== 0 &&
                                            values?.IncomeTaxAssessee ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "blue",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    this.state.PANEdit = false;
                                                    setFieldValue(
                                                      "PANNumber",
                                                      ""
                                                    );
                                                  }}
                                                >
                                                  Edit
                                                </p>
                                                <p
                                                  style={{
                                                    fontSize: "10px",
                                                    marginTop: "2px",
                                                  }}
                                                >
                                                  (To change PAN number click on
                                                  edit)
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <Field
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : this.state.PANEdit === true
                                                ? true
                                                : false ||
                                                  !values.IncomeTaxAssessee
                                                ? true
                                                : false
                                            }
                                            placeholder={this.props.t(
                                              "PAN Number"
                                            )}
                                            autoComplete="off"
                                            type="text"
                                            className="form-control"
                                            name="PANNumber"
                                            onChange={(e) => {
                                              setFieldValue(
                                                "PANNumber",
                                                e?.target?.value
                                                  ? e?.target?.value?.toUpperCase()
                                                  : ""
                                              );
                                            }}
                                            onKeyPress={(event) => {
                                              if (
                                                INVALID_CHARS.includes(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onBlur={(event) => {
                                              event.target.value =
                                                event?.target?.value &&
                                                event?.target?.value?.replace(
                                                  INVALID_CHARS_REGEX,
                                                  ""
                                                );
                                              setFieldValue(
                                                "PANNumber",
                                                event?.target?.value
                                              );
                                            }}
                                          />
                                          {errors.PANNumber ? (
                                            <div className="invalid-feedback d-block">
                                              {errors.PANNumber}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </Col>
                                        <Col lg="4">
                                          <Label className={"requiredField"}>
                                            {" "}
                                            {this.props.t(
                                              "PAN Card (Self Attested)"
                                            )}
                                          </Label>
                                          <CustomInput
                                            disabled={
                                              this.state.IsProfileCompleted ===
                                              true
                                                ? true
                                                : false ||
                                                  !values.IncomeTaxAssessee
                                                ? true
                                                : false
                                            }
                                            id="PANCopy"
                                            key={"PANCopy"}
                                            type="file"
                                            name={"PANCopy"}
                                            onChange={(event) => {
                                              if (
                                                allow_file_formats(
                                                  event.target.files[0].name,
                                                  ["pdf"],
                                                  setFieldError,
                                                  "PANCopy"
                                                )
                                              ) {
                                                setFieldValue(
                                                  "PANCopy",
                                                  event.target.files[0]
                                                );
                                              }
                                            }}
                                          />
                                          <p className="text-semi-muted">
                                            {getFileSizeText(5, "MB")}
                                            <br />
                                            {getAllowedExtensionText("pdf", [
                                              "png",
                                              "doc",
                                              "pdf",
                                              "jpg",
                                              "jpeg",
                                            ])}
                                            {this.state.Profile.PANCopy &&
                                            typeof this.state.Profile
                                              .PANCopy === "string" ? (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "Pointer",
                                                  float: "right",
                                                }}
                                              >
                                                <FileDisplay
                                                  Value={
                                                    this.state.Profile.PANCopy
                                                  }
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          {errors.PANCopy && (
                                            <div className="invalid-feedback d-block">
                                              {errors.PANCopy}
                                            </div>
                                          )}{" "}
                                        </Col>
                                      </Fragment>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </TabPane>
                              <TabPane tabId={4}>
                                <div>
                                  <Row>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t(
                                          "Qualification Type"
                                        )}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="QualificationType"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values.QualificationType &&
                                          (typeof values.QualificationType ===
                                          "string"
                                            ? QualificationTypes.find(
                                                (r) =>
                                                  r.value ===
                                                  values.QualificationType
                                              )
                                            : QualificationTypes.find(
                                                (r) =>
                                                  r.value ===
                                                  values.QualificationType
                                              ))
                                        }
                                        options={QualificationTypes}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "QualificationType",
                                            ""
                                          );
                                        }}
                                        Action={(entity, value) => {
                                          debugger;
                                          console.log(
                                            "entity1",
                                            errors,
                                            entity,
                                            value
                                          );
                                          setFieldValue(
                                            "QualificationType",
                                            entity?.value
                                          );
                                          setFieldError(
                                            "QualificationType",
                                            null
                                          );
                                          this.setState({
                                            QualificationType: entity?.value,
                                          });
                                          // setFieldValue(
                                          //   "IsIntegratedDegree",
                                          //   entity && entity.value === QUALIFICATIONTYPES.Integrated ? true : false
                                          // );
                                          let existingDegrees = values.Degrees;
                                          if (
                                            entity.value ===
                                            QUALIFICATIONTYPES.FiveYearDegree
                                          ) {
                                            if (existingDegrees.length > 1) {
                                              existingDegrees.splice(1, 1);
                                            }
                                          } else if (
                                            entity.value ===
                                            QUALIFICATIONTYPES.ThreeYearDegree
                                          ) {
                                            if (existingDegrees.length === 1) {
                                              existingDegrees.push({
                                                Institution: "",
                                                Name: "",
                                                Url: "",
                                                YearOfPassing: 0,
                                              });
                                            }
                                          }
                                          setFieldValue(
                                            "Degrees",
                                            existingDegrees
                                          );
                                        }}
                                        // errors={errors.QualificationType}
                                      />
                                      {!this.state.QualificationType && (
                                        // typeof errors.Degrees !== "object" && (
                                        <div className="invalid-feedback d-block">
                                          {errors.QualificationType}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  {values.QualificationType
                                    ? values.Degrees.map(
                                        (qualification, qIndex) => {
                                          console.log("qualification", values);
                                          return (
                                            <Fragment
                                              key={"qualification" + qIndex}
                                            >
                                              <Row>
                                                <Col lg="3">
                                                  <Label className="requiredField">
                                                    {this.props.t(
                                                      "Institution"
                                                    )}
                                                  </Label>
                                                  <Field
                                                    disabled={
                                                      this.state
                                                        .IsProfileCompleted ===
                                                      true
                                                        ? true
                                                        : false
                                                    }
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    name={`Degrees[${qIndex}].Institution`}
                                                    onKeyPress={(event) => {
                                                      if (
                                                        INVALID_CHARS.includes(
                                                          event.key
                                                        )
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    onBlur={(event) => {
                                                      event.target.value =
                                                        event?.target?.value &&
                                                        event?.target?.value?.replace(
                                                          INVALID_CHARS_REGEX,
                                                          ""
                                                        );
                                                      setFieldValue(
                                                        `Degrees[${qIndex}].Institution`,
                                                        event?.target?.value
                                                      );
                                                    }}
                                                  />
                                                  {errors.Degrees &&
                                                    errors.Degrees[qIndex] &&
                                                    errors.Degrees[qIndex]
                                                      .Institution && (
                                                      <div className="invalid-feedback d-block">
                                                        {
                                                          errors.Degrees[qIndex]
                                                            .Institution
                                                        }
                                                      </div>
                                                    )}
                                                </Col>
                                                <Col lg="2">
                                                  <Label className="requiredField">
                                                    {this.props.t("Degree")}
                                                  </Label>
                                                  <Field
                                                    disabled={
                                                      this.state
                                                        .IsProfileCompleted ===
                                                      true
                                                        ? true
                                                        : false
                                                    }
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    name={`Degrees[${qIndex}].Name`}
                                                    onKeyPress={(event) => {
                                                      if (
                                                        INVALID_CHARS.includes(
                                                          event.key
                                                        )
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    onBlur={(event) => {
                                                      event.target.value =
                                                        event?.target?.value &&
                                                        event?.target?.value?.replace(
                                                          INVALID_CHARS_REGEX,
                                                          ""
                                                        );
                                                      setFieldValue(
                                                        `Degrees[${qIndex}].Name`,
                                                        event?.target?.value
                                                      );
                                                    }}
                                                  />
                                                  {errors.Degrees &&
                                                    errors.Degrees[qIndex] &&
                                                    errors.Degrees[qIndex]
                                                      .Name && (
                                                      <div className="invalid-feedback d-block">
                                                        {
                                                          errors.Degrees[qIndex]
                                                            .Name
                                                        }
                                                      </div>
                                                    )}
                                                </Col>
                                                <Col lg="3">
                                                  <Label className="requiredField">
                                                    {values.QualificationType ===
                                                      QUALIFICATIONTYPES.ThreeYearDegree &&
                                                    qIndex === 0
                                                      ? this.props.t(
                                                          "Copy Of UG Degree Certificate (Self Attested)"
                                                        )
                                                      : (values.QualificationType ===
                                                          QUALIFICATIONTYPES.ThreeYearDegree &&
                                                          qIndex === 1) ||
                                                        (values.QualificationType ===
                                                          QUALIFICATIONTYPES.FiveYearDegree &&
                                                          qIndex === 0)
                                                      ? "Copy of Law Degree Certificate (Self Attested)"
                                                      : this.props.t(
                                                          "Copy Of Degree Certificate (Self Attested)"
                                                        )}
                                                  </Label>
                                                  <CustomInput
                                                    disabled={
                                                      this.state
                                                        .IsProfileCompleted ===
                                                      true
                                                        ? true
                                                        : false
                                                    }
                                                    id={`Degrees[${qIndex}].Url`}
                                                    key={"Url"}
                                                    type="file"
                                                    name={`Degrees[${qIndex}].Url`}
                                                    onChange={(event) => {
                                                      if (
                                                        allow_file_formats(
                                                          event.target.files[0]
                                                            .name,
                                                          ["pdf"],
                                                          setFieldError,
                                                          `Degrees[${qIndex}].Url`
                                                        )
                                                      ) {
                                                        setFieldValue(
                                                          `Degrees[${qIndex}].Url`,
                                                          event.target.files[0]
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <p className="text-semi-muted">
                                                    {getFileSizeText(5, "MB")}
                                                    <br />
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {this.state.Profile &&
                                                    this.state.Profile
                                                      .Degrees &&
                                                    this.state.Profile.Degrees
                                                      .length > 0 &&
                                                    this.state.Profile.Degrees[
                                                      qIndex
                                                    ] &&
                                                    this.state.Profile.Degrees[
                                                      qIndex
                                                    ].Url &&
                                                    typeof this.state.Profile
                                                      .Degrees[qIndex].Url ===
                                                      "string" ? (
                                                      <span
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          float: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state.Profile
                                                              .Degrees[qIndex]
                                                              .Url
                                                          }
                                                        />
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>

                                                  {errors.Degrees &&
                                                    errors.Degrees[qIndex] &&
                                                    errors.Degrees[qIndex]
                                                      .Url && (
                                                      <div className="invalid-feedback d-block">
                                                        {
                                                          errors.Degrees[qIndex]
                                                            .Url
                                                        }
                                                      </div>
                                                    )}
                                                </Col>
                                                <Col lg="2">
                                                  <Label className="requiredField">
                                                    {this.props.t(
                                                      "Year Of Passing"
                                                    )}
                                                  </Label>
                                                  <Field
                                                    disabled={
                                                      this.state
                                                        .IsProfileCompleted ===
                                                      true
                                                        ? true
                                                        : false
                                                    }
                                                    autoComplete="off"
                                                    type="number"
                                                    className="IncrementHide form-control"
                                                    name={`Degrees[${qIndex}].YearOfPassing`}
                                                    min="1900"
                                                    onKeyPress={(event) => {
                                                      if (
                                                        INVALID_CHARS.includes(
                                                          event.key
                                                        )
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    onBlur={(event) => {
                                                      event.target.value =
                                                        event?.target?.value &&
                                                        event?.target?.value?.replace(
                                                          INVALID_CHARS_REGEX,
                                                          ""
                                                        );
                                                      setFieldValue(
                                                        `Degrees[${qIndex}].YearOfPassing`,
                                                        event?.target?.value
                                                      );
                                                    }}
                                                  />
                                                  {errors.Degrees &&
                                                    errors.Degrees[qIndex] &&
                                                    errors.Degrees[qIndex]
                                                      .YearOfPassing && (
                                                      <div className="invalid-feedback d-block">
                                                        {
                                                          errors.Degrees[qIndex]
                                                            .YearOfPassing
                                                        }
                                                      </div>
                                                    )}
                                                </Col>
                                                <Col lg="2">
                                                  <Label></Label>
                                                  <br />
                                                  <br />
                                                  <i
                                                    disabled={
                                                      this.state
                                                        .IsProfileCompleted ===
                                                      true
                                                        ? true
                                                        : false
                                                    }
                                                    className="fa fa-plus-circle mr-1"
                                                    title="Add Qualification Detail"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      let existingDegrees =
                                                        values.Degrees;
                                                      existingDegrees.push({
                                                        Institution: "",
                                                        Name: "",
                                                        Url: "",
                                                        YearOfPassing: 0,
                                                      });
                                                      setFieldValue(
                                                        "Degrees",
                                                        existingDegrees
                                                      );
                                                    }}
                                                  ></i>
                                                  {qIndex !== 0 ? (
                                                    <i
                                                      disabled={
                                                        this.state
                                                          .IsProfileCompleted ===
                                                        true
                                                          ? true
                                                          : false
                                                      }
                                                      className="fa fa-minus-circle ml-1"
                                                      title="Remove Qualification Detail"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        let existingDegrees =
                                                          values.Degrees;
                                                        if (
                                                          existingDegrees.length >
                                                          1
                                                        ) {
                                                          existingDegrees.splice(
                                                            qIndex,
                                                            1
                                                          );
                                                        }
                                                        setFieldValue(
                                                          "Degrees",
                                                          existingDegrees
                                                        );
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Col>
                                              </Row>
                                            </Fragment>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </TabPane>
                              <TabPane tabId={5}>
                                <div>
                                  <Row>
                                    <Col lg="6">
                                      <Label>
                                        {this.props.t("Residence Address")}
                                      </Label>
                                    </Col>
                                    <Col lg="2">
                                      <Label>
                                        {this.props.t("Official Address")}
                                      </Label>
                                      <br />
                                    </Col>
                                    <Col lg="4">
                                      <Label className="float-left">
                                        {this.props.t(
                                          "Same as Residence Address"
                                        )}
                                      </Label>
                                      <div className="custom-control custom-switch float-right">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox"
                                          id="SameAsResidenceAddress"
                                          checked={
                                            values?.SameAsResidenceAddress
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              "SameAsResidenceAddress",
                                              e.target.checked
                                            );
                                            if (e.target.checked) {
                                              let provisionalAddress =
                                                values?.ResidencyAddress
                                                  ?.Address;
                                              setFieldValue(
                                                "ProvisionalAddress.Address",
                                                provisionalAddress
                                              );
                                            }
                                          }}
                                          disabled={
                                            this.state.IsProfileCompleted ===
                                            true
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="SameAsResidenceAddress"
                                        >
                                          {values?.SameAsResidenceAddress
                                            ? "Yes"
                                            : "No"}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {this.props.t("Address Line 1")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ResidencyAddress.Address.Line1"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line1",
                                            e?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line1",
                                              e?.target?.value
                                            );
                                          }
                                        }}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line1",
                                            event?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line1",
                                              event?.target?.value
                                            );
                                          }
                                        }}
                                      />
                                      {errors.ResidencyAddress &&
                                        errors.ResidencyAddress.Address &&
                                        errors.ResidencyAddress.Address
                                          .Line1 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ResidencyAddress.Address
                                                .Line1
                                            }
                                          </div>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {this.props.t("Address Line 1")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ProvisionalAddress.Address.Line1"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Line1",
                                            event?.target?.value
                                          );
                                        }}
                                      />
                                      {errors.ProvisionalAddress &&
                                        errors.ProvisionalAddress.Address &&
                                        errors.ProvisionalAddress.Address
                                          .Line1 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ProvisionalAddress.Address
                                                .Line1
                                            }
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Label className="">
                                        {this.props.t("Address Line 2")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ResidencyAddress.Address.Line2"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line2",
                                            e?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line2",
                                              e?.target?.value
                                            );
                                          }
                                        }}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line2",
                                            event?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line2",
                                              event?.target?.value
                                            );
                                          }
                                        }}
                                      />
                                      {errors.ResidencyAddress &&
                                        errors.ResidencyAddress.Address &&
                                        errors.ResidencyAddress.Address
                                          .Line2 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ResidencyAddress.Address
                                                .Line2
                                            }
                                          </div>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="">
                                        {this.props.t("Address Line 2")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ProvisionalAddress.Address.Line2"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Line2",
                                            event?.target?.value
                                          );
                                        }}
                                      />
                                      {errors.ProvisionalAddress &&
                                        errors.ProvisionalAddress.Address &&
                                        errors.ProvisionalAddress.Address
                                          .Line2 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ProvisionalAddress.Address
                                                .Line2
                                            }
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Label className="">
                                        {this.props.t("Address Line 3")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ResidencyAddress.Address.Line3"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line3",
                                            e?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line3",
                                              e?.target?.value
                                            );
                                          }
                                        }}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ResidencyAddress.Address.Line3",
                                            event?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Line3",
                                              event?.target?.value
                                            );
                                          }
                                        }}
                                      />

                                      {errors.ResidencyAddress &&
                                        errors.ResidencyAddress.Address &&
                                        errors.ResidencyAddress.Address
                                          .Line3 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ResidencyAddress.Address
                                                .Line3
                                            }
                                          </div>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="">
                                        {this.props.t("Address Line 3")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        name="ProvisionalAddress.Address.Line3"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Line3",
                                            event?.target?.value
                                          );
                                        }}
                                      />
                                      {errors.ProvisionalAddress &&
                                        errors.ProvisionalAddress.Address &&
                                        errors.ProvisionalAddress.Address
                                          .Line3 && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ProvisionalAddress.Address
                                                .Line3
                                            }
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <DropDown
                                        labelClassName="requiredField"
                                        label={this.props.t("State")}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="ResidencyAddress.Address.State"
                                        isSearchable
                                        isDisabled={
                                          this.state?.IsProfileCompleted ===
                                          true
                                            ? true
                                            : false
                                        }
                                        isClearable={true}
                                        value={
                                          values?.ResidencyAddress &&
                                          values?.ResidencyAddress?.Address &&
                                          values?.ResidencyAddress?.Address
                                            .State &&
                                          (typeof values?.ResidencyAddress
                                            .Address.State === "string"
                                            ? this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.State
                                              )
                                            : this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.State._id
                                              ))
                                        }
                                        options={this.state.StateList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.State",
                                            undefined
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.State",
                                              undefined
                                            );
                                          }
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.State",
                                            entity ? entity.value : undefined
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.State",
                                              entity ? entity.value : undefined
                                            );
                                          }
                                        }}
                                        errors={
                                          errors.ResidencyAddress &&
                                          errors.ResidencyAddress.Address
                                            ? errors.ResidencyAddress.Address
                                                .State
                                            : ""
                                        }
                                      />
                                    </Col>
                                    <Col lg="6">
                                      <DropDown
                                        labelClassName="requiredField"
                                        label={this.props.t("State")}
                                        isDisabled={
                                          this.state?.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="ProvisionalAddress.Address.State"
                                        isSearchable
                                        // isDisabled={true}
                                        isClearable={true}
                                        value={
                                          values?.ProvisionalAddress &&
                                          values?.ProvisionalAddress?.Address &&
                                          values?.ProvisionalAddress?.Address
                                            .State &&
                                          (typeof values?.ProvisionalAddress
                                            .Address.State === "string"
                                            ? this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    .Address.State
                                              )
                                            : this.state.StateList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    .Address.State._id
                                              ))
                                        }
                                        options={this.state.StateList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.State",
                                            undefined
                                          );
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.State",
                                            entity ? entity.value : undefined
                                          );
                                        }}
                                        errors={
                                          errors.ProvisionalAddress &&
                                          errors.ProvisionalAddress.Address
                                            ? errors.ProvisionalAddress.Address
                                                .State
                                            : ""
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t("District")}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values?.ResidencyAddress &&
                                          values?.ResidencyAddress &&
                                          values?.ResidencyAddress?.Address &&
                                          values?.ResidencyAddress?.Address
                                            .District &&
                                          (typeof values?.ResidencyAddress
                                            .Address.District === "string"
                                            ? this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.District
                                              )
                                            : this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.District._id
                                              ))
                                        }
                                        options={this.state.DistrictList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.District",
                                            null
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.District",
                                              null
                                            );
                                          }
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.District",
                                            entity.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.District",
                                              entity.value
                                            );
                                          }
                                          this.GetAllTaluk(
                                            entity ? entity.value : ""
                                          );
                                        }}
                                        errors={
                                          errors.ResidencyAddress &&
                                          errors.ResidencyAddress.Address
                                            ? errors.ResidencyAddress.Address
                                                .District
                                            : ""
                                        }
                                      />
                                    </Col>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t("District")}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values?.ProvisionalAddress &&
                                          values?.ProvisionalAddress?.Address &&
                                          values?.ProvisionalAddress?.Address
                                            .District &&
                                          (typeof values?.ProvisionalAddress
                                            .Address.District === "string"
                                            ? this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    .Address.District
                                              )
                                            : this.state.DistrictList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    .Address.District._id
                                              ))
                                        }
                                        options={this.state.DistrictList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.District",
                                            null
                                          );
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.District",
                                            entity.value
                                          );
                                          this.GetAllTaluk(
                                            entity ? entity.value : ""
                                          );
                                        }}
                                        errors={
                                          errors.ProvisionalAddress &&
                                          errors.ProvisionalAddress.Address
                                            ? errors.ProvisionalAddress.Address
                                                .District
                                            : ""
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t("Taluk")}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values?.ResidencyAddress &&
                                          values?.ResidencyAddress?.Address &&
                                          values?.ResidencyAddress?.Address
                                            .Taluk &&
                                          (typeof values?.ResidencyAddress
                                            .Address.Taluk === "string"
                                            ? this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.Taluk
                                              )
                                            : this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ResidencyAddress
                                                    .Address.Taluk._id
                                              ))
                                        }
                                        options={this.state.TalukList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Taluk",
                                            null
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Taluk",
                                              null
                                            );
                                          }
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Taluk",
                                            entity.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Taluk",
                                              entity.value
                                            );
                                          }
                                        }}
                                        errors={
                                          errors.ResidencyAddress &&
                                          errors.ResidencyAddress.Address
                                            ? errors.ResidencyAddress.Address
                                                .Taluk
                                            : ""
                                        }
                                      />
                                    </Col>
                                    <Col lg="6">
                                      <DropDown
                                        isDisabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        labelClassName="requiredField"
                                        label={this.props.t("Taluk")}
                                        components={{
                                          Input: CustomSelectInput,
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable
                                        isClearable={true}
                                        value={
                                          values?.ProvisionalAddress &&
                                          values?.ProvisionalAddress?.Address &&
                                          values?.ProvisionalAddress?.Address
                                            .Taluk &&
                                          (typeof values?.ProvisionalAddress
                                            .Address.Taluk === "string"
                                            ? this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    ?.Address?.Taluk
                                              )
                                            : this.state.TalukList.find(
                                                (r) =>
                                                  r.value ===
                                                  values?.ProvisionalAddress
                                                    ?.Address?.Taluk?._id
                                              ))
                                        }
                                        options={this.state.TalukList}
                                        ClearAction={() => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Taluk",
                                            null
                                          );
                                        }}
                                        Action={(entity) => {
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Taluk",
                                            entity.value
                                          );
                                        }}
                                        errors={
                                          errors.ProvisionalAddress &&
                                          errors.ProvisionalAddress.Address
                                            ? errors.ProvisionalAddress.Address
                                                .Taluk
                                            : ""
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {this.props.t("PIN Code")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted === true
                                            ? true
                                            : false
                                        }
                                        autoComplete="nope"
                                        type="number"
                                        className="IncrementHide form-control"
                                        name="ResidencyAddress.Address.Pincode"
                                        placeholder="Pincode"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ResidencyAddress.Address.Pincode",
                                            e?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Pincode",
                                              e?.target?.value
                                            );
                                          }
                                        }}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ResidencyAddress.Address.Pincode",
                                            event?.target?.value
                                          );
                                          if (values?.SameAsResidenceAddress) {
                                            setFieldValue(
                                              "ProvisionalAddress.Address.Pincode",
                                              event?.target?.value
                                            );
                                          }
                                        }}
                                      />
                                      {errors.ResidencyAddress &&
                                        errors.ResidencyAddress.Address &&
                                        errors.ResidencyAddress.Address
                                          .Pincode && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ResidencyAddress.Address
                                                .Pincode
                                            }
                                          </div>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                      <Label className="requiredField">
                                        {this.props.t("PIN Code")}
                                      </Label>
                                      <Field
                                        disabled={
                                          this.state.IsProfileCompleted ===
                                            true ||
                                          values?.SameAsResidenceAddress ===
                                            true
                                            ? true
                                            : false
                                        }
                                        autoComplete="nope"
                                        type="number"
                                        className="IncrementHide form-control"
                                        name="ProvisionalAddress.Address.Pincode"
                                        placeholder="Pincode"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event?.target?.value &&
                                            event?.target?.value?.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "ProvisionalAddress.Address.Pincode",
                                            event?.target?.value
                                          );
                                        }}
                                      />
                                      {errors.ProvisionalAddress &&
                                        errors.ProvisionalAddress.Address &&
                                        errors.ProvisionalAddress.Address
                                          .Pincode && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.ProvisionalAddress.Address
                                                .Pincode
                                            }
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                </div>
                              </TabPane>
                            </TabContent>
                            {!this.state.IsProfileCompleted && (
                              <ul
                                className="pager wizard twitter-bs-wizard-pager-link"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li
                                  className={
                                    this.state.ActiveTabId === 1
                                      ? "previous disabled d-none"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      this.toggleTab(
                                        this.state.ActiveTabId - 1
                                      );
                                      this.GetProfileDetail();
                                      this.state.AadhaarEdit =
                                        values?.Aadhar?.length !== 0
                                          ? true
                                          : false;
                                      this.state.PANEdit =
                                        values?.PANNumber?.length !== 0
                                          ? true
                                          : false;
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li
                                  className={
                                    this.state.ActiveTabId ===
                                    this.state.tabs.length
                                      ? "next d-none"
                                      : "next"
                                  }
                                >
                                  <Button
                                    color="info"
                                    to="#"
                                    type="submit"
                                    onClick={() => {
                                      this.ButtonPressed = "Next";
                                    }}
                                  >
                                    Next
                                  </Button>
                                </li>
                                <li
                                  className={
                                    this.state.ActiveTabId ===
                                      this.state.tabs.length &&
                                    !this.state.IsProfileCompleted
                                      ? "  d-block"
                                      : "d-none"
                                  }
                                >
                                  <Button
                                    color="primary"
                                    to="#"
                                    type="submit"
                                    onClick={() => {
                                      this.ButtonPressed = "Submit";
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </li>
                              </ul>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.manageEligibilityModal}
          toggle={this.toggleEligibilityModal}
          size="xl"
        >
          <ModalHeader toggle={this.toggleEligibilityModal}>
            {this.props.t("EligibilityCriteria")}
          </ModalHeader>

          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.EligibilityContent
                  ? this.state.EligibilityContent.Content
                  : "",
              }}
            ></div>
          </ModalBody>

          <ModalFooter>
            <Button
              color="danger"
              className="form-second-btn"
              onClick={() => this.toggleEligibilityModal()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.loading}>
          <ModalBody>
            <Fragment>
              <span>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
              Processing... Please wait... Please login once completed.
            </Fragment>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(withNamespaces()(Profile))
);
