import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withNamespaces } from "react-i18next";
import { Row, Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { fromUnixTime } from "date-fns";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";

class DuplicateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  async savePDF() {
    this.setState({
      loading: true,
    });

    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    if (this.props.match) {
      options.scrollX = -7;
    }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    const printArea = document.getElementById("PrintForm");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");

    var pagePromises = [];

    Promise.all(pagePromises).then(() => {
      pdf.save(
        this.props.Application
          ? this.state.Application && this.state.Application.Title + ".pdf"
          : "saved.pdf"
      );
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 10000);
  }

  DownloadFormAsPDF = () => {
    const Application = this.props.Application.ApplicationFields
      ? this.props.Application.ApplicationFields
      : this.props.Application;
    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    pdf.text(
      "REQUEST FOR DUPLICATE CERTIFICATE OF PRACTICE",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 30;
    pdf.text("From", 50, y, null, null);
    y = y + 10;

    // pdf.text("R. Priyanga,", 50, y, null, null);
    // y = y + 10;
    // pdf.text("No.5, 14th Street,", 50, y, null, null);
    // y = y + 10;
    // pdf.text("Tansi Nagar,", 50, y, null, null);
    // y = y + 10;
    // pdf.text("Velachery-600042,", 50, y, null, null);
    pdf.text(Application.From, 50, y, null, null);
    y = y + 40;
    pdf.text("To", 50, y, null, null);
    y = y + 10;

    pdf.text("The Secretary to Government,", 50, y, null, null);
    y = y + 10;

    pdf.text("Law Department,", 50, y, null, null);
    y = y + 10;

    pdf.text("Secretariat,", 50, y, null, null);
    y = y + 10;
    pdf.text("Chennai - 600 009.", 50, y, null, null);
    y = y + 20;

    pdf.text("Sir/Madam,", 50, y, null, null);
    y = y + 20;
    pdf.text("Sub:", 100, y, null, null);

    pdf.text(
      "Request for issue of Duplicate Certificate of Practice - Reg.",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 10;
    pdf.text("Ref:-", 100, y, null, null);

    pdf.text("G.O.(Ms) No.", 123, y, null, null);
    pdf.text(Application.NotaryAppointmentGONumber, 175, y, null, null);
    pdf.line(175, y + 5, 250, y + 5);

    pdf.text(", Law Department, dated", 250, y, null, null);
    y = y + 15;

    pdf.text(
      Application.NotaryAppointmentGODate
        ? formatDate(
            fromUnixTime(Application.NotaryAppointmentGODate / 1000),
            datePickerFormat
          )
        : "",
      125,
      y,
      null,
      null
    );
    pdf.line(125, y + 5, 190, y + 5);
    pdf.text("[Appointment G.O.]", 200, y, null, null);

    y = y + 30;

    pdf.text(
      "I have been appointed as Notary vide the Government Order cited w.e.f",
      50,
      y,
      null,
      null
    );
    pdf.text(
      Application.NotaryAppointmentDate
        ? formatDate(
            fromUnixTime(Application.NotaryAppointmentDate / 1000),
            datePickerFormat
          )
        : "",
      320,
      y,
      null,
      null
    );
    pdf.line(320, y + 5, 360, y + 5);
    y = y + 20;

    pdf.text(
      "(here mention the date of appointment) to practice as such in and throughout",
      50,
      y,
      null,
      null
    );
    y = y + 20;

    pdf.text(Application.Areas, 50, y, null, null);

    pdf.line(50, y + 5, 300, y + 5);
    y = y + 20;

    pdf.text(
      "(here mention the area of practice). I have paid the prescribed fee of Rs. 750/-",
      50,
      y,
      null,
      null
    );
    y = y + 20;

    pdf.text(
      "through online.  I hereby request to issue a Duplicate Certificate of Practice for the following.",
      50,
      y,
      null,
      null
    );
    y = y + 20;
    pdf.text("reason.", 50, y, null, null);
    pdf.text(Application.Reason, 80, y, null, null);
    pdf.line(80, y + 5, 200, y + 5);

    y = y + 20;

    pdf.text("Thanking you.", 230, y, null, null, "center");
    y = y + 20;

    // pdf.text("Signature of Notary", 330, y, null, null);
    // y = y + 10;

    // pdf.text(Application && Application.Name, 380, y, null, null);

    y = y + 30;
    pdf.text("Place: ", 50, y, null, null);
    pdf.text(Application.Place, 80, y, null, null);
    y = y + 20;

    pdf.text("Date: ", 50, y, null, null);
    pdf.text(
      this.props.Application.ApplicationDate
        ? moment(new Date(this.props.Application.ApplicationDate)).format(
            "DD/MM/YYYY"
          )
        : "",
      80,
      y,
      null,
      null
    );

    pdf.save(
      this.props.Application
        ? this.props.Application &&
            this.props.Application.ApplicationNoText + ".pdf"
        : "Form2.pdf"
    );
  };

  render() {
    const Application = this.props.Application.ApplicationFields;

    return (
      <Fragment>
        <Card>
          <CardBody>
            <Row>
              <Colxx xxs="12">
                <h4>Duplicate Form</h4>
              </Colxx>
              <Colxx xxs="9"></Colxx>
              <Colxx xxs="3">
                <Button
                  className="form-second-btn"
                  type="button"
                  onClick={() => this.DownloadFormAsPDF()}
                  color="success"
                  disabled={this.state.loading}
                >
                  Download as PDF{" "}
                </Button>
              </Colxx>
              <Colxx xxs="12" id="PrintForm">
                <div
                // style={{
                //   width: "210mm",
                //   height: "297mm",
                // }}
                >
                  <Row>
                    <Colxx xxs="12 text-center mt-1">
                      <strong>
                        REQUEST FOR DUPLICATE CERTIFICATE OF PRACTICE
                      </strong>
                    </Colxx>

                    <Colxx xxs="12 text-left  mt-2">
                      <span>From</span>
                    </Colxx>
                    <pre
                      style={{
                        padding: "12px",
                        fontFamily: "Poppins",
                        fontSize: "0.8125rem",
                        fontWeight: "400",
                      }}
                    >
                      {Application && Application.From
                        ? Application && Application.From
                        : "-"}
                    </pre>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>To</span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>The Secretary to Government,</span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Law Department, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Secretariat, </span>
                    </Colxx>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Chennai – 600 009. </span>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="12 text-left  mt-2">
                      <span>Sir/Madam, </span>
                    </Colxx>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Colxx xxs="4"></Colxx>
                    <Colxx xxs="5">
                      <table className="table table-bordered table-centered table mb-2">
                        <tr>
                          <td>Sub:</td>
                          <td>
                            Request for issue of Duplicate Certificate of
                            Practice - Reg.
                          </td>
                        </tr>
                        <tr>
                          <td>Ref:-</td>
                          <td>
                            G.O.(Ms).No{" "}
                            <span
                              className={
                                Application &&
                                Application.NotaryAppointmentGONumber
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application &&
                              Application.NotaryAppointmentGONumber
                                ? Application &&
                                  Application.NotaryAppointmentGONumber
                                : "-"}
                            </span>
                            , Law Department, dated{" "}
                            <span
                              className={
                                Application &&
                                Application.NotaryAppointmentGODate
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application &&
                              Application.NotaryAppointmentGODate
                                ? moment(
                                    new Date(
                                      Application.NotaryAppointmentGODate * 1000
                                    )
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </span>
                            [Appointment G.O.]
                          </td>
                        </tr>
                      </table>
                    </Colxx>
                  </Row>

                  <Row>
                    <Colxx xxs="12 mt-3">
                      <Row>
                        <Colxx className="">
                          &emsp; I have been appointed as Notary{" "}
                          <span className="text-underline">vide </span> the
                          Government Order cited w.e.f{" "}
                          <span
                            className={
                              Application && Application.NotaryAppointmentDate
                                ? "text-underline"
                                : ""
                            }
                          >
                            {" "}
                            {Application && Application.NotaryAppointmentDate
                              ? moment(
                                  new Date(
                                    Application.NotaryAppointmentDate * 1000
                                  )
                                ).format("DD/MM/YYYY")
                              : "-"}
                          </span>{" "}
                          (here mention the date of appointment) to practice as
                          such in and throughout{" "}
                          <span
                            className={
                              Application && Application.Areas
                                ? "text-underline"
                                : ""
                            }
                          >
                            {" "}
                            {Application && Application.Areas
                              ? Application && Application.Areas
                              : "-"}
                          </span>{" "}
                          (here mention the area of practice). I have paid the
                          prescribed fee of Rs. 750/- through online. I hereby
                          request to issue a Duplicate Certificate of Practice
                          for the following reason.{" "}
                          <span
                            className={
                              Application && Application.Reason
                                ? "text-underline"
                                : ""
                            }
                          >
                            {" "}
                            {Application && Application.Reason
                              ? Application && Application.Reason
                              : "-"}
                          </span>
                        </Colxx>
                      </Row>
                    </Colxx>
                    <br />
                    <br />
                    <Colxx className="text-center">Thanking you.</Colxx>
                    <br />
                  </Row>
                  <Row>
                    <Colxx xxs="6 text-center  mt-3">
                      <Row>
                        <Colxx xxs="12 text-left  mt-3">
                          <span>
                            {"Place: "}{" "}
                            <span
                              className={
                                Application && Application.Place
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application && Application.Place
                                ? Application && Application.Place
                                : "-"}
                            </span>
                          </span>
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="6 text-left">
                          <span>
                            {"Date: "}{" "}
                            <span
                              className={
                                Application &&
                                this.props.Application.ApplicationDate
                                  ? "text-underline"
                                  : ""
                              }
                            >
                              {" "}
                              {Application &&
                              this.props.Application.ApplicationDate
                                ? moment(
                                    new Date(
                                      this.props.Application.ApplicationDate
                                    )
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </span>{" "}
                          </span>
                        </Colxx>
                      </Row>
                    </Colxx>
                  </Row>
                  <Row>
                    <Colxx xxs="12 text-right  mt-2 float-right">
                      <Row>
                        <Colxx xxs="12 text-right  mt-2">
                          <span>{`Signature of Notary`}</span>
                        </Colxx>
                        <Colxx xxs="12 text-right  mt-2">
                          <span
                            className={
                              Application && Application.Name
                                ? "text-underline"
                                : ""
                            }
                          >
                            {" "}
                            {Application && Application.Name
                              ? Application.Name
                              : "-"}
                          </span>
                        </Colxx>
                      </Row>
                    </Colxx>
                  </Row>
                </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(DuplicateForm));
