import React, { Component, Fragment } from "react";
// import cookie from "react-cookies";

import { Cookies } from "react-cookie";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import logo from "../../assets/images/logo.png";
import { CallService } from "../../helpers/ServiceCall";
import { authenctication, cms } from "../../constants/config";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withNamespaces } from "react-i18next";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
import {
  Base64ToUint8Array,
  encryptBox,
  decryptBox,
  formatEncryptedField,
  Uint8ArrayToBase64,
} from "../../helpers/encryption";
nacl.util = naclUtil;
class Login extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      userLoginType: "",
      IsProfileCompleted: true,
      loading: false,
      captchaVerified: false,
    };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    if (values.captcha === this.state.captchatxt) {
      const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
      const password = values.password;
      const { cipherText, nonceText, clientPublicKeyText } = encryptBox(
        password,
        serverPublicKey
      );
      if (this.state.captchaVerified) {
        if (!this.state.loading && !this.state.UniqueUserNameValidate) {
          this.setState({
            loading: true,
            captchaVerified: false,
          });
          console.log("userLoginType", this.state.userLoginType);
          switch (this.state.userLoginType) {
            case "Advocate":
              CallService(
                authenctication.advocateLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                }, //refer
                "",
                this.LoginResponse
              );
              break;
            case "Official":
              CallService(
                authenctication.OfficialLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                }, //refer
                "",
                this.LoginOfficalResponse
              );
              break;
            case "Law Admin":
              CallService(
                authenctication.OfficialLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                },
                "",
                this.LoginOfficalResponse
              );
              break;
            case "Interview Board":
              CallService(
                authenctication.interviewBoardLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                },
                "",
                this.LoginOfficalResponse
              );
              break;
            case "Competent Authority":
              CallService(
                authenctication.OfficialLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                },
                "",
                this.LoginOfficalResponse
              );
              break;
            case "Super Admin":
              CallService(
                authenctication.superAdmin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                },
                "",
                this.LoginSuperAdminResponse
              );
              break;
            default:
              CallService(
                authenctication.advocateLogin,
                MethodType.POST,
                false,
                {
                  MobileNumber: values.email,
                  Password: formatEncryptedField(
                    this.state.SeedId,
                    cipherText,
                    nonceText,
                    clientPublicKeyText
                  ),
                  CaptchaId:
                    this.state.CaptchaDetails &&
                    this.state.CaptchaDetails.CaptchaId,
                },
                "",
                this.LoginResponse
              );
          }
          var authUser = values;
          delete authUser.password;
          localStorage.setItem("authUser", JSON.stringify(values));
          //  this.props.history.push("/case");
        }
      } else {
        CallService(
          authenctication.HandleCaptcha,
          MethodType.POST,
          false,
          {
            CaptchaText: values.captcha,
            CaptchaId:
              this.state.CaptchaDetails && this.state.CaptchaDetails.CaptchaId,
          },
          "",
          this.HandleCaptchaResponse
        );
      }
    } else {
      this.setState({ loading: false });
      toastr.error("", "Please Check captcha");
    }
  }
  HandleCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        "Captcha verified successfully. Please login to continue."
      );
      this.setState({
        captchaVerified: true,
      });
    }
  };
  LoginResponse = (data, dd) => {
    this.setState({ loading: false });
    if (data.result) {
      // if (data.result.IsProfileCompleted === undefined) {
      //   data.result.IsProfileCompleted = true;
      // }
      sessionStorage.setItem("user", JSON.stringify(data.result));

      if (!data.result.IsProfileCompleted) {
        sessionStorage.setItem("AdvocateName", data.result.Name);

        sessionStorage.setItem("MobileNumber", data.result.MobileNumber);
        sessionStorage.setItem("EmailID", data.result.EmailID);
        sessionStorage.setItem("user_token", data.result.token);
        // sessionStorage.setItem(
        //   "user_id",
        //   data.result.UserID ? data.result.UserID : ""
        // );
        sessionStorage.setItem(
          "IsProfileCompleted",
          data.result.IsProfileCompleted
        );
        sessionStorage.setItem("user_id", data.result.UserID);
        sessionStorage.setItem(
          "user_role",
          data.result.Role && data.result.Role.Name
        );
        this.props.history.push("/userprofile");
      } else {
        sessionStorage.setItem("AdvocateName", data.result.Name);
        sessionStorage.setItem(
          "BarCouncilNumber",
          data.result.BarCouncilNumber
        );
        sessionStorage.setItem("MobileNumber", data.result.MobileNumber);

        sessionStorage.setItem("EmailID", data.result.EmailID);

        sessionStorage.setItem("user_token", data.result.token);
        // sessionStorage.setItem("user_role", data.result.Role);
        sessionStorage.setItem("user_id", data.result.UserID);
        sessionStorage.setItem(
          "Status",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0].Status
            : data.result.ExistingNotaryApplications &&
              data.result.ExistingNotaryApplications.length > 0
            ? data.result.ExistingNotaryApplications[0].Status
            : ""
        );
        sessionStorage.setItem(
          "ValidTo",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0].ValidTo
            : data.result.ExistingNotaryApplications &&
              data.result.ExistingNotaryApplications.length > 0
            ? data.result.ExistingNotaryApplications[0].NotaryApplication
                .ValidTo
            : ""
        );

        sessionStorage.setItem(
          "CallLetter",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0].InterviewDetails
              ? data.result.Applications[0].InterviewDetails.CallLetter
              : ""
            : ""
        );
        sessionStorage.setItem(
          "FeeTotalAmount",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0].FeeDetails
              ? data.result.Applications[0].FeeDetails.TotalAmount
              : ""
            : ""
        );
        sessionStorage.setItem(
          "TotalAreas",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0].TotalAreas
              ? JSON.stringify(data.result.Applications[0].TotalAreas)
              : ""
            : ""
        );
        sessionStorage.setItem(
          "IsArchived",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0]
              ? data.result.Applications[0].IsArchived
              : ""
            : ""
        );
        sessionStorage.setItem(
          "ApplicationId",
          data.result.Applications && data.result.Applications[0]
            ? data.result.Applications[0]._id
            : ""
        );
        sessionStorage.setItem(
          "RenewalStatus",
          data.result &&
            data.result.renewalApplication &&
            data.result.renewalApplication
            ? data.result.renewalApplication.Status
            : ""
        );
        sessionStorage.setItem(
          "RenewalApplicationID",
          data.result &&
            data.result.renewalApplication &&
            data.result.renewalApplication
            ? data.result.renewalApplication._id
            : ""
        );
        sessionStorage.setItem(
          "AreaStatus",
          data.result &&
            data.result.areaExtensionApplication &&
            data.result.areaExtensionApplication
            ? data.result.areaExtensionApplication.Status
            : ""
        );
        sessionStorage.setItem(
          "AreaExtensionsApplicationID",
          data.result &&
            data.result.areaExtensionApplication &&
            data.result.areaExtensionApplication
            ? data.result.areaExtensionApplication._id
            : ""
        );
        sessionStorage.setItem(
          "DuplicateStatus",
          data.result &&
            data.result.duplicateCertificateApplication &&
            data.result.duplicateCertificateApplication
            ? data.result.duplicateCertificateApplication.Status
            : ""
        );
        sessionStorage.setItem(
          "DuplicateApplicationID",
          data.result &&
            data.result.duplicateCertificateApplication &&
            data.result.duplicateCertificateApplication
            ? data.result.duplicateCertificateApplication._id
            : ""
        );
        sessionStorage.setItem(
          "CoPCopy",
          data.result && data.result.Applications.length > 0
            ? data.result.Applications[0]
              ? data.result.Applications[0].CoPCopy
              : ""
            : ""
        );

        sessionStorage.setItem(
          "IsProfileCompleted",
          data.result.IsProfileCompleted
        );
        const cookies = new Cookies();
        let pathname = this.props.location.pathname;
        cookies.remove("pathname");
        cookies.set("pathname", pathname, {
          path: "/",
          secure: true,
          httpOnly: true,
          sameSite: "strict",
        });

        if (
          data.result.ExistingNotaryApplications &&
          data.result.ExistingNotaryApplications.length > 0
        ) {
          sessionStorage.setItem("ExistingNotaryApplications", true);
        } else {
          sessionStorage.setItem("ExistingNotaryApplications", false);
        }
        sessionStorage.setItem(
          "ExistingNotaryApplicationsID",
          data.result
            ? data.result.ExistingNotaryApplications
              ? data.result.ExistingNotaryApplications.length > 0
                ? data.result.ExistingNotaryApplications[0]._id
                : ""
              : ""
            : ""
        );
        if (data.result.Applications && data.result.Applications.length > 0) {
          sessionStorage.setItem("Application", true);
          this.props.history.push("/dashboard");
        } else {
          sessionStorage.setItem("Application", false);
          this.props.history.push("/new_notary_application");
        }

        //        this.props.history.push("/dashboard");
      }
    } else if (data.statusCode === "401") {
      this.getSeed();
      toastr.error("", "Incorrect username or password");
    } else toastr.error("", "Something went wrong");
  };
  LoginOfficalResponse = (data, dd) => {
    this.setState({ loading: false });
    if (data.result) {
      sessionStorage.setItem("user", JSON.stringify(data.result));
      sessionStorage.setItem(
        "IsPasswordChanged",
        data.result.IsPasswordChanged
      );
      sessionStorage.setItem("MobileNumber", data.result.MobileNumber);

      // sessionStorage.setItem("EmailID", data.result.EmailID);

      sessionStorage.setItem(
        "Distircts",
        data.result ? JSON.stringify(data.result.Districts) : ""
      );

      sessionStorage.setItem("user_token", data.result.token);
      sessionStorage.setItem(
        "IsProfileCompleted",
        this.state.IsProfileCompleted
      );
      sessionStorage.setItem("user_id", data.result.UserID);
      sessionStorage.setItem(
        "IsProfileCompleted",
        data.result.IsProfileCompleted
      );

      const cookies = new Cookies();
      let pathname = this.props.location.pathname;
      cookies.remove("pathname");
      cookies.set("pathname", pathname, {
        path: "/",
        secure: true,
        httpOnly: true,
        sameSite: "strict",
      });

      if (data.result && data.result.IsPasswordChanged) {
        this.props.history.push("/notary_applications");
      } else {
        this.props.history.push("/changepassword");
      }
    } else if (data.statusCode === "401")
      toastr.error("", "Incorrect username or password");
    else toastr.error("", "Something went wrong");
  };

  LoginSuperAdminResponse = (data, dd) => {
    this.setState({ loading: false });
    if (data.result) {
      sessionStorage.setItem("user", JSON.stringify(data.result));
      sessionStorage.setItem("MobileNumber", data.result.MobileNumber);

      // sessionStorage.setItem("EmailID", data.result.EmailID);

      sessionStorage.setItem("user_token", data.result.token);
      // sessionStorage.setItem(
      //   "IsProfileCompleted",
      //   this.state.IsProfileCompleted
      // );
      sessionStorage.setItem("user_id", data.result.UserID);
      sessionStorage.setItem(
        "Status",
        data.result && data.result.Applications
          ? data.result.Applications[0].Status
          : ""
      );
      sessionStorage.setItem(
        "IsProfileCompleted",
        data.result.IsProfileCompleted
      );
      this.props.history.push("/advocate");
      const cookies = new Cookies();
      let pathname = this.props.location.pathname;
      cookies.remove("pathname");
      cookies.set("pathname", pathname, {
        path: "/",
        secure: true,
        httpOnly: true,
        sameSite: "strict",
      });
    } else if (data.statusCode === "401")
      toastr.error("", "Incorrect mobile number or password");
    else toastr.error("", "Something went wrong");
  };
  componentWillMount() {
    var url = this.props.match.url;
    this.getSeed();
    if (url.includes("lawadmin")) {
      this.setState({
        userLoginType: "Law Admin",
      });
    } else if (url.includes("advocate")) {
      this.setState({
        userLoginType: "Advocate",
      });
    } else if (url.includes("official")) {
      this.setState({
        userLoginType: "Official",
      });
    } else if (url.includes("superadmin")) {
      this.setState({
        userLoginType: "Super Admin",
      });
    } else if (url.includes("interviewboard")) {
      this.setState({
        userLoginType: "Interview Board",
      });
    } else if (url.includes("competentauthority")) {
      this.setState({
        userLoginType: "Competent Authority",
      });
    } else {
      this.setState({
        userLoginType: "Advocate",
      });
    }
    var x = document.getElementsByTagName("body")[0];
    if (x.className !== "bg-image") {
      x.className += "bg-image";
    }
  }
  componentWillUnmount() {
    var x = document.getElementsByTagName("body")[0];
    if (x.className === "bg-image") {
      x.className.replace("bg-image", "");
    }
  }
  componentDidMount() {
    // if (this.props.match.url.includes("interviewboardlogin")) {
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener("popstate", function (event) {
    //     window.history.pushState(null, document.title, window.location.href);
    //   });
    // }
    //  Window.history.replace("/login");
    this.props.apiError("");
    this.GetCMS("eligibility");

    setTimeout(() => {
      this.getSeed();
    }, 1000);
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState(
        {
          ServerPublicKey: data.result.ServerPublicKey,
          SeedId: data.result._id,
        },
        () => {
          this.getCaptcha();
        }
      );
    }
  };
  getCaptcha() {
    const { publicKey: clientPublicKey, secretKey: clientPrivateKey } =
      nacl.box.keyPair();

    const clientPublicKeyText = Uint8ArrayToBase64(clientPublicKey);
    const clientPrivateKeyText = Uint8ArrayToBase64(clientPrivateKey);
    CallService(
      authenctication.GetCaptcha,
      MethodType.POST,
      false,
      { ClientPublicKey: clientPublicKeyText },
      "",
      this.getCaptchaResponse
    );
    this.setState({
      captchaClientPublicKey: clientPublicKeyText,
      captchaClientPrivateKey: clientPrivateKeyText,
    });
  }
  getCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      const captcha = data.result.Captcha;
      const nonce = data.result.Nonce;
      const serverPublicKey = data.result.ServerPublicKey;
      const decipherText = decryptBox(
        captcha,
        nonce,
        serverPublicKey,
        this.state.captchaClientPrivateKey
      );
      var CaptchaDetails = {
        CaptchaId: data.result._id,
        CaptchaServerPublicKey: serverPublicKey,
        Captcha: decipherText,
      };
      this.setState(
        {
          CaptchaDetails: CaptchaDetails,
          Captcha: decipherText,
        },
        () => this.createCaptcha()
      );
    }
  };

  createCaptcha() {
    var canvas = this.canvasRef.current;

    var context = canvas.getContext("2d");

    context.clearRect(0, 0, 100, 100);
    var actualCaptcha = "";
    if (this.state.Captcha) {
      actualCaptcha = this.state.Captcha;
    } else {
      var charsArray = "0123456789";
      var lengthOtp = 6;
      var captcha = [];
      for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) === -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      actualCaptcha = captcha.join("");
    }
    context.font = "18px Georgia";
    context.fillText(actualCaptcha, 0, 30);
    this.setState({
      captchatxt: actualCaptcha,
    });
  }
  GetCMS = (searchString) => {
    let requestModel = {
      pageNumber: 1,
      pageLimit: 999999999999,
      searchString: searchString,
    };
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      requestModel,
      "",
      this.GetCMSResponse
    );
  };

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        EligibilityContent: data.result && data.result[0],
      });
    }
  };

  UniqueUserName = (user) => {
    var UniqueUser = {};
    if (this.state.userLoginType === "Advocate") {
      UniqueUser.User = "Advocate";
      UniqueUser.Username = user.target.value;
    } else if (this.state.userLoginType === "Official") {
      UniqueUser.User = "Officer";
      UniqueUser.Username = user.target.value;
    } else if (this.state.userLoginType === "Interview Board") {
      UniqueUser.User = "IB";
      UniqueUser.Username = user.target.value;
    } else if (this.state.userLoginType === "Law Admin") {
      UniqueUser.User = "LA";
      UniqueUser.Username = user.target.value;
    } else if (this.state.userLoginType === "Competent Authority") {
      UniqueUser.User = "CA/PDJ";
      UniqueUser.Username = user.target.value;
    }

    CallService(
      authenctication.UserNameValidate,
      MethodType.POST,
      false,
      UniqueUser,
      "",
      this.UniqueUserNameResponse
    );
  };

  UniqueUserNameResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        this.setState({
          UniqueUserNameValidate: data.result.IsUsernameAvailable,
        });
      }
    }
  };

  toggleEligibilityModal = () => {
    this.setState({
      manageEligibilityModal: !this.state.manageEligibilityModal,
    });
  };

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <br />
            <br />
            <Row>
              <Col lg={4} />
              <Col lg={4}>
                <Card
                  style={{
                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                  }}
                  className="overflow-hidden"
                >
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            {this.props.t(
                              "Government of Tamil Nadu - Law Department"
                            )}
                          </h5>
                          {/* <p>Tamil Nadu Pollution Control Board</p> */}
                        </div>
                      </Col>
                      {/* <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col> */}
                    </Row>
                  </div>

                  <CardBody className="pt-0">
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="75"
                          />
                        </span>
                      </div>
                    </div>
                    <CardTitle style={{ textAlign: "center" }}>
                      {this.state.userLoginType === "Advocate"
                        ? this.props.t("Applicant / Notary Login")
                        : this.state.userLoginType === "Official"
                        ? this.props.t("Official Login")
                        : this.state.userLoginType === "Interview Board"
                        ? this.props.t("Interview Board Login")
                        : this.state.userLoginType === "Competent Authority"
                        ? this.props.t("Competent Authority Login")
                        : this.state.userLoginType === "Law Admin"
                        ? this.props.t("Law Admin Login")
                        : this.props.t("Super Admin Login")}
                    </CardTitle>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="form-group">
                          <AvField
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter this field",
                              },
                              // pattern: {
                              //   value: "^[z0-9]+$",
                              //   errorMessage: "Allowed only numbers",
                              // },
                              // minLength: {
                              //   value: 10,
                              //   errorMessage: "Minimum 10 digits",
                              // },
                              // maxLength: {
                              //   value: 10,
                              //   errorMessage: "Maximum 10 digits",
                              // },
                            }}
                            autoComplete="off"
                            name="email"
                            // onBlur={(e) => {
                            //   if (this.state.userLoginType !== "Super Admin") {
                            //     this.UniqueUserName(e);
                            //   }
                            // }}
                            onChange={(e) => {
                              this.setState({
                                UserNameValue: e.target.value,
                              });
                            }}
                            label={
                              this.state.userLoginType === "Super Admin"
                                ? this.props.t("Mobile Number")
                                : this.props.t("Username")
                            }
                            // value="admin@themesbrand.com"
                            className="form-control"
                            placeholder={
                              this.state.userLoginType === "Super Admin"
                                ? this.props.t("Mobile Number")
                                : this.props.t("Username")
                            }
                            type="text"
                            required
                          />
                          {/* 
                          {this.state.UserNameValue ? (
                            this.state.UniqueUserNameValidate === true ? (
                              this.state.userLoginType === "Advocate" ? (
                                <div className="invalid-feedback d-block">
                                  Unregistered Username. Please sign up to login
                                </div>
                              ) : (
                                  <div className="invalid-feedback d-block">
                                    Invalid Username
                                  </div>
                                )
                            ) : (
                                ""
                              )
                          ) : (
                              ""
                            )}*/}
                        </div>
                        <div className="form-group">
                          <AvField
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter this field",
                              },
                              minLength: {
                                value: 6,
                                errorMessage: "Minimum 6 character",
                              },
                            }}
                            name="password"
                            autoComplete="off"
                            label={this.props.t("Password")}
                            // value="123456"advocatelogin
                            type="password"
                            required
                            placeholder={this.props.t("Password")}
                          />
                        </div>
                        <div className="form-group">
                          <label style={{ marginBottom: "-5%" }}>
                            Type the number shown in the box below
                          </label>
                          <Row>
                            <div className={"col-6"}>
                              <label className="captchaformcanvas">
                                <canvas
                                  className="MobileCanvas"
                                  width="150"
                                  height="33"
                                  ref={this.canvasRef}
                                />
                                <div className="captacharefresh">
                                  <i
                                    onClick={() => {
                                      this.getCaptcha();
                                      this.getSeed();
                                    }}
                                    className="mdi mdi-refresh"
                                  ></i>
                                </div>
                              </label>
                            </div>
                            <div className={"col-6 mt-2"}>
                              <AvField
                                className="form-control captchaform"
                                type="text"
                                autoComplete="off"
                                name="captcha"
                                required
                                // validate={this.validatePassword}
                              />
                            </div>
                          </Row>
                        </div>
                        {/* <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}
                        <div className="mt-3">
                          <button
                            type="submit"
                            className={`btn btn-primary btn-block waves-effect waves-light ${
                              this.state.loading ? "disabled" : ""
                            }`}
                          >
                            {this.state.loading ? (
                              <span>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : this.state.captchaVerified ? (
                              this.props.t("Login")
                            ) : (
                              this.props.t("Verify")
                            )}
                          </button>
                        </div>{" "}
                        <br></br>
                        {this.state.userLoginType === "Advocate" ? (
                          <Fragment>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleEligibilityModal();
                              }}
                              className="text-muted"
                            >
                              <span style={{ color: "blue", float: "right" }}>
                                {this.props.t("Sign Up")}
                              </span>
                            </Link>
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {/* // <div className="mt-4 text-center">
                          //   <Link to="/register" className="text-muted">
                          //     <i></i>Do you want to create a New Account ?
                          //   </Link>
                          // </div> */}
                        <div className="mt-4 text-center">
                          {this.props.location.pathname === "/advocatelogin" ? (
                            <Link
                              to="/advocate_forgot_password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock mr-1"></i>{" "}
                              <span style={{ color: "blue" }}>
                                {this.props.t("Forgot your password ?")}
                              </span>
                            </Link>
                          ) : this.props.location.pathname ===
                            "/officiallogin" ? (
                            <Link
                              to="/official_forgot_password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock mr-1"></i>{" "}
                              <span style={{ color: "blue" }}>
                                {this.props.t("Forgot your password ?")}
                              </span>
                            </Link>
                          ) : this.props.location.pathname ===
                            "/interviewboardlogin" ? (
                            <Link
                              to="/interview_forgot_password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock mr-1"></i>{" "}
                              <span style={{ color: "blue" }}>
                                {this.props.t("Forgot your password ?")}
                              </span>
                            </Link>
                          ) : this.props.location.pathname ===
                            "/lawadminlogin" ? (
                            <Link
                              to="/lawadmin_forgot_password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock mr-1"></i>{" "}
                              <span style={{ color: "blue" }}>
                                {this.props.t("Forgot your password ?")}
                              </span>
                            </Link>
                          ) : this.props.location.pathname ===
                            "/competentauthoritylogin" ? (
                            <Link
                              to="/competentauthority_forgot_password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock mr-1"></i>{" "}
                              <span style={{ color: "blue" }}>
                                {this.props.t("Forgot your password ?")}
                              </span>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4} />
            </Row>
          </div>
        </div>

        <Modal
          isOpen={this.state.manageEligibilityModal}
          toggle={this.toggleEligibilityModal}
          size="xl"
        >
          <ModalHeader toggle={this.toggleEligibilityModal}>
            {this.props.t("EligibilityCriteria")}
          </ModalHeader>

          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.EligibilityContent
                  ? this.state.EligibilityContent.Content
                  : "",
              }}
            ></div>

            <br />
            {this.props.t("EligibilityConfirmation")}
          </ModalBody>

          <ModalFooter>
            <Button
              color="success"
              className="form-second-btn"
              onClick={() => {
                this.props.history.push("/register");
              }}
            >
              Proceed
            </Button>
            <Button
              color="danger"
              className="form-second-btn"
              onClick={() => this.toggleEligibilityModal()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(withNamespaces()(Login))
);
