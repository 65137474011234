import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import DropDown from "../../components/custom/DropDown";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import {
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { settings, district } from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
import { SettingsValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ListPageHeader from "../../components/custom/ListPageHeader";
import { init } from "echarts";
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      SettingList: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      Language: sessionStorage.getItem("lng"),
      initialValues: {},
      DisplayName: [],
      // Setting: {
      //   AllowNewApplications: "",
      //   AllowAreaExtensionApplications: "",
      //   AllowRenewalApplications: "",
      //   AllowDuplicateApplications: "",
      //   RenewalYears: "",
      //   AllowExistingNotaryApplications: "",
      // },
      SettingKeyValue: [],
      FormikName: [],
    };
  }

  componentDidMount() {
    this.GetAllSetting();
  }

  GetAllSetting() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      settings.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllSettingResponse
    );
  }

  GetAllSettingResponse = (data) => {
    if (data.pagination && data.result) {
      var key, value;

      value = data.result[0];

      var KeyAndValue = [];
      var initialValues = {};
      var Formik = [];
      var FormikArray = [];
      var displayName = [];

      for (key in value) {
        if (typeof value[key] === "object") {
          displayName.push(value[key].Label);
          FormikArray.push((Formik[key] = value[key].Value));
          // var Key = value[key]value

          initialValues[key] = value[key].Value;
          var arrayValue = value[key].Label + " : " + value[key].Value;
          KeyAndValue.push(arrayValue);

          // var name = value[key];
          // formikName.push(name);
        } else {
          arrayValue = key + " : " + value[key];
          KeyAndValue.push(arrayValue);
        }
      }
      var splitVal = [];
      for (let i = 0; i < KeyAndValue.length; i++) {
        splitVal.push(KeyAndValue[i].split(" : "));
      }

      this.setState(
        {
          Intial: initialValues,
          SettingsArray: KeyAndValue,
          SettingKeyValue: splitVal,
          FormikName: FormikArray,
          isLoading: false,
          totalPage: data.pagination.totalCount / data.pagination.pageLimit,
          SettingList: data.result,
          DisplayName: displayName,
          totalCount: data.pagination.totalCount,
        },
        () => {
          this.GetOneSetting();
        }
      );
    } else
      this.setState({
        isLoading: false,
      });
  };
  GetOneSetting() {
    CallService(
      settings.GetOne + this.state.SettingList[0]._id,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneSettingResponse
    );
  }

  GetOneSettingResponse = (data) => {
    if (data.result) {
      this.setState({
        Setting: data.result,
      });
    }
  };
  addBtnClick = () => {
    this.toggleManageModal();
  };
  toggleManageModal = () => {
    this.setState({
      manageModal: !this.state.manageModal,
    });
  };

  handleSubmit = (values) => {
    var settingValue = this.state.SettingList[0];

    Object.keys(settingValue).map((key, i) => {
      if (typeof settingValue[key] === "object") {
        return (settingValue[key].Value = values[key]);
      }
    });

    // var handleValues = values;
    // handleValues[values.key] = values.value;
    // delete handleValues.key;
    // delete handleValues.value;
    // console.log(values);

    CallService(
      settingValue._id ? settings.Update + settingValue._id : settings.Create,
      settingValue._id ? MethodType.PATCH : MethodType.PUT,
      false,
      settingValue,
      "",
      this.handleSubmitResponse
    );
  };
  handleSubmitResponse = (data) => {
    this.setState({
      buttonAction: false,
    });

    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.state.Setting._id
          ? "Settings Updated Successfully"
          : "Settings Created Successfully"
      );
      this.GetAllSetting();
    }
  };
  toggleArchivedPreviousYear = () => {
    this.setState({
      ArchivedPreviousYear: !this.state.ArchivedPreviousYear,
    });
  };
  handlePreviousYear() {
    CallService(
      settings.Archive,
      MethodType.GET,
      false,
      "",
      "",
      this.handlePreviousYearResponse
    );
  }

  handlePreviousYearResponse = (data) => {
    if (data.statusCode === "200") {
      toastr.success(
        "",
        "Archive Previous Year Applications disapear successfully"
      );
    } else if (data.exception) {
      toastr.error(data.exception);
    }
    this.toggleArchivedPreviousYear();
  };
  render() {
    const initialValues = this.state.Intial ? this.state.Intial : {};

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid mt-4">
            <Card>
              <CardBody>
                <ListPageHeader
                  heading={`${this.props.t("Home")}.${this.props.t(
                    "Settings"
                  )}`}
                  showSearch={false}
                  onTextChange={this.SearchQueryChanged}
                  // buttonClick={this.addBtnClick}
                  showButton={false}
                  searchValue={this.state.ListRequestModel.searchString}
                  //    buttonText={this.props.t("Add New")}
                />
                <Row>
                  <Colxx style={{ float: "right" }} xxs="4"></Colxx>
                  <Colxx xxs="6">
                    <Button
                      onClick={() => this.toggleArchivedPreviousYear()}
                      outline
                      color="primary"
                      style={{ float: "right" }}
                    >
                      {this.props.t("Archive Previous Year Applications")}
                    </Button>
                  </Colxx>
                  {/* <Colxx xxs="2">
                    <Button onClick={() => this.addBtnClick()} color="primary">
                      {this.props.t("Add New")}
                    </Button>
                  </Colxx> */}
                </Row>
                <br></br>

                <Formik
                  initialValues={initialValues}
                  validationSchema={SettingsValidation}
                  onSubmit={this.handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Fragment>
                        {Object.keys(initialValues).map((key, i) => {
                          return (
                            <Row className="mb-10">
                              <Colxx xxs="2"></Colxx>
                              <Colxx xxs="4">
                                <Label className="requiredField">
                                  {this.state.DisplayName.map((display, j) => {
                                    if (j === i) {
                                      return display;
                                    }
                                  })}
                                </Label>
                              </Colxx>

                              <Colxx xxs="4">
                                <FormGroup className="form-group has-float-label">
                                  <Field
                                    type={
                                      initialValues[key] === true ||
                                      initialValues[key] === false
                                        ? "checkbox"
                                        : "text"
                                    }
                                    autoFocus
                                    className={
                                      initialValues[key] === true ||
                                      initialValues[key] === false
                                        ? ""
                                        : "form-control"
                                    }
                                    name={key}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="2"></Colxx>
                            </Row>
                          );
                        })}

                        {/* {this.state.FormikName.map((item, i) => {
                          
                          

                          if (
                            item.Label === "_id" ||
                            item.Label === "createdAt" ||
                            item.Label === "updatedAt"
                          ) {
                            // No Empty Space
                          } else {
                            return (
                              <Row className="mb-10">
                                <Colxx xxs="2"></Colxx>
                                <Colxx xxs="4">
                                  <Label className="requiredField">
                                    {this.props.t(item.Label)}
                                    {console.log(item)}
                                    {/* {item[0].replace(/([A-Z])/g, " $1").trim()}
                                  </Label>
                                </Colxx>

                                <Colxx xxs="4">
                                  <FormGroup className="form-group has-float-label">
                                    <Field
                                      type={
                                        item && item.Value === true
                                          ? "checkbox"
                                          : "text"
                                      }
                                      autoFocus
                                      className={
                                        item && item.Value === true
                                          ? ""
                                          : "form-control"
                                      }
                                      //  name={this.state.SettingList[item]}
                                      //   name={item[0]}
                                      name={item}
                                      //  value={item.Value}
                                    />
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="2"></Colxx>

                                {/* {console.log(
                                  this.state.SettingsArray.map((s, i) => {
                                    return s[0];
                                  })
                                )}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item[1],
                                  }}
                                />
                              </Row>
                            );
                          }
                        })} */}
                        {/* {this.state.SettingKeyValue.map((item, i) => {
                          if (
                            item[0] === "_id" ||
                            item[0] === "createdAt" ||
                            item[0] === "updatedAt"
                          ) {
                            // No Empty Space
                          } else {
                            return (
                              <Row className="mb-10">
                                <Colxx xxs="2"></Colxx>
                                <Colxx xxs="4">
                                  <Label className="requiredField">
                                    {this.props.t(item[0])}
                                    {/* {item[0].replace(/([A-Z])/g, " $1").trim()}  
                                  </Label>
                                </Colxx>
                                <Colxx xxs="4">
                                  <FormGroup className="form-group has-float-label">
                                    <Field
                                      type={
                                        item[1] === "true" || item === "false"
                                          ? "checkbox"
                                          : "text"
                                      }
                                      autoFocus
                                      className={
                                        item[1] === "true" || item === "false"
                                          ? ""
                                          : "form-control"
                                      }
                                      // name={this.state.FormikName[i]}
                                      //  value={item[1]}
                                      //    name={this.state.SettingsArray[i]}
                                      // name={this.state.Setting}
                                      //  name={this.state.SettingList[item]}
                                      // name={item[0]}
                                      // name={this.state.FormikName[i]}
                                    />
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="2"></Colxx>

                                {/* <div
                                  dangerouslySetInnerHTML={{
                                    __html: item[1],
                                  }}
                                /> 
                              </Row>
                            );
                          }
                        })}{" "} */}
                        {/* <Row className="mb-10">++
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="4">
                            <Label className="requiredField">
                              &nbsp; {this.props.t("Allow New Applications")}
                            </Label>
                          </Colxx>
                          <Colxx xxs="4">
                            <FormGroup className="form-group has-float-label">
                              <FormGroup className="form-group has-float-label">
                                <Field
                                  type="checkbox"
                                  autoFocus
                                  name="AllowNewApplications"
                                />
                              </FormGroup>
                              {errors.AllowNewApplications && (
                                <div className="invalid-feedback d-block">
                                  {errors.AllowNewApplications}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row> */}
                      </Fragment>
                      <Row>
                        <Colxx xxs="9"></Colxx>
                        <Colxx xxs="2">
                          <FormGroup>
                            <Button
                              className={
                                this.state.buttonAction ? "disabled" : ""
                              }
                              type="submit"
                              outline
                              color="primary"
                            >
                              {this.props.t("Update")}
                            </Button>
                          </FormGroup>
                        </Colxx>
                      </Row>
                    </Form>
                  )}
                </Formik>
                {/* 
                <ListPage
                  columns={this.state.columns}
                  data={this.state.SettingList}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                /> */}
              </CardBody>
            </Card>

            {/* <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.props.t("Create Settings")}
              </ModalHeader>
              <Formik
                initialValues={initialValues}
                //  validationSchema={SettingsValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row className="mb-10">
                          <Colxx xxs="1"></Colxx>
                          <Colxx xxs="2">
                            <Label> {this.props.t("Label")}</Label>
                          </Colxx>
                          <Colxx xxs="8">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="text"
                                className="form-control"
                                name="key"
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="1"></Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="1"></Colxx>
                          <Colxx xxs="2">
                            <Label> {this.props.t("Value")}</Label>
                          </Colxx>
                          <Colxx xxs="8">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="text"
                                className="form-control"
                                name="value"
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="1"></Colxx>
                        </Row>
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        {/* {this.state.Setting._id ? (
                          // <Button
                          //   className={
                          //     this.state.buttonAction ? "disabled" : ""
                          //   }
                          //   type="submit"
                          //   outline
                          //   color="primary"
                          // >
                          //   {this.props.t("Update")}
                          // </Button>
                        ) : (
                        <Button
                          className={this.state.buttonAction ? "disabled" : ""}
                          type="submit"
                          outline
                          color="primary"
                        >
                          {this.props.t("Create")}
                        </Button>
                        {/* // )}
                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleManageModal()}
                        >
                          {this.props.t("Cancel")}
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal> */}

            <Modal
              isOpen={this.state.ArchivedPreviousYear}
              toggle={this.toggleArchivedPreviousYear}
            >
              <ModalHeader toggle={this.toggleArchivedPreviousYear}>
                {this.props.t("Archived Previous Year")}
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5 className="pr-2 pt-2">
                        All the applitions will disappear from notary
                        application list. Are you sure you want to archive all
                        the applications?
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right">
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={() => this.handlePreviousYear()}
                  >
                    {this.props.t("Yes")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleArchivedPreviousYear()}
                  >
                    {this.props.t("No")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(Settings));
