import React from "react";
import { Container, Row, Col } from "reactstrap";
import footerlogo from "../../assets/images/TNeGA.png";
import RulesFile from "../../assets/images/The Notaries Rules 1956.pdf";

const Footer = () => {
  return (
    <React.Fragment>
      {/* <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Law Admin.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                <img
                  alt="tnega"
                  src={logofull}
                  style={{ width: "25px", objectFit: "contain" }}
                />
                <u>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://tnega.tn.gov.in/"
                    target="blank"
                  >
                    {" "}
                    TNeGA
                  </a>
                </u>
              </div>
            </Col>
          </Row>
        </Container>
      </footer> */}

      <div className="homePageFooter">
        <div className="footerContainer">
          <Row className="justify-content-around m-0">
            <Col lg="3" xxs="12" className="text-align-justify">
              <h4 className="mt-2" style={{ color: "white" }}>
                {" "}
                Contact Info
              </h4>
              <span className="d-block">
                <img
                  style={{ width: "15%" }}
                  alt="Profile"
                  src="/assets/images/logo.png"
                  className="footerLogo"
                />
              </span>
              {/* <span className="d-block">
                Government of Tamil Nadu - Law Department
              </span> */}

              <span className="d-block">Deputy Secretary to Government,</span>
              <span className="d-block"> Law(admin) Department,</span>

              <span className="d-block">Fort St George,</span>

              {/* <span className="d-block">
                {" "}
                P.T.LEE Chengalvaraya Naicker Building,
              </span> */}
              <span className="d-block">Tamil Nadu </span>
              {/* <span className="d-block">
                Chennai - 600 002.Opt. LIC Building
              </span> */}
              <span className="d-block">Chennai - 600009</span>
              {/* <span className="d-block">Fax:+91-44-28521112,</span> */}

              {/* <span className="d-block"> Phone No : 044-0566 5765</span> */}
              <span className="d-block"> Phone No : 044-2567 5418</span>
              <span className="d-block">
                {" "}
                Email: tnlawadmnnodalofficer@gmail.com{" "}
              </span>
              {/* 
              <span className="d-block">E-Mail ID: tnega@tn.gov.in</span> */}
              <span className="d-block"></span>
              {/* <span className="d-block">
                Website:&nbsp;
                <a
                  href=" https://tnega.tn.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tnega.tn.gov.in
                </a>
              </span> */}
            </Col>
            <Col lg="3" xxs="12" className="text-align-justify">
              <h4 className="mt-2" style={{ color: "white" }}>
                {" "}
                Quick Links
              </h4>
              {/* <span className="d-block">
                <a
                  href="https://www.tn.gov.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Government of Tamil Nadu
                </a>
              </span> */}
              <span className="d-block">
                <a href="#/About">About Us</a>
              </span>
              <span className="d-block">
                <a href="#/Contact">Contact Us</a>
              </span>
              <span className="d-block">
                <a href="#/FAQ">FAQ</a>
              </span>
              <span className="d-block">
                <a
                  href="https://www.indiacode.nic.in/bitstream/123456789/2172/1/a1952-53.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Notaries Act, 1952
                </a>
              </span>
              <span className="d-block">
                <a href={RulesFile} target="_blank" rel="noopener noreferrer">
                  The Notaries Rules, 1956
                </a>
              </span>
            </Col>

            <Col lg="3" xxs="12" className="text-align-justify">
              <h4 className="mt-2" style={{ color: "white" }}>
                {" "}
                Important Links
              </h4>
              <span className="d-block">
                <a
                  href="https://www.tn.gov.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Government of Tamil Nadu
                </a>
              </span>
            </Col>
            {/* <Col lg="3" xxs="12" className="text-align-justify">
                <h4 className="mt-2" style={{color:"white"}}>  Useful Links</h4>
                <span className="d-block"><a href="https://it.tn.gov.in" target="_blank" rel="noopener noreferrer">Department Portal </a></span>
                <span className="d-block"><a href="https://tn.gov.in" target="_blank" rel="noopener noreferrer">State Portal </a></span>
                <span className="d-block"><a href="https://meity.gov.in" target="_blank" rel="noopener noreferrer">GoI Meity Portal </a></span>
                <span className="d-block"><a href="https://elcot.in" target="_blank" rel="noopener noreferrer">ELCOT </a></span>
                {/* <span className="d-block"><a href="https://tnega.tn.gov.in" target="_blank" rel="noopener noreferrer">TNeGA </a></span> 
              </Col> */}
          </Row>
          <Row className="text-align-left fusion-footer-copyright-area m-0">
            <Col lg="10" className="m-1">
              <div className="text-sm-right d-none d-sm-block">
                <p>
                  Powered by{" "}
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://tnega.tn.gov.in/"
                    target="blank"
                  >
                    <img
                      alt="tnega"
                      src={footerlogo}
                      style={{ width: "25px", objectFit: "contain" }}
                    />
                    TNeGA
                  </a>
                </p>
              </div>
            </Col>
            {/* <div className="to-top-container to-top-right"><a href="#" id="toTop" ><span id="toTopHover" ></span><span > <i className="simple-icon-arrow-up"></i> </span></a></div> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
