import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

// users
import user1 from "../../../assets/images/users/user.png";
import { CallService } from "../../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../../constants/defaultValues";
import { profile } from "../../../constants/config";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name,
      UserName:
        sessionStorage.getItem("AdvocateName") === "undefined"
          ? JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name
          : sessionStorage.getItem("AdvocateName"),
      CopCopy: sessionStorage.getItem("CoPCopy"),
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.displayName });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.username });
      }
    }
  }
  logout = () => {
    CallService(
      profile.logout,
      MethodType.POST,
      false,
      {},
      "",
      this.logoutResponse
    );
  };
  logoutResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      sessionStorage.clear();
      this.props.history.push("/logout");
      window.location.reload();
    }
  };
  profile = () => {
    this.props.history.push("/userprofile");
  };
  editNotary = () => {
    this.props.history.push("/existing_notaryapplication");
  };
  profilePdj = () => {
    this.props.history.push("/changepassword");
  };
  changeMobileNumber = () => {
    this.props.history.push("/changeMobileNumber");
  };
  render() {
    return (
      <React.Fragment>
        {/* {sessionStorage.getItem("IsProfileCompleted") === "true" ||
        sessionStorage.getItem("IsProfileCompleted") === "undefined" ? ( */}
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
            {this.state.name === "advocate" ? (
              <span className="text-white d-none d-xl-inline-block ml-2 mr-1">
                {this.state.UserName}
              </span>
            ) : (
              <span className="text-white d-none d-xl-inline-block ml-2 mr-1">
                {this.state.name}
              </span>
            )}
            <i className="text-white mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu>
            {/* <DropdownItem tag="a" href="/profile">
             <i className="bx bx-user font-size-16 align-middle mr-1"></i>
             {this.props.t("Profile")}
           </DropdownItem> */}
            {/* <DropdownItem tag="a" href="/crypto-wallet">
                           <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
                           {this.props.t('My Wallet')}
                       </DropdownItem>
                       <DropdownItem tag="a" href="#">
                           <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                           {this.props.t('Settings')}
                       </DropdownItem> */}
            {/* <DropdownItem tag="a" href="auth-lock-screen">
                           <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{this.props.t('Lock screen')}
                       </DropdownItem> */}
            {/* <div className="dropdown-divider"></div> */}
            {JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "advocate" ? (
              <div>
                {sessionStorage.getItem("IsProfileCompleted") === "false" ? (
                  ""
                ) : (
                  <DropdownItem onClick={this.profile}>
                    <i className="bx bx-user  font-size-16 align-middle mr-1 text-primary"></i>
                    <span>{this.props.t("Profile")}</span>
                  </DropdownItem>
                )}

                {sessionStorage.getItem("ExistingNotaryApplications") === "false" ? (
                  ""
                ) : (
                  <DropdownItem onClick={this.editNotary}>
                    <i className="bx bx-book-bookmark font-size-16 align-middle mr-1 text-primary"></i>
                    <span>{this.props.t("Existing Notary")}</span>
                  </DropdownItem>
                )}

                {this.state.CopCopy ? (
                  <DropdownItem
                  //onClick={this.changeMobileNumber}
                  >
                    <i className="bx bx-download font-size-16 align-middle mr-1 text-primary"></i>
                    <a
                      className="LinkStyle"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.CopCopy}
                    >
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {this.props.t("Certificate of Practice")}
                      </span>
                    </a>
                  </DropdownItem>
                ) : (
                  ""
                )}
                {sessionStorage.getItem("IsProfileCompleted") === "false" ? (
                  ""
                ) : (
                  <DropdownItem onClick={this.changeMobileNumber}>
                    <i className="bx bx-mobile font-size-16 align-middle mr-1 text-primary"></i>
                    <span>{this.props.t("Change Mobile / Email ID")}</span>
                  </DropdownItem>
                )}
              </div>
            ) : (
              ""
            )}

            {(JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "pdj") ||
              (JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "interview_board") ||
              (JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "law_admin") ? (
              <DropdownItem onClick={this.profilePdj}>
                <i className="bx bx-user font-size-16 align-middle mr-1 text-primary"></i>
                <span>{this.props.t("Profile")}</span>
              </DropdownItem>
            ) : (
              ""
            )}

            <DropdownItem onClick={this.logout}>
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>{this.props.t("Logout")}</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {/* ) : (
          ""
        )} */}
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(ProfileMenu));
