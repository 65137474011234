import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import {
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { cms, interviewboard } from "../../constants/config";

import "toastr/build/toastr.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      Advocates: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      HtmlContent: "",

      columns: [
        {
          name: "UserName",
          selector: "UserName",
          sortable: false,
          cell: (row) => <span>{row.UserName ? row.UserName : ""}</span>,
        },

        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="danger"
                onClick={() => this.toggleDeleteModal(row)}
              >
                Delete
              </Button>
            </Row>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.GetCMS("faq");
  }

  GetCMS(search) {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = search;
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        HtmlContent: data.result && data.result[0],
      });
    }
  };

  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAllAdvocate();
      }
    );
  };

  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllAdvocate();
        }
      }
    );
  };

  addBtnClick = () => {
    this.toggleManageModal();
  };

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <CardBody>
                <br></br>
                <div
                  className="card-body accordion-content"
                  dangerouslySetInnerHTML={{
                    __html: this.state.HtmlContent
                      ? this.state.HtmlContent.Content
                      : "",
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default FAQ;
