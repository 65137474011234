import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
//i18n
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { Formik, Form, Field } from "formik";
import ListPage from "../../components/custom/ListPage";
import { CallService } from "../../helpers/ServiceCall";
import {
  roles,
  level,
  user,
  gender,
  district,
  settings,
} from "../../constants/config";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { officerValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import ListPageHeader from "../../components/custom/ListPageHeader";
import ViewDetails from "../../pages/ViewDetails/modal";
class ArchivedApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      isLoading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
        YearDate: null,
      },
      buttonAction: false,
      showProcesing: true,
      UpdateId: "",
      Language: sessionStorage.getItem("lng"),

      columns: [
        // {
        //   width: "5%",
        //   name: <strong>{this.props.t("Sl.No")}</strong>,
        //   selector: "Name",
        //   sortable: false,
        //   cell: (row, i) => <span>{i ? i : ""}</span>,
        // },
        {
          width: "21%",
          name: <strong>{this.props.t("Application Number")}</strong>,
          selector: "ApplicationNo",
          sortable: false,
          cell: (row) => (
            <span>{row.ApplicationNo ? row.ApplicationNo : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: true,
          cell: (row) => <span>{row.Applicant ? row.Applicant.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("District")}</strong>,
          selector: "District",
          sortable: true,
          cell: (row) => (
            <span>
              {row.District
                ? sessionStorage.getItem("lng") === "en"
                  ? row.District.Name
                  : row.District.TamilName
                : row.NotaryApplication
                ? row.NotaryApplication.District
                  ? sessionStorage.getItem("lng") === "ta"
                    ? row.NotaryApplication.District.TamilName
                    : row.NotaryApplication.District.Name
                  : ""
                : ""}
            </span>
          ),
        },

        {
          name: <strong>{this.props.t("Taluks")}</strong>,
          selector: "Taluks",
          sortable: true,
          cell: (row) => (
            <span>
              {row
                ? row.Taluks
                  ? row.Taluks.map((t) => {
                      return t
                        ? this.state.Language === "en"
                          ? t.Name
                          : t.TamilName
                        : "-";
                    }).join(",  ")
                  : row?.NotaryApplication?.Taluks
                  ? row?.NotaryApplication?.Taluks.map((t) => {
                      return t
                        ? this.state.Language === "ta"
                          ? t.TamilName
                          : t.Name
                        : "-";
                    }).join(",  ")
                  : "-"
                : "-"}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Submitted On")}</strong>,
          selector: "SubmittedOn",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn
                ? moment(new Date(row.SubmittedOn * 1000)).format(
                    `DD/MM/YYYY hh:mm a`
                  )
                : ""}
            </span>
          ),
        },
        {
          width: "7%",
          name: <strong>{this.props.t("View")}</strong>,
          selector: "View",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={() => this.toggleViewModal(row)}
              >
                View
              </Button>
            </Row>
          ),
        },
        {
          //  width: "27%",
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              {row.CanTakeAction && row.options.length ? (
                row.options.map((option) => {
                  return (
                    <Button
                      className="ml-2"
                      size="md"
                      outline
                      style={{
                        color: "#fff",
                        borderColor: option.buttonColor,
                        backgroundColor: option.buttonColor,
                      }}
                      onClick={() => {
                        this.setState(
                          {
                            Status: option.state,
                            buttonName: option.buttonName,
                          },
                          () => {
                            this.handleActions(row, option.state);
                          }
                        );
                      }}
                    >
                      {option.buttonName}
                    </Button>
                  );
                })
              ) : (
                <div>{row.Status}</div>
              )}
            </Row>
          ),
        },
      ],
      ArchivedList: [],
    };
  }
  componentDidMount() {
    this.GetAllDistrict();
    this.GetAllArchivedApplications();
  }

  GetAllArchivedApplications = () => {
    this.setState({
      isLoading: true,
    });

    let { ListRequestModel } = this.state;

    CallService(
      settings.GetAllArchived,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllArchivedApplicationsResponse
    );
  };
  GetAllArchivedApplicationsResponse = (data) => {
    if (data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        ArchivedList: data.result,
        totalCount: data.pagination.totalCount,
        showProcesing: false,
      });
    } else {
      this.setState({
        isLoading: false,
        showProcesing: false,
      });
    }
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }
  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };

  // handle Search string
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllArchivedApplications();
        }
      }
    );
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllArchivedApplications();
      }
    );
  };

  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      function () {
        this.GetAllArchivedApplications();
      }
    );
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllArchivedApplications();
      }
    );
  };
  toggleViewModal = (row) => {
    console.log(row);
    if (row) {
      var rowValues = row;
      rowValues.GenerateCertificate = "";
      this.setState({
        viewValues: rowValues,
        activeFirstTab: 1,
        viewModal: !this.state.viewModal,
      });
    } else {
      this.setState({
        viewModal: !this.state.viewModal,
      });
    }
  };

  render() {
    return (
      <div className="page-content">
        <div className="container-fluid">
          <Card className="mt-4">
            <CardBody>
              <ListPageHeader
                heading={`${this.props.t("Home")}.${this.props.t(
                  "Archived Applications"
                )}`}
                onTextChange={this.SearchQueryChanged}
                buttonClick={this.addBtnClick}
                showButton={false}
                searchValue={this.state.ListRequestModel.searchString}
                buttonText={this.props.t("Add New")}
              />
              <Row>
                {this.state.Role !== "pdj" && (
                  <Colxx xxs="4">
                    <DropDown
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      label={this.props.t("District")}
                      name="District"
                      isClearable
                      isSearchable
                      options={this.state.DistrictList}
                      ClearAction={() => {
                        let json = this.state.ListRequestModel;
                        json.District = null;
                        this.setState(
                          {
                            ListRequestModel: json,
                          },
                          () => {
                            this.GetAllArchivedApplications();
                          }
                        );
                      }}
                      Action={(entity) => {
                        let json = this.state.ListRequestModel;
                        json.District = entity ? entity.value : null;
                        this.setState(
                          {
                            ListRequestModel: json,
                            District: entity ? entity.value : null,
                          },
                          () => {
                            this.GetAllArchivedApplications();
                          }
                        );
                      }}
                      //   value={
                      //     this.state.ListRequestModel.District &&
                      //     this.state.DistrictsList.find(
                      //       (r) =>
                      //         r.value === this.state.ListRequestModel.District
                      //     )
                      //   }
                    />
                  </Colxx>
                )}
                <Colxx xxs="12" sm="4" lg="4" md="4">
                  <Label>{this.props.t("Year")}</Label>
                  <DatePicker
                    // autoComplete="off"
                    className="form-control"
                    isClearable={true}
                    selected={this.state.Date}
                    onChange={(date) => {
                      var result = this.state.ListRequestModel;
                      result.YearDate = date
                        ? new Date(date).getFullYear()
                        : null;
                      this.setState(
                        {
                          Date: date,
                          ListRequestModel: result,
                        },
                        () => {
                          this.GetAllArchivedApplications();
                        }
                      );
                    }}
                    placeholderText="Select..."
                    showYearPicker
                    dateFormat="yyyy"
                  />
                </Colxx>
              </Row>
              <ListPage
                className={"cursor-pointer"}
                columns={this.state.columns}
                data={this.state.ArchivedList}
                keyField={this.state.keyField}
                totalCount={this.state.totalCount}
                rowsPerPageOnChange={this.handlePerRowsChange}
                rowClicked={this.HandleRowClicked}
                pageChange={this.handlePageChange}
                overFlowXRemoval={true}
                onSort={this.handleSort}
                isDataLoading={this.state.isLoading}
              />
            </CardBody>
          </Card>

          <Modal
            size="xl"
            isOpen={this.state.viewModal}
            toggle={this.toggleViewModal}
          >
            <ModalHeader toggle={this.toggleViewModal}>
              {this.props.t("View Details")}
            </ModalHeader>

            <ModalBody>
              <Fragment>
                {<ViewDetails viewRowId={this.state.viewValues} />}
              </Fragment>
            </ModalBody>

            <ModalFooter>
              <FormGroup className="float-sm-right ">
                {this.state.viewValues &&
                this.state.viewValues.CanTakeAction &&
                this.state.viewValues.options.length
                  ? this.state.viewValues.options.map((option) => {
                      return (
                        <Button
                          outline
                          size="md"
                          className="mr-2"
                          style={{
                            backgroundColor: option.buttonColor,
                            borderColor: option.buttonColor,
                            color: "#fff",
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                Status: option.state,
                                buttonName: option.buttonName,
                              },
                              () => {
                                this.handleActions(
                                  this.state.viewValues,
                                  option.state
                                );
                              }
                            );
                          }}
                        >
                          {option.buttonName}
                        </Button>
                      );
                    })
                  : ""}

                <Button
                  color="danger"
                  className="mr-2"
                  onClick={() => this.toggleViewModal()}
                >
                  Close
                </Button>
              </FormGroup>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(withNamespaces()(ArchivedApplication));
