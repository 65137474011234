import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { Slide } from "react-slideshow-image";
import moment from "moment";
import lodash from "lodash";
// import { CKEditor } from "ckeditor4";
import JoditEditor from "jodit-react";
import {
  uploadFile,
  SetObjectPropertyVal,
  INVALID_CHARS,
  INVALID_CHARS_REGEX,
  checkIfPdf,
} from "../../helpers/Utils";
//i18n
import { withNamespaces } from "react-i18next";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";

import Select from "react-select";

import { Formik, Form, Field } from "formik";
import ListPage from "../../components/custom/ListPage";
import { CallService } from "../../helpers/ServiceCall";
import {
  district,
  advocate,
  community,
  fileUpload,
  session,
  newapplication,
  renewalApplication,
  areaExtensionApplication,
  duplicateCertificateApplication,
  interviewboard,
  user,
} from "../../constants/config";
import {
  MethodType,
  StatusCodes,
  STATUSES,
  TYPES,
} from "../../constants/defaultValues";
import { officerValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import ListPageHeader from "../../components/custom/ListPageHeader";
import { sheduleInterviewValidation } from "../../helpers/validations";
import ViewDetails from "../../pages/ViewDetails/modal";
import Form1 from "../Advocate/Form1";
import Form2 from "../Advocate/Form2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RenewalForm from "../Advocate/RenewalForm";
import DuplicateForm from "../Advocate/DuplicateForm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
import FileDisplay from "../../components/custom/FileDisplay";
import Logo from "../../assets/images/logo.png";
import LogoWaterMark from "../../assets/images/tn_water_mark.png";
let apis = {};

class ApplicationsList extends Component {
  constructor(props) {
    super(props);
    this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    this.state = {
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      isLoading: true,
      tabs: [],
      isOpenDatePopper: false,
      activeFirstTab: 1,
      DistrictsList: [],
      PDJList: [],
      SelectableRowList: [],
      loading: false,
      SelectableListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
        District: null,
      },
      toggledClearRows: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
        District: null,
        Date: null,
        Type: "List",
      },
      SessionDistricts: JSON.parse(sessionStorage.getItem("Distircts")),
      IsCheckbox: false,
      Sessions: [],
      actionsAPI: "",
      isBulk: false,
      state: "",
      bulkActionButtoncolor: "",
      isBulkActionRequested: false,
      bulkActionButtonName: "",
      buttonAction: false,
      showProcesing: true,
      UpdateId: "",
      CreateOfficer: {
        Name: "",
        MobileNumber: "",
        EmailID: "",
        Password: "",
        Designation: "",
        Areas: [],
        IsPasswordChanged: false,
      },
      multiArea: [""],
      OtherJson: {},
      AreaLevels: [
        {
          AreaList: [],
          SelectedAreaLevel: {},
        },
      ],

      BulkActionButtonDisabled: true,
      // JSON.parse(sessionStorage.getItem("user"))&&JSON.parse(sessionStorage.getItem("user")).Role&&JSON.parse(sessionStorage.getItem("user")).Role.Name === "interview_board"
      //   ? false
      //   : true,
      BulkActionButton: [],
      CheckBoxList: [],
      Language: sessionStorage.getItem("lng"),

      columns: [
        {
          width: "5%",
          name: <strong>{this.props.t("Sl.No")}</strong>,
          selector: "Name",
          sortable: false,
          cell: (row) => (
            <span>{row.SerialNumber ? row.SerialNumber : ""}</span>
          ),
        },
        {
          width: "19%",
          name: <strong>{this.props.t("Sub - Registration Number")}</strong>,
          selector: "SRApplicationNo",
          sortable: false,
          cell: (row) => (
            <span>{row.SRApplicationNo ? row.SRApplicationNo : ""}</span>
          ),
        },
        {
          width: "19%",
          name: <strong>{this.props.t("Application Number")}</strong>,
          selector: "ApplicationNo",
          sortable: false,
          cell: (row) => (
            <span>{row.ApplicationNo ? row.ApplicationNo : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: true,
          cell: (row) => <span>{row.Applicant ? row.Applicant.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("District")}</strong>,
          selector: "District",
          sortable: true,
          cell: (row) => (
            <span>
              {typeof row.District === "object"
                ? sessionStorage.getItem("lng") === "en"
                  ? row.District.Name
                  : row.District.TamilName
                : row.NotaryApplication
                ? row.NotaryApplication.District
                  ? sessionStorage.getItem("lng") === "ta"
                    ? row.NotaryApplication.District.TamilName
                    : row.NotaryApplication.District.Name
                  : ""
                : ""}
            </span>
          ),
        },

        {
          name: <strong>{this.props.t("Taluks")}</strong>,
          selector: "Taluks",
          sortable: true,
          cell: (row) => (
            <span>
              {row
                ? row.Taluks
                  ? row.Taluks.map((t) => {
                      return t
                        ? this.state.Language === "en"
                          ? t.Name
                          : t.TamilName
                        : "-";
                    }).join(",  ")
                  : row.NotaryApplication && row.NotaryApplication.Taluks
                  ? row.NotaryApplication.Taluks.map((t) => {
                      return t
                        ? this.state.Language === "ta"
                          ? t.TamilName
                          : t.Name
                        : "-";
                    }).join(",  ")
                  : "-"
                : "-"}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Submitted On")}</strong>,
          selector: "SubmittedOn",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn
                ? moment(new Date(row.SubmittedOn * 1000)).format(
                    `DD/MM/YYYY hh:mm a`
                  )
                : ""}
            </span>
          ),
        },
        {
          width: "9%",
          name: <strong>{this.props.t("View")}</strong>,
          selector: "View",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={() => this.toggleViewModal(row)}
              >
                {this.props.t("View")}
              </Button>
            </Row>
          ),
        },
        {
          width: "27%",
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              {row.CanTakeAction && row.options.length
                ? row.options.map((option) => {
                    // debugger;
                    // if (
                    //   row.AdditionalDocuments.length > 0 &&
                    //   option.state === "Returned"
                    // ) {
                    //   // No use
                    // } else {
                    console.log("Action option", option, row);
                    return (
                      <Button
                        className="ml-2"
                        size="md"
                        outline
                        style={{
                          color: "#fff",
                          borderColor: option.buttonColor,
                          backgroundColor: option.buttonColor,
                        }}
                        onClick={() => {
                          this.setState(
                            {
                              Status: option.state,
                              buttonName: option.buttonName,
                            },
                            () => {
                              this.handleActions(row, option.state);
                            }
                          );
                        }}
                      >
                        {this.props.t(option.buttonName)}
                      </Button>
                    );
                  })
                : // })
                  (console.log("CanTakeAction", row),
                  (
                    <div>
                      {row.Status}

                      {/* {row.Status === "Approved" ? (
                    <Button
                      className="ml-2"
                      size="sm"

                      outline
                      color="primary"
                      onClick={() => {
                        this.handleGenerateCertificate(row);
                      }}
                    >
                      View Details
                    </Button>
                  ) : (
                    ""
                  )} */}
                    </div>
                  ))}
            </Row>
          ),
        },
      ],
      DesignationList: [],
      AreaList: [],
      applicationsList: [],
      ArearesultCheck: false,

      Role: "",
      ForwardedApplications: [],
      InterviewBatches: [
        {
          From: 1,
          To: undefined,
          Date: "",
          Location: "",
          Session: "",
        },
      ],
    };
  }

  componentDidMount() {
    console.log(
      "applicationsList,applicationsList",
      this.state.applicationsList
    );
    apis = this.props.apis;
    this.setTab(1);
    this.GetAllDistrict();
    //  this.GetAllApplication();
    this.getAllSession();
    if (JSON.parse(sessionStorage.getItem("user")).Role.Name === "law_admin")
      this.GetAllUser();
    this.setState({
      Role:
        JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name,
    });
  }
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    var filDis = data.result;
    let { ListRequestModel } = this.state;

    if (
      JSON.parse(sessionStorage.getItem("user")) &&
      JSON.parse(sessionStorage.getItem("user")).Role &&
      JSON.parse(sessionStorage.getItem("user")).Role.Name === "interview_board"
    ) {
      filDis = this.state.SessionDistricts;
      //  ListRequestModel.District = filDis.length > 0 ? filDis[0]._id : null;
    }

    if (sessionStorage.getItem("lng") === "ta") {
      this.setState(
        {
          DistrictsList: filDis.map(function (a) {
            return { value: a._id, label: a.TamilName };
          }),
        },
        () => {
          this.GetAllApplication();
        }
      );
    } else if (sessionStorage.getItem("lng") === "en") {
      this.setState(
        {
          DistrictsList: filDis?.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
        },
        () => {
          this.GetAllApplication();
        }
      );
    }
  };
  getAllSession() {
    CallService(
      session.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllSessionResponse
    );
  }

  GetAllSessionResponse = (data) => {
    this.setState({
      Sessions: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Action Taken"),
        },
      ],
    });
  };
  handleActions = (row, status) => {
    let mandatoryFields = [];
    let action = row.Actions.find((obj) => obj.State === status);
    if (action) {
      let fields = action.Fields.map((act) => {
        let field = act;
        if (act.InputType === "TextArea") field.component = "textarea";
        else field.type = act.InputType.toLowerCase();
        if (act.IsMandatory) mandatoryFields.push(act.Key);
        return field;
      });
      console.log("fields", fields, row, status);
      this.setState({ fields: fields, mandatoryFields });
    }
    this.setState(
      {
        ApplicationId: row._id,
        Status: status,
        ButtonName: this.state.buttoName,
        ButtonColor: action.ButtonColor,
        isSingle: true,
        isBulkActionRequested: false,
      },
      () => {
        if (action.Fields.length) {
          this.toggle();
          this.setState({ OtherJson: {} });
        } else this.toggleConfirm();
      }
    );
  };
  toggleConfirm = () => {
    this.setState({
      confirmModal: !this.state.confirmModal,
    });
  };
  fileUpload = (values) => {
    console.log("values", values);
    if (values.GO || values.Letter) {
      this.setState({ loading: true, CopyToPDJ: values.CopyToPDJ });
      console.log("values", values);
      let formData = new FormData();
      if (values.GO) {
        formData.append("GO", values.GO);
      } else {
        formData.append("Letter", values.Letter);
      }
      CallService(
        fileUpload.upload,
        MethodType.POST,
        false,
        formData,
        "",
        this.uploadFileResponse,
        true
      );
    } else {
      this.toggle(values);
    }
  };
  uploadFileResponse = (data) => {
    if (data.result) {
      var url = data.result;
      this.toggle(url);
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload Failed");
    }
  };
  toggle = (values) => {
    console.log("valuesvaluesvalues", values);
    console.log("valuesvaluesvalues", this.state.mandatoryFields);
    let copyToPDJ = [];
    debugger;
    if (this.state.CopyToPDJ) {
      values = { ...values, CopyToPDJ: this.state.CopyToPDJ };
    }
    if (values?.CopyToPDJ) {
      copyToPDJ = values.CopyToPDJ.map((item) => item.value);
      values = { ...values, CopyToPDJ: copyToPDJ };
    }
    console.log("valuesvaluesvalues", values);
    this.setState({
      loading: false,
      OtherJson: values,
    });
    let filled = true;
    if (
      this.state.confirmationFieldsModal &&
      this.state.mandatoryFields.length
    ) {
      console.log("this.state.mandatoryFields", this.state.mandatoryFields);
      console.log("this.state.OtherJson", this.state.OtherJson);
      this.state.mandatoryFields.map((field) => {
        if (filled) {
          if (!this.state.OtherJson[field]) filled = false;
        }
        return true;
      });
      if (!filled) toastr.warning("", "Please fill all the mandatory fields.");
    }
    if (filled && this.state.confirmationFieldsModal) {
      this.toggleConfirm();
      this.setState({
        confirmationFieldsModal: !this.state.confirmationFieldsModal,
      });
    } else if (!this.state.confirmationFieldsModal)
      this.setState({
        confirmationFieldsModal: !this.state.confirmationFieldsModal,
      });
  };
  handleBulkActions = (buttonLabel) => {
    let action = {};
    let mandatoryFields = [];
    // this.state.applicationsList.map((doc) => {
    //   if (!action || !action.Fields)
    //     action = doc.Actions.find((obj) => obj.IsBulk);
    //   return true;
    // });
    if (buttonLabel) {
      let fields = buttonLabel.Fields.map((act) => {
        let field = act;
        if (act.InputType === "TextArea") field.component = "textarea";
        else field.type = act.InputType.toLowerCase();
        if (field.IsMandatory) mandatoryFields.push(field.Key);
        return field;
      });
      this.setState({ fields: fields, mandatoryFields }, () => {
        if (this.state.fields && this.state.fields.length)
          this.setState({
            bulkActionStatus: buttonLabel.State,
            bulkActionButtonName: buttonLabel.ButtonName,
            bulkActionButtoncolor: buttonLabel.ButtonColor,
            ButtonName: buttonLabel,
            confirmationFieldsModal: !this.state.confirmationFieldsModal,
          });
        else
          this.setState({
            bulkActionStatus: buttonLabel.State,
            bulkActionButtoncolor: buttonLabel.ButtonColor,
            bulkActionButtonName: buttonLabel.ButtonName,
            ButtonName: buttonLabel,
            confirmModal: !this.state.confirmModal,
          });

        //this.toggleConfirm();
      });
    }
  };
  toggleViewModal = (row) => {
    console.log("toggleViewModal", row);
    if (row) {
      var rowValues = row;
      rowValues.GenerateCertificate = "";
      this.setState({
        viewValues: rowValues,
        FormApplication: row,
        activeFirstTab: 1,
        viewModal: !this.state.viewModal,
      });
    } else {
      this.setState({
        viewModal: !this.state.viewModal,
      });
    }
  };

  GetAllApplication = () => {
    let { ListRequestModel } = this.state;
    this.setState({
      showProcesing: false,
      isLoading: true,
    });
    CallService(
      apis.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllApplicationResponse
    );
  };
  GetAllApplicationResponse = (data) => {
    debugger;
    console.log("GetAllApplicationResponse", data);
    if (data.result) {
      //#region has bulk actions

      let bulkActions = [];
      data.result.map((doc) => {
        let bulkActionsList = [];
        bulkActionsList = doc.Actions.filter((act) => act.IsBulk);
        console.log("bulkActionsList", bulkActionsList);
        bulkActions = [...bulkActions, ...bulkActionsList];
        console.log("bulkActions", bulkActions);

        return true;
      });
      bulkActions = lodash.uniqBy(bulkActions, "ButtonName");

      if (bulkActions.length) {
        var checkBoxList = [];

        checkBoxList = bulkActions
          .filter(
            (btn) =>
              btn.State === "Interview Cleared" ||
              btn.State === "Interview Failed" ||
              btn.ButtonName === "Selective Recommend"
          )
          .map((filteredPerson) => filteredPerson);

        var disabledAction = true;

        if (checkBoxList.length) {
          disabledAction = false;
          if (this.state.Role === "interview_board") {
            if (this.state.District) {
              var isCheckbox = true;
            }
          } else if (this.state.Role === "pdj") {
            isCheckbox = true;
          }
        }

        this.setState({
          isLoading: false,
          IsCheckbox: isCheckbox,
          BulkActionButtonDisabled: disabledAction,
          BulkActionButton: bulkActions,
          CheckBoxList: checkBoxList,
        });
      } else {
        this.setState({
          BulkActionButton: bulkActions,
        });
      }
      debugger;
      //#region individual actions
      data.result = data.result.map((obj, index) => {
        let options = obj.Actions.filter((a) => !a.IsBulk);

        if (options.length > 0) {
          options = options.filter((a) =>
            a.State === "Returned"
              ? a.State === "Returned" && obj.AdditionalDocuments.length === 0
              : a.State !== "Returned"
          );
        }

        obj.options = options.map((op) => ({
          state: op.State,
          buttonName: op.ButtonName,
          buttonColor: op.ButtonColor,
          fields: op.Fields,
        }));
        // obj.SerialNumber = index + 1;
        // return obj;

        obj.SerialNumber = data.pagination.skipCount + 1 + index;
        return obj;
      });

      //  if (JSON.parse(sessionStorage.getItem("user"))&&JSON.parse(sessionStorage.getItem("user")).Role&&JSON.parse(sessionStorage.getItem("user")).Role.Name === "interview_board") {

      // var district = data.result.map(function(a) {
      //   if (sessionStorage.getItem("lng") === "ta") {
      //     return {
      //       value: a.District._id,
      //       label: a.District.TamilName,
      //     };
      //   } else {
      //     return {
      //       value: a.District._id,
      //       label: a.District.Name,
      //     };
      //   }
      // });

      // for (var i = 0; i < district.length; i++) {
      //   var item = filterDistrictVal.filter(
      //     (a) => a.value.indexOf(district[i].value) > -1
      //   );
      //   if (item.length === 0) {
      //     filterDistrictVal.push(district[i]);
      //   }
      // }
      //  }
      //#endregion
      console.log("results", data.result);
      this.setState(
        {
          //  DistrictsList: filterDistrictVal,
          isLoading: false,
          totalPage: data.pagination.totalCount / data.pagination.pageLimit,
          applicationsList: data.result,
          totalCount: data.pagination.totalCount,
          showProcesing: false,
          SelectableRowList: [],
        }
        // () => {
        //   if (filterDistrictVal.length === 0) {
        //     this.GetAllDistrict();
        //   }
        //   //   console.log(this.state.applicationsList);
        // }
      );
      //   console.log(this.state.applicationsList + " ," + data.result);
      // var interDistrict;
    } else {
      this.setState(
        {
          SelectableRowList: [],
          isLoading: false,
          showProcesing: false,
          applicationsList: data.result,
          isBulk: false,
          state: "",
        },
        () => {
          this.GetAllDistrict();
        }
      );
    }
  };

  /***** Handle ListPage Function Start  *****/

  // handle Search string
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllApplication();
        }
      }
    );
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };

  handlePerRowsChange = (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };
  /***** Handle ListPage Function End  *****/

  /***** Handle Selectable List Row Function Start  *****/

  handleSelectableSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        SelectableListRequestModel: {
          ...this.state.SelectableListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };
  GetAllUser = () => {
    let { ListRequestModel } = this.state;
    this.setState({
      showProcesing: false,
    });
    let payload = { ...ListRequestModel, pageLimit: 100000, pageNumber: 1 };
    CallService(
      user.GetAll,
      MethodType.POST,
      false,
      payload,
      "",
      this.GetAllUserResponse
    );
  };
  GetAllUserResponse = (data) => {
    if (data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        PDJList: data.result.map((item) => {
          return { label: item.Name, value: item._id };
        }),
        totalCount: data.pagination.totalCount,
        showProcesing: false,
      });
    } else {
      this.setState({
        isLoading: false,
        showProcesing: false,
      });
    }
  };
  handleSelectablePerRowsChange = (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        SelectableListRequestModel: {
          ...this.state.SelectableListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };
  handleSelectablePageChange = (page) => {
    this.setState(
      {
        pageNumber: page,
        SelectableListRequestModel: {
          ...this.state.SelectableListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllApplication();
      }
    );
  };

  /***** Handle Selectable List Row Function End  *****/

  bulkAction = () => {
    debugger;
    var SelectedButton = this.state.ButtonName;

    var state = SelectedButton?.State || this.state.Status;
    let role = this.state.Role;
    let api = "";
    let json = {};
    if (this.state.District) json["District"] = this.state.District;
    if (this.props.type === TYPES.REGISTRATION) {
      if (role === "pdj") {
        switch (state) {
          case STATUSES.Recommended: {
            if (SelectedButton.ButtonName === "Selective Recommend") {
              api = apis.SelectiveRecommended;
              if (this.state.SelectableRowList.length)
                json["ApplicationIds"] = this.state.SelectableRowList.map(
                  (obj) => obj._id
                );
              break;
            } else {
              api = apis.Recommend;
              break;
            }
          }

          case STATUSES.InterviewCallLetterSent: {
            api = apis.SendCallLetter;

            break;
          }

          default:
            api = "";
        }

        // this.getApplications(10000000);
      } else if (role === "law_admin") {
        switch (state) {
          case STATUSES.Forwarded: {
            api = apis.Forward;

            break;
          }
          case STATUSES.PaymentPending: {
            api = apis.EnablePayment;

            break;
          }
          case STATUSES.Approved: {
            api = apis.ApproveAll;

            break;
          }
          default:
            api = "";
        }
      } else if (role === "interview_board") {
        switch (state) {
          case STATUSES.InterviewCleared: {
            api = apis.PassAll;
            if (this.state.SelectableRowList.length)
              json["ids"] = this.state.SelectableRowList.map((obj) => obj._id);
            break;
          }
          case STATUSES.InterviewFailed: {
            api = apis.FailAll;

            break;
          }
          default:
            api = "";
        }
      }
    } else if (this.props.type === TYPES.RENEWAL) {
      if (role === "law_admin") {
        switch (state) {
          case STATUSES.Approved: {
            api = apis.Approve;

            break;
          }
          case STATUSES.Rejected: {
            api = apis.Reject;

            break;
          }
          case STATUSES.Forwarded: {
            api = apis.Forward;

            break;
          }
          case STATUSES.PaymentPending: {
            api = apis.EnablePayment;

            break;
          }
          default:
            api = "";
        }
      } else if (role === "pdj") {
        debugger;
        switch (state) {
          case STATUSES.Recommended: {
            api = apis.Recommend;

            break;
          }
          case STATUSES.NotRecommend: {
            api = apis.NotRecommend;

            break;
          }
          default:
            api = "";
        }
      }
    } else if (this.props.type === TYPES.DUPLICATE) {
      if (role === "law_admin") {
        switch (state) {
          case STATUSES.Approved: {
            api = apis.Approve;

            break;
          }
          case STATUSES.Rejected: {
            api = apis.Reject;

            break;
          }

          default:
            api = "";
        }
      }
    } else if (this.props.type === TYPES.EXTENSION) {
      if (role === "law_admin") {
        switch (state) {
          case STATUSES.Approved: {
            api = apis.Approve;

            break;
          }
          case STATUSES.Rejected: {
            api = apis.Reject;

            break;
          }
          case STATUSES.Forwarded: {
            api = apis.Forward;

            break;
          }
          case STATUSES.PaymentPending: {
            api = apis.EnablePayment;

            break;
          }
          default:
            api = "";
        }
      } else if (role === "pdj") {
        switch (state) {
          case STATUSES.Recommended: {
            api = apis.Recommend;

            break;
          }

          case STATUSES.NotRecommend: {
            api = apis.NotRecommend;

            break;
          }
          default:
            api = "";
        }
      }
    } else if (this.props.type === TYPES.EXISTINGNOTARY) {
      if (role === "law_admin") {
        switch (state) {
          case STATUSES.Approved: {
            api = apis.Approve;

            break;
          }
          case STATUSES.Rejected: {
            api = apis.Reject;

            break;
          }

          default:
            api = "";
        }
      }
    }
    if (this.props.type !== TYPES.REGISTRATION)
      json["ApplicationId"] = this.state.ApplicationId;
    this.state.fields.map((field) => {
      if (field.Key) json[field.Key] = this.state.OtherJson[field.Key];
      return null;
    });
    this.setState({ actionsAPI: api });
    console.log("json", json);
    this.updateStatus(this.state.actionsAPI, json);
  };

  getApplications = () => {
    let json = {
      pageNumber: 1,
      pageLimit: 100000,
      District: this.state.District,
    };

    CallService(
      apis.GetAll,
      MethodType.POST,
      false,
      json,
      "",
      this.getApplicationsResponse
    );
  };
  getApplicationsResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      let applications = data.result;
      applications = applications.slice(
        this.state.OtherJson.From - 1,
        this.state.OtherJson.To
      );

      let data1 = applications.map((app) => {
        return { _id: app._id, ...this.state.OtherJson };
      });
      this.updateStatus(apis.ScheduleInterview, {
        District: this.state.District,
        Data: data1,
      });
    } else {
      toastr.error("", "Something went wrong");
    }
  };
  updateStatus = (api, items) => {
    // var BulkItem = {};
    // BulkItem.Comments = items.Comments;
    // BulkItem.IsSelected = this.state.IsSelected;
    // console.log(BulkItem);

    CallService(
      api,
      MethodType.POST,
      false,
      items,
      "",
      this.updateStatusResponse
    );
  };
  updateStatusResponse = (data) => {
    this.setState({ isBulkActionRequested: false });
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Applications processed successfully");
    } else {
      toastr.error("", "Something went wrong");
    }
    this.setState({
      Date: null,
      viewModal: false,
      SelectableRowList: [],
      IsCheckbox: false,
      OtherJson: {},
    });
    this.GetAllApplication();
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllApplication();
        }
      }
    );
  };
  handleConfirm = () => {
    debugger;
    this.toggleConfirm();
    if (
      !this.state.isBulkActionRequested &&
      this.props.type === TYPES.REGISTRATION
    ) {
      // this.setState({
      //   isDataLoading: true,
      // })
      var confirmApplication = {};
      confirmApplication.ApplicationID = this.state.ApplicationId;
      confirmApplication.Status = this.state.Status;
      confirmApplication.OtherJson = this.state.OtherJson;

      CallService(
        apis.ChangeStatus,
        MethodType.POST,
        false,
        confirmApplication,
        "",
        this.handleConfirmResponse
      );
    } else {
      this.bulkAction();
    }
  };
  handleConfirmResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", `Application processed Successfully`);
      this.setState({
        viewModal: false,
        SelectableRowList: [],
        IsCheckbox: false,
        clearRows: true,
        applicationsList: [],
      });
      this.GetAllApplication();
    } else {
      toastr.error("", "Something went wrong");
    }
  };
  togglePhotoViewModal(img, title) {
    if (img) {
      this.setState({
        DocumentView: img,
        DocumentViewModal: !this.state.DocumentViewModal,
        ImageGallery: img,
        Title: title,
      });
    } else {
      this.setState({
        DocumentViewModal: !this.state.DocumentViewModal,
      });
    }
  }
  properties = {
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: false,

    onChange: (oldIndex, newIndex) => {
      this.setState({
        currentImage: this.state.ImageGallery,
      });
    },
  };

  closeModals = (modal) => {
    this.setState({
      [modal]: !this.state[modal],
      //  viewModal: false,
      Date: "",
    });
  };
  scheduleInterview = (values) => {
    if (this.state.scheduleInterview) {
      this.setState({ OtherJson: values }, () => this.getApplications());
    }
    this.setState({
      scheduleInterview: !this.state.scheduleInterview,
      scheduleButton: values,
    });
  };

  /* Each Row Selected function */
  handleCheckboxSelectedRow = (row) => {
    var SelectedRow = row.selectedRows;
    var newArray = [];
    var obj = {};
    SelectedRow.map((rowID) => {
      const rowId = rowID._id;
      const districtId = rowID && rowID.District && rowID.District._id;
      obj = { ApplicationID: rowId, District: districtId };
      newArray.push(obj);
    });

    if (row.selectedRows.length >= 1) {
      var disabledAction = true;
    } else {
      disabledAction = false;
    }
    this.setState({
      //  totalPage: data.pagination.totalCount / data.pagination.pageLimit,
      BulkActionButtonDisabled: disabledAction,
      selectableTotalCount: row.selectedCount,
      SelectableRowList: row.selectedRows,
      //  IsSelected: newArray,
    });
  };
  confirmMakingPayment = () => {
    this.setState({
      MakingPaymentModal: !this.state.MakingPaymentModal,
    });
  };
  handleMakingPayment() {
    var apis;
    this.props.location.pathname === "/notary_applications"
      ? (apis = newapplication)
      : this.props.location.pathname === "/area_extension_applications"
      ? (apis = areaExtensionApplication)
      : this.props.location.pathname === "/renewal_applications"
      ? (apis = renewalApplication)
      : this.props.location.pathname === "/duplicate_certificate_applications"
      ? (apis = duplicateCertificateApplication)
      : (apis = newapplication);

    CallService(
      apis.GetMaking + this.state.MakingPaymentId,
      MethodType.GET,
      false,
      "",
      "",
      this.handleMakingPaymentResponse
    );
  }

  handleMakingPaymentResponse = (data) => {
    if (data.result) {
      toastr.error("", "Application process successully");
    }
  };

  handleGenerateCertificate = (row) => {
    CallService(
      newapplication.ApprovedStatus + row._id,
      MethodType.GET,
      false,
      "",
      "",
      this.handleGenerateCertificateResponse
    );
  };

  handleGenerateCertificateResponse = (data) => {
    if (data.result) {
      var lastArray = data.result.length - 1;
      var generate = data.result[lastArray];
      generate.GenerateCertificate = "GenerateCertificate";
      console.log("generate", generate);
      this.setState({
        viewValues: generate,
        GenerateCertificateModal: !this.state.GenerateCertificateModal,
      });
    }
  };
  ConfirmGenerateCertificate = (row) => {
    if (row) {
      this.setState({
        GenerateCertificateModal: !this.state.GenerateCertificateModal,
      });
    } else {
      this.setState({
        GenerateCertificateModal: !this.state.GenerateCertificateModal,
      });
    }
  };

  GetForwardedApplications = () => {
    CallService(
      newapplication.GetAllForwardedApplications,
      MethodType.GET,
      true,
      "",
      this.state.District,
      this.GetForwardedApplicationsResponse
    );
  };

  GetForwardedApplicationsResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      let interviewBatches = this.state.InterviewBatches;
      interviewBatches[0].To = data.result.Data
        ? parseInt(data.result.Data.length)
        : 0;
      this.setState({
        ForwardedApplications: data.result.Data,
      });
    }
  };

  UpdateInterviewBatchFields = (batchIndex, propertyName, propertyVal) => {
    let interviewBatches = [...this.state.InterviewBatches];
    let batchModel = interviewBatches[batchIndex];
    SetObjectPropertyVal(propertyName, propertyVal, batchModel);
    interviewBatches[batchIndex] = batchModel;
    this.setState({
      InterviewBatches: interviewBatches,
    });
  };

  RemoveInterviewBatch = (batchIndex) => {
    let interviewBatches = this.state.InterviewBatches;
    interviewBatches.splice(batchIndex, 1);
    this.setState({
      InterviewBatches: interviewBatches,
    });
  };

  AddInterviewBatch = () => {
    let interviewBatches = this.state.InterviewBatches;
    let interviewBatcheslength = this.state.InterviewBatches.length;
    let prevIndexToVal = parseInt(
      interviewBatches[interviewBatcheslength - 1].To
    );
    if (prevIndexToVal < this.state.ForwardedApplications.length) {
      interviewBatches.push({
        From: parseInt(prevIndexToVal + 1),
        To: this.state.ForwardedApplications.length,
        Date: "",
        Location: "",
        Session: "",
      });
      this.setState({
        InterviewBatches: interviewBatches,
      });
    }
  };
  ValidateBatchField = (batchIndex, type, currentVal) => {
    var isValid = true;
    let interviewBatches = this.state.InterviewBatches;
    let interviewBatchModel = interviewBatches[batchIndex];
    if (type === "From") {
      if (parseInt(currentVal) > this.state.ForwardedApplications.length) {
        isValid = false;
      }
    } else if (type === "To") {
      if (parseInt(currentVal) > this.state.ForwardedApplications.length) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleScheduleInterview = (values) => {
    let interviewBatches = this.state.InterviewBatches;
    let requestObj = {};
    requestObj.District = this.state.District;
    let scheduleData = [];
    interviewBatches.map((interviewBatch) => {
      for (
        let i = parseInt(interviewBatch.From);
        i <= parseInt(interviewBatch.To);
        i++
      ) {
        scheduleData.push({
          _id: this.state.ForwardedApplications[i - 1],
          Session: interviewBatch.Session,
          Location: interviewBatch.Location,
          Date: interviewBatch.Date,
        });
      }
      return "";
    });
    if (scheduleData.length === this.state.ForwardedApplications.length) {
      requestObj.Data = scheduleData;
      var scheduledApis;
      this.state.scheduleButton &&
      this.state.scheduleButton.ButtonName === "Schedule All"
        ? (scheduledApis = newapplication.ScheduleInterview)
        : (scheduledApis = newapplication.ReScheduleInterview);

      CallService(
        // newapplication.ScheduleInterview,
        scheduledApis,
        MethodType.POST,
        false,
        requestObj,
        "",
        this.handleScheduleInterviewResponse
      );
    } else {
      toastr.error(
        "",
        "Interview should scheduled for all the applications. Kindly provide the batch details accordingly"
      );
    }
  };

  handleScheduleInterviewResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Interview scheduled successfully");
      this.setState({ scheduleInterview: false });
      this.GetAllApplication();
    }
  };

  toggleFormModal = () => {
    this.setState({
      FormModal: !this.state.FormModal,
    });
  };
  DownloadFormAsPDF = () => {
    const advocate = this.state.viewValues;
    const payment = this.state.viewValues.PaymentTransaction;
    const paymentAmt = this.state.viewValues.Payment;
    console.log("paymentAmt", paymentAmt, payment, advocate);
    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    y = y + 20;

    pdf.setFontSize(15);
    y = y + 20;
    const logoWidth = 80; // Adjust as needed
    const logoHeight = 80; // Adjust as needed

    const logoX = (pdf.internal.pageSize.width - logoWidth) / 2; // Center horizontally
    const logoY = (pdf.internal.pageSize.height - logoHeight) / 2; // Center vertically

    pdf.addImage(Logo, "PNG", logoX, 0, logoWidth, 0);

    //WaterMark logo
    pdf.addImage(
      LogoWaterMark,
      "PNG",
      (pdf.internal.pageSize.width - 400) / 2,
      (pdf.internal.pageSize.height - 400) / 2,
      400,
      400
    );
    pdf.setFont("helvetica", "bold");

    y = y + 55;
    pdf.text(
      "Government of Tamil Nadu - Law Department",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 15;

    pdf.text(
      "Web application for the Online Notary Appointment / Renewal of Practice",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 35;

    pdf.text("Payment Transaction Receipt", 230, y, null, null, "center");
    pdf.line(175, y + 5, 290, y + 5);
    y = y + 30;
    pdf.setLineDashPattern([1.5]);

    pdf.setFont("helvetica", "bold");
    pdf.text("Status :-", 350, y, null, null, "right"); // Align to the right
    pdf.setFont("helvetica", "normal");
    pdf.setFont("helvetica", "normal");
    pdf.text(paymentAmt?.Status || "-", 420, y, null, null, "right"); // Align to the left
    // pdf.setTextColor(0, 0, 0); // Reset text color to black
    // pdf.setFillColor(255, 255, 255);

    y = y + 30;
    pdf.text("Application Number", 50, y, null, null);
    pdf.text(this.state.viewValues.ApplicationNo, 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 30;
    pdf.text("Applicant Name", 50, y, null, null);
    pdf.text(this.state.viewValues.Applicant.Name, 200, y, null, null);
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    // pdf.text("Status", 50, y, null, null);
    // pdf.text(payment.Status, 200, y, null, null);
    // pdf.setLineDashPattern([1.5]);

    // pdf.line(190, y + 5, 300, y + 5);

    // y = y + 25;
    pdf.text("Application", 50, y, null, null);
    pdf.text(
      paymentAmt.ApplicationPath.replace(/([A-Z])/g, " $1").trim(),
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.text("District", 50, y, null, null);
    pdf.text(
      typeof advocate.District === "object"
        ? advocate.District.Name
        : advocate.District,
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.text("Order Id", 50, y, null, null);
    pdf.text(payment.OrderId.toString(), 200, y, null, null);

    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;

    pdf.text("Transaction Receipt Number", 50, y, null, null);
    pdf.text(payment.TransactionReceiptNumber.toString(), 200, y, null, null);

    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;

    pdf.text("Payment Amount", 50, y, null, null);
    pdf.text(
      paymentAmt.Amount.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
        .replace("₹", "")
        .replace(/\.\d*0$/, ""),
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.text("Transaction Date & Time", 50, y, null, null);

    pdf.text(
      payment
        ? formatDate(
            fromUnixTime(payment.TransactionDateTime),
            datePickerFormat
          )
        : "",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;
    if (payment.AdditionalInfo1) {
      pdf.text("Additional Info 1", 50, y, null, null);
      pdf.text(payment.AdditionalInfo1, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo2) {
      pdf.text("Additional Info 2", 50, y, null, null);
      pdf.text(payment.AdditionalInfo2, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo3) {
      pdf.text("Additional Info 3", 50, y, null, null);
      pdf.text(payment.AdditionalInfo3, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo4) {
      pdf.text("Additional Info 4", 50, y, null, null);
      pdf.text(payment.AdditionalInfo4, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo5) {
      pdf.text("Additional Info 5", 50, y, null, null);
      pdf.text(payment.AdditionalInfo5, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    pdf.save(
      payment ? payment.TransactionReceiptNumber + ".pdf" : "PaymentReceipt.pdf"
    );
  };
  addBtnClick = () => {
    console.log();
  };
  render() {
    const initialValues = this.state.CreateOfficer;
    console.log("this.state.applicationsList", this.state.applicationsList);
    return (
      <div className="page-content">
        <div
          className="container-fluid"
          style={{
            marginTop: "3%",
          }}
        >
          <ListPageHeader
            heading={`${this.props.t("Home")}.${this.props.t(
              this.props.title
            )}`}
            onTextChange={this.SearchQueryChanged}
            showButton={false}
            buttonClick={this.addBtnClick}
            searchValue={this.state.ListRequestModel.searchString}
          />
          <Row>
            {this.state.Role !== "pdj" && (
              <Colxx xxs="4">
                <DropDown
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  label={this.props.t("District")}
                  name="District"
                  isClearable
                  isSearchable
                  options={this.state.DistrictsList}
                  ClearAction={() => {
                    let json = this.state.ListRequestModel;
                    json.District = null;
                    this.setState(
                      {
                        SelectableRowList: [],
                        ListRequestModel: json,
                        District: null,
                        applicationsList: [],
                        clearRows: true,
                        isLoading: true,
                      },
                      () => {
                        this.GetAllApplication();
                      }
                    );
                  }}
                  Action={(entity) => {
                    let json = this.state.ListRequestModel;
                    json.District = entity ? entity.value : null;
                    this.setState(
                      {
                        isLoading: true,

                        applicationsList: [],
                        ListRequestModel: json,
                        District: entity ? entity.value : null,
                        IsCheckbox: entity ? true : false,
                      },
                      () => {
                        this.GetAllApplication();
                        this.GetForwardedApplications();
                      }
                    );
                  }}
                  value={
                    this.state.ListRequestModel?.District &&
                    this?.state?.DistrictsList?.find(
                      (r) => r.value === this.state.ListRequestModel.District
                    )
                  }
                />
              </Colxx>
            )}
            <Colxx xxs="12" sm="4" lg="4" md="4">
              <Label>{this.props.t("Date")}</Label>
              <DatePicker
                onInputClick={() => {
                  this.setState({
                    isOpenDatePopper: !this.state.isOpenDatePopper,
                  });
                }}
                onSelect={() => {
                  this.setState({
                    isOpenDatePopper: false,
                  });
                }}
                onClickOutside={() => {
                  this.setState({
                    isOpenDatePopper: false,
                  });
                }}
                open={this.state.isOpenDatePopper}
                popperPlacement="bottom"
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"], // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
                autoComplete="off"
                selected={this.state.Date}
                className="form-control"
                value={this.state.ListRequestModel.Date}
                showYearDropdown
                showMonthDropdown
                selectsStart
                isClearable={true}
                startDate={this.state.Date}
                // endDate={this.state.endDate}
                onChange={(date) => {
                  var result = this.state.ListRequestModel;
                  result.Date = new Date(date).getTime();
                  console.log("dtae", result.Date);
                  this.setState(
                    {
                      Date: date,
                      ListRequestModel: result,
                    },
                    () => {
                      this.GetAllApplication();
                    }
                  );
                  //  values.Dob = new Date(date).getTime();
                }}
                placeholderText="dd/mm/yyyy"
                name="startDate"
                dateFormat="dd/MM/yyyy"
                //  maxDate={new Date()}
              />
            </Colxx>
            <Colxx>
              {this.state.BulkActionButton
                ? (this.state.Role !== "pdj" && this.state.District) ||
                  this.state.Role === "pdj"
                  ? this.state.applicationsList &&
                    this.state.applicationsList.length > 0
                    ? this.state.BulkActionButton.map((btn) => {
                        return (
                          <Button
                            outline
                            size="md"
                            className={
                              btn.State === "Interview Cleared" ||
                              btn.State === "Interview Failed" ||
                              btn.ButtonName === "Selective Recommend"
                                ? this.state.BulkActionButtonDisabled
                                  ? "mr-2"
                                  : "disabled mr-2"
                                : btn.State === "Recommended"
                                ? this.state.SelectableRowList.length === 0
                                  ? "mr-2"
                                  : "disabled mr-2"
                                : "mr-2"
                            }
                            style={{
                              color: "#fff",
                              marginTop: "28px",
                              borderColor: btn.ButtonColor
                                ? btn.ButtonColor
                                : "green",
                              backgroundColor: btn.ButtonColor
                                ? btn.ButtonColor
                                : "green",
                            }}
                            color="success"
                            onClick={(e) => {
                              btn.State === "Interview Cleared" ||
                              btn.State === "Interview Failed" ||
                              btn.ButtonName === "Selective Recommend"
                                ? this.state.BulkActionButtonDisabled
                                  ? this.setState(
                                      {
                                        isBulkActionRequested: true,
                                      },
                                      () => {
                                        btn.State === STATUSES.Scheduled ||
                                        btn.State === STATUSES.Rescheduled
                                          ? this.scheduleInterview(btn)
                                          : this.handleBulkActions(btn);
                                      }
                                    )
                                  : console.log("asdf")
                                : btn.State === "Recommended"
                                ? this.state.SelectableRowList.length === 0
                                  ? this.setState(
                                      {
                                        isBulkActionRequested: true,
                                      },
                                      () => {
                                        btn.State === STATUSES.Scheduled ||
                                        btn.State === STATUSES.Rescheduled
                                          ? this.scheduleInterview(btn)
                                          : this.handleBulkActions(btn);
                                      }
                                    )
                                  : console.log("sfd")
                                : this.setState(
                                    {
                                      isBulkActionRequested: true,
                                    },
                                    () => {
                                      btn.State === STATUSES.Scheduled ||
                                      btn.State === STATUSES.Rescheduled
                                        ? this.scheduleInterview(btn)
                                        : this.handleBulkActions(btn);
                                    }
                                  );
                            }}
                          >
                            {this.props.t(btn.ButtonName)}
                          </Button>
                        );
                      })
                    : ""
                  : ""
                : ""}
            </Colxx>

            {/* {((this.state.isBulk && this.state.District) ||
              (this.state.Role !== "pdj" && this.state.IsBulk)) &&
            this.state.applicationsList &&
            this.state.applicationsList.length ? (
              <Colxx xxs="4">
                <br />
                <Button
                  size="md"
                  style={{
                    marginTop: "8px",
                    backgroundColor: this.state.bulkActionButtoncolor
                      ? this.state.bulkActionButtoncolor
                      : "green",
                  }}
                  color="success"
                  onClick={() => {
                    this.setState(
                      {
                        isBulkActionRequested: true,
                      },
                      () => {
                        this.state.state === STATUSES.Scheduled
                          ? this.scheduleInterview()
                          : this.handleBulkActions();
                      }
                    );
                  }}
                >
                  {this.state.bulkActionButtonName}
                </Button>
              </Colxx>
            ) : (
              ""
            )} */}
          </Row>
          {this.state.SelectableRowList &&
            this.state.SelectableRowList.length > 0 && (
              <Fragment>
                <br></br>{" "}
                <h5>
                  {" "}
                  <strong> Selected Applications </strong>
                </h5>
                <ListPage
                  className={"cursor-pointer"}
                  columns={this.state.columns}
                  data={this.state.SelectableRowList}
                  keyField={this.state.keyField}
                  totalCount={this.state.selectableTotalCount}
                  rowsPerPageOnChange={this.handleSelectablePerRowsChange}
                  pageChange={this.handleSelectablePageChange}
                  overFlowXRemoval={true}
                  onSort={this.handleSelectableSort}
                  isDataLoading={this.state.isLoading}
                />
              </Fragment>
            )}

          {this.state.SelectableRowList &&
            this.state.SelectableRowList.length > 0 && (
              <h5>
                {" "}
                <strong> All Applications </strong>
              </h5>
            )}

          <ListPage
            className={"cursor-pointer"}
            columns={this.state.columns}
            data={this.state.applicationsList}
            keyField={this.state.keyField}
            totalCount={this.state.totalCount}
            rowsPerPageOnChange={this.handlePerRowsChange}
            // rowClicked={this.HandleRowClicked}
            pageChange={this.handlePageChange}
            overFlowXRemoval={true}
            onSort={this.handleSort}
            isDataLoading={this.state.isLoading}
            selectableRows={
              this.state.CheckBoxList && this.state.CheckBoxList.length > 0
                ? this.state.Role === "interview_board" ||
                  this.state.Role === "pdj"
                  ? this.state.IsCheckbox
                  : false
                : false
            }
            clearSelectedRows={this.state.clearRows}
            //  selectableRows={true}
            onSelectedRowsChange={this.handleCheckboxSelectedRow}
          />

          <Modal
            size="xl"
            isOpen={this.state.viewModal}
            toggle={this.toggleViewModal}
          >
            <ModalHeader toggle={this.toggleViewModal}>
              {this.props.t("View Details")}
            </ModalHeader>
            <ModalBody>
              <Fragment>
                {this.state.Role === "law_admin" ||
                this.state.Role === "advocate" ? (
                  this.state.viewValues &&
                  this.state.viewValues.PaymentTransaction ? (
                    <Button
                      color="info"
                      className="mr-2 ml-2 float-sm-right"
                      onClick={() => this.DownloadFormAsPDF()}
                    >
                      {this.props.t("View Payment Receipt")}
                    </Button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {this.props.location.pathname === "/notary_applications" ? (
                  <Button
                    color="primary"
                    className="ml-2 float-sm-right"
                    onClick={() => this.toggleFormModal()}
                  >
                    {this.props.t("View Form")}
                  </Button>
                ) : this.props.location.pathname === "/renewal_applications" ? (
                  <Button
                    color="primary"
                    className="ml-2 float-sm-right"
                    onClick={() => this.toggleFormModal()}
                  >
                    {this.props.t("View Form")}
                  </Button>
                ) : this.props.location.pathname ===
                  "/duplicate_certificate_applications" ? (
                  <Button
                    color="primary"
                    className="ml-2 float-sm-right"
                    onClick={() => this.toggleFormModal()}
                  >
                    {this.props.t("View Form")}
                  </Button>
                ) : (
                  ""
                )}

                {<ViewDetails viewRowId={this.state.viewValues} />}
              </Fragment>
            </ModalBody>
            <ModalFooter>
              <FormGroup className="float-sm-right ">
                {this.state.viewValues &&
                this.state.viewValues.CanTakeAction &&
                this.state.viewValues.options.length
                  ? this.state.viewValues.options.map((option) => {
                      return (
                        <Button
                          outline
                          size="md"
                          className="mr-2"
                          style={{
                            backgroundColor: option.buttonColor,
                            borderColor: option.buttonColor,
                            color: "#fff",
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                Status: option.state,
                                buttonName: option.buttonName,
                              },
                              () => {
                                this.handleActions(
                                  this.state.viewValues,
                                  option.state
                                );
                              }
                            );
                          }}
                        >
                          {this.props.t(option.buttonName)}
                        </Button>
                      );
                    })
                  : ""}

                <Button
                  color="danger"
                  className="mr-2"
                  onClick={() => this.toggleViewModal()}
                >
                  {this.props.t("Close")}
                </Button>
              </FormGroup>
            </ModalFooter>
          </Modal>
          <Modal
            className="viewDocumentModal"
            isOpen={this.state.DocumentViewModal}
            size="md"
            toggle={this.togglePhotoViewModal}
          >
            <ModalHeader toggle={this.togglePhotoViewModal}>
              <b>{this.props.t(this.state.Title)}</b>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12">
                  <Fragment>
                    {checkIfPdf(this.state.ImageGallery) ? (
                      <Colxx xxs="5">
                        <FileDisplay Value={this.state.ImageGallery} />
                      </Colxx>
                    ) : (
                      <div className="each-slide">
                        <img
                          alt="noimg"
                          id="userimg"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          src={this.state.ImageGallery}
                        />
                      </div>
                    )}
                  </Fragment>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <FormGroup>
                <a href={this.state.ImageGallery}>
                  <Button
                    type="submit"
                    className="default   btn-sm mb-2 btn-addon"
                    color="primary"
                  >
                    {this.props.t("Download")}
                  </Button>{" "}
                </a>

                <Button
                  className="default   btn-sm mb-2 btn-addon"
                  color="danger"
                  onClick={() => this.togglePhotoViewModal()}
                >
                  {this.props.t("Close")}
                </Button>
              </FormGroup>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.confirmationFieldsModal}
            toggle={() => this.closeModals("confirmationFieldsModal")}
            backdrop="static"
          >
            <ModalHeader
              toggle={() => this.closeModals("confirmationFieldsModal")}
            >
              {!this.state.isBulkActionRequested
                ? this.props.t(this.state.buttonName + " Application")
                : this.state.bulkActionStatus === "Interview Cleared"
                ? this.props.t("Mark selected as cleared")
                : this.props.t(
                    this.state.bulkActionButtonName + " Applications"
                  )}
            </ModalHeader>
            <Formik onSubmit={this.fileUpload} initialValues={{}}>
              {({ errors, values, setFieldValue }) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <ModalBody>
                    <Fragment>
                      {this.state.fields &&
                        this.state.fields.map((f) => {
                          let className = "av-label";
                          if (f.IsMandatory) {
                            className += " requiredField";
                            // let mandatoryFields = this.state.mandatoryFields;
                            // mandatoryFields.push(f.Key);
                            // this.setState({ mandatoryFields });
                          }
                          if (f.type === "select") {
                            return (
                              // <Row className="mb-4">
                              //   <Colxx xxs="12">
                              //     <Label className={className}>
                              //       {" "}
                              //       {this.props.t(f.DisplayName)}
                              //     </Label>
                              //   </Colxx>
                              //   <Colxx xxs="12">
                              //     <Field
                              //       autoComplete="off"
                              //       name={f.type !== "file" ? f.Key : "File"}
                              //       type={f.type}
                              //       component={f.component}
                              //       className="form-control"
                              //       onChange={(event) => {
                              //         if (f.type !== "file")
                              //           setFieldValue(
                              //             f.Key,
                              //             event ? event.target.value : ""
                              //           );
                              //         else {
                              //           setFieldValue(
                              //             f.Key,
                              //             event.target.files[0]
                              //           );
                              //         }
                              //       }}
                              //     />
                              //   </Colxx>
                              // </Row>
                              <Colxx xxs="6">
                                <FormGroup className="av-label">
                                  <DropDown
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name={this.props.t(f.DisplayName)}
                                    labelClassName="requiredField"
                                    label={this.props.t(f.DisplayName)}
                                    isMulti
                                    isSearchable
                                    options={this.state.PDJList}
                                    value={
                                      values &&
                                      values.CopyToPDJ &&
                                      this.state.PDJList.filter((a) => {
                                        return values.CopyToPDJ.some((r) => {
                                          return (
                                            a.value ===
                                            (typeof r === "object"
                                              ? r.value
                                                ? r.value
                                                : r.value
                                              : r)
                                          );
                                        });
                                      }).map(function (o) {
                                        return o;
                                      })
                                    }
                                    ClearAction={() => {
                                      setFieldValue("CopyToPDJ", null);
                                    }}
                                    Action={(entity) => {
                                      console.log("entity", entity);
                                      setFieldValue(
                                        "CopyToPDJ",
                                        entity ? entity : ""
                                      );

                                      // var filterValues =
                                      //   this.state.PDJList.length > 0 &&
                                      //   this.state.PDJList.filter(function (
                                      //     val
                                      //   ) {
                                      //     return entity
                                      //       ? entity.indexOf(val) === -1
                                      //       : null;
                                      //   });
                                    }}
                                  />
                                  {errors.CopyToPDJ ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.CopyToPDJ}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Colxx>
                            );
                          } else if (f.InputType === "TextEditor") {
                            return (
                              <Colxx>
                                <FormGroup className="av-label">
                                  <JoditEditor
                                    onChange={(event) => {
                                      console.log("event", event);
                                      setFieldValue(f.Key, event ? event : "");
                                    }}
                                  />
                                </FormGroup>
                              </Colxx>
                            );
                          } else {
                            console.log("f.type", f);
                            return (
                              <Row className="mb-4">
                                <Colxx xxs="12">
                                  <Label className={className}>
                                    {" "}
                                    {this.props.t(f.DisplayName)}
                                  </Label>
                                </Colxx>
                                <Colxx xxs="12">
                                  <Field
                                    autoComplete="off"
                                    name={f.type !== "file" ? f.Key : "File"}
                                    type={f.type}
                                    component={f.component}
                                    className="form-control"
                                    onChange={(event) => {
                                      if (f.type !== "file")
                                        setFieldValue(
                                          f.Key,
                                          event ? event.target.value : ""
                                        );
                                      else {
                                        setFieldValue(
                                          f.Key,
                                          event.target.files[0]
                                        );

                                        // uploadFile(
                                        //   event.target.files[0],
                                        //   (url) => {
                                        //     setFieldValue(f.Key, url ? url : "");
                                        //   }
                                        // );
                                      }
                                    }}
                                  />
                                  {f.type === "file" ? (
                                    <p className="text-semi-muted">
                                      Allowed formats are png, doc, pdf, jpg and
                                      jpeg
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Colxx>
                              </Row>
                            );
                          }
                        })}
                    </Fragment>
                  </ModalBody>

                  <ModalFooter>
                    <FormGroup className="float-sm-right ">
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: !this.state.isBulkActionRequested
                            ? this.state.ButtonColor
                            : this.state.bulkActionButtoncolor,
                        }}
                        // style={{
                        //   color: "#fff",
                        //   borderColor: this.state.isBulkActionRequested
                        //     ? "#1a9504"
                        //     : "#e90909",
                        //   backgroundColor: this.state.isBulkActionRequested
                        //     ? "#1a9504"
                        //     : "#e90909",
                        // }}
                        // onClick={() => this.toggle()}
                      >
                        {this.state.loading ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            {!this.state.isBulkActionRequested
                              ? this.state.buttonName
                              : this.state.bulkActionButtonName}
                          </span>
                        ) : !this.state.isBulkActionRequested ? (
                          this.props.t(this.state.buttonName)
                        ) : (
                          this.props.t(this.state.bulkActionButtonName)
                        )}
                      </Button>
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() =>
                          this.closeModals("confirmationFieldsModal")
                        }
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Modal>
          <Modal
            isOpen={this.state.confirmModal}
            toggle={() => this.closeModals("confirmModal")}
          >
            <ModalHeader toggle={() => this.closeModals("confirmModal")}>
              Confirm{" "}
              {!this.state.isBulkActionRequested
                ? this.props.t(this.state.buttonName + " Application")
                : this.state.bulkActionStatus === "Interview Cleared"
                ? this.props.t("Mark selected applications as cleared")
                : this.state.bulkActionButtonName + " Applications"}
            </ModalHeader>

            <AvForm onSubmit={() => this.handleConfirm()}>
              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      {!this.state.isBulkActionRequested ? (
                        this.state.buttonName === "Not Recommend" ? (
                          <h5 className="pr-2 pt-2">
                            {this.props.t(
                              "Are you sure you don't want to recommend this application"
                            )}{" "}
                            ?
                          </h5>
                        ) : (
                          <h5 className="pr-2 pl-1 pt-2">
                            Are you sure you want to{" "}
                            <span style={{ textTransform: "lowercase" }}>
                              {this.state.buttonName}{" "}
                            </span>{" "}
                            this application ?
                          </h5>
                        )
                      ) : this.state.bulkActionStatus ===
                        "Interview Cleared" ? (
                        <h5 className="pr-2 pt-2">
                          {/* Are you sure you want to mark selected applications as
                          cleared ? */}
                          {this.props.t(
                            "All the other applications except selected in this district will automatically be marked as failed in interview."
                          )}
                        </h5>
                      ) : (
                        <h5 className="pr-2 pl-1 pt-2">
                          Are you sure you want to{" "}
                          {this.state.bulkActionButtonName.toLowerCase()} the
                          applications ?
                        </h5>
                      )}
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button type="submit" outline color="primary">
                    {this.props.t("Confirm")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.closeModals("confirmModal")}
                  >
                    {this.props.t("Cancel")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </AvForm>
          </Modal>

          <Modal
            size="xl"
            isOpen={this.state.scheduleInterview}
            toggle={() => this.closeModals("scheduleInterview")}
          >
            <ModalHeader toggle={() => this.closeModals("scheduleInterview")}>
              {console.log(this.state.scheduleButton)}
              {this.props.t(
                `${
                  this.state.scheduleButton &&
                  this.state.scheduleButton.ButtonName
                } Interview`
              )}
            </ModalHeader>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.handleScheduleInterview}
              initialValues={{}}
              validationSchema={sheduleInterviewValidation}
            >
              {({ errors, values, setFieldValue }) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <ModalBody>
                    <Row>
                      <Colxx sm="6" className="text-right">
                        <Label>
                          {this.props.t("Total Applications Count")}
                        </Label>
                      </Colxx>
                      <Colxx sm="6" className="text-left">
                        <Label>
                          {" "}
                          {this.state.ForwardedApplications.length}{" "}
                        </Label>
                      </Colxx>
                    </Row>
                    {this.state.InterviewBatches &&
                    this.state.InterviewBatches.length > 0
                      ? this.state.InterviewBatches.map(
                          (interviewBatch, batchIndex) => {
                            return (
                              <Fragment key={batchIndex}>
                                <Row>
                                  <Colxx sm="3">
                                    <FormGroup>
                                      <Label>
                                        {this.props.t("Serial Number")}
                                      </Label>{" "}
                                      <br />
                                      <Field
                                        name="From"
                                        type="number"
                                        className="form-control col-6 float-left"
                                        placeholder="From"
                                        value={interviewBatch.From}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          if (
                                            this.ValidateBatchField(
                                              batchIndex,
                                              "From",
                                              e.target.value
                                            )
                                          ) {
                                            this.UpdateInterviewBatchFields(
                                              batchIndex,
                                              "From",
                                              parseInt(e.target.value)
                                            );
                                          } else {
                                            toastr.error(
                                              "",
                                              "Kindly check the batch details"
                                            );
                                            this.UpdateInterviewBatchFields(
                                              batchIndex,
                                              "From",
                                              0
                                            );
                                          }
                                        }}
                                      />
                                      <Field
                                        name="To"
                                        type="number"
                                        className="form-control col-6 float-left"
                                        placeholder="To"
                                        value={interviewBatch.To}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          if (
                                            this.ValidateBatchField(
                                              batchIndex,
                                              "To",
                                              e.target.value
                                            )
                                          ) {
                                            this.UpdateInterviewBatchFields(
                                              batchIndex,
                                              "To",
                                              parseInt(e.target.value)
                                            );
                                          } else {
                                            this.UpdateInterviewBatchFields(
                                              batchIndex,
                                              "To",
                                              0
                                            );
                                          }
                                        }}
                                      />
                                    </FormGroup>
                                  </Colxx>
                                  <Colxx sm="2">
                                    <FormGroup>
                                      <Label>{this.props.t("Date")}</Label>
                                      <DatePicker
                                        autoComplete="nope"
                                        showYearDropdown
                                        showMonthDropdown
                                        selected={interviewBatch.DateObj}
                                        className="form-control"
                                        value={interviewBatch.DateObj}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                          // this.setState({
                                          //   Date: date,
                                          // });
                                          // values.Date = new Date(date).getTime();
                                          this.UpdateInterviewBatchFields(
                                            batchIndex,
                                            "Date",
                                            new Date(date).getTime()
                                          );
                                          this.UpdateInterviewBatchFields(
                                            batchIndex,
                                            "DateObj",
                                            date
                                          );
                                        }}
                                        placeholderText="dd/mm/yyyy"
                                        name="Date"
                                        dateFormat="dd/MM/yyyy"
                                      />
                                    </FormGroup>
                                  </Colxx>
                                  <Colxx sm="3">
                                    <FormGroup>
                                      <Label>{this.props.t("Session")}</Label>
                                      <br />

                                      {this.state.Sessions &&
                                        this.state.Sessions.map((ses) => {
                                          return (
                                            <Fragment>
                                              <input
                                                className="ml-2"
                                                type="radio"
                                                value={ses.value}
                                                name={"Session" + batchIndex}
                                                onChange={(e) => {
                                                  e.preventDefault();
                                                  this.UpdateInterviewBatchFields(
                                                    batchIndex,
                                                    "Session",
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <Label className="ml-2">
                                                {ses.label}
                                              </Label>
                                            </Fragment>
                                          );
                                        })}
                                    </FormGroup>
                                  </Colxx>
                                  <Colxx sm="3">
                                    <FormGroup>
                                      <Label>{this.props.t("Location")}</Label>
                                      <Field
                                        name="Location"
                                        component="textarea"
                                        className="form-control"
                                        onChange={(e) => {
                                          e.preventDefault();
                                          this.UpdateInterviewBatchFields(
                                            batchIndex,
                                            "Location",
                                            e.target.value
                                          );
                                        }}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event.target.value &&
                                            event.target.value.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                        }}
                                      />
                                      {errors.Location && (
                                        <div className="invalid-feedback d-block">
                                          {errors.Location}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Colxx>
                                  <Colxx sm="1">
                                    <Label></Label>
                                    <br />
                                    {batchIndex > 0 ? (
                                      <i
                                        className="fa fa-trash ml-1"
                                        title="Remove Batch"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.RemoveInterviewBatch(batchIndex);
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    {batchIndex ===
                                    this.state.InterviewBatches.length - 1 ? (
                                      <i
                                        className="fa fa-plus-circle ml-2"
                                        title="Add New Batch"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.AddInterviewBatch();
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </Colxx>
                                </Row>
                              </Fragment>
                            );
                          }
                        )
                      : ""}
                  </ModalBody>

                  <ModalFooter>
                    <FormGroup className="float-sm-right ">
                      <Button
                        type="submit"
                        outline
                        style={{
                          color: "#fff",
                          borderColor: this.state.isBulkActionRequested
                            ? "#1a9504"
                            : "#e90909",
                          backgroundColor: this.state.isBulkActionRequested
                            ? "#1a9504"
                            : "#e90909",
                        }}
                        // onClick={() => this.toggle()}
                      >
                        {this.props.t(
                          this.state.scheduleButton &&
                            this.state.scheduleButton.ButtonName
                        )}
                      </Button>
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() => this.closeModals("scheduleInterview")}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal
            isOpen={this.state.MakingPaymentModal}
            toggle={() => this.confirmMakingPayment()}
          >
            <ModalHeader toggle={() => this.confirmMakingPayment()}>
              Confirm Payment Applications
            </ModalHeader>

            <AvForm onSubmit={() => this.handleMakingPayment()}>
              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      <h5 className="pr-2 pt-2">
                        {this.props.t(
                          "Are you sure you don't want to recommend this application ?"
                        )}
                      </h5>
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button type="submit" outline color="primary">
                    {this.props.t("Confirm")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.confirmMakingPayment()}
                  >
                    {this.props.t("Cancel")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </AvForm>
          </Modal>

          <Modal
            size="lg"
            isOpen={this.state.GenerateCertificateModal}
            toggle={() => this.ConfirmGenerateCertificate()}
          >
            <ModalHeader toggle={() => this.ConfirmGenerateCertificate()}>
              {this.props.t("Generate Certificate")}
            </ModalHeader>
            <ModalBody>
              <Fragment>
                {/* <ListPage
                  className={"cursor-pointer"}
                  columns={this.state.columns}
                  data={this.state.GenerateCertificateList}
                  keyField={this.state.keyField}
                  // totalCount={this.state.selectableTotalCount}
                  // rowsPerPageOnChange={this.handleSelectablePerRowsChange}
                  // pageChange={this.handleSelectablePageChange}
                  overFlowXRemoval={true}
                  onSort={this.handleSelectableSort}
                  isDataLoading={this.state.isLoading}
                /> */}
                {/* <Nav tabs className="nav-tabs-custom nav-justified">
                  {this.state.tabs.map((t) => {
                    return (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: this.state.activeFirstTab === t.id,
                          }}
                          onClick={() => {
                            this.toggleCustomJustified(t.id);
                          }}
                        >
                          <span className="d-none d-sm-block">{t.Name}</span>
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>  */}
                {/* <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={1}></TabPane>
                </TabContent>
                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={2}>
                  </TabPane>
                </TabContent> */}
                {<ViewDetails viewRowId={this.state.viewValues} />}
              </Fragment>
            </ModalBody>
            <ModalFooter>
              <FormGroup className="float-sm-right ">
                <Button
                  color="danger"
                  className="ml-2"
                  onClick={() => this.ConfirmGenerateCertificate()}
                >
                  {this.props.t("Close")}
                </Button>
              </FormGroup>
            </ModalFooter>
          </Modal>

          <Modal
            size={"xl"}
            isOpen={this.state.FormModal}
            toggle={this.toggleFormModal}
          >
            <ModalHeader toggle={this.toggleFormModal}>View</ModalHeader>

            <ModalBody>
              {this.props.location.pathname === "/renewal_applications" ? (
                <RenewalForm Application={this.state.FormApplication} />
              ) : this.props.location.pathname ===
                "/duplicate_certificate_applications" ? (
                <DuplicateForm Application={this.state.FormApplication} />
              ) : this.state.viewValues &&
                this.state.viewValues.Applicant &&
                this.state.viewValues.Applicant.FormType &&
                this.state.viewValues.Applicant.FormType.Name === "Form-I" ? (
                <Form1 Application={this.state.FormApplication} />
              ) : (
                <Form2 Application={this.state.FormApplication} />
              )}
            </ModalBody>

            <ModalFooter>
              <FormGroup className="float-sm-right ">
                <Button
                  color="danger"
                  className="form-second-btn"
                  onClick={this.toggleFormModal}
                >
                  {this.props.t("Close")}
                </Button>
              </FormGroup>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(withNamespaces()(ApplicationsList));
