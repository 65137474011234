import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { menu } from "../../../constants/menu";
//i18n
import { withNamespaces } from "react-i18next";

// users
import user1 from "../../../assets/images/users/user.png";

class LoginProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name,
      UserName: sessionStorage.getItem("AdvocateName"),
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.displayName });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.username });
      }
    }
  }
  logout = () => {
    sessionStorage.clear();
    this.props.history.push("/logout");
    window.location.reload();
  };
  profile = () => {
    this.props.history.push("/userprofile");
  };
  profilePdj = () => {
    this.props.history.push("/changepassword");
  };
  render() {
    var Menu = menu;
    return (
      <React.Fragment>
        {Menu.map((m) => {
          return m.subs ? (
            <Dropdown
              isOpen={this.state.menu}
              toggle={this.toggle}
              className="d-inline-block"
            >
              <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
              >
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                //to={m.to}
                >
                  <i className={"bx " + m.icon}></i>{" "}
                  <span>{this.props.t(m.label)}</span>
                  <div className="arrow-down"></div>
                </Link>
              </DropdownToggle>
              <DropdownMenu right>
                {m.subs.map((sm, i) => {
                  return (
                    <DropdownItem>
                      <Link to={sm.to} className="dropdown-item">
                        {" "}
                        {this.props.t(sm.label)}
                      </Link>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          ) : (
              <Dropdown>
                <DropdownToggle
                  className="btn header-item waves-effect"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  <Link
                    to={m.to}
                    //   className={
                    //     m.subs ? "has-arrow waves-effect" : "waves-effect"
                    //   }
                    className="nav-link"
                  >
                    <i className={"bx " + m.icon}></i>{" "}
                    <span>{this.props.t(m.label)}</span>
                  </Link>
                </DropdownToggle>
              </Dropdown>
            );
        })}
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(LoginProfileMenu));
