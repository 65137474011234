import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Spinner } from "reactstrap";
import { Cookies } from "react-cookie";

class CookieRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    const cookies = new Cookies();
    if (cookies.get("pathname")) {
      this.props.history.push(cookies.get("pathname"));
    } else {
      this.props.history.push("/home");
    }
  }

  render() {
    return (
      <Fragment>
        <span>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </span>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(CookieRedirect));
