import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";
import ChangeMobileNumber from "../pages/Authentication/ChangeMobile";
import Profile from "../pages/Authentication/Profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ChangePassword from "../pages/Authentication/ChangePassword";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import HomePage from "../pages/Home/homepage";
// import Designation from "../pages/Designation/crud";

// import {
//   NotaryApplication,
//   AreaExtensionApplication,
//   DuplicateCertificate,
//   RenewalApplication,
// } from "../pages/Advocate/application";
import NotaryApplication from "../pages/Advocate/application";
import ExistingNotaryApplication from "../pages/ExistingNotary/application";

import AreaExtensionApplication from "../pages/Advocate/application";
import DuplicateCertificate from "../pages/Advocate/application";
import RenewalApplication from "../pages/Advocate/application";

import { WorkType, Fee, Role } from "../pages/MasterData/crud";
import RenewalApplicationList from "../pages/Advocate/applicationList";
import AreaExtensionList from "../pages/Advocate/applicationList";
import DuplicateCertificateList from "../pages/Advocate/applicationList";
import User from "../pages/User/list";
//import Level from "../pages/Level/heirarchy";
import LoginHome from "../pages/LoginHome/list";
import ArchivedApplication from "../pages/ArchivedApplication/list";
import About from "../pages/About/crud";
import FAQ from "../pages/FAQ/list";
import Contact from "../pages/Contact/list";
import AnnualReturn from "../pages/AnnualReturn/annualreturn";
import InterviewBoard from "../pages/InterviewBoard/ib";
import Settings from "../pages/Settings/crud";
import Notary from "../pages/Notary/list";
import CMS from "../pages/CMS/cms";
import CookieRedirect from "../pages/Cookie/redirect";
import {
  NotaryApplicationsList,
  RenewalApplicationsList,
  AreaExtensionApplicationsList,
  DuplicateCertificateApplicationsList,
  ExistingNotaryApplicationsList,
} from "../pages/Application/list_page";
import Report from "../pages/Report/list";
import PaymentRedirect from "../pages/PaymentGateway/PaymentRedirect";

// var authProtectedRoutes = [];

// var authProtectedRoutes = [];
// if(sessionStorage.getItem("user_role")){
// }
const authProtectedRoutes = [
  //profile
  { path: "/profile", component: UserProfile },
  { path: "/userprofile", component: Profile },
  { path: "/changeMobileNumber", component: ChangeMobileNumber },
  { path: "/changepassword", component: ChangePassword },
  { path: "/payment", component: PaymentRedirect },

  { path: "/new_notary_application", component: HomePage },
  { path: "/notary_renewal", component: HomePage },
  { path: "/dashboard", component: HomePage },
  // { path: "/Designation", component: Designation },
  { path: "/notaryapplication", component: NotaryApplication },
  { path: "/existing_notaryapplication", component: ExistingNotaryApplication },

  { path: "/areaextensionapplication", component: AreaExtensionApplication },
  { path: "/duplicatecertificate", component: DuplicateCertificate },
  { path: "/renewalapplication", component: RenewalApplication },
  { path: "/worktype", component: WorkType },
  { path: "/fee", component: Fee },
  { path: "/role", component: Role },
  { path: "/renewal", component: RenewalApplicationList },
  { path: "/duplicate", component: DuplicateCertificateList },
  { path: "/areaextension", component: AreaExtensionList },
  { path: "/report", component: Report },

  { path: "/user", component: User },
  // { path: "/level", component: Level },
  { path: "/interviewboard", component: InterviewBoard },
  { path: "/settings", component: Settings },
  { path: "/advocate", component: Notary },
  { path: "/annual_return", component: AnnualReturn },
  { path: "/notary_applications", component: NotaryApplicationsList },
  { path: "/renewal_applications", component: RenewalApplicationsList },
  {
    path: "/area_extension_applications",
    component: AreaExtensionApplicationsList,
  },
  {
    path: "/duplicate_certificate_applications",
    component: DuplicateCertificateApplicationsList,
  },
  {
    path: "/existing_notary_applications",
    component: ExistingNotaryApplicationsList,
  },
  { path: "/cms", component: CMS },
  { path: "/annualreturn", component: AnnualReturn },
  { path: "/archived_application", component: ArchivedApplication },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/cookie" />,
  },
];

// else{
//   authProtectedRoutes = [
//     { path: "/", exact: true, component: () => <Redirect to="/adminlogin" /> },
//   ]

// }
const publicRoutes = [
  { path: "/logout", component: Logout },
  // { path: "/login", component: Login },
  // { path: "/lawadminlogin", component: Login },
  { path: "/advocatelogin", component: Login },
  { path: "/interviewboardlogin", component: Login },
  { path: "/officiallogin", component: Login },
  { path: "/lawadminlogin", component: Login },
  { path: "/competentauthoritylogin", component: Login },
  { path: "/superadminlogin", component: Login },

  { path: "/forgot-password", component: ForgetPwd },
  { path: "/interview_forgot_password", component: ForgetPwd },
  { path: "/advocate_forgot_password", component: ForgetPwd },
  { path: "/official_forgot_password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/home", component: LoginHome },
  { path: "/about", component: About },
  { path: "/faq", component: FAQ },
  { path: "/contact", component: Contact },

  { path: "/cookie", component: CookieRedirect },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
