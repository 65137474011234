import { BASE_URL, log } from "../constants/config";
import { MethodType, StatusCodes } from "../constants/defaultValues";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Token: sessionStorage.getItem("user_token")
      ? sessionStorage.getItem("user_token")
      : null,
    language: sessionStorage.getItem("lng"),
  },
});
export const CallService = function (
  url,
  type,
  isUrlType,
  data,
  urlData,
  callback,
  headers
) {
  // debugger
  switch (type) {
    case MethodType.GET:
      if (isUrlType) {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,
          method: MethodType.GET,
          url: url + urlData,
        })
          .then((res) => {

            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            debugger;

            // handle error
            handleResponse(error);
            console.log("handleResponse", error);
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,
          method: MethodType.GET,
          url: url,
        })
          .then((res) => {
            debugger;

            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            debugger;

            callback(error);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.POST:
      if (isUrlType) {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.POST,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (url.includes("sign_in")) callback(res.data, data);
            else if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        let header = sessionStorage.getItem("user_token")
          ? { Token: sessionStorage.getItem("user_token") }
          : null;
        if (headers && headers.all_languages)
          header["all_languages"] = headers.all_languages;
        axiosInstance({
          headers: header,
          method: MethodType.POST,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (url.includes("sign_in")) callback(res.data, data);
            else if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.PATCH:
      if (isUrlType) {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,
          method: MethodType.PATCH,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.PATCH,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.PUT:
      if (isUrlType) {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.PUT,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.PUT,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.DELETE:
      if (isUrlType) {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.DELETE,
          url: url + urlData,
          data: data,
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: sessionStorage.getItem("user_token")
            ? { Token: sessionStorage.getItem("user_token") }
            : null,

          method: MethodType.DELETE,
          url: url,
          data: data,
        })
          .then((res) => {
            if (handleResponse(res)) callback(res.data, data);
          })
          .catch(function (error) {
            // handle error
            callback(error);
          })
          .finally(function () {
            // always executed
          });
      }
      break;

    default:
      break;
  }
};

function handleResponse(res) {
  var response = false;
  console.log("handleResponsehandleResponse", res);
  if (res.data) {
    // if (res.data.statusCode === StatusCodes.Duplicate) {
    //   toastr.error("", "Duplicate value! Please try some other");
    // } else
    if (res.data.statusCode === StatusCodes.InvalidData) {
      if (
        res.config.url.includes("sign_up") ||
        res.config.url.includes("reset_password")
      ) {
        toastr.error("", "Invalid OTP");
      } else if (res.data.exception) {
        toastr.error("", res.data.exception);
      } else if (res.data.stringResult === "File too small") {
        toastr.error("", "File too small");
        response = true;
      } else {
        toastr.error("", "Please check your values! Something went wrong");
      }
    } else if (res.data.statusCode === StatusCodes.Forbidden)
      toastr.error("", "Server rejected our request");
    else if (res.data.statusCode === StatusCodes.NotFound) {
      if (res.config.url.includes("archive")) {
        toastr.error("", res.data.exception);
      } else {
        toastr.error(
          "",
          "Your request is not found",
          3000,
          () => {},
          null,
          "filled"
        );
      }
    } else if (res.data.statusCode === StatusCodes.Unauthorized) {
      if (res.config.url.includes("sign_in")) {
        toastr.error("", "Incorrect username or password");
      } else if (res.config.url.includes("change_password")) {
        toastr.error("", "Incorrect current password");
      } else {
        toastr.error(
          "",
          "Your session expired. Please login again",
          3000,
          () => {},
          null,
          "filled"
        );
        sessionStorage.clear();
        window.location.replace("");
      }
    } else if (res.data.statusCode === StatusCodes.Error)
      toastr.error("", "Something went wrong", 3000, () => {}, null, "filled");
    else response = true;
  } else response = true;
  console.log("responseresponseresponse", response);
  return response;
}
