import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withNamespaces } from "react-i18next";
import { Row, Button, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
class Form2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  async savePDF() {
    this.setState({
      loading: true,
    });

    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    if (this.props.match) {
      options.scrollX = -7;
    }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    const printArea = document.getElementById("PrintForm");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");

    var pagePromises = [];

    Promise.all(pagePromises).then(() => {
      pdf.save(
        this.props.Application
          ? this.state.Application && this.state.Application.Title + ".pdf"
          : "saved.pdf"
      );
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 10000);
  }

  DownloadFormAsPDF = () => {
    const Application = this.props.Application.Applicant
      ? this.props.Application.Applicant
      : this.props.Application;

    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    pdf.text("(FORM II)", 230, y, null, null, "center");
    y = y + 10;
    pdf.text("(Rule 4 (2))", 230, y, null, null, "center");
    y = y + 30;

    if (Application.Base64Photo) {
      pdf.addImage(Application.Base64Photo, "PNG", 20, y, 80, 50);
    }
    pdf.text("1.Name", 125, y, null, null);
    pdf.text(Application.Name, 180, y, null, null, "center");
    pdf.line(160, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text("2.Father's/Husband's Name", 125, y, null, null);
    pdf.text(Application.FatherOrHusbandName, 260, y, null, null);
    pdf.line(235, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text("3.Date of birth", 125, y, null, null);
    pdf.text(
      Application.Dob
        ? formatDate(fromUnixTime(Application.Dob / 1000), datePickerFormat)
        : "",
      190,
      y,
      null,
      null
    );
    pdf.line(180, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text("4.Whether SC/ST/OBC/General", 125, y, null, null);
    pdf.text(Application.Community.Name, 270, y, null, null);
    pdf.line(250, y + 5, 400, y + 5);
    y = y + 20;
    pdf.text("5.Address(Residence)", 50, y, null, null);

    if (Application && Application.FullResidencyAddress) {
      pdf.text(
        Application && Application.FullResidencyAddress,
        // "5,14th Street,Tansi Nagar,Velachery,Chennai,Tamilnadu",
        150,
        y,
        null,
        null
      );
    } else {
      pdf.text("", 150, y, null, null);
    }
    pdf.line(140, y + 5, 400, y + 5);
    y = y + 30;
    pdf.text("PIN", 60, y, null, null);
    pdf.text(
      Application &&
        Application.ResidencyAddress &&
        Application.ResidencyAddress.Address &&
        Application.ResidencyAddress.Address.Pincode
        ? Application.ResidencyAddress.Address.Pincode
        : "",
      85,
      y,
      null,
      null
    );
    pdf.line(75, y + 5, 150, y + 5);
    y = y + 25;
    pdf.text("Telephone", 50, y, null, null);
    pdf.text(
      Application.Landline ? Application.Landline : "",
      110,
      y,
      null,
      null
    );
    pdf.line(90, y + 5, 170, y + 5);
    pdf.text("Fax", 170, y, null, null);
    pdf.text(Application.Fax ? Application.Fax : "", 220, y, null, null);
    pdf.line(190, y + 5, 270, y + 5);
    pdf.text("E-mail", 270, y, null, null);
    pdf.text(
      Application.EmailID ? Application.EmailID : "",
      300,
      y,
      null,
      null
    );
    pdf.line(300, y + 5, 400, y + 5);

    y = y + 20;
    pdf.text("Address(office)", 50, y, null, null);
    pdf.text(
      Application.FullProvisionalAddress
        ? Application.FullProvisionalAddress
        : "",
      //  "5,14th Street,Tansi Nagar,Velachery,Chennai,Tamilnadu",
      120,
      y,
      null,
      null
    );
    pdf.line(110, y + 5, 400, y + 5);
    y = y + 20;
    pdf.line(50, y + 5, 190, y + 5);
    pdf.text("PIN", 200, y, null, null);
    pdf.text(
      Application.ProvisionalAddress &&
        Application.ProvisionalAddress.Address &&
        Application.ProvisionalAddress.Address.Pincode
        ? Application.ProvisionalAddress.Address.Pincode
        : "",
      240,
      y,
      null,
      null
    );
    pdf.line(220, y + 5, 400, y + 5);

    y = y + 25;
    pdf.text("Telephone", 50, y, null, null);
    pdf.text(
      Application.Landline ? Application.Landline : "",
      110,
      y,
      null,
      null
    );
    pdf.line(90, y + 5, 170, y + 5);
    pdf.text("Fax", 170, y, null, null);
    pdf.text(Application.Fax ? Application.Fax : "", 220, y, null, null);
    pdf.line(190, y + 5, 270, y + 5);
    pdf.text("E-mail", 270, y, null, null);
    pdf.text(
      Application.EmailID ? Application.EmailID : "",
      300,
      y,
      null,
      null
    );
    pdf.line(300, y + 5, 400, y + 5);

    y = y + 30;

    pdf.text("6. Educational qualifications", 50, y, null, null);
    pdf.text(
      Application.Degrees
        ? Application.Degrees.map((d) => d.Name).join(", ")
        : "",
      190,
      y,
      null,
      null
    );

    pdf.line(175, y + 5, 400, y + 5);

    y = y + 30;

    pdf.text("7. Date of joining government service", 50, y, null, null);

    pdf.text(
      Application && Application.GovtServiceStartDate
        ? formatDate(
            fromUnixTime(Application.GovtServiceStartDate / 1000),
            datePickerFormat
          )
        : "",
      220,
      y,
      null,
      null
    );
    pdf.line(200, y + 5, 400, y + 5);

    y = y + 30;

    pdf.text("8. Date of retirement", 50, y, null, null);
    pdf.text(
      Application && Application.GovtServiceEndDate
        ? formatDate(
            fromUnixTime(Application.GovtServiceEndDate / 1000),
            datePickerFormat
          )
        : "",
      150,
      y,
      null,
      null
    );
    pdf.line(125, y + 5, 400, y + 5);

    y = y + 30;

    pdf.text("9. Post held at the time of retirement", 50, y, null, null);
    pdf.text(
      Application && Application.GovtServicePost
        ? Application.GovtServicePost
        : "",
      220,
      y,
      null,
      null
    );
    pdf.line(200, y + 5, 400, y + 5);

    y = y + 30;

    pdf.text(
      "10. Area where the memorialist intends to practice as Notary",
      50,
      y,
      null,
      null
    );

    y = y + 20;
    pdf.text(
      Application &&
        Application.ResidencyAddress &&
        Application.ResidencyAddress.Address
        ? Application.ResidencyAddress.Address.Taluk.Name +
            "," +
            Application.ResidencyAddress.Address.District.Name
        : "",
      70,
      y,
      null,
      null
    );
    pdf.line(50, y + 5, 400, y + 5);

    y = y + 75;
    // pdf.text("Signature of the applicant", 300, y, null, null);

    y = y + 30;
    pdf.text("Dated", 50, y, null, null);
    pdf.text(new Date().getDate().toString(), 85, y, null, null);
    pdf.line(75, y + 5, 120, y + 5);
    pdf.text("day of", 120, y, null, null);
    pdf.text(getMonthName(new Date().getMonth()), 155, y, null, null);
    pdf.line(140, y + 5, 230, y + 5);
    pdf.text("20", 230, y, null, null);
    pdf.text(
      new Date().getFullYear().toString().substring(2, 4),
      255,
      y,
      null,
      null
    );
    pdf.line(240, y + 5, 300, y + 5);

    pdf.save(
      this.props.Application
        ? this.props.Application &&
            this.props.Application.ApplicationNoText + ".pdf"
        : "Form2.pdf"
    );
  };

  render() {
    const Application =
      this.props.Application && this.props.Application.Applicant
        ? this.props.Application.Applicant
        : this.props.Application;

    return (
      <Fragment>
        <Card>
          <CardBody>
            <Row></Row>

            <Row>
              <Colxx xxs="10"></Colxx>
              <Colxx xxs="2">
                {/* <Button
                  className="form-second-btn"
                  type="button"
                  onClick={() => this.savePDF()}
                  color="success"
                  disabled={this.state.loading}
                >
                  Download as PDF{" "}
                </Button> */}
                <Button
                  className="form-second-btn"
                  type="button"
                  onClick={() => this.DownloadFormAsPDF()}
                  color="success"
                >
                  Download as PDF{" "}
                </Button>
              </Colxx>
              <Colxx xxs="12" id="PrintForm">
                <div
                // style={{
                //   width: "210mm",
                //   height: "297mm",
                // }}
                >
                  <Row>
                    <Colxx xxs="12 text-center mt-1">
                      <span>(FORM II)</span>
                    </Colxx>
                    <Colxx xxs="12 text-center  mt-1">
                      <span>(Rule 4(2))</span>
                    </Colxx>

                    <Colxx xxs="9">
                      <Colxx xxs="12 mb-2">
                        <Colxx xxs="6 float-left">1. Name</Colxx>
                        <Colxx xxs="6" className="text-underline float-left">
                          {Application && Application.Name}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="12 mb-2">
                        <Colxx xxs="6 float-left">
                          2. Father's/Husband’s name
                        </Colxx>
                        <Colxx xxs="6" className="text-underline float-left">
                          {Application && Application.FatherOrHusbandName}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="12 mb-2">
                        <Colxx xxs="6 float-left">3. Date of birth</Colxx>
                        <Colxx xxs="6" className="text-underline float-left">
                          {Application && Application.Dob
                            ? formatDate(
                                fromUnixTime(Application.Dob / 1000),
                                datePickerFormat
                              )
                            : ""}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="12 mb-2">
                        <Colxx xxs="6 float-left">
                          4. Whether SC/ST/OBC/General
                        </Colxx>
                        <Colxx xxs="6" className="text-underline float-left">
                          {Application &&
                            Application.Community &&
                            Application.Community.Name}
                        </Colxx>
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="3" className="text-center">
                      <span>
                        {" "}
                        <img
                          alt="noimg"
                          id="userimg"
                          className="applicantImage"
                          src={Application ? Application.Photo : ""}
                          // onClick={() =>
                          //   this.togglePhotoViewModal(
                          //     this.state.Application.Photo,
                          //     "Photo"
                          //   )
                          // }
                        />
                      </span>
                    </Colxx>
                    {/* <Colxx xxs="2"></Colxx> */}
                    <Colxx xxs="9">
                      <Colxx xxs="12">
                        <Colxx xxs="6 float-left">5.Address(Residence)</Colxx>
                        <Colxx
                          xxs="6 float-left"
                          className={
                            Application && Application.FullResidencyAddress
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.FullResidencyAddress
                            ? Application.FullProvisionalAddress
                            : "-"}
                        </Colxx>
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="3"></Colxx>
                    <Colxx xxs="9">
                      <Colxx xxs="12 mt-2">
                        <Colxx xxs="6 float-left">PIN</Colxx>
                        <Colxx xxs="6 float-left text-underline">
                          {" "}
                          {Application &&
                            Application.ResidencyAddress &&
                            Application.ResidencyAddress.Address &&
                            Application.ResidencyAddress.Address.Pincode &&
                            Application.ResidencyAddress.Address.Pincode}
                        </Colxx>
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="3"></Colxx>

                    <Colxx xxs="12 mt-2">
                      <Colxx xxs="2 pl-4 float-left">Telephone</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ResidencyAddress &&
                          Application.ResidencyAddress.Address &&
                          Application.ResidencyAddress.Address.TelePhone &&
                          Application.ResidencyAddress.Address.TelePhone
                            ? "text-underline"
                            : ""
                        }
                      >
                        {Application &&
                        Application.ResidencyAddress &&
                        Application.ResidencyAddress.Address &&
                        Application.ResidencyAddress.Address.TelePhone &&
                        Application.ResidencyAddress.Address.TelePhone
                          ? Application.ResidencyAddress.Address.TelePhone
                          : "-"}
                      </Colxx>
                      <Colxx xxs="2 float-left">Fax</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ResidencyAddress &&
                          Application.ResidencyAddress.Address &&
                          Application.ResidencyAddress.Address.Fax &&
                          Application.ResidencyAddress.Address.Fax
                            ? "text-underline"
                            : ""
                        }
                      >
                        {" "}
                        {Application &&
                        Application.ResidencyAddress &&
                        Application.ResidencyAddress.Address &&
                        Application.ResidencyAddress.Address.Fax &&
                        Application.ResidencyAddress.Address.Fax
                          ? Application.ResidencyAddress.Address.Fax
                          : "-"}
                      </Colxx>
                      <Colxx xxs="2 float-left"> E-mail</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ResidencyAddress &&
                          Application.ResidencyAddress.Address &&
                          Application.ResidencyAddress.Address.Email
                            ? "text-underline"
                            : ""
                        }
                      >
                        {" "}
                        {Application &&
                        Application.ResidencyAddress &&
                        Application.ResidencyAddress.Address &&
                        Application.ResidencyAddress.Address.Email
                          ? Application.ResidencyAddress.Address.Email
                          : "-"}
                      </Colxx>
                    </Colxx>

                    <Colxx xxs="9 mt-2">
                      <Colxx xxs="12 mt-2">
                        <Colxx xxs="6 float-left">Address(Official)</Colxx>
                        <Colxx
                          xxs="6 float-left"
                          className={
                            Application && Application.FullProvisionalAddress
                              ? "text-underline"
                              : ""
                          }
                        >
                          {" "}
                          {Application && Application.FullProvisionalAddress
                            ? Application.FullProvisionalAddress
                            : "-"}
                        </Colxx>
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="3"></Colxx>
                    <Colxx xxs="9 mt-2">
                      <Colxx xxs="6 float-left pl-4">PIN</Colxx>
                      <Colxx xxs="6 float-left text-underline">
                        {" "}
                        {Application &&
                          Application.ProvisionalAddress &&
                          Application.ProvisionalAddress.Address &&
                          Application.ProvisionalAddress.Address.Pincode &&
                          Application.ProvisionalAddress.Address.Pincode}
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="3"></Colxx>

                    <Colxx xxs="12 mt-2">
                      <Colxx xxs="2 pl-4 float-left">Telephone</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ProvisionalAddress &&
                          Application.ProvisionalAddress.Address &&
                          Application.ProvisionalAddress.Address.TelePhone &&
                          Application.ProvisionalAddress.Address.TelePhone
                            ? "text-underline"
                            : ""
                        }
                      >
                        {Application &&
                        Application.ProvisionalAddress &&
                        Application.ProvisionalAddress.Address &&
                        Application.ProvisionalAddress.Address.TelePhone &&
                        Application.ProvisionalAddress.Address.TelePhone
                          ? Application.ProvisionalAddress.Address.TelePhone
                          : "-"}
                      </Colxx>
                      <Colxx xxs="2 float-left">Fax</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ProvisionalAddress &&
                          Application.ProvisionalAddress.Address &&
                          Application.ProvisionalAddress.Address.Fax &&
                          Application.ProvisionalAddress.Address.Fax
                            ? "text-underline"
                            : ""
                        }
                      >
                        {" "}
                        {Application &&
                        Application.ProvisionalAddress &&
                        Application.ProvisionalAddress.Address &&
                        Application.ProvisionalAddress.Address.Fax &&
                        Application.ProvisionalAddress.Address.Fax
                          ? Application.ProvisionalAddress.Address.Fax
                          : "-"}
                      </Colxx>
                      <Colxx xxs="2 float-left"> E-mail</Colxx>
                      <Colxx
                        xxs="2 float-left"
                        className={
                          Application &&
                          Application.ProvisionalAddress &&
                          Application.ProvisionalAddress.Address &&
                          Application.ProvisionalAddress.Address.Email
                            ? "text-underline"
                            : ""
                        }
                      >
                        {" "}
                        {Application &&
                        Application.ProvisionalAddress &&
                        Application.ProvisionalAddress.Address &&
                        Application.ProvisionalAddress.Address.Email
                          ? Application.ProvisionalAddress.Address.Email
                          : "-"}
                      </Colxx>
                    </Colxx>
                    <Colxx xxs="12">
                      <Colxx xxs="9 mt-2">
                        <Colxx xxs="6 float-left">
                          6. Educational qualifications
                        </Colxx>
                        <Colxx xxs="6 float-left text-underline">
                          {" "}
                          {Application &&
                            Application.Degrees &&
                            Application.Degrees.map((d) => d.Name).join(", ")}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="9 mt-2">
                        <Colxx xxs="6 float-left text-left">
                          7. Date of joining government service{" "}
                        </Colxx>
                        <Colxx xxs="6 float-left text-left text-underline">
                          {" "}
                          {Application && Application.GovtServiceStartDate
                            ? formatDate(
                                fromUnixTime(
                                  Application.GovtServiceStartDate / 1000
                                ),
                                datePickerFormat
                              )
                            : ""}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="9 mt-2">
                        <Colxx xxs="6 float-left text-left">
                          8. Date of retirement
                        </Colxx>
                        <Colxx xxs="6 float-left text-left text-underline">
                          {" "}
                          {Application && Application.GovtServiceEndDate
                            ? formatDate(
                                fromUnixTime(
                                  Application.GovtServiceEndDate / 1000
                                ),
                                datePickerFormat
                              )
                            : ""}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="9 mt-2">
                        <Colxx xxs="6 float-left text-left">
                          9. Post held at the time of retirement
                        </Colxx>
                        <Colxx xxs="6 float-left text-left text-underline">
                          {Application && Application.GovtServicePost}
                        </Colxx>
                      </Colxx>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="9 mt-2">
                        <Colxx xxs="6 float-left text-left">
                          {" "}
                          10. Area where the memorialist intends to practice as
                          Notary
                        </Colxx>
                        <Colxx xxs="6 float-left text-left text-underline">
                          {Application &&
                          Application.ResidencyAddress &&
                          Application.ResidencyAddress.Address
                            ? Application.ResidencyAddress.Address.Taluk.Name +
                              "," +
                              Application.ResidencyAddress.Address.District.Name
                            : ""}
                        </Colxx>
                      </Colxx>
                      {/* <Colxx xxs="12 mt-5 text-right">
                      <span>Signature of the applicant</span>
                    </Colxx> */}
                    </Colxx>
                    <Colxx xxs="3"></Colxx>
                    <br />
                    <br />
                    <Colxx xxs="12 mt-2">
                      <span>
                        Dated{" "}
                        <span className="text-underline">
                          {" "}
                          {new Date().getDay()}{" "}
                        </span>{" "}
                        day of{" "}
                        <span className="text-underline">
                          {" "}
                          {getMonthName(new Date().getMonth())}{" "}
                        </span>{" "}
                        <span className="text-underline">
                          {" "}
                          {new Date().getFullYear()}{" "}
                        </span>
                      </span>
                    </Colxx>
                  </Row>
                </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Form2));
