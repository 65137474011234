import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import {
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { designation, worktype, fee, roles } from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  DesignationValidation,
  FeeValidation,
} from "../../helpers/validations";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";
import toastr from "toastr";

import "toastr/build/toastr.min.css";
import ListPageHeader from "../../components/custom/ListPageHeader";
class CRUD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      data: [],
      columns: [
        {
          omit: props.title === "Fee" ? false : true,
          name: <strong>{"Type"}</strong>,
          selector: "Type.en",
          sortable: true,
          cell: (row) => <span>{row?.DisplayName}</span>,
        },
        {
          omit: props.title === "Fee" ? false : true,
          name: <strong>{"Fee"}</strong>,
          selector: "Type.en",
          sortable: true,
          cell: (row) => <span>{row?.Fee}</span>,
        },
        {
          omit: props.title === "Work Type" ? false : true,

          name: <strong>{"Name"}</strong>,
          selector: "Name.en",
          sortable: true,
          cell: (row) => <span>{row?.Name?.en}</span>,
        },
        {
          omit:
            props.title === "Fee" && props.title === "Work Type" ? false : true,

          name: <strong>{"TamilName"}</strong>,
          selector: "Name.ta",
          sortable: true,
          cell: (row) => <span>{row?.Name?.ta}</span>,
        },
        {
          omit:
            props.title === "Fee" && props.title === "Work Type" ? false : true,

          name: <strong>{"MaxUnitFee"}</strong>,
          selector: "MaxUnitFee",
          sortable: true,
          cell: (row) => <span>{row.MaxUnitFee ? row.MaxUnitFee : ""}</span>,
        },

        {
          omit: props.title !== "Fee" ? true : false,

          name: <strong>{"Action"}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => this.toggleDeleteModal(row._id)}
              >
                Delete
              </Button>
              {/* </ButtonGroup> */}
            </Row>
          ),
        },
      ],
      reqBody: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
    };
  }
  componentDidMount() {
    this.GetAll();
  }
  GetAll() {
    this.setState({
      isLoading: true,
    });
    const { reqBody } = this.state;
    let headers = {};
    if (this.props.title === "Work Type") headers["all_languages"] = true;
    CallService(
      this.props.apis.GetAll,
      MethodType.POST,
      false,
      reqBody,
      "",
      this.GetAllResponse,
      headers
    );
  }

  GetAllResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        data: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  handleSubmit = (values) => {
    if (!this.state.buttonAction) {
      this.setState({
        buttonAction: true,
      });
      this.manage(values);
    }
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        reqBody: {
          ...this.state.reqBody,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAll();
      }
    );
  };

  HandleRowClicked = (row) => {
    if (row) {
      this.setState({
        datum: row,
        manageModal: !this.state.manageModal,
      });
    }
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        reqBody: {
          ...this.state.reqBody,
          pageNumber: page,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.reqBody.searchString === "" ||
          this.state.reqBody.searchString.length > 2
        ) {
          this.GetAll();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      datum: row,
    });
  };

  addBtnClick = () => {
    this.toggleManageModal();
  };
  toggleManageModal = () => {
    this.setState({
      datum: {},
      buttonAction: false,
      manageModal: !this.state.manageModal,
    });
  };

  handleDelete = (id) => {
    CallService(
      this.props.apis.Delete + id,
      MethodType.DELETE,
      false,
      "",
      "",
      this.handleDeleteResponse
    );
  };

  handleDeleteResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    this.toggleDeleteModal();
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", `${this.props.title} deleted Successfully`);
      this.GetAll();
    }
  };
  manage = (values) => {
    CallService(
      values._id ? this.props.apis.Update + values._id : this.props.apis.Create,
      values._id ? MethodType.PATCH : MethodType.PUT,
      false,
      values,
      "",
      this.manageResponse
    );
  };
  manageResponse = (data) => {
    this.setState({
      buttonAction: false,
      manageModal: !this.state.manageModal,
    });

    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.state.datum._id
          ? `${this.props.title} updated Successfully`
          : `${this.props.title} created Successfully`
      );

      this.GetAll();
    }
    //  this.toggleManageModal();
  };

  render() {
    const initialValues = this.state.datum;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4">
              <CardBody>
                <ListPageHeader
                  heading={`Home.${this.props.title}`}
                  onTextChange={this.SearchQueryChanged}
                  buttonClick={this.addBtnClick}
                  searchValue={this.state.reqBody.searchString}
                  showButton={false}
                  showSearch={false}
                />

                <ListPage
                  columns={this.state.columns}
                  data={this.state.data}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {`Delete ${this.props.title}`}
              </ModalHeader>

              <AvForm onSubmit={() => this.handleDelete(this.state.datum)}>
                {console.log(this.state.datum)}
                <ModalBody>
                  <Fragment>
                    <Row className="mb-4">
                      <Label className="av-label ml-3">
                        <h5>
                          {`Are you sure you want to delete this ${this.props.title}?`}
                        </h5>
                      </Label>
                    </Row>
                  </Fragment>
                </ModalBody>

                <ModalFooter>
                  <FormGroup className="float-sm-right ">
                    <Button type="submit" outline color="primary">
                      Yes
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.toggleDeleteModal()}
                    >
                      No
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </AvForm>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.datum && this.state.datum._id
                  ? `Update ${this.props.title}`
                  : `Create ${this.props.title}`}
              </ModalHeader>
              <Formik
                initialValues={initialValues}
                // validationSchema={this.props.validation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        {this.props.fields &&
                          this.props.fields.map((field) => {
                            return (
                              <Row className="mb-10">
                                <Colxx xxs="2"></Colxx>
                                <Colxx xxs="2">
                                  {" "}
                                  <Label>{field.label}</Label>
                                </Colxx>
                                <Colxx xxs="6">
                                  <FormGroup className="form-group has-float-label">
                                    <Field
                                      disabled={
                                        field.name === "Type" ? true : false
                                      }
                                      className="form-control"
                                      name={field.name}
                                      type={field.type}
                                      onKeyPress={(event) => {
                                        if (INVALID_CHARS.includes(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={(event) => {
                                        event.target.value =
                                          event.target.value &&
                                          event.target.value.replace(
                                            INVALID_CHARS_REGEX,
                                            ""
                                          );
                                        setFieldValue(
                                          field.name,
                                          event.target.value
                                        );
                                      }}
                                    />
                                    {errors[field.name] && (
                                      <div className="invalid-feedback d-block">
                                        {errors[field.name]}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="1"></Colxx>
                              </Row>
                            );
                          })}
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        {this.state.datum._id ? (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            Create
                          </Button>
                        )}

                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleManageModal()}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
export class WorkType extends Component {
  render() {
    const fields = [
      {
        type: "text",
        name: "Name.en",
        label: "Name",
      },
      {
        type: "text",
        name: "Name.ta",
        label: "Tamil Name",
      },

      {
        type: "number",
        name: "SortOrder",
        label: "Sort Order",
      },
      {
        type: "number",
        name: "MaxUnitFee",
        label: "Maximum Unit Fee",
      },
    ];
    return (
      <Fragment>
        <CRUD
          title="Work Type"
          fields={fields}
          apis={worktype}
          validation={DesignationValidation}
        />
      </Fragment>
    );
  }
}
export class Fee extends Component {
  render() {
    const fields = [
      {
        type: "text",
        name: "Type",
        label: "Type",
      },
      {
        type: "number",
        name: "Fee",
        label: "Fee",
      },
    ];
    return (
      <Fragment>
        <CRUD
          title="Fee"
          fields={fields}
          apis={fee}
          validation={FeeValidation}
        />
      </Fragment>
    );
  }
}

export class Role extends Component {
  render() {
    const fields = [
      {
        type: "text",
        name: "Name",
        label: "Name",
      },
    ];
    return (
      <Fragment>
        <CRUD
          title="Role"
          fields={fields}
          apis={roles}
          validation={FeeValidation}
        />
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(CRUD));
