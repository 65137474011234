import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import DropDown from "../../components/custom/DropDown";
import moment from "moment";
import FileDisplay from "../../components/custom/FileDisplay";
import ReactTooltip from "react-tooltip";
import "../../../node_modules/react-tooltip/dist/index.js";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import {
  application,
  documentMaster,
  district,
  applicationProcess,
  newapplication,
  taluk,
  areaExtensionApplication,
  duplicateCertificateApplication,
  renewalApplication,
  existingNotary,
  eligibility,
} from "../../constants/config";
import {
  ExistingNotatryValidation,
  nocValidation,
  RenewalFormValidation,
} from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFileSizeText,
  checkIfImage,
  getAllowedExtensionText,
  INVALID_CHARS,
  INVALID_CHARS_REGEX,
} from "../../helpers/Utils";
import Blink from "react-blink-text";
import PaymentForm from "../../pages/PaymentGateway/PaymentForm";

class   ExistingNotaryApplication extends Component {
  constructor(props) {
    super(props);
    this.toggleAlretView = this.toggleAlretView.bind(this);
    this.state = {
      buttonAction: false,
      isLoading: true,
      IsEdited: false,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      ApplicatibindonTitle: "",
      Applications: [],
      Language: sessionStorage.getItem("lng"),
      Status: sessionStorage.getItem("Status"),
      // Status: "Payment Pending",
      CallLetter: sessionStorage.getItem("CallLetter"),
      FeeTotalAmount: sessionStorage.getItem("FeeTotalAmount"),
      SessionValidTo: sessionStorage.getItem("ValidTo"),
      IsApplication: sessionStorage.getItem("Application"),
      // IsExistingNotaryApplications: sessionStorage.getItem(
      //   "ExistingNotaryApplications"
      // ),  ///  Existing Changes
      IsExistingNotaryApplications: false,
      ExistingNotaryApplicationsID: sessionStorage.getItem(
        "ExistingNotaryApplicationsID"
      ),
      ApplicationId: sessionStorage.getItem("ApplicationId"),
      RenewalApplicationID: sessionStorage.getItem("RenewalApplicationID"),
      RenewalStatus: sessionStorage.getItem("RenewalStatus"),
      // RenewalStatus: "Approved",
      AreaStatus: sessionStorage.getItem("AreaStatus"),
      AreaApplicationID: sessionStorage.getItem("AreaExtensionsApplicationID"),
      DuplicateStatus: sessionStorage.getItem("DuplicateStatus"),
      DuplicateApplicationID: sessionStorage.getItem("DuplicateApplicationID"),
      IsArchived: sessionStorage.getItem("IsArchived"),
      ExistingNotatry: false,
      activeFirstTab: 1,
      tabs: [],
      loading: false,
      DisabledButton: true,
      CurrentApplication: "Existing Notary Application",
      ApplicationFields: {
        RenewFrom: "",
        LastRenewalGONumber: "",
        LastRenewedDate: "",
        NotaryAppointmentGONumber: "",
        NotaryAppointmentGODate: "",
        NotaryAppointmentDate: "",
        Areas: "",
        ValidFrom: "",
        ValidTo: "",
        // RenewalBefore: "",
        //   Reason: "",
        Name: "",
        Place: "",
      },
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      buttonLoading: false,
      NotaryApplication: {
        //    SerialNumber: "",
        //    SubmittedOn: null,
        //     SubmittedOnDate: null,
        ValidFrom: null,
        ValidTo: null,
        GO: "",
        GONumber: "",
        District: "",
        Taluks: [],
        // Grounds: "",
      },
      RenewalApplications: [
        {
          //  SubmittedOn: null,
          //   SubmittedOnDate: null,
          ValidFrom: null,
          ValidTo: null,
          GO: "",
          GONumber: null,
        },
      ],
      AreaExtensionApplications: [
        {
          District: null,
          Taluks: [],
          //   SubmittedOn: null,
          //   SubmittedOnDate: null,
          GO: "",
          GONumber: null,
        },
      ],
      // Remarks: "",
      ApplicationProcess: {
        Status: "",
        Comments: "",
      },
      DuplicateFormFields: {
        NotaryAppointmentGONumber: "",
        NotaryAppointmentGODate: "",
        NotaryAppointmentDate: "",
        Areas: "",
        Reason: "",
        Place: "",
      },
      NOC: "",
      DistrictList: [],
      TalukList: [],
      ExistingDetailEntity: {
        existingNotaryApplication: {
          NotaryApplication: {
            Taluks: [],
            Grounds: "",
            ValidFrom: "",
            ValidTo: "",
            GONumber: "",
            District: "",
            GO: "",
            SubmittedOn: "",
            SubmittedOnDate: "",
          },
        },
      },
    };
  }

  componentDidMount() {
    this.setTab(1);
    this.GetAllDistrict();
    this.GetAllTaluk();
    this.GetAllEligibility();
    this.GetDetailExistingNotary();
  }

  GetAllEligibility() {
    CallService(
      eligibility.GetAll,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllEligibilityResponse
    );
  }

  GetAllEligibilityResponse = (data) => {
    if (data.result) {
      this.setState({
        Eligibility: data.result,
        RenewalDueDate:
          data.result &&
          data.result.RenewalApplicationFields &&
          data.result.RenewalApplicationFields.RenewalDueDate,
      });
    }
  };

  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Existing Notary Details"),
        },
        {
          id: 2,
          Name: this.props.t("Renewal Application"),
        },
        {
          id: 3,
          Name: this.props.t("Area of Practice Details"),
        },
      ],
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  GetOneApplications() {
    CallService(
      newapplication.GetOne + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneApplicationsResponse
    );
  }

  GetOneApplicationsResponse = (data) => {
    this.setState({
      ApplicationValues: data.result,
    });
  };

  GetAllApplication() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      application.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllApplicationResponse
    );
  }

  GetAllApplicationResponse = (data) => {
    if (data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        Applications: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  GetAllDistrict() {
    CallService(
      district.GetAll,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  GetAllTaluk(districtId) {
    let { ListRequestModel } = this.state;
    ListRequestModel.District = districtId;
    ListRequestModel.pageLimit = 1000;

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  }

  GetAllTalukResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  handleSubmit = (values) => {
    var details = {};
    //details.SubmittedOnDate = new Date(values.SubmittedOnDate).getTime();
    // details.SubmittedOn = new Date(values.SubmittedOnDate).getTime();
    details.ValidFrom = new Date(values.ValidFrom).getTime();
    details.ValidTo = new Date(values.ValidTo).getTime();
    details.SerialNumber = values.SerialNumber;
    details.GONumber = values.GONumber;
    //   details.Remarks = values.Remarks ? values.Remarks : "";
    //  details.Grounds = values.Grounds ? values.Grounds : "";
    details.District = values.District ? values.District : null;

    if (values.Taluks[0]?.value) {
      details.Taluks = values.Taluks.map((taluk) => {
        return taluk.value;
      });
    } else {
      details.Taluks = values.Taluks;
    }

    details.GO = values.GO ? values.GO : "";

    // if (!this.state.buttonAction) {

    this.setState((prevState) => ({
      AreaExtensionApplications: [
        {
          ...prevState.AreaExtensionApplications[0],
          District: details.District,
        },
      ],
      NotaryApplication: details,
      activeFirstTab: 2,
    }));
  };
  handleRenewalApplication = (values) => {
    if (values) {
      var isValidAll = true;
      var errorItem = null;
      for (var i = 0; i < values.length; i++) {
        if (
          values[i] &&
          // values.RenewalApplications[i].SubmittedOnDate &&
          values[i].ValidFrom &&
          values[i].ValidTo &&
          values[i].GONumber &&
          values[i].GO
        ) {
        } else {
          isValidAll = false;
          errorItem = values[i];
          break;
        }
      }

      if (isValidAll) {
        var details = {};
        details = values;
        for (var i = 0; i < values.length; i++) {
          // details.RenewalApplications[i].SubmittedOnDate = new Date(
          //   values.RenewalApplications[i].SubmittedOnDate
          // ).getTime();
          // details.RenewalApplications[i].SubmittedOn = new Date(
          //   values.RenewalApplications[i].SubmittedOnDate
          // ).getTime();
          details[i].ValidFrom = new Date(values[i].ValidFrom).getTime();
          details[i].ValidTo = new Date(values[i].ValidTo).getTime();
          details[i].GONumber = values[i].GONumber;
          details[i].GO = values[i].GO;
        }
        this.setState({
          RenewalApplications: details,
          activeFirstTab: 3,
        });
      } else {
        toastr.error("", "Please fill all field");
      }
    } else {
      this.setState({
        activeFirstTab: 3,
        RenewalApplications: [],
      });
    }
  };

  handleAreaExtensionApplication = (values) => {
    if (values) {
      var isValidAll = true;
      var errorItem = null;

      for (var i = 0; i < values.length; i++) {
        if (!Object.keys(values[i]).length) {
          isValidAll = true;
        } else {
          if (
            values[i] &&
            values[i].District &&
            values[i].Taluks &&
            //  values.AreaExtensionApplications[i].SubmittedOnDate &&
            values[i].GONumber &&
            values[i].GO
          ) {
            isValidAll = true;
          } else {
            isValidAll = false;
            errorItem = values[i];
            break;
          }
        }
      }

      if (isValidAll) {
        if (!this.state.loading) {
          var details = {};
          details = values;
          for (var i = 0; i < values.length; i++) {
            if (Object.keys(values[i]).length) {
              // details.AreaExtensionApplications[i].SubmittedOnDate = new Date(
              //   values.AreaExtensionApplications[i].SubmittedOnDate
              // ).getTime();

              // details.AreaExtensionApplications[i].SubmittedOn = new Date(
              //   values.AreaExtensionApplications[i].SubmittedOnDate
              // ).getTime();

              details[i].District = values[i].District.value
                ? values[i].District.value
                : values[i].District;
              //  details.AreaExtensionApplications[i].District = values[0].District;
              details[i].Taluks = values[i].Taluks.map((obj) =>
                obj.value ? obj.value : obj
              );
              details[i].GONumber = values[i].GONumber;
              details[i].GO = values[i].GO;
            }
          }

          this.setState({
            buttonAction: true,
            loading: true,
            manageModal: false,
            AreaExtensionApplications: details,
          });

          let formData = new FormData();
          if (typeof this.state.NotaryApplication.GO === "object") {
            formData.append(
              `NotaryApplication`,
              this.state.NotaryApplication.GO
            );
          }

          for (var i = 0; i < this.state.RenewalApplications.length; i++) {
            if (typeof this.state.RenewalApplications[i]?.GO === "object") {
              formData.append(
                `RenewalApplications${i}`,
                this.state.RenewalApplications[i].GO
              );
            }
          }

          for (
            var i = 0;
            i < this.state.AreaExtensionApplications.length;
            i++
          ) {
            if (
              typeof this.state.AreaExtensionApplications[i]?.GO === "object"
            ) {
              formData.append(
                `AreaExtensionApplications${i}`,
                this.state.AreaExtensionApplications[i].GO
              );
            }
          }

          for (var value of formData.values()) {
            if (value) {
              return CallService(
                documentMaster.FileUpload,
                MethodType.POST,
                false,
                formData,
                "",
                this.DocumentUploaded,
                true
              );
            }
          }
          this.manageApplication();
        }
      }
    } else {
      this.setState({
        AreaExtensionApplications: [],
        manageModal: false,
        loading: true,
      });
    }
  };

  DocumentUploaded = (data) => {
    if (data.result) {
      var areaDetails = this.state.AreaExtensionApplications;
      var renewalDetails = this.state.RenewalApplications;
      var notaryDetails = this.state.NotaryApplication;

      if (data.result.AreaExtensionApplications0) {
        for (var i = 0; i < this.state.AreaExtensionApplications.length; i++) {
          if (Object.keys(this.state.AreaExtensionApplications[i]).length) {
            if (areaDetails.length > 0) {
              areaDetails[i].GO = data.result["AreaExtensionApplications" + i]
                ? data.result["AreaExtensionApplications" + i]
                : "";
            }
          }
        }
      } else {
        areaDetails = this.state.AreaExtensionApplications;
      }

      if (data.result.RenewalApplications0) {
        for (var i = 0; i < this.state.RenewalApplications.length; i++) {
          if (renewalDetails.length > 0) {
            renewalDetails[i].GO = data.result["RenewalApplications" + i]
              ? data.result["RenewalApplications" + i]
              : "";
          }
        }
      } else {
        renewalDetails = this.state.renewalDetails;
      }

      if (data.result.NotaryApplication) {
        notaryDetails.GO = data.result["NotaryApplication"];
      }

      this.setState({
        AreaExtensionApplication: areaDetails,
        renewalDetails: renewalDetails,
        NotaryApplication: notaryDetails,
      });
      this.manageApplication();
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload Failed");
    }
  };

  toggleManageModal = () => {
    this.setState({
      NotaryApplication: {
        //  SerialNumber: "",
        //  SubmittedOn: null,
        //  SubmittedOnDate: null,
        ValidFrom: null,
        ValidTo: null,
        GO: "",
        GONumber: "",
        District: "",
        Taluks: [],
        //  Grounds: "",
      },
      RenewalApplications: [
        {
          //   SubmittedOn: null,
          //  SubmittedOnDate: null,
          ValidFrom: null,
          ValidTo: null,
          GO: "",
          GONumber: null,
        },
      ],
      AreaExtensionApplications: [
        {
          District: null,
          Taluks: [],
          // SubmittedOn: null,
          //  SubmittedOnDate: null,
          GO: "",
          GONumber: null,
        },
      ],
      activeFirstTab: 1,
      Application: {},
      manageModal: !this.state.manageModal,
    });
  };

  manageApplication = () => {
    this.setState({
      buttonAction: !this.state.buttonAction,
    });

    var finalValues = {
      NotaryApplication: this.state.NotaryApplication,
      RenewalApplications: this.state.RenewalApplications,
      AreaExtensionApplications: this.state.AreaExtensionApplications,
    };

    CallService(
      existingNotary.UpdateDetail,
      MethodType.POST,
      false,
      finalValues,
      "",
      this.manageApplicationResponse
    );
  };
  manageApplicationResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        buttonAction: !this.state.buttonAction,
      });

      toastr.success("", "Updated Successfully");
      this.componentDidMount();
      // sessionStorage.setItem("Status", data.result.Status);
      // sessionStorage.setItem("ExistingNotaryApplications", true);
      // sessionStorage.setItem(
      //   "ExistingNotaryApplicationsID",
      //   data.result ? data.result._id : ""
      // );
      // sessionStorage.setItem(
      //   "ApplicationId",
      //   data.result ? data.result._id : ""
      // );
      // //  window.location.href = "#/dashboard";
      // this.setState({
      //   loading: false,
      //   manageModal: false,
      // });

      sessionStorage.clear();
      this.props.history.push("/advocatelogin");
      window.location.reload();
    } else if (data.statusCode === "409") {
      toastr.warning("", "Existing Notary already created");
      this.setState({
        loading: false,
        manageModal: false,
      });
    } else if (data.statusCode === "422") {
      if (data.exception) {
        toastr.error("", data.exception);
      } else {
        toastr.warning("", "Something went wrong");
      }
    } else {
      toastr.error("", "Something Went Wrong");
      this.setState({
        loading: false,
        manageModal: false,
      });
    }
    //  this.toggleManageModal();
  };
  manageApplicationProcess = (values) => {
    if (this.state.ApplicationProcess.Comments !== "") {
      values = {
        Status: this.state.title,
        ApplicationProcess: this.state.ApplicationProcess.Comments,
      };

      CallService(
        applicationProcess.Update + this.state.UpdateId,
        MethodType.PATCH,
        false,
        values,
        "",
        this.manageProcessResponse
      );
    } else {
      toastr.error("", "Please enter your comments");
    }
  };
  manageProcessResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Application Processed Successfully");
      this.toggleManageModal();
      this.GetAllApplication();
    }
  };
  toggleProcessModal = (row, status) => {
    this.setState({
      processModal: !this.state.processModal,
      UpdateId: row ? row._id : "",
      title: status,
    });
  };

  toggleApplicationModal(row, title) {
    if (title) {
      this.setState({
        alretView: false,
        ApplicationTitle: title,
        ListRowId: row,
        applicationModal: !this.state.applicationModal,
      });
    } else {
      this.setState({
        alretView: false,
        applicationModal: !this.state.applicationModal,
      });
    }
  }
  toggleAlretView(row, title) {
    if (this.state.Eligibility && this.state.Eligibility.Reason !== "EXPIRED") {
      if (title === "Renewal") {
        if (
          this.state.Eligibility &&
          this.state.Eligibility.Renewal
          // this.state.RenewalStatus === "" ||
          // this.state.RenewalStatus === "Approved" ||
          // this.state.RenewalStatus === "Rejected"
        ) {
          this.setState({
            ApplicationID: row,
            //     alretView: !this.state.alretView,
            RenewalFormModal: !this.state.RenewalFormModal,
            RenewalFormFields:
              this.state.Eligibility &&
              this.state.Eligibility.RenewalApplicationFields,
            ApplicationFields: {
              RenewFrom: moment(
                new Date(
                  this.state.Eligibility &&
                    this.state.Eligibility.RenewalApplicationFields &&
                    this.state.Eligibility.RenewalApplicationFields.RenewFrom
                )
              ).format("DD/MM/YYYY"),
              // RenewFrom:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.RenewFrom
              //     ? new Date(
              //         this.state.Eligibility.RenewalApplicationFields.RenewFrom
              //       )
              //     : "",
              LastRenewalGONumber: "",
              LastRenewedDate: "",
              NotaryAppointmentGONumber: "",
              NotaryAppointmentGODate: "",
              // NotaryAppointmentGODate:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields
              //     .NotaryAppointmentGODate
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .NotaryAppointmentGODate
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              NotaryAppointmentDate: "",
              // NotaryAppointmentDate:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields
              //     .NotaryAppointmentGODate
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .NotaryAppointmentGODate
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              Areas:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Areas
                  ? this.state.Eligibility.RenewalApplicationFields.Areas
                  : "",
              ValidFrom: "",
              ValidTo: "",
              // ValidFrom:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.ValidFrom
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .ValidFrom
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              // ValidTo:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.ValidTo
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields.ValidTo
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              // RenewalBefore:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.RenewalBefore
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .RenewalBefore
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              // Reason: "",
              Name:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Name
                  ? this.state.Eligibility.RenewalApplicationFields.Name
                  : "",
              Place: "",
              Date:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Date
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.RenewalApplicationFields &&
                          this.state.Eligibility.RenewalApplicationFields.Date *
                            1000
                      )
                    ).format("DD/MM/YYYY")
                  : "",
            },
            ApplicationTitle: title,
          });
        } else if (this.state.RenewalDueDate) {
          this.setState({
            RenewalModal: true,
          });
        }
      } else if (title === "Duplicate") {
        if (
          this.state.DuplicateStatus === "" ||
          this.state.DuplicateStatus === "Approved" ||
          this.state.DuplicateStatus === "Rejected"
        ) {
          this.setState({
            ApplicationID: row,
            //   alretView: !this.state.alretView,
            DuplicateFormModal: !this.state.DuplicateFormModal,
            // DuplicateFormFields:
            // this.state.Eligibility &&
            // this.state.Eligibility.DuplicateApplicationFields,

            DuplicateFormFields: {
              NotaryAppointmentGONumber: "",
              NotaryAppointmentGODate:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields
                  .NotaryAppointmentGODate
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .NotaryAppointmentGODate
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              NotaryAppointmentDate:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields
                  .NotaryAppointmentGODate
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .NotaryAppointmentGODate
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              Areas:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields.Areas
                  ? this.state.Eligibility.DuplicateApplicationFields.Areas
                  : "",

              Reason: "",
              Date:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields.Date
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .Date * 1000
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              Place: "",
            },
            ApplicationTitle: title,
          });
        }
      } else {
        this.setState({
          alretView: false,
        });
      }
    }
  }

  handleConfirmApplication = (values) => {
    var apis;
    values === "Duplicate"
      ? (apis = newapplication.CreateDuplicate)
      : values === "Renewal"
      ? (apis = newapplication.CreateRenewal)
      : (apis = newapplication.Create);

    var applicant = this.state.ApplicationValues;
    var ApplicationResquest = {
      FatherOrHusbandName:
        applicant.Applicant && applicant.Applicant.FatherOrHusbandName,
      IsMarried: applicant.Applicant && applicant.Applicant.IsMarried,
      PracticingSince:
        applicant.Applicant && applicant.Applicant.PracticingSince,
      PracticingIn: applicant.Applicant && applicant.Applicant.PracticingIn,
      IncomeTaxAssessee:
        applicant.Applicant && applicant.Applicant.IncomeTaxAssessee,
      FathersName: applicant.Applicant && applicant.Applicant.FathersName,
      Name: applicant.Applicant && applicant.Applicant.Name,
      MobileNumber: applicant.Applicant && applicant.Applicant.MobileNumber,
      BarCouncilNumber:
        applicant.Applicant && applicant.Applicant.BarCouncilNumber,
      Dob: applicant.Applicant && applicant.Applicant.Dob,
      EmailID: applicant.Applicant && applicant.Applicant.EmailID,
      Gender:
        applicant.Applicant &&
        applicant.Applicant.Gender &&
        applicant.Applicant.Gender._id,
      Community:
        applicant.Applicant &&
        applicant.Applicant.Community &&
        applicant.Applicant.Community._id,
      Aadhar: applicant.CreatedBy && applicant.CreatedBy.Aadhar,
      EnrolmentDate: applicant.CreatedBy && applicant.CreatedBy.EnrolmentDate,
      LandlineNumber: applicant.CreatedBy && applicant.CreatedBy.LandlineNumber,
      PANNumber: applicant.CreatedBy && applicant.CreatedBy.PANNumber,
      Fax: applicant.CreatedBy && applicant.CreatedBy.Fax,
      ResidencyAddress: {
        Address: {
          Line1:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line1,
          Line2:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line2,
          Line3:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line3,
          District:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.District &&
            applicant.CreatedBy.ResidencyAddress.Address.District._id
              ? applicant.CreatedBy.ResidencyAddress.Address.District._id
              : applicant.CreatedBy.ResidencyAddress.Address.District,

          State:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.State &&
            applicant.CreatedBy.ResidencyAddress.Address.State._id
              ? applicant.CreatedBy.ResidencyAddress.Address.State._id
              : applicant.CreatedBy.ResidencyAddress.Address.State,
          Taluk:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.Taluk &&
            applicant.CreatedBy.ResidencyAddress.Address.Taluk._id
              ? applicant.CreatedBy.ResidencyAddress.Address.Taluk._id
              : applicant.CreatedBy.ResidencyAddress.Address.Taluk,
          Pincode:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.Pincode,
          ProofType:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.ProofType,
          IDProofNumber:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.IDProofNumber,
          ProofUrl:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.ProofUrl,
        },
      },
      ProvisionalAddress: {
        Address: {
          Line1:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line1,
          Line2:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line2,
          Line3:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line3,
          District:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.District &&
            applicant.CreatedBy.ProvisionalAddress.Address.District._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.District._id
              : applicant.CreatedBy.ProvisionalAddress.Address.District,
          State:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.State &&
            applicant.CreatedBy.ProvisionalAddress.Address.State._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.State._id
              : applicant.CreatedBy.ProvisionalAddress.Address.State,
          Taluk:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.Taluk &&
            applicant.CreatedBy.ProvisionalAddress.Address.Taluk._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.Taluk._id
              : applicant.CreatedBy.ProvisionalAddress.Address.Taluk,
          Pincode:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.Pincode,
          ProofType:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.ProofType,
          IDProofNumber:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.IDProofNumber,
          ProofUrl:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.ProofUrl,
        },
      },

      Taluks: applicant
        ? applicant.Taluks
          ? applicant.Taluks.map((taluk) => {
              return taluk._id;
            })
          : ""
        : "",
      District:
        applicant.District && applicant.District._id
          ? applicant.District._id
          : applicant.District,
      //  Remarks: applicant.Remarks ? applicant.Remarks : "",
      //   Grounds: applicant.Grounds ? applicant.Grounds : "",
    };

    if (this.state.ApplicationTitle === "Renewal") {
      ApplicationResquest.ApplicationFields = this.state.RenewalFormFields;
    }
    if (this.state.ApplicationTitle === "Duplicate") {
      ApplicationResquest.ApplicationFields = this.state.DuplicateSubmitValue;
    }
    console.log(ApplicationResquest);
    CallService(
      apis,
      MethodType.PUT,
      false,
      ApplicationResquest,
      "",
      this.handleConfirmApplicationResponse
    );
  };
  handleConfirmApplicationResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      // toastr.success(
      //   "",
      //   `${this.state.ApplicationTitle} application processed successfully`
      // );
      if (this.state.ApplicationTitle === "Renewal") {
        sessionStorage.setItem(
          "RenewalStatus",
          data.result.Status ? data.result.Status : "Payment Pending"
        );
        this.setState({
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
      } else {
        sessionStorage.setItem(
          "DuplicateStatus",
          data.result.Status ? data.result.Status : "Payment Pending"
        );
        this.setState({
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
      }
      //  window.location.reload();

      this.setState(
        {
          DuplicateFormModal: false,
          RenewalFormModal: false,
          buttonAction: false,
          alretView: false,
          FeeTotalAmount:
            data.result &&
            data.result.FeeDetails &&
            data.result.FeeDetails.TotalAmount,
        },
        () => {
          // this.GetAllEligibility();
          //   window.location.reload();
          // this.confirmMakingPayment();
        }
      );
    } else if (data.statusCode === "422") {
      if (data.exception) {
        toastr.error("", data.exception);
      } else {
        toastr.warning("", "Something went wrong");
      }
    } else {
      toastr.error("", "Something Went Wrong");
    }
    this.setState({
      buttonAction: false,
    });
  };

  GetDetailExistingNotary() {
    CallService(
      existingNotary.GetDetail,
      MethodType.GET,
      false,
      "",
      "",
      this.GetDetailExistingNotaryResponse
    );
  }

  GetDetailExistingNotaryResponse = (data) => {
    if (data.result) {
      this.setState({
        ExistingDetailEntity: data.result,
        IsEdited: data.result.editAllowed ? false : true,
        RenewalApplications:
          data.result?.existingNotaryApplication?.RenewalApplications,
        AreaExtensionApplications:
          data.result?.existingNotaryApplication?.AreaExtensionApplications,
      });
    }
  };

  handleNewNotary = () => {
    this.props.history.push("/notaryapplication");
  };
  handleContinueNotatry = () => {
    this.props.history.push("/notaryapplication");
  };

  handleNotatryApplication = () => {
    this.props.history.push("/notaryapplication");
  };

  confirmMakingPayment = () => {
    this.setState({
      MakingPaymentModal: !this.state.MakingPaymentModal,
    });
  };

  handleMakingPayment() {
    var apis;
    this.state.ApplicationTitle === "Renewal"
      ? (apis = renewalApplication.GetMaking + this.state.RenewalApplicationID)
      : this.state.ApplicationTitle === "Duplicate"
      ? (apis =
          duplicateCertificateApplication.GetMaking +
          this.state.DuplicateApplicationID)
      : (apis = newapplication.GetMaking);

    if (
      this.state.ApplicationTitle === "Renewal" ||
      this.state.ApplicatibindonTitle === "Duplicate"
    ) {
      CallService(
        apis,
        MethodType.GET,
        false,
        "",
        "",
        this.handleMakingPaymentResponse
      );
    } else {
      var NOCValues = {
        NOC: this.state.NOC,
        ApplicationId: this.state.ApplicationId,
      };
      console.log(NOCValues);
      CallService(
        apis,
        MethodType.POST,
        false,
        NOCValues,
        "",
        this.handleMakingPaymentResponse
      );
    }
  }

  handleMakingPaymentResponse = (data) => {
    if (data.result) {
      // toastr.success("", `Application processed successfully`);
      if (data.result) {
        sessionStorage.setItem(
          "Status",
          data.result.Status ? data.result.Status : ""
        );
        sessionStorage.setItem("IsArchived", true);
        this.setState({
          confirmMakingPayment: false,
          nocAttachmentModal: false,
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
        // window.location.reload();
      }
    } else toastr.error("", "Something went wrong");
  };

  toggleNocAttachment = () => {
    this.setState({
      nocAttachmentModal: !this.state.nocAttachmentModal,
    });
  };
  handleNOC = (values) => {
    if (values) {
      console.log(values);
      this.setState({
        buttonLoading: true,
      });
      let formData = new FormData();

      formData.append("NOC", values.NOC);

      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.NOCUpload,
        true
      );
    }
  };

  NOCUpload = (data) => {
    if (data.result) {
      this.setState(
        {
          buttonLoading: false,
          NOC: data.result.NOC,
        },
        () => {
          //   this.confirmMakingPayment();
          this.handleMakingPayment();
        }
      );
    }
  };

  toggleRenewalForm = () => {
    this.setState({
      RenewalFormModal: !this.state.RenewalFormModal,
    });
  };
  handleRenewalForm = (values) => {
    console.log(values);
    var renewal = values;

    renewal.RenewFrom =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.RenewFrom;
    renewal.LastRenewedDate =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.LastRenewedDate;

    // renewal.NotaryAppointmentDate =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.NotaryAppointmentDate;

    // renewal.NotaryAppointmentGODate =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.NotaryAppointmentGODate;

    // renewal.RenewalBefore = new Date(
    //   this.state.ApplicationFields.RenewalBefore
    // ).getTime();
    renewal.Date =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.Date;
    renewal.NotaryAppointmentDate = new Date(
      values.NotaryAppointmentDate
    ).getTime();
    renewal.NotaryAppointmentGODate = new Date(
      values.NotaryAppointmentGODate
    ).getTime();
    renewal.ValidFrom = new Date(values.ValidFrom).getTime();
    renewal.ValidTo = new Date(values.ValidTo).getTime();
    // renewal.ValidFrom =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.ValidFrom;

    // renewal.ValidTo =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.ValidTo;

    this.setState({
      RenewalFormFields: renewal,
    });
    if (
      values.RenewFrom !== "" &&
      // values.LastRenewalGONumber !== "" &&
      // values.LastRenewedDate !== "" &&
      values.NotaryAppointmentGONumber !== "" &&
      values.NotaryAppointmentGODate !== "" &&
      values.NotaryAppointmentDate !== "" &&
      values.Areas !== "" &&
      values.ValidFrom !== "" &&
      values.ValidTo !== "" &&
      // values.RenewalBefore !== "" &&
      //  values.Reason !== "" &&
      values.Name !== "" &&
      values.Place !== ""
    ) {
      this.handleConfirmApplication("Renewal");
    } else {
      toastr.error("", "Please fill all field");
    }
  };

  toggleDuplicateForm = () => {
    this.setState({
      DuplicateFormModal: !this.state.DuplicateFormModal,
    });
  };
  handleDuplicateForm = (values) => {
    console.log(values);
    var duplicate = values;

    if (
      values.NotaryAppointmentGONumber !== "" &&
      values.NotaryAppointmentGODate !== "" &&
      values.NotaryAppointmentDate !== "" &&
      values.Reason !== "" &&
      values.Areas !== "" &&
      values.Place !== ""
    ) {
      duplicate.NotaryAppointmentDate =
        this.state.Eligibility &&
        this.state.Eligibility.DuplicateApplicationFields &&
        this.state.Eligibility.DuplicateApplicationFields.NotaryAppointmentDate;
      duplicate.NotaryAppointmentGODate =
        this.state.Eligibility &&
        this.state.Eligibility.DuplicateApplicationFields &&
        this.state.Eligibility.DuplicateApplicationFields
          .NotaryAppointmentGODate;

      duplicate.Date =
        this.state.Eligibility &&
        this.state.Eligibility.DuplicateApplicationFields &&
        this.state.Eligibility.DuplicateApplicationFields.Date;

      // duplicate.NotaryAppointmentGODate = new Date(
      //   this.state.DuplicateFormFields.NotaryAppointmentGODate
      // ).getTime();
      // duplicate.Date = new Date(this.state.DuplicateFormFields.Date).getTime();

      this.setState({
        DuplicateSubmitValue: duplicate,
      });
      this.handleConfirmApplication("Duplicate");
    } else {
      toastr.error("", "Please fill all field");
    }
  };
  // PaymentInitiate = (text) => {
  //   var apis;
  //   var id;
  //   if (text === "Renewal") {
  //     apis = renewalApplication.GetMaking;
  //     id = this.state.RenewalApplicationID;
  //   } else {
  //     apis = duplicateCertificateApplication.GetMaking;
  //     id = this.state.DuplicateApplicationID;
  //   }

  //   CallService(
  //     // newapplication.PaymentInitialize + event._id,
  //     // duplicateCertificateApplication.GetMaking,
  //     apis,
  //     MethodType.POST,
  //     false,
  //     { ApplicationId: id },
  //     "",
  //     this.PaymentInitiateResponse
  //   );
  // };

  // PaymentInitiateResponse = (data) => {
  //
  //   if (data) {
  //     this.setState({
  //       InitiateResponse: data.result,
  //       MakingPaymentModal: true,
  //     });
  //   }
  // };

  SubmitPayment = (message) => {
    CallService(
      newapplication.GetPaymentResponse,
      MethodType.POST,
      false,
      // { ApplicationId: this.state.AreaExtensionId },
      { msg: message, isFromUrl: true },
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentStatus: data.result,
      });
    }
  };
  toggleRenewalModal = () => {
    this.setState({
      RenewalModal: !this.state.RenewalModal,
    });
  };

  render() {
    const initialValues =
      this.state.ExistingDetailEntity?.existingNotaryApplication
        ?.NotaryApplication;
    const initialRenewalValues = this.state.RenewalApplications;
    const initialAreaValues = this.state.AreaExtensionApplications;
    const initialNOCValues = this.state.NOC;
    //  const initialApplicationField = this.state.ApplicationFields;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4">
              <CardBody>
                <Row className="mt-4">
                  <Colxx xxs="5">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0 font-size-18">
                        {this.props.t(this.state.CurrentApplication)}
                      </h4>
                    </div>
                  </Colxx>

                  <Colxx
                    style={{
                      color: "red",
                      textAlign: "right",
                    }}
                  >
                    {this.state.IsEdited
                      ? this.props.t(
                          "Cannot edit profile as you have already applied for an application"
                        )
                      : ""}
                  </Colxx>
                </Row>

                <Nav tabs className="m-3 nav-tabs-custom nav-justified">
                  {this.state.tabs.map((t) => {
                    return (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={{
                            active: this.state.activeFirstTab === t.id,
                          }}
                          onClick={() => {
                            if (
                              this.state.IsExistingNotaryApplications === "true"
                            ) {
                              this.toggleCustomJustified(t.id);
                            } else if (this.state.IsEdited === true) {
                              this.toggleCustomJustified(t.id);
                            }
                          }}
                        >
                          <span className="d-none d-sm-block">{t.Name}</span>
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>

                <Formik
                  initialValues={initialValues}
                  validationSchema={ExistingNotatryValidation}
                  onSubmit={this.handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                  disabled={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={1}>
                          <Fragment>
                            <Row>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t("Appointment Date")}
                                  </Label>

                                  <DatePicker
                                    autoComplete="nope"
                                    disabled={this.state.IsEdited}
                                    showYearDropdown
                                    showMonthDropdown
                                    value={new Date(
                                      values.ValidFrom
                                    ).toLocaleDateString()}
                                    // selected={
                                    //   this.state.NotaryApplication.ValidFrom
                                    // }
                                    className="form-control"
                                    selectsStart
                                    startDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    maxDate={new Date().getTime()}
                                    endDate={
                                      this.state.NotaryApplication.ValidTo
                                    }
                                    onChange={(date) => {
                                      let dateAr = this.state.NotaryApplication;
                                      dateAr.ValidFrom = date;
                                      dateAr.ValidTo = "";
                                      this.setState({
                                        NotaryApplication: dateAr,
                                      });
                                      values.ValidFrom = date;
                                    }}
                                    placeholderText={"DD/MM/YYYY"}
                                    dateFormat="dd/MM/yyyy"
                                  />

                                  {errors.ValidFrom && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ValidFrom}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Expiry Date")}{" "}
                                  </Label>
                                  <DatePicker
                                    disabled={this.state.IsEdited}
                                    showYearDropdown
                                    showMonthDropdown
                                    // selected={
                                    //   this.state.NotaryApplication.ValidTo
                                    // }
                                    value={new Date(
                                      values.ValidTo
                                    ).toLocaleDateString()}
                                    className="form-control"
                                    selectsEnd
                                    startDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    endDate={
                                      this.state.NotaryApplication.ValidTo
                                    }
                                    minDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    onChange={(date) => {
                                      let dateAr = this.state.NotaryApplication;
                                      dateAr.ValidTo = date;
                                      this.setState({
                                        NotaryApplication: dateAr,
                                      });
                                      values.ValidTo = date;
                                    }}
                                    placeholderText={"DD/MM/YYYY"}
                                    dateFormat="dd/MM/yyyy"
                                    autoComplete="nope"
                                  />
                                  {errors.ValidTo && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ValidTo}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Appointment GO Number")}
                                  </Label>
                                  <Field
                                    disabled={this.state.IsEdited}
                                    type="text"
                                    className="form-control"
                                    name="GONumber"
                                    placeholder="Go Number"
                                  />
                                  {errors.GONumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.GONumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx style={{ zIndex: 0 }} xxs="6">
                                <Label className="requiredField">
                                  {this.props.t("Appointment GO Copy")}
                                </Label>
                                <FormGroup className="form-group has-float-label">
                                  <CustomInput
                                    disabled={this.state.IsEdited}
                                    key={"GO"}
                                    type="file"
                                    name={"GO"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "GO",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  {errors.GO ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.GO}
                                    </div>
                                  ) : null}
                                  {/* <p className="text-semi-muted">
                                    Allowed formats are png, doc, pdf, jpg and
                                    jpeg
                                    {this.state.IsExistingNotaryApplications ===
                                    "true" ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={
                                            this.state.NotaryApplication.GO
                                          }
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </p> */}
                                  <p className="text-semi-muted">
                                    {getFileSizeText(5, "MB")}

                                    <p className="text-semi-muted">
                                      {getAllowedExtensionText("pdf", [
                                        "png",
                                        "doc",
                                        "pdf",
                                        "jpg",
                                        "jpeg",
                                      ])}
                                      {values.GO ? (
                                        <p
                                          style={{
                                            color: "blue",
                                            cursor: "Pointer",
                                            textAlign: "right",
                                          }}
                                        >
                                          <FileDisplay Value={values.GO} />
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </p>
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    components={{ Input: CustomSelectInput }}
                                    classNamePrefix="react-select"
                                    labelClassName="requiredField"
                                    isSearchable
                                    label={this.props.t("District")}
                                    isClearable={true}
                                    isDisabled={this.state.IsEdited}
                                    value={
                                      values.District &&
                                      (typeof values.District === "string"
                                        ? this.state.DistrictList.find(
                                            (r) => r.value === values.District
                                          )
                                        : this.state.DistrictList.find(
                                            (r) => r.value === values.District
                                          ))
                                    }
                                    options={this.state.DistrictList}
                                    ClearAction={() => {
                                      setFieldValue("District", null);
                                      setFieldValue("Taluks", []);
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "District",
                                        entity ? entity.value : null
                                      );
                                      values.Taluks = [];
                                      this.GetAllTaluk(
                                        entity ? entity.value : ""
                                      );
                                    }}
                                  />
                                  {errors.District ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.District}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    isDisabled={this.state.IsEdited}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="Taluk"
                                    labelClassName="requiredField"
                                    label={this.props.t("Taluks")}
                                    isMulti
                                    isSearchable
                                    options={this.state.TalukList}
                                    value={
                                      values &&
                                      values.Taluks &&
                                      this.state.TalukList.filter((a) => {
                                        return values.Taluks.some((r) => {
                                          return (
                                            a.value ===
                                            (typeof r === "object"
                                              ? r._id
                                                ? r._id
                                                : r.value
                                              : r)
                                          );
                                        });
                                      }).map(function (o) {
                                        return o;
                                      })
                                    }
                                    ClearAction={() => {
                                      setFieldValue("Taluks", "");
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "Taluks",
                                        entity ? entity : ""
                                      );

                                      var filterValues =
                                        this.state.TalukList.length > 0 &&
                                        this.state.TalukList.filter(function (
                                          val
                                        ) {
                                          return entity
                                            ? entity.indexOf(val) === -1
                                            : null;
                                        });

                                      this.setState({
                                        TaluksEntity: entity,
                                        FilterTalukValues: filterValues,
                                      });
                                    }}
                                  />
                                  {errors.Taluks ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.Taluks}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Colxx>
                              {/* <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Grounds")}</Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    component="textarea"
                                    autoFocus
                                    className="form-control"
                                    name="Grounds"
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Remarks")}</Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    component="textarea"
                                    autoFocus
                                    className="form-control"
                                    name="Remarks"
                                  />
                                </FormGroup>
                              </Colxx> */}
                            </Row>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {this.state.IsEdited ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Next")}
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => {
                                this.props.history.push("/dashboard");
                              }}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialRenewalValues}
                  //  validationSchema={ApplicationValidation}
                  onSubmit={this.handleRenewalApplication}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={2}>
                          <Fragment>
                            <Row>
                              {this.state.RenewalApplications
                                ? this.state.RenewalApplications.length > 0
                                  ? this.state.RenewalApplications.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            {/* <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {" "}
                                                  {this.props.t(
                                                    "Submitted Date"
                                                  )}
                                                </Label>
                                                <DatePicker
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].SubmittedOnDate
                                                  }
                                                  onChange={(date) => {
                                                    var dateAr = this.state
                                                      .RenewalApplications;
                                                    dateAr[
                                                      Index
                                                    ].SubmittedOnDate = date;

                                                    this.setState({
                                                      RenewalApplications: dateAr,
                                                    });
                                                    setFieldValue(
                                                      `RenewalApplications[${Index}].SubmittedOnDate`,
                                                      dateAr[Index]
                                                        .SubmittedOnDate
                                                    );
                                                  }}
                                                  placeholderText="dd/mm/yyyy"
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx> */}

                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {" "}
                                                  {this.props.t("Renewed From")}
                                                </Label>
                                                <DatePicker
                                                  autoComplete="nope"
                                                  disabled={this.state.IsEdited}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  className="form-control"
                                                  selectsStart
                                                  startDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  maxDate={new Date().getTime()}
                                                  endDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  onChange={(date) => {
                                                    if (date) {
                                                      var dateAr =
                                                        this.state
                                                          .RenewalApplications;

                                                      dateAr[Index].ValidFrom =
                                                        date;

                                                      var validto = moment(
                                                        date
                                                      ).add(5, "year");

                                                      dateAr[Index].ValidTo =
                                                        validto._d;

                                                      this.setState({
                                                        RenewalApplications:
                                                          dateAr,
                                                      });
                                                      setFieldValue(
                                                        `[${Index}].ValidFrom`,
                                                        dateAr[Index].ValidFrom
                                                      );
                                                      setFieldValue(
                                                        `[${Index}].ValidTo`,
                                                        dateAr[Index].ValidTo
                                                      );
                                                    }
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t(
                                                    "Renewed To (5 Years)"
                                                  )}{" "}
                                                </Label>
                                                <DatePicker
                                                  disabled={this.state.IsEdited}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  className="form-control"
                                                  selectsEnd
                                                  startDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  endDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  minDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  //   isClearable={true}
                                                  onChange={(date) => {
                                                    var dateAr =
                                                      this.state
                                                        .RenewalApplications;
                                                    dateAr[Index].ValidTo =
                                                      date;

                                                    this.setState({
                                                      RenewalApplications:
                                                        dateAr,
                                                    });
                                                    setFieldValue(
                                                      `RenewalApplications[${Index}].ValidTo`,
                                                      dateAr[Index].ValidTo
                                                    );
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t(
                                                    "Last Renewal GO Number"
                                                  )}
                                                </Label>
                                                <Field
                                                  disabled={this.state.IsEdited}
                                                  type="text"
                                                  className="form-control"
                                                  name={`[${Index}].GONumber`}
                                                  onChange={(event) => {
                                                    if (event) {
                                                      setFieldValue(
                                                        `RenewalApplications[${Index}].GONumber`,
                                                        event.target.value
                                                      );
                                                      setFieldValue(
                                                        `[${Index}].GONumber`,
                                                        event.target.value
                                                      );
                                                    } else {
                                                      delete values
                                                        .RenewalApplications[
                                                        Index
                                                      ].GONumber;
                                                    }
                                                  }}
                                                  placeholder="GO Number"
                                                />
                                                {errors.GONumber && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GONumber}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx
                                              style={{ zIndex: 0 }}
                                              xxs="5"
                                            >
                                              <Label>
                                                {this.props.t(
                                                  "Last Renewal GO Copy"
                                                )}
                                              </Label>
                                              <FormGroup className="form-group has-float-label">
                                                <CustomInput
                                                  disabled={this.state.IsEdited}
                                                  key={"GO"}
                                                  type="file"
                                                  name={"GO"}
                                                  onChange={(event) => {
                                                    if (event) {
                                                      setFieldValue(
                                                        `[${Index}].GO`,
                                                        event.target.files[0]
                                                      );
                                                    } else {
                                                      delete values[Index].GO;
                                                    }
                                                  }}
                                                />

                                                {errors.GO ? (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GO}
                                                  </div>
                                                ) : null}
                                                <p className="text-semi-muted">
                                                  {getFileSizeText(5, "MB")}

                                                  <p className="text-semi-muted">
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "png",
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {values[Index].GO ? (
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state
                                                              .RenewalApplications[
                                                              Index
                                                            ].GO
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>
                                                {/* <p className="text-semi-muted">
                                                  Allowed formats are png, doc,
                                                  pdf, jpg and jpeg
                                                  {this.state
                                                    .IsExistingNotaryApplications ===
                                                  "true" ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state
                                                            .RenewalApplications[
                                                            Index
                                                          ].GO
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p> */}
                                              </FormGroup>
                                            </Colxx>

                                            <div
                                              style={{
                                                marginTop: "33px",
                                              }}
                                            >
                                              {this.state.RenewalApplications
                                                .length > 1 && (
                                                <i
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  className="bx bx-minus-circle cursorPointer"
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .RenewalApplications;

                                                    selectedProduct.splice(
                                                      Index,
                                                      1
                                                    );
                                                    this.setState({
                                                      RenewalApplications:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                              &nbsp;&nbsp;&nbsp;
                                              {this.state.RenewalApplications
                                                .length ===
                                                Index + 1 && (
                                                <i
                                                  className="bx bx-plus-circle cursorPointer"
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .RenewalApplications;
                                                    selectedProduct.push({
                                                      //    SubmittedOn: "",
                                                      //    SubmittedOnDate: "",
                                                      ValidFrom: "",
                                                      ValidTo: "",
                                                      GO: "",
                                                      GONumber: "",
                                                    });
                                                    this.setState({
                                                      RenewalApplications:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""}
                            </Row>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {/* {this.state.IsExistingNotaryApplications ===
                              "true" ? (
                              ""
                            ) : ( */}
                            {this.state.IsEdited ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Next")}
                              </Button>
                            )}

                            {/* )} */}
                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => {
                                this.props.history.push("/dashboard");
                              }}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialAreaValues}
                  //  validationSchema={ApplicationValidation}
                  onSubmit={this.handleAreaExtensionApplication}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={3}>
                          <Fragment>
                            <Row>
                              {this.state.AreaExtensionApplications
                                ? this.state.AreaExtensionApplications.length >
                                  0
                                  ? this.state.AreaExtensionApplications.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <DropDown
                                                  components={{
                                                    Input: CustomSelectInput,
                                                  }}
                                                  classNamePrefix="react-select"
                                                  labelClassName=""
                                                  isSearchable
                                                  label={this.props.t(
                                                    "District"
                                                  )}
                                                  isClearable={true}
                                                  isDisabled={
                                                    this.state.IsEdited
                                                  }
                                                  value={
                                                    values[Index]?.District &&
                                                    (typeof values[Index]
                                                      .District === "string"
                                                      ? this.state.DistrictList.find(
                                                          (r) =>
                                                            r.value ===
                                                            values[Index]
                                                              .District
                                                        )
                                                      : this.state.DistrictList.find(
                                                          (r) =>
                                                            r.value ===
                                                            values[Index]
                                                              .District
                                                        ))
                                                  }
                                                  options={
                                                    this.state.DistrictList
                                                  }
                                                  ClearAction={() => {
                                                    setFieldValue(
                                                      `[${Index}].District`,
                                                      null
                                                    );
                                                  }}
                                                  Action={(entity) => {
                                                    setFieldValue(
                                                      `[${Index}].District`,
                                                      entity
                                                        ? entity.value
                                                        : null
                                                    );
                                                    this.GetAllTaluk(
                                                      entity ? entity.value : ""
                                                    );
                                                  }}
                                                />
                                              </FormGroup>
                                            </Colxx>

                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <DropDown
                                                  isDisabled={
                                                    this.state.IsEdited
                                                  }
                                                  //  labelClassName="requiredField"
                                                  components={{
                                                    Input: CustomSelectInput,
                                                  }}
                                                  className="react-select"
                                                  classNamePrefix="react-select"
                                                  name="Taluks"
                                                  label={this.props.t("Taluks")}
                                                  isMulti
                                                  isSearchable
                                                  options={
                                                    values &&
                                                    values.AreaExtensionApplications &&
                                                    values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ] &&
                                                    values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].District ===
                                                      this.state
                                                        .NotaryApplication
                                                        .District
                                                      ? this.state
                                                          .FilterTalukValues
                                                      : this.state.TalukList
                                                  }
                                                  value={
                                                    values &&
                                                    values[Index] &&
                                                    values[Index].Taluks &&
                                                    this.state.TalukList.filter(
                                                      (a) => {
                                                        return values[
                                                          Index
                                                        ].Taluks.some((r) => {
                                                          return (
                                                            a.value ===
                                                            (typeof r ===
                                                            "object"
                                                              ? r._id
                                                                ? r._id
                                                                : r.value
                                                              : r)
                                                          );
                                                        });
                                                      }
                                                    ).map(function (o) {
                                                      return o;
                                                    })
                                                  }
                                                  ClearAction={() => {
                                                    // setFieldValue(
                                                    //   `AreaExtensionApplications[${Index}].Taluks`,
                                                    //   null
                                                    // );
                                                    delete values[Index].Taluks;
                                                  }}
                                                  Action={(entity) => {
                                                    if (entity) {
                                                      setFieldValue(
                                                        `[${Index}].Taluks`,
                                                        entity ? entity : null
                                                      );
                                                    } else {
                                                      delete values[Index]
                                                        .Taluks;
                                                    }
                                                  }}
                                                />
                                              </FormGroup>
                                              {/* <p>
                                                (
                                                <label>
                                                  Practicing Taluks:
                                                </label>{" "}
                                                {this.state.TaluksEntity &&
                                                  this.state.TaluksEntity.map(
                                                    (ET, i) => {
                                                      if (i < 2) {
                                                        return ET.label + ", ";
                                                      } else {
                                                        return ".";
                                                      }
                                                    }
                                                  )}
                                                )
                                              </p> */}
                                            </Colxx>

                                            {/* <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {" "}
                                                  {this.props.t(
                                                    "Submitted Date"
                                                  )}
                                                </Label>
                                                <DatePicker
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  selected={
                                                    this.state
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].SubmittedOnDate
                                                  }
                                                  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  onChange={(date) => {
                                                    var dateAr = this.state
                                                      .AreaExtensionApplications;
                                                    dateAr[
                                                      Index
                                                    ].SubmittedOnDate = date;

                                                    this.setState({
                                                      AreaExtensionApplications: dateAr,
                                                    });
                                                    setFieldValue(
                                                      `AreaExtensionApplications[${Index}].SubmittedOnDate`,
                                                      dateAr[Index]
                                                        .SubmittedOnDate
                                                    );
                                                  }}
                                                  placeholderText="dd/mm/yyyy"
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx> */}

                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label
                                                //className="requiredField"
                                                >
                                                  {this.props.t(
                                                    "Appointment GO Number"
                                                  )}
                                                </Label>

                                                <Field
                                                  disabled={this.state.IsEdited}
                                                  onChange={(event) => {
                                                    if (event.target.value) {
                                                      setFieldValue(
                                                        `[${Index}].GONumber`,
                                                        event.target.value
                                                      );
                                                    } else {
                                                      delete values[Index]
                                                        .GONumber;
                                                    }
                                                  }}
                                                  type="text"
                                                  className="form-control"
                                                  name={`[${Index}].GONumber`}
                                                  placeholder="Go Number"
                                                />
                                                {errors.GONumber && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GONumber}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx
                                              style={{ zIndex: 0 }}
                                              xxs="5"
                                            >
                                              <Label
                                              // className="requiredField"
                                              >
                                                {this.props.t(
                                                  "Appointment GO Copy"
                                                )}
                                              </Label>
                                              <FormGroup className="form-group has-float-label">
                                                <CustomInput
                                                  disabled={this.state.IsEdited}
                                                  key={"GO"}
                                                  type="file"
                                                  name={"GO"}
                                                  onChange={(event) => {
                                                    if (event.target.files[0]) {
                                                      setFieldValue(
                                                        `[${Index}].GO`,
                                                        event.target.files[0]
                                                      );
                                                    } else {
                                                      delete values[Index].GO;
                                                    }
                                                  }}
                                                />

                                                {errors.GO ? (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GO}
                                                  </div>
                                                ) : null}
                                                <p className="text-semi-muted">
                                                  {getFileSizeText(5, "MB")}

                                                  <p className="text-semi-muted">
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "png",
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {values[Index]?.GO ? (
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state
                                                              .AreaExtensionApplications[
                                                              Index
                                                            ].GO
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>
                                                {/* <p className="text-semi-muted">
                                                  Allowed formats are png, doc,
                                                  pdf, jpg and jpeg
                                                  {this.state
                                                    .IsExistingNotaryApplications ===
                                                  "true" ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state
                                                            .AreaExtensionApplications[
                                                            Index
                                                          ].GO
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p> */}
                                              </FormGroup>
                                            </Colxx>

                                            <div
                                              style={{
                                                marginTop: "33px",
                                              }}
                                            >
                                              {this.state
                                                .AreaExtensionApplications
                                                .length > 1 && (
                                                <i
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  className="bx bx-minus-circle cursorPointer"
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .AreaExtensionApplications;

                                                    selectedProduct.splice(
                                                      Index,
                                                      1
                                                    );
                                                    // if(Object.keys(values.AreaExtensionApplications[values.AreaExtensionApplications.length - 1]).length)
                                                    // {
                                                    //   values.AreaExtensionApplications.splice(  values.AreaExtensionApplications.length - 1, 1)

                                                    // }

                                                    this.setState({
                                                      selectedProduct:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                              &nbsp;&nbsp;&nbsp;
                                              {this.state
                                                .AreaExtensionApplications
                                                .length ===
                                                Index + 1 && (
                                                <i
                                                  className="bx bx-plus-circle cursorPointer"
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .AreaExtensionApplications;
                                                    selectedProduct.push({
                                                      District: null,
                                                      Taluks: [],
                                                      //  SubmittedOn: null,
                                                      //     SubmittedOnDate: null,
                                                      GO: "",
                                                      GONumber: null,
                                                    });
                                                    this.setState({
                                                      selectedProduct:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""}
                              {/* <Colxx xxs="12">
                                {" "}
                                <p>
                                  (
                                  <label>
                                    {this.props.t("Practicing Taluks")} :
                                  </label>{" "}
                                  {this.state.TaluksEntity &&
                                    this.state.TaluksEntity.map((ET) => {
                                      return ET.label
                                        ? ET.label
                                        : this.state.Language === "ta"
                                        ? ET.TamilName
                                        : ET.Name;
                                    }).join(", ")}
                                  )
                                </p>
                              </Colxx> */}
                            </Row>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {this.state.IsEdited ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Submit")}
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => {
                                this.props.history.push("/dashboard");
                              }}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(ExistingNotaryApplication));
