import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Button,
  FormGroup,
  Spinner,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  userNameRegex,
} from "../../constants/defaultValues";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { otpValidation } from "../../helpers/validations";

import { Formik, Form, Field } from "formik";
import { Colxx } from "../../components/Common/CustomBootstrap";
// action
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../store/actions";
import { authenctication } from "../../constants/config";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";
// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
import {
  formatEncryptedField,
  encryptBox,
  Base64ToUint8Array,
  Uint8ArrayToBase64,
  decryptBox,
} from "../../helpers/encryption";
nacl.util = naclUtil;

class Register extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();

    this.state = {
      captchaVerified: false,
      loading: false,
    };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.startTimer = this.startTimer.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.registerUser(values);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");

    setTimeout(() => {
      this.getSeed();
    }, 1000);
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState(
        {
          ServerPublicKey: data.result.ServerPublicKey,
          SeedId: data.result._id,
        },
        () => {
          this.getCaptcha();
        }
      );
    }
  };

  onUserLogin = (values) => {
    if (!this.state.loading) {
      if (values.Password === values.ConfirmPassword) {
        if (
          values.Username !== "" &&
          values.MobileNumber !== "" &&
          values.OTP !== "" &&
          values.MobileNumberVerificationOTP !== "" &&
          values.EmailVerificationOTP !== "" &&
          values.SessionID !== " "
        ) {
          this.setState({ loading: true });
          this.AuthenticateUser(values);
        }
      } else {
        toastr.error(
          "",
          "Your password and confirmation password do not match"
        );
      }
    }
  };
  AuthenticateUser = (values) => {
    sessionStorage.setItem("MobileNumber", values.MobileNumber);
    sessionStorage.setItem("EmailID", values.EmailID);
    var submitValues = values;
    
    const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
    const password = values.Password;
    const { cipherText, nonceText, clientPublicKeyText } = encryptBox(
      password,
      serverPublicKey
    );
    submitValues.SessionID=0
    submitValues.Password = formatEncryptedField(
      this.state.SeedId,
      cipherText,
      nonceText,
      clientPublicKeyText
    );
    submitValues.CaptchaId =
      this.state.CaptchaDetails && this.state.CaptchaDetails.CaptchaId;

    delete submitValues.ConfirmPassword;

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);

    CallService(
      authenctication.signUp,
      MethodType.PUT,
      false,
      submitValues,
      "",
      this.AuthenticateUserResponse
    );
  };

  // onKeyUpValue(event) {
  //   return false;
  // }

  AuthenticateUserResponse = (data) => {
    this.setState({ loading: false });
    if (data.statusCode === StatusCodes.Success) {
    
      sessionStorage.setItem("user_token", data.result.token);
      sessionStorage.setItem("user_role", data.result.Role);
      sessionStorage.removeItem("OTPRequested");
      var resultString = JSON.stringify(data.result);
      sessionStorage.setItem("user_detail", resultString);
      sessionStorage.setItem("TokenIssuedTime", new Date().getTime());
      sessionStorage.setItem(
        "IsProfileCompleted",
        data.result.IsProfileCompleted
      );
      sessionStorage.setItem("user", JSON.stringify(data.result));
      this.props.history.push("/userprofile");
      // window.location.reload();
    } else if (data.statusCode === StatusCodes.Duplicate) {
      toastr.warning("", "Profile already created");
    } else {
      toastr.warning("", "Invalid OTP or Mobile Number");
    }
  };
  getOtp = (val) => {
    var Values = {};
    if (
      this.state.UniqueUserNameValidate &&
      this.state.UniqueUserMobileNumberValidate
    ) {
      if (val.MobileNumber && val.Username && val.Name && val.Captcha) {
        let mobileRegex = /^\d{10}$/;
        var mobileno = val.MobileNumber.toString();
        //let userRegux = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        var userVal = val.Username;

        if (userVal.match(userNameRegex)) {
          if (mobileno.match(mobileRegex)) {
            if (val.Captcha === this.state.captchatxt) {
              if (this.state.captchaVerified) {
                Values.MobileNumber = val.MobileNumber;
                Values.EmailID = val.EmailID;
                Values.Type = "VERIFICATION";
                this.setState(
                  { loading: false, seconds: 120, SendOtp: true },
                  () => {
                    this.startTimer();
                  }
                );
                CallService(
                  authenctication.getOTP,
                  MethodType.POST,
                  false,
                  Values,
                  "",
                  this.OtpResponse
                );
              } else {
                CallService(
                  authenctication.HandleCaptcha,
                  MethodType.POST,
                  false,
                  {
                    CaptchaText: val.Captcha,
                    CaptchaId:
                      this.state.CaptchaDetails &&
                      this.state.CaptchaDetails.CaptchaId,
                  },
                  "",
                  this.HandleCaptchaResponse
                );
              }
            } else {
              this.setState({ loading: false });
              toastr.error("", "Please Check captcha");
            }
          } else toastr.warning("", "Not a valid number");
        } else toastr.warning("", "Not a valid username");
      }
    }
  };
  resendOTP = (MobileNumber) => {
    if (MobileNumber) {
      let mobileRegex = /^\d{10}$/;
      var mobileno = MobileNumber.toString();
      if (mobileno.match(mobileRegex)) {
        this.setState({ loading: false, seconds: 120, SendOtp: true }, () => {
          this.startTimer();
        });
        CallService(
          authenctication.getOTP,
          MethodType.POST,
          false,
          { MobileNumber: MobileNumber },
          "",
          this.OtpResponse
        );
      } else toastr.warning("", "Not a valid number");
    } else toastr.warning("", "Not a valid number");
  };
  OtpResponse = (data) => {
    if (data.result) {
      this.setState({ loading: false });
    }
  };
  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  UniqueUserName = (user) => {
    var UniqueUser = {};
    UniqueUser.User = "Advocate";
    UniqueUser.Username = user.target.value;

    CallService(
      authenctication.UserNameValidate,
      MethodType.POST,
      false,
      UniqueUser,
      "",
      this.UniqueUserNameResponse
    );
  };

  UniqueUserNameResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        this.setState({
          UniqueUserNameValidate: data.result.IsUsernameAvailable,
        });
      }
    }
  };

  UniqueMobileNumber = (no) => {
    var UniqueUser = {};
    UniqueUser.User = "Advocate";
    UniqueUser.MobileNumber = no.target.value;

    CallService(
      authenctication.MobileNumberValidation,
      MethodType.POST,
      false,
      UniqueUser,
      "",
      this.UniqueMobileNumberResponse
    );
  };

  UniqueMobileNumberResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        this.setState({
          UniqueUserMobileNumberValidate:
            data.result.IsUnregisteredMobileNumber,
        });
      }
    }
  };
  getCaptcha() {
    const {
      publicKey: clientPublicKey,
      secretKey: clientPrivateKey,
    } = nacl.box.keyPair();

    const clientPublicKeyText = Uint8ArrayToBase64(clientPublicKey);
    const clientPrivateKeyText = Uint8ArrayToBase64(clientPrivateKey);
    CallService(
      authenctication.GetCaptcha,
      MethodType.POST,
      false,
      { ClientPublicKey: clientPublicKeyText },
      "",
      this.getCaptchaResponse
    );
    this.setState({
      captchaClientPublicKey: clientPublicKeyText,
      captchaClientPrivateKey: clientPrivateKeyText,
    });
  }
  getCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      const captcha = data.result.Captcha;
      const nonce = data.result.Nonce;
      const serverPublicKey = data.result.ServerPublicKey;
      const decipherText = decryptBox(
        captcha,
        nonce,
        serverPublicKey,
        this.state.captchaClientPrivateKey
      );
      var CaptchaDetails = {
        CaptchaId: data.result._id,
        CaptchaServerPublicKey: serverPublicKey,
        Captcha: decipherText,
      };
      this.setState(
        {
          CaptchaDetails: CaptchaDetails,
          Captcha: decipherText,
        },
        () => this.createCaptcha()
      );
    }
  };

  createCaptcha() {
    var canvas = this.canvasRef.current;

    var context = canvas.getContext("2d");

    context.clearRect(0, 0, 100, 100);
    var actualCaptcha = "";
    if (this.state.Captcha) {
      actualCaptcha = this.state.Captcha;
    } else {
      var charsArray = "0123456789";
      var lengthOtp = 6;
      var captcha = [];
      for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) === -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      actualCaptcha = captcha.join("");
    }
    context.font = "18px Georgia";
    context.fillText(actualCaptcha, 0, 30);
    this.setState({
      captchatxt: actualCaptcha,
    });
  }
  HandleCaptchaResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        "Captcha verified successfully. Please send otp to continue."
      );
      this.setState({
        captchaVerified: true,
      });
    }
  };
  render() {
    const initialValues = {
      Name: "",
      Username: "",
      MobileNumber: "",
      Password: "",
      ConfirmPassword: "",
      Captcha: "",
      MobileNumberVerificationOTP: "",
      EmailVerificationOTP: "",
      EmailID: "",
    };
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div> */}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <br />
            <br />
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card
                  style={{
                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                  }}
                  className="mt-5 overflow-hidden"
                >
                  <div className="bg-soft-primary">
                    <Row>
                      <Col>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            {this.props.t("Notary Portal Registration")}
                          </h5>

                          {/* <h5 className="text-primary">Free Register</h5>
                          <p>Get your free Law Admin account now.</p> */}
                        </div>
                      </Col>
                      {/* <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="75"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="p-2">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={otpValidation}
                        onSubmit={this.onUserLogin}
                        validateOnBlur={false}
                        validateOnChange={true}
                      >
                        {({ values, errors, touched, setFieldValue }) => (
                          <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-float-label">
                              <Label>{this.props.t("Username")}</Label>
                              <Field
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                name="Username"
                                placeholder={this.props.t("Username")}
                                //  onChange={(e) => this.UniqueUserName(e)}
                                onBlur={(e) => {
                                  values.Username = e.target.value;
                                  this.UniqueUserName(e);
                                }}
                                onChange={(e) => {
                                  setFieldValue(
                                    "Username",
                                    e.target.value
                                      ? e.target.value.toLowerCase()
                                      : ""
                                  );
                                }}
                              />
                              {errors.Username && touched.Username ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Username}
                                </div>
                              ) : this.state.UniqueUserNameValidate ===
                                false ? (
                                <div className="invalid-feedback d-block">
                                  Username already exists. Try another.
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                            <FormGroup className="form-group has-float-label">
                              <Label>{this.props.t("Name")}</Label>
                              <Field
                                autoComplete="nope"
                                type="text"
                                className="form-control"
                                name="Name"
                                placeholder={this.props.t("Name")}
                                onKeyPress={(event) => {
                                  if (INVALID_CHARS.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={(event) => {
                                  event.target.value =
                                    event.target.value &&
                                    event.target.value.replace(
                                      INVALID_CHARS_REGEX,
                                      ""
                                    );
                                  setFieldValue("Name", event.target.value);
                                }}
                              />

                              {errors.Name && touched.Name ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Name}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                            <FormGroup className="form-group has-float-label">
                              <Label>{this.props.t("Mobile Number")}</Label>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                name="MobileNumber"
                                placeholder={this.props.t("Mobile Number")}
                                //  onChange={(e) => this.UniqueUserName(e)}
                                onChange={(e) => {
                                  values.MobileNumber = e.target.value;
                                  if (values.MobileNumber.length >= 7) {
                                    this.UniqueMobileNumber(e);
                                  }
                                }}
                              />
                              {/* <Field
                                type="text"
                                className="form-control"
                                name="MobileNumber"
                                placeholder={this.props.t("Mobile Number")}
                                //  onKeyUp={this.onKeyUpValue.bind(this)}
                              /> */}
                              {errors.MobileNumber && touched.MobileNumber ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MobileNumber}
                                </div>
                              ) : this.state.UniqueUserMobileNumberValidate ===
                                false ? (
                                <div className="invalid-feedback d-block">
                                  Mobile Number already exists. Try another.
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                            <FormGroup className="form-group has-float-label">
                              <Label>{this.props.t("Email ID")}</Label>
                              <Field
                                autoComplete="off"
                                type="email"
                                className="form-control"
                                name="EmailID"
                                placeholder={this.props.t("Email ID")}
                              />
                              {errors.EmailID && touched.EmailID ? (
                                <div className="invalid-feedback d-block">
                                  {errors.EmailID}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>

                            <div className="form-group">
                              <label style={{ marginBottom: "-5%" }}>
                                Type the number shown in the box below
                              </label>
                              <Row>
                                <div className={"col-6"}>
                                  <label className="captchaformcanvas">
                                    <canvas
                                      className="MobileCanvas"
                                      width="150"
                                      height="33"
                                      ref={this.canvasRef}
                                    />
                                    <div className="captacharefresh">
                                      <i
                                        onClick={() => {
                                          this.getCaptcha();
                                        }}
                                        className="mdi mdi-refresh"
                                      ></i>
                                    </div>
                                  </label>
                                </div>
                                <div className={"col-6 mt-2"}>
                                  <Field
                                    className="form-control captchaform"
                                    type="text"
                                    autoComplete="off"
                                    name="Captcha"

                                    // validate={this.validatePassword}
                                  />{" "}
                                  {errors.Captcha && touched.Captcha ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.Captcha}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Row>
                            </div>

                            {!this.state.SendOtp ? (
                              <Fragment>
                                <div className="mt-4">
                                  <button
                                    color="primary"
                                    className="btn btn-primary btn-block waves-effect waves-light"
                                    // className={`btn-shadow btn-multiple-state`}
                                    size="lg"
                                    onClick={() => this.getOtp(values)}
                                  >
                                    {this.state.captchaVerified
                                      ? this.props.t("Send OTP")
                                      : this.props.t("Verify")}
                                  </button>
                                </div>
                              </Fragment>
                            ) : (
                              ""
                            )}

                            {this.state.SendOtp ? (
                              this.state.seconds > 0 ? (
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#007bff",
                                    float: "right",
                                  }}
                                >
                                  {/* {this.state.time.m < 10 ? "0" : ""}
                                  {this.state.time.m}:{" "}
                                  {this.state.time.s < 10 ? "0" : ""}
                                  {this.state.time.s} */}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#007bff",
                                    textDecoration: "underline",
                                    float: "right",
                                    zIndex: 3,
                                  }}
                                  onClick={() =>
                                    this.resendOTP(values.MobileNumber)
                                  }
                                >
                                  Resend OTP
                                </span>
                              )
                            ) : (
                              ""
                            )}
                            <br />

                            {this.state.SendOtp ? (
                              <Fragment>
                                <FormGroup className="form-group has-float-label">
                                  <Label>Mobile Verification OTP</Label>{" "}
                                  <span style={{ float: "right" }}>
                                    Please verify your OTP
                                  </span>
                                  <Field
                                    autoComplete="off"
                                    className="form-control"
                                    type="text"
                                    name="MobileNumberVerificationOTP"
                                    onKeyPress={(event) => {
                                      if (INVALID_CHARS.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      event.target.value =
                                        event.target.value &&
                                        event.target.value.replace(
                                          INVALID_CHARS_REGEX,
                                          ""
                                        );
                                      setFieldValue(
                                        "MobileNumberVerificationOTP",
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {errors.MobileNumberVerificationOTP &&
                                  touched.MobileNumberVerificationOTP ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.MobileNumberVerificationOTP}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                                <FormGroup className="form-group has-float-label">
                                  <Label>Email Verification OTP</Label>{" "}
                                  <span style={{ float: "right" }}>
                                    Please verify your OTP
                                  </span>
                                  <Field
                                    autoComplete="off"
                                    className="form-control"
                                    type="text"
                                    name="EmailVerificationOTP"
                                    onKeyPress={(event) => {
                                      if (INVALID_CHARS.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      event.target.value =
                                        event.target.value &&
                                        event.target.value.replace(
                                          INVALID_CHARS_REGEX,
                                          ""
                                        );
                                      setFieldValue(
                                        "EmailVerificationOTP",
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {errors.EmailVerificationOTP &&
                                  touched.EmailVerificationOTP ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.EmailVerificationOTP}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>

                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Password")}</Label>
                                  <Field
                                    autoComplete="off"
                                    type="password"
                                    className="form-control"
                                    name="Password"
                                    placeholder={this.props.t("Password")}
                                  />
                                  {errors.Password && touched.Password ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.Password}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>

                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("Confirm Password")}
                                  </Label>
                                  <Field
                                    autoComplete="off"
                                    type="password"
                                    className="form-control"
                                    name="ConfirmPassword"
                                    placeholder={this.props.t(
                                      "Confirm Password"
                                    )}
                                  />
                                  {errors.ConfirmPassword &&
                                  touched.ConfirmPassword ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.ConfirmPassword}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>

                                <div className="mt-4">
                                  <button
                                    color="primary"
                                    className={`btn btn-primary btn-block waves-effect waves-light ${
                                      this.state.loading ? "show-spinner" : ""
                                    }`}
                                    size="lg"
                                  >
                                    <span className="spinner d-inline-block">
                                      <span className="bounce1" />
                                      <span className="bounce2" />
                                      <span className="bounce3" />
                                    </span>
                                    <span className="label">
                                      {this.props.t("Sign Up")}
                                    </span>
                                  </button>
                                </div>
                              </Fragment>
                            ) : (
                              ""
                            )}
                            <div className="mt-3 text-center">
                              <p className="mb-0">
                                {/* By Registered as Notary Portal Registration{" "} */}
                                {this.props.t("By agree to")}
                                <Link to="#" className="text-primary">
                                  {" "}
                                  {this.props.t("Terms & Conditions")}
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                      {/* <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.user && this.props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {this.props.registrationError &&
                        this.props.registrationError ? (
                          <Alert color="danger">
                            {this.props.registrationError}
                          </Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="username"
                            label="Name"
                            type="text"
                            required
                            placeholder="Enter Name"
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="Mobile Number"
                            label="Mobile Number"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            type="number"
                            required
                          />
                        </div>
                        {/* <Button
                          color="primary"
                          className={`btn-shadow btn-multiple-state`}
                          size="lg"
                          onClick={() => this.getOtp(values.MobileNumber)}
                        >
                          Send OTP
                        </Button>

                        <div className="form-group">
                          <AvField
                            name="otp"
                            label="OTP"
                            type="password"
                            required
                            placeholder="Enter OTP"
                          />
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Law Admin{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mt-3 text-center">
                          <p className="mb-0">
                            By Registered as Notary Portal Registration{" "}
                            <br></br>
                            <Link to="#" className="text-primary">
                              {" "}
                              Terms of Condition
                            </Link>
                          </p>
                        </div>
                      </AvForm> */}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {this.props.t("Already have an account ?")}
                    <Link
                      to="/advocatelogin"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      {this.props.t("Login")}
                    </Link>{" "}
                  </p>
                  {/* <p>
                    © {new Date().getFullYear()}{" "}
                    {this.props.t("Law Admin. Crafted with")}{" "}
                    by{" "}
                    <a
                      href="https://vertace.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Vertace
                    </a>
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    registerUser,
    apiError,
    registerUserFailed,
  })(withNamespaces()(Register))
);
