import React, { Component } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
//i18n
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { Formik, Form, Field } from "formik";
import ListPage from "../../components/custom/ListPage";
import { CallService } from "../../helpers/ServiceCall";
import {
  roles,
  level,
  user,
  gender,
  district,
  authenctication,
} from "../../constants/config";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { officerValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import ListPageHeader from "../../components/custom/ListPageHeader";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
import {
  encryptBox,
  formatEncryptedField,
  Base64ToUint8Array,
} from "../../helpers/encryption";
nacl.util = naclUtil;

class User extends Component {
  constructor(props) {
    super(props);
    this.toggleAddMoal = this.toggleAddMoal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      isLoading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
        Role: "5f69d82bfe8dab33a4c02350",
      },
      buttonAction: false,
      showProcesing: true,
      Title: "Create Competent Authority",
      UpdateId: "",
      Language: sessionStorage.getItem("lng"),
      CreateUser: {
        Name: "",
        Username: "",
        MobileNumber: "",
        EmailID: "",
        Password: "",
        Role: "",
        Address: {
          Line1: "",
          Line2: "",
          Line3: "",
          Pincode: "",
          District: null,
          Taluk: null,
          State: null,
        },
        IsPasswordChanged: false,
      },
      multiArea: [""],

      AreaLevels: [
        {
          AreaList: [],
          SelectedAreaLevel: {},
        },
      ],
      columns: [
        {
          name: <strong>{this.props.t("Username")}</strong>,
          selector: "Username",
          sortable: true,
          cell: (row) => <span>{row.Username ? row.Username : " "}</span>,
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: true,
          cell: (row) => <span>{row.Name ? row.Name : " "}</span>,
        },
        {
          name: <strong>{this.props.t("Email ID")}</strong>,
          selector: "EmailID",
          sortable: true,
          cell: (row) => <span>{row.EmailID ? row.EmailID : ""}</span>,
        },
        {
          name: <strong>{this.props.t("District")}</strong>,
          selector: "District",
          sortable: true,
          cell: (row) => (
            <span>
              {row.District
                ? sessionStorage.getItem("lng") === "ta"
                  ? row.District.TamilName
                  : row.District.Name
                : ""}
            </span>
          ),
        },
        // {
        //   name: <strong>{this.props.t("Mobile Number")}</strong>,
        //   selector: "MobileNumber",
        //   sortable: true,
        //   cell: (row) => (
        //     <span>{row.MobileNumber ? row.MobileNumber : ""}</span>
        //   ),
        // },
        {
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => this.toggle(row._id)}
              >
                Delete
              </Button>
              {/* </ButtonGroup> */}
            </Row>
          ),
        },
      ],

      AreaList: [],
      UserList: [],
      GenderList: [],
      ArearesultCheck: false,
      UniqueUserNameValidate: true,
    };
  }
  componentDidMount() {
    this.GetAllUser();
    this.GetAllGender();
    this.GetAllDistrict();

    setTimeout(() => {
      this.getSeed();
    }, 1000);
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState({
        ServerPublicKey: data.result.ServerPublicKey,
        SeedId: data.result._id,
      });
    }
  };
  GetAllUser = () => {
    let { ListRequestModel } = this.state;
    this.setState({
      showProcesing: false,
    });
    CallService(
      user.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllUserResponse
    );
  };
  GetAllUserResponse = (data) => {
    if (data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        UserList: data.result,
        totalCount: data.pagination.totalCount,
        showProcesing: false,
      });
    } else {
      this.setState({
        isLoading: false,
        showProcesing: false,
      });
    }
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }
  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  GetAllGender() {
    CallService(
      gender.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllGenderResponse
    );
  }

  GetAllGenderResponse = (data) => {
    this.setState({
      GenderList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };

  // handle Search string
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllUser();
        }
      }
    );
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllUser();
      }
    );
  };

  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      function () {
        this.GetAllUser();
      }
    );
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllUser();
      }
    );
  };
  toggle(rowId) {
    if (rowId) {
      this.setState({
        deleteUserModal: !this.state.deleteUserModal,
        deleteRowId: rowId,
      });
    } else {
      this.setState({
        deleteUserModal: !this.state.deleteUserModal,
      });
    }
  }

  handleSubmit = (values) => {
    if (this.state.UniqueUserNameValidate) {
      if (!this.state.buttonAction) {
        this.setState({
          buttonAction: true,
        });
        var details = {};

        const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
        const password = values.Password;
        const { cipherText, nonceText, clientPublicKeyText } = encryptBox(
          password,
          serverPublicKey
        );

        details.Password = formatEncryptedField(
          this.state.SeedId,
          cipherText,
          nonceText,
          clientPublicKeyText
        );

        details.Username = values.Username;
        details.Name = values.Name;
        details.IsPasswordChanged = values.IsPasswordChanged ? "false" : "true";
        details.Gender = values.Gender;
        details.MobileNumber = values.MobileNumber;
        details.EmailID = values.EmailID;
        //   details.Password = values.Password;
        details.Role = "5f69d82bfe8dab33a4c02350";
        details.District = values.District;
        //    details.Dob = moment(values.Dob * 1000).unix();
        if (this.state.Title === "Create Competent Authority") {
          this.CreateUser(details);
        } else {
          this.UpdateUser(details);
        }
      }
    }
  };

  addBtnClick = () => {
    this.setState((prevState) => ({
      CreateUser: {
        ...prevState.CreateOffice,
        Name: "",
        Username: "",
        MobileNumber: "",
        EmailID: "",
        Password: "",
        Role: "",
        //    Dob: null,
        IsPasswordChanged: false,
      },
      UniqueUserNameValidate: true,
      startDate: null,
      Title: "Create Competent Authority",
    }));

    this.toggleAddMoal();
  };
  toggleAddMoal() {
    this.setState({
      addmodal: !this.state.addmodal,
    });
  }
  CreateUser = (list) => {
    console.log(list);
    setTimeout(() => {
      this.setState({
        buttonAction: false,
      });
    }, 5000);
    CallService(
      user.Create,
      MethodType.PUT,
      false,
      list,
      "",
      this.CreateUserResponse
    );
  };
  CreateUserResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.props.t("Competent Authority created successfully")
      );
      this.toggleAddMoal();

      this.GetAllUser();
    } else if (data.statusCode === "409") {
      toastr.error("", this.props.t("Mobile Number already exists"));
    } else {
      toastr.error("", this.props.t("Something Went Wrong"));
    }
  };

  HandleRowClicked = (row) => {
    console.log(row);
    var prefill = row;
    row.IsPasswordChanged = row.IsPasswordChanged ? "false" : "true";
    row.Password = row.RawPassword;
    this.setState({
      CreateUser: prefill,
      Title: "Update Competent Authority",
      UpdateId: row._id,
      addmodal: !this.state.addmodal,
    });
  };

  UpdateUser(list) {
    console.log(list);
    setTimeout(() => {
      this.setState({
        buttonAction: false,
      });
    }, 5000);
    CallService(
      user.Update + this.state.UpdateId,
      MethodType.PATCH,
      false,
      list,
      "",
      this.UpdateUserResponse
    );
  }
  UpdateUserResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    this.toggleAddMoal();
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.props.t("Competent Authority updated successfully")
      );
      this.GetAllUser();
    } else {
      toastr.error("", this.props.t("Something Went Wrong"));
    }
  };

  DeleteUser(id) {
    CallService(
      user.Delete + id,
      MethodType.DELETE,
      false,
      "",
      "",
      this.DeleteUserResponse
    );
  }
  DeleteUserResponse = (data) => {
    this.toggle("");
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.props.t("Competent Authority deleted successfully")
      );
      this.GetAllUser();
    } else {
      toastr.error("", this.props.t("Something Went Wrong"));
    }
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllUser();
        }
      }
    );
  };
  getUniqueUserName = (unique) => {
    console.log(unique.target.value);

    var UniqueUser = {};
    UniqueUser.User = "Officer";
    UniqueUser.Username = unique.target.value;

    CallService(
      user.UserNameValidate,
      MethodType.POST,
      false,
      UniqueUser,
      "",
      this.UniqueUserNameResponse
    );
  };

  UniqueUserNameResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        this.setState({
          UniqueUserNameValidate: data.result.IsUsernameAvailable,
        });
      }
    }
  };
  render() {
    const initialValues = this.state.CreateUser;
    return (
      <div className="page-content">
        <div className="container-fluid">
          <Card className="mt-4">
            <CardBody>
              <ListPageHeader
                heading={`${this.props.t("Home")}.${this.props.t(
                  "Competent Authority"
                )}`}
                onTextChange={this.SearchQueryChanged}
                buttonClick={this.addBtnClick}
                searchValue={this.state.ListRequestModel.searchString}
                buttonText={this.props.t("Add New")}
              />

              <ListPage
                className={"cursor-pointer"}
                columns={this.state.columns}
                data={this.state.UserList}
                keyField={this.state.keyField}
                totalCount={this.state.totalCount}
                rowsPerPageOnChange={this.handlePerRowsChange}
                rowClicked={this.HandleRowClicked}
                pageChange={this.handlePageChange}
                overFlowXRemoval={true}
                onSort={this.handleSort}
                isDataLoading={this.state.isLoading}
              />

              <Modal
                size="lg"
                isOpen={this.state.addmodal}
                toggle={this.toggleAddMoal}
              >
                <ModalHeader toggle={this.toggleAddMoal}>
                  {this.props.t(this.state.Title)}
                </ModalHeader>
                <Formik
                  initialValues={initialValues}
                  validationSchema={officerValidation}
                  onSubmit={this.handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Row>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {this.props.t("Username")}
                              </Label>
                              {this.state.Title ===
                              this.props.t("Create Competent Authority") ? (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  disabled={
                                    this.state.Title ===
                                    this.props.t("Create Competent Authority")
                                      ? false
                                      : true
                                  }
                                  // value={
                                  //   this.state.CreateUser.Username
                                  //     ? this.state.CreateUser.Username
                                  //     : ""
                                  // }
                                  className={
                                    this.state.Title ===
                                    this.props.t("Create Competent Authority")
                                      ? "form-control"
                                      : "cls-disabled form-control"
                                  }
                                  name="Username"
                                  onChange={(e) => {
                                    this.getUniqueUserName(e);
                                    values.Username = e.target.value;
                                  }}
                                />
                              ) : (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  disabled={
                                    this.state.Title ===
                                    this.props.t("Create Competent Authority")
                                      ? false
                                      : true
                                  }
                                  value={this.state.CreateUser.Username}
                                  className={
                                    this.state.Title ===
                                    this.props.t("Create Competent Authority")
                                      ? "form-control"
                                      : "cls-disabled form-control"
                                  }
                                  name="Username"
                                  onChange={(e) => {
                                    this.getUniqueUserName(e);
                                    values.Username = e.target.value;
                                  }}
                                />
                              )}
                              {errors.Username && touched.Username ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Username}
                                </div>
                              ) : this.state.UniqueUserNameValidate ===
                                false ? (
                                <div className="invalid-feedback d-block">
                                  Already created this username
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {this.props.t("Name")}
                              </Label>
                              <Field
                                autoComplete="nope"
                                type="text"
                                autoFocus
                                className="form-control"
                                name="Name"
                                onKeyPress={(event) => {
                                  if (INVALID_CHARS.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={(event) => {
                                  event.target.value =
                                    event.target.value &&
                                    event.target.value.replace(
                                      INVALID_CHARS_REGEX,
                                      ""
                                    );
                                  setFieldValue("Name", event.target.value);
                                }}
                              />

                              {errors.Name && touched.Name ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Name}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                isDisabled={
                                  this.state.IsProfileCompleted === "true"
                                    ? true
                                    : false
                                }
                                //   labelClassName="requiredField"
                                label={this.props.t("Gender")}
                                components={{ Input: CustomSelectInput }}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="Gender"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.Gender &&
                                  (typeof values.Gender === "string"
                                    ? this.state.GenderList.find(
                                        (r) => r.value === values.Gender
                                      )
                                    : this.state.GenderList.find(
                                        (r) => r.value === values.Gender._id
                                      ))
                                }
                                options={this.state.GenderList}
                                ClearAction={() => {
                                  setFieldValue("Gender", null);
                                }}
                                Action={(entity) => {
                                  setFieldValue(
                                    "Gender",
                                    entity ? entity.value : null
                                  );
                                }}
                                errors={errors.Gender}
                              />
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label
                              // className="requiredField"
                              >
                                {this.props.t("Mobile Number")}
                              </Label>
                              <Field
                                autoComplete="nope"
                                type="number"
                                autoFocus
                                className="form-control"
                                name="MobileNumber"
                                onKeyPress={(event) => {
                                  if (INVALID_CHARS.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={(event) => {
                                  event.target.value =
                                    event.target.value &&
                                    event.target.value.replace(
                                      INVALID_CHARS_REGEX,
                                      ""
                                    );
                                  setFieldValue(
                                    "MobileNumber",
                                    event.target.value
                                  );
                                }}
                              />

                              {errors.MobileNumber && touched.MobileNumber ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MobileNumber}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {" "}
                                {this.props.t("Email ID")}
                              </Label>
                              <Field
                                type="email"
                                autoFocus
                                className="form-control"
                                name="EmailID"
                              />
                              {errors.EmailID && touched.EmailID ? (
                                <div className="invalid-feedback d-block">
                                  {errors.EmailID}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <DropDown
                                labelClassName="requiredField"
                                label={this.props.t("District")}
                                components={{ Input: CustomSelectInput }}
                                classNamePrefix="react-select"
                                isSearchable
                                isClearable={true}
                                value={
                                  values.District &&
                                  (typeof values.District === "string"
                                    ? this.state.DistrictList.find(
                                        (r) => r.value === values.District
                                      )
                                    : this.state.DistrictList.find(
                                        (r) => r.value === values.District._id
                                      ))
                                }
                                options={this.state.DistrictList}
                                ClearAction={() => {
                                  setFieldValue("District", null);
                                }}
                                Action={(entity) => {
                                  setFieldValue(
                                    "District",
                                    entity ? entity.value : null
                                  );

                                  values.District = entity
                                    ? entity.value
                                    : null;

                                  // this.GetAllTaluk(entity ? entity.value : "");
                                }}
                                errors={errors.District}
                              />
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="requiredField">
                                {" "}
                                {this.props.t("Password")}
                              </Label>
                              <Field
                                type="password"
                                autoFocus
                                className="form-control"
                                name="Password"
                              />
                              {errors.Password && touched.Password ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Password}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="checkbox"
                                autoFocus
                                style={{ marginTop: "35Px" }}
                                name="IsPasswordChanged"
                              />
                              <Label>
                                &nbsp; {this.props.t("Force Password Change")}
                              </Label>
                            </FormGroup>
                          </Colxx>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        {this.state.Title ===
                        this.props.t("Create Competent Authority") ? (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            color="success"
                            outline
                            type="submit"
                          >
                            {this.props.t("Create")}
                          </Button>
                        ) : (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            color="primary"
                            outline
                            type="submit"
                          >
                            {this.props.t("Update")}
                          </Button>
                        )}
                        <Button color="danger" onClick={this.toggleAddMoal}>
                          {this.props.t("Cancel")}
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
              <Modal
                isOpen={this.state.deleteUserModal}
                toggle={this.toggle}
                className={"modal-warning " + this.props.className}
              >
                <ModalHeader toggle={this.toggle}>
                  Delete Competent Authority
                </ModalHeader>
                <ModalBody>
                  {"Do you want to delete this Competent Authority ?"}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault();
                      this.DeleteUser(this.state.deleteRowId);
                    }}
                  >
                    Delete
                  </Button>{" "}
                  <Button color="danger" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(withNamespaces()(User));
