import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import {
  menu,
  advocateMenu,
  pdjMenu,
  lawAdminMenu,
  Menus,
  superAdminMenu,
  interviewBoardMenu,
} from "../../constants/menu";
import { officer } from "../../constants/config";
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if ("#" + this.props.location.pathname === items[i].hash) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    var Menu =
      JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "advocate"
        ? advocateMenu
        : (JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "pdj")
          ? pdjMenu
          : (JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "law_admin")
            ? lawAdminMenu
            : (JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).Role && JSON.parse(sessionStorage.getItem("user")).Role.Name === "interview_board")
              ? interviewBoardMenu
              : superAdminMenu;

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{this.props.t('Menu')}</li> */}
            {Menu.map((m) => {
              return (
                <li>
                  <Link
                    to={m.to}
                    className={
                      m.subs ? "has-arrow waves-effect" : "waves-effect"
                    }
                  >
                    <i className={"bx " + m.icon}></i>
                    {m.badge === "yes" && (
                      <span className="badge badge-pill badge-info float-right">
                        03
                      </span>
                    )}
                    <span>{this.props.t(m.label)}</span>
                  </Link>
                  {m.subs && (
                    <ul className="sub-menu" aria-expanded="false">
                      {m.subs.map((sm, i) => {
                        return (
                          <li key={i}>
                            <Link to={sm.to}>{this.props.t(sm.label)}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(SidebarContent));
