import React, { Component, Fragment } from "react";
import { Row, Button, Card, CardBody, Container, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { cms, interviewboard } from "../../constants/config";
import toastr from "toastr";

import "toastr/build/toastr.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import OwlCarousel from "react-owl-carousel";
import { Colxx } from "../../components/Common/CustomBootstrap";

const slideOptions = {
  items: 1,
  nav: false,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  responsiveClass: true,
  responsive: {
    0: {
      dotsEach: 1,
      items: 1,
    },
    600: {
      dotsEach: 1,
      items: 1,
    },
    768: {
      dotsEach: 1,
      items: 1,
    },
  },
};
class LoginHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      Advocates: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      HtmlContent: "",
      Advocate: {
        UserName: "",
        Password: "",
        District: "",
      },
      columns: [
        {
          name: "UserName",
          selector: "UserName",
          sortable: false,
          cell: (row) => <span>{row.UserName ? row.UserName : ""}</span>,
        },

        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="danger"
                onClick={() => this.toggleDeleteModal(row)}
              >
                Delete
              </Button>
            </Row>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    // this.setState({
    //   toggleModel:true
    // });
    this.GetCMS("home");
  }

  GetCMS(search) {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = search;
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        HtmlContent: data.result && data.result[0],
      });
    }
  };
  GetAllAdvocate() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      interviewboard.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllAdvocateResponse
    );
  }

  GetAllAdvocateResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        Advocates: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };

  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAllAdvocate();
      }
    );
  };

  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllAdvocate();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      Advocate: row,
    });
  };
  toggleModal = () => {
    this.setState({
      toggleModel: !this.state.toggleModel,
    });
  };
  addBtnClick = () => {
    this.toggleManageModal();
  };

  deleteInterviewBoard = (id) => {
    CallService(
      interviewboard.Delete + id,
      MethodType.DELETE,
      false,
      "",
      "",
      this.deleteInterviewBoardResponse
    );
  };

  deleteInterviewBoardResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    this.toggleDeleteModal();
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Applicant Deleted Successfully");
      this.GetAllAdvocate();
    }
  };

  render() {
    return (
      <Fragment>
        {/* <Card>
          <CardBody className="pb-0 pl-0 pr-0"> */}

        <Row className="justify-content-center banner-align ml-0 mr-0 mt-5">
          <Col xxs="12" className="pl-0 pr-0 mt-5 ">
            <OwlCarousel
            key={`carousel_${this?.state?.HtmlContent?.Images?.length}`}
              // className="section h-100vh bg-overlay d-flex align-items-center"
              className="banner-slides owl-carousel owl-theme"
              // onDragged={(e) => { this.changeSlide((e.item.index - 1)) }}
              {...slideOptions}
            >
              {this.state.HtmlContent &&
                this.state.HtmlContent.Images &&
                this.state.HtmlContent.Images.map((c) => (
                  <div className="item">
                    <section
                      className={`slide-1`}
                      //alt="source"
                      style={{
                        maxHeight: "750px",
                        background:
                          "rgba(0, 0, 0, 0) url(" +
                          c +
                          ") no-repeat scroll center center / cover",
                      }}
                    >
                      {/* <img src={c.image} alt="source" /> */}
                      {c && (
                        <div className="">
                          <div className="banner-overlay bottom-align">
                            {/* <a
                                  href={c}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                > */}
                            <p
                              style={{
                                height: "750px",
                              }}
                            ></p>
                            {/* </a> */}
                          </div>
                        </div>
                      )}
                    </section>
                  </div>
                ))}
            </OwlCarousel>
          </Col>
        </Row>
        {/* </CardBody>
        </Card> */}

        {/* <Carousel autoPlay>
          {
            this.state.HtmlContent && this.state.HtmlContent.ImageItems.map((obj, i) =>

              <div>
                <img alt="" src={obj.Image} />
                <p className="legend">Legend {i + 1}</p>
              </div>)}
        </Carousel> */}
        <div>
          {" "}
          <br></br>
          <div
            className="card-body accordion-content"
            dangerouslySetInnerHTML={{
              __html: this.state.HtmlContent
                ? this.state.HtmlContent.Content
                : "",
            }}
          />
        </div>
        {/* {JSON.stringify(this.state.HtmlContent)} */}
        <Modal
              isOpen={this.state.toggleModel}
              toggle={() => this.toggleModal()}
              size="lg"
              centered
            >
              <ModalHeader>{("ATTENTION")}</ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Colxx>
                      <div>
{
  `On considering the order of the Hon'ble Supreme Court of India, it is to inform that the Notaries whose due 
  date for renewal of certificate of practice (i.e) six months before the date of expiry of Notary, falls within
  the period from 15.03.2020 to 28.02.2022 and who have not applied for renewal or whose renewal application was 
  rejected are required to apply afresh in offline mode to the Law Department, Secretariat, Chennai-9 with 
  prescribed fee and relevant documents on or before 02.05.2022.
  `
}
                      </div>
                    </Colxx>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleModal()}
                  >
                    {("Close")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>

      </Fragment>
    );
  }
}
export default LoginHome;
