import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { Colxx } from "../../components/Common/CustomBootstrap";
import {
  Row,
  Label,
  Button,
  FormGroup,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { CallService } from "../../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  numberFormat,
} from "../../constants/defaultValues";
import { worktype, annualreturn, district } from "../../constants/config";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import ListPage from "../../components/custom/ListPage";
import { Link, withRouter } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { AnnualReturnValidation } from "../../helpers/validations";
import moment from "moment";
import ListPageHeader from "../../components/custom/ListPageHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";

import ViewDetails from "../../pages/ViewDetails/modal";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { exec } from "apexcharts";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
class AnnualReturn extends Component {
  constructor(props) {
    super(props);
    this.toggleViewModal = this.toggleViewModal.bind(this);
    this.state = {
      ListRequestModel: {
        searchString: "",
        sortCondition: { createdAt: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      ListRequestModelWorkType: {
        searchString: "",
        sortCondition: { createdAt: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      IsYear: false,
      UserName:
        JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name,
      Language: sessionStorage.getItem("lng"),
      GrandTotal: 0,
      QuantityGrandTotal: 0,
      UnitFeeGrandTotal: 0,
      GrandTotalFee: 0,
      isLoading: true,
      AnnualReturns: [],
      YearList: [],
      AnnualReturn: {
        Year: null,
        WorkTypes: [
          {
            WorkType: null,
            Quantity: 0,
            UnitFee: 0,
            TotalFee: 0,
          },
        ],
      },
      WorkTypeList: [],
      columns: [
        {
          omit:
            JSON.parse(sessionStorage.getItem("user")) &&
            JSON.parse(sessionStorage.getItem("user")).Role &&
            JSON.parse(sessionStorage.getItem("user")).Role.Name === "law_admin"
              ? false
              : true,
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: false,
          cell: (row) => <span>{row.Applicant ? row.Applicant.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Year")}</strong>,
          selector: "Year",
          sortable: false,
          cell: (row) => <span>{row.Year ? row.Year : ""}</span>,
        },

        // {
        //   name: this.props.t("Quantity"),
        //   selector: "Quantity",
        //   sortable: true,
        //   cell: (row) => (
        //     <span>
        //       {" "}
        //       {row.WorkTypes
        //         ? row.WorkTypes.map((n) => {
        //             return n.Quantity;
        //           }).join(",  ")
        //         : "-"}
        //     </span>
        //   ),
        // },
        // {
        //   name: this.props.t("Unit Fee"),
        //   selector: "UnitFee",
        //   sortable: true,
        //   cell: (row) => (
        //     <span>
        //       {" "}
        //       {row.WorkTypes
        //         ? row.WorkTypes.map((n) => {
        //             return n.UnitFee;
        //           }).join(",  ")
        //         : "-"}
        //     </span>
        //   ),
        // },
        {
          name: (
            <strong>
              {this.props.t("Total")} (
              <i className="bx bx-rupee" />)
            </strong>
          ),
          selector: "TotalFee",
          sortable: true,
          cell: (row) => <span> {row ? row.TotalFee : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Submitted On")}</strong>,
          selector: "SubmittedOn",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn
                ? moment(new Date(row.SubmittedOn * 1000)).format(
                    "DD/MM/YYYY hh:mm a"
                  )
                : ""}
            </span>
          ),
        },

        {
          name: <strong>{this.props.t("View")}</strong>,
          selector: "View",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={() => this.toggleViewModal(row)}
              >
                View
              </Button>
            </Row>
          ),
        },
      ],
    };
  }
  addBtnClick = () => {
    this.setState({
      AnnualReturn: {
        Year: null,
        WorkTypes: [],
      },
      WorkTypeList: [],
      ParticularYear: [],
      AnnualReturnModal: !this.state.AnnualReturnModal,
    });
  };
  toggleAnnaulModal = () => {
    this.setState({
      AnnualReturnModal: !this.state.AnnualReturnModal,
    });
  };
  componentDidMount() {
    this.GetAllAnnualReturns(0);
    this.GetAllWorkType();
    //  this.GetAllDistrict();
    var CurrentYear = new Date().getFullYear();
    var YearList = [];
    for (var i = CurrentYear - 1; i <= CurrentYear; i++) {
      var year = {};
      year.label = i;
      year.value = i;
      YearList.push(year);
    }
    this.setState({
      YearList: YearList,
    });
  }

  /***** Handle ListPage Function Start  *****/

  // handle Search string
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllAnnualReturns();
        }
      }
    );
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllAnnualReturns();
      }
    );
  };

  handlePerRowsChange = (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      function () {
        this.GetAllAnnualReturns();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllAnnualReturns();
      }
    );
  };
  /***** Handle ListPage Function End  *****/

  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }
  GetAllDistrictResponse = (data) => {
    this.setState({
      DistrictList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  GetAllAnnualReturns(year) {
    let { ListRequestModel } = this.state;
    ListRequestModel.Year = year;
    CallService(
      this.state.UserName === "pdj"
        ? annualreturn.GetAllPDJ
        : this.state.UserName === "law_admin"
        ? annualreturn.GetAllLawAdmin
        : annualreturn.GetAll,
      MethodType.POST,
      false,
      this.state.ListRequestModel,
      "",
      this.GetAllAnnualReturnsResponse
    );
  }

  GetAllAnnualReturnsResponse = (data) => {
    if (data.result) {
      if (this.state.IsYear) {
        if (data.result.length) {
          let TotalQuantity = 0;
          let TotalUnitFee = 0;
          let Total = 0;
          data.result[0] &&
            data.result[0].WorkTypes &&
            data.result[0].WorkTypes.forEach((element) => {
              var amt = parseInt(element.Quantity);
              TotalQuantity = TotalQuantity + amt;
            });
          data.result[0].WorkTypes &&
            data.result[0].WorkTypes.forEach((element) => {
              var amt = parseInt(element.UnitFee);
              TotalUnitFee = TotalUnitFee + amt;
            });
          data.result[0].WorkTypes &&
            data.result[0].WorkTypes.forEach((element) => {
              var amt = parseInt(element.TotalFee);
              Total = Total + amt;
            });

          this.setState({
            TotalQuantityValues: TotalQuantity,
            TotalUnitFeeValues: TotalUnitFee,
            TotalValues: Total,
            ParticularYear: data.result,
          });
        } else {
          this.GetAllWorkType();
        }
      } else {
        this.setState({
          isLoading: false,
          totalPage: data.pagination.totalCount / data.pagination.pageLimit,
          AnnualReturns: data.result,
          totalCount: data.pagination.totalCount,
          showProcesing: false,
        });
      }
    } else {
      this.setState({
        showProcesing: false,
      });
    }
  };

  GetAllWorkType() {
    CallService(
      worktype.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllWorkTypeResponse
    );
  }
  GetAllWorkTypeResponse = (data) => {
    this.setState({
      WorkTypeList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  // GetAllWorkType() {
  //   let { ListRequestModelWorkType } = this.state;
  //   ListRequestModelWorkType.pageLimit = 100000;
  //   CallService(
  //     worktype.GetAll,
  //     MethodType.POST,
  //     false,
  //     ListRequestModelWorkType,
  //     "",
  //     this.GetAllWorkTypeResponse
  //   );
  // }
  // GetAllWorkTypeResponse = (data) => {
  //   if (data.result) {
  //     this.setState({
  //       WorkTypeList: data.result.map(function (a) {
  //         return { value: a._id, label: a.Name };
  //       }),
  //     });
  //   }
  // };
  toggleViewModal = (row) => {
    if (row) {
      var TotalQuantity = 0;
      var TotalUnitFee = 0;
      var Total = 0;
      row &&
        row.WorkTypes &&
        row.WorkTypes.forEach((element) => {
          var amt = parseInt(element.Quantity);
          TotalQuantity = TotalQuantity + amt;
        });
      row.WorkTypes &&
        row.WorkTypes.forEach((element) => {
          var amt = parseInt(element.UnitFee);
          TotalUnitFee = TotalUnitFee + amt;
        });
      row.WorkTypes &&
        row.WorkTypes.forEach((element) => {
          var amt = parseInt(element.TotalFee);
          Total = Total + amt;
        });

      this.setState({
        TotalQuantityValues: TotalQuantity,
        TotalUnitFeeValues: TotalUnitFee,
        TotalValues: Total,
        viewValues: row,
        viewModal: !this.state.viewModal,
      });
    } else {
      this.setState({
        viewModal: !this.state.viewModal,
      });
    }
  };
  handleSubmit = (values) => {
    if (values.WorkTypes.length) {
      var detailsValues = values;
      var WorkType = [];
      for (var i = 0; i < this.state.WorkTypeList.length; i++) {
        var wt = {};
        wt.WorkType = this.state.WorkTypeList[i].value;
        wt.Quantity =
          detailsValues.WorkTypes && detailsValues.WorkTypes[i]
            ? detailsValues.WorkTypes[i].Quantity
            : 0;
        wt.UnitFee =
          detailsValues.WorkTypes && detailsValues.WorkTypes[i]
            ? detailsValues.WorkTypes[i].UnitFee
            : 0;
        wt.TotalFee = wt.Quantity * wt.UnitFee;
        WorkType.push(wt);
      }
      var removeZeroVal = WorkType.filter(function (total) {
        return total.TotalFee > 0;
      });
      if (removeZeroVal.length) {
        if (!this.state.buttonAction) {
          this.setState(
            {
              AnnualReturn: {
                Year: detailsValues.Year,
                WorkTypes: removeZeroVal,
              },
              buttonAction: true,
            },
            () => {
              this.toggleConfirmModal();
            }
          );
        } else {
          this.setState({
            buttonAction: false,
          });
          toastr.error("", "Please fill required field");
        }
      } else {
        toastr.error(
          "",
          "Please fill atleast one worktype Quantity and Unit Fee"
        );
      }
    } else {
      toastr.error(
        "",
        "Please fill atleast one worktype Quantity and Unit Fee"
      );
    }
  };

  manageAnnualReturn = () => {
    CallService(
      this.state.AnnualReturn._id
        ? annualreturn.Update + this.state.AnnualReturn._id
        : annualreturn.Create,
      this.state.AnnualReturn._id ? MethodType.PATCH : MethodType.PUT,
      false,
      this.state.AnnualReturn,
      "",
      this.manageAnnualReturnResponse
    );
  };
  manageAnnualReturnResponse = (data) => {
    this.setState({
      buttonAction: false,
      confirmModal: false,
    });
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        IsYear: false,
      });
      toastr.success(
        "",
        this.state.AnnualReturn._id
          ? "Annual Return Updated Successfully"
          : "Annual Return Submitted Successfully"
      );
      this.GetAllAnnualReturns(0);
    } else {
      toastr.error("", "Something Went Wrong");
    }
    this.toggleAnnaulModal();
  };
  toggleConfirmModal = () => {
    this.setState({
      confirmModal: !this.state.confirmModal,
    });
  };
  GrandTotalFee = (val) => {
    var gd = 0;

    val.WorkTypes.map((grand) => {
      gd +=
        parseInt(grand.Quantity ? grand.Quantity : 0) *
        parseInt(grand.UnitFee ? grand.UnitFee : 0);
    });
    this.setState({
      GrandTotalFee: gd,
    });
  };
  toggleViewDetailsModal = (row) => {
    this.setState({
      viewDetialsValues: row,
      viewDetailsModal: !this.state.viewDetailsModal,
    });
  };
  exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportExcel = () => {
    var ExcelAnnual = [];
    var excel = this.state.viewValues;
    var allWorkType = this.state.WorkTypeList;

    var qua = "";
    var unit = "";
    var sub = "";

    for (var j = 0; j < allWorkType.length; j++) {
      for (var i = 0; i < excel.WorkTypes.length; i++) {
        if (excel.WorkTypes[i].WorkType.Name.en === allWorkType[j].label) {
          qua = excel.WorkTypes[i].Quantity;
          unit = excel.WorkTypes[i].UnitFee;
          sub = excel.WorkTypes[i].TotalFee;
          break;
        } else if (excel.WorkTypes.length - 1 === i) {
          qua = "-";
          unit = "-";
          sub = "-";
        } else {
          qua = "";
          unit = "";
          sub = "";
        }
      }

      var annual = {
        Year: excel.Year,
        WorkTypeName: allWorkType[j].label,
        Quantity: qua,
        UnitFee: unit,
        SubTotal: sub,
      };

      ExcelAnnual.push(annual);
    }

    // for (var i = 0; i < excel.WorkTypes.length; i++) {
    //   var annual = {

    //     Year: excel.Year,
    //     WorkTypeName:
    //       this.state.Language === "en"
    //         ? excel.WorkTypes[i].WorkType.Name.en
    //         : excel.WorkTypes[i].WorkType.Name.ta,
    //     Quantity: excel.WorkTypes[i].Quantity,
    //     UnitFee: excel.WorkTypes[i].UnitFee,
    //     SubTotal: excel.WorkTypes[i].TotalFee,
    //   };

    //   ExcelAnnual.push(annual);
    // }
    var qu = this.state.TotalQuantityValues;
    var total = this.state.TotalValues;
    var unit = this.state.TotalUnitFeeValues;

    var Grand = {
      //"GrandTotal",
      Year: "",
      WorkTypeName: "Grand Total",
      Quantity: qu,
      UnitFee: unit,
      SubTotal: total,
    };
    ExcelAnnual.push(Grand);

    this.exportToCSV(ExcelAnnual, "AnnualReturn");
    console.log(ExcelAnnual);
  };

  render() {
    const conditionalRowStyles = [
      {
        when: (row) => row,
        style: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ];
    const initialAnnualValues = this.state.AnnualReturn;
    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4">
              <CardBody>
                {this.state.UserName === "advocate" ? (
                  <ListPageHeader
                    heading={`${this.props.t("Home")}.${this.props.t(
                      "Annual Return"
                    )}`}
                    onTextChange={this.SearchQueryChanged}
                    buttonClick={this.addBtnClick}
                    searchValue={this.state.ListRequestModel.searchString}
                    buttonText={this.props.t("Add Annual Return")}
                  />
                ) : (
                  <ListPageHeader
                    heading={`${this.props.t("Home")}.${this.props.t(
                      "Annual Return"
                    )}`}
                    onTextChange={this.SearchQueryChanged}
                    buttonClick={this.addBtnClick}
                    showButton={false}
                    searchValue={this.state.ListRequestModel.searchString}
                    buttonText={this.props.t("Add Annual Return")}
                  />
                )}

                <ListPage
                  columns={this.state.columns}
                  data={this.state.AnnualReturns}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  // rowClicked={
                  //   this.state.UserName === "law_admin"
                  //     ? this.toggleViewDetailsModal
                  //     : ""
                  // }
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                  // conditionalRowStyles={
                  //   this.state.UserName === "law_admin"
                  //     ? conditionalRowStyles
                  //     : ""
                  // }
                />
                <Modal
                  isOpen={this.state.AnnualReturnModal}
                  toggle={this.toggleAnnaulModal}
                  size={"lg"}
                >
                  <ModalHeader toggle={this.toggleAnnaulModal}>
                    {this.props.t("Add Annual Return")}
                  </ModalHeader>
                  <Formik
                    initialValues={initialAnnualValues}
                    validationSchema={AnnualReturnValidation}
                    onSubmit={this.handleSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({ errors, values, setFieldValue }) => (
                      <Form className="av-tooltip tooltip-label-bottom">
                        <ModalBody>
                          <Row className="m-3">
                            <Colxx ccs="1"></Colxx>
                            <Colxx xxs="2">
                              <FormGroup className="form-group has-float-label mt-4">
                                <Label>{this.props.t("Year")}</Label>
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isSearchable
                                  options={this.state.YearList}
                                  value={
                                    this.state.Year &&
                                    this.state.YearList &&
                                    this.state.YearList.filter((a) => {
                                      return this.state.Year.some((r) => {
                                        return (
                                          a.value ===
                                          (typeof r === "object"
                                            ? r.value
                                              ? r.value
                                              : r.value
                                            : r)
                                        );
                                      });
                                    }).map(function (o) {
                                      return o;
                                    })
                                  }
                                  ClearAction={() => {
                                    setFieldValue("Year", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Year",
                                      entity ? entity.value : null
                                    );
                                    this.setState({
                                      IsYear: true,
                                      WorkTypeList: [],
                                      ParticularYear: [],
                                    });
                                    this.GetAllAnnualReturns(entity.value);
                                  }}
                                />
                                {errors.Year && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Year}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx ccs="1"></Colxx>
                          </Row>
                          {(this.state.ParticularYear &&
                            this.state.ParticularYear.length) ||
                          (this.state.WorkTypeList &&
                            this.state.WorkTypeList.length) ? (
                            <Fragment>
                              <Row className="mb-10">
                                {/* <Colxx ccs="1"></Colxx> */}
                                <Colxx xxs="4">
                                  <Label>
                                    {this.props.t("Work Type Name")}
                                  </Label>
                                </Colxx>
                                <Colxx xxs="3">
                                  <FormGroup
                                    style={{
                                      textAlign: "right",
                                    }}
                                    className="form-group has-float-label"
                                  >
                                    <Label>{this.props.t("Quantity")}</Label>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="3">
                                  <FormGroup
                                    style={{
                                      textAlign: "right",
                                    }}
                                    className="form-group has-float-label"
                                  >
                                    <Label>
                                      {this.props.t("Unit Fee")} (
                                      <i className="bx bx-rupee" />)
                                    </Label>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="2">
                                  <FormGroup
                                    style={{ textAlign: "right" }}
                                    className="form-group has-float-label"
                                  >
                                    <Label style={{ textAlign: "right" }}>
                                      {this.props.t("Sub Total")} (
                                      <i className="bx bx-rupee" />)
                                    </Label>
                                  </FormGroup>
                                </Colxx>
                              </Row>
                            </Fragment>
                          ) : (
                            ""
                          )}

                          {this.state.ParticularYear &&
                          this.state.ParticularYear.length ? (
                            <Fragment>
                              {this.state.ParticularYear[0].WorkTypes.map(
                                function (wt, i) {
                                  return (
                                    <Fragment>
                                      <Row className="mb-10">
                                        {/* <Colxx ccs="1"></Colxx> */}
                                        <Colxx xxs="4">
                                          <FormGroup>
                                            <div>
                                              {wt.WorkType && wt.WorkType.Name
                                                ? wt.WorkType.Name.en
                                                  ? wt.WorkType.Name.en
                                                  : wt.WorkType.Name
                                                : "-"}
                                            </div>
                                          </FormGroup>
                                        </Colxx>
                                        <Colxx xxs="3">
                                          <FormGroup className="form-group has-float-label">
                                            <div
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              {wt.Quantity}
                                            </div>
                                          </FormGroup>
                                        </Colxx>
                                        <Colxx xxs="3">
                                          <FormGroup className="form-group has-float-label">
                                            <div
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              {wt.UnitFee}
                                            </div>
                                          </FormGroup>
                                        </Colxx>
                                        <Colxx xxs="2">
                                          <FormGroup className="form-group has-float-label">
                                            <div
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              {wt.TotalFee}
                                            </div>
                                          </FormGroup>
                                        </Colxx>
                                        <Colxx xxs="1"></Colxx>
                                      </Row>
                                    </Fragment>
                                  );
                                }
                              )}

                              <Row className="mb-10">
                                <Colxx ccs="1"></Colxx>
                                <Colxx xxs="3">
                                  <FormGroup>
                                    <Label>
                                      {this.props.t("Grand Total")}
                                      {/* (
                                      <i className="bx bx-rupee" />) */}
                                    </Label>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="3">
                                  <FormGroup className="form-group has-float-label">
                                    <div
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {this.state.TotalQuantityValues}
                                    </div>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="3">
                                  <FormGroup className="form-group has-float-label">
                                    <div
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {this.state.TotalUnitFeeValues}
                                    </div>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="2">
                                  <FormGroup className="form-group has-float-label">
                                    <div
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {this.state.TotalValues}
                                    </div>
                                  </FormGroup>
                                </Colxx>
                                <Colxx xxs="1"></Colxx>
                              </Row>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {this.state.WorkTypeList.map((wt, i) => {
                                return (
                                  <Fragment>
                                    <Row className="mb-10">
                                      <Colxx xxs="4">
                                        <FormGroup>
                                          <div>{wt.label}</div>
                                          <Field
                                            disabled
                                            style={{
                                              border: "1px solid white",
                                            }}
                                            type="text"
                                            className="form-control"
                                            //  value={wt.label}
                                            name={wt.label}
                                            //   name={`WorkType[${i}].WorkType`}
                                          />

                                          {/* <div style={{ marginTop: "10px" }}>
                                            {wt && wt.label}
                                          </div> */}
                                        </FormGroup>
                                      </Colxx>
                                      <Colxx xxs="3">
                                        <FormGroup className="form-group has-float-label">
                                          <input
                                            type="number"
                                            className="form-control IncrementHide"
                                            //  name={`WorkTypes[${i}].Quantity`}
                                            style={{ textAlign: "right" }}
                                            onChange={(e) => {
                                              if (values.WorkTypes[i]) {
                                                values.WorkTypes[i].Quantity = e
                                                  .target.value
                                                  ? e.target.value
                                                  : 0;
                                              } else {
                                                values.WorkTypes[i] = {
                                                  Quantity: e.target.value
                                                    ? e.target.value
                                                    : 0,
                                                };
                                              }
                                              var updated = values;
                                              // updated.WorkTypes[i].Quantity =
                                              //   e.target.value;

                                              var total = 0;
                                              updated.WorkTypes.map((q, i) => {
                                                total += parseInt(
                                                  q.Quantity ? q.Quantity : 0
                                                );
                                              });

                                              this.setState(
                                                {
                                                  AnnualReturn: updated,
                                                  QuantityGrandTotal: total,
                                                },
                                                () => {
                                                  this.GrandTotalFee(values);
                                                }
                                              );
                                            }}
                                          />
                                        </FormGroup>
                                      </Colxx>
                                      <Colxx xxs="3">
                                        <FormGroup className="form-group has-float-label">
                                          <input
                                            type="number"
                                            className="form-control IncrementHide"
                                            //  name={`WorkTypes[${i}].Quantity`}
                                            style={{ textAlign: "right" }}
                                            onChange={(e) => {
                                              if (values.WorkTypes[i]) {
                                                values.WorkTypes[i].UnitFee = e
                                                  .target.value
                                                  ? e.target.value
                                                  : 0;
                                              } else {
                                                values.WorkTypes[i] = {
                                                  UnitFee: e.target.value
                                                    ? e.target.value
                                                    : 0,
                                                };
                                              }
                                              var updated = values;
                                              // updated.WorkTypes[i].UnitFee =
                                              //   e.target.value;

                                              var total = 0;
                                              updated.WorkTypes.map((q, i) => {
                                                total += parseInt(
                                                  q.UnitFee ? q.UnitFee : 0
                                                );
                                              });
                                              this.setState(
                                                {
                                                  AnnualReturn: updated,
                                                  UnitFeeGrandTotal: total,
                                                },
                                                () => {
                                                  this.GrandTotalFee(values);
                                                }
                                              );
                                            }}
                                          />
                                          {/* <Field
                                            type="number"
                                            style={{ textAlign: "right" }}
                                            className="form-control IncrementHide"
                                           
                                            // inputProps={{
                                            //   min: 0,
                                            //   max: wt.MaxUnitFee,
                                            // }}
                                            // min={1}
                                            // max={wt.MaxUnitFee}
                                          /> */}
                                          {/* <div>
                                            (Max amt {wt && wt.MaxUnitFee})
                                          </div> */}
                                        </FormGroup>
                                      </Colxx>

                                      <Colxx xxs="2">
                                        <FormGroup className="form-group has-float-label">
                                          <div
                                            style={{
                                              textAlign: "right",
                                            }}
                                          >
                                            <i className="bx bx-rupee" />

                                            {numberFormat(
                                              (values &&
                                              values.WorkTypes &&
                                              values.WorkTypes[i] &&
                                              values.WorkTypes[i].Quantity
                                                ? values &&
                                                  values.WorkTypes &&
                                                  values.WorkTypes[i] &&
                                                  values.WorkTypes[i].Quantity
                                                : 0) *
                                                (values &&
                                                values.WorkTypes &&
                                                values.WorkTypes[i] &&
                                                values.WorkTypes[i].UnitFee
                                                  ? values &&
                                                    values.WorkTypes &&
                                                    values.WorkTypes[i] &&
                                                    values.WorkTypes[i].UnitFee
                                                  : 0)
                                            )}
                                          </div>
                                          {/* <Field
                                            disabled
                                            style={{
                                              border: "1px solid white",
                                              textAlign: "right",
                                            }}
                                            type="text"
                                            className="form-control"
                                            value={numberFormat(
                                              (values &&
                                              values.WorkTypes &&
                                              values.WorkTypes[i] &&
                                              values.WorkTypes[i].Quantity
                                                ? values &&
                                                  values.WorkTypes &&
                                                  values.WorkTypes[i] &&
                                                  values.WorkTypes[i].Quantity
                                                : 0) *
                                                (values &&
                                                values.WorkTypes &&
                                                values.WorkTypes[i] &&
                                                values.WorkTypes[i].UnitFee
                                                  ? values &&
                                                    values.WorkTypes &&
                                                    values.WorkTypes[i] &&
                                                    values.WorkTypes[i].UnitFee
                                                  : 0)
                                            )}
                                            name={`WorkType[${i}].TotalFee`}
                                          /> */}
                                        </FormGroup>
                                      </Colxx>

                                      <Colxx xxs="1"></Colxx>
                                    </Row>
                                  </Fragment>
                                );
                              })}

                              {this.state.WorkTypeList &&
                              this.state.WorkTypeList.length > 0 ? (
                                <Fragment>
                                  <Row className="mb-10">
                                    <Colxx xxs="3">
                                      <FormGroup>
                                        <Label>
                                          {this.props.t("Grand Total")}
                                          {/* (
                                          <i className="bx bx-rupee" />) */}
                                        </Label>
                                      </FormGroup>
                                    </Colxx>

                                    <Colxx xxs="4">
                                      <FormGroup className="mr-2 form-group has-float-label">
                                        <div style={{ textAlign: "right" }}>
                                          {this.state.QuantityGrandTotal}
                                        </div>
                                      </FormGroup>
                                    </Colxx>
                                    <Colxx xxs="3"></Colxx>
                                    {/* <Colxx xxs="3">
                                      <FormGroup className="form-group has-float-label">
                                        <div style={{ textAlign: "right" }}>
                                          {/* {this.state.UnitFeeGrandTotal} 
                                          {numberFormat(
                                            this.state.UnitFeeGrandTotal
                                          )}
                                        </div>
                                      </FormGroup>
                                    </Colxx> */}
                                    <Colxx xxs="2">
                                      <FormGroup className="form-group has-float-label">
                                        <div style={{ textAlign: "right" }}>
                                          <i className="bx bx-rupee" />
                                          {numberFormat(
                                            this.state.GrandTotalFee
                                          )}
                                          {/* <input
                                            disabled
                                            style={{
                                              border: "1px solid white",
                                              textAlign: "right",
                                            }}
                                            type="text"
                                            className="form-control"
                                            value={values.WorkTypes.map(
                                              (grand) => {
                                                var gd = 0;
                                                gd +=
                                                  parseInt(
                                                    grand.Quantity
                                                      ? grand.Quantity
                                                      : 0
                                                  ) *
                                                  parseInt(
                                                    grand.UnitFee
                                                      ? grand.UnitFee
                                                      : 0
                                                  );
                                                return gd;
                                              }
                                            )}
                                            //  name={`WorkType[${i}].TotalFee`}
                                          /> */}
                                        </div>
                                      </FormGroup>
                                    </Colxx>
                                    <Colxx xxs="1"></Colxx>
                                  </Row>
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          {this.state.ParticularYear &&
                          this.state.ParticularYear.length ? (
                            <FormGroup>
                              <Button
                                color="danger"
                                className="ml-2"
                                onClick={() => this.toggleAnnaulModal()}
                              >
                                {this.props.t("Close")}
                              </Button>
                            </FormGroup>
                          ) : (
                            <FormGroup className="float-sm-right m-3">
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Submit")}
                              </Button>

                              <Button
                                color="danger"
                                className="ml-2"
                                onClick={() => this.toggleAnnaulModal()}
                              >
                                {this.props.t("Cancel")}
                              </Button>
                            </FormGroup>
                          )}
                        </ModalFooter>
                      </Form>
                    )}
                  </Formik>
                </Modal>

                <Modal
                  size="lg"
                  isOpen={this.state.viewModal}
                  toggle={this.toggleViewModal}
                >
                  <ModalHeader toggle={this.toggleViewModal}>
                    {this.props.t("View Annual Returns")}
                  </ModalHeader>

                  <ModalBody>
                    <table
                      style={{ textAlign: "right" }}
                      className="table table-bordered table-centered table mb-2"
                    >
                      <tbody>
                        <tr>
                          <th>{this.props.t("Year")}</th>
                          <th
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {this.props.t("Work Type Name")}
                          </th>
                          <th>{this.props.t("Quantity")}</th>
                          <th style={{ width: "130px" }}>
                            {this.props.t("Unit Fee")} (
                            <i className="bx bx-rupee" />)
                          </th>
                          <th style={{ width: "130px"}}>
                            {this.props.t("Sub Total")} (
                            <i className="bx bx-rupee" />)
                          </th>
                        </tr>
                        <td
                          rowSpan={
                            this.state.WorkTypeList &&
                            this.state.WorkTypeList.length + 1
                          }
                        >
                          {this.state.viewValues && this.state.viewValues.Year}
                        </td>

                        {this.state.WorkTypeList &&
                          this.state.WorkTypeList.length > 0 &&
                          this.state.WorkTypeList.map((val, i) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  {" "}
                                  {val.label}
                                </td>

                                <td>
                                  {this.state.viewValues &&
                                    this.state.viewValues.WorkTypes &&
                                    this.state.viewValues.WorkTypes.map(
                                      (total, i) => {
                                        return val.label ===
                                          total.WorkType.Name.en
                                          ? this.state.viewValues &&
                                              this.state.viewValues.WorkTypes[
                                                i
                                              ] &&
                                              this.state.viewValues.WorkTypes[i]
                                                .Quantity
                                          : "";
                                      }
                                    )}
                                </td>

                                <td>
                                  {this.state.viewValues &&
                                    this.state.viewValues.WorkTypes &&
                                    this.state.viewValues.WorkTypes.map(
                                      (total, i) => {
                                        return val.label ===
                                          total.WorkType.Name.en
                                          ? this.state.viewValues &&
                                              this.state.viewValues.WorkTypes[
                                                i
                                              ] &&
                                              this.state.viewValues.WorkTypes[i]
                                                .UnitFee
                                          : "";
                                      }
                                    )}
                                </td>
                                <td>
                                  {this.state.viewValues &&
                                    this.state.viewValues.WorkTypes &&
                                    this.state.viewValues.WorkTypes.map(
                                      (total, i) => {
                                        return val.label ===
                                          total.WorkType.Name.en
                                          ? this.state.viewValues &&
                                              this.state.viewValues.WorkTypes[
                                                i
                                              ] &&
                                              this.state.viewValues.WorkTypes[i]
                                                .TotalFee
                                          : "";
                                      }
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        {/* 
                        {this.state.viewValues &&
                          this.state.viewValues.WorkTypes &&
                          this.state.viewValues.WorkTypes.map((val, i) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  {" "}
                                  {val.WorkType && val.WorkType.Name
                                    ? val.WorkType.Name.en
                                      ? val.WorkType.Name.en
                                      : val.WorkType.Name
                                    : "-"}
                                </td>
                                <td> {val.Quantity}</td>
                                <td> {val.UnitFee}</td>
                                <td> {val.TotalFee}</td>
                              </tr>
                            );
                          })} */}
                        <tr>
                          <th colSpan="2">
                            {this.props.t("Grand Total")}
                            {/* (
                            <i className="bx bx-rupee" />) */}
                          </th>

                          <th>{this.state.TotalQuantityValues}</th>
                          <th>{this.state.TotalUnitFeeValues}</th>
                          <th>{this.state.TotalValues}</th>
                        </tr>
                      </tbody>
                    </table>
                  </ModalBody>
                  <ModalFooter>
                    <FormGroup className="float-sm-right">
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() => this.toggleViewModal()}
                      >
                        {this.props.t("Close")}
                      </Button>
                      <Button
                        color="primary"
                        className="ml-2"
                        onClick={this.exportExcel}
                      >
                        Download
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
                <Modal
                  size="lg"
                  isOpen={this.state.confirmModal}
                  toggle={() => this.toggleConfirmModal()}
                >
                  <ModalHeader toggle={() => this.toggleConfirmModal()}>
                    Confirm Annual Return
                  </ModalHeader>

                  <AvForm onSubmit={() => this.manageAnnualReturn()}>
                    <ModalBody>
                      <Fragment>
                        <table
                          style={{ textAlign: "right" }}
                          className="table table-bordered table-centered table mb-2"
                        >
                          <tr>
                            <th>{this.props.t("Year")}</th>
                            <th
                              style={{
                                textAlign: "left",
                              }}
                            >
                              {this.props.t("Work Type Name")}
                            </th>
                            <th>{this.props.t("Quantity")}</th>
                            <th>
                              {this.props.t("Unit Fee")} (
                              <i className="bx bx-rupee" />)
                            </th>
                            <th>
                              {this.props.t("Sub Total")} (
                              <i className="bx bx-rupee" />)
                            </th>
                          </tr>

                          <td
                            rowSpan={
                              this.state.AnnualReturn &&
                              this.state.AnnualReturn.WorkTypes &&
                              this.state.AnnualReturn.WorkTypes.length + 1
                            }
                          >
                            {this.state.AnnualReturn &&
                              this.state.AnnualReturn.Year}
                          </td>
                          {this.state.AnnualReturn &&
                            this.state.AnnualReturn.WorkTypes &&
                            this.state.AnnualReturn.WorkTypes.map((val, i) => {
                              return (
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div>
                                      {val && val.WorkType
                                        ? this.state.WorkTypeList.find(
                                            (r) => r.value === val.WorkType
                                          ).label
                                        : ""}
                                    </div>
                                  </td>
                                  <td> {val.Quantity}</td>
                                  <td> {val.UnitFee}</td>
                                  <td> {val.TotalFee}</td>
                                </tr>
                              );
                            })}
                          <tr>
                            <th colSpan="2">
                              {this.props.t("Grand Total")}
                              {/* (
                              <i className="bx bx-rupee" />) */}
                            </th>
                            <th> {this.state.QuantityGrandTotal}</th>
                            <th>{this.state.UnitFeeGrandTotal}</th>
                            <th>{this.state.GrandTotalFee}</th>
                          </tr>
                        </table>
                        <br></br>
                        <Row className="mb-4">
                          <Label className="av-label ml-3">
                            <h5>
                              Are you sure you want to submit annual return ?
                            </h5>
                          </Label>
                        </Row>
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        <Button type="submit" outline color="primary">
                          Confirm
                        </Button>
                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleConfirmModal()}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </AvForm>
                </Modal>

                <Modal
                  size="lg"
                  isOpen={this.state.viewDetailsModal}
                  toggle={this.toggleViewDetailsModal}
                >
                  <ModalHeader toggle={this.toggleViewDetailsModal}>
                    {this.props.t("View Details")}
                  </ModalHeader>

                  <ModalBody>
                    {<ViewDetails viewRowId={this.state.viewDetialsValues} />}
                  </ModalBody>
                  <ModalFooter>
                    <FormGroup className="float-sm-right">
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() => this.toggleViewDetailsModal()}
                      >
                        {this.props.t("Close")}
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(AnnualReturn));
