import React, { Component, Fragment } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
  Spinner,
} from "reactstrap";
import { COPValidation } from "../../helpers/validations";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import {
  renewalApplication,
  duplicateCertificateApplication,
  areaExtensionApplication,
  newapplication,
  report,
  district,
  documentMaster,
  uploadCOP,
} from "../../constants/config";
import DropDown from "../../components/custom/DropDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ListPage from "../../components/custom/ListPage";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import ListPageHeader from "../../components/custom/ListPageHeader";
import ViewDetails from "../../pages/ViewDetails/modal";
import html2canvas from "html2canvas";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getFileSizeText,
  checkIfImage,
  getAllowedExtensionText,
} from "../../helpers/Utils";
import ReactTooltip from "react-tooltip";
import "../../../node_modules/react-tooltip/dist/index.js";
import FileDisplay from "../../components/custom/FileDisplay";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      tabs: [],
      activeFirstTab: 1,
      data: [],
      DistrictList: [],
      CoPCopy: "",

      Language: sessionStorage.getItem("lng"),
      //   CurrentList:
      //     this.props.location.pathname === "/renewal"
      //       ? "Renewal Application List"
      //       : this.props.location.pathname === "/areaextension"
      //       ? "Area Extension Application List"
      //       : this.props.location.pathname === "/duplicate"
      //       ? "Duplicate Application List"
      //       : "Application List",
      columns: [
        {
          width: "20%",
          name: <strong>{this.props.t("Application Number")}</strong>,
          selector: "ApplicationNo",
          sortable: true,
          cell: (row) => (
            <span>{row.ApplicationNo ? row.ApplicationNo : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: true,
          cell: (row) => <span>{row.Applicant ? row.Applicant.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("District")}</strong>,
          selector: "District",
          sortable: true,
          cell: (row) => (
            <span>
              {row.District
                ? this.state.Language === "ta"
                  ? row.District.TamilName
                  : row.District.Name
                : ""}
            </span>
          ),
        },

        {
          name: <strong>{this.props.t("Taluks")}</strong>,
          selector: "Taluks",
          sortable: true,
          cell: (row) => (
            <span>
              {row
                ? row.Taluks
                  ? row.Taluks.map((t) => {
                      return this.state.Language === "ta"
                        ? t.TamilName
                        : t.Name;
                    }).join(",  ")
                  : "-"
                : "-"}
            </span>
          ),
        },
        {
          widht: "8%",
          name: <strong>{this.props.t("View")}</strong>,
          selector: "View",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={() => this.toggleViewModal(row)}
              >
                View
              </Button>
            </Row>
          ),
        },
        {
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "Action",
          sortable: true,
          cell: (row) => (
            <span>
              <span
                data-tip={this.props.t("Upload Certificate of Practice Copy")}
                style={{
                  fontSize: "26px",
                  cursor: "pointer",
                }}
                onClick={() => this.toggleUploadCOP(row)}
              >
                <ReactTooltip effect="solid" /> <i className="bx bx-upload"></i>
              </span>

              {row.CoPCopy !== "" ? (
                <a
                  data-tip={this.props.t(
                    "Download Certificate of Practice Copy"
                  )}
                  style={{
                    fontSize: "26px",
                  }}
                  className="pl-1 LinkStyle"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={row.CoPCopy}
                >
                  <i className="bx bx-download" />
                </a>
              ) : (
                ""
              )}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Valid From")}</strong>,
          selector: "ValidFrom",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn ? (
                <span>
                  {moment(new Date(row.ValidFrom)).format("DD/MM/YYYY")}
                  <br />
                  {moment(new Date(row.ValidFrom)).format("hh:mm a")}
                </span>
              ) : (
                ""
              )}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Valid To")}</strong>,
          selector: "ValidTo",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn ? (
                <span>
                  {moment(new Date(row.ValidTo)).format("DD/MM/YYYY")}
                  <br />
                  {moment(new Date(row.ValidTo)).format("hh:mm a")}
                </span>
              ) : (
                ""
              )}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Submitted On")}</strong>,
          selector: "SubmittedOn",
          sortable: true,
          cell: (row) => (
            <span>
              {row.SubmittedOn ? (
                <span>
                  {moment(new Date(row.SubmittedOn * 1000)).format(
                    "DD/MM/YYYY"
                  )}
                  <br />
                  {moment(new Date(row.SubmittedOn * 1000)).format("hh:mm a")}
                </span>
              ) : (
                ""
              )}
            </span>
          ),
        },
      ],
      reqBody: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
        YearDate: null,
      },
    };
  }
  componentDidMount() {
    this.GetAll();
    this.setTab(1);
    this.GetAllDistrict();
  }
  toggleViewModal = (row) => {
    console.log(row);
    if (row) {
      var rowValues = row;
      rowValues.GenerateCertificate = "";
      this.setState({
        viewValues: rowValues,
        activeFirstTab: 1,
        viewModal: !this.state.viewModal,
      });
    } else {
      this.setState({
        viewModal: !this.state.viewModal,
      });
    }
  };
  toggleUploadCOP = (row) => {
    console.log(row);
    if (row) {
      this.setState({
        UploadCOPID: row._id,
        UploadCOPValue: row,
        uploadCOPModal: !this.state.uploadCOPModal,
      });
    } else {
      this.setState({
        uploadCOPModal: !this.state.uploadCOPModal,
      });
    }
  };

  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Applicant"),
        },
        {
          id: 2,
          Name: this.props.t("Personal"),
        },
        {
          id: 3,
          Name: this.props.t("Professional"),
        },
        {
          id: 4,
          Name: this.props.t("Qualification"),
        },
        {
          id: 5,
          Name: this.props.t("Residence Address"),
        },
        {
          id: 6,
          Name: this.props.t("Professional Address"),
        },
      ],
    });
  };
  GetAll() {
    this.setState({
      isLoading: true,
    });
    const { reqBody } = this.state;
    reqBody.Type = "List";

    // var apis;
    // this.props.location.pathname === "/renewal"
    //   ? (apis = renewalApplication)
    //   : this.props.location.pathname === "/areaextension"
    //   ? (apis = areaExtensionApplication)
    //   : this.props.location.pathname === "/duplicate"
    //   ? (apis = duplicateCertificateApplication)
    //   : (apis = newapplication);

    CallService(
      report.GetAll,
      MethodType.POST,
      false,
      reqBody,
      "",
      this.GetAllResponse
    );
  }

  GetAllResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        data: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };

  GetAllExcelDownload = () => {
    console.log(this.state.reqBody);
    const { reqBody } = this.state;
    reqBody.pageLimit = 10000;
    reqBody.Type = "Excel";
    CallService(
      report.GetAll,
      MethodType.POST,
      false,
      reqBody,
      "",
      this.GetAllExcelDownloadResponse
    );
  };

  GetAllExcelDownloadResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) window.open(data.result);
    } else {
      toastr.error("", "Report not found");
    }
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        reqBody: {
          ...this.state.reqBody,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAll();
      }
    );
  };

  HandleRowClicked = (row) => {
    if (row) {
      this.setState({
        datum: row,
        manageModal: !this.state.manageModal,
      });
    }
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        reqBody: {
          ...this.state.reqBody,
          pageNumber: page,
        },
      },
      function () {
        this.GetAll();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        reqBody: {
          ...this.state.reqBody,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.reqBody.searchString === "" ||
          this.state.reqBody.searchString.length > 2
        ) {
          this.GetAll();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      datum: row,
    });
  };

  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };

  //   async savePDF() {
  //     const printArea = document.getElementById("printWrapper");
  //     var options = {
  //       scrollX: -8,
  //       scrollY: -window.scrollY,
  //     };
  //     if (!this.props.match)
  //       options = {
  //         scrollX: -3,
  //       };

  //     html2canvas(printArea, options).then(function (canvas) {
  //       var link = document.createElement("a");
  //       document.body.appendChild(link);
  //       link.download = "report.png";
  //       link.href = canvas.toDataURL("image/png");
  //       link.target = "_blank";
  //       link.click();
  //     });
  // }
  handleCOP = (values) => {
    if (values) {
      console.log(values);
      this.setState({
        buttonLoading: true,
      });
      let formData = new FormData();
      console.log("CoP Copy Files", values.CoPCopy);
      formData.append("COP", values.CoPCopy);
      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.handleCOPResponse,
        true
      );
    }
  };

  handleCOPResponse = (data) => {
    if (data.result) {
      this.setState(
        {
          buttonLoading: false,
          CoPCopy: data.result.COP,
        },
        () => {
          this.SubmitUploadCOP();
        }
      );
    }
  };

  SubmitUploadCOP() {
    var COPValues = {
      CoPCopy: this.state.CoPCopy,
      ApplicationId: this.state.UploadCOPID,
    };
    console.log(COPValues);
    CallService(
      uploadCOP.Upload,
      MethodType.POST,
      false,
      COPValues,
      "",
      this.SubmitUploadCOPResponse
    );
  }

  SubmitUploadCOPResponse = (data) => {
    if (data) {
      toastr.success("", `Certificate of Practice Copy Uploaded successfully`);
      this.setState({
        uploadCOPModal: false,
      });
    } else toastr.error("", "Something went wrong");
  };

  render() {
    const initialCOPValues = this.state.CoPCopy;
    return (
      <Fragment>
        <div className="page-content">
          <div className="mt-4 pt-4 container-fluid">
            {/* <Card className="mt-4">
              <CardBody> */}
            <ListPageHeader
              heading={`${this.props.t("Home")}.${this.props.t("Notary List")}`}
              onTextChange={this.SearchQueryChanged}
              buttonClick={this.GetAllExcelDownload}
              searchValue={this.state.reqBody.searchString}
              buttonText={this.props.t("Gazette Report Download")}
              //  showSearch={false}
            />

            <Row style={{ zIndex: 10000 }}>
              <Colxx xxs="12" sm="4" lg="3" md="4">
                <DropDown
                  className="react-select"
                  name={"District"}
                  //  isMulti
                  MobcolSplit={12}
                  label={this.props.t("District")}
                  isClearable
                  isSearchable
                  options={this.state.DistrictList}
                  values={this.state.reqBody.District}
                  ClearAction={
                    () => {
                      var result = this.state.reqBody;
                      result.District = null;
                      this.setState(
                        {
                          reqBody: result,
                        },
                        () => {
                          this.GetAll();
                        }
                      );
                    }

                    //   this.setState(
                    //     {
                    //       reqBody: null,
                    //     },
                    //     () => {
                    //       this.GetAll();
                    //     }
                    //   );
                    // }}
                  }
                  Action={(entity) => {
                    var result = this.state.reqBody;
                    result.District =
                      entity && entity.value ? entity.value : null;
                    this.setState(
                      {
                        reqBody: result,
                      },
                      () => {
                        this.GetAll();
                      }
                    );
                  }}
                />
              </Colxx>
              <Colxx xxs="12" sm="4" lg="3" md="4">
                <Label>{this.props.t("Valid From")}</Label>
                <DatePicker
                  autoComplete="off"
                  selected={this.state.startDate}
                  className="form-control"
                  value={this.state.reqBody.ValidFrom}
                  showYearDropdown
                  showMonthDropdown
                  selectsStart
                  isClearable={true}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={(date) => {
                    var result = this.state.reqBody;
                    result.ValidFrom = new Date(date).getTime();
                    this.setState(
                      {
                        startDate: date,
                        reqBody: result,
                      },
                      () => {
                        this.GetAll();
                      }
                    );
                    //  values.Dob = new Date(date).getTime();
                  }}
                  placeholderText="dd/mm/yyyy"
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  //  maxDate={new Date()}
                  popperPlacement="bottom"
                />
              </Colxx>

              <Colxx xxs="12" sm="4" lg="3" md="4">
                <Label>{this.props.t("Valid To")}</Label>
                <DatePicker
                  autoComplete="off"
                  selected={this.state.endDate}
                  className="form-control"
                  value={this.state.reqBody.ValidTo}
                  showYearDropdown
                  showMonthDropdown
                  selectsEnd
                  popperPlacement="bottom"
                  isClearable={true}
                  minDate={this.state.startDate}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={(date) => {
                    var result = this.state.reqBody;
                    result.ValidTo = new Date(date).getTime();
                    this.setState(
                      {
                        endDate: date,
                        reqBody: result,
                      },
                      () => {
                        this.GetAll();
                      }
                    );
                  }}
                  placeholderText="dd/mm/yyyy"
                  name="endDate"
                  dateFormat="dd/MM/yyyy"
                  //  maxDate={new Date()}
                />
              </Colxx>
              <Colxx xxs="12" sm="4" lg="3" md="4">
                <Label>{this.props.t("Year")}</Label>
                <DatePicker
                  autoComplete="off"
                  className="form-control"
                  isClearable={true}
                  selected={this.state.Date}
                  popperPlacement="bottom"
                  onChange={(date) => {
                    var result = this.state.reqBody;
                    result.YearDate = date
                      ? new Date(date).getFullYear()
                      : null;
                    this.setState(
                      {
                        Date: date,
                        reqBody: result,
                      },
                      () => {
                        this.GetAll();
                      }
                    );
                  }}
                  placeholderText="Select..."
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Colxx>
            </Row>
            <ListPage
              columns={this.state.columns}
              data={this.state.data}
              keyField={this.state.keyField}
              totalCount={this.state.totalCount}
              rowClicked={this.HandleRowClicked}
              rowsPerPageOnChange={this.handlePerRowsChange}
              pageChange={this.handlePageChange}
              isDataLoading={this.state.isLoading}
              overFlowXRemoval={true}
            />
            {/* </CardBody>
            </Card> */}
          </div>
          <Modal
            size="xl"
            isOpen={this.state.viewModal}
            toggle={this.toggleViewModal}
          >
            <ModalHeader toggle={this.toggleViewModal}>
              {this.props.t("View Details")}
            </ModalHeader>
            <ModalBody>
              <Fragment>
                {<ViewDetails viewRowId={this.state.viewValues} />}
              </Fragment>
            </ModalBody>

            <ModalFooter>
              <Button
                color="danger"
                className="mr-2"
                onClick={() => this.toggleViewModal()}
              >
                {this.props.t("Close")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.uploadCOPModal}
            toggle={() => this.toggleUploadCOP()}
          >
            <ModalHeader toggle={() => this.toggleUploadCOP()}>
              {this.props.t("Upload Certificate of Practice Copy")}
            </ModalHeader>
            <ModalBody>
              <Formik
                onSubmit={this.handleCOP}
                validationSchema={COPValidation}
                initialValues={{ initialCOPValues }}
              >
                {({ errors, values, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row className="mb-4">
                          <Colxx xxs="12">
                            <Label>
                              {this.props.t(
                                "Upload Certificate of Practice(COP)"
                              )}
                            </Label>
                          </Colxx>
                          <Colxx xxs="12">
                            <CustomInput
                              key={"CoPCopy"}
                              type="file"
                              name={"CoPCopy"}
                              onChange={(event) => {
                                setFieldValue("CoPCopy", event.target.files[0]);
                              }}
                            />
                            <p className="text-semi-muted">
                              {getFileSizeText(5, "MB")}

                              <p className="text-semi-muted">
                                {getAllowedExtensionText("pdf", [
                                  "png",
                                  "doc",
                                  "pdf",
                                  "jpg",
                                  "jpeg",
                                ])}
                                <span style={{ float: "right" }}>
                                  {this.state.UploadCOPValue &&
                                  this.state.UploadCOPValue.CoPCopy ? (
                                    <FileDisplay
                                      Value={this.state.UploadCOPValue.CoPCopy}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                            </p>

                            {errors.CoPCopy && (
                              <div className="invalid-feedback d-block">
                                {errors.CoPCopy}
                              </div>
                            )}
                          </Colxx>
                        </Row>
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        <Button color="primary" className="ml-2">
                          {this.state.buttonLoading ? (
                            <span>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              &nbsp;
                              {this.props.t("Submit")}
                            </span>
                          ) : (
                            this.props.t("Submit")
                          )}
                        </Button>
                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleUploadCOP()}
                        >
                          {this.props.t("Cancel")}
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </Modal>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(Report));
