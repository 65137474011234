import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import DropDown from "../../components/custom/DropDown";
import { ApplicationFormValidation } from "../../helpers/validations";
import moment from "moment";
import FileDisplay from "../../components/custom/FileDisplay";
import ReactTooltip from "react-tooltip";
import "../../../node_modules/react-tooltip/dist/index.js";
import { TITLE, FORMTYPES } from "../../constants/defaultValues";
import Form1 from "./Form1";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Button,
  CustomInput,
  Spinner,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import {
  application,
  documentMaster,
  district,
  applicationProcess,
  newapplication,
  taluk,
  areaExtensionApplication,
  duplicateCertificateApplication,
  renewalApplication,
  advocate,
  existingNotary,
  eligibility,
} from "../../constants/config";
import {
  ExistingNotatryValidation,
  nocValidation,
  RenewalFormValidation,
} from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFileSizeText,
  checkIfImage,
  getAllowedExtensionText,
  INVALID_CHARS,
  INVALID_CHARS_REGEX,
  checkIfPdf,
} from "../../helpers/Utils";
import Blink from "react-blink-text";
import PaymentForm from "../../pages/PaymentGateway/PaymentForm";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.toggleAlretView = this.toggleAlretView.bind(this);
    this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      ApplicatibindonTitle: "",
      Applications: [],
      AreaExtensionValues: {},
      area_form_values: {
        district: "",
        taluk: [],
        reason: "",
        place: "",
      },
      Language: sessionStorage.getItem("lng"),
      Status: sessionStorage.getItem("Status"),
      // Status: "Payment Pending",
      CallLetter: sessionStorage.getItem("CallLetter"),
      FeeTotalAmount: sessionStorage.getItem("FeeTotalAmount"),
      SessionValidTo: sessionStorage.getItem("ValidTo"),
      AreaExtensionId: sessionStorage.getItem("AreaExtensionsApplicationID"),
      IsApplication: sessionStorage.getItem("Application"),
      IsExistingNotaryApplications: sessionStorage.getItem(
        "ExistingNotaryApplications"
      ),
      ExistingNotaryApplicationsID: sessionStorage.getItem(
        "ExistingNotaryApplicationsID"
      ),
      ApplicationId: sessionStorage.getItem("ApplicationId"),
      RenewalApplicationID: sessionStorage.getItem("RenewalApplicationID"),
      RenewalStatus: sessionStorage.getItem("RenewalStatus"),
      // RenewalStatus: "Approved",
      AdvocateId: sessionStorage.getItem("user_id"),
      AreaStatus: sessionStorage.getItem("AreaStatus"),
      AreaApplicationID: sessionStorage.getItem("AreaExtensionsApplicationID"),
      DuplicateStatus: sessionStorage.getItem("DuplicateStatus"),
      DuplicateApplicationID: sessionStorage.getItem("DuplicateApplicationID"),
      IsArchived: sessionStorage.getItem("IsArchived"),
      ExistingNotatry: false,
      activeFirstTab: 1,
      tabs: [],
      loading: false,
      DisabledButton: true,
      ApplicationFields: {
        RenewFrom: "",
        LastRenewalGONumber: "",
        LastRenewedDate: "",
        NotaryAppointmentGONumber: "",
        NotaryAppointmentGODate: "",
        NotaryAppointmentDate: "",
        Areas: "",
        ValidFrom: "",
        ValidTo: "",
        RenewalBefore: "",
        Reason: "",
        Name: "",
        Place: "",
      },

      CurrentApplication:
        this.props.location.pathname === "/notaryapplication"
          ? "Notary Application"
          : this.props.location.pathname === "/areaextensionapplication"
          ? "Practicing Area Extension Application"
          : this.props.location.pathname === "/renewalapplication"
          ? "Renewal Application"
          : this.props.location.pathname === "/duplicatecertificate"
          ? "Duplication Certificate Application"
          : "Notary Application",
      activeFirstTab: sessionStorage.getItem("Status") === "Returned" ? 2 : 1,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      buttonLoading: false,
      NotaryApplication: {
        //    SerialNumber: "",
        //    SubmittedOn: null,
        //     SubmittedOnDate: null,
        ValidFrom: null,
        ValidTo: null,
        GO: "",
        GONumber: "",
        District: "",
        Taluks: [],
        // Grounds: "",
      },
      RenewalApplications: [
        {
          //  SubmittedOn: null,
          //   SubmittedOnDate: null,
          ValidFrom: null,
          ValidTo: null,
          GO: "",
          GONumber: null,
        },
      ],
      AreaExtensionApplications: [
        {
          District: null,
          Taluks: [],
          //   SubmittedOn: null,
          //   SubmittedOnDate: null,
          GO: "",
          GONumber: null,
        },
      ],
      // Remarks: "",
      ApplicationProcess: {
        Status: "",
        Comments: "",
      },
      DuplicateFormFields: {
        NotaryAppointmentGONumber: "",
        NotaryAppointmentGODate: "",
        NotaryAppointmentDate: "",
        district: "",
        area: "",
        Reason: "",
        Place: "",
      },
      NOC: "",
      DistrictList: [],
      TalukList: [],
      onSearch: "",
    };
  }

  componentDidMount() {
    console.log("area_form_values", this.state.AreaExtensionValues);
    debugger;
    console.log("areaExtensionApplication", this.state);
    if (this.state?.Status === "Approved") {
      if (this.state?.ApplicationId) {
        this.GetOneApplications();
      } else {
        this.GetOneExistingNotary();
      }
    }
    this.setTab(1);
    this.GetAllDistrict();
    this.GetAllTaluk();
    this.GetAllEligibility();
  }
  GetOneAdvocate() {
    console.log("AdvocateId", this.state.AdvocateId);
    CallService(
      advocate.GetOne + this.state.AreaApplicationID,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneAdvocateResponse
    );
  }

  GetOneAdvocateResponse = (data) => {
    if (data.result) {
      var Dob = data.result ? (data.result ? data.result.Dob : "") : "";
      var date = moment(new Date(Dob)).format("DD/MM/YYYY");
      //     var PrefillAdvocate = this.state.Application;
      var DegreeValue = [];
      for (var i = 0; i < data.result.Degrees.length; i++) {
        var deg = {};
        deg.Institution = data.result.Degrees[i]
          ? data.result.Degrees[i].Institution
          : "";
        deg.Name = data.result.Degrees[i] ? data.result.Degrees[i].Name : "";
        deg.Url = data.result.Degrees[i] ? data.result.Degrees[i].Url : "";
        deg.YearOfPassing = data.result.Degrees[i]
          ? data.result.Degrees[i].YearOfPassing
          : "";
        DegreeValue.push(deg);
      }

      if (data.result.Title === TITLE.THIRUMATHI) {
        var FatherText = "Husband's Name";
      } else {
        FatherText = "Father's Name";
      }

      this.GetAllTaluk(data.result.ResidencyAddress.Address.District);
      var PrefillAdvocate = {
        Title: data.result.Title,
        IsMarried: data.result.IsMarried,
        Name: data.result.Name,
        Gender: data.result.Gender,
        MobileNumber: sessionStorage.getItem("MobileNumber"),
        Dob: date,
        FormType: data.result.FormType,
        EmailID: data.result.EmailID,
        FatherOrHusbandName: data.result.FatherOrHusbandName,
        Community: data.result.Community,
        BarCouncilNumber: data.result.BarCouncilNumber,
        IsIntegratedDegree: data.result.IsIntegratedDegree,
        DeclarationFields: {
          Name: data.result.Name,
          AreaOfPracticeAsAnAdvocate: this.state.ApplicationValues
            ?.DeclarationFields?.AreaOfPracticeAsAnAdvocate
            ? this.state.ApplicationValues?.DeclarationFields
                ?.AreaOfPracticeAsAnAdvocate
            : "",
          Requirements: this.state.ApplicationValues?.DeclarationFields
            ?.AreaOfPracticeAsAnAdvocate
            ? this.state.ApplicationValues?.DeclarationFields?.Requirements
            : "",
          IntendedAreaOfPractice: this.state.ApplicationValues
            ?.DeclarationFields?.AreaOfPracticeAsAnAdvocate
            ? this.state.ApplicationValues?.DeclarationFields
                ?.IntendedAreaOfPractice
            : "",
        },
        ResidencyAddress: {
          Address: {
            Line1: data.result.ResidencyAddress.Address.Line1,
            Line2: data.result.ResidencyAddress.Address.Line2,
            Line3: data.result.ResidencyAddress.Address.Line3,
            District: data.result.ResidencyAddress.Address.District,
            Taluk: data.result.ResidencyAddress.Address.Taluk,
            Pincode: data.result.ResidencyAddress.Address.Pincode,
          },
        },
        ProvisionalAddress: {
          Address: {
            Line1: data.result.ProvisionalAddress.Address.Line1,
            Line2: data.result.ProvisionalAddress.Address.Line2,
            Line3: data.result.ProvisionalAddress.Address.Line3,
            District: data.result.ProvisionalAddress.Address.District,
            Taluk: data.result.ProvisionalAddress.Address.Taluk,
            Pincode: data.result.ProvisionalAddress.Address.Pincode,
          },
          IDProofNumber: data.result.ProvisionalAddress.IDProofNumber,
        },

        Aadhar: data.result.Aadhar,
        PANNumber: data.result.PANNumber,
        EnrolmentDate: data.result.EnrolmentDate
          ? moment(new Date(data.result.EnrolmentDate)).format("DD/MM/YYYY")
          : null,
        PracticingSince: data.result.PracticingSince
          ? moment(new Date(data.result.PracticingSince)).format("DD/MM/YYYY")
          : null,
        PracticingIn: data.result
          ? data.result
            ? data.result.PracticingIn
              ? data.result.PracticingIn.map((In) => {
                  return In._id;
                })
              : null
            : null
          : null,
        IncomeTaxAssessee: data.result.IncomeTaxAssessee,
        Fax: data.result.Fax,

        Degrees: DegreeValue,
        Photo: data.result.Photo,
        AdditionalDocuments: data.result && data.result.AdditionalDocuments,
        //  DistrictLabel: data.result && data.result.District.Name,
      };

      this.setState({
        FatherText: FatherText,
        Application: PrefillAdvocate,
      });
    } else {
      toastr.error("", "Something went wrong");
    }
  };

  GetAllEligibility(row) {
    // if (row && row.ValidTo !== null) {
    // Render the component or logic that depends on the 'ValidTo' property
    CallService(
      eligibility.GetAll,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllEligibilityResponse
    );
    // } else {
    //   // Render an alternative component or handle the case when 'ValidTo' is null
    // }
  }

  togglePhotoViewModal(img, title) {
    if (img) {
      this.setState({
        DocumentView: img,
        DocumentViewModal: !this.state.DocumentViewModal,
        ImageGallery: img,
        Title: title,
      });
    } else {
      this.setState({
        DocumentViewModal: !this.state.DocumentViewModal,
      });
    }
  }

  GetAllEligibilityResponse = (data) => {
    if (data && data.result) {
      const { result } = data;
      this.setState({
        Eligibility: result,
        IsReasonRequired: result?.RenewalApplicationFields?.AskReason || false,
        RenewalDueDate:
          result &&
          result.RenewalApplicationFields &&
          result.RenewalApplicationFields.RenewalDueDate,
      });
    }
  };

  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Existing Notary Details"),
        },
        {
          id: 2,
          Name: this.props.t("Renewal Application"),
        },
        {
          id: 3,
          Name: this.props.t("Area of Practice Details"),
        },
      ],
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  GetOneApplications() {
    CallService(
      newapplication.GetOne + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneApplicationsResponse
    );
  }
  GetOneAreaExtension() {
    CallService(
      areaExtensionApplication.GetOne + this.state.AreaExtensionId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneAreaExtensionResponse
    );
  }

  GetOneAreaExtensionResponse = (data) => {
    debugger;
    this.setState({
      AreaExtensionValues: {
        District: data?.result?.District?.id,
        Taluks: data?.result?.Taluks?.map((list) => list?.id),
        Reason: data?.result?.Reason,
      },
    });
    console.log("areaExtensionApplication", data);
  };
  GetOneApplicationsResponse = (data) => {
    this.setState({
      ApplicationValues: data.result,
    });
  };

  GetAllApplication() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      application.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllApplicationResponse
    );
  }

  GetAllApplicationResponse = (data) => {
    if (data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        Applications: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  GetAllDistrict() {
    CallService(
      district.GetAll,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  GetAllTaluk(districtId) {
    let { ListRequestModel } = this.state;
    ListRequestModel.District = districtId;
    ListRequestModel.pageLimit = 1000;

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  }

  GetAllTalukResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };

  GetOneApplications() {
    CallService(
      newapplication.GetOne + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneApplicationsResponse
    );
  }

  GetOneApplicationsResponse = (data) => {
    this.setState({
      ApplicationValues: data.result,
    });
  };

  handleAreaExtensionSubmit = (values) => {
    debugger;
    values = {
      District: this.state.area_form_values.district,
      Taluks: this.state.area_form_values.taluk,
      Reason: this.state.area_form_values.reason,
      Place: this.state.area_form_values.place,
    };
    var details = values;
    var isValid = false;

    if (this.state.Status !== "Returned") {
      if (this.state.CurrentApplication === "Notary Application") {
        if (values.FormType === FORMTYPES.Form1) {
          if (values.DeclarationFields) {
            if (
              values.DeclarationFields.Name &&
              values.DeclarationFields.AreaOfPracticeAsAnAdvocate &&
              values.DeclarationFields.IntendedAreaOfPractice &&
              values.DeclarationFields.Requirements
            ) {
              isValid = true;
              details.DeclarationFields.Name = values.DeclarationFields.Name
                ? values.DeclarationFields.Name
                : "";
              details.DeclarationFields.AreaOfPracticeAsAnAdvocate = values
                .DeclarationFields.AreaOfPracticeAsAnAdvocate
                ? values.DeclarationFields.AreaOfPracticeAsAnAdvocate
                : "";
              details.DeclarationFields.Requirements = values.DeclarationFields
                .Requirements
                ? values.DeclarationFields.Requirements
                : "";
              details.DeclarationFields.IntendedAreaOfPractice = values
                .DeclarationFields.IntendedAreaOfPractice
                ? values.DeclarationFields.IntendedAreaOfPractice
                : "";
            } else {
              toastr.error("", "Please fill all field");
            }
          } else {
            toastr.error("", "Please fill all field");
          }
        } else {
          isValid = true;
        }
      } else {
        if (values.District && values.Reason) {
          isValid = true;
        } else {
          toastr.error("", "Please fill mandatory field");
        }
      }
    } else {
      this.handleAreaExtensionReSubmit(values);
    }

    if (isValid) {
      if (!this.state.buttonAction) {
        this.setState({
          buttonAction: true,
        });
        if (
          details.Gender ||
          details.Community ||
          details.ResidencyAddress ||
          details.ProvisionalAddress
        ) {
          details.Gender =
            values.Gender && values.Gender._id
              ? values.Gender._id
              : values.Gender;
          details.Community =
            values.Community && values.Community._id
              ? values.Community._id
              : values.Community;

          details.ResidencyAddress.Address.District =
            values.ResidencyAddress &&
            values.ResidencyAddress.Address &&
            values.ResidencyAddress.Address.District._id
              ? values.ResidencyAddress.Address.District._id
              : values.ResidencyAddress.Address.District;

          details.ResidencyAddress.Address.Taluk =
            values.ResidencyAddress &&
            values.ResidencyAddress.Address &&
            values.ResidencyAddress.Address.Taluk._id
              ? values.ResidencyAddress.Address.Taluk._id
              : values.ResidencyAddress.Address.Taluk;

          details.ProvisionalAddress.Address.District =
            values.ProvisionalAddress &&
            values.ProvisionalAddress.Address &&
            values.ProvisionalAddress.Address.District._id
              ? values.ProvisionalAddress.Address.District._id
              : values.ProvisionalAddress.Address.District;

          details.ProvisionalAddress.Address.Taluk =
            values.ProvisionalAddress &&
            values.ProvisionalAddress.Address &&
            values.ProvisionalAddress.Address.Taluk._id
              ? values.ProvisionalAddress.Address.Taluk._id
              : values.ProvisionalAddress.Address.Taluk;
        }
        // details.District = details.ResidencyAddress.Address.District;

        if (this.state.CurrentApplication === "Notary Application") {
          details.District =
            details?.ResidencyAddress?.Address?.District || values.District;
        } else if (values.District) {
          details.District = values.District;
        } else {
          details.District = details?.ResidencyAddress?.Address?.District;
        }

        details.Taluks =
          values?.Taluks?.length !== 0
            ? values.Taluks
            : this.state.AreaExtensionValues.Taluks;
        details.District =
          details.District || this.state.AreaExtensionValues?.District;
        debugger;
        console.log(
          "manageApplication",
          details,
          this.state.AreaExtensionValues
        );
        this.setState({
          buttonAction: true,
        });
        this.manageApplication(details);
      } else {
        this.setState({
          buttonAction: false,
        });
      }
    }
  };
  handleAreaExtensionReSubmit = (values) => {
    var isValidAll;

    if (
      values.AdditionalDocuments &&
      values.AdditionalDocuments[0].Name &&
      values.AdditionalDocuments[0].Url
    ) {
      isValidAll = true;
      this.setState({
        AdditionalLabel: values,
      });
    }

    if (isValidAll) {
      let formData = new FormData();
      for (let i = 0; i < values.AdditionalDocuments.length; i++) {
        formData.append(
          `AdditionalDocuments${i}`,
          values.AdditionalDocuments[i].Url
        );
      }

      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.DocumentUploaded,
        true
      );
    } else {
      toastr.error("", "Please fill additional documents");
    }
  };

  handleSubmit = (values) => {
    var details = {};
    //details.SubmittedOnDate = new Date(values.SubmittedOnDate).getTime();
    // details.SubmittedOn = new Date(values.SubmittedOnDate).getTime();
    details.ValidFrom = new Date(values.ValidFrom).getTime();
    details.ValidTo = new Date(values.ValidTo).getTime();
    details.SerialNumber = values.SerialNumber;
    details.GONumber = values.GONumber;
    //   details.Remarks = values.Remarks ? values.Remarks : "";
    //  details.Grounds = values.Grounds ? values.Grounds : "";
    details.District = values.District ? values.District : null;
    details.Taluks = values.Taluks
      ? values.Taluks.map((taluk) => {
          return taluk.value;
        })
      : null;
    details.GO = values.GO ? values.GO : "";

    // if (!this.state.buttonAction) {

    this.setState((prevState) => ({
      AreaExtensionApplications: [
        {
          ...prevState.AreaExtensionApplications[0],
          District: details.District,
        },
      ],
      NotaryApplication: details,
      activeFirstTab: 2,
    }));
  };
  handleRenewalApplication = (values) => {
    if (values && values.RenewalApplications) {
      var isValidAll = true;
      var errorItem = null;
      for (var i = 0; i < values.RenewalApplications.length; i++) {
        if (
          values.RenewalApplications[i] &&
          // values.RenewalApplications[i].SubmittedOnDate &&
          values.RenewalApplications[i].ValidFrom &&
          values.RenewalApplications[i].ValidTo &&
          values.RenewalApplications[i].GONumber &&
          values.RenewalApplications[i].GO
        ) {
        } else {
          isValidAll = false;
          errorItem = values.RenewalApplications[i];
          break;
        }
      }

      if (isValidAll) {
        var details = {};
        details = values;
        for (var i = 0; i < values.RenewalApplications.length; i++) {
          // details.RenewalApplications[i].SubmittedOnDate = new Date(
          //   values.RenewalApplications[i].SubmittedOnDate
          // ).getTime();
          // details.RenewalApplications[i].SubmittedOn = new Date(
          //   values.RenewalApplications[i].SubmittedOnDate
          // ).getTime();
          details.RenewalApplications[i].ValidFrom = new Date(
            values.RenewalApplications[i].ValidFrom
          ).getTime();
          details.RenewalApplications[i].ValidTo = new Date(
            values.RenewalApplications[i].ValidTo
          ).getTime();
        }
        this.setState({
          RenewalApplications: details.RenewalApplications,
          activeFirstTab: 3,
        });
      } else {
        toastr.error("", "Please fill all field");
      }
    } else {
      this.setState({
        activeFirstTab: 3,
        RenewalApplications: [],
      });
    }
  };

  handleAreaExtensionApplication = (values) => {
    if (values && values.AreaExtensionApplications) {
      var isValidAll = true;
      var errorItem = null;

      for (var i = 0; i < values.AreaExtensionApplications.length; i++) {
        if (!Object.keys(values.AreaExtensionApplications[i]).length) {
          isValidAll = true;
        } else {
          if (
            values.AreaExtensionApplications[i] &&
            values.AreaExtensionApplications[i].District &&
            values.AreaExtensionApplications[i].Taluks &&
            //  values.AreaExtensionApplications[i].SubmittedOnDate &&
            values.AreaExtensionApplications[i].GONumber &&
            values.AreaExtensionApplications[i].GO
          ) {
            isValidAll = true;
          } else {
            isValidAll = false;
            errorItem = values.AreaExtensionApplications[i];
            break;
          }
        }
      }
      if (isValidAll) {
        if (!this.state.loading) {
          var details = {};
          details = values;
          for (var i = 0; i < values.AreaExtensionApplications.length; i++) {
            if (Object.keys(values.AreaExtensionApplications[i]).length) {
              // details.AreaExtensionApplications[i].SubmittedOnDate = new Date(
              //   values.AreaExtensionApplications[i].SubmittedOnDate
              // ).getTime();

              // details.AreaExtensionApplications[i].SubmittedOn = new Date(
              //   values.AreaExtensionApplications[i].SubmittedOnDate
              // ).getTime();

              details.AreaExtensionApplications[i].District = values
                .AreaExtensionApplications[i].District.vallue
                ? values.AreaExtensionApplications[i].District.value
                : values.AreaExtensionApplications[i].District;
              //  details.AreaExtensionApplications[i].District = values[0].District;
              details.AreaExtensionApplications[i].Taluks =
                values.AreaExtensionApplications[i].Taluks.map((obj) =>
                  obj.value ? obj.value : obj
                );
            }
          }

          this.setState({
            buttonAction: true,
            loading: true,
            manageModal: false,
            AreaExtensionApplications: details.AreaExtensionApplications,
          });

          let formData = new FormData();
          formData.append(`NotaryApplication`, this.state.NotaryApplication.GO);

          for (var i = 0; i < this.state.RenewalApplications.length; i++) {
            formData.append(
              `RenewalApplications${i}`,
              this.state.RenewalApplications[i].GO
            );
          }
          for (var i = 0; i < values.AreaExtensionApplications.length; i++) {
            if (Object.keys(values.AreaExtensionApplications[i]).length) {
              formData.append(
                `AreaExtensionApplications${i}`,
                values.AreaExtensionApplications[i].GO
              );
            }
          }
          CallService(
            documentMaster.FileUpload,
            MethodType.POST,
            false,
            formData,
            "",
            this.DocumentUploaded,
            true
          );
        }
      } else {
        toastr.error("", "Please fill mandatory field");
      }
    } else {
      this.setState({
        AreaExtensionApplications: [],
        manageModal: false,
        loading: true,
      });
      let formData = new FormData();
      formData.append(`NotaryApplication`, this.state.NotaryApplication.GO);

      if (this.state.RenewalApplications.length) {
        for (var i = 0; i < this.state.RenewalApplications.length; i++) {
          if (this.state.RenewalApplications[i].GO) {
            formData.append(
              `RenewalApplications${i}`,
              this.state.RenewalApplications[i].GO
            );
          }
        }
      }
      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.DocumentUploaded,
        true
      );
    }
  };

  DocumentUploaded = (data) => {
    if (data.result) {
      var areaDetails = this.state.AreaExtensionApplications;
      var renewalDetails = this.state.RenewalApplications;
      var notaryDetails = this.state.NotaryApplication;

      for (var i = 0; i < this.state.AreaExtensionApplications.length; i++) {
        if (Object.keys(this.state.AreaExtensionApplications[i]).length) {
          if (areaDetails.length > 0) {
            areaDetails[i].GO = data.result["AreaExtensionApplications" + i]
              ? data.result["AreaExtensionApplications" + i]
              : "";
          }
        }
      }

      for (var i = 0; i < this.state.RenewalApplications.length; i++) {
        if (renewalDetails.length > 0) {
          renewalDetails[i].GO = data.result["RenewalApplications" + i]
            ? data.result["RenewalApplications" + i]
            : "";
        }
      }
      notaryDetails.GO = data.result["NotaryApplication"];

      this.setState({
        AreaExtensionApplication: areaDetails,
        RenewalApplications: renewalDetails,
        NotaryApplication: notaryDetails,
      });
      this.manageApplication();
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload Failed");
    }
  };
  onSearch = () => {
    let Input = document.getElementById("searchBox");
    let searchValue = Input.value.toUpperCase();
  };
  toggleManageModal = () => {
    this.setState({
      NotaryApplication: {
        //  SerialNumber: "",
        //  SubmittedOn: null,
        //  SubmittedOnDate: null,
        ValidFrom: null,
        ValidTo: null,
        GO: "",
        GONumber: "",
        District: "",
        Taluks: [],
        //  Grounds: "",
      },
      RenewalApplications: [
        {
          //   SubmittedOn: null,
          //  SubmittedOnDate: null,
          ValidFrom: null,
          ValidTo: null,
          GO: "",
          GONumber: null,
        },
      ],
      AreaExtensionApplications: [
        {
          District: null,
          Taluks: [],
          // SubmittedOn: null,
          //  SubmittedOnDate: null,
          GO: "",
          GONumber: null,
        },
      ],
      activeFirstTab: 1,
      Application: {},
      manageModal: !this.state.manageModal,
    });
    if (this.state.IsExistingNotaryApplications === "true") {
      this.GetOneExistingNotary(this.state.ExistingNotaryApplicationsID);
    }
  };

  manageApplication = (values) => {
    var finalValues = {
      NotaryApplication: this.state.NotaryApplication,
      RenewalApplications: this.state.RenewalApplications,
      AreaExtensionApplications: this.state.AreaExtensionApplications,
    };
    if (values) {
      this.setState({
        IsAreaExtensionApplication: true,
      });
      CallService(
        newapplication.CreateArea,
        MethodType.PUT,
        false,
        values,
        "",
        this.manageApplicationResponse
      );
    } else {
      CallService(
        existingNotary.Create,
        MethodType.PUT,
        false,
        finalValues,
        "",
        this.manageApplicationResponse
      );
    }
  };
  manageApplicationResponse = (data) => {
    if (this.state.IsAreaExtensionApplication) {
      if (data.statusCode === StatusCodes.Success) {
        toastr.success(
          "",
          `${this.state.CurrentApplication} Submitted Successfully`
        );
        this.setState({
          buttonAction: false,
          AreaExtensionFormModal: false,
        });
      } else {
        toastr.error("", "Something went wrong");
      }
      sessionStorage.setItem(
        "AreaStatus",
        data.result ? data.result.Status : ""
      );
      sessionStorage.setItem(
        "AreaExtensionsApplicationID",
        data.result ? data.result._id : ""
      );
      window.location.href = "#/dashboard";
      //    this.props.history.push("/Home");
      // window.location.reload()
    }
    if (!this.state.IsAreaExtensionApplication) {
      if (data.statusCode === StatusCodes.Success) {
        toastr.success("", "Registered Successfully");
        sessionStorage.setItem("Status", data.result.Status);
        sessionStorage.setItem("ExistingNotaryApplications", true);
        sessionStorage.setItem(
          "ExistingNotaryApplicationsID",
          data.result ? data.result._id : ""
        );
        sessionStorage.setItem(
          "ApplicationId",
          data.result ? data.result._id : ""
        );
        //  window.location.href = "#/dashboard";
        this.setState({
          loading: false,
          manageModal: false,
          AreaExtensionFormModal: false,
        });
        window.location.reload();

        this.props.history.push("/advocatelogin");
      } else if (data.statusCode === "409") {
        toastr.warning("", "Existing Notary already created");
        this.setState({
          loading: false,
          manageModal: false,
        });
      } else if (data.statusCode === "422") {
        if (data.exception) {
          toastr.error("", data.exception);
        } else {
          toastr.warning("", "Something went wrong");
        }
      } else {
        toastr.error("", "Something Went Wrong");
        this.setState({
          loading: false,
          manageModal: false,
        });
      }
    }
    //  this.toggleManageModal();
  };
  manageApplicationProcess = (values) => {
    if (this.state.ApplicationProcess.Comments !== "") {
      values = {
        Status: this.state.title,
        ApplicationProcess: this.state.ApplicationProcess.Comments,
      };

      CallService(
        applicationProcess.Update + this.state.UpdateId,
        MethodType.PATCH,
        false,
        values,
        "",
        this.manageProcessResponse
      );
    } else {
      toastr.error("", "Please enter your comments");
    }
  };
  manageProcessResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Application Processed Successfully");
      this.toggleManageModal();
      this.GetAllApplication();
    }
  };
  toggleProcessModal = (row, status) => {
    this.setState({
      processModal: !this.state.processModal,
      UpdateId: row ? row._id : "",
      title: status,
    });
  };

  toggleApplicationModal(row, title) {
    if (title) {
      this.setState({
        alretView: false,
        ApplicationTitle: title,
        ListRowId: row,
        applicationModal: !this.state.applicationModal,
      });
    } else {
      this.setState({
        alretView: false,
        applicationModal: !this.state.applicationModal,
      });
    }
  }
  toggleAlretView(row, title, isReasonRequired) {
    if (title === "Duplicate") {
      // if (
      //   this.state.DuplicateStatus === "" ||
      //   this.state.DuplicateStatus === "Approved" ||
      //   this.state.DuplicateStatus === "Rejected"
      // ) {
      this.setState({
        ApplicationID: row,
        //   alretView: !this.state.alretView,
        DuplicateFormModal: !this.state.DuplicateFormModal,
        // DuplicateFormFields:
        // this.state.Eligibility &&
        // this.state.Eligibility.DuplicateApplicationFields,

        DuplicateFormFields: {
          NotaryAppointmentGONumber: "",
          NotaryAppointmentGODate:
            this.state.Eligibility &&
            this.state.Eligibility.DuplicateApplicationFields &&
            this.state.Eligibility.DuplicateApplicationFields
              .NotaryAppointmentGODate
              ? moment(
                  new Date(
                    this.state.Eligibility &&
                      this.state.Eligibility.DuplicateApplicationFields &&
                      this.state.Eligibility.DuplicateApplicationFields
                        .NotaryAppointmentGODate
                  )
                ).format("DD/MM/YYYY")
              : "",
          NotaryAppointmentDate:
            this.state.Eligibility &&
            this.state.Eligibility.DuplicateApplicationFields &&
            this.state.Eligibility.DuplicateApplicationFields
              .NotaryAppointmentGODate
              ? moment(
                  new Date(
                    this.state.Eligibility &&
                      this.state.Eligibility.DuplicateApplicationFields &&
                      this.state.Eligibility.DuplicateApplicationFields
                        .NotaryAppointmentGODate
                  )
                ).format("DD/MM/YYYY")
              : "",
          area:
            this.state.Eligibility &&
            this.state.Eligibility.DuplicateApplicationFields &&
            this.state.Eligibility.DuplicateApplicationFields.Areas
              ? this.state.Eligibility.DuplicateApplicationFields.Areas?.split(
                  "-"
                )?.[1] || ""
              : "",
          district:
            this.state.Eligibility &&
            this.state.Eligibility.DuplicateApplicationFields &&
            this.state.Eligibility.DuplicateApplicationFields.Areas
              ? this.state.Eligibility?.DuplicateApplicationFields?.Areas?.split(
                  "-"
                )?.[0] || ""
              : "",

          Reason: "",
          Date:
            this.state.Eligibility &&
            this.state.Eligibility.DuplicateApplicationFields &&
            this.state.Eligibility.DuplicateApplicationFields.Date
              ? moment(
                  new Date(
                    this.state.Eligibility &&
                      this.state.Eligibility.DuplicateApplicationFields &&
                      this.state.Eligibility.DuplicateApplicationFields.Date *
                        1000
                  )
                ).format("DD/MM/YYYY")
              : "",
          Place: "",
        },
        ApplicationTitle: title,
      });
      // }
    }
    if (this.state.Eligibility && this.state.Eligibility.Reason !== "EXPIRED") {
      if (title === "Renewal") {
        console.log("Renewal", this.state);
        if (
          this.state.Eligibility &&
          this.state.Eligibility.Renewal
          // this.state.RenewalStatus === "" ||
          // this.state.RenewalStatus === "Approved" ||
          // this.state.RenewalStatus === "Rejected"
        ) {
          this.setState({
            ApplicationID: row,
            //     alretView: !this.state.alretView,
            RenewalFormModal: !this.state.RenewalFormModal,

            RenewalFormFields:
              this.state.Eligibility &&
              this.state.Eligibility.RenewalApplicationFields,
            ApplicationFields: {
              RenewFrom: moment(
                new Date(
                  this.state.Eligibility &&
                    this.state.Eligibility.RenewalApplicationFields &&
                    this.state.Eligibility.RenewalApplicationFields.RenewFrom
                )
              ).format("DD/MM/YYYY"),
              // RenewFrom:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.RenewFrom
              //     ? new Date(
              //         this.state.Eligibility.RenewalApplicationFields.RenewFrom
              //       )
              //     : "",
              LastRenewalGONumber: "",
              LastRenewedDate: "",
              showReason: isReasonRequired,
              NotaryAppointmentGONumber: "",
              NotaryAppointmentGODate: "",
              // NotaryAppointmentGODate:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields
              //     .NotaryAppointmentGODate
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .NotaryAppointmentGODate
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              NotaryAppointmentDate: "",
              // NotaryAppointmentDate:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields
              //     .NotaryAppointmentGODate
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .NotaryAppointmentGODate
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              Areas:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Areas
                  ? this.state.Eligibility.RenewalApplicationFields.Areas
                  : "",
              ValidFrom: "",
              ValidTo: "",
              // ValidFrom:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.ValidFrom
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields
              //               .ValidFrom
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              // ValidTo:
              //   this.state.Eligibility &&
              //   this.state.Eligibility.RenewalApplicationFields &&
              //   this.state.Eligibility.RenewalApplicationFields.ValidTo
              //     ? moment(
              //         new Date(
              //           this.state.Eligibility &&
              //             this.state.Eligibility.RenewalApplicationFields &&
              //             this.state.Eligibility.RenewalApplicationFields.ValidTo
              //         )
              //       ).format("DD/MM/YYYY")
              //     : "",
              RenewalBefore:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.RenewalBefore
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility?.RenewalApplicationFields &&
                          this.state.Eligibility.RenewalApplicationFields
                            .RenewalBefore
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              Reason: "",
              Name:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Name
                  ? this.state.Eligibility.RenewalApplicationFields.Name
                  : "",
              Place: "",
              Date:
                this.state.Eligibility &&
                this.state.Eligibility.RenewalApplicationFields &&
                this.state.Eligibility.RenewalApplicationFields.Date
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.RenewalApplicationFields &&
                          this.state.Eligibility.RenewalApplicationFields.Date *
                            1000
                      )
                    ).format("DD/MM/YYYY")
                  : "",
            },
            ApplicationTitle: title,
          });
        } else if (this.state.RenewalDueDate) {
          this.setState({
            // RenewalModal: true,
            RenewalFormModal: true,
          });
        }
      } else if (title === "Duplicate") {
        if (
          this.state.DuplicateStatus === "" ||
          this.state.DuplicateStatus === "Approved" ||
          this.state.DuplicateStatus === "Rejected"
        ) {
          this.setState({
            ApplicationID: row,
            //   alretView: !this.state.alretView,
            DuplicateFormModal: !this.state.DuplicateFormModal,
            // DuplicateFormFields:
            // this.state.Eligibility &&
            // this.state.Eligibility.DuplicateApplicationFields,

            DuplicateFormFields: {
              NotaryAppointmentGONumber: "",
              NotaryAppointmentGODate:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields
                  .NotaryAppointmentGODate
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .NotaryAppointmentGODate
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              NotaryAppointmentDate:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields
                  .NotaryAppointmentGODate
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .NotaryAppointmentGODate
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              Areas:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields.Areas
                  ? this.state.Eligibility.DuplicateApplicationFields.Areas
                  : "",

              Reason: "",
              Date:
                this.state.Eligibility &&
                this.state.Eligibility.DuplicateApplicationFields &&
                this.state.Eligibility.DuplicateApplicationFields.Date
                  ? moment(
                      new Date(
                        this.state.Eligibility &&
                          this.state.Eligibility.DuplicateApplicationFields &&
                          this.state.Eligibility.DuplicateApplicationFields
                            .Date * 1000
                      )
                    ).format("DD/MM/YYYY")
                  : "",
              Place: "",
            },
            ApplicationTitle: title,
          });
        }
      } else {
        this.setState({
          alretView: false,
        });
      }
    }
  }

  handleConfirmApplication = (values) => {
    var apis;
    values === "Duplicate"
      ? (apis = newapplication.CreateDuplicate)
      : values === "Renewal"
      ? (apis = newapplication.CreateRenewal)
      : (apis = newapplication.Create);

    var applicant = this.state.ApplicationValues;
    var ApplicationResquest = {
      FatherOrHusbandName:
        applicant.Applicant && applicant.Applicant.FatherOrHusbandName,
      IsMarried: applicant.Applicant && applicant.Applicant.IsMarried,
      PracticingSince:
        applicant.Applicant && applicant.Applicant.PracticingSince,
      PracticingIn: applicant.Applicant && applicant.Applicant.PracticingIn,
      IncomeTaxAssessee:
        applicant.Applicant && applicant.Applicant.IncomeTaxAssessee,
      FathersName: applicant.Applicant && applicant.Applicant.FathersName,
      Name: applicant.Applicant && applicant.Applicant.Name,
      MobileNumber: applicant.Applicant && applicant.Applicant.MobileNumber,
      BarCouncilNumber:
        applicant.Applicant && applicant.Applicant.BarCouncilNumber,
      Dob: applicant.Applicant && applicant.Applicant.Dob,
      EmailID: applicant.Applicant && applicant.Applicant.EmailID,
      Gender:
        applicant.Applicant &&
        applicant.Applicant.Gender &&
        applicant.Applicant.Gender._id,
      Community:
        applicant.Applicant &&
        applicant.Applicant.Community &&
        applicant.Applicant.Community._id,
      Aadhar: applicant.CreatedBy && applicant.CreatedBy.Aadhar,
      EnrolmentDate: applicant.CreatedBy && applicant.CreatedBy.EnrolmentDate,
      LandlineNumber: applicant.CreatedBy && applicant.CreatedBy.LandlineNumber,
      PANNumber: applicant.CreatedBy && applicant.CreatedBy.PANNumber,
      Fax: applicant.CreatedBy && applicant.CreatedBy.Fax,
      ResidencyAddress: {
        Address: {
          Line1:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line1,
          Line2:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line2,
          Line3:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress.Address.Line3,
          District:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.District &&
            applicant.CreatedBy.ResidencyAddress.Address.District._id
              ? applicant.CreatedBy.ResidencyAddress.Address.District._id
              : applicant.CreatedBy.ResidencyAddress.Address.District,

          State:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.State &&
            applicant.CreatedBy.ResidencyAddress.Address.State._id
              ? applicant.CreatedBy.ResidencyAddress.Address.State._id
              : applicant.CreatedBy.ResidencyAddress.Address.State,
          Taluk:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.Taluk &&
            applicant.CreatedBy.ResidencyAddress.Address.Taluk._id
              ? applicant.CreatedBy.ResidencyAddress.Address.Taluk._id
              : applicant.CreatedBy.ResidencyAddress.Address.Taluk,
          Pincode:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.Address &&
            applicant.CreatedBy.ResidencyAddress.Address.Pincode,
          ProofType:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.ProofType,
          IDProofNumber:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.IDProofNumber,
          ProofUrl:
            applicant.CreatedBy &&
            applicant.CreatedBy.ResidencyAddress &&
            applicant.CreatedBy.ResidencyAddress.ProofUrl,
        },
      },
      ProvisionalAddress: {
        Address: {
          Line1:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line1,
          Line2:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line2,
          Line3:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress.Address.Line3,
          District:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.District &&
            applicant.CreatedBy.ProvisionalAddress.Address.District._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.District._id
              : applicant.CreatedBy.ProvisionalAddress.Address.District,
          State:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.State &&
            applicant.CreatedBy.ProvisionalAddress.Address.State._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.State._id
              : applicant.CreatedBy.ProvisionalAddress.Address.State,
          Taluk:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.Taluk &&
            applicant.CreatedBy.ProvisionalAddress.Address.Taluk._id
              ? applicant.CreatedBy.ProvisionalAddress.Address.Taluk._id
              : applicant.CreatedBy.ProvisionalAddress.Address.Taluk,
          Pincode:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.Address &&
            applicant.CreatedBy.ProvisionalAddress.Address.Pincode,
          ProofType:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.ProofType,
          IDProofNumber:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.IDProofNumber,
          ProofUrl:
            applicant.CreatedBy &&
            applicant.CreatedBy.ProvisionalAddress &&
            applicant.CreatedBy.ProvisionalAddress.ProofUrl,
        },
      },

      Taluks: applicant
        ? applicant.Taluks
          ? applicant.Taluks.map((taluk) => {
              return taluk._id;
            })
          : ""
        : "",
      District:
        applicant.District && applicant.District._id
          ? applicant.District._id
          : applicant.District,
      //  Remarks: applicant.Remarks ? applicant.Remarks : "",
      //   Grounds: applicant.Grounds ? applicant.Grounds : "",
    };

    if (this.state.ApplicationTitle === "Renewal" || values === "Renewal") {
      ApplicationResquest.Reason = this.state.RenewalFormFields.Reason;
      ApplicationResquest.ReasonDocuments = [
        { Name: "Reason Document", Url: this.state.RRDocumentURL },
      ];
      delete this.state.RenewalFormFields.Reason;
      delete this.state.RenewalFormFields.ReasonDocument;
      delete this.state.RenewalFormFields.AreasDistrict;
      delete this.state.RenewalFormFields.AreasTaluk;
      ApplicationResquest.ApplicationFields = this.state.RenewalFormFields;
    }
    if (this.state.ApplicationTitle === "Duplicate" || values === "Duplicate") {
      ApplicationResquest.ApplicationFields = this.state.DuplicateSubmitValue;
      ApplicationResquest.From = this.state.DuplicateSubmitValue.From;
      ApplicationResquest.Reason = this.state.DuplicateSubmitValue.Reason;
    }
    CallService(
      apis,
      MethodType.PUT,
      false,
      ApplicationResquest,
      "",
      this.handleConfirmApplicationResponse
    );
  };

  handleConfirmApplicationResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      // toastr.success(
      //   "",
      //   `${this.state.ApplicationTitle} application processed successfully`
      // );
      if (this.state.ApplicationTitle === "Renewal") {
        sessionStorage.setItem(
          "RenewalStatus",
          data.result.Status ? data.result.Status : "Payment Pending"
        );
        this.setState({
          RenewalStatus: data.result.Status
            ? data.result.Status
            : "Payment Pending",
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
      } else {
        sessionStorage.setItem(
          "DuplicateStatus",
          data.result.Status ? data.result.Status : "Payment Pending"
        );
        this.setState({
          DuplicateStatus: data.result.Status
            ? data.result.Status
            : "Payment Pending",
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
      }
      //  window.location.reload();

      this.setState(
        {
          DuplicateFormModal: false,
          RenewalFormModal: false,
          buttonAction: false,
          alretView: false,
          FeeTotalAmount:
            data.result &&
            data.result.FeeDetails &&
            data.result.FeeDetails.TotalAmount,
        },
        () => {
          // this.GetAllEligibility();
          //   window.location.reload();
          // this.confirmMakingPayment();
        }
      );
    } else if (data.statusCode === "422") {
      if (data.exception) {
        toastr.error("", data.exception);
      } else {
        toastr.warning("", "Something went wrong");
      }
    } else {
      toastr.error("", "Something Went Wrong");
    }
    this.setState({
      buttonAction: false,
    });
  };
  GetOneExistingNotary() {
    CallService(
      existingNotary.GetOne + this.state.ExistingNotaryApplicationsID,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneExistingNotaryResponse
    );
  }

  GetOneExistingNotaryResponse = (data) => {
    var notary =
      data.result && data.result.NotaryApplication
        ? data.result.NotaryApplication
        : {};
    var renewal =
      data.result && data.result.RenewalApplications
        ? data.result.RenewalApplications
        : [];

    var area =
      data.result && data.result.AreaExtensionApplications
        ? data.result.AreaExtensionApplications
        : [];

    var renewalArray = [];
    if (renewal.length > 0) {
      for (let i = 0; i < renewal.length; i++) {
        var ren = {};

        ren.GO = renewal[i].GO ? renewal[i].GO : "";

        // ren.SubmittedOnDate = renewal[i].SubmittedOnDate
        //   ? new Date(renewal[i].SubmittedOnDate)
        //   : "";
        ren.ValidFrom = renewal[i].ValidFrom
          ? new Date(renewal[i].ValidFrom)
          : "";
        ren.ValidTo = renewal[i].ValidTo ? new Date(renewal[i].ValidTo) : "";
        ren.GONumber = renewal[i].GONumber ? renewal[i].GONumber : "";
        renewalArray.push(ren);
      }
    } else {
      var ren = {};

      ren.SubmittedOn = null;
      //   ren.SubmittedOnDate = null;
      ren.ValidFrom = null;
      ren.ValidTo = null;
      ren.GO = null;
      ren.GONumber = null;
      renewalArray.push(ren);
    }

    var areaArray = [];

    if (area.length > 0) {
      for (let i = 0; i < area.length; i++) {
        var areaObject = {};

        areaObject.District =
          area && area[i].District ? area[i].District._id : "";
        areaObject.Taluks =
          area[i] && area[i].Taluks
            ? area[i].Taluks.map((taluk) => {
                return taluk._id;
              })
            : null;
        // areaObject.SubmittedOnDate = area[i].SubmittedOnDate
        //   ? new Date(area[i].SubmittedOnDate)
        //   : "";

        areaObject.GO = area[i].GO ? area[i].GO : "";
        areaObject.GONumber = area[i].GONumber ? area[i].GONumber : "";
        areaArray.push(areaObject);
      }
    } else {
      var areaObject = {};

      areaObject.District = "";
      areaObject.Taluks = [];
      areaObject.SubmittedOn = null;
      // areaObject.SubmittedOnDate = null;

      areaObject.GO = "";
      areaObject.GONumber = "";
      areaArray.push(areaObject);
    }

    this.setState({
      TaluksEntity: notary.Taluks,
      ApplicationValues: data.result,
      RenewalApplications: renewalArray,
      AreaExtensionApplications: areaArray,
      NotaryApplication: {
        SerialNumber: notary.SerialNumber ? notary.SerialNumber : "",
        // SubmittedOn: notary.SubmittedOnDate
        //   ? new Date(notary.SubmittedOnDate)
        //   : "",
        // SubmittedOnDate: notary.SubmittedOnDate
        //   ? new Date(notary.SubmittedOnDate)
        //   : "",
        ValidFrom: notary.ValidFrom ? new Date(notary.ValidFrom) : "",
        ValidTo: notary.ValidTo ? new Date(notary.ValidTo) : "",
        GO: notary.GO,
        GONumber: notary.GONumber,
        District: notary.District ? notary.District._id : "",
        Taluks: notary.Taluks
          ? notary.Taluks.map((taluk) => {
              return taluk._id;
            })
          : null,
        //  Grounds: notary.Grounds,
      },
    });
  };

  handleNewNotary = () => {
    this.props.history.push("/notaryapplication");
  };
  handleContinueNotatry = () => {
    this.props.history.push("/notaryapplication");
  };

  handleNotatryApplication = () => {
    this.props.history.push("/notaryapplication");
  };

  confirmMakingPayment = () => {
    this.setState({
      MakingPaymentModal: !this.state.MakingPaymentModal,
    });
  };

  handleMakingPayment() {
    var apis;
    this.state.ApplicationTitle === "Renewal"
      ? (apis = renewalApplication.GetMaking + this.state.RenewalApplicationID)
      : this.state.ApplicationTitle === "Duplicate"
      ? (apis =
          duplicateCertificateApplication.GetMaking +
          this.state.DuplicateApplicationID)
      : (apis = newapplication.GetMaking);

    if (
      this.state.ApplicationTitle === "Renewal" ||
      this.state.ApplicatibindonTitle === "Duplicate"
    ) {
      CallService(
        apis,
        MethodType.GET,
        false,
        "",
        "",
        this.handleMakingPaymentResponse
      );
    } else {
      var NOCValues = {
        NOC: this.state.NOC,
        ApplicationId: this.state.ApplicationId,
      };
      CallService(
        apis,
        MethodType.POST,
        false,
        NOCValues,
        "",
        this.handleMakingPaymentResponse
      );
    }
  }

  handleMakingPaymentResponse = (data) => {
    if (data.result) {
      // toastr.success("", `Application processed successfully`);
      if (data.result) {
        sessionStorage.setItem(
          "Status",
          data.result.Status ? data.result.Status : ""
        );
        sessionStorage.setItem("IsArchived", true);
        this.setState({
          confirmMakingPayment: false,
          nocAttachmentModal: false,
          InitiateResponse: data.result,
          MakingPaymentModal: true,
        });
        // window.location.reload();
      }
    } else toastr.error("", "Something went wrong");
  };

  toggleNocAttachment = () => {
    this.setState({
      nocAttachmentModal: !this.state.nocAttachmentModal,
    });
  };
  handleNOC = (values) => {
    if (values) {
      this.setState({
        buttonLoading: true,
      });
      let formData = new FormData();

      formData.append("NOC", values.NOC);
      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.NOCUpload,
        true
      );
    }
  };

  NOCUpload = (data) => {
    if (data.result) {
      this.setState(
        {
          buttonLoading: false,
          NOC: data.result.NOC,
        },
        () => {
          //   this.confirmMakingPayment();
          this.handleMakingPayment();
        }
      );
    }
  };

  toggleRenewalForm = () => {
    this.setState({
      RenewalFormModal: !this.state.RenewalFormModal,
    });
  };
  handleRRDocument = (values) => {
    if (values) {
      let formData = new FormData();

      formData.append("RRD", values);
      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.handleRRDocumentUpload,
        true
      );
    }
  };

  handleRRDocumentUpload = (data) => {
    if (data.result && data.statusCode === "200") {
      this.setState({
        RRDocumentURL: data.result.RRD,
      });
      this.handleConfirmApplication("Renewal");
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload is Failed");
    }
  };
  handleRenewalForm = (values) => {
    debugger;
    var renewal = values;

    renewal.RenewFrom =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.RenewFrom;
    renewal.LastRenewedDate =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.LastRenewedDate;

    // renewal.NotaryAppointmentDate =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.NotaryAppointmentDate;

    // renewal.NotaryAppointmentGODate =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.NotaryAppointmentGODate;

    renewal.RenewalBefore = new Date(
      this.state.ApplicationFields.RenewalBefore
    ).getTime();
    renewal.Date =
      this.state.Eligibility &&
      this.state.Eligibility.RenewalApplicationFields &&
      this.state.Eligibility.RenewalApplicationFields.Date;
    renewal.NotaryAppointmentDate = new Date(
      values.NotaryAppointmentDate
    ).getTime();
    renewal.NotaryAppointmentGODate = new Date(
      values.NotaryAppointmentGODate
    ).getTime();
    renewal.ValidFrom = new Date(values.ValidFrom).getTime();
    renewal.ValidTo = new Date(values.ValidTo).getTime();
    // renewal.ValidFrom =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.ValidFrom;

    // renewal.ValidTo =
    //   this.state.Eligibility &&
    //   this.state.Eligibility.RenewalApplicationFields &&
    //   this.state.Eligibility.RenewalApplicationFields.ValidTo;

    this.setState({
      RenewalFormFields: renewal,
    });
    values.Name = this.state?.ApplicationValues?.Applicant?.Name;
    if (
      values.RenewFrom !== "" &&
      // values.LastRenewalGONumber !== "" &&
      // values.LastRenewedDate !== "" &&
      values.NotaryAppointmentGONumber !== "" &&
      values.NotaryAppointmentGODate !== "" &&
      values.NotaryAppointmentDate !== "" &&
      values.Areass !== "" &&
      values.ValidFrom !== "" &&
      values.ValidTo !== "" &&
      values.AreasDistrict &&
      values.AreasTaluk &&
      // values.RenewalBefore !== "" &&
      values.Reason !== "" &&
      values.Name !== "" &&
      values.Place !== ""
    ) {
      if (values.AreasDistrict && values.AreasTaluk) {
        values.Areas = `${values.AreasDistrict} - ${values.AreasTaluk}`;
        // delete values.AreasDistrict;
        // delete values.AreasTaluk;
      }

      if (
        this.state.ApplicationFields.showReason ||
        !this.state.Eligibility.Renewal
      ) {
        this.handleRRDocument(values.ReasonDocument);
      } else {
        this.handleConfirmApplication("Renewal");
      }

      // this.handleConfirmApplication("Renewal");
    } else {
      toastr.error("", "Please fill all field");
    }
  };

  toggleDuplicateForm = () => {
    this.setState({
      DuplicateFormModal: !this.state.DuplicateFormModal,
    });
  };
  toggleAreaExtensionForm = (values) => {
    debugger;
    if (values === "call_advocate") {
      this.GetOneAdvocate();
    } else if (values === "call_area_extension") {
      this.GetOneAreaExtension();
    }
    this.setState({
      AreaExtensionFormModal: !this.state.AreaExtensionFormModal,
    });
  };
  handleDuplicateForm = (values) => {
    debugger;
    var duplicate = values;
    if (
      values.NotaryAppointmentGONumber !== "" &&
      values.NotaryAppointmentGODate !== "" &&
      values.NotaryAppointmentDate !== "" &&
      values.Reason !== "" &&
      // values.Areas !== "" &&
      values.district !== "" &&
      values.area !== "" &&
      values.Place !== ""
    ) {
      values.Areas = `${values?.district} - ${values?.area}`;
      duplicate.NotaryAppointmentDate = moment(
        values.NotaryAppointmentDate,
        "DD/MM/YYYY"
      ).unix();
      duplicate.NotaryAppointmentGODate = moment(
        values.NotaryAppointmentGODate,
        "DD/MM/YYYY"
      ).unix();
      // this.state.Eligibility &&
      // this.state.Eligibility.DuplicateApplicationFields &&
      // this.state.Eligibility.DuplicateApplicationFields.NotaryAppointmentDate;
      // duplicate.NotaryAppointmentGODate =
      //   this.state.Eligibility &&
      //   this.state.Eligibility.DuplicateApplicationFields &&
      //   this.state.Eligibility.DuplicateApplicationFields
      //     .NotaryAppointmentGODate;

      // duplicate.Date =
      //   this.state.Eligibility &&
      //   this.state.Eligibility.DuplicateApplicationFields &&
      //   this.state.Eligibility.DuplicateApplicationFields.Date;

      // duplicate.NotaryAppointmentGODate = new Date(
      //   this.state.DuplicateFormFields.NotaryAppointmentGODate
      // ).getTime();
      // duplicate.Date = new Date(this.state.DuplicateFormFields.Date).getTime();

      this.setState({
        DuplicateSubmitValue: duplicate,
      });
      this.handleConfirmApplication("Duplicate");
    } else {
      toastr.error("", "Please fill all field");
    }
  };
  // PaymentInitiate = (text) => {
  //   var apis;
  //   var id;
  //   if (text === "Renewal") {
  //     apis = renewalApplication.GetMaking;
  //     id = this.state.RenewalApplicationID;
  //   } else {
  //     apis = duplicateCertificateApplication.GetMaking;
  //     id = this.state.DuplicateApplicationID;
  //   }

  //   CallService(
  //     // newapplication.PaymentInitialize + event._id,
  //     // duplicateCertificateApplication.GetMaking,
  //     apis,
  //     MethodType.POST,
  //     false,
  //     { ApplicationId: id },
  //     "",
  //     this.PaymentInitiateResponse
  //   );
  // };

  // PaymentInitiateResponse = (data) => {
  //
  //   if (data) {
  //     this.setState({
  //       InitiateResponse: data.result,
  //       MakingPaymentModal: true,
  //     });
  //   }
  // };

  AreaExtensionPaymentInitiate = () => {
    CallService(
      areaExtensionApplication.GetMaking,
      // dummyPayment.GetAll,
      MethodType.POST,
      false,
      { ApplicationId: this.state.AreaExtensionId },
      // "",
      "",
      this.AreaExtensionPaymentInitiateResponse
    );
  };

  AreaExtensionPaymentInitiateResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        InitiateResponse: data.result,
        MakingPaymentModal: true,
      });
    } else {
      toastr.error("", "Something went wrong");
    }
  };

  AreaExtensionSubmitPayment = (message) => {
    let formData = new FormData();
    formData.append("msg", message);
    formData.append("isFromUrl", true);
    CallService(
      newapplication.GetPaymentResponse,
      MethodType.POST,
      false,
      formData,
      "",
      this.AreaExtensionSubmitPaymentResponse
    );
  };

  AreaExtensionSubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentStatus: data.result,
      });
    }
  };
  // new

  SubmitPayment = (message) => {
    CallService(
      newapplication.GetPaymentResponse,
      MethodType.POST,
      false,
      // { ApplicationId: this.state.AreaExtensionId },
      { msg: message, isFromUrl: true },
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentStatus: data.result,
      });
    }
  };
  toggleRenewalModal = () => {
    this.setState({
      RenewalModal: !this.state.RenewalModal,
    });
  };

  render() {
    const initialValues = this.state.NotaryApplication;
    const initialRenewalValues = this.state.RenewalApplications;
    const initialAreaValues = this.state.AreaExtensionApplications;
    const initialNOCValues = this.state.NOC;
    // const initialApplicationField = this.state.ApplicationFields;
    return (
      <Fragment>
        <div className="page-content">
          <Row className="mt-4">
            <Colxx xxs="5"></Colxx>
            <Colxx xxs="6 mt-4">
              {this.state.Status === "Payment Pending" ? (
                this.state.IsArchived === "false" ? (
                  <Button
                    size="md"
                    type="button"
                    class="btn btn-secondary"
                    color="primary"
                    onClick={() => this.toggleNocAttachment()}
                  >
                    {this.props.t("Make Payment")}
                  </Button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Colxx>
          </Row>

          <Row>
            {this.state.Status === "Interview Call Letter Sent" ? (
              this.state.CallLetter ? (
                <Fragment>
                  <Colxx xxs="11">
                    <h5 style={{ float: "right" }}>Download Call Letter</h5>
                  </Colxx>

                  <Colxx xxs="1">
                    <div>
                      <a href={this.state.CallLetter}>
                        <i
                          className="bx bxs-download"
                          style={{ fontSize: "25px" }}
                        />
                      </a>
                    </div>
                  </Colxx>
                </Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Row>

          <div className="container-fluid">
            <Colxx xxs="12">
              {/* <Row>
                <Colxx xxs="3"></Colxx>
                <Colxx xxs="5">
                  {this.state.Status === "" ? (
                    ""
                  ) : this.state.Status === "Interview Cleared" ? (
                    <Alert color="success" style={{ textAlign: "center" }}>
                      {this.state.Status}
                    </Alert>
                  ) : (
                    <Alert color="primary" style={{ textAlign: "center" }}>
                      {this.state.Status}
                    </Alert>
                  )}
                </Colxx>
              </Row> */}
              <br></br>
              <br></br>
              <Row>
                {this.state.IsApplication === "true" ? (
                  this.state.Status === "Approved" ? (
                    <Fragment>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="6">
                        <Card
                          style={{
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody>
                            <div style={{ textAlign: "center" }}>
                              <h5>
                                <u>{this.props.t("Notary Details")}</u>
                              </h5>
                            </div>

                            <Fragment>
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Name")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .Name
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              {this.state.ApplicationValues &&
                              this.state.ApplicationValues.NotaryNo ? (
                                <Row className="mb-10">
                                  <Colxx xxs="6">
                                    <Label>
                                      {this.props.t("Notary Number")}
                                    </Label>
                                  </Colxx>
                                  {": "}
                                  <Colxx xxs="4">
                                    {this.state.ApplicationValues
                                      ? this.state.ApplicationValues
                                        ? this.state.ApplicationValues.NotaryNo
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                              ) : (
                                ""
                              )}
                              {this.state.ApplicationValues &&
                              this.state.ApplicationValues.NotaryApplication &&
                              this.state.ApplicationValues.NotaryApplication
                                .SerialNumber ? (
                                <Row className="mb-10">
                                  <Colxx xxs="6">
                                    <Label>
                                      {this.props.t("Serial Number")}
                                    </Label>
                                  </Colxx>
                                  {": "}
                                  <Colxx xxs="4">
                                    {this.state.ApplicationValues
                                      ? this.state.ApplicationValues
                                        ? this.state.ApplicationValues
                                            .NotaryApplication
                                          ? this.state.ApplicationValues
                                              .NotaryApplication.SerialNumber
                                          : "-"
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                              ) : (
                                ""
                              )}
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Mobile Number")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .MobileNumber
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>
                                    {this.props.t("Bar Council Number")}
                                  </Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .BarCouncilNumber
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              {/* <Row className="mb-10">
                                <Colxx xxs="4">
                                  <Label>{this.props.t("Serial Number")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.Applicant
                                      ? this.state.ApplicationValues.Applicant
                                          .SerialNumber
                                        ? this.state.ApplicationValues.Applicant
                                            .SerialNumber
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row> */}
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>
                                    {this.props.t("Appointment Date")}
                                  </Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.ValidFrom
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.ValidFrom
                                          )
                                        ).format("DD/MM/YYYY")
                                      : this.state.ApplicationValues
                                          .NotaryApplication
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.NotaryApplication.ValidFrom
                                          )
                                        ).format("DD/MM/YYYY")
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>

                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Expiry Date")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.ValidTo
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.ValidTo
                                          )
                                        ).format("DD/MM/YYYY")
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Districts")}</Label>
                                </Colxx>
                                {": "}

                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.TotalAreas &&
                                      this.state.ApplicationValues.TotalAreas
                                        .length >= 0
                                      ? this.state.Language === "ta"
                                        ? this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              return TA.District.TamilName;
                                            }
                                          ).join(", ")
                                        : this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              return TA.District.Name;
                                            }
                                          ).join(", ")
                                      : this.state.ApplicationValues.District
                                      ? this.state.Language === "ta"
                                        ? this.state.ApplicationValues.District
                                            .TamilName
                                        : this.state.ApplicationValues.District
                                            .Name
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>

                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Taluks")}</Label>
                                </Colxx>
                                {": "}
                                {/* <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.TotalAreas
                                      ? this.state.ApplicationValues.TotalAreas
                                          .length >= 0
                                        ? this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              return TA.Taluks.map((taluk) => {
                                                return this.state.Language ===
                                                  "ta"
                                                  ? taluk.TamilName
                                                  : taluk.Name;
                                              }).join(", ");
                                            }
                                          )
                                        : this.state.ApplicationValues.NotaryApplication.map(
                                            (taluk) => {
                                              return this.state.Language ===
                                                "ta"
                                                ? taluk.TamilName
                                                : taluk.Name;
                                            }
                                          ).join(", ")
                                      : this.state.ApplicationValues &&
                                        this.state.ApplicationValues.NotaryApplication.map(
                                          (taluk) => {
                                            return this.state.Language === "ta"
                                              ? taluk.TamilName
                                              : taluk.Name;
                                          }
                                        ).join(", ")
                                    : "-"}
                                </Colxx> */}

                                <Colxx xxs="4">
                                  {sessionStorage.setItem(
                                    "TotalTaluks",
                                    JSON.stringify(
                                      this.state.ApplicationValues &&
                                        this.state.ApplicationValues.TotalAreas
                                        ? this.state.ApplicationValues &&
                                            this.state.ApplicationValues
                                              .TotalAreas
                                        : null
                                    )
                                  )}
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.TotalAreas
                                      ? this.state.ApplicationValues.TotalAreas
                                          .length >= 0
                                        ? this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              let filterTaluk =
                                                TA.Taluks.filter(
                                                  (ele, ind) =>
                                                    ind ===
                                                    TA.Taluks.findIndex(
                                                      (element) =>
                                                        element._id === ele._id
                                                    )
                                                );

                                              return filterTaluk
                                                .map((taluk) => {
                                                  return this.state.Language ===
                                                    "ta"
                                                    ? taluk.TamilName
                                                    : taluk.Name;
                                                })
                                                .join(", ");
                                            }
                                          ).join(", ")
                                        : this.state.ApplicationValues.NotaryApplication.map(
                                            (taluk) => {
                                              return this.state.Language ===
                                                "ta"
                                                ? taluk.TamilName
                                                : taluk.Name;
                                            }
                                          ).join(", ")
                                      : this.state.ApplicationValues &&
                                        this.state.ApplicationValues.NotaryApplication.map(
                                          (taluk) => {
                                            return this.state.Language === "ta"
                                              ? taluk.TamilName
                                              : taluk.Name;
                                          }
                                        ).join(", ")
                                    : "-"}
                                </Colxx>
                              </Row>
                              <br></br>
                              <Row>
                                <Colxx xxs="12">
                                  <div
                                    class="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    {/* <Link
                                      to={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Reason ===
                                          "EXPIRED"
                                          ? "/dashboard"
                                          : "/areaextensionapplication"
                                      }
                                    > */}
                                    <Button
                                      data-tip={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Reason ===
                                          "EXPIRED"
                                          ? this.props.t(
                                              "Your cetificate has been expired"
                                            )
                                          : ""
                                      }
                                      size="md"
                                      type="button"
                                      class="btn btn-secondary"
                                      color="success"
                                      onClick={() =>
                                        this.toggleAreaExtensionForm(
                                          "call_area_extension"
                                        )
                                      }
                                      // className={
                                      //   this.state.Eligibility &&
                                      //   this.state.Eligibility.AreaExtension
                                      //     ? ""
                                      //     : "disabled"
                                      // }
                                    >
                                      {this.props.t("Request Area Extension")}
                                    </Button>
                                    {/* </Link> */}

                                    <ReactTooltip effect="solid" />

                                    <Button
                                      data-tip={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Reason ===
                                          "EXPIRED"
                                          ? this.props.t(
                                              "Your cetificate has been expired"
                                            )
                                          : this.state.RenewalStatus ===
                                              "Approved" ||
                                            this.state.RenewalStatus ===
                                              "Rejected" ||
                                            this.state.RenewalStatus === ""
                                          ? this.state.Eligibility &&
                                            this.state.Eligibility.Renewal
                                            ? ""
                                            : this.state.Eligibility &&
                                              !this.state.Eligibility.Renewal &&
                                              this.state.RenewalDueDate
                                            ? ""
                                            : this.props.t(
                                                "An application is under processing."
                                              )
                                          : this.props.t(
                                              "An application is under processing."
                                            )
                                      }
                                      // className={
                                      //   this.state.RenewalStatus ===
                                      //     "Approved" ||
                                      //   this.state.RenewalStatus ===
                                      //     "Rejected" ||
                                      //   this.state.RenewalStatus === ""
                                      //   ?    (this.state.Eligibility && this.state.Eligibility.Renewal)
                                      //    ? ""
                                      //    : "disabled"
                                      //   : ""
                                      // }
                                      className={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Renewal
                                          ? ""
                                          : "disabled"
                                      }
                                      type="button"
                                      size="md"
                                      class="btn btn-secondary"
                                      color="warning"
                                      onClick={() =>
                                        this.toggleAlretView(
                                          "row",
                                          "Renewal",
                                          this.state.IsReasonRequired
                                        )
                                      }
                                    >
                                      {this.props.t("Request Renewal")}
                                    </Button>

                                    <Button
                                      data-tip={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Reason ===
                                          "EXPIRED"
                                          ? this.props.t(
                                              "Your cetificate has been expired"
                                            )
                                          : this.state.DuplicateStatus ===
                                              "Approved" ||
                                            this.state.DuplicateStatus ===
                                              "Rejected" ||
                                            this.state.DuplicateStatus === ""
                                          ? ""
                                          : this.state.DuplicateStatus ===
                                            "Payment Pending"
                                          ? this.props.t(
                                              "Please make payment to submit for your duplicate application"
                                            )
                                          : this.props.t(
                                              "An application is under processing."
                                            )
                                      }
                                      className={
                                        this.state.Eligibility &&
                                        this.state.Eligibility.Duplicate
                                          ? this.state.DuplicateStatus ===
                                              "Approved" ||
                                            this.state.DuplicateStatus ===
                                              "Rejected" ||
                                            this.state.DuplicateStatus === ""
                                            ? ""
                                            : "disabled"
                                          : "disabled"

                                        // this.state.DuplicateStatus === "" ||
                                        // this.state.DuplicateStatus ===
                                        //   "Approved" ||
                                        // this.state.DuplicateStatus ===
                                        //   "Rejected" ||
                                        // (this.state.Eligibility &&
                                        //   !this.state.Eligibility.Duplicate)
                                        //   ? ""
                                        //   : "disabled"
                                      }
                                      size="md"
                                      type="button"
                                      class="btn btn-secondary"
                                      color="primary"
                                      onClick={() =>
                                        this.toggleAlretView("row", "Duplicate")
                                      }
                                    >
                                      {this.props.t("Request Duplicate")}
                                    </Button>
                                  </div>
                                  {this.state.Eligibility &&
                                  this.state.Eligibility.Renewal ? (
                                    this.state.Eligibility &&
                                    this.state.Eligibility
                                      .RenewalDueDaysCount ? (
                                      <div
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <Blink
                                          color="red"
                                          text={`${
                                            this.state.Eligibility
                                              .RenewalDueDaysCount === 1
                                              ? "Last"
                                              : this.state.Eligibility
                                                  .RenewalDueDaysCount
                                          } more days for expiry of due date for renewal`}
                                          fontSize="20"
                                          delay="6"
                                          effectChange={"3"}
                                        ></Blink>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Colxx>
                              </Row>
                            </Fragment>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Colxx xxs="3"></Colxx>

                      <Colxx xxs="5">
                        {this.state.Status ? (
                          <div style={{ textAlign: "center" }}>
                            <h5>
                              <strong>{this.state.Status}</strong>
                            </h5>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        <Card
                          style={{
                            height: "180px",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingTop: "72px",
                              backgroundImage:
                                "linear-gradient(#556ee6, #8b139a)",
                            }}
                            // style={
                            //   this.state.Status === ""
                            //     ? {
                            //         cursor: "pointer",
                            //         textAlign: "center",
                            //         paddingTop: "72px",
                            //         backgroundImage:
                            //           "linear-gradient(#556ee6, #8b139a)",
                            //       }
                            //     : {
                            //         cursor: "pointer",
                            //         textAlign: "center",
                            //         paddingTop: "72px",
                            //         backgroundImage:
                            //           "linear-gradient(#155314, #55C34B)",
                            //       }
                            // }
                            onClick={this.handleNotatryApplication}
                          >
                            <div>
                              <span>
                                {" "}
                                <h4 className={"text-white"}>
                                  {" "}
                                  {this.props.t("Notary Application")}
                                </h4>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Fragment>
                  )
                ) : this.state.IsApplication === "false" &&
                  this.state.IsExistingNotaryApplications === "false" ? (
                  <Fragment>
                    <Colxx xxs="3"></Colxx>
                    {this.props.location.pathname ===
                    "/new_notary_application" ? (
                      <Colxx xxs="5">
                        <Card
                          style={{
                            height: "180px",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingTop: "72px",
                              backgroundImage:
                                "linear-gradient(#556ee6, #8b139a)",
                            }}
                            onClick={() => this.handleNewNotary()}
                          >
                            <div>
                              <span>
                                {" "}
                                <h4 className={"text-white"}>
                                  {" "}
                                  {this.props.t("Apply For New Notary")}
                                </h4>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Colxx>
                    ) : (
                      ""
                    )}
                    {/* <Colxx xxs="2">
                      {" "}
                      <div style={{ paddingTop: "72px", textAlign: "center" }}>
                        <h2>({this.props.t("or")})</h2>
                      </div>
                    </Colxx> */}
                    {this.props.location.pathname === "/notary_renewal" ? (
                      <Colxx xxs="5">
                        <Card
                          style={{
                            height: "180px",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody
                            style={{
                              cursor: "pointer",

                              textAlign: "center",
                              paddingTop: "72px",
                              backgroundImage:
                                "linear-gradient(#556ee6, #8b139a)",
                            }}
                            onClick={this.toggleManageModal}
                          >
                            <div>
                              <span>
                                {" "}
                                <h4 className={"text-white"}>
                                  {" "}
                                  {this.props.t("I am an existing Notary")}
                                </h4>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Colxx>
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : this.state.IsExistingNotaryApplications === "true" ? (
                  this.state.Status === "Approved" ? (
                    <Fragment>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="6">
                        <Card
                          style={{
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody>
                            <div style={{ textAlign: "center" }}>
                              <h5>
                                <u>{this.props.t("Notary Details")}</u>
                              </h5>
                            </div>
                            <Fragment>
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Name")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .Name
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              {this.state.ApplicationValues &&
                              this.state.ApplicationValues.NotaryNo ? (
                                <Row className="mb-10">
                                  <Colxx xxs="6">
                                    <Label>
                                      {this.props.t("Notary Number")}
                                    </Label>
                                  </Colxx>
                                  {": "}
                                  <Colxx xxs="4">
                                    {this.state.ApplicationValues
                                      ? this.state.ApplicationValues
                                        ? this.state.ApplicationValues.NotaryNo
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                              ) : (
                                ""
                              )}
                              {this.state.ApplicationValues &&
                              this.state.ApplicationValues.NotaryApplication &&
                              this.state.ApplicationValues.NotaryApplication
                                .SerialNumber ? (
                                <Row className="mb-10">
                                  <Colxx xxs="6">
                                    <Label>
                                      {this.props.t("Serial Number")}
                                    </Label>
                                  </Colxx>
                                  {": "}
                                  <Colxx xxs="4">
                                    {this.state.ApplicationValues
                                      ? this.state.ApplicationValues
                                        ? this.state.ApplicationValues
                                            .NotaryApplication
                                          ? this.state.ApplicationValues
                                              .NotaryApplication.SerialNumber
                                          : "-"
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                              ) : (
                                ""
                              )}
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Mobile Number")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .MobileNumber
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>
                                    {this.props.t("Bar Council Number")}
                                  </Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues
                                      ? this.state.ApplicationValues.Applicant
                                        ? this.state.ApplicationValues.Applicant
                                            .BarCouncilNumber
                                        : "-"
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              {/* <Row className="mb-10">
                    <Colxx xxs="4">
                      <Label>{this.props.t("Serial Number")}</Label>
                    </Colxx>
                    {": "}
                    <Colxx xxs="4">
                      {this.state.ApplicationValues
                        ? this.state.ApplicationValues.Applicant
                          ? this.state.ApplicationValues.Applicant
                              .SerialNumber
                            ? this.state.ApplicationValues.Applicant
                                .SerialNumber
                            : "-"
                          : "-"
                        : "-"}
                    </Colxx>
                  </Row> */}
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Renewal From")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.ValidFrom
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.ValidFrom
                                          )
                                        ).format("DD/MM/YYYY")
                                      : this.state.ApplicationValues
                                          .NotaryApplication
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.NotaryApplication.ValidFrom
                                          )
                                        ).format("DD/MM/YYYY")
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>

                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Renewal To")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.ValidTo
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.ValidTo
                                          )
                                        ).format("DD/MM/YYYY")
                                      : this.state.ApplicationValues
                                          .NotaryApplication
                                      ? moment(
                                          new Date(
                                            this.state.ApplicationValues.NotaryApplication.ValidTo
                                          )
                                        ).format("DD/MM/YYYY")
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>
                              {/* <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("District")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.District
                                      ? this.state.Language === "ta"
                                        ? this.state.ApplicationValues.District
                                            .TamilName
                                        : this.state.ApplicationValues.District
                                            .Name
                                      : this.state.Language === "ta"
                                      ? this.state.ApplicationValues
                                          .NotaryApplication.District.TamilName
                                      : this.state.ApplicationValues
                                          .NotaryApplication.District.Name
                                    : "-"}
                                </Colxx>
                              </Row>

                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Taluks")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.Taluks
                                      ? this.state.ApplicationValues.Taluks.map(
                                          (taluk) => {
                                            return this.state.Language === "ta"
                                              ? taluk.TamilName
                                              : taluk.Name;
                                          }
                                        ).join(", ")
                                      : this.state.ApplicationValues.NotaryApplication.Taluks.map(
                                          (taluk) => {
                                            return this.state.Language === "ta"
                                              ? taluk.TamilName
                                              : taluk.Name;
                                          }
                                        ).join(", ")
                                    : "-"}
                                </Colxx>
                              </Row> */}
                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("District")}</Label>
                                </Colxx>
                                {": "}

                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.TotalAreas &&
                                      this.state.ApplicationValues.TotalAreas
                                        .length >= 0
                                      ? this.state.Language === "ta"
                                        ? this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              return TA.District.TamilName;
                                            }
                                          ).join(", ")
                                        : this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              return TA.District.Name;
                                            }
                                          ).join(", ")
                                      : this.state.ApplicationValues.District
                                      ? this.state.Language === "ta"
                                        ? this.state.ApplicationValues.District
                                            .TamilName
                                        : this.state.ApplicationValues.District
                                            .Name
                                      : "-"
                                    : "-"}
                                </Colxx>
                              </Row>

                              <Row className="mb-10">
                                <Colxx xxs="6">
                                  <Label>{this.props.t("Taluks")}</Label>
                                </Colxx>
                                {": "}
                                <Colxx xxs="4">
                                  {this.state.ApplicationValues
                                    ? this.state.ApplicationValues.TotalAreas
                                      ? this.state.ApplicationValues.TotalAreas
                                          .length >= 0
                                        ? this.state.ApplicationValues.TotalAreas.map(
                                            (TA) => {
                                              let filterTaluk =
                                                TA.Taluks.filter(
                                                  (ele, ind) =>
                                                    ind ===
                                                    TA.Taluks.findIndex(
                                                      (element) =>
                                                        element._id === ele._id
                                                    )
                                                );

                                              return filterTaluk
                                                .map((taluk) => {
                                                  return this.state.Language ===
                                                    "ta"
                                                    ? taluk.TamilName
                                                    : taluk.Name;
                                                })
                                                .join(", ");
                                            }
                                          ).join(", ")
                                        : this.state.ApplicationValues.NotaryApplication.map(
                                            (taluk) => {
                                              return this.state.Language ===
                                                "ta"
                                                ? taluk.TamilName
                                                : taluk.Name;
                                            }
                                          ).join(", ")
                                      : this.state.ApplicationValues &&
                                        this.state.ApplicationValues
                                          .NotaryApplication.length > 0 &&
                                        this.state.ApplicationValues.NotaryApplication.map(
                                          (taluk) => {
                                            return this.state.Language === "ta"
                                              ? taluk.TamilName
                                              : taluk.Name;
                                          }
                                        ).join(", ")
                                    : "-"}
                                </Colxx>
                              </Row>

                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                {/* <Link
                                  to={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Reason === "EXPIRED"
                                      ? "/dashboard"
                                      : "/areaextensionapplication"
                                  }
                                > */}
                                <Button
                                  data-tip={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Reason === "EXPIRED"
                                      ? this.props.t(
                                          "Your cetificate has been expired"
                                        )
                                      : ""
                                  }
                                  size="md"
                                  onClick={() =>
                                    this.toggleAlretView(
                                      "row",
                                      "Duplicate",
                                      false
                                    )
                                  }
                                  type="button"
                                  class="btn btn-secondary"
                                  color="success"
                                  // className={
                                  //   this.state.Eligibility &&
                                  //   this.state.Eligibility.AreaExtension
                                  //     ? ""
                                  //     : "disabled"
                                  // }
                                >
                                  {/* <Link to="/areaextensionapplication">
                                      <Button
                                        data-tip={
                                          this.state.Eligibility &&
                                            this.state.Eligibility.Reason ===
                                            "EXPIRED"
                                            ? this.props.t(
                                              "Your cetificate has been expired"
                                            )
                                            : ""
                                        }
                                        size="md"
                                        type="button"
                                        class="btn btn-secondary"
                                        color="success"
                                      // className={
                                      //   this.state.Eligibility &&
                                      //   this.state.Eligibility.AreaExtension
                                      //     ? ""
                                      //     : "disabled"
                                      // }
                                      > */}
                                  {this.props.t("Request Area Extension")}
                                </Button>
                                {/* </Link> */}

                                <ReactTooltip effect="solid" />

                                <Button
                                  data-tip={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Reason === "EXPIRED"
                                      ? this.props.t(
                                          "Your cetificate has been expired"
                                        )
                                      : this.state.RenewalStatus ===
                                          "Approved" ||
                                        this.state.RenewalStatus ===
                                          "Rejected" ||
                                        this.state.RenewalStatus === ""
                                      ? this.state.Eligibility &&
                                        this.state.Eligibility.Renewal
                                        ? ""
                                        : this.state.Eligibility &&
                                          !this.state.Eligibility.Renewal &&
                                          this.state.RenewalDueDate
                                        ? ""
                                        : this.props.t(
                                            "An application is under processing."
                                          )
                                      : this.props.t(
                                          "An application is under processing."
                                        )
                                  }
                                  // className={
                                  //   this.state.RenewalStatus ===
                                  //     "Approved" ||
                                  //   this.state.RenewalStatus ===
                                  //     "Rejected" ||
                                  //   this.state.RenewalStatus === ""
                                  //   ?    (this.state.Eligibility && this.state.Eligibility.Renewal)
                                  //    ? ""
                                  //    : "disabled"
                                  //   : ""
                                  // }
                                  className={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Renewal
                                      ? ""
                                      : "disabled"
                                  }
                                  type="button"
                                  size="md"
                                  class="btn btn-secondary"
                                  color="warning"
                                  onClick={() =>
                                    this.toggleAlretView(
                                      "row",
                                      "Renewal",
                                      this.state.IsReasonRequired
                                    )
                                  }
                                >
                                  {this.props.t("Request Renewal")}
                                </Button>

                                <Button
                                  data-tip={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Reason === "EXPIRED"
                                      ? this.props.t(
                                          "Your cetificate has been expired"
                                        )
                                      : this.state.DuplicateStatus ===
                                          "Approved" ||
                                        this.state.DuplicateStatus ===
                                          "Rejected" ||
                                        this.state.DuplicateStatus === ""
                                      ? ""
                                      : this.state.DuplicateStatus ===
                                        "Payment Pending"
                                      ? this.props.t(
                                          "Please make payment to submit for your duplicate application"
                                        )
                                      : this.props.t(
                                          "An application is under processing."
                                        )
                                  }
                                  className={
                                    this.state.Eligibility &&
                                    this.state.Eligibility.Duplicate
                                      ? this.state.DuplicateStatus ===
                                          "Approved" ||
                                        this.state.DuplicateStatus ===
                                          "Rejected" ||
                                        this.state.DuplicateStatus === ""
                                        ? ""
                                        : "disabled"
                                      : "disabled"
                                  }
                                  size="md"
                                  type="button"
                                  class="btn btn-secondary"
                                  color="primary"
                                  onClick={() =>
                                    this.toggleAlretView("row", "Duplicate")
                                  }
                                >
                                  {this.props.t("Request Duplicate")}
                                </Button>
                              </div>
                              {this.state.Eligibility &&
                              this.state.Eligibility.Renewal ? (
                                this.state.Eligibility &&
                                this.state.Eligibility.RenewalDueDaysCount ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Blink
                                      color="red"
                                      style={{
                                        textAlign: "center",
                                      }}
                                      text={`${this.state.Eligibility.RenewalDueDaysCount} more days for expiry of due date for renewal`}
                                      fontSize="20"
                                      delay="6"
                                      effectChange={"3"}
                                    ></Blink>
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Fragment>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Colxx xxs="3"></Colxx>
                      <Colxx xxs="5">
                        <Card
                          style={{
                            height: "180px",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <CardBody
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingTop: "72px",
                              backgroundImage:
                                "linear-gradient(#556ee6, #8b139a)",
                            }}
                            onClick={this.toggleManageModal}
                          >
                            <div>
                              <span>
                                {" "}
                                <h4 className={"text-white"}>
                                  {" "}
                                  {this.props.t("Existing Notary Applications")}
                                </h4>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Fragment>
                  )
                ) : (
                  ""
                )}
              </Row>
            </Colxx>
            <Modal isOpen={this.state.alretView} toggle={this.toggleAlretView}>
              <ModalHeader toggle={this.toggleAlretView}>
                {this.state.ApplicationTitle === "Duplicate"
                  ? this.state.ApplicationTitle + " Application"
                  : this.state.NotaryApplication
                  ? this.state.NotaryApplication &&
                    this.state.NotaryApplication.ValidTo
                    ? "Renewal Certificate Expires On " +
                      moment(
                        new Date(this.state.NotaryApplication.ValidTo)
                      ).format("DD/MM/YYYY")
                    : this.state.ApplicationValues &&
                      this.state.ApplicationValues.ValidTo
                    ? "Renewal Certificate Expires On " +
                      moment(
                        new Date(this.state.ApplicationValues.ValidTo)
                      ).format("DD/MM/YYYY")
                    : "Renewal Application"
                  : "-"}

                {/* &nbsp; {this.state.ApplicationTitle} Application */}
              </ModalHeader>

              <AvForm
              // onSubmit={() =>
              //   this.toggleApplicationModal(this.state.ApplicationID)
              // }
              >
                <ModalBody>
                  <Fragment>
                    <Row className="mb-4">
                      <Label className="av-label ml-3">
                        <h5>
                          {this.state.ApplicationTitle === "Duplicate" ? (
                            // ? "Are you sure you want to request a duplicate for your certificate?"
                            "Are you sure you want to request a duplicate for your certificate?"
                          ) : (
                            //  : "Please confirm you want to submit renewal certificate expiry on "(
                            <div>
                              {" "}
                              Are you sure you want to renew your certificate?
                              <br></br>
                              Your certificate expires on{" "}
                              {this.state.NotaryApplication &&
                              this.state.NotaryApplication.validTo
                                ? moment(
                                    new Date(
                                      this.state.NotaryApplication.ValidTo
                                    )
                                  ).format("DD/MM/YYYY")
                                : this.state.ApplicationValues &&
                                  this.state.ApplicationValues.ValidTo
                                ? moment(
                                    new Date(
                                      this.state.ApplicationValues.ValidTo
                                    )
                                  ).format("DD/MM/YYYY")
                                : ""}
                            </div>
                          )}
                          {/* :  "Are you sure you want to renew your certificate ?"} */}
                        </h5>
                      </Label>
                    </Row>
                  </Fragment>
                </ModalBody>
                <ModalFooter>
                  <FormGroup className="float-sm-right ">
                    <Button
                      type="submit"
                      outline
                      color="primary"
                      onClick={() =>
                        this.handleConfirmApplication(
                          this.state.ApplicationTitle
                        )
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.toggleAlretView()}
                    >
                      No
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </AvForm>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.props.t("Existing Notary")}
              </ModalHeader>
              {/* <div className="col-12">
                <div className="row">
                  <div className="col-12 pointer text-uppercase text-center">
                    <Alert color="primary">Gazzette Number</Alert>
                  </div>
                </div>
              </div> */}
              {this.state.Status === "Approved" || this.state.Status === "" ? (
                ""
              ) : (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 pointer text-uppercase text-center">
                      <Alert color="primary">{this.state.Status}</Alert>
                    </div>
                  </div>
                </div>
              )}
              <Nav tabs className="m-3 nav-tabs-custom nav-justified">
                {this.state.tabs.map((t) => {
                  return (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{
                          active: this.state.activeFirstTab === t.id,
                        }}
                        onClick={() => {
                          if (
                            this.state.IsExistingNotaryApplications === "true"
                          ) {
                            this.toggleCustomJustified(t.id);
                          }
                        }}
                      >
                        <span className="d-none d-sm-block">{t.Name}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              <ModalBody>
                <Formik
                  initialValues={initialValues}
                  validationSchema={ExistingNotatryValidation}
                  onSubmit={this.handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={1}>
                          <Fragment>
                            <Row>
                              {/* <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Serial Number")}
                                  </Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    className="form-control"
                                    name="SerialNumber"
                                    placeholder="Serial Number"
                                  />

                                  {errors.SerialNumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.SerialNumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t("Submitted Date")}
                                  </Label>
                                  <DatePicker
                                    autoComplete="nope"
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    selected={
                                      this.state.NotaryApplication
                                        .SubmittedOnDate
                                    }
                                    className="form-control"
                                    showYearDropdown
                                    showMonthDropdown
                                    onChange={(date) => {
                                      let dateAr = this.state.NotaryApplication;
                                      dateAr.SubmittedOnDate = date;
                                      this.setState({
                                        NotaryApplication: dateAr,
                                      });
                                      values.SubmittedOnDate = date;
                                    }}
                                    placeholderText={"DD/MM/YYYY"}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                  {errors.SubmittedOnDate && (
                                    <div className="invalid-feedback d-block">
                                      {errors.SubmittedOnDate}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx> */}
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {" "}
                                    {this.props.t("Appointment Date")}
                                  </Label>

                                  <DatePicker
                                    autoComplete="nope"
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    showYearDropdown
                                    showMonthDropdown
                                    selected={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    className="form-control"
                                    selectsStart
                                    startDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    maxDate={new Date().getTime()}
                                    endDate={
                                      this.state.NotaryApplication.ValidTo
                                    }
                                    onChange={(date) => {
                                      let dateAr = this.state.NotaryApplication;
                                      dateAr.ValidFrom = date;
                                      dateAr.ValidTo = "";
                                      this.setState({
                                        NotaryApplication: dateAr,
                                      });
                                      values.ValidFrom = date;
                                    }}
                                    placeholderText={"DD/MM/YYYY"}
                                    dateFormat="dd/MM/yyyy"
                                  />

                                  {errors.ValidFrom && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ValidFrom}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Expiry Date")}{" "}
                                  </Label>
                                  <DatePicker
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    showYearDropdown
                                    showMonthDropdown
                                    selected={
                                      this.state.NotaryApplication.ValidTo
                                    }
                                    className="form-control"
                                    selectsEnd
                                    startDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    endDate={
                                      this.state.NotaryApplication.ValidTo
                                    }
                                    minDate={
                                      this.state.NotaryApplication.ValidFrom
                                    }
                                    onChange={(date) => {
                                      let dateAr = this.state.NotaryApplication;
                                      dateAr.ValidTo = date;
                                      this.setState({
                                        NotaryApplication: dateAr,
                                      });
                                      values.ValidTo = date;
                                    }}
                                    placeholderText={"DD/MM/YYYY"}
                                    dateFormat="dd/MM/yyyy"
                                    autoComplete="nope"
                                  />
                                  {errors.ValidTo && (
                                    <div className="invalid-feedback d-block">
                                      {errors.ValidTo}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Appointment GO Number")}
                                  </Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    type="text"
                                    className="form-control"
                                    name="GONumber"
                                    placeholder="Go Number"
                                  />
                                  {errors.GONumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.GONumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx style={{ zIndex: 0 }} xxs="6">
                                <Label className="requiredField">
                                  {this.props.t("Appointment GO Copy")}
                                </Label>
                                <FormGroup className="form-group has-float-label">
                                  <CustomInput
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    key={"GO"}
                                    type="file"
                                    name={"GO"}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "GO",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  {errors.GO ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.GO}
                                    </div>
                                  ) : null}
                                  {/* <p className="text-semi-muted">
                                    Allowed formats are png, doc, pdf, jpg and
                                    jpeg
                                    {this.state.IsExistingNotaryApplications ===
                                    "true" ? (
                                      <p
                                        style={{
                                          color: "blue",
                                          cursor: "Pointer",
                                          textAlign: "right",
                                        }}
                                      >
                                        <FileDisplay
                                          Value={
                                            this.state.NotaryApplication.GO
                                          }
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </p> */}
                                  <p className="text-semi-muted">
                                    {getFileSizeText(5, "MB")}

                                    <p className="text-semi-muted">
                                      {getAllowedExtensionText("pdf", [
                                        "png",
                                        "doc",
                                        "pdf",
                                        "jpg",
                                        "jpeg",
                                      ])}
                                      {this.state
                                        .IsExistingNotaryApplications ===
                                      "true" ? (
                                        <p
                                          style={{
                                            color: "blue",
                                            cursor: "Pointer",
                                            textAlign: "right",
                                          }}
                                        >
                                          <FileDisplay
                                            Value={
                                              this.state.NotaryApplication.GO
                                            }
                                          />
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </p>
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    components={{ Input: CustomSelectInput }}
                                    classNamePrefix="react-select"
                                    labelClassName="requiredField"
                                    isSearchable
                                    label={this.props.t("District")}
                                    isClearable={true}
                                    isDisabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    value={
                                      values.District &&
                                      (typeof values.District === "string"
                                        ? this.state.DistrictList.find(
                                            (r) => r.value === values.District
                                          )
                                        : this.state.DistrictList.find(
                                            (r) =>
                                              r.value === values.District._id
                                          ))
                                    }
                                    options={this.state.DistrictList}
                                    ClearAction={() => {
                                      setFieldValue("District", null);
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "District",
                                        entity ? entity.value : null
                                      );
                                      values.Taluks = [];
                                      this.GetAllTaluk(
                                        entity ? entity.value : ""
                                      );
                                    }}
                                  />
                                  {errors.District ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.District}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="6">
                                <FormGroup className="form-group has-float-label">
                                  <DropDown
                                    isDisabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="Taluk"
                                    labelClassName="requiredField"
                                    label={this.props.t("Taluks")}
                                    isMulti
                                    isSearchable
                                    options={this.state.TalukList}
                                    value={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? values &&
                                          values.Taluks &&
                                          this.state.TalukList.filter((a) => {
                                            return values.Taluks.some((r) => {
                                              return (
                                                a.value ===
                                                (typeof r === "object"
                                                  ? r._id
                                                    ? r._id
                                                    : r.value
                                                  : r)
                                              );
                                            });
                                          }).map(function (o) {
                                            return o;
                                          })
                                        : values.Taluks
                                    }
                                    ClearAction={() => {
                                      setFieldValue("Taluks", null);
                                    }}
                                    Action={(entity) => {
                                      setFieldValue(
                                        "Taluks",
                                        entity ? entity : ""
                                      );

                                      var filterValues =
                                        this.state.TalukList.length > 0 &&
                                        this.state.TalukList.filter(function (
                                          val
                                        ) {
                                          return entity
                                            ? entity.indexOf(val) === -1
                                            : null;
                                        });

                                      this.setState({
                                        TaluksEntity: entity,
                                        FilterTalukValues: filterValues,
                                      });
                                    }}
                                  />
                                  {errors.Taluks ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.Taluks}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Colxx>
                              {/* <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Grounds")}</Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    component="textarea"
                                    autoFocus
                                    className="form-control"
                                    name="Grounds"
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("Remarks")}</Label>
                                  <Field
                                    disabled={
                                      this.state
                                        .IsExistingNotaryApplications === "true"
                                        ? true
                                        : false
                                    }
                                    component="textarea"
                                    autoFocus
                                    className="form-control"
                                    name="Remarks"
                                  />
                                </FormGroup>
                              </Colxx> */}
                            </Row>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {this.state.IsExistingNotaryApplications ===
                            "true" ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Next")}
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialRenewalValues}
                  //  validationSchema={ApplicationValidation}
                  onSubmit={this.handleRenewalApplication}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={2}>
                          <Fragment>
                            <Row>
                              {this.state.RenewalApplications
                                ? this.state.RenewalApplications.length > 0
                                  ? this.state.RenewalApplications.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            {/* <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {" "}
                                                  {this.props.t(
                                                    "Submitted Date"
                                                  )}
                                                </Label>
                                                <DatePicker
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].SubmittedOnDate
                                                  }
                                                  onChange={(date) => {
                                                    var dateAr = this.state
                                                      .RenewalApplications;
                                                    dateAr[
                                                      Index
                                                    ].SubmittedOnDate = date;

                                                    this.setState({
                                                      RenewalApplications: dateAr,
                                                    });
                                                    setFieldValue(
                                                      `RenewalApplications[${Index}].SubmittedOnDate`,
                                                      dateAr[Index]
                                                        .SubmittedOnDate
                                                    );
                                                  }}
                                                  placeholderText="dd/mm/yyyy"
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx> */}
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {" "}
                                                  {this.props.t("Renewed From")}
                                                </Label>
                                                <DatePicker
                                                  autoComplete="nope"
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  className="form-control"
                                                  selectsStart
                                                  startDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  maxDate={new Date().getTime()}
                                                  endDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  onChange={(date) => {
                                                    if (date) {
                                                      var dateAr =
                                                        this.state
                                                          .RenewalApplications;

                                                      dateAr[Index].ValidFrom =
                                                        date;

                                                      var validto = moment(
                                                        date
                                                      ).add(5, "year");

                                                      dateAr[Index].ValidTo =
                                                        validto._d;

                                                      this.setState({
                                                        RenewalApplications:
                                                          dateAr,
                                                      });
                                                      setFieldValue(
                                                        `RenewalApplications[${Index}].ValidFrom`,
                                                        dateAr[Index].ValidFrom
                                                      );
                                                      setFieldValue(
                                                        `RenewalApplications[${Index}].ValidTo`,
                                                        dateAr[Index].ValidTo
                                                      );
                                                    }
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t(
                                                    "Renewed To (5 Years)"
                                                  )}{" "}
                                                </Label>
                                                <DatePicker
                                                  disabled={true}
                                                  // disabled={
                                                  //   this.state
                                                  //     .IsExistingNotaryApplications ===
                                                  //   "true"
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  selected={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  className="form-control"
                                                  selectsEnd
                                                  startDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  endDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidTo
                                                  }
                                                  minDate={
                                                    this.state
                                                      .RenewalApplications[
                                                      Index
                                                    ].ValidFrom
                                                  }
                                                  //   isClearable={true}
                                                  onChange={(date) => {
                                                    var dateAr =
                                                      this.state
                                                        .RenewalApplications;
                                                    dateAr[Index].ValidTo =
                                                      date;

                                                    this.setState({
                                                      RenewalApplications:
                                                        dateAr,
                                                    });
                                                    setFieldValue(
                                                      `RenewalApplications[${Index}].ValidTo`,
                                                      dateAr[Index].ValidTo
                                                    );
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t(
                                                    "Last Renewal GO Number"
                                                  )}
                                                </Label>
                                                <Field
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  type="text"
                                                  className="form-control"
                                                  name={`[${Index}].GONumber`}
                                                  onChange={(event) => {
                                                    if (event) {
                                                      setFieldValue(
                                                        `RenewalApplications[${Index}].GONumber`,
                                                        event.target.value
                                                      );
                                                    } else {
                                                      delete values
                                                        .RenewalApplications[
                                                        Index
                                                      ].GONumber;
                                                    }
                                                  }}
                                                  placeholder="GO Number"
                                                />
                                                {errors.GONumber && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GONumber}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx
                                              style={{ zIndex: 0 }}
                                              xxs="5"
                                            >
                                              <Label>
                                                {this.props.t(
                                                  "Last Renewal GO Copy"
                                                )}
                                              </Label>
                                              <FormGroup className="form-group has-float-label">
                                                <CustomInput
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  key={"GO"}
                                                  type="file"
                                                  name={"GO"}
                                                  onChange={(event) => {
                                                    if (event) {
                                                      setFieldValue(
                                                        `RenewalApplications[${Index}].GO`,
                                                        event.target.files[0]
                                                      );
                                                    } else {
                                                      delete values
                                                        .RenewalApplications[
                                                        Index
                                                      ].GO;
                                                    }
                                                  }}
                                                />

                                                {errors.GO ? (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GO}
                                                  </div>
                                                ) : null}
                                                <p className="text-semi-muted">
                                                  {getFileSizeText(5, "MB")}

                                                  <p className="text-semi-muted">
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "png",
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true" ? (
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state
                                                              .RenewalApplications[
                                                              Index
                                                            ].GO
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>
                                                {/* <p className="text-semi-muted">
                                                  Allowed formats are png, doc,
                                                  pdf, jpg and jpeg
                                                  {this.state
                                                    .IsExistingNotaryApplications ===
                                                  "true" ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state
                                                            .RenewalApplications[
                                                            Index
                                                          ].GO
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p> */}
                                              </FormGroup>
                                            </Colxx>

                                            <div
                                              style={{
                                                marginTop: "33px",
                                              }}
                                            >
                                              {this.state.RenewalApplications
                                                .length > 1 && (
                                                <i
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  className="bx bx-minus-circle cursorPointer"
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .RenewalApplications;

                                                    selectedProduct.splice(
                                                      Index,
                                                      1
                                                    );
                                                    this.setState({
                                                      RenewalApplications:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                              &nbsp;&nbsp;&nbsp;
                                              {this.state.RenewalApplications
                                                .length ===
                                                Index + 1 && (
                                                <i
                                                  className="bx bx-plus-circle cursorPointer"
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .RenewalApplications;
                                                    selectedProduct.push({
                                                      //    SubmittedOn: "",
                                                      //    SubmittedOnDate: "",
                                                      ValidFrom: "",
                                                      ValidTo: "",
                                                      GO: "",
                                                      GONumber: "",
                                                    });
                                                    this.setState({
                                                      RenewalApplications:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""}
                            </Row>
                            <hr></hr>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {this.state.IsExistingNotaryApplications ===
                            "true" ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Next")}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={initialAreaValues}
                  //  validationSchema={ApplicationValidation}
                  onSubmit={this.handleAreaExtensionApplication}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={3}>
                          <Fragment>
                            <Row>
                              {this.state.AreaExtensionApplications
                                ? this.state.AreaExtensionApplications.length >
                                  0
                                  ? this.state.AreaExtensionApplications.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <DropDown
                                                  isDisabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  //  labelClassName="requiredField"
                                                  components={{
                                                    Input: CustomSelectInput,
                                                  }}
                                                  classNamePrefix="react-select"
                                                  isSearchable
                                                  label={this.props.t("u")}
                                                  isClearable={true}
                                                  value={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? values[Index] &&
                                                        values[Index]
                                                          .District &&
                                                        (typeof values[Index]
                                                          .District === "string"
                                                          ? this.state.DistrictList.find(
                                                              (r) =>
                                                                r.value ===
                                                                values[Index]
                                                                  .District
                                                            )
                                                          : this.state.DistrictList.find(
                                                              (r) =>
                                                                r.value ===
                                                                values[Index]
                                                                  .District._id
                                                            ))
                                                      : values &&
                                                        values.District &&
                                                        (typeof values.District ===
                                                        "string"
                                                          ? this.state.DistrictList.find(
                                                              (r) =>
                                                                r.value ===
                                                                values.District
                                                            )
                                                          : this.state.DistrictList.find(
                                                              (r) =>
                                                                r.value ===
                                                                values.District
                                                                  ._id
                                                            ))
                                                  }
                                                  options={
                                                    this.state.DistrictList
                                                  }
                                                  ClearAction={() => {
                                                    // setFieldValue(
                                                    //   `AreaExtensionApplications[${Index}].District`,
                                                    //   null
                                                    // );
                                                    delete values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].District;
                                                  }}
                                                  Action={(entity) => {
                                                    setFieldValue(
                                                      `AreaExtensionApplications[${Index}].District`,
                                                      entity
                                                        ? entity.value
                                                        : null
                                                    );
                                                    this.GetAllTaluk(
                                                      entity ? entity.value : ""
                                                    );
                                                  }}
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <DropDown
                                                  isDisabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  //  labelClassName="requiredField"
                                                  components={{
                                                    Input: CustomSelectInput,
                                                  }}
                                                  className="react-select"
                                                  classNamePrefix="react-select"
                                                  name="Taluks"
                                                  label={this.props.t("Taluks")}
                                                  isMulti
                                                  isSearchable
                                                  options={
                                                    values &&
                                                    values.AreaExtensionApplications &&
                                                    values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ] &&
                                                    values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].District ===
                                                      this.state
                                                        .NotaryApplication
                                                        .District
                                                      ? this.state
                                                          .FilterTalukValues
                                                      : this.state.TalukList
                                                  }
                                                  value={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? values &&
                                                        values[Index] &&
                                                        values[Index].Taluks &&
                                                        this.state.TalukList.filter(
                                                          (a) => {
                                                            return values[
                                                              Index
                                                            ].Taluks.some(
                                                              (r) => {
                                                                return (
                                                                  a.value ===
                                                                  (typeof r ===
                                                                  "object"
                                                                    ? r._id
                                                                      ? r._id
                                                                      : r.value
                                                                    : r)
                                                                );
                                                              }
                                                            );
                                                          }
                                                        ).map(function (o) {
                                                          return o;
                                                        })
                                                      : values &&
                                                        values.Taluks &&
                                                        this.state.TalukList.filter(
                                                          (a) => {
                                                            return values.Taluks.some(
                                                              (r) => {
                                                                return (
                                                                  a.value ===
                                                                  (typeof r ===
                                                                  "object"
                                                                    ? r._id
                                                                      ? r._id
                                                                      : r.value
                                                                    : r)
                                                                );
                                                              }
                                                            );
                                                          }
                                                        ).map(function (o) {
                                                          return o;
                                                        })
                                                  }
                                                  ClearAction={() => {
                                                    // setFieldValue(
                                                    //   `AreaExtensionApplications[${Index}].Taluks`,
                                                    //   null
                                                    // );
                                                    delete values
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].Taluks;
                                                  }}
                                                  Action={(entity) => {
                                                    if (entity) {
                                                      setFieldValue(
                                                        `AreaExtensionApplications[${Index}].Taluks`,
                                                        entity ? entity : null
                                                      );
                                                    } else {
                                                      delete values
                                                        .AreaExtensionApplications[
                                                        Index
                                                      ].Taluks;
                                                    }
                                                  }}
                                                />
                                              </FormGroup>
                                              {/* <p>
                                                (
                                                <label>
                                                  Practicing Taluks:
                                                </label>{" "}
                                                {this.state.TaluksEntity &&
                                                  this.state.TaluksEntity.map(
                                                    (ET, i) => {
                                                      if (i < 2) {
                                                        return ET.label + ", ";
                                                      } else {
                                                        return ".";
                                                      }
                                                    }
                                                  )}
                                                )
                                              </p> */}
                                            </Colxx>

                                            {/* <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {" "}
                                                  {this.props.t(
                                                    "Submitted Date"
                                                  )}
                                                </Label>
                                                <DatePicker
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  selected={
                                                    this.state
                                                      .AreaExtensionApplications[
                                                      Index
                                                    ].SubmittedOnDate
                                                  }
                                                  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  onChange={(date) => {
                                                    var dateAr = this.state
                                                      .AreaExtensionApplications;
                                                    dateAr[
                                                      Index
                                                    ].SubmittedOnDate = date;

                                                    this.setState({
                                                      AreaExtensionApplications: dateAr,
                                                    });
                                                    setFieldValue(
                                                      `AreaExtensionApplications[${Index}].SubmittedOnDate`,
                                                      dateAr[Index]
                                                        .SubmittedOnDate
                                                    );
                                                  }}
                                                  placeholderText="dd/mm/yyyy"
                                                  dateFormat="dd/MM/yyyy"
                                                  autoComplete="nope"
                                                />
                                              </FormGroup>
                                            </Colxx> */}

                                            <Colxx xxs="6">
                                              <FormGroup className="form-group has-float-label">
                                                <Label
                                                //className="requiredField"
                                                >
                                                  {this.props.t(
                                                    "Appointment GO Number"
                                                  )}
                                                </Label>

                                                <Field
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(event) => {
                                                    if (event.target.value) {
                                                      setFieldValue(
                                                        `AreaExtensionApplications[${Index}].GONumber`,
                                                        event.target.value
                                                      );
                                                    } else {
                                                      delete values
                                                        .AreaExtensionApplications[
                                                        Index
                                                      ].GONumber;
                                                    }
                                                  }}
                                                  type="text"
                                                  className="form-control"
                                                  name={`[${Index}].GONumber`}
                                                  placeholder="Go Number"
                                                />
                                                {errors.GONumber && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GONumber}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx
                                              style={{ zIndex: 0 }}
                                              xxs="5"
                                            >
                                              <Label
                                              // className="requiredField"
                                              >
                                                {this.props.t(
                                                  "Appointment GO Copy"
                                                )}
                                              </Label>
                                              <FormGroup className="form-group has-float-label">
                                                <CustomInput
                                                  disabled={
                                                    this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true"
                                                      ? true
                                                      : false
                                                  }
                                                  key={"GO"}
                                                  type="file"
                                                  name={"GO"}
                                                  onChange={(event) => {
                                                    if (event.target.files[0]) {
                                                      setFieldValue(
                                                        `AreaExtensionApplications[${Index}].GO`,
                                                        event.target.files[0]
                                                      );
                                                    } else {
                                                      delete values
                                                        .AreaExtensionApplications[
                                                        Index
                                                      ].GO;
                                                    }
                                                  }}
                                                />

                                                {errors.GO ? (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.GO}
                                                  </div>
                                                ) : null}
                                                <p className="text-semi-muted">
                                                  {getFileSizeText(5, "MB")}

                                                  <p className="text-semi-muted">
                                                    {getAllowedExtensionText(
                                                      "pdf",
                                                      [
                                                        "png",
                                                        "doc",
                                                        "pdf",
                                                        "jpg",
                                                        "jpeg",
                                                      ]
                                                    )}
                                                    {this.state
                                                      .IsExistingNotaryApplications ===
                                                    "true" ? (
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state
                                                              .AreaExtensionApplications[
                                                              Index
                                                            ].GO
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>
                                                {/* <p className="text-semi-muted">
                                                  Allowed formats are png, doc,
                                                  pdf, jpg and jpeg
                                                  {this.state
                                                    .IsExistingNotaryApplications ===
                                                  "true" ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state
                                                            .AreaExtensionApplications[
                                                            Index
                                                          ].GO
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p> */}
                                              </FormGroup>
                                            </Colxx>

                                            <div
                                              style={{
                                                marginTop: "33px",
                                              }}
                                            >
                                              {this.state
                                                .AreaExtensionApplications
                                                .length > 1 && (
                                                <i
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  className="bx bx-minus-circle cursorPointer"
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .AreaExtensionApplications;

                                                    selectedProduct.splice(
                                                      Index,
                                                      1
                                                    );
                                                    // if(Object.keys(values.AreaExtensionApplications[values.AreaExtensionApplications.length - 1]).length)
                                                    // {
                                                    //   values.AreaExtensionApplications.splice(  values.AreaExtensionApplications.length - 1, 1)

                                                    // }

                                                    this.setState({
                                                      selectedProduct:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                              &nbsp;&nbsp;&nbsp;
                                              {this.state
                                                .AreaExtensionApplications
                                                .length ===
                                                Index + 1 && (
                                                <i
                                                  className="bx bx-plus-circle cursorPointer"
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    var selectedProduct =
                                                      this.state
                                                        .AreaExtensionApplications;
                                                    selectedProduct.push({
                                                      District: null,
                                                      Taluks: [],
                                                      //  SubmittedOn: null,
                                                      //     SubmittedOnDate: null,
                                                      GO: "",
                                                      GONumber: null,
                                                    });
                                                    this.setState({
                                                      selectedProduct:
                                                        selectedProduct,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </div>

                                            <ModalFooter></ModalFooter>
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""}
                              <Colxx xxs="12">
                                {" "}
                                <p>
                                  (
                                  <label>
                                    {this.props.t("Practicing Taluks")} :
                                  </label>{" "}
                                  {this.state.TaluksEntity &&
                                    this.state.TaluksEntity.map((ET) => {
                                      return ET.label
                                        ? ET.label
                                        : this.state.Language === "ta"
                                        ? ET.TamilName
                                        : ET.Name;
                                    }).join(", ")}
                                  )
                                </p>
                              </Colxx>
                            </Row>
                          </Fragment>
                          <FormGroup className="float-sm-right ">
                            {this.state.IsExistingNotaryApplications ===
                            "true" ? (
                              ""
                            ) : (
                              <Button
                                className={
                                  this.state.buttonAction ? "disabled" : ""
                                }
                                type="submit"
                                outline
                                color="primary"
                              >
                                {this.props.t("Submit")}
                              </Button>
                            )}

                            <Button
                              color="danger"
                              className="ml-2"
                              onClick={() => this.toggleManageModal()}
                            >
                              {this.props.t("Cancel")}
                            </Button>
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
              <ModalFooter>
                {/* <FormGroup className="float-sm-right ">
                  <Button
                    className={this.state.buttonAction ? "disabled" : ""}
                    type="submit"
                    outline
                    color="primary"
                  >
                    {this.props.t("Create")}
                  </Button>
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleManageModal()}
                  >
                    {this.props.t("Cancel")}
                  </Button>
                </FormGroup> */}
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.loading}
              style={{ marginTop: "20%" }}
            >
              <ModalBody>
                <Fragment>
                  <span>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                  &nbsp;
                  {/* Processing... Please wait... */}
                  Processing... Please wait... Please login once completed.
                </Fragment>
              </ModalBody>
            </Modal>
            {/* <Modal
              isOpen={this.state.MakingPaymentModal}
              toggle={() => this.confirmMakingPayment()}
            >
              <ModalHeader toggle={() => this.confirmMakingPayment()}>
                {this.props.t("Confirm Payment Applications")}
              </ModalHeader>

              <AvForm onSubmit={() => this.handleMakingPayment()}>
                <ModalBody>
                  <Fragment>
                    <Row className="mb-4">
                      <Label className="av-label ml-3">
                        <h5 className="pr-2 pt-2">
                          Are you sure you want to make payment of Rs.
                          {this.state.FeeTotalAmount} ?
                        </h5>
                      </Label>
                    </Row>
                  </Fragment>
                </ModalBody>
                <ModalFooter>
                  <FormGroup className="float-sm-right ">
                    <Button type="submit" outline color="primary">
                      {this.props.t("Confirm")}
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.confirmMakingPayment()}
                    >
                      {this.props.t("Cancel")}
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </AvForm>
            </Modal> */}
            <Modal
              isOpen={this.state.nocAttachmentModal}
              toggle={() => this.toggleNocAttachment()}
            >
              <ModalHeader toggle={() => this.toggleNocAttachment()}>
                {this.props.t("Attached NOC")}
              </ModalHeader>
              <ModalBody>
                <Formik
                  onSubmit={this.handleNOC}
                  validationSchema={nocValidation}
                  initialValues={{ initialNOCValues }}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Fragment>
                          <Row className="mb-4">
                            <Colxx xxs="12">
                              <Label> {this.props.t("NOC")}</Label>
                            </Colxx>
                            <Colxx xxs="12">
                              <CustomInput
                                key={"NOC"}
                                type="file"
                                name={"NOC"}
                                onChange={(event) => {
                                  setFieldValue("NOC", event.target.files[0]);
                                }}
                              />
                              <p className="text-semi-muted">
                                {getFileSizeText(5, "MB")}

                                <p className="text-semi-muted">
                                  {getAllowedExtensionText("pdf", [
                                    "png",
                                    "doc",
                                    "pdf",
                                    "jpg",
                                    "jpeg",
                                  ])}
                                </p>
                              </p>
                              {errors.NOC && (
                                <div className="invalid-feedback d-block">
                                  {errors.NOC}
                                </div>
                              )}
                            </Colxx>
                          </Row>
                        </Fragment>
                      </ModalBody>

                      <ModalFooter>
                        <FormGroup className="float-sm-right ">
                          <Button color="primary" className="ml-2">
                            {this.state.buttonLoading ? (
                              <span>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                &nbsp; {this.props.t("Submit")}
                              </span>
                            ) : (
                              this.props.t("Submit")
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className="ml-2"
                            onClick={() => this.toggleNocAttachment()}
                          >
                            {this.props.t("Cancel")}
                          </Button>
                        </FormGroup>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>

            <Modal
              size={"xl"}
              isOpen={this.state.RenewalFormModal}
              toggle={() => this.toggleRenewalForm()}
            >
              <ModalHeader toggle={() => this.toggleRenewalForm()}>
                Renewal Form
              </ModalHeader>
              <ModalBody>
                <Formik
                  onSubmit={this.handleRenewalForm}
                  //  validationSchema={RenewalFormValidation}
                  initialValues={this.state.ApplicationFields}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Fragment>
                          <Row className="mb-4">
                            <Card>
                              <CardBody>
                                <Row>
                                  {/* <Colxx xxs="12">
                                    <h4>Renewal Form</h4>
                                  </Colxx> */}
                                  <Colxx xxs="9"></Colxx>
                                  {/* <Colxx xxs="3">
                                    <Button
                                      className="form-second-btn"
                                      type="button"
                                      onClick={() => this.savePDF()}
                                      color="success"
                                      disabled={this.state.loading}
                                    >
                                      Download as PDF{" "}
                                    </Button>
                                  </Colxx> */}
                                  <Colxx xxs="12" id="PrintForm">
                                    <div>
                                      <Row>
                                        <Colxx xxs="12 text-center mt-1">
                                          <span>FORM XVI</span>
                                        </Colxx>
                                        <Colxx xxs="12 text-center  mt-1">
                                          <span>[See rule 8B] </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>To, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Secretary to Government, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Law Department, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Secretariat, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Chennai - 9. </span>
                                        </Colxx>
                                        <Colxx xxs="12 mt-2">
                                          <Row>
                                            <Colxx xxs="12 text-center">
                                              Subject- Request for renewal of
                                              Certificate of Practice w.e.f.
                                              {/* <Field
                                              name={"RenewFrom"}
                                              type="text"
                                            /> */}{" "}
                                              {"  "}
                                              <span
                                                className={"text-underline"}
                                              >
                                                {this.state.ApplicationFields &&
                                                  this.state.ApplicationFields
                                                    .RenewFrom}
                                              </span>
                                              {/* <DatePicker
                                                autoComplete="nope"
                                                selected={
                                                  this.state.ApplicationFields
                                                    .RenewFrom
                                                }
                                                //  className="form-control"
                                                showYearDropdown
                                                showMonthDropdown
                                                onChange={(date) => {
                                                  let dateAr = this.state
                                                    .ApplicationFields;
                                                  dateAr.RenewFrom = date;
                                                  this.setState({
                                                    ApplicationFields: dateAr,
                                                  });
                                                  // values.SubmittedOnDate = date;
                                                }}
                                                placeholderText={"DD/MM/YYYY"}
                                                dateFormat="dd/MM/yyyy"
                                              /> */}
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                        <Colxx xxs="12 mt-2">
                                          <Colxx xxs="2"></Colxx>

                                          <Row>
                                            <Colxx xxs="2"> </Colxx>
                                            <Colxx
                                              className="text-right"
                                              xxs="6"
                                            >
                                              (G.O.(Ms) No.-{" "}
                                              <Field
                                                name={"LastRenewalGONumber"}
                                                type="text"
                                              />
                                              , Law Department, dated)
                                            </Colxx>
                                            {/* <span className="text-underline">
                                                {this.state.ApplicationFields &&
                                                  this.state.ApplicationFields
                                                    .LastRenewedDate}
                                              </span> */}
                                            <Colxx
                                              className="text-right"
                                              xxs="3"
                                            >
                                              {" "}
                                              <DatePicker
                                                autoComplete="nope"
                                                selected={
                                                  this.state.ApplicationFields
                                                    .LastRenewedDate
                                                }
                                                maxDate={new Date().getTime()}
                                                //  className="form-control"
                                                showYearDropdown
                                                showMonthDropdown
                                                onChange={(date) => {
                                                  let dateAr =
                                                    this.state
                                                      .ApplicationFields;
                                                  dateAr.LastRenewedDate = date;
                                                  this.setState(
                                                    (prevState) => ({
                                                      ...prevState.ApplicationFields,
                                                      LastRenewedDate: date,
                                                    })
                                                  );
                                                  // values.SubmittedOnDate = date;
                                                }}
                                                placeholderText={"DD/MM/YYYY"}
                                                dateFormat="dd/MM/yyyy"
                                              />
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                        <Colxx xxs="12 text-center  mt-2">
                                          <span>
                                            {" "}
                                            {`{Note: Please mention the G.O. number by which your Certificate of Practice was lastly renewed.}`}{" "}
                                          </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Sir, </span>
                                        </Colxx>
                                        <Colxx xxs="12 mt-3">
                                          <Row>
                                            <Colxx>
                                              The applicant was appointed as
                                              Notary vide G.O.(Ms) No. &nbsp;
                                              <span>
                                                <Field
                                                  name={
                                                    "NotaryAppointmentGONumber"
                                                  }
                                                  type="text"
                                                />
                                              </span>{" "}
                                              &nbsp; ,Law Department, dated
                                              &nbsp;{" "}
                                              <span>
                                                <DatePicker
                                                  autoComplete="nope"
                                                  selected={
                                                    this.state.ApplicationFields
                                                      .NotaryAppointmentGODate
                                                  }
                                                  maxDate={new Date().getTime()}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  onChange={(date) => {
                                                    let dateAr =
                                                      this.state
                                                        .ApplicationFields;
                                                    dateAr.NotaryAppointmentGODate =
                                                      date;
                                                    // dateAr.NotaryAppointmentDate =
                                                    //   date;

                                                    this.setState(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        ApplicationFields:
                                                          dateAr,
                                                      })
                                                    );

                                                    setFieldValue(
                                                      `NotaryAppointmentGODate`,
                                                      date
                                                    );
                                                    // setFieldValue(
                                                    //   `NotaryAppointmentDate`,
                                                    //   date
                                                    // );
                                                    // values = dateAr;
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                />
                                              </span>
                                            </Colxx>
                                          </Row>
                                          <Row
                                            className="mt-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <Colxx xxs="text-center ml-2">
                                              w.e.f. &nbsp;
                                              {/* <span className="text-underline">
                                                {this.state.ApplicationFields &&
                                                  this.state.ApplicationFields
                                                    .NotaryAppointmentDate}
                                              </span> */}
                                            </Colxx>
                                            <Colxx xxs="text-center">
                                              <DatePicker
                                                // disabled
                                                autoComplete="nope"
                                                selected={
                                                  this.state.ApplicationFields
                                                    .NotaryAppointmentDate
                                                }
                                                //  className="form-control"
                                                showYearDropdown
                                                showMonthDropdown
                                                onChange={(date) => {
                                                  let dateAr =
                                                    this.state
                                                      .ApplicationFields;
                                                  dateAr.NotaryAppointmentDate =
                                                    date;
                                                  this.setState(
                                                    (prevState) => ({
                                                      ...prevState.ApplicationFields,
                                                      NotaryAppointmentDate:
                                                        date,
                                                    })
                                                  );
                                                  setFieldValue(
                                                    `NotaryAppointmentDate`,
                                                    date
                                                  );
                                                  values = dateAr;
                                                  // values.SubmittedOnDate = date;
                                                }}
                                                placeholderText={"DD/MM/YYYY"}
                                                dateFormat="dd/MM/yyyy"
                                              />
                                            </Colxx>
                                            <Colxx xxs="text-center">
                                              {" "}
                                              &nbsp; to practice as such, in and
                                              throughout{" "}
                                            </Colxx>{" "}
                                            &nbsp; &nbsp;
                                            <Colxx xxs="text-center">
                                              <FormGroup className="form-group has-float-label">
                                                <div
                                                  style={
                                                    {
                                                      // width: "200px"
                                                    }
                                                  }
                                                >
                                                  {/* <DropDown
                                                    // style={{
                                                    //   fontSize: "15px",
                                                    //   outerWidth: "200px",
                                                    // }}
                                                    // label={this.props.t("Taluk")}
                                                    components={{
                                                      Input: CustomSelectInput,
                                                    }}
                                                    //    className="react-select"
                                                    classNamePrefix="react-select"
                                                    // isDisabled
                                                    isSearchable
                                                    isClearable={true}
                                                    value={
                                                      values?.ResidencyAddress &&
                                                      values?.ResidencyAddress
                                                        .Address &&
                                                      values?.ResidencyAddress
                                                        .Address.District &&
                                                      (typeof values
                                                        ?.ResidencyAddress
                                                        ?.Address.District ===
                                                      "string"
                                                        ? this.state?.DistrictList.find(
                                                            (r) =>
                                                              r.value ===
                                                              values
                                                                ?.ResidencyAddress
                                                                ?.Address
                                                                ?.District
                                                          )
                                                        : this.state?.DistrictList.find(
                                                            (r) =>
                                                              r.value ===
                                                              values
                                                                ?.ResidencyAddress
                                                                ?.Address
                                                                ?.District._id
                                                          ))
                                                    }
                                                    options={
                                                      this.state?.DistrictList
                                                    }
                                                    ClearAction={() => {
                                                      setFieldValue(
                                                        "AreasDistrict",
                                                        null
                                                      );
                                                    }}
                                                    Action={(entity) => {
                                                      setFieldValue(
                                                        "AreasDistrict",
                                                        entity
                                                          ? entity?.label
                                                          : null
                                                      );
                                                    }}
                                                    errors={
                                                      errors?.ResidencyDistrict
                                                    }
                                                  /> */}
                                                  <Field
                                                    name={"AreasDistrict"}
                                                    type="text"
                                                    placeholder="District"
                                                  />
                                                  {errors.AreasDistrict && (
                                                    <div className="invalid-feedback d-block">
                                                      {errors.AreasDistrict}
                                                    </div>
                                                  )}
                                                </div>
                                              </FormGroup>
                                            </Colxx>{" "}
                                            <Colxx>
                                              <FormGroup className="form-group has-float-label">
                                                <div
                                                  style={{
                                                    width: "200px",
                                                  }}
                                                >
                                                  {/* <DropDown
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                    // label={this.props.t("Taluk")}
                                                    components={{
                                                      Input: CustomSelectInput,
                                                    }}
                                                    //    className="react-select"
                                                    classNamePrefix="react-select"
                                                    // isDisabled
                                                    isSearchable
                                                    isClearable={true}
                                                    value={
                                                      values?.ResidencyAddress &&
                                                      values?.ResidencyAddress
                                                        .Address &&
                                                      values?.ResidencyAddress
                                                        .Address.Taluk &&
                                                      (typeof values
                                                        ?.ResidencyAddress
                                                        ?.Address.Taluk ===
                                                      "string"
                                                        ? this.state?.TalukList.find(
                                                            (r) =>
                                                              r.value ===
                                                              values
                                                                ?.ResidencyAddress
                                                                ?.Address?.Taluk
                                                          )
                                                        : this.state?.TalukList.find(
                                                            (r) =>
                                                              r.value ===
                                                              values
                                                                ?.ResidencyAddress
                                                                ?.Address?.Taluk
                                                                ._id
                                                          ))
                                                    }
                                                    options={
                                                      this.state?.TalukList
                                                    }
                                                    ClearAction={() => {
                                                      setFieldValue(
                                                        "AreasTaluk"
                                                      );
                                                    }}
                                                    Action={(entity) => {
                                                      setFieldValue(
                                                        "AreasTaluk",
                                                        entity
                                                          ? entity?.label
                                                          : null
                                                      );
                                                    }}
                                                    errors={
                                                      errors?.ResidencyDistrict
                                                    }
                                                  /> */}
                                                  <Field
                                                    name={"AreasTaluk"}
                                                    type="text"
                                                    placeholder="Taluk"
                                                  />
                                                  {errors.AreasTaluk && (
                                                    <div className="invalid-feedback d-block">
                                                      {errors.AreasTaluk}
                                                    </div>
                                                  )}
                                                </div>
                                              </FormGroup>
                                            </Colxx>
                                          </Row>
                                          <Row className="mt-2 ml-1" xxs="8">
                                            You are requested to renew the same
                                            w.e.f. &nbsp;
                                            <Colxx xxs="text-center">
                                              {/* <span className="text-underline">
                                                {this.state.ApplicationFields &&
                                                  this.state.ApplicationFields
                                                    .ValidFrom}
                                              </span> */}

                                              <DatePicker
                                                autoComplete="nope"
                                                selected={
                                                  this.state.ApplicationFields
                                                    .ValidFrom
                                                }
                                                showYearDropdown
                                                showMonthDropdown
                                                onChange={(date) => {
                                                  let dateAr =
                                                    this.state
                                                      .ApplicationFields;
                                                  dateAr.ValidFrom = date;

                                                  var validto = moment(
                                                    date
                                                  ).add(5, "year");
                                                  var yesterday = moment(
                                                    validto
                                                  ).add(-1, "days");

                                                  dateAr.ValidTo =
                                                    yesterday?._d;
                                                  this.setState(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      ApplicationFields: dateAr,
                                                    })
                                                  );

                                                  setFieldValue(
                                                    "ValidFrom",
                                                    date
                                                  );
                                                  setFieldValue(
                                                    "ValidTo",
                                                    yesterday
                                                  );
                                                }}
                                                maxDate={moment()
                                                  .add(6, "months")
                                                  .toDate()} // Set maxDate to 6 months from today
                                                minDate={new Date()} // Set minDate to today or any other minimum date you want
                                                placeholderText="DD/MM/YYYY"
                                                dateFormat="dd/MM/yyyy"
                                              />
                                            </Colxx>
                                            <Colxx xxs="text-center">
                                              {" "}
                                              &nbsp; to{" "}
                                              <span>
                                                {" "}
                                                <DatePicker
                                                  disabled
                                                  autoComplete="nope"
                                                  selected={
                                                    this.state.ApplicationFields
                                                      .ValidTo
                                                  }
                                                  //  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  onChange={(date) => {
                                                    let dateAr =
                                                      this.state
                                                        .ApplicationFields;
                                                    dateAr.ValidTo = date;
                                                    this.setState(
                                                      (prevState) => ({
                                                        ...prevState.ApplicationFields,
                                                        ValidTo: date,
                                                      })
                                                    );
                                                    // values.SubmittedOnDate = date;
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                />{" "}
                                              </span>{" "}
                                              &nbsp;{" "}
                                              <span> at the earliest.</span>
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                        <br />
                                        <br />
                                        <Colxx
                                          xxs="12"
                                          style={{
                                            textAlign: "right",
                                            marginRight: "5px",
                                          }}
                                        >
                                          Name of the Applicant <br />
                                          <span className="text-underline">
                                            {this.state.ApplicationFields &&
                                              this.state.ApplicationFields.Name}
                                          </span>{" "}
                                          {/* <Field name={"Name"} type="text" />
                                          {errors.Name && (
                                            <div className="invalid-feedback d-block">
                                              {errors.Name}
                                            </div>
                                          )} */}
                                        </Colxx>
                                      </Row>

                                      {this?.state?.ApplicationFields
                                        ?.showReason ||
                                      !this.state.Eligibility.Renewal ? (
                                        <Row>
                                          <Colxx xxs="12 text-center  mt-3">
                                            <Row>
                                              <Colxx xxs="12 text-left  mt-5">
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  {`Reason :`}{" "}
                                                  <Field
                                                    name={"Reason"}
                                                    component="textarea"
                                                  />
                                                  {errors.Reason && (
                                                    <div className="invalid-feedback d-block">
                                                      {errors.Reason}
                                                    </div>
                                                  )}
                                                </span>
                                              </Colxx>
                                            </Row>
                                            <Row>
                                              <Colxx xxs="12 text-left  mt-3">
                                                <FormGroup className="form-group has-float-label">
                                                  <Label className="requiredField">
                                                    {this.props.t(
                                                      "Reason Document"
                                                    )}
                                                  </Label>
                                                  <CustomInput
                                                    key={"ReasonDocument"}
                                                    type="file"
                                                    name={"ReasonDocument"}
                                                    onChange={(event) => {
                                                      setFieldValue(
                                                        `ReasonDocument`,
                                                        event.target.files[0]
                                                      );

                                                      // values.Url =
                                                      //   event.target.files[0];
                                                    }}
                                                  />
                                                  <p className="text-semi-muted">
                                                    {getFileSizeText(5, "MB")}

                                                    <p className="text-semi-muted">
                                                      {getAllowedExtensionText(
                                                        "pdf",
                                                        [
                                                          "png",
                                                          "doc",
                                                          "pdf",
                                                          "jpg",
                                                          "jpeg",
                                                        ]
                                                      )}
                                                      <p
                                                        style={{
                                                          color: "blue",
                                                          cursor: "Pointer",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <FileDisplay
                                                          Value={
                                                            this.state
                                                              ?.ReasonDocument
                                                          }
                                                        />
                                                      </p>
                                                    </p>
                                                  </p>

                                                  {errors.ReasonDocument && (
                                                    <div className="invalid-feedback d-block">
                                                      {errors.ReasonDocument}
                                                    </div>
                                                  )}
                                                </FormGroup>
                                              </Colxx>
                                            </Row>
                                          </Colxx>
                                        </Row>
                                      ) : (
                                        ""
                                      )}
                                      <Row>
                                        <Colxx xxs="6 text-center  mt-3">
                                          <Row>
                                            <Colxx xxs="6 text-left">
                                              <span>
                                                {"Date: "}{" "}
                                                <span className="text-underline">
                                                  {this.state
                                                    .ApplicationFields &&
                                                    this.state.ApplicationFields
                                                      .Date}
                                                </span>
                                                {/* <DatePicker
                                                  autoComplete="nope"
                                                  selected={
                                                    this.state.ApplicationFields
                                                      .Date
                                                  }
                                                  //  className="form-control"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  onChange={(date) => {
                                                    let dateAr = this.state
                                                      .ApplicationFields;
                                                    dateAr.Date = date;
                                                    this.setState(
                                                      (prevState) => ({
                                                        ...prevState.ApplicationFields,
                                                        Date: date,
                                                      })
                                                    );
                                                    // values.SubmittedOnDate = date;
                                                  }}
                                                  placeholderText={"DD/MM/YYYY"}
                                                  dateFormat="dd/MM/yyyy"
                                                /> */}
                                              </span>
                                            </Colxx>
                                          </Row>
                                          <Row>
                                            <Colxx xxs="12 text-left  mt-3">
                                              <span>
                                                {`Place:`}{" "}
                                                <Field
                                                  name={"Place"}
                                                  type="text"
                                                />
                                                {errors.Place && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Place}
                                                  </div>
                                                )}
                                              </span>
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                      </Row>
                                    </div>
                                  </Colxx>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        </Fragment>
                      </ModalBody>

                      <ModalFooter>
                        <FormGroup className="float-sm-right ">
                          <Button color="primary" className="ml-2">
                            {this.state.buttonLoading ? (
                              <span>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                &nbsp;Submit
                              </span>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className="ml-2"
                            onClick={() => this.toggleRenewalForm()}
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>

            <Modal
              size={"xl"}
              isOpen={this.state.DuplicateFormModal}
              toggle={() => this.toggleDuplicateForm()}
            >
              <ModalHeader toggle={() => this.toggleDuplicateForm()}>
                Duplicate Form
              </ModalHeader>
              <ModalBody>
                <Formik
                  onSubmit={this.handleDuplicateForm}
                  //  validationSchema={RenewalFormValidation}
                  initialValues={this.state.DuplicateFormFields}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Fragment>
                          <Row className="mb-4">
                            <Card>
                              <CardBody>
                                <Row>
                                  <Colxx xxs="9"></Colxx>

                                  <Colxx xxs="12" id="PrintForm">
                                    <div>
                                      <Row>
                                        <Colxx xxs="12 text-center mt-1">
                                          <strong>
                                            REQUEST FOR DUPLICATE CERTIFICATE OF
                                            PRACTICE
                                          </strong>
                                        </Colxx>

                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>From</span>
                                        </Colxx>
                                        <FormGroup className="form-group has-float-label">
                                          <Field
                                            style={{
                                              fontSize: "15px",
                                            }}
                                            component="textarea"
                                            className="form-control"
                                            name="From"
                                            onKeyPress={(event) => {
                                              if (
                                                INVALID_CHARS.includes(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onBlur={(event) => {
                                              event.target.value =
                                                event.target.value &&
                                                event.target.value.replace(
                                                  INVALID_CHARS_REGEX,
                                                  ""
                                                );
                                              setFieldValue(
                                                "From",
                                                event.target.value
                                              );
                                            }}
                                          />
                                        </FormGroup>

                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>To</span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>
                                            The Secretary to Government,
                                          </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Law Department, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Secretariat, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Chennai – 600 009. </span>
                                        </Colxx>

                                        <Row>
                                          <Colxx xxs="5"></Colxx>
                                          <Colxx>
                                            <table className="table table-bordered table-centered table mb-2">
                                              <tr>
                                                <td>Sub:</td>
                                                <td>
                                                  Request for issue of Duplicate
                                                  Certificate of Practice - Reg.
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Ref:-</td>
                                                <td>
                                                  G.O.(Ms).No
                                                  <Field
                                                    className="mt-1 mb-1"
                                                    type="text"
                                                    name="NotaryAppointmentGONumber"
                                                  />
                                                  , Law Department, dated{" "}
                                                  {/* <span className="text-underline">
                                                    {this.state
                                                      .DuplicateFormFields &&
                                                      this.state
                                                        .DuplicateFormFields
                                                        .NotaryAppointmentDate}
                                                  </span>{" "} */}
                                                  <Field
                                                    className="mt-2"
                                                    name={
                                                      "NotaryAppointmentGODate"
                                                    }
                                                    type="text"
                                                  />
                                                </td>
                                              </tr>
                                            </table>
                                          </Colxx>
                                        </Row>

                                        <Colxx xxs="12 mt-3">
                                          <Row>
                                            <Colxx className="">
                                              &emsp; I have been appointed as
                                              Notary{" "}
                                              <span className="text-underline">
                                                vide{" "}
                                              </span>{" "}
                                              the Government Order cited w.e.f{" "}
                                              {/* <span className="text-underline">
                                                {this.state
                                                  .DuplicateFormFields &&
                                                  this.state.DuplicateFormFields
                                                    .NotaryAppointmentDate}
                                              </span>{" "} */}
                                              <Field
                                                className="mt-2"
                                                name={"NotaryAppointmentDate"}
                                                type="text"
                                              />
                                              (here mention the date of
                                              appointment) to practice as such
                                              in and throughout{" "}
                                              <span className="text-underline">
                                                {this.state.ApplicationFields &&
                                                  this.state.ApplicationFields
                                                    .Areas}
                                              </span>{" "}
                                              <Field
                                                className="mt-2"
                                                name={"district"}
                                                type="text"
                                                placeholder="District"
                                              />{" "}
                                              <Field
                                                className="mt-2"
                                                name={"area"}
                                                type="text"
                                                placeholder="Area"
                                              />{" "}
                                              (here mention the area of
                                              practice). I have paid the
                                              prescribed fee of Rs. 750/-
                                              through online. I hereby request
                                              to issue a Duplicate Certificate
                                              of Practice for the following
                                              reason.{" "}
                                              <Field
                                                className="mt-2"
                                                name={"Reason"}
                                                type="text"
                                                onKeyPress={(event) => {
                                                  if (
                                                    INVALID_CHARS.includes(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onBlur={(event) => {
                                                  event.target.value =
                                                    event.target.value &&
                                                    event.target.value.replace(
                                                      INVALID_CHARS_REGEX,
                                                      ""
                                                    );
                                                  setFieldValue(
                                                    "Reason",
                                                    event.target.value
                                                  );
                                                }}
                                              />{" "}
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                        <br />
                                        <br />
                                        <Colxx className="text-center">
                                          Thanking you.
                                        </Colxx>
                                        <br />
                                      </Row>

                                      <Row
                                        style={{
                                          float: "right",
                                          //textAlign: "right",
                                          marginRight: "5px",
                                        }}
                                      >
                                        {/* <CustomInput
                                          disabled={
                                            this.state
                                              .IsExistingNotaryApplications ===
                                            "true"
                                              ? true
                                              : false
                                          }
                                          key={"GO"}
                                          type="file"
                                          name={"GO"}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "SignatureCopy",
                                              event.target.files[0]
                                            );
                                          }}
                                        /> */}
                                        {/* Signature of Notary <br />
                                        [Upload a copy of Signature] */}
                                      </Row>
                                      <Row>
                                        <Colxx xxs="6 text-center  mt-3">
                                          <Row>
                                            <Colxx xxs="12 text-left  mt-3">
                                              <span>
                                                {`Place:`}{" "}
                                                <Field
                                                  name={"Place"}
                                                  type="text"
                                                />
                                                {errors.Place && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Place}
                                                  </div>
                                                )}
                                              </span>
                                            </Colxx>
                                          </Row>
                                          <Row>
                                            <Colxx xxs="6 text-left">
                                              <span>
                                                {"Date: "}{" "}
                                                <span className="text-underline">
                                                  {moment().format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </span>
                                              </span>
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                      </Row>
                                    </div>
                                  </Colxx>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        </Fragment>
                      </ModalBody>

                      <ModalFooter>
                        <FormGroup className="float-sm-right ">
                          <Button color="primary" className="ml-2">
                            {this.state.buttonLoading ? (
                              <span>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                &nbsp;Submit
                              </span>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className="ml-2"
                            onClick={() => this.toggleDuplicateForm()}
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            <Modal
              size={"xl"}
              isOpen={this.state.AreaExtensionFormModal}
              toggle={() => this.toggleAreaExtensionForm()}
            >
              <ModalHeader toggle={() => this.toggleAreaExtensionForm()}>
                Area Extension Form
              </ModalHeader>
              <ModalBody>
                <Formik
                  onSubmit={this.handleAreaExtensionSubmit}
                  // validationSchema={ApplicationFormValidation}
                  initialValues={this.state?.AreaExtensionValues}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue, setValues }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <ModalBody>
                        <Fragment>
                          <Row className="mb-4">
                            <Card>
                              <CardBody>
                                <Row className="mt-4">
                                  <Colxx xxs="8">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                      <h4 className="mb-0 font-size-18">
                                        {this.props.t(
                                          this.state.CurrentApplication
                                        )}

                                        {/* {this.props.title} */}
                                      </h4>
                                    </div>
                                  </Colxx>
                                  <Colxx xxs="2">
                                    {this.state.AreaStatus ===
                                    "Payment Pending" ? (
                                      sessionStorage.getItem("IsArchived") ===
                                      "false" ? (
                                        <Button
                                          size="md"
                                          type="button"
                                          class="btn btn-secondary"
                                          color="primary"
                                          style={{ float: "right" }}
                                          onClick={() =>
                                            this.AreaExtensionPaymentInitiate()
                                          }
                                        >
                                          {this.props.t("Make Payment")}
                                        </Button>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </Colxx>

                                  <Colxx xxs="2">
                                    {checkIfPdf(
                                      this.state.ApplicationValues?.Applicant
                                        ?.Photo
                                    ) ? (
                                      <FileDisplay
                                        value={
                                          this.state.ApplicationValues
                                            ?.Applicant?.Photo
                                        }
                                      />
                                    ) : (
                                      <img
                                        alt="noimg"
                                        id="userimg"
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "contain",
                                        }}
                                        src={
                                          this.state.ApplicationValues
                                            ? this.state.ApplicationValues
                                                ?.Applicant?.Photo
                                            : ""
                                        }
                                        onClick={() =>
                                          this.togglePhotoViewModal(
                                            this.state.ApplicationValues
                                              ?.Applicant?.Photo,
                                            "Photo"
                                          )
                                        }
                                      />
                                    )}
                                  </Colxx>
                                </Row>

                                <Row>
                                  <Colxx xxs="12" id="PrintForm">
                                    <div>
                                      <Row>
                                        <Colxx xxs="12 text-center mt-1">
                                          <strong>AREA EXTENSION FORM</strong>
                                        </Colxx>

                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>From</span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.Title?.Name
                                          }
                                          &nbsp;&nbsp;
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.Name
                                          }
                                          ,
                                          <br />
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.FatherOrHusbandName
                                          }
                                          ,
                                          <br />
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.FullResidencyAddress
                                          }
                                          ,
                                          <br />
                                          {
                                            this.state?.ApplicationValues
                                              ?.District?.Name
                                          }
                                          ,
                                          <br />
                                          {this.state?.ApplicationValues?.Taluks.map(
                                            (taluk) => {
                                              return this.state.Language ===
                                                "ta"
                                                ? taluk.TamilName
                                                : taluk.Name;
                                            }
                                          )}
                                          ,
                                          <br />
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.ResidencyAddress
                                              ?.Address?.Pincode
                                          }
                                          ,
                                          <br />
                                          {
                                            this.state?.ApplicationValues
                                              ?.Applicant?.MobileNumber
                                          }
                                          .
                                        </Colxx>

                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>To</span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>
                                            The Secretary to Government,
                                          </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Law Department, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Secretariat, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span>Fort St. George, </span>
                                        </Colxx>
                                        <Colxx xxs="12 text-left  mt-2">
                                          <span> Chennai —600 009</span>
                                        </Colxx>

                                        <Row>
                                          <Colxx xxs="5"></Colxx>
                                          <Colxx>
                                            <div
                                              style={{
                                                width: "500px",
                                                marginLeft: "280px",
                                              }}
                                            >
                                              <table className="table table-bordered table-centered table mb-2">
                                                <tr>
                                                  <td>Sub:</td>
                                                  <td>
                                                    Request for extension of
                                                    Area of Practice From{" "}
                                                    {
                                                      <Colxx xxs="6">
                                                        {this.state
                                                          .CurrentApplication ===
                                                        "Notary Application" ? (
                                                          ""
                                                        ) : (
                                                          <ReactTooltip />
                                                        )}
                                                        <FormGroup
                                                          data-tip={this.props.t(
                                                            "Same district as residential district"
                                                          )}
                                                          className="form-group has-float-label"
                                                        >
                                                          <DropDown
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                            labelClassName="requiredField"
                                                            isDisabled={
                                                              this.state
                                                                .CurrentApplication ===
                                                              "Notary Application"
                                                                ? this.state
                                                                    .AreaStatus ===
                                                                    "" ||
                                                                  this.state
                                                                    .AreaStatus ===
                                                                    "Approved"
                                                                  ? false
                                                                  : true
                                                                : true
                                                            }
                                                            components={{
                                                              Input:
                                                                CustomSelectInput,
                                                            }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="District"
                                                            // label={this.props.t("District")}
                                                            isSearchable
                                                            options={
                                                              this.state
                                                                .DistrictList
                                                            }
                                                            value={
                                                              this.state
                                                                ?.CurrentApplication !==
                                                              "Notary Application"
                                                                ? values.ResidencyAddress &&
                                                                  values
                                                                    .ResidencyAddress
                                                                    .Address &&
                                                                  values
                                                                    .ResidencyAddress
                                                                    .Address
                                                                    .District &&
                                                                  (typeof values
                                                                    .ResidencyAddress
                                                                    .Address
                                                                    .District ===
                                                                  "string"
                                                                    ? this.state.DistrictList.find(
                                                                        (r) =>
                                                                          r.value ===
                                                                          values
                                                                            .ResidencyAddress
                                                                            .Address
                                                                            .District
                                                                      )
                                                                    : this.state.DistrictList.find(
                                                                        (r) =>
                                                                          r.value ===
                                                                          values
                                                                            .ResidencyAddress
                                                                            .Address
                                                                            .District
                                                                            ._id
                                                                      ))
                                                                : values &&
                                                                  values.District &&
                                                                  (typeof values.District ===
                                                                  "string"
                                                                    ? this.state.DistrictList.find(
                                                                        (r) =>
                                                                          r.value ===
                                                                          values.District
                                                                      )
                                                                    : this.state.DistrictList.find(
                                                                        (r) =>
                                                                          r.value ===
                                                                          values
                                                                            .District
                                                                            ._id
                                                                      ))
                                                            }
                                                            ClearAction={() => {
                                                              setFieldValue(
                                                                "District",
                                                                null
                                                              );
                                                              this.GetAllTaluk(
                                                                null
                                                              );
                                                            }}
                                                            Action={(
                                                              entity
                                                            ) => {
                                                              setFieldValue(
                                                                "District",
                                                                entity
                                                              );
                                                              setValues(
                                                                "District",
                                                                entity?.value
                                                              );

                                                              this.setState({
                                                                area_form_values:
                                                                  {
                                                                    ...this
                                                                      .state
                                                                      .area_form_values,
                                                                    district:
                                                                      entity?.value,
                                                                    taluk: null,
                                                                  },
                                                              });
                                                              // setFieldValue(
                                                              //   "District",
                                                              //   entity
                                                              //     ? entity.value
                                                              //     : ""
                                                              // );

                                                              this.GetAllTaluk(
                                                                entity
                                                                  ? entity.value
                                                                  : null
                                                              );
                                                            }}
                                                          />
                                                        </FormGroup>
                                                      </Colxx>
                                                    }{" "}
                                                    <Colxx xxs="6">
                                                      <FormGroup className="form-group has-float-label">
                                                        <DropDown
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                          isDisabled={
                                                            this.state
                                                              .CurrentApplication ===
                                                            "Notary Application"
                                                              ? this.state
                                                                  .AreaStatus ===
                                                                  "" ||
                                                                this.state
                                                                  .AreaStatus ===
                                                                  "Approved" ||
                                                                this.state
                                                                  .AreaStatus ===
                                                                  "Rejected"
                                                                ? false
                                                                : true
                                                              : this.state
                                                                  .IsApplication ===
                                                                "true"
                                                              ? this.state
                                                                  .Status ===
                                                                "Approved"
                                                                ? false
                                                                : true
                                                              : false
                                                          }
                                                          components={{
                                                            Input:
                                                              CustomSelectInput,
                                                          }}
                                                          className="react-select"
                                                          classNamePrefix="react-select"
                                                          name="Taluk"
                                                          // label={this.props.t("Taluks")}
                                                          isMulti
                                                          isSearchable
                                                          options={
                                                            this.state.TalukList
                                                          }
                                                          value={
                                                            values &&
                                                            values.Taluks &&
                                                            this.state.TalukList.filter(
                                                              (a) => {
                                                                return values.Taluks.some(
                                                                  (r) => {
                                                                    return (
                                                                      a.value ===
                                                                      (typeof r ===
                                                                      "object"
                                                                        ? r._id
                                                                          ? r._id
                                                                          : r.value
                                                                        : r)
                                                                    );
                                                                  }
                                                                );
                                                              }
                                                            ).map(function (o) {
                                                              return o;
                                                            })
                                                          }
                                                          ClearAction={() => {
                                                            // setFieldValue(
                                                            //   "Taluks",
                                                            //   null
                                                            // );
                                                            this.setState({
                                                              area_form_values:
                                                                {
                                                                  ...this.state
                                                                    .area_form_values,
                                                                  taluk: null,
                                                                },
                                                            });
                                                            setValues(
                                                              "District",
                                                              null
                                                            );
                                                          }}
                                                          Action={(entity) => {
                                                            // setValues(
                                                            //   "District",
                                                            //   entity?.value
                                                            // );
                                                            console.log(
                                                              "entity",
                                                              entity
                                                            );
                                                            this.setState({
                                                              area_form_values:
                                                                {
                                                                  ...this.state
                                                                    .area_form_values,
                                                                  taluk:
                                                                    entity?.map(
                                                                      (list) =>
                                                                        list?.value
                                                                    ),
                                                                },
                                                            });
                                                            // setFieldValue(
                                                            //   "Taluks",
                                                            //   entity
                                                            //     ? entity
                                                            //     : ""
                                                            // );
                                                          }}
                                                        />
                                                        {errors.Taluk && (
                                                          <div className="invalid-feedback d-block">
                                                            {errors.Taluk}
                                                          </div>
                                                        )}
                                                      </FormGroup>
                                                    </Colxx>
                                                    {/* Reg. */}
                                                  </td>
                                                </tr>
                                                {/* <tr>
                                                  <td>Ref:-</td>
                                                  <td>
                                                  G.O.(Ms).No
                                                  <Field
                                                    disabled
                                                    className="mt-1 mb-1"
                                                    type="text"
                                                    name="NotaryAppointmentGONumber"
                                                  />
                                                  , Law Department, dated{" "}
                                                  <span className="text-underline">
                                                    {this.state
                                                      ?.DuplicateFormFields &&
                                                      this.state
                                                        ?.DuplicateFormFields
                                                        ?.NotaryAppointmentDate}
                                                  </span>{" "}
                                                  [Appointment G.O.]
                                                </td>
                                                </tr> */}
                                              </table>
                                            </div>
                                          </Colxx>
                                        </Row>

                                        <Colxx xxs="12 mt-3">
                                          <Row>
                                            <Colxx xxs="12">
                                              <FormGroup className="form-group has-float-label">
                                                <Label className="requiredField">
                                                  {this.props.t("Reason")}
                                                </Label>
                                                <Field
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                  component="textarea"
                                                  className="form-control"
                                                  name="Reason"
                                                  type="text"
                                                  disabled={
                                                    this.state.AreaStatus ===
                                                      "" ||
                                                    this.state.AreaStatus ===
                                                      "Approved"
                                                      ? false
                                                      : true
                                                  }
                                                  value={values?.Reason}
                                                  // onKeyPress={(event) => {
                                                  //   if (
                                                  //     INVALID_CHARS.includes(
                                                  //       event.key
                                                  //     )
                                                  //   ) {
                                                  //     event.preventDefault();
                                                  //   }
                                                  // }}
                                                  onBlur={(event) => {
                                                    // event.target.value =
                                                    //   event.target.value &&
                                                    //   event.target.value.replace(
                                                    //     INVALID_CHARS_REGEX,
                                                    //     ""
                                                    //   );
                                                    // setFieldValue(
                                                    //   "Reason",
                                                    //   event.target.value
                                                    // );GG
                                                  }}
                                                  onChange={(event) => {
                                                    this.setState({
                                                      area_form_values: {
                                                        ...this.state
                                                          .area_form_values,
                                                        reason:
                                                          event.target.value,
                                                      },
                                                    });
                                                    // console.log(
                                                    //   "event",
                                                    //   event.target.value
                                                    // );
                                                  }}
                                                />
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx>
                                              So , I request you to extent the
                                              notary in and throughout{" "}
                                              {/* <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {" "}
                                                {
                                                  this.state?.ApplicationValues
                                                    ?.District?.Name
                                                }
                                              </span>{" "} */}
                                              District, to Taluk
                                              {/* <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {" "}
                                                {this.state?.ApplicationValues?.Taluks.map(
                                                  (taluk) => {
                                                    return this.state
                                                      .Language === "ta"
                                                      ? taluk.TamilName
                                                      : taluk.Name;
                                                  }
                                                )}
                                              </span>{" "} */}{" "}
                                              I Will Be Very Grateful To You
                                            </Colxx>
                                          </Row>
                                        </Colxx>
                                        <br />
                                        <br />
                                        <Colxx className="text-center">
                                          <br />
                                          Thanking you.
                                        </Colxx>
                                        <br />
                                      </Row>

                                      <Row
                                        style={{
                                          float: "right",
                                          //textAlign: "right",
                                          marginRight: "5px",
                                        }}
                                      >
                                        {/* <CustomInput
                                          disabled={
                                            this.state
                                              .IsExistingNotaryApplications ===
                                            "true"
                                              ? true
                                              : false
                                          }
                                          key={"GO"}
                                          type="file"
                                          name={"GO"}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "SignatureCopy",
                                              event.target.files[0]
                                            );
                                          }}
                                        /> */}
                                        {/* Signature of Notary <br />
                                        [Upload a copy of Signature] */}
                                      </Row>
                                      <Row>
                                        <Colxx xxs="6 text-center  mt-3">
                                          {/* <Row>
                                            <Colxx xxs="12 text-left  mt-3">
                                              <span>
                                                {`Place:`}{" "}
                                                <Field
                                                  name={"Place"}
                                                  type="text"
                                                  onChange={(event) => {
                                                    this.setState({
                                                      area_form_values: {
                                                        ...this.state
                                                          .area_form_values,
                                                        place:
                                                          event.target.value,
                                                      },
                                                    });
                                                    // console.log(
                                                    //   "event",
                                                    //   event.target.value
                                                    // );
                                                  }}
                                                />
                                                {errors.Place && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Place}
                                                  </div>
                                                )}
                                              </span>
                                            </Colxx>
                                          </Row> */}
                                          <Row>
                                            <Colxx xxs="6 text-left">
                                              <span>
                                                {"Date: "}{" "}
                                                <span className="text-underline">
                                                  {moment().format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </span>
                                              </span>
                                            </Colxx>
                                          </Row>
                                        </Colxx>

                                        <Colxx
                                          style={{
                                            marginTop: "20px",
                                            justifyItems: "flex-end",
                                            display: "grid",
                                          }}
                                        >
                                          <Row>
                                            <FormGroup className="float-sm-right ">
                                              {this.state.CurrentApplication ===
                                              "Notary Application" ? (
                                                this.state.AreaStatus === "" ||
                                                this.state.AreaStatus ===
                                                  "Approved" ||
                                                this.state.AreaStatus ===
                                                  "Rejected" ? (
                                                  <Button
                                                    className={
                                                      this.state.buttonAction
                                                        ? "disabled ml-2"
                                                        : "ml-2"
                                                    }
                                                    type="submit"
                                                    outline
                                                    color="success"
                                                    onClick={(e) => {
                                                      setValues(
                                                        "isSubmitted",
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    {this.props.t("Submit")}
                                                  </Button>
                                                ) : (
                                                  ""
                                                )
                                              ) : this.state.IsApplication ===
                                                "true" ? (
                                                this.state.Status ===
                                                "Approved" ? (
                                                  <Button
                                                    className={
                                                      this.state.buttonAction
                                                        ? "disabled ml-2"
                                                        : "ml-2"
                                                    }
                                                    type="submit"
                                                    outline
                                                    color="success"
                                                    onClick={(e) => {
                                                      setValues(
                                                        "isSubmitted",
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    {" "}
                                                    {this.props.t("Submit")}
                                                  </Button>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                <Button
                                                  className={
                                                    this.state.buttonAction
                                                      ? "disabled ml-2"
                                                      : "ml-2"
                                                  }
                                                  type="submit"
                                                  outline
                                                  color="success"
                                                  onClick={(e) => {
                                                    setValues(
                                                      "isSubmitted",
                                                      true
                                                    );
                                                  }}
                                                >
                                                  {this.props.t("Submit")}
                                                </Button>
                                              )}

                                              {this.state.Status ===
                                              "Returned" ? (
                                                this.state.activeFirstTab ===
                                                2 ? (
                                                  <Button
                                                    style={{ float: "right" }}
                                                    color="success"
                                                    className="ml-2"
                                                    type="submit"
                                                  >
                                                    {this.props.t("Resubmit")}{" "}
                                                  </Button>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </FormGroup>
                                          </Row>
                                        </Colxx>
                                      </Row>
                                    </div>
                                  </Colxx>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        </Fragment>
                      </ModalBody>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            {/* photoviewr modal*/}
            <Modal
              className="viewDocumentModal"
              isOpen={this.state.DocumentViewModal}
              size="md"
              toggle={this.togglePhotoViewModal}
            >
              <ModalHeader toggle={this.togglePhotoViewModal}>
                <b>{this.state.Title}</b>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12">
                    <Fragment>
                      <div className="each-slide">
                        <img
                          alt="noimg"
                          id="userimg"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          src={this.state.ImageGallery}
                        />
                      </div>
                    </Fragment>
                  </Colxx>
                </Row>
              </ModalBody>
              <ModalFooter>
                <FormGroup>
                  <a href={this.state.ImageGallery}>
                    <Button
                      type="submit"
                      className="default   btn-sm mb-2 btn-addon"
                      color="primary"
                    >
                      {this.props.t("Download")}
                    </Button>{" "}
                  </a>

                  <Button
                    className="default   btn-sm mb-2 btn-addon"
                    color="danger"
                    onClick={() => this.togglePhotoViewModal()}
                  >
                    {this.props.t("Close")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
            {/* payment modal */}
            <Modal
              isOpen={this.state.MakingPaymentModal}
              toggle={() => this.confirmMakingPayment()}
            >
              <ModalHeader toggle={() => this.confirmMakingPayment()}>
                Confirm Payment Applications
              </ModalHeader>
              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      {this.state.InitiateResponse &&
                      this.state.InitiateResponse.pmtData &&
                      this.state.InitiateResponse.pmtData.transactionAmount !==
                        "null" ? (
                        <h5 className="pr-2 pt-2">
                          Are you sure you want to make payment of Rs.
                          {this.state.InitiateResponse &&
                            this.state.InitiateResponse.pmtData &&
                            this.state.InitiateResponse.pmtData
                              .transactionAmount}{" "}
                          ?
                        </h5>
                      ) : (
                        <h5 className="pr-2 pt-2">
                          Are you sure you want to make payment ?
                        </h5>
                      )}
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  {/* <Button type="submit" outline color="primary">
                      Confirm
                    </Button>*/}
                  <PaymentForm
                    SubmitPayment={this.AreaExtensionSubmitPayment}
                    InitiateResponse={this.state.InitiateResponse}
                  />
                </FormGroup>
              </ModalFooter>

              {/* RenewalModal */}
            </Modal>
            <Modal
              isOpen={this.state.RenewalModal}
              toggle={() => this.toggleRenewalModal()}
            >
              <ModalHeader>{this.props.t("Renewal Application")}</ModalHeader>

              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Colxx>
                      <div>
                        {`You ought to have applied for renewal of your
                        certificate of practice on or before (${
                          this.state.RenewalDueDate
                            ? moment(
                                new Date(this.state.RenewalDueDate)
                              ).format("DD/MM/YYYY")
                            : ""
                        }) as per
                        the Notaries Rules, 1956. As you have failed to apply
                        for renewal before the due date, you cannot apply now.`}
                      </div>
                    </Colxx>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleRenewalModal()}
                  >
                    {this.props.t("Close")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(HomePage));
