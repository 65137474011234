import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import { authenctication } from "../../constants/config";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { Formik, Form, Field } from "formik";
import { withNamespaces } from "react-i18next";
import "rc-switch/assets/index.css";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changeMobileValidation,
  changeEmailId,
} from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { editProfile } from "../../store/actions";

class ChangeMobileNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFirstTab: 1,
      idx: 1,
      buttonAction: false,
      tabs: [],
      loading: false,
      SubmitLoading: false,
      changeMobileNumber: {
        MobileNumber: "",
        OTP: "",
        OldMobileNumber: "",
      },
      changeEmailIDValues: {
        EmailID: "",
        OTP: "",
        OldEmailID: "",
      },
      Language: sessionStorage.getItem("lng"),

      State: [],
      //  MobileNumber: sessionStorage.getItem("MobileNumber"),
    };
  }
  componentDidMount() {
    if (sessionStorage.getItem("user_token")) {
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
          this.setState({
            name: obj.displayName,
            email: obj.email,
            idx: obj.uid,
          });
        } else if (
          process.env.REACT_APP_DEFAULTAUTH === "fake" ||
          process.env.REACT_APP_DEFAULTAUTH === "jwt"
        ) {
          this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
        }
      }
      this.setTab(1);
    } else {
      window.location.replace("");
    }
  }

  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Change Mobile Number"),
        },
        {
          id: 2,
          Name: this.props.t("Change Email ID"),
        },
      ],
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }

    this.setState({
      loading: false,
      SubmitLoading: false,
      SendOtp: false,
    });
  };

  handleSubmit = (values) => {
    if (values) {
      this.setState({
        SubmitLoading: true,
        ProfileDetail: values,
      });
      this.ChangeMobileNumber();
    }
  };
  handleEmail = (values) => {
    if (values) {
      this.setState({
        SubmitLoading: true,
        ProfileDetail: values,
      });
      this.ChangeEmailID();
    }
  };

  ChangeMobileNumber() {
    console.log();
    CallService(
      authenctication.ChangeMobileNumber,
      MethodType.POST,
      false,
      this.state.ProfileDetail,
      "",
      this.ChangeMobileNumberResponse
    );
  }
  ChangeMobileNumberResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Mobile Number Changed Successfully");
      this.props.history.push("/dashboard");
    } else if (data.statusCode === StatusCodes.Duplicate) {
      toastr.error("", "Duplicate Mobile Number");
    } else if (data.statusCode === StatusCodes.InvalidData) {
      toastr.error("", "Unauthorized mobile number");
    } else {
      toastr.error("", "Something went wrong");
    }

    setTimeout(
      () => this.setState({ position: 1, SubmitLoading: false }),
      1000
    );
  };
  ChangeEmailID() {
    console.log();
    CallService(
      authenctication.changeEmailID,
      MethodType.POST,
      false,
      this.state.ProfileDetail,
      "",
      this.ChangeEmailIDResponse
    );

    setTimeout(
      function () {
        this.setState({ position: 1, SubmitLoading: false });
      }.bind(this),
      3000
    );
  }
  ChangeEmailIDResponse = (data) => {
    this.setState({
      SubmitLoading: false,
    });
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Email ID Changed Successfully");
      this.props.history.push("/dashboard");
    } else if (data.statusCode === StatusCodes.Duplicate) {
      toastr.error("", "Duplicate Email ID");
    } else if (data.statusCode === StatusCodes.InvalidData) {
      toastr.error("", "Unauthorized Email ID");
    } else {
      toastr.error("", "Something went wrong");
    }

    setTimeout(
      function () {
        this.setState({ position: 1, SubmitLoading: false });
      }.bind(this),
      3000
    );
  };
  getOtp = (val) => {
    console.log(val);

    var isValid = false;
    var values = {};
    if (val.MobileNumber) {
      let mobileRegex = /^\d{10}$/;
      var mobileno = val.MobileNumber.toString();
      if (mobileno.match(mobileRegex)) {
        this.setState({
          loading: true,
          SendOtp: true,
        });

        isValid = true;
        values.MobileNumber = val.MobileNumber;
        values.Type = "MOBILE_NUMBER_CHANGE";
      } else {
        toastr.warning("", "Not a valid number");
      }
    } else if (val.EmailID) {
      this.setState({
        loading: false,
        SendOtp: true,
      });
      isValid = true;
      values.EmailID = val.EmailID;
      values.Type = "EMAILID_CHANGE";
    } else {
      toastr.warning("", "Not a valid email id");
    }

    if (isValid) {
      CallService(
        authenctication.getOTP,
        MethodType.POST,
        false,
        values,
        "",
        this.OtpResponse
      );
    }
  };

  OtpResponse = (data) => {
    if (data.result) {
      this.setState({ loading: false });
    }
  };
  render() {
    const initialValues = this.state.changeMobileNumber;
    const initialEmailValues = this.state.changeEmailIDValues;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb
              title={this.props.t("Dashboard")}
              breadcrumbItem={this.props.t(
                this.state.activeFirstTab === 1
                  ? "Change Mobile Number"
                  : "Change Email ID"
              )}
            />
            {/* <h4 className="card-title mb-4">Change UserName</h4> */}
            <Nav tabs className="nav-tabs-custom nav-justified">
              {this.state.tabs.map((t) => {
                return (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={{
                        active: this.state.activeFirstTab === t.id,
                      }}
                      onClick={() => {
                        this.toggleCustomJustified(t.id);
                      }}
                    >
                      <span className="d-none d-sm-block">{t.Name}</span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>

            <Card>
              <CardBody>
                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={1}>
                    <br></br>
                    <Colxx xx="6">
                      <Row>
                        <Colxx xx="3" lg="3" md="3" sm="3"></Colxx>
                        <Colxx xx="6" lg="6" md="6" sm="6">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={changeMobileValidation}
                            onSubmit={this.handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={true}
                          >
                            {({ values, errors, touched, setFieldValue }) => (
                              <Form className="av-tooltip tooltip-label-bottom">
                                <FormGroup className="form-group has-float-label">
                                  <Label>
                                    {this.props.t("New Mobile Number")}
                                  </Label>
                                  <Field
                                    className="IncrementHide form-control"
                                    type="number"
                                    name="MobileNumber"
                                    onKeyPress={(event) => {
                                      if (INVALID_CHARS.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      event.target.value =
                                        event.target.value &&
                                        event.target.value.replace(
                                          INVALID_CHARS_REGEX,
                                          ""
                                        );
                                      setFieldValue(
                                        "MobileNumber",
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {errors.MobileNumber &&
                                    touched.MobileNumber ? (
                                      <div className="invalid-feedback d-block">
                                        {errors.MobileNumber}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                </FormGroup>

                                {!this.state.SendOtp ? (
                                  <Fragment>
                                    <div className="mt-4">
                                      <button
                                        color="primary"
                                        className="btn btn-primary btn-block waves-effect waves-light"
                                        // className={`btn-shadow btn-multiple-state`}
                                        size="lg"
                                        onClick={() => this.getOtp(values)}
                                      >
                                        {this.props.t("Send OTP")}
                                      </button>
                                    </div>
                                  </Fragment>
                                ) : (
                                    ""
                                  )}

                                {this.state.SendOtp ? (
                                  <Fragment>
                                    <FormGroup className="form-group has-float-label">
                                      <Label>OTP</Label>{" "}
                                      <span style={{ float: "right" }}>
                                        {this.props.t("Please verify your OTP")}
                                      </span>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        name="OTP"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event.target.value &&
                                            event.target.value.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "OTP",
                                            event.target.value
                                          );
                                        }}
                                      />
                                      {errors.OTP && touched.OTP ? (
                                        <div className="invalid-feedback d-block">
                                          {errors.OTP}
                                        </div>
                                      ) : (
                                          ""
                                        )}
                                    </FormGroup>

                                    <FormGroup className="form-group has-float-label">
                                      <Label>
                                        {this.props.t("Old Mobile Number")}
                                      </Label>
                                      <Field
                                        type="number"
                                        className="IncrementHide form-control"
                                        name="OldMobileNumber"
                                        placeholder={this.props.t(
                                          "mobile number"
                                        )}
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event.target.value &&
                                            event.target.value.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "OldMobileNumber",
                                            event.target.value
                                          );
                                        }}
                                      />
                                      {errors.OldMobileNumber &&
                                        touched.OldMobileNumber ? (
                                          <div className="invalid-feedback d-block">
                                            {errors.OldMobileNumber}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                    </FormGroup>

                                    <div className="mt-4">
                                      <button
                                        color="primary"
                                        className={`btn btn-primary btn-block waves-effect waves-light ${this.state.SubmitLoading
                                            ? "show-spinner"
                                            : ""
                                          }`}
                                        size="lg"
                                      >
                                        {this.state.SubmitLoading ? (
                                          <span>
                                            <Spinner
                                              as="span"
                                              animation="grow"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : (
                                            this.props.t("Submit")
                                          )}
                                      </button>
                                    </div>
                                  </Fragment>
                                ) : (
                                    ""
                                  )}
                              </Form>
                            )}
                          </Formik>
                        </Colxx>
                      </Row>
                    </Colxx>{" "}
                  </TabPane>
                </TabContent>
                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={2}>
                    <br></br>
                    <Colxx xx="6">
                      <Row>
                        <Colxx xx="3" lg="3" md="3" sm="3"></Colxx>
                        <Colxx xx="6" lg="6" md="6" sm="6">
                          <Formik
                            initialValues={initialEmailValues}
                            validationSchema={changeEmailId}
                            onSubmit={this.handleEmail}
                            validateOnBlur={false}
                            validateOnChange={true}
                          >
                            {({ values, errors, touched, setFieldValue }) => (
                              <Form className="av-tooltip tooltip-label-bottom">
                                <FormGroup className="form-group has-float-label">
                                  <Label>{this.props.t("New Email ID")}</Label>
                                  <Field
                                    className="form-control"
                                    type="email"
                                    name="EmailID"
                                  />
                                  {errors.EmailID && touched.EmailID ? (
                                    <div className="invalid-feedback d-block">
                                      {errors.EmailID}
                                    </div>
                                  ) : (
                                      ""
                                    )}
                                </FormGroup>

                                {!this.state.SendOtp ? (
                                  <Fragment>
                                    <div className="mt-4">
                                      <button
                                        color="primary"
                                        className="btn btn-primary btn-block waves-effect waves-light"
                                        // className={`btn-shadow btn-multiple-state`}
                                        size="lg"
                                        onClick={() => this.getOtp(values)}
                                      >
                                        {this.props.t("Send OTP")}
                                      </button>
                                    </div>
                                  </Fragment>
                                ) : (
                                    ""
                                  )}

                                {this.state.SendOtp ? (
                                  <Fragment>
                                    <FormGroup className="form-group has-float-label">
                                      <Label>OTP</Label>{" "}
                                      <span style={{ float: "right" }}>
                                        {this.props.t("Please verify your OTP")}
                                      </span>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        name="OTP"
                                        onKeyPress={(event) => {
                                          if (
                                            INVALID_CHARS.includes(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onBlur={(event) => {
                                          event.target.value =
                                            event.target.value &&
                                            event.target.value.replace(
                                              INVALID_CHARS_REGEX,
                                              ""
                                            );
                                          setFieldValue(
                                            "OTP",
                                            event.target.value
                                          );
                                        }}
                                      />
                                      {errors.OTP && touched.OTP ? (
                                        <div className="invalid-feedback d-block">
                                          {errors.OTP}
                                        </div>
                                      ) : (
                                          ""
                                        )}
                                    </FormGroup>

                                    <FormGroup className="form-group has-float-label">
                                      <Label>
                                        {this.props.t("Old Email ID")}
                                      </Label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        name="OldEmailID"
                                        placeholder={this.props.t(
                                          "example@gmail.com"
                                        )}
                                      />
                                      {errors.OldEmailID &&
                                        touched.OldEmailID ? (
                                          <div className="invalid-feedback d-block">
                                            {errors.OldEmailID}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                    </FormGroup>

                                    <div className="mt-4">
                                      <button
                                        color="primary"
                                        className={`btn btn-primary btn-block waves-effect waves-light ${this.state.loading
                                            ? "show-spinner"
                                            : ""
                                          }`}
                                        size="lg"
                                      >
                                        {this.state.SubmitLoading ? (
                                          <span>
                                            <Spinner
                                              as="span"
                                              animation="grow"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : (
                                            this.props.t("Submit")
                                          )}
                                      </button>
                                    </div>
                                  </Fragment>
                                ) : (
                                    ""
                                  )}
                              </Form>
                            )}
                          </Formik>
                        </Colxx>
                      </Row>
                    </Colxx>{" "}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(
    withNamespaces()(ChangeMobileNumber)
  )
);
