import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Colxx } from "../../components/Common/CustomBootstrap";
import moment from "moment";
import FileDisplay from "../../components/custom/FileDisplay";
import { withNamespaces } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import ReactTooltip from "react-tooltip";
import ReactHtmlParser from "react-html-parser";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Button,
  FormGroup,
  Card,
  CardBody,
  Col,
  CustomInput,
  Alert,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApplicationFormValidation } from "../../helpers/validations";
import { CallService } from "../../helpers/ServiceCall";
import {
  MethodType,
  StatusCodes,
  TITLE,
  FORMTYPES,
} from "../../constants/defaultValues";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  district,
  taluk,
  advocate,
  newapplication,
  gender,
  community,
  practicingin,
  areaExtensionApplication,
  existingNotary,
  formType,
  documentMaster,
  dummyPayment,
} from "../../constants/config";
import {
  getFileSizeText,
  checkIfImage,
  getAllowedExtensionText,
  INVALID_CHARS,
  INVALID_CHARS_REGEX,
  checkIfPdfS,
} from "../../helpers/Utils";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { formatDate, getMonthName } from "../../helpers/Utils";
import PaymentForm from "../../pages/PaymentGateway/PaymentForm";
class Application extends Component {
  constructor(props) {
    super(props);
    // this.toggleImageView = this.toggleImageView.bind(this);
    this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    {
      console.log("AreaStatusSSS", sessionStorage.getItem("AreaStatus"));
    }
    this.state = {
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
      },
      buttonAction: false,
      Status: sessionStorage.getItem("Status"),
      //  RenewalStatus: sessionStorage.getItem("RenewalStatus"),
      AreaStatus: sessionStorage.getItem("AreaStatus"),
      AreaExtensionId: sessionStorage.getItem("AreaExtensionsApplicationID"),
      //  RenewalId: sessionStorage.getItem("RenewalApplicationID"),
      Language: sessionStorage.getItem("lng"),
      AdvocateId: sessionStorage.getItem("user_id"),
      ApplicationId: sessionStorage.getItem("ApplicationId"),
      ExistingNotaryId: sessionStorage.getItem("ExistingNotaryApplicationsID"),
      CallLetter: sessionStorage.getItem("CallLetter"),
      FeeTotalAmount: sessionStorage.getItem("FeeTotalAmount"),
      //  TotalAreas: JSON.parse(sessionStorage.getItem("TotalAreas")),
      TotalTaluks: JSON.parse(sessionStorage.getItem("TotalTaluks")),
      IsApplication: sessionStorage.getItem("Application"),
      CurrentApplication:
        this.props.location.pathname === "/notaryapplication"
          ? "Notary Application"
          : this.props.location.pathname === "/areaextensionapplication"
          ? "Practicing Area Extension Application"
          : this.props.location.pathname === "/renewalapplication"
          ? "Renewal Application"
          : this.props.location.pathname === "/duplicatecertificate"
          ? "Duplication Certificate Application"
          : "Notary Application",
      activeFirstTab: sessionStorage.getItem("Status") === "Returned" ? 2 : 1,

      Application: {
        Name: "",
        Gender: "",
        FatherOrHusbandName: "",
        Dob: "",
        MobileNumber: "",
        EmailID: "",
        BarCouncilNumber: "",
        Community: "",

        ResidencyAddress: {
          Address: {
            Line1: "",
          },
          ProvisionalAddress: {
            Address: {
              Line1: "",
              Line2: "",
              Line3: "",
            },
          },
          Degrees: [
            {
              Institution: "",
              Name: "",
              Url: "",
              YearOfPassing: "",
            },
          ],
        },
        Taluks: [],
        District: "",
        DeclarationFields: {
          Name: "",
          AreaOfPracticeAsAnAdvocate: "",
          Requirements: "",
          IntendedAreaOfPractice: "",
        },
      },
      AdditionalDocuments: [
        {
          Name: "",
          Url: "",
        },
      ],
      tabs: [],
      DistrictList: [],
      TalukList: [],
      GenderList: [],
      Communities: [],
      PracticingInList: [],
    };
  }
  componentDidMount() {
    this.setTab(1);

    this.GetAllCommunity();
    this.GetOneAdvocate();
    this.GetAllDistrict();
    //  this.GetAllTaluk();
    this.GetAllGender();
    this.GetAllPracticingIn();
    this.getAllFormTypes();

    if (
      this.state.CurrentApplication === "Practicing Area Extension Application"
    ) {
      if (
        (this.state.AreaStatus !== "Approved" ||
          this.state.AreaStatus !== "Rejected") &&
        this.state.AreaStatus
      ) {
        this.GetOneAreaApplication();
        //  this.GetOneExistingNotary();
      }
    } else {
      if (this.state.IsApplication === "true") {
        if (this.state.Status !== "Approved") {
          this.GetOneNewApplications(this.state.ApplicationId);
        }
      }
    }
  }
  GetAllPracticingIn() {
    CallService(
      practicingin.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllPracticingInResponse
    );
  }

  GetAllPracticingInResponse = (data) => {
    this.setState({
      PracticingInList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  GetOneNewApplications() {
    CallService(
      newapplication.GetOne + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneNewApplicationsResponse
    );
  }

  GetOneNewApplicationsResponse = (data) => {
    var PrefileValues = this.state.Application;

    PrefileValues.Taluks = data.result
      ? data.result
        ? data.result.Taluks
        : null
      : null;

    if (this.props.location.pathname === "/notaryapplication") {
      PrefileValues.District = data.result
        ? data.result
          ? data.result.District._id
          : null
        : null;
    } else if (
      this.props.location.pathname === "/areaextensionapplication" &&
      this.state.AreaStatus !== ""
    ) {
      PrefileValues.District = data.result
        ? data.result
          ? data.result.District._id
          : null
        : null;
    }

    //  PrefileValues.Remarks = data.result ? data.result.Remarks : "";
    PrefileValues.Grounds = data.result ? data.result.Grounds : "";

    PrefileValues.DeclarationFields =
      data.result && data.result.DeclarationFields
        ? data.result.DeclarationFields
        : {};
    PrefileValues.AdditionalDocuments =
      data.result && data.result.AdditionalDocuments;
    PrefileValues.Comments = data.result && data.result.Comments;
    PrefileValues.DistrictLabel =
      data.result && data.result.District && data.result.District.Name;

    PrefileValues.ActionTakenName =
      data.result && data.result.ActionTaken
        ? data.result.ActionTaken && data.result.ActionTaken.length > 0
          ? data.result.ActionTaken[data.result.ActionTaken.length - 1].By &&
            data.result.ActionTaken[data.result.ActionTaken.length - 1].By.Name
            ? data.result.ActionTaken[data.result.ActionTaken.length - 1].By
                .Name
            : ""
          : ""
        : "";

    this.setState({
      Application: PrefileValues,
      ApplicationNumber:
        data.result && data.result.ApplicationNo
          ? data.result.ApplicationNo
          : "",
      SRApplicationNumber:
        data.result && data.result.SRApplicationNo
          ? data.result.SRApplicationNo
          : "",
      ApplicationDetail: data.result,
    });
  };

  // GetOneExistingNotary() {
  //   CallService(
  //     existingNotary.GetOne + this.state.ExistingNotaryId,
  //     MethodType.GET,
  //     false,
  //     "",
  //     "",
  //     this.GetOneExistingNotaryResponse
  //   );
  // }

  // GetOneExistingNotaryResponse = (data) => {
  //   let AllTaluk = this.state.TalukList;
  //   let extendArea = data.result.AreaExtensionApplications[0].Taluks;

  //   var result = AllTaluk.filter(function (o1) {
  //     return extendArea.some(function (o2) {
  //       return o1.value !== o2._id;
  //     });
  //   });

  //   this.setState({
  //     TalukList: result,
  //   });
  // };
  GetOneAreaApplication() {
    CallService(
      areaExtensionApplication.GetOne + this.state.AreaExtensionId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneAreaApplicationResponse
    );
  }

  GetOneAreaApplicationResponse = (data) => {
    var PrefileValues = this.state.Application;
    PrefileValues.Taluks = data.result
      ? data.result
        ? data.result.Taluks
          ? data.result.Taluks.map((taluk) => {
              return taluk._id;
            })
          : null
        : null
      : null;
    if (this.props.location.pathname === "/notaryapplication") {
      PrefileValues.District = data.result
        ? data.result
          ? data.result.District._id
          : null
        : null;
    } else if (
      this.props.location.pathname === "/areaextensionapplication" &&
      this.state.AreaStatus !== ""
    ) {
      PrefileValues.District = data.result
        ? data.result
          ? data.result.District._id
          : null
        : null;
    }

    //  PrefileValues.Remarks = data.result ? data.result.Remarks : "";
    PrefileValues.Grounds = data.result ? data.result.Grounds : "";
    this.setState({
      PracticingTaluks: data.result,
      Application: PrefileValues,
    });
  };
  GetOneAdvocate() {
    CallService(
      advocate.GetOne + this.state.AdvocateId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneAdvocateResponse
    );
  }

  GetOneAdvocateResponse = (data) => {
    if (data.result) {
      var Dob = data.result ? (data.result ? data.result.Dob : "") : "";
      var date = moment(new Date(Dob)).format("DD/MM/YYYY");
      //     var PrefillAdvocate = this.state.Application;
      var DegreeValue = [];
      for (var i = 0; i < data.result.Degrees.length; i++) {
        var deg = {};
        deg.Institution = data.result.Degrees[i]
          ? data.result.Degrees[i].Institution
          : "";
        deg.Name = data.result.Degrees[i] ? data.result.Degrees[i].Name : "";
        deg.Url = data.result.Degrees[i] ? data.result.Degrees[i].Url : "";
        deg.YearOfPassing = data.result.Degrees[i]
          ? data.result.Degrees[i].YearOfPassing
          : "";
        DegreeValue.push(deg);
      }

      if (data.result.Title === TITLE.THIRUMATHI) {
        var FatherText = "Husband's Name";
      } else {
        FatherText = "Father's Name";
      }

      this.GetAllTaluk(data.result.ResidencyAddress.Address.District);
      var PrefillAdvocate = {
        Title: data.result.Title,
        IsMarried: data.result.IsMarried,
        Name: data.result.Name,
        Gender: data.result.Gender,
        MobileNumber: sessionStorage.getItem("MobileNumber"),
        Dob: date,
        FormType: data.result.FormType,
        EmailID: data.result.EmailID,
        FatherOrHusbandName: data.result.FatherOrHusbandName,
        Community: data.result.Community,
        BarCouncilNumber: data.result.BarCouncilNumber,
        IsIntegratedDegree: data.result.IsIntegratedDegree,
        DeclarationFields: {
          Name: data.result.Name,
          AreaOfPracticeAsAnAdvocate: this.state.Application?.DeclarationFields
            ?.AreaOfPracticeAsAnAdvocate
            ? this.state.Application?.DeclarationFields
                ?.AreaOfPracticeAsAnAdvocate
            : "",
          Requirements: this.state.Application?.DeclarationFields
            ?.AreaOfPracticeAsAnAdvocate
            ? this.state.Application?.DeclarationFields?.Requirements
            : "",
          IntendedAreaOfPractice: this.state.Application?.DeclarationFields
            ?.AreaOfPracticeAsAnAdvocate
            ? this.state.Application?.DeclarationFields?.IntendedAreaOfPractice
            : "",
        },
        ResidencyAddress: {
          Address: {
            Line1: data.result.ResidencyAddress.Address.Line1,
            Line2: data.result.ResidencyAddress.Address.Line2,
            Line3: data.result.ResidencyAddress.Address.Line3,
            District: data.result.ResidencyAddress.Address.District,
            Taluk: data.result.ResidencyAddress.Address.Taluk,
            Pincode: data.result.ResidencyAddress.Address.Pincode,
          },
        },
        ProvisionalAddress: {
          Address: {
            Line1: data.result.ProvisionalAddress.Address.Line1,
            Line2: data.result.ProvisionalAddress.Address.Line2,
            Line3: data.result.ProvisionalAddress.Address.Line3,
            District: data.result.ProvisionalAddress.Address.District,
            Taluk: data.result.ProvisionalAddress.Address.Taluk,
            Pincode: data.result.ProvisionalAddress.Address.Pincode,
          },
          IDProofNumber: data.result.ProvisionalAddress.IDProofNumber,
        },

        Aadhar: data.result.Aadhar,
        PANNumber: data.result.PANNumber,
        EnrolmentDate: data.result.EnrolmentDate
          ? moment(new Date(data.result.EnrolmentDate)).format("DD/MM/YYYY")
          : null,
        PracticingSince: data.result.PracticingSince
          ? moment(new Date(data.result.PracticingSince)).format("DD/MM/YYYY")
          : null,
        PracticingIn: data.result
          ? data.result
            ? data.result.PracticingIn
              ? data.result.PracticingIn.map((In) => {
                  return In._id;
                })
              : null
            : null
          : null,
        IncomeTaxAssessee: data.result.IncomeTaxAssessee,
        Fax: data.result.Fax,

        Degrees: DegreeValue,
        Photo: data.result.Photo,
        AdditionalDocuments: data.result && data.result.AdditionalDocuments,
        //  DistrictLabel: data.result && data.result.District.Name,
      };

      this.setState({
        FatherText: FatherText,
        Application: PrefillAdvocate,
      });
    } else {
      toastr.error("", "Something went wrong");
    }
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  GetAllTaluk(districtId) {
    let { ListRequestModel } = this.state;
    ListRequestModel.pageLimit = 1000;
    ListRequestModel.District = districtId;

    // if (
    //   this.state.CurrentApplication === "Practicing Area Extension Application"
    // ) {
    //   ListRequestModel.District = null;
    // } else {
    //   ListRequestModel.District = districtId;
    // }

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  }

  GetAllTalukResponse = (data) => {
    var filterValues = data.result;
    let extendArea;

    var totalAreas = sessionStorage.getItem("TotalAreas");
    if (totalAreas) {
      var TotalAreas = JSON.parse(totalAreas);
      extendArea = TotalAreas[0].Taluks;
    }
    if (extendArea) {
      filterValues =
        data.result.length > 0 &&
        data.result.filter(function (val) {
          return extendArea.indexOf(val._id) === -1;
        });
    }

    if (this.state.Language === "en") {
      this.setState(
        {
          TalukList: data.result.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
          SliceTaluks: filterValues.map(function (a) {
            return { value: a._id, label: a.Name };
          }),
        },
        () => {
          // if (this.state.ExistingNotaryId) this.GetOneExistingNotary();
        }
      );
    } else {
      this.setState(
        {
          TalukList: data.result.map(function (a) {
            return { value: a._id, label: a.TamilName };
          }),
          SliceTaluks: filterValues.map(function (a) {
            return { value: a._id, label: a.TamilName };
          }),
        },
        () => {
          //  if (this.state.ExistingNotaryId) this.GetOneExistingNotary();
        }
      );
    }
  };
  GetAllCommunity() {
    CallService(
      community.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllCommunityResponse
    );
  }

  GetAllCommunityResponse = (data) => {
    this.setState({
      Communities: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  GetAllGender() {
    CallService(
      gender.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllGenderResponse
    );
  }

  GetAllGenderResponse = (data) => {
    this.setState({
      GenderList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };
  handleSubmit = (values) => {
    var details = values;
    var isValid = false;

    if (this.state.Status !== "Returned") {
      if (this.state.CurrentApplication === "Notary Application") {
        if (values.FormType === FORMTYPES.Form1) {
          if (values.DeclarationFields) {
            if (
              values.DeclarationFields.Name &&
              values.DeclarationFields.AreaOfPracticeAsAnAdvocate &&
              values.DeclarationFields.IntendedAreaOfPractice &&
              values.DeclarationFields.Requirements
            ) {
              isValid = true;
              details.DeclarationFields.Name = values.DeclarationFields.Name
                ? values.DeclarationFields.Name
                : "";
              details.DeclarationFields.AreaOfPracticeAsAnAdvocate = values
                .DeclarationFields.AreaOfPracticeAsAnAdvocate
                ? values.DeclarationFields.AreaOfPracticeAsAnAdvocate
                : "";
              details.DeclarationFields.Requirements = values.DeclarationFields
                .Requirements
                ? values.DeclarationFields.Requirements
                : "";
              details.DeclarationFields.IntendedAreaOfPractice = values
                .DeclarationFields.IntendedAreaOfPractice
                ? values.DeclarationFields.IntendedAreaOfPractice
                : "";
            } else {
              toastr.error("", "Please fill all field");
            }
          } else {
            toastr.error("", "Please fill all field");
          }
        } else {
          isValid = true;
        }
      } else {
        if (values.District && values.Reason) {
          isValid = true;
        } else {
          toastr.error("", "Please fill mandatory field");
        }
      }
    } else {
      this.handleReSubmit(values);
    }

    if (isValid) {
      if (!this.state.buttonAction) {
        this.setState({
          buttonAction: true,
        });

        details.Gender =
          values.Gender && values.Gender._id
            ? values.Gender._id
            : values.Gender;
        details.Community =
          values.Community && values.Community._id
            ? values.Community._id
            : values.Community;

        details.ResidencyAddress.Address.District =
          values.ResidencyAddress &&
          values.ResidencyAddress.Address &&
          values.ResidencyAddress.Address.District._id
            ? values.ResidencyAddress.Address.District._id
            : values.ResidencyAddress.Address.District;

        details.ResidencyAddress.Address.Taluk =
          values.ResidencyAddress &&
          values.ResidencyAddress.Address &&
          values.ResidencyAddress.Address.Taluk._id
            ? values.ResidencyAddress.Address.Taluk._id
            : values.ResidencyAddress.Address.Taluk;

        details.ProvisionalAddress.Address.District =
          values.ProvisionalAddress &&
          values.ProvisionalAddress.Address &&
          values.ProvisionalAddress.Address.District._id
            ? values.ProvisionalAddress.Address.District._id
            : values.ProvisionalAddress.Address.District;

        details.ProvisionalAddress.Address.Taluk =
          values.ProvisionalAddress &&
          values.ProvisionalAddress.Address &&
          values.ProvisionalAddress.Address.Taluk._id
            ? values.ProvisionalAddress.Address.Taluk._id
            : values.ProvisionalAddress.Address.Taluk;

        // details.District = details.ResidencyAddress.Address.District;

        if (this.state.CurrentApplication === "Notary Application") {
          details.District = details.ResidencyAddress.Address.District;
        } else if (values.District) {
          details.District = values.District;
        } else {
          details.District = details.ResidencyAddress.Address.District;
        }

        details.Taluks =
          typeof values.Taluks === "object"
            ? values.Taluks.map((taluk) => {
                return taluk.value ? taluk.value : taluk;
              })
            : values.Taluks;

        console.log(details);
        this.manageApplication(details);
      } else {
        this.setState({
          buttonAction: false,
        });
      }
    }
  };
  handleReSubmit = (values) => {
    var isValidAll;

    if (
      values.AdditionalDocuments &&
      values.AdditionalDocuments[0].Name &&
      values.AdditionalDocuments[0].Url
    ) {
      isValidAll = true;
      this.setState({
        AdditionalLabel: values,
      });
    }

    if (isValidAll) {
      let formData = new FormData();
      for (let i = 0; i < values.AdditionalDocuments.length; i++) {
        formData.append(
          `AdditionalDocuments${i}`,
          values.AdditionalDocuments[i].Url
        );
      }

      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.DocumentUploaded,
        true
      );
    } else {
      toastr.error("", "Please fill additional documents");
    }
  };

  DocumentUploaded = (data) => {
    if (data.result) {
      //   var additional = this.state.AdditionalDocuments;
      var additionalLabel = this.state.AdditionalLabel.AdditionalDocuments;

      for (var i = 0; i < additionalLabel.length; i++) {
        if (additionalLabel.length > 0) {
          additionalLabel[i].Url = data.result["AdditionalDocuments" + i]
            ? data.result["AdditionalDocuments" + i]
            : "";
        }
      }

      this.setState({
        AdditionalDocuments: additionalLabel,
      });

      this.manageReSubmit();
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "File Upload Failed");
    }
  };

  manageReSubmit = () => {
    var val = {};
    val.AdditionalDocuments = this.state.AdditionalDocuments;
    val.ApplicationId = this.state.ApplicationId;

    console.log(val);
    CallService(
      newapplication.ResubmitApplication,
      MethodType.PUT,
      false,
      val,
      "",
      this.manageReSubmitResponse
    );
  };
  manageReSubmitResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        `${this.state.CurrentApplication} Submitted Successfully`
      );
      this.setState({
        buttonAction: false,
      });
      sessionStorage.setItem("Status", data.result.Status);
      window.location.href = "#/dashboard";
    } else {
      toastr.error("", "Something went wrong");
    }
  };

  GetDraftSubmitted = () => {
    CallService(
      newapplication.GetSubmitted + this.state.ApplicationId,
      MethodType.GET,
      false,
      "",
      "",
      this.GetDraftSubmittedResponse
    );
  };
  GetDraftSubmittedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Application Submitted Successfully");
      this.setState({
        buttonAction: false,
      });
    }
    this.setState({
      buttonAction: false,
    });
  };

  manageApplication = (values) => {
    var apis;
    this.props.location.pathname === "/notaryapplication"
      ? (apis = newapplication.Create)
      : this.props.location.pathname === "/areaextensionapplication"
      ? (apis = newapplication.CreateArea)
      : this.props.location.pathname === "/renewalapplication"
      ? (apis = newapplication.CreateRenewal)
      : this.props.location.pathname === "/duplicatecertificate"
      ? (apis = newapplication.CreateDuplicate)
      : (apis = newapplication.Create);
    console.log(values);
    CallService(
      apis,
      MethodType.PUT,
      false,
      values,
      "",
      this.manageApplicationResponse
    );
  };
  manageApplicationResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        `${this.state.CurrentApplication} Submitted Successfully`
      );
      this.setState({
        buttonAction: false,
      });
    } else {
      toastr.error("", "Something went wrong");
    }

    if (this.state.CurrentApplication === "Notary Application") {
      sessionStorage.setItem("Status", data.result ? data.result.Status : "");
      sessionStorage.setItem("Application", true);
      sessionStorage.setItem(
        "ApplicationId",
        data.result ? data.result._id : ""
      );
      window.location.href = "#/dashboard";
    } else if (
      this.state.CurrentApplication === "Practicing Area Extension Application"
    ) {
      sessionStorage.setItem(
        "AreaStatus",
        data.result ? data.result.Status : ""
      );
      sessionStorage.setItem(
        "AreaExtensionsApplicationID",
        data.result ? data.result._id : ""
      );
      window.location.href = "#/dashboard";
    }
    //    this.props.history.push("/Home");
  };
  toggleImageView(rowId) {
    if (rowId) {
      this.setState({
        imageViewModal: !this.state.imageViewModal,
        ImageViewId: rowId,
      });
    } else {
      this.setState({
        imageViewModal: !this.state.imageViewModal,
      });
    }
  }
  confirmMakingPayment = () => {
    this.setState({
      MakingPaymentModal: !this.state.MakingPaymentModal,
    });
  };

  handleMakingPayment() {
    CallService(
      areaExtensionApplication.GetMaking + this.state.AreaExtensionId,
      MethodType.GET,
      false,
      "",
      "",
      this.handleMakingPaymentResponse
    );
  }

  handleMakingPaymentResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Application process successully");
      sessionStorage.setItem(
        "AreaStatus",
        data.result ? data.result.Status : ""
      );
      sessionStorage.setItem("IsArchived", true);

      this.setState({
        MakingPaymentModal: false,
      });
      this.PaymentInitiate();
      window.location.href = "#/dashboard";
    } else toastr.error("", "Something went wrong");
  };

  togglePhotoViewModal(img, title) {
    if (img) {
      this.setState({
        DocumentView: img,
        DocumentViewModal: !this.state.DocumentViewModal,
        ImageGallery: img,
        Title: title,
      });
    } else {
      this.setState({
        DocumentViewModal: !this.state.DocumentViewModal,
      });
    }
  }
  properties = {
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: false,

    onChange: (oldIndex, newIndex) => {
      this.setState({
        currentImage: this.state.ImageGallery,
      });
    },
  };
  toggleFormModal = () => {
    this.setState({
      FormModal: !this.state.FormModal,
    });
  };

  getAllFormTypes() {
    CallService(
      formType.getAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.getAllFormTypesResponse
    );
  }

  getAllFormTypesResponse = (data) => {
    this.setState({
      formTypes: [{ value: data.result[0]._id, label: data.result[0].Name }],
      // formTypes: data.result.map(function (a) {
      //   return { value: a._id, label: a.Name };
      // }),
    });
  };
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Application"),
        },
        {
          id: 2,
          Name: this.props.t("Resubmission Documents"),
        },
      ],
    });
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  // DummyMakePayment = () => {
  //   CallService(
  //     dummyPayment.GetAll,
  //     MethodType.GET,
  //     false,
  //     "",
  //     "",
  //     this.DummyMakePaymentResponse
  //   );
  // };

  // DummyMakePaymentResponse = (data) => {
  //   if (data.result) {
  //     this.setState({
  //       InitiateResponse: data.result,
  //       PaymentModal: true,
  //     });
  //   }
  //   window.location("", "_blank")
  // };
  PaymentInitiate = () => {
    CallService(
      areaExtensionApplication.GetMaking,
      // dummyPayment.GetAll,
      MethodType.POST,
      false,
      { ApplicationId: this.state.AreaExtensionId },
      // "",
      "",
      this.PaymentInitiateResponse
    );
  };

  PaymentInitiateResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        InitiateResponse: data.result,
        MakingPaymentModal: true,
      });
    } else {
      toastr.error("", "Something went wrong");
    }
  };

  SubmitPayment = (message) => {
    let formData = new FormData();
    formData.append("msg", message);
    formData.append("isFromUrl", true);
    CallService(
      newapplication.GetPaymentResponse,
      MethodType.POST,
      false,
      formData,
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentStatus: data.result,
      });
    }
  };
  render() {
    //    const initialValues = this.state.Application;
    const initialApplication = this.state.Application;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <CardBody>
                <Row className="mt-4">
                  <Colxx xxs="8">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0 font-size-18">
                        {this.props.t(this.state.CurrentApplication)}

                        {/* {this.props.title} */}
                      </h4>
                    </div>
                  </Colxx>
                  <Colxx xxs="2">
                    {this.state.AreaStatus === "Payment Pending" ? (
                      sessionStorage.getItem("IsArchived") === "false" ? (
                        <Button
                          size="md"
                          type="button"
                          class="btn btn-secondary"
                          color="primary"
                          style={{ float: "right" }}
                          onClick={() => this.PaymentInitiate()}
                        >
                          {this.props.t("Make Payment")}
                        </Button>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Colxx>

                  <Colxx xxs="2">
                    <img
                      alt="noimg"
                      id="userimg"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        marginBottom: "10px",
                      }}
                      src={
                        this.state.Application
                          ? this.state.Application.Photo
                          : ""
                      }
                      onClick={() =>
                        this.togglePhotoViewModal(
                          this.state.Application.Photo,
                          "Photo"
                        )
                      }
                    />
                  </Colxx>
                </Row>
                {this.state.CurrentApplication ===
                "Practicing Area Extension Application" ? (
                  this.state.AreaStatus === "" ||
                  this.state.AreaStatus === "Approved" ||
                  this.state.AreaStatus === "Rejected" ? (
                    ""
                  ) : (
                    <Alert color="primary" style={{ textAlign: "center" }}>
                      {this.state.AreaStatus}
                    </Alert>
                  )
                ) : this.state.Status === "" ||
                  this.state.Status === "Approved" ? (
                  ""
                ) : this.state.Status === "Interview Cleared" ? (
                  <Alert color="success" style={{ textAlign: "center" }}>
                    {this.state.Status}
                  </Alert>
                ) : (
                  <Alert color="primary" style={{ textAlign: "center" }}>
                    {this.state.Status}
                  </Alert>
                )}
                {this.state.SRApplicationNumber && (
                  <Row>
                    <Colxx style={{ fontSize: "18px" }} xxs="6">
                      <Label>
                        {" "}
                        {this.props.t("Sub - Registration Number")}
                      </Label>
                      {" : "}
                      {this.state.SRApplicationNumber}
                    </Colxx>
                  </Row>
                )}
                {this.state.ApplicationNumber ? (
                  <Row>
                    <Colxx style={{ fontSize: "18px" }} xxs="6">
                      <Label> {this.props.t("Application Number")}</Label>
                      {" : "}
                      {this.state.ApplicationNumber}
                    </Colxx>
                    {this.state.Status === "Interview Call Letter Sent" ? (
                      this.state.CallLetter ? (
                        <Fragment>
                          <Colxx xxs="5">
                            <h5 style={{ float: "right" }}>
                              {this.props.t("Download Call Letter")}
                            </h5>
                          </Colxx>
                          <Colxx xxs="1">
                            <div>
                              <a href={this.state.CallLetter}>
                                <i
                                  className="bx bxs-download"
                                  style={{ fontSize: "25px" }}
                                />
                              </a>
                            </div>
                          </Colxx>
                        </Fragment>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}

                {this.state.Status === "Returned" ? (
                  <Nav
                    tabs
                    className="nav-tabs-custom
                  nav-justified"
                  >
                    {this.state.tabs.map((t) => {
                      return (
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={{
                              active: this.state.activeFirstTab === t.id,
                            }}
                            onClick={() => {
                              this.toggleCustomJustified(t.id);
                            }}
                          >
                            <span className="d-none d-sm-block">{t.Name}</span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                ) : this.state.Application &&
                  this.state.Application.AdditionalDocuments &&
                  this.state.Application.AdditionalDocuments.length > 0 ? (
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {this.state.tabs.map((t) => {
                      return (
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={{
                              active: this.state.activeFirstTab === t.id,
                            }}
                            onClick={() => {
                              this.toggleCustomJustified(t.id);
                            }}
                          >
                            <span className="d-none d-sm-block">{t.Name}</span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                ) : (
                  ""
                )}
                <br></br>

                <Formik
                  initialValues={initialApplication}
                  validationSchema={ApplicationFormValidation}
                  onSubmit={this.handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form
                      style={{ fontSize: "15px" }}
                      className="av-tooltip tooltip-label-bottom"
                    >
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={1}>
                          <Row className="mb-10">
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                {/* <Label>Name</Label> */}
                                <Label>{this.props.t("Name")}</Label>

                                <i className="bx bxs-ban"></i>
                                <Field
                                  style={{
                                    fontSize: "15px",
                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  className="FieldFontSize requiredField form-control"
                                  name="Name"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>
                                  {this.props.t(this.state.FatherText)}
                                </Label>

                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",
                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="FatherOrHusbandName"
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Date Of Birth")}</Label>

                                <DatePicker
                                  style={{
                                    fontSize: "15px",
                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  selected={this.state.startDate}
                                  className="cls-disabled form-control"
                                  value={this.state.Application.Dob}
                                  onChange={(date) => {
                                    this.setState({
                                      startDate: date,
                                    });
                                    values.Dob = new Date(date).getTime();
                                  }}
                                  placeholderText="dd/mm/yyyy"
                                  name="startDate"
                                  dateFormat="dd/MM/yyyy"
                                  required
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  label={this.props.t("Community")}
                                  isDisabled={true}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Community"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.Community &&
                                    (typeof values.Community === "string"
                                      ? this.state.Communities.find(
                                          (r) => r.value === values.Community
                                        )
                                      : this.state.Communities.find(
                                          (r) =>
                                            r.value === values.Community._id
                                        ))
                                  }
                                  options={this.state.Communities}
                                  ClearAction={() => {
                                    setFieldValue("Community", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Community",
                                      entity ? entity.value : null
                                    );
                                  }}
                                  errors={errors.Community}
                                />
                              </FormGroup>
                            </Colxx>
                            {/* <Colxx xxs="4">
                          <FormGroup className="form-group has-float-label">
                            <DropDown
                              isDisabled={true}
                              required
                              label="Gender"
                              components={{ Input: CustomSelectInput }}
                              className="react-select"
                              classNamePrefix="react-select"
                              name="Gender"
                              isSearchable
                              isClearable={true}
                              value={
                                values.Gender &&
                                (typeof values.Gender === "string"
                                  ? this.state.GenderList.find(
                                      (r) => r.value === values.Gender
                                    )
                                  : this.state.GenderList.find(
                                      (r) => r.value === values.Gender._id
                                    ))
                              }
                              options={this.state.GenderList}
                              ClearAction={() => {
                                setFieldValue("Gender", null);
                              }}
                              Action={(entity) => {
                                setFieldValue(
                                  "Gender",
                                  entity ? entity.value : null
                                );
                              }}
                            />
                          </FormGroup>
                        </Colxx> */}

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Mobile Number")}</Label>

                                <Field
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  type="MobileNumber"
                                  autoFocus
                                  className="form-control"
                                  name="MobileNumber"
                                />
                                {errors.MobileNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.MobileNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Fax")}</Label>

                                <Field
                                  style={{
                                    fontSize: "15px",
                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Fax"
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Email ID")}</Label>
                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="Email"
                                  autoFocus
                                  className="form-control"
                                  name="EmailID"
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("AADHAR Number")}</Label>

                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  className="form-control"
                                  name="Aadhar"
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("PAN Number")}</Label>
                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",
                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="PANNumber"
                                  // name={
                                  //   values.ProvisionAddress
                                  //     ? "ResidencyAddress.Address.Pincode"
                                  //     : "ProvisionalAddress.Address.Pincode"
                                  // }
                                  // placeholder="PANNumber"
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Enrolment Date")}</Label>
                                <DatePicker
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  disabled
                                  value={this.state.Application.EnrolmentDate}
                                  // selected={this.state.EnrolmentDate}
                                  className="cls-disabled form-control"
                                  name="EnrolmentDate"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>
                                  {this.props.t("Bar Council Enrolment Number")}
                                </Label>
                                <Field
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  type="text"
                                  className="form-control"
                                  name="BarCouncilNumber"
                                />
                                {errors.BarCouncilNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.BarCouncilNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>

                            {/* <Colxx xxs="4">
                          <FormGroup className="form-group has-float-label">
                            <Label>{this.props.t("Practicing Since")}</Label>
                            <DatePicker
                              style={{
                                fontSize: "15px",
                              }}
                              disabled
                              value={this.state.Application.PracticingSince}
                              //selected={this.state.PracticingSince}
                              className="cls-disabled form-control"
                              name="PracticingSince"
                              dateFormat="dd/MM/yyyy"
                            />
                          </FormGroup>
                        </Colxx> */}
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  isMulti="true"
                                  label={this.props.t("Practicing In")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="PracticingIn"
                                  isDisabled
                                  value={
                                    values &&
                                    values.PracticingIn &&
                                    this.state.PracticingInList.filter((a) => {
                                      return values.PracticingIn.some((r) => {
                                        return (
                                          a.value ===
                                          (typeof r === "object"
                                            ? r.value
                                              ? r.value
                                              : r.value
                                            : r)
                                        );
                                      });
                                    }).map(function (o) {
                                      return o;
                                    })
                                  }
                                  options={this.state.PracticingInList}
                                />
                              </FormGroup>
                            </Colxx>
                            {/* <Colxx xxs="4">
                          <FormGroup className="form-group has-float-label">
                            <br></br>
                            <Label style={{ marginTop: "20px" }}>
                              Income Tax Assessee
                            </Label>
                            &emsp;
                            <Field
                              disabled
                              type="checkbox"
                              autoFocus
                              name="IncomeTaxAssessee"
                            />
                          </FormGroup>
                        </Colxx> */}
                            <Colxx xxs="2">
                              <Label
                                style={{ float: "right", marginTop: "37px" }}
                              >
                                {this.props.t("Income Tax Assessee")}
                              </Label>
                            </Colxx>
                            <Colxx xxs="1">
                              <FormGroup className="form-group has-float-label">
                                <Field
                                  style={{
                                    marginTop: "41px",
                                    color: "gray",

                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  type="checkbox"
                                  autoFocus
                                  name="IncomeTaxAssessee"
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>

                          <Row>
                            <Colxx xxs="6">
                              <label>
                                <h5>{this.props.t("Residence Address")}</h5>
                              </label>
                            </Colxx>
                          </Row>

                          <Row>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 1")}</Label>

                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="ResidencyAddress.Address.Line1"
                                />

                                {errors.Line1 && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Line1}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 2")}</Label>

                                <Field
                                  type="text"
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  autoFocus
                                  disabled
                                  className="form-control"
                                  name="ResidencyAddress.Address.Line2"
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 3")}</Label>
                                <Field
                                  type="text"
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  disabled
                                  autoFocus
                                  className="form-control"
                                  name="ResidencyAddress.Address.Line3"
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  label={this.props.t("District")}
                                  components={{ Input: CustomSelectInput }}
                                  //    className="react-select"
                                  classNamePrefix="react-select"
                                  isSearchable
                                  isDisabled
                                  isClearable={true}
                                  value={
                                    values.ResidencyAddress.Address.District &&
                                    (typeof values.ResidencyAddress.Address
                                      .District === "string"
                                      ? this.state.DistrictList.find(
                                          (r) =>
                                            r.value ===
                                            values.ResidencyAddress.Address
                                              .District
                                        )
                                      : this.state.DistrictList.find(
                                          (r) =>
                                            r.value ===
                                            values.ResidencyAddress.Address
                                              .District._id
                                        ))
                                  }
                                  options={this.state.DistrictList}
                                  ClearAction={() => {
                                    setFieldValue(
                                      "ResidencyAddress.Address.District",
                                      null
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "ResidencyAddress.Address.District",
                                      entity ? entity.value : null
                                    );
                                    this.GetAllTaluk(
                                      entity ? entity.value : ""
                                    );
                                  }}
                                  errors={errors.ResidencyDistrict}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  label={this.props.t("Taluk")}
                                  components={{ Input: CustomSelectInput }}
                                  //    className="react-select"
                                  classNamePrefix="react-select"
                                  // isDisabled
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.ResidencyAddress &&
                                    values.ResidencyAddress.Address &&
                                    values.ResidencyAddress.Address.Taluk &&
                                    (typeof values.ResidencyAddress.Address
                                      .Taluk === "string"
                                      ? this.state.TalukList.find(
                                          (r) =>
                                            r.value ===
                                            values.ResidencyAddress.Address
                                              .Taluk
                                        )
                                      : this.state.TalukList.find(
                                          (r) =>
                                            r.value ===
                                            values.ResidencyAddress.Address
                                              .Taluk._id
                                        ))
                                  }
                                  options={this.state.TalukList}
                                  ClearAction={() => {
                                    setFieldValue(
                                      "ResidencyAddress.Address.Taluk",
                                      null
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "ResidencyAddress.Address.Taluk",
                                      entity ? entity.value : null
                                    );
                                  }}
                                  errors={errors.ResidencyDistrict}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Pincode")}</Label>

                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="ResidencyAddress.Address.Pincode"
                                  placeholder="Pincode"
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="6">
                              <div>
                                <label>
                                  <h5>{this.props.t("Official Address")}</h5>
                                </label>
                              </div>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 1")}</Label>
                                <Field
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="ProvisionalAddress.Address.Line1"
                                  disabled

                                  // name={
                                  //   values.ProvisionAddress
                                  //     ? "ResidencyAddress.Address.Line1"
                                  //     : "ProvisionalAddress.Address.Line1"
                                  // }
                                />
                                {errors.ProvisionLine1 && (
                                  <div className="invalid-feedback d-block">
                                    {errors.ProvisionLine1}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 2")}</Label>
                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="ProvisionalAddress.Address.Line2"

                                  // name={
                                  //   values.ProvisionAddress
                                  //     ? "ResidencyAddress.Address.Line2"
                                  //     : "ProvisionalAddress.Address.Line2"
                                  // }
                                />
                                {errors.ProvisionLine2 && (
                                  <div className="invalid-feedback d-block">
                                    {errors.ProvisionLine2}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 3")}</Label>
                                <Field
                                  disabled
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="ProvisionalAddress.Address.Line3"

                                  // name={
                                  //   values.ProvisionAddress
                                  //     ? "ResidencyAddress.Address.Line3"
                                  //     : "ProvisionalAddress.Address.Line3"
                                  // }
                                />
                                {errors.Line3 && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Line3}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>

                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  label={this.props.t("District")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="ResidencyDistrict"
                                  isSearchable
                                  isClearable={true}
                                  isDisabled
                                  value={
                                    values.ProvisionalAddress &&
                                    values.ProvisionalAddress.Address
                                      .District &&
                                    (typeof values.ProvisionalAddress.Address
                                      .District === "string"
                                      ? this.state.DistrictList.find(
                                          (r) =>
                                            r.value ===
                                            values.ProvisionalAddress.Address
                                              .District
                                        )
                                      : this.state.DistrictList.find(
                                          (r) =>
                                            r.value ===
                                            values.ProvisionalAddress.Address
                                              .District._id
                                        ))
                                  }
                                  options={this.state.DistrictList}
                                  ClearAction={() => {
                                    setFieldValue(
                                      "ProvisionalAddress.Address.District",
                                      null
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "ProvisionalAddress.Address.District",
                                      entity ? entity.value : ""
                                    );
                                    this.GetAllTaluk(
                                      entity ? entity.value : ""
                                    );
                                  }}
                                  errors={errors.ResidencyDistrict}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  label={this.props.t("Taluk")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  // isDisabled
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.ProvisionalAddress &&
                                    values.ProvisionalAddress.Address &&
                                    values.ProvisionalAddress.Address.Taluk &&
                                    (typeof values.ProvisionalAddress.Address
                                      .Taluk === "string"
                                      ? this.state.TalukList.find(
                                          (r) =>
                                            r.value ===
                                            values.ProvisionalAddress.Address
                                              .Taluk
                                        )
                                      : this.state.TalukList.find(
                                          (r) =>
                                            r.value ===
                                            values.ProvisionalAddress.Address
                                              .Taluk._id
                                        ))
                                  }
                                  options={this.state.TalukList}
                                  ClearAction={() => {
                                    setFieldValue(
                                      "ProvisionalAddress.Address.Taluk",
                                      null
                                    );
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "ProvisionalAddress.Address.Taluk",
                                      entity ? entity.value : ""
                                    );
                                  }}
                                  errors={errors.ResidencyDistrict}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="4">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Pincode")}</Label>
                                <Field
                                  disabled
                                  type="text"
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  autoFocus
                                  className="form-control"
                                  name="ProvisionalAddress.Address.Pincode"
                                  // name={
                                  //   values.ProvisionAddress
                                  //     ? "ResidencyAddress.Address.Pincode"
                                  //     : "ProvisionalAddress.Address.Pincode"
                                  // }
                                  placeholder="Pincode"
                                />
                                {/* {errors.ResidencyPincode && (
                                <div className="invalid-feedback d-block">
                                  {errors.ResidencyPincode}
                                </div>
                              )} */}
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            {this.state.Application
                              ? this.state.Application.Degrees
                                ? this.state.Application.Degrees.length > 0
                                  ? this.state.Application.Degrees.map(
                                      (val, Index) => {
                                        return (
                                          <Fragment>
                                            <Colxx xxs="3">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t("Institution")}
                                                </Label>
                                                <Field
                                                  disabled
                                                  style={{
                                                    color: "gray",
                                                    fontSize: "15px",

                                                    backgroundColor: "#F0F0F0",
                                                  }}
                                                  type="text"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].Institution`}
                                                />
                                                {errors.Institution && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Institution}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="2">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t("Degree")}
                                                </Label>
                                                <Field
                                                  disabled
                                                  style={{
                                                    fontSize: "15px",

                                                    color: "gray",
                                                    backgroundColor: "#F0F0F0",
                                                  }}
                                                  type="text"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].Name`}
                                                />
                                                {errors.Degree && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Degree}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="4">
                                              <FormGroup className="form-group has-float-label">
                                                {this.state.Application
                                                  .IsIntegratedDegree ? (
                                                  <Label>
                                                    {this.props.t(
                                                      "Copy Of Law Degree (Self Attested)"
                                                    )}
                                                  </Label>
                                                ) : (
                                                  <Label>
                                                    {this.props.t(
                                                      "Copy Of Graduation Degree (Self Attested)"
                                                    )}
                                                  </Label>
                                                )}
                                                <CustomInput
                                                  disabled
                                                  className="cls-disabled"
                                                  key={"Url"}
                                                  type="file"
                                                  name={"Url"}
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      `Degrees[${Index}].Url`,
                                                      event.target.files[0]
                                                    );
                                                  }}
                                                />
                                                <p className="text-semi-muted">
                                                  {getAllowedExtensionText(
                                                    "pdf",
                                                    [
                                                      "png",
                                                      "doc",
                                                      "pdf",
                                                      "jpg",
                                                      "jpeg",
                                                    ]
                                                  )}
                                                  {this.state.Application &&
                                                  this.state.Application
                                                    .Degrees &&
                                                  this.state.Application
                                                    .Degrees[Index].Url ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state.Application
                                                            .Degrees[Index].Url
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p>

                                                {/* <p className="text-semi-muted">
                                              Allowed formats are png, doc, pdf,
                                              jpg and jpeg
                                              {this.state.Application &&
                                              this.state.Application.Degrees &&
                                              this.state.Application.Degrees[
                                                Index
                                              ].Url ? (
                                                <p
                                                  style={{
                                                    cursor: "Pointer",
                                                    color: "blue",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <FileDisplay
                                                    Value={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  /> */}
                                                {/* <span
                                                    onClick={() =>
                                                      this.toggleImageView(
                                                        this.state.Application
                                                          .Degrees[Index].Url
                                                      )
                                                    }
                                                  >
                                                    View |{" "}
                                                  </span>
                                                  <a
                                                    className="LinkStyle"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      this.state.Application
                                                        .Degrees[Index].Url
                                                    }
                                                  >
                                                    Download{" "}
                                                  </a> */}
                                                {/* </p>
                                              ) : (
                                                ""
                                              )}
                                            </p> */}

                                                {errors.Url && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.Url}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>
                                            <Colxx xxs="3">
                                              <FormGroup className="form-group has-float-label">
                                                <Label>
                                                  {this.props.t(
                                                    "Year Of Passing"
                                                  )}
                                                </Label>
                                                <Field
                                                  style={{
                                                    color: "gray",
                                                    fontSize: "15px",

                                                    backgroundColor: "#F0F0F0",
                                                  }}
                                                  disabled
                                                  type="number"
                                                  autoFocus
                                                  className="form-control"
                                                  name={`Degrees[${Index}].YearOfPassing`}
                                                />
                                                {errors.YearOfPassing && (
                                                  <div className="invalid-feedback d-block">
                                                    {errors.YearOfPassing}
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Colxx>

                                            {/* <div
                                          style={{
                                            marginTop: "33px",
                                          }}
                                        >
                                          {this.state.Application.Degrees
                                            .length > 1 && (
                                            <i
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                              className="bx bx-minus-circle cursorPointer"
                                              onClick={() => {
                                                var selectedProduct = this.state
                                                  .Application.Degrees;

                                                selectedProduct.splice(
                                                  Index,
                                                  1
                                                );
                                                this.setState({
                                                  selectedProduct: selectedProduct,
                                                });
                                              }}
                                            />
                                          )}
                                          &nbsp;&nbsp;&nbsp; 
                                    {this.state.Application.Degrees
                                            .length ===
                                            Index + 1 && (
                                            <i
                                              className="bx bx-plus-circle cursorPointer"
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                var selectedProduct = this.state
                                                  .Application.Degrees;
                                                selectedProduct.push({
                                                  Institution: "",
                                                  Name: "",
                                                  Url: "",
                                                  YearOfPassing: "",
                                                });
                                                this.setState({
                                                  selectedProduct: selectedProduct,
                                                });
                                              }}
                                            />
                                          )} 
                                        </div>*/}
                                          </Fragment>
                                        );
                                      }
                                    )
                                  : ""
                                : ""
                              : ""}
                            <Colxx xxs="12">
                              <h5>{this.props.t("Notary Application")}</h5>
                            </Colxx>
                            <Colxx xxs="6">
                              {this.state.CurrentApplication ===
                              "Practicing Area Extension Application" ? (
                                ""
                              ) : (
                                <ReactTooltip />
                              )}
                              <FormGroup
                                data-tip={this.props.t(
                                  "Same district as residential district"
                                )}
                                className="form-group has-float-label"
                              >
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  labelClassName="requiredField"
                                  isDisabled={
                                    this.state.CurrentApplication ===
                                    "Practicing Area Extension Application"
                                      ? this.state.AreaStatus === "" ||
                                        this.state.AreaStatus === "Approved"
                                        ? false
                                        : true
                                      : true
                                  }
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="District"
                                  label={this.props.t("District")}
                                  isSearchable
                                  options={this.state.DistrictList}
                                  value={
                                    this.state.CurrentApplication !==
                                    "Practicing Area Extension Application"
                                      ? values.ResidencyAddress &&
                                        values.ResidencyAddress.Address &&
                                        values.ResidencyAddress.Address
                                          .District &&
                                        (typeof values.ResidencyAddress.Address
                                          .District === "string"
                                          ? this.state.DistrictList.find(
                                              (r) =>
                                                r.value ===
                                                values.ResidencyAddress.Address
                                                  .District
                                            )
                                          : this.state.DistrictList.find(
                                              (r) =>
                                                r.value ===
                                                values.ResidencyAddress.Address
                                                  .District._id
                                            ))
                                      : values &&
                                        values.District &&
                                        (typeof values.District === "string"
                                          ? this.state.DistrictList.find(
                                              (r) => r.value === values.District
                                            )
                                          : this.state.DistrictList.find(
                                              (r) =>
                                                r.value === values.District._id
                                            ))
                                  }
                                  ClearAction={() => {
                                    setFieldValue("District", null);
                                    this.GetAllTaluk(null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "District",
                                      entity ? entity.value : ""
                                    );

                                    this.GetAllTaluk(
                                      entity ? entity.value : null
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  isDisabled={
                                    this.state.CurrentApplication ===
                                    "Practicing Area Extension Application"
                                      ? this.state.AreaStatus === "" ||
                                        this.state.AreaStatus === "Approved" ||
                                        this.state.AreaStatus === "Rejected"
                                        ? false
                                        : true
                                      : this.state.IsApplication === "true"
                                      ? this.state.Status === "Approved"
                                        ? false
                                        : true
                                      : false
                                  }
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Taluk"
                                  label={this.props.t("Taluks")}
                                  isMulti
                                  isSearchable
                                  options={this.state.SliceTaluks}
                                  value={
                                    values &&
                                    values.Taluks &&
                                    this.state.TalukList.filter((a) => {
                                      return values.Taluks.some((r) => {
                                        return (
                                          a.value ===
                                          (typeof r === "object"
                                            ? r._id
                                              ? r._id
                                              : r.value
                                            : r)
                                        );
                                      });
                                    }).map(function (o) {
                                      return o;
                                    })
                                  }
                                  ClearAction={() => {
                                    setFieldValue("Taluks", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Taluks",
                                      entity ? entity : ""
                                    );
                                  }}
                                />
                                {errors.Taluk && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Taluk}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>

                            {/* <Colxx xxs="6">
                          <FormGroup className="form-group has-float-label">
                            <Label>Covering Letter</Label>
                            <CustomInput
                              key={"CoveringLetter"}
                              type="file"
                              name={"CoveringLetter"}
                              onChange={(event) => {
                                setFieldValue(
                                  "CoveringLetter",
                                  event.target.files[0]
                                );
                              }}
                            />
                            <p className="text-semi-muted">
                              Allowed formats are png, doc, pdf, jpg and jpeg
                            </p>
                            {errors.CoveringLetter && (
                              <div className="invalid-feedback d-block">
                                {errors.CoveringLetter}
                              </div>
                            )}
                          </FormGroup>
                        </Colxx> */}
                            {this.state.CurrentApplication ===
                            "Practicing Area Extension Application" ? (
                              <Colxx xxs="12">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="requiredField">
                                    {this.props.t("Reason")}
                                  </Label>
                                  <Field
                                    style={{
                                      fontSize: "15px",
                                    }}
                                    component="textarea"
                                    className="form-control"
                                    name="Reason"
                                    disabled={
                                      this.state.AreaStatus === "" ||
                                      this.state.AreaStatus === "Approved"
                                        ? false
                                        : true
                                    }
                                    onKeyPress={(event) => {
                                      if (INVALID_CHARS.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      event.target.value =
                                        event.target.value &&
                                        event.target.value.replace(
                                          INVALID_CHARS_REGEX,
                                          ""
                                        );
                                      setFieldValue(
                                        "Reason",
                                        event.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Colxx>
                            ) : (
                              ""
                            )}
                            <Colxx xxs="12">
                              {/* <FormGroup className="form-group has-float-label">
                            <Label>{this.props.t("Remarks")}</Label>

                            {this.state.IsApplication === "true" ? (
                              this.state.Status === "Approved" ? (
                                <Field
                                  style={{
                                    fontSize: "15px",
                                  }}
                                  component="textarea"
                                  className="form-control"
                                  name="Remarks"
                                  maxLength="225"
                                  rows="3"
                                />
                              ) : (
                                <Field
                                  component="textarea"
                                  className="form-control"
                                  name="Remarks"
                                  maxLength="225"
                                  style={{
                                    fontSize: "15px",

                                    color: "gray",
                                    backgroundColor: "#F0F0F0",
                                  }}
                                  rows="3"
                                  disabled
                                />
                              )
                            ) : (
                              <Field
                                component="textarea"
                                className="form-control"
                                name="Remarks"
                                maxLength="225"
                                rows="3"
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                            )}
                          </FormGroup> */}
                              {this.state.CurrentApplication ===
                              "Practicing Area Extension Application" ? (
                                <p>
                                  (
                                  <strong>
                                    {this.props.t("Practicing Taluks")} :
                                  </strong>{" "}
                                  {/* {this.state.PracticingTaluks &&
                                this.state.PracticingTaluks.Taluks.map((ET) => {
                                  return ET.label
                                    ? ET.label
                                    : this.state.Language === "ta"
                                    ? ET.TamilName
                                    : ET.Name;
                                }).join(", ")} */}
                                  {this.state.TotalTaluks
                                    ? this.state.TotalTaluks.length >= 0
                                      ? this.state.TotalTaluks.map((TA) => {
                                          let filterTaluk = TA.Taluks.filter(
                                            (ele, ind) =>
                                              ind ===
                                              TA.Taluks.findIndex(
                                                (element) =>
                                                  element._id === ele._id
                                              )
                                          );
                                          return filterTaluk
                                            .map((taluk) => {
                                              return this.state.Language ===
                                                "ta"
                                                ? taluk.TamilName
                                                : taluk.Name;
                                            })
                                            .join(", ");
                                        }).join(", ")
                                      : "-"
                                    : "-"}
                                  )
                                </p>
                              ) : (
                                ""
                              )}
                            </Colxx>
                            {this.state.Application.FormType ===
                            FORMTYPES.Form1 ? (
                              this.state.CurrentApplication ===
                              "Notary Application" ? (
                                <Colxx>
                                  <p>
                                    The application of (name of the applicant in
                                    block letters) Showeth &nbsp;
                                    <span
                                      className={
                                        this.state.Application &&
                                        this.state.Application
                                          .DeclarationFields &&
                                        this.state.Application.DeclarationFields
                                          .Name
                                          ? "text-underline"
                                          : ""
                                      }
                                    >
                                      {this.state.Application &&
                                        this.state.Application
                                          .DeclarationFields &&
                                        this.state.Application.DeclarationFields.Name.toUpperCase()}
                                    </span>
                                    {/* <Field
                                      disabled={
                                        this.state.CurrentApplication ===
                                        "Practicing Area Extension Application"
                                          ? this.state.AreaStatus === "" ||
                                            this.state.AreaStatus ===
                                              "Approved" ||
                                            this.state.AreaStatus === "Rejected"
                                            ? false
                                            : true
                                          : this.state.IsApplication === "true"
                                          ? this.state.Status === "Approved"
                                            ? false
                                            : true
                                          : false
                                      }
                                      //  className="form-control"
                                      name={`DeclarationFields.Name`}
                                      type="text"
                                    /> */}
                                  </p>
                                  <p>
                                    1. That the applicant is a person eligible
                                    for appointment as a notary underthe
                                    Notaries Act, 1952, and clause (a) of rule 3
                                    of the Notaries Rules,1956; <br></br> 2.
                                    That the applicant practices as an Advocate{" "}
                                    <Field
                                      //className="form-control"
                                      name={`DeclarationFields.AreaOfPracticeAsAnAdvocate`}
                                      type="text"
                                      disabled={
                                        this.state.CurrentApplication ===
                                        "Practicing Area Extension Application"
                                          ? this.state.AreaStatus === "" ||
                                            this.state.AreaStatus ===
                                              "Approved" ||
                                            this.state.AreaStatus === "Rejected"
                                            ? false
                                            : true
                                          : this.state.IsApplication === "true"
                                          ? this.state.Status === "Approved"
                                            ? false
                                            : true
                                          : false
                                      }
                                      onKeyPress={(event) => {
                                        if (INVALID_CHARS.includes(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={(event) => {
                                        event.target.value =
                                          event.target.value &&
                                          event.target.value.replace(
                                            INVALID_CHARS_REGEX,
                                            ""
                                          );
                                        setFieldValue(
                                          "DeclarationFields.AreaOfPracticeAsAnAdvocate",
                                          event.target.value
                                        );
                                      }}
                                    />
                                    &nbsp; (herein state the name of the local
                                    area and name of court where he intends
                                    topractice as an Advocate) <br></br> 3. That
                                    the number of notaries practicing in the
                                    local area is insufficient for the
                                    requirements thereof (Statement to be added
                                    stating grounds for Requirement of more
                                    Notaries) {/* <Colxx xxs="3"> */}
                                    <Field
                                      disabled={
                                        this.state.CurrentApplication ===
                                        "Practicing Area Extension Application"
                                          ? this.state.AreaStatus === "" ||
                                            this.state.AreaStatus ===
                                              "Approved" ||
                                            this.state.AreaStatus === "Rejected"
                                            ? false
                                            : true
                                          : this.state.IsApplication === "true"
                                          ? this.state.Status === "Approved"
                                            ? false
                                            : true
                                          : false
                                      }
                                      onKeyPress={(event) => {
                                        if (INVALID_CHARS.includes(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={(event) => {
                                        event.target.value =
                                          event.target.value &&
                                          event.target.value.replace(
                                            INVALID_CHARS_REGEX,
                                            ""
                                          );
                                        setFieldValue(
                                          "DeclarationFields.Requirements",
                                          event.target.value
                                        );
                                      }}
                                      className="form-control"
                                      name={`DeclarationFields.Requirements`}
                                      as="textarea"
                                    />
                                    {/* </Colxx> */}
                                    {/* <Colxx xxs="3">
                                <Field
                                  className="form-control"
                                  name={`DeclarationFields.Requirements`}
                                  type="text"
                                />
                              </Colxx> */}
                                    <br></br> 4. That no previous application of
                                    the applicant has been rejected orWithdrawn
                                    by him, within the preceding six months;
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    The applicant, therefore, prays that the
                                    Government be pleased to appointand admit
                                    him as a notary under and by virtue of the
                                    Notaries Act, 1952 (53 of1952), and clause
                                    (a) of rule 3 of the Notaries Rules, 1956,
                                    to practice in {/* <Colxx xxs="3"> */}
                                    <Field
                                      disabled={
                                        this.state.CurrentApplication ===
                                        "Practicing Area Extension Application"
                                          ? this.state.AreaStatus === "" ||
                                            this.state.AreaStatus ===
                                              "Approved" ||
                                            this.state.AreaStatus === "Rejected"
                                            ? false
                                            : true
                                          : this.state.IsApplication === "true"
                                          ? this.state.Status === "Approved"
                                            ? false
                                            : true
                                          : false
                                      }
                                      onKeyPress={(event) => {
                                        if (INVALID_CHARS.includes(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={(event) => {
                                        event.target.value =
                                          event.target.value &&
                                          event.target.value.replace(
                                            INVALID_CHARS_REGEX,
                                            ""
                                          );
                                        setFieldValue(
                                          "DeclarationFields.IntendedAreaOfPractice",
                                          event.target.value
                                        );
                                      }}
                                      className="requiredField"
                                      name={`DeclarationFields.IntendedAreaOfPractice`}
                                      type="text"
                                    />
                                    {/* </Colxx> */}
                                    &nbsp;(Mention here the name of the local
                                    area where he/she intendsto practice as
                                    Notary). <br></br>
                                    <br></br>
                                    <span>
                                      Dated{" "}
                                      <span className="text-underline">
                                        {" "}
                                        {moment(new Date()).format("DD")}{" "}
                                      </span>{" "}
                                      day of{" "}
                                      <span className="text-underline">
                                        {" "}
                                        {getMonthName(
                                          new Date().getMonth()
                                        )}{" "}
                                      </span>{" "}
                                      <span className="text-underline">
                                        {" "}
                                        {new Date().getFullYear()}{" "}
                                      </span>
                                    </span>
                                  </p>
                                </Colxx>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </Row>
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={this.state.activeFirstTab}>
                        <TabPane tabId={2}>
                          {/* {this.state.Status === "Returned" ? ( */}
                          <div>
                            (1){" "}
                            {/* {this.state.Application &&
                          this.state.Application.ActionTakenName}{" "} */}
                            Priniciple District judge,{" "}
                            {/* {JSON.stringify(
                              this.state.Application &&
                                this.state.Application.DistrictLabel
                            )} */}
                            {this.state.Application &&
                              this.state.Application &&
                              this.state.Application.DistrictLabel}
                            , has returned your application with the following
                            comments:
                            <br />{" "}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              {" "}
                              (2){" "}
                              <strong>
                                {this.state.Application &&
                                this.state.Application.Comments?.includes("<p>")
                                  ? ReactHtmlParser(
                                      this.state.Application.Comments
                                    )
                                  : this.state.Application.Comments}
                              </strong>
                              Please upload required documents and resubmit your
                              application.
                            </div>
                          </div>
                          <br />
                          <Row>
                            {this.state.AdditionalDocuments
                              ? this.state.AdditionalDocuments.length > 0
                                ? this.state.AdditionalDocuments.map(
                                    (val, Index) => {
                                      return (
                                        <Fragment>
                                          <Colxx xxs="5">
                                            <FormGroup className="form-group has-float-label">
                                              <Label className="requiredField">
                                                {this.props.t("File Name")}
                                              </Label>
                                              <Field
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                autoFocus
                                                className="form-control"
                                                name={`AdditionalDocuments[${Index}].Name`}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `AdditionalDocuments[${Index}].Name`,
                                                    e.target.value
                                                  );
                                                  // values.AdditionalDocuments =
                                                  //   e.target.value;
                                                }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    INVALID_CHARS.includes(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onBlur={(event) => {
                                                  event.target.value =
                                                    event.target.value &&
                                                    event.target.value.replace(
                                                      INVALID_CHARS_REGEX,
                                                      ""
                                                    );
                                                  setFieldValue(
                                                    `AdditionalDocuments[${Index}].Name`,
                                                    event.target.value
                                                  );
                                                }}
                                              />

                                              {errors.AdditionalDocuments && (
                                                <div className="invalid-feedback d-block">
                                                  {errors.AdditionalDocuments}
                                                </div>
                                              )}
                                            </FormGroup>
                                          </Colxx>
                                          <Colxx xxs="5">
                                            <FormGroup className="form-group has-float-label">
                                              <Label className="requiredField">
                                                {this.props.t("File")}
                                              </Label>
                                              <CustomInput
                                                key={"Url"}
                                                type="file"
                                                name={"Url"}
                                                onChange={(event) => {
                                                  if (
                                                    event.target.files[0].name.includes(
                                                      ".pdf"
                                                    )
                                                  ) {
                                                    setFieldValue(
                                                      `AdditionalDocuments[${Index}].Url`,
                                                      event.target.files[0]
                                                    );
                                                  } else {
                                                    toastr.error(
                                                      "",
                                                      "Invalid file format"
                                                    );
                                                  }
                                                  console.log(
                                                    "event",
                                                    event.target.files[0],
                                                    event.target
                                                  );
                                                  // values.Url =
                                                  //   event.target.files[0];
                                                }}
                                              />
                                              <p className="text-semi-muted">
                                                {getFileSizeText(5, "MB")}

                                                <p className="text-semi-muted">
                                                  {getAllowedExtensionText(
                                                    "pdf",
                                                    [
                                                      "png",
                                                      "doc",
                                                      "pdf",
                                                      "jpg",
                                                      "jpeg",
                                                    ]
                                                  )}
                                                  {this.state.Application &&
                                                  this.state.Application
                                                    .AdditionalDocuments &&
                                                  this.state.Application
                                                    .AdditionalDocuments
                                                    .length > 0 ? (
                                                    <p
                                                      style={{
                                                        color: "blue",
                                                        cursor: "Pointer",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <FileDisplay
                                                        Value={
                                                          this.state.Application
                                                            .AdditionalDocuments[
                                                            Index
                                                          ].Url
                                                        }
                                                      />
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p>
                                              </p>

                                              {errors.Url && (
                                                <div className="invalid-feedback d-block">
                                                  {errors.Url}
                                                </div>
                                              )}
                                            </FormGroup>
                                          </Colxx>
                                          <div
                                            style={{
                                              marginTop: "33px",
                                            }}
                                          >
                                            {this.state.AdditionalDocuments
                                              .length > 1 && (
                                              <i
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                className="bx bx-minus-circle cursorPointer"
                                                onClick={() => {
                                                  var AdditionalDocuments =
                                                    this.state
                                                      .AdditionalDocuments;

                                                  AdditionalDocuments.splice(
                                                    Index,
                                                    1
                                                  );
                                                  this.setState({
                                                    AdditionalDocuments:
                                                      AdditionalDocuments,
                                                  });
                                                }}
                                              />
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            {this.state.AdditionalDocuments
                                              .length ===
                                              Index + 1 && (
                                              <i
                                                className="bx bx-plus-circle cursorPointer"
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  var AdditionalDocuments =
                                                    this.state
                                                      .AdditionalDocuments;
                                                  AdditionalDocuments.push({
                                                    Name: "",
                                                    Url: "",
                                                  });
                                                  this.setState({
                                                    AdditionalDocuments:
                                                      AdditionalDocuments,
                                                  });
                                                }}
                                              />
                                            )}
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  )
                                : ""
                              : ""}
                          </Row>
                          {/* ) : (
                            ""
                          )} */}
                        </TabPane>
                      </TabContent>
                      <Modal
                        size={"xl"}
                        isOpen={this.state.FormModal}
                        toggle={this.toggleFormModal}
                      >
                        <ModalHeader toggle={this.toggleFormModal}>
                          View
                        </ModalHeader>

                        <ModalBody>
                          {/* {this.state.Application &&
                            (this.state.formTypes &&
                            this.state.formTypes.find(
                              (f) => f.value === this.state.Application.FormType
                            ) === FORMTYPES.Form1 ? (
                              <Form1 Application={this.state.Application} />
                            ) : (
                              <Form2 Application={this.state.Application} />
                            ))} */}

                          {this.state.Application.FormType ===
                          FORMTYPES.Form1 ? (
                            <Form1 Application={this.state.ApplicationDetail} />
                          ) : (
                            <Form2 Application={this.state.ApplicationDetail} />
                          )}
                        </ModalBody>

                        <ModalFooter>
                          <FormGroup className="float-sm-right ">
                            <Button
                              color="danger"
                              className="form-second-btn"
                              onClick={this.toggleFormModal}
                            >
                              {this.props.t("Close")}
                            </Button>
                          </FormGroup>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        isOpen={this.state.imageViewModal}
                        toggle={this.toggleImageView}
                      >
                        <ModalHeader toggle={this.toggleImageView}>
                          {this.props.t("View")}
                        </ModalHeader>

                        <ModalBody>
                          <Fragment>
                            <Row className="mb-4">
                              <img
                                alt="docView"
                                src={this.state.ImageViewId}
                                title="documentview"
                                className="Img-Height"
                                width="100%"
                                style={{ objectFit: "contain" }}
                              />
                            </Row>
                            <Row>
                              <Fragment>
                                <Colxx style={{ textAlign: "right" }}>
                                  <a
                                    className="LinkStyle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={this.state.ImageViewId}
                                  >
                                    {this.props.t("Download")}
                                  </a>
                                </Colxx>
                              </Fragment>
                            </Row>
                          </Fragment>
                        </ModalBody>

                        <ModalFooter>
                          <FormGroup className="float-sm-right ">
                            <Button
                              color="danger"
                              className="form-second-btn"
                              onClick={() => this.toggleImageView("")}
                            >
                              {this.props.t("Close")}
                            </Button>
                          </FormGroup>
                        </ModalFooter>
                      </Modal>

                      <FormGroup className="float-sm-right ">
                        {this.state.CurrentApplication ===
                        "Practicing Area Extension Application" ? (
                          this.state.AreaStatus === "" ||
                          this.state.AreaStatus === "Approved" ||
                          this.state.AreaStatus === "Rejected" ? (
                            <Button
                              className={
                                this.state.buttonAction
                                  ? "disabled ml-2"
                                  : "ml-2"
                              }
                              type="submit"
                              outline
                              color="success"
                              onClick={(e) => {
                                setFieldValue("isSubmitted", true);
                              }}
                            >
                              {this.props.t("Submit")}
                            </Button>
                          ) : (
                            ""
                          )
                        ) : this.state.IsApplication === "true" ? (
                          this.state.Status === "Approved" ? (
                            <Button
                              className={
                                this.state.buttonAction
                                  ? "disabled ml-2"
                                  : "ml-2"
                              }
                              type="submit"
                              outline
                              color="success"
                              onClick={(e) => {
                                setFieldValue("isSubmitted", true);
                              }}
                            >
                              {" "}
                              {this.props.t("Submit")}
                            </Button>
                          ) : (
                            ""
                          )
                        ) : (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled ml-2" : "ml-2"
                            }
                            type="submit"
                            outline
                            color="success"
                            onClick={(e) => {
                              setFieldValue("isSubmitted", true);
                            }}
                          >
                            {this.props.t("Submit")}
                          </Button>
                        )}

                        {this.state.Status === "Returned" ? (
                          this.state.activeFirstTab === 2 ? (
                            <Button
                              style={{ float: "right" }}
                              color="success"
                              className="ml-2"
                              type="submit"
                            >
                              {this.props.t("Resubmit")}{" "}
                            </Button>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Form>
                  )}
                </Formik>

                {this.state.Status !== "" ? (
                  this.state.CurrentApplication === "Notary Application" ? (
                    <Button
                      color="primary"
                      className="ml-2"
                      onClick={() => this.toggleFormModal()}
                    >
                      {this.props.t("View Submitted Form")}
                    </Button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* <Link to="/Dashboard">
                  {" "}
                  <Button
                    style={{ float: "right" }}
                    color="danger"
                    className="ml-2"
                  >
                    Back
                  </Button>
                </Link> */}
              </CardBody>
            </Card>
            <Modal
              isOpen={this.state.MakingPaymentModal}
              toggle={() => this.confirmMakingPayment()}
            >
              <ModalHeader toggle={() => this.confirmMakingPayment()}>
                {this.props.t("Confirm Payment Applications")}
              </ModalHeader>

              {/* <AvForm onSubmit={() => this.handleMakingPayment()}> */}
              {/* <AvForm onSubmit={() => this.SubmitPayment()}> */}
              <ModalBody>
                <Fragment>
                  <Row className="mb-4">
                    <Label className="av-label ml-3">
                      {this.state.InitiateResponse &&
                      this.state.InitiateResponse.pmtData &&
                      this.state.InitiateResponse.pmtData.transactionAmount !==
                        "null" ? (
                        <h5 className="pr-2 pt-2">
                          Are you sure you want to make payment of Rs.
                          {this.state.InitiateResponse &&
                            this.state.InitiateResponse.pmtData &&
                            this.state.InitiateResponse.pmtData
                              .transactionAmount}
                        </h5>
                      ) : (
                        <h5 className="pr-2 pt-2">
                          Are you sure you want to make payment ?
                        </h5>
                      )}
                    </Label>
                  </Row>
                </Fragment>
              </ModalBody>
              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  {/* <Button type="submit" outline color="primary">
                       {this.props.t("Confirm")}
                    </Button>*/}
                  <PaymentForm
                    SubmitPayment={this.SubmitPayment}
                    InitiateResponse={this.state.InitiateResponse}
                  />

                  {/* <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.confirmMakingPayment()}
                    >
                     {this.props.t("Cancel")}
                    </Button> */}
                </FormGroup>
              </ModalFooter>
              {/* </AvForm> */}
            </Modal>

            <Modal
              className="viewDocumentModal"
              isOpen={this.state.DocumentViewModal}
              size="md"
              toggle={this.togglePhotoViewModal}
            >
              <ModalHeader toggle={this.togglePhotoViewModal}>
                <b>{this.state.Title}</b>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12">
                    <Fragment>
                      <div className="each-slide">
                        <img
                          alt="noimg"
                          id="userimg"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          src={this.state.ImageGallery}
                        />
                      </div>
                    </Fragment>
                  </Colxx>
                </Row>
              </ModalBody>
              <ModalFooter>
                <FormGroup>
                  <a href={this.state.ImageGallery}>
                    <Button
                      type="submit"
                      className="default   btn-sm mb-2 btn-addon"
                      color="primary"
                    >
                      {this.props.t("Download")}
                    </Button>{" "}
                  </a>

                  <Button
                    className="default   btn-sm mb-2 btn-addon"
                    color="danger"
                    onClick={() => this.togglePhotoViewModal()}
                  >
                    {this.props.t("Close")}
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
            {/* <Modal
              size="lg"
              className="viewDocumentModal"
              isOpen={this.state.PaymentModal}
            >
              <ModalBody className="m-3">
                <Row className="align-items-center">
                  <Col lg={1} />
                  <Col lg={10} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <h5 className="mt-4 text-center">
                      The payment for event is{" "}
                      {this.state.InitiateResponse &&
                        this.state.InitiateResponse.transactionAmount}
                    </h5>
                  </Col>
                </Row>
                <PaymentForm
                  SubmitPayment={this.SubmitPayment}
                  InitiateResponse={this.state.InitiateResponse}
                />
              </ModalBody>
            </Modal> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export class NotaryApplication extends Component {
  render() {
    return (
      <Fragment>
        <Application title="Notary Application" />
      </Fragment>
    );
  }
}
export class AreaExtensionApplication extends Component {
  render() {
    return (
      <Fragment>
        <Application
          isAreaExtension={true}
          title="Practicing Area Extension Application"
        />
      </Fragment>
    );
  }
}

export class DuplicateCertificate extends Component {
  render() {
    return (
      <Fragment>
        <Application
          isDuplicateCertificate={true}
          title="Duplicate Certificate Application"
        />
      </Fragment>
    );
  }
}

export class RenewalApplication extends Component {
  render() {
    return (
      <Fragment>
        <Application isRenewal={true} title="Renewal Application" />
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Application));
