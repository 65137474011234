import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
// import { Link } from "react-router-dom"
import { getDateTimeString } from "../../helpers/Utils";
import queryString from "query-string";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { CallService } from "../../helpers/ServiceCall";
import { payment } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, getMonthName } from "../../helpers/Utils";
import { datePickerFormat } from "../../constants/defaultValues";
import { fromUnixTime } from "date-fns";
import Logo from "../../assets/images/logo.png";
import LogoWaterMark from "../../assets/images/tn_water_mark.png";

class PaymentRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var param = queryString.parse(this.props.location.search);
    // var PaymentResponse = {
    //   Flag: param["Flag"],
    //   OrderId: param["OrderId"],
    //   CustomerId: param["CustomerId"],
    //   TransactionAmount: param["TransactionAmount"],
    //   CurrencyCode: param["CurrencyCode"],
    //   SurePayTxnId: param["SurePayTxnId"],
    //   PaymentMode: param["PaymentMode"],
    //   ResponseDateTime: param["ResponseDateTime"],
    //   BankTransactionNo: param["BankTransactionNo"],
    //   AdditionalInfo1: param["AdditionalInfo1"],
    //   AdditionalInfo2: param["AdditionalInfo2"],
    //   AdditionalInfo3: param["AdditionalInfo3"],
    //   AdditionalInfo4: param["AdditionalInfo4"],
    //   AdditionalInfo5: param["AdditionalInfo5"],
    // }
    var PaymentTransactionId = param["PaymentTransactionId"];
    this.setState(
      {
        // PaymentResponse: PaymentResponse,
        PaymentTransactionId: PaymentTransactionId,
      },
      () => this.SubmitPayment()
    );
  }
  SubmitPayment = () => {
    CallService(
      payment.Response,
      MethodType.POST,
      false,
      { PaymentTransactionId: this.state.PaymentTransactionId },
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentResponse: data.result,
      });
    }
  };
  DownloadFormAsPDF = () => {
    const payment = this.state.PaymentResponse;

    const pdf = new jsPDF("p", "px", "a4", true);
    var y = 20;
    pdf.setFontSize(11);
    y = y + 20;

    pdf.setFontSize(15);
    y = y + 20;
    const logoWidth = 80; // Adjust as needed
    const logoHeight = 80; // Adjust as needed

    const logoX = (pdf.internal.pageSize.width - logoWidth) / 2; // Center horizontally
    const logoY = (pdf.internal.pageSize.height - logoHeight) / 2; // Center vertically

    pdf.addImage(Logo, "PNG", logoX, 0, logoWidth, 0);

    //WaterMark logo
    pdf.addImage(
      LogoWaterMark,
      "PNG",
      (pdf.internal.pageSize.width - 400) / 2,
      (pdf.internal.pageSize.height - 400) / 2,
      400,
      400
    );
    pdf.setFont("helvetica", "bold");

    y = y + 55;
    pdf.text(
      "Government of Tamil Nadu - Law Department",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 15;

    pdf.text(
      "Web application for the Online Notary Appointment / Renewal of Practice",
      230,
      y,
      null,
      null,
      "center"
    );
    y = y + 35;

    pdf.text("Payment Transaction Receipt", 230, y, null, null, "center");
    pdf.line(175, y + 5, 290, y + 5);
    y = y + 25;
    pdf.setLineDashPattern([1.5]);

    pdf.setFont("helvetica", "bold");
    pdf.text("Status :-", 350, y, null, null, "right"); // Align to the right
    // pdf.setTextColor(255, 255, 255); // Set text color (white)
    // pdf.setFillColor(0, 0, 255);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse?.Status || "-",
      400,
      y,
      null,
      null,
      "right"
    ); // Align to the left
    // pdf.setTextColor(0, 0, 0); // Reset text color to black
    // pdf.setFillColor(255, 255, 255);
    y = y + 30;

    pdf.setFont("helvetica", "bold");
    pdf.text("Application Number", 50, y, null, null);

    pdf.text(
      this.state.PaymentResponse?.Payment &&
        this.state.PaymentResponse?.Payment?.Application &&
        this.state.PaymentResponse?.Payment?.Application?.ApplicationNo
        ? " - " +
            this.state.PaymentResponse?.Payment?.Application?.ApplicationNo
        : "-",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Date", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse &&
        this.state.PaymentResponse?.TransactionDateTime
        ? formatDate(
            fromUnixTime(this.state.PaymentResponse?.TransactionDateTime),
            datePickerFormat
          )
        : "",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Applicant Name", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse?.Payment?.Application
        ? this.state.PaymentResponse?.Payment?.Application?.Applicant
          ? this.state.PaymentResponse?.Payment?.Application?.Applicant?.Name
          : ""
        : "",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("Application", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state?.PaymentResponse &&
        this.state?.PaymentResponse?.Payment &&
        this.state?.PaymentResponse?.Payment?.ApplicationPath?.replace(
          /([A-Z])/g,
          " $1"
        )?.trim(),
      200,
      y,
      null,
      null
    );

    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("District", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse &&
        this.state.PaymentResponse?.Payment &&
        this.state.PaymentResponse?.Payment?.Application &&
        this.state.PaymentResponse?.Payment?.Application?.District &&
        this.state.PaymentResponse?.Payment?.Application?.District?.Name
        ? this.state.PaymentResponse?.Payment?.Application?.District?.Name
        : "-",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    // pdf.text("Status", 50, y, null, null);
    // pdf.text(payment.Status, 200, y, null, null);
    // pdf.setLineDashPattern([1.5]);

    // pdf.line(190, y + 5, 300, y + 5);

    // y = y + 25;
    pdf.setFont("helvetica", "bold");
    pdf.text("Order Id", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse &&
        this.state.PaymentResponse?.OrderId?.toString(),
      200,
      y,
      null,
      null
    );

    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Receipt Number", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse &&
        this.state.PaymentResponse?.TransactionReceiptNumber?.toString(),
      200,
      y,
      null,
      null
    );

    pdf.line(190, y + 5, 350, y + 5);

    y = y + 25;

    pdf.setFont("helvetica", "bold");
    pdf.text("Payment Amount", 50, y, null, null);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      this.state.PaymentResponse &&
        this.state.PaymentResponse?.Payment &&
        this.state.PaymentResponse?.Payment?.Amount
        ? this.state.PaymentResponse?.Payment?.Amount?.toString()
        : "-",
      200,
      y,
      null,
      null
    );
    pdf.line(190, y + 5, 350, y + 5);
    y = y + 25;

    y = y + 25;
    if (payment.AdditionalInfo1) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 1", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment.AdditionalInfo1, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo2) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 2", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment.AdditionalInfo2, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo3) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 3", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment.AdditionalInfo3, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo4) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 4", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment.AdditionalInfo4, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    if (payment.AdditionalInfo5) {
      pdf.setFont("helvetica", "bold");
      pdf.text("Additional Info 5", 50, y, null, null);
      pdf.setFont("helvetica", "normal");
      pdf.text(payment.AdditionalInfo5, 200, y, null, null);
      pdf.line(190, y + 5, 300, y + 5);
      y = y + 25;
    }
    pdf.save(
      payment ? payment.TransactionReceiptNumber + ".pdf" : "PaymentReceipt.pdf"
    );
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="email-template">
              <Col md="12">
                <table
                  className="body-wrap"
                  style={{
                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                    boxSizing: "border-box",
                    fontSize: "14px",
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        margin: "0",
                      }}
                    >
                      <td
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          margin: "0",
                        }}
                        valign="top"
                      ></td>
                      <td
                        className="container"
                        width="600"
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          display: "block!important",
                          maxWidth: "600px !important",
                          clear: "both !important",
                          margin: "0 auto",
                        }}
                        valign="top"
                      >
                        <div
                          className="content"
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "14px",
                            maxWidth: "600px",
                            display: "block",
                            margin: "0 auto",
                            padding: "20px",
                          }}
                        >
                          <table
                            className="main"
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              fontFamily:
                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                              boxSizing: "border-box",
                              fontSize: "14px",
                              borderRadius: "3px",
                              margin: "0",
                              border: "none",
                            }}
                          >
                            <tbody>
                              <tr
                                style={{
                                  fontFamily:
                                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                                  boxSizing: "border-box",
                                  fontSize: "14px",
                                  margin: "0",
                                }}
                              >
                                <td
                                  className="content-wrap aligncenter"
                                  style={{
                                    fontFamily:
                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    verticalAlign: "top",
                                    margin: "0",
                                    padding: "20px",
                                    border: "3px solid #1d1e3a",
                                    borderRadius: "7px",
                                    backgroundColor: "#fff",
                                  }}
                                  align="center"
                                  valign="top"
                                >
                                  <table
                                    width="100%"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      margin: "0",
                                    }}
                                  >
                                    <tbody>
                                      <tr
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          margin: "0",
                                        }}
                                      >
                                        <td
                                          className="content-block"
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            margin: "0",
                                            padding: "0 0 20px",
                                          }}
                                          valign="top"
                                        >
                                          <h2
                                            className="aligncenter"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "24px",
                                              color: "#000",
                                              lineHeight: "1.2em",
                                              fontWeight: "400",
                                              textAlign: "center",
                                              margin: "40px 0 0",
                                            }}
                                            align="center"
                                          >
                                            Government of Tamil Nadu - Law
                                            Department
                                          </h2>
                                          <h5
                                            className="aligncenter"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif",
                                              boxSizing: "border-box",
                                              color: "#000",
                                              textAlign: "center",
                                              margin: "40px 0 0",
                                            }}
                                            align="center"
                                          >
                                            Web application for the Online
                                            Notary Appointment / Renewal of
                                            Practice
                                          </h5>
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          margin: "0",
                                        }}
                                      >
                                        <td
                                          className="content-block aligncenter"
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "center",
                                            margin: "0",
                                            padding: "0 0 20px",
                                          }}
                                          align="center"
                                          valign="top"
                                        >
                                          <table
                                            className="invoice"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "14px",
                                              textAlign: "left",
                                              width: "80%",
                                              margin: "20px auto",
                                            }}
                                          >
                                            {this.state.PaymentResponse && (
                                              <tbody>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"Reason"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse
                                                      .Payment &&
                                                    this.state.PaymentResponse
                                                      .Payment.ApplicationPath
                                                      ? this.state.PaymentResponse.Payment.ApplicationPath.split(
                                                          /(?=[A-Z])/
                                                        ).join(" ")
                                                      : ""}
                                                    {this.state.PaymentResponse
                                                      .Payment &&
                                                    this.state.PaymentResponse
                                                      .Payment.Application &&
                                                    this.state.PaymentResponse
                                                      .Payment.Application
                                                      .ApplicationNo
                                                      ? " - " +
                                                        this.state
                                                          .PaymentResponse
                                                          .Payment.Application
                                                          .ApplicationNo
                                                      : ""}
                                                    {/* <i className="fa fa-check " /> */}
                                                  </td>
                                                </tr>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"Status"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse
                                                      .Status
                                                      ? this.state
                                                          .PaymentResponse
                                                          .Status
                                                      : ""}
                                                    {/* <i className="fa fa-check " /> */}
                                                  </td>
                                                </tr>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"OrderId"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse
                                                      .TransactionReceiptNumber
                                                      ? this.state
                                                          .PaymentResponse
                                                          .TransactionReceiptNumber
                                                      : ""}
                                                    {/* <i className="fa fa-check " /> */}
                                                  </td>
                                                </tr>
                                                {/* <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"CustomerId"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.CustomerId ? this.state.PaymentResponse.CustomerId : ""}
                                                  </td>
                                                </tr> */}
                                                {/* <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"TransactionAmount"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.TransactionAmount ? this.state.PaymentResponse.TransactionAmount : ""}
                                                  </td>
                                                </tr> */}
                                                {/* <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"CurrencyCode"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.CurrencyCode ? this.state.PaymentResponse.CurrencyCode : ""}
                                                  </td>
                                                </tr>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"PaymentMode"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.PaymentMode ? this.state.PaymentResponse.PaymentMode : ""}
                                                  </td>
                                                </tr> */}
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b>
                                                      {" "}
                                                      {"TransactionDateTime"}
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse
                                                      .TransactionDateTime
                                                      ? getDateTimeString(
                                                          this.state
                                                            .PaymentResponse
                                                            .TransactionDateTime
                                                        )
                                                      : ""}
                                                  </td>
                                                </tr>
                                                {/* <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"SurePayTxnId"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.SurePayTxnId ? this.state.PaymentResponse.SurePayTxnId : ""}
                                                  </td>
                                                </tr>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"BankTransactionNo"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse.BankTransactionNo ? this.state.PaymentResponse.BankTransactionNo : ""}
                                                  </td>
                                                </tr> */}
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b> {"AdditionalInfo"}</b>
                                                  </td>
                                                  <td>
                                                    {this.state.PaymentResponse
                                                      .AdditionalInfo1
                                                      ? this.state
                                                          .PaymentResponse
                                                          .AdditionalInfo1
                                                      : ""}
                                                    <br />{" "}
                                                    {this.state.PaymentResponse
                                                      .AdditionalInfo2
                                                      ? ", " +
                                                        this.state
                                                          .PaymentResponse
                                                          .AdditionalInfo2
                                                      : ""}
                                                    <br />{" "}
                                                    {this.state.PaymentResponse
                                                      .AdditionalInfo3
                                                      ? ", " +
                                                        this.state
                                                          .PaymentResponse
                                                          .AdditionalInfo3
                                                      : ""}
                                                    <br />{" "}
                                                    {this.state.PaymentResponse
                                                      .AdditionalInfo4
                                                      ? ", " +
                                                        this.state
                                                          .PaymentResponse
                                                          .AdditionalInfo4
                                                      : ""}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )}
                                          </table>
                                        </td>
                                      </tr>
                                      <Button
                                        color="success"
                                        className="mr-2 ml-2 float-sm-right"
                                        onClick={() => this.DownloadFormAsPDF()}
                                      >
                                        Download Payment Receipt
                                      </Button>
                                      <tr
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          margin: "0",
                                        }}
                                      >
                                        <td
                                          className="content-block aligncenter"
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "center",
                                            margin: " 0",
                                            padding: " 0 0 20px",
                                          }}
                                          align="center"
                                          valign="top"
                                        ></td>
                                      </tr>

                                      <tr
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          margin: "0",
                                        }}
                                      >
                                        <td
                                          className="content-block"
                                          style={{
                                            textAlign: "center",
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                          valign="top"
                                        >
                                          © {new Date().getFullYear()}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            {/* <div className="float-right">  <Link className="btn btn-primary waves-effect waves-light " to="/dashboard">Back to Dashboard</Link>
            </div> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentRedirect;
