import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import {
  district,
  state,
  profileComplete,
  gender,
  taluk,
  user,
  authenctication,
} from "../../constants/config";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import DropDown from "../../components/custom/DropDown";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import { Formik, Form, Field } from "formik";
import { withNamespaces } from "react-i18next";
import moment from "moment";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import "react-tagsinput/react-tagsinput.css";
import "rc-switch/assets/index.css";
// import "react-datepicker/dist/react-datepicker.css";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changePasswordValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Base64ToUint8Array, encryptBox } from "../../helpers/encryption";
import avatar from "../../assets/images/users/logo_tnpcb.png";
// actions
import { editProfile } from "../../store/actions";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
nacl.util = naclUtil;

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFirstTab: 1,
      email: "",
      name: "",
      idx: 1,
      buttonAction: false,
      tabs: [],
      UserId: sessionStorage.getItem("user_id"),
      UserName:
        JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name,
      loading: false,
      ProvisionAddress: false,
      ListRequestModel: {
        searchString: "",
        sortCondition: {},
        pageNumber: 1,
        pageLimit: 10,
      },
      ProfileDetail: {
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
      Language: sessionStorage.getItem("lng"),

      Profile: {
        Name: "",
        MobileNumber: "",
        EmailID: "",
        Password: "",
        Role: "",
        Address: {
          Line1: "",
          Line2: "",
          Line3: "",
          Pincode: "",
          District: null,
          Taluk: null,
          State: null,
        },
        IsPasswordChanged: false,
      },
      GenderList: [],
      DistrictList: [],
      TalukList: [],
      State: [],
      MobileNumber: sessionStorage.getItem("MobileNumber"),
    };
  }
  componentDidMount() {
    this.GetAllGender();
    this.GetAllState();
    this.GetAllDistrict();
    this.GetAllTaluk("");
    setTimeout(() => {
      this.getSeed();
    }, 1000);

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid });
      }
    }
    this.setTab(1);
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState(
        {
          ServerPublicKey: data.result.ServerPublicKey,
          SeedId: data.result._id,
        },
        () => this.getSecondSeed()
      );
    }
  };
  getSecondSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSecondSeedResponse
    );
  }
  getSecondSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState({
        ServerSecondPublicKey: data.result.ServerPublicKey,
        SeedSecondId: data.result._id,
      });
    }
  };
  setTab = (no) => {
    if (sessionStorage.getItem("IsPasswordChanged") === "false") {
      this.setState({
        tabs: [
          {
            id: 1,
            Name: this.props.t("Change Password"),
          },
        ],
      });
    } else {
      if (
        (JSON.parse(sessionStorage.getItem("user")) &&
          JSON.parse(sessionStorage.getItem("user")).Role &&
          JSON.parse(sessionStorage.getItem("user")).Role.Name ===
          "interview_board") ||
        (JSON.parse(sessionStorage.getItem("user")) &&
          JSON.parse(sessionStorage.getItem("user")).Role &&
          JSON.parse(sessionStorage.getItem("user")).Role.Name === "law_admin")
      ) {
        this.setState({
          tabs: [
            {
              id: 1,
              Name: this.props.t("Change Password"),
            },
          ],
        });
      } else {
        this.setState({
          tabs: [
            {
              id: 1,
              Name: this.props.t("Change Password"),
            },
            {
              id: 2,
              Name: this.props.t("Profile"),
            },
          ],
        });
      }
    }
  };
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      if (tab === 2) {
        this.GetOneUser(this.state.UserId);
      }
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  GetOneUser(id) {
    CallService(
      user.GetOne + id,
      MethodType.GET,
      false,
      "",
      "",
      this.GetOneUserResponse
    );
  }

  GetOneUserResponse = (data) => {
    // var Dob = data.result ? (data.result ? data.result.Dob : "") : "";
    // var date = moment(new Date(Dob)).format("DD/MM/YYYY");

    var PrefillProfile = {
      Name: data.result ? data.result.Name : "",
      Gender: data.result ? data.result.Gender : "",
      MobileNumber: data.result ? data.result.MobileNumber : "",
      EmailID: data.result ? data.result.EmailID : "",
      District: data.result ? data.result.District : "",
      Role: data.result ? data.result.Role : "",
      //  Dob: data.result ? data.result.Dob : null,
      Address: {
        Line1: data.result
          ? data.result.Address
            ? data.result.Address.Line1
            : ""
          : "",
        Line2: data.result
          ? data.result.Address
            ? data.result.Address.Line2
            : ""
          : "",
        Line3: data.result
          ? data.result.Address
            ? data.result.Address.Line3
            : ""
          : "",
        Pincode: data.result
          ? data.result.Address
            ? data.result.Address.Pincode
            : ""
          : "",
        State: this.state.StateList
          ? this.state.StateList[0]
            ? this.state.StateList[0].value
            : ""
          : "",
        District: data.result
          ? data.result.Address
            ? data.result.Address.District
            : ""
          : "",
        Taluk: data.result
          ? data.result.Address
            ? data.result.Address.Taluk
            : ""
          : "",
      },
    };

    this.setState({
      Profile: PrefillProfile,
    });
  };

  handleValidSubmit = (values) => {
    if (!this.state.buttonAction) {
      var submitValues = values;

      submitValues.Address.State = submitValues.Address.State._id
        ? submitValues.Address.State._id
        : submitValues.Address.State;

      //  submitValues.Dob = moment(submitValues.Dob * 1000).unix();
      this.manageApplication(submitValues);

      this.setState({
        //  buttonAction: true,
        loading: true,
        ProfileDetails: submitValues,
      });
    }
  };

  manageApplication = (values) => {
    // var PasswordEncrpt = values;

    // const rPrivateKey = nacl.util.decodeBase64(this.state.Seed);
    // const Password = values.Password;
    // const cipher = nacl.sign(Buffer.from(Password), rPrivateKey);
    // const cipherText = nacl.util.encodeBase64(cipher);

    // PasswordEncrpt.Password = { _id: this.state.SeedId, seed: cipherText };

    CallService(
      user.Update + this.state.UserId,
      MethodType.PATCH,
      false,
      values,
      "",
      this.manageApplicationResponse
    );
  };
  manageApplicationResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      this.setState({
        loading: false,
        buttonAction: false,
      });
      toastr.success("", "Profile updated Successfully");
      this.props.history.push("/notary_applications");
    } else {
      toastr.error("", "Please try again");
      this.setState({
        loading: false,
        buttonAction: false,
      });
    }
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllTaluk(districtId) {
    let { ListRequestModel } = this.state;
    ListRequestModel.District = districtId;
    ListRequestModel.pageLimit = 1000;

    CallService(
      taluk.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllTalukResponse
    );
  }

  GetAllTalukResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else {
      this.setState({
        TalukList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };
  GetAllState() {
    CallService(
      state.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllStateResponse
    );
  }
  GetAllStateResponse = (data) => {
    if (this.state.Language === "ta") {
      this.setState({
        StateList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    } else {
      this.setState({
        StateList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    }
  };

  GetAllGender() {
    CallService(
      gender.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllGenderResponse
    );
  }

  GetAllGenderResponse = (data) => {
    this.setState({
      GenderList: data.result.map(function (a) {
        return { value: a._id, label: a.Name };
      }),
    });
  };

  handleSubmit = (values) => {
    console.log(values);
    if (values) {
      if (values.NewPassword === values.ConfirmPassword) {
        //  delete values.ConfirmPassword;

        values.MobileNumber = this.state.MobileNumber;

        this.setState({
          ProfileDetail: values,
        });
        this.ChangePassword();
      } else {
        toastr.warning("", "New Password and Confirm Password must be same");
      }
    }
  };

  ChangePassword() {
    var apis;
    JSON.parse(sessionStorage.getItem("user")) &&
      JSON.parse(sessionStorage.getItem("user")).Role &&
      JSON.parse(sessionStorage.getItem("user")).Role.Name === "pdj"
      ? (apis = authenctication.PDJChangePassword)
      : JSON.parse(sessionStorage.getItem("user")) &&
        JSON.parse(sessionStorage.getItem("user")).Role &&
        JSON.parse(sessionStorage.getItem("user")).Role.Name ===
        "interview_board"
        ? (apis = authenctication.IBChangePassword)
        : JSON.parse(sessionStorage.getItem("user")) &&
          JSON.parse(sessionStorage.getItem("user")).Role &&
          JSON.parse(sessionStorage.getItem("user")).Role.Name === "advocate"
          ? (apis = authenctication.AdvocateChangePassword)
          : (apis = authenctication.PDJChangePassword);

    var PasswordEncrpt = this.state.ProfileDetail;
    const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
    const oldPassword = this.state.ProfileDetail.OldPassword;
    // const cipher = nacl.sign(Buffer.from(oldPassword), rPrivateKey);
    // const cipherText = nacl.util.encodeBase64(cipher);
    var encrypted = encryptBox(oldPassword, serverPublicKey);
    PasswordEncrpt.OldPassword = {
      _id: this.state.SeedId,
      Nonce: encrypted.nonceText,
      Message: encrypted.cipherText,
      ClientPublicKey: encrypted.clientPublicKeyText,
    };

    const newPassword = this.state.ProfileDetail.NewPassword;
    // const cipherNew = nacl.sign(Buffer.from(newPassword), rPrivateKey);
    // const cipherNewText = nacl.util.encodeBase64(cipherNew);
    const serverSecondPublicKey = Base64ToUint8Array(
      this.state.ServerSecondPublicKey
    );
    var SecondEncrypted = encryptBox(newPassword, serverSecondPublicKey);
    PasswordEncrpt.NewPassword = {
      _id: this.state.SeedSecondId,
      Nonce: SecondEncrypted.nonceText,
      Message: SecondEncrypted.cipherText,
      ClientPublicKey: SecondEncrypted.clientPublicKeyText,
    };
    delete PasswordEncrpt.ConfirmPassword;
    CallService(
      apis,
      MethodType.POST,
      false,
      PasswordEncrpt,
      "",
      this.ChangePasswordResponse
    );
  }
  ChangePasswordResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Password Changed Successfully");
      this.props.history.push("/officiallogin");
    } else {
      toastr.error("", "Something went wrong");
    }
    this.setState({
      ProfileDetail: {
        ...this.state.ProfileDetail,
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
    });
  };
  render() {
    const initialProfileValues = this.state.Profile;
    const initialValues = this.state.ProfileDetail;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb
              title={this.props.t("Law Admin")}
              breadcrumbItem={this.props.t("Profile")}
            />
            {/* <h4 className="card-title mb-4">Change UserName</h4> */}
            <Nav tabs className="nav-tabs-custom nav-justified">
              {this.state.tabs.map((t) => {
                return (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={{
                        active: this.state.activeFirstTab === t.id,
                      }}
                      onClick={() => {
                        this.toggleCustomJustified(t.id);
                      }}
                    >
                      <span className="d-none d-sm-block">
                        {this.props.t(t.Name)}
                      </span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>

            <Card>
              <CardBody>
                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={1}>
                    <Colxx xx="6">
                      <Row>
                        <Colxx xx="3" lg="3" md="3" sm="3"></Colxx>
                        <Colxx xx="6" lg="6" md="6" sm="6">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={changePasswordValidation}
                            onSubmit={this.handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                          >
                            {({ values, errors }) => (
                              <Form className="av-tooltip tooltip-label-bottom">
                                <Fragment>
                                  {console.log(values)}
                                  <CardBody>
                                    <Row>
                                      <Colxx xx="12">
                                        <FormGroup className="form-group has-float-label">
                                          <Label>
                                            {this.props.t("Current Password")}
                                          </Label>
                                          <Field
                                            type="password"
                                            className="form-control"
                                            name="OldPassword"
                                          />
                                          {errors.OldPassword && (
                                            <div className="invalid-feedback d-block">
                                              {errors.OldPassword}
                                            </div>
                                          )}
                                        </FormGroup>
                                        <FormGroup className="form-group has-float-label">
                                          <Label>
                                            {this.props.t("New Password")}
                                          </Label>
                                          <Field
                                            type="password"
                                            className="form-control"
                                            name="NewPassword"
                                          />
                                          {errors.NewPassword && (
                                            <div className="invalid-feedback d-block">
                                              {errors.NewPassword}
                                            </div>
                                          )}
                                        </FormGroup>
                                        <FormGroup className="form-group has-float-label">
                                          <Label>
                                            {this.props.t("Confirm Password")}
                                          </Label>
                                          <Field
                                            type="password"
                                            className="form-control"
                                            name="ConfirmPassword"
                                          />
                                          {errors.ConfirmPassword && (
                                            <div className="invalid-feedback d-block">
                                              {errors.ConfirmPassword}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Colxx>
                                    </Row>
                                    <FormGroup className="float-sm-right ">
                                      <Button
                                        type="submit"
                                        outline
                                        color="primary"
                                      >
                                        Submit
                                      </Button>
                                    </FormGroup>
                                  </CardBody>
                                </Fragment>
                              </Form>
                            )}
                          </Formik>
                        </Colxx>
                      </Row>
                    </Colxx>{" "}
                  </TabPane>
                </TabContent>
                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId={2}>
                    <Formik
                      initialValues={initialProfileValues}
                      //  validationSchema={ProfileAddressValidation}
                      onSubmit={this.handleValidSubmit}
                      validateOnBlur={false}
                      validateOnChange={false}
                      enableReinitialize={true}
                    >
                      {({ errors, values, setFieldValue }) => (
                        <Form className="av-tooltip tooltip-label-bottom">
                          <Row>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("Name")}
                                </Label>
                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Name"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue("Name", event.target.value);
                                  }}
                                />
                                {errors.Name && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Name}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  isDisabled={
                                    this.state.IsProfileCompleted === "true"
                                      ? true
                                      : false
                                  }
                                  labelClassName="requiredField"
                                  label={this.props.t("Gender")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="Gender"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.Gender &&
                                    (typeof values.Gender === "string"
                                      ? this.state.GenderList.find(
                                        (r) => r.value === values.Gender
                                      )
                                      : this.state.GenderList.find(
                                        (r) => r.value === values.Gender._id
                                      ))
                                  }
                                  options={this.state.GenderList}
                                  ClearAction={() => {
                                    setFieldValue("Gender", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Gender",
                                      entity ? entity.value : null
                                    );
                                  }}
                                  errors={errors.Gender}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            {/* <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("Date Of Birth")}
                                </Label>

                                <DatePicker
                                  selected={
                                    this.state.startDate
                                      ? this.state.startDate
                                      : ""
                                  }
                                  className="form-control"
                                  value={
                                    this.state.Profile.Dob
                                      ? moment(
                                          new Date(this.state.Profile.Dob)
                                        ).format("DD/MM/YYYY")
                                      : ""
                                  }
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) => {
                                    this.setState({
                                      startDate: date,
                                    });
                                    values.Dob = date;
                                    //  values.Dob = new Date(date).getTime();
                                  }}
                                  placeholderText="dd/mm/yyyy"
                                  name="startDate"
                                  dateFormat="dd/MM/yyyy"
                                />
                                {errors.Dob && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Dob}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx> */}

                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("Mobile Number")}
                                </Label>
                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="MobileNumber"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue(
                                      "MobileNumber",
                                      event.target.value
                                    );
                                  }}
                                />
                                {errors.MobileNumber && (
                                  <div className="invalid-feedback d-block">
                                    {errors.MobileNumber}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                            {/* </Row>
                          <Row> */}
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Email ID")}
                                </Label>
                                <Field
                                  type="email"
                                  autoFocus
                                  className="form-control"
                                  name="EmailID"
                                />
                                {errors.EmailID && (
                                  <div className="invalid-feedback d-block">
                                    {errors.EmailID}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  labelClassName="requiredField"
                                  label={this.props.t("District")}
                                  components={{ Input: CustomSelectInput }}
                                  classNamePrefix="react-select"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values.District &&
                                    (typeof values.District === "string"
                                      ? this.state.DistrictList.find(
                                        (r) => r.value === values.District
                                      )
                                      : this.state.DistrictList.find(
                                        (r) => r.value === values.District._id
                                      ))
                                  }
                                  options={this.state.DistrictList}
                                  ClearAction={() => {
                                    setFieldValue("District", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "District",
                                      entity ? entity.value : null
                                    );
                                    values.District = entity
                                      ? entity.value
                                      : null;

                                    // this.GetAllTaluk(entity ? entity.value : "");
                                  }}
                                  errors={errors.District}
                                />
                              </FormGroup>
                            </Colxx>
                            {/* </Row>
                          <Row> */}
                            {/* <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {" "}
                                  {this.props.t("Password")}
                                </Label>
                                <Field
                                  type="Password"
                                  autoFocus
                                  className="form-control"
                                  name="Password"
                                />
                                {errors.Password && (
                                  <div className="invalid-feedback d-block">
                                    {errors.Password}
                                  </div>
                                )}
                              </FormGroup>
                            </Colxx> */}

                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("Address Line 1")}
                                </Label>
                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Address.Line1"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue(
                                      "Address.Line1",
                                      event.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 2")}</Label>
                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Address.Line2"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue(
                                      "Address.Line2",
                                      event.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label>{this.props.t("Address Line 3")}</Label>

                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Address.Line3"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue(
                                      "Address.Line3",
                                      event.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  labelClassName="requiredField"
                                  label={this.props.t("State")}
                                  components={{ Input: CustomSelectInput }}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isDisabled={true}
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values &&
                                    values.Address &&
                                    values.Address.State &&
                                    (typeof values.Address.State === "string"
                                      ? this.state.StateList.find(
                                        (r) =>
                                          r.value === values.Address.State
                                      )
                                      : this.state.StateList.find(
                                        (r) =>
                                          r.value === values.Address.State._id
                                      ))
                                  }
                                  options={this.state.StateList}
                                  ClearAction={() => {
                                    setFieldValue("Address.State", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Address.State",
                                      entity ? entity.value : null
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  labelClassName="requiredField"
                                  label={this.props.t("District")}
                                  components={{ Input: CustomSelectInput }}
                                  classNamePrefix="react-select"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values &&
                                    values.Address &&
                                    values.Address.District &&
                                    (typeof values.Address.District === "string"
                                      ? this.state.DistrictList.find(
                                        (r) =>
                                          r.value === values.Address.District
                                      )
                                      : this.state.DistrictList.find(
                                        (r) =>
                                          r.value ===
                                          values.Address.District._id
                                      ))
                                  }
                                  options={this.state.DistrictList}
                                  ClearAction={() => {
                                    setFieldValue("Address.District", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Address.District",
                                      entity ? entity.value : null
                                    );

                                    this.GetAllTaluk(
                                      entity ? entity.value : ""
                                    );
                                  }}
                                // errors={errors.ResidencyDistrict}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <DropDown
                                  labelClassName="requiredField"
                                  label={this.props.t("Taluk")}
                                  components={{ Input: CustomSelectInput }}
                                  //    className="react-select"
                                  classNamePrefix="react-select"
                                  isSearchable
                                  isClearable={true}
                                  value={
                                    values &&
                                    values.Address &&
                                    values.Address.Taluk &&
                                    (typeof values.Address.Taluk === "string"
                                      ? this.state.TalukList.find(
                                        (r) =>
                                          r.value === values.Address.Taluk
                                      )
                                      : this.state.TalukList.find(
                                        (r) =>
                                          r.value === values.Address.Taluk._id
                                      ))
                                  }
                                  options={this.state.TalukList}
                                  ClearAction={() => {
                                    setFieldValue("Address.Taluk", null);
                                  }}
                                  Action={(entity) => {
                                    setFieldValue(
                                      "Address.Taluk",
                                      entity ? entity.value : null
                                    );
                                  }}
                                //errors={errors.ResidencyTaluk}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="6">
                              <FormGroup className="form-group has-float-label">
                                <Label className="requiredField">
                                  {this.props.t("PIN Code")}
                                </Label>
                                <Field
                                  type="text"
                                  autoFocus
                                  className="form-control"
                                  name="Address.Pincode"
                                  placeholder="Pincode"
                                  onKeyPress={(event) => {
                                    if (INVALID_CHARS.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onBlur={(event) => {
                                    event.target.value =
                                      event.target.value &&
                                      event.target.value.replace(
                                        INVALID_CHARS_REGEX,
                                        ""
                                      );
                                    setFieldValue(
                                      "Address.Pincode",
                                      event.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>
                          <p style={{ textAlign: "right" }}>
                            Fields marked with
                            <span style={{ color: "red" }}> &nbsp;*</span> are
                            mandatory
                          </p>

                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            color="success"
                            style={{ float: "right" }}
                          >
                            Submit
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(withNamespaces()(UserProfile))
);
