/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from "react";

import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// reactstrap

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LoginProfileMenu from "../CommonForBoth/TopbarDropdown/LoginProfileMenu";

import logofull from "../../assets/images/logo.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";

//import tnegaLogo from "../../assets/images/TNeGA.png";
import tnegaLogo from "../../assets/images/TNeGA.png";

// import images

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from "react-i18next";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearch: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    // this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header
          id="page-topbar"
        // style={{
        //   height: "80px",
        // }}
        >
          <div className="mt-3 mb-3 navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logofull} alt="TN Notary" height="55" />
                  </span>
                  <span className="logo-lg">
                    <img src={logofull} alt="TN Notary" height="85" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="19" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="d-flex">
              <div className="navbar-brand-box">
                <div style={{ fontSize: "15px" }}>
                  <h4 className="text-center">
                    {this.props.t("Government of Tamil Nadu - Law Department")}
                  </h4>
                  <h6 className="text-center">
                    {this.props.t(
                      "Web application for the Online Notary Appointment / Renewal of Practice"
                    )}
                  </h6>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="navbar-brand-box">
                <div>
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={tnegaLogo} alt="TN Notary" height="55" />
                    </span>
                    <span className="logo-lg">
                      <img src={tnegaLogo} alt="TN Notary" height="80" />
                    </span>
                  </Link>

                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={tnegaLogo} alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                      <img src={tnegaLogo} alt="" height="19" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            
            {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>{" "}
              <div style={{ marginTop: "25px", fontSize: "15px" }}>
                <span>
                  {this.props.t("Government of Tamil Nadu - Law Department")}
                </span>
              </div> */}
            {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> 
            </div>*/}
            {/* 
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("Search") + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                </div> 
            </div> 
                */}
            {/* <LoginProfileMenu />
            <LanguageDropdown /> */}
          </div>
          <div style={{color:"#e00f04",textAlign: "center", verticalAlign:"middle"}} width="100%" height="50px">
          <b >
            "No fresh application has been called for appointment of New Notary 
by the Government of Tamil Nadu at present. The date calling for application for appointment of new Notary will be intimated as and when the Government proposes."
</b>
</div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default withRouter(
  connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header))
);
