import React from "react";
import { Row, Col } from "reactstrap";
import logofull from "../../assets/images/TNeGA.png";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Law Admin.</Col>
            <Col sm={6}>
              {/* <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                {/* <a
                  href="https://vertace.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}{" "}
              {/* Tnega */}
              {/* </a> 
              </div> */}
              <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                <img
                  alt="tnega"
                  src={logofull}
                  style={{ width: "25px", objectFit: "contain" }}
                />
                <u>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://tnega.tn.gov.in/"
                    target="blank"
                  >
                    {" "}
                    TNeGA
                  </a>
                </u>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
