import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import DropDown from "../../components/custom/DropDown";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import CustomSelectInput from "../../components/Common/CustomSelectInput";
import {
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import {
  interviewboard,
  district,
  user,
  authenctication,
} from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
import { InterviewBoardValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ListPageHeader from "../../components/custom/ListPageHeader";
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";
import {
  formatEncryptedField,
  encryptBox,
  Base64ToUint8Array,
} from "../../helpers/encryption";
import { INVALID_CHARS, INVALID_CHARS_REGEX } from "../../helpers/Utils";
nacl.util = naclUtil;

class InterviewBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      InterviewBoards: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      Language: sessionStorage.getItem("lng"),
      InterviewBoard: {
        Name: "",
        Username: "",
        MobileNumber: "",
        EmailId: "",
        Districts: [],
        Password: "",
      },
      UniqueUserNameValidate: true,
      columns: [
        {
          name: <strong>{this.props.t("Username")}</strong>,
          selector: "Username",
          sortable: false,
          cell: (row) => <span>{row.Username ? row.Username : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: false,
          cell: (row) => <span>{row.Name ? row.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Districts")}</strong>,
          selector: "District",
          sortable: false,
          cell: (row) => (
            <span>
              {sessionStorage.getItem("lng") === "ta"
                ? row.Districts.map((dis) => {
                    return dis.TamilName;
                  }).join(", ")
                : row.Districts.map((dis) => {
                    return dis.Name;
                  }).join(", ")}
            </span>
          ),
        },
        {
          name: <strong>{this.props.t("Mobile Number")}</strong>,
          selector: "MobileNumber",
          sortable: false,
          cell: (row) => (
            <span>{row.MobileNumber ? row.MobileNumber : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Email ID")}</strong>,
          selector: "EmailID",
          sortable: false,
          cell: (row) => <span>{row.EmailID ? row.EmailID : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="danger"
                onClick={() => this.toggleDeleteModal(row)}
              >
                {this.props.t("Delete")}
              </Button>
            </Row>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.GetAllInterviewBoard();
    this.GetAllDistrict();

    setTimeout(() => {
      this.getSeed();
    }, 1000);
  }

  getSeed() {
    CallService(
      authenctication.GetSeed,
      MethodType.POST,
      false,
      {},
      "",
      this.getSeedResponse
    );
  }
  getSeedResponse = (data) => {
    if (data.statusCode === StatusCodes.Success && data.result) {
      this.setState({
        ServerPublicKey: data.result.ServerPublicKey,
        SeedId: data.result._id,
      });
    }
  };
  GetAllInterviewBoard() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      interviewboard.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllInterviewBoardResponse
    );
  }

  GetAllInterviewBoardResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        InterviewBoards: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  GetAllDistrict() {
    CallService(
      district.GetAllWithOutPagination,
      MethodType.GET,
      false,
      "",
      "",
      this.GetAllDistrictResponse
    );
  }

  GetAllDistrictResponse = (data) => {
    if (this.state.Language === "en") {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.Name };
        }),
      });
    } else {
      this.setState({
        DistrictList: data.result.map(function (a) {
          return { value: a._id, label: a.TamilName };
        }),
      });
    }
  };
  handleSubmit = (values) => {
    if (values._id) {
      this.setState({
        buttonAction: true,
      });
      if (values?.Districts?.[0]?.value) {
        let details = values;
        details.Districts = values.Districts
          ? values.Districts.map((district) => {
              return district.value;
            })
          : null;
      }
      this.manageInterviewBoard(values);
    } else {
      if (this.state.UniqueUserNameValidate) {
        console.log("create", values);
        var details = values;
        details.Districts = values?.Districts
          ? values?.Districts.map((district) => {
              return district?.value;
            })
          : null;
        console.log("buttonAction", this.state.buttonAction);
        if (!this.state.buttonAction) {
          this.setState({
            buttonAction: true,
            InterviewBoardValue: details,
          });
          this.manageInterviewBoard(details);
        }
      }
    }
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllInterviewBoard();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAllInterviewBoard();
      }
    );
  };

  HandleRowClicked = (row) => {
    console.log(row);
    var prefill = row;
    row.Password = row.RawPassword;
    row.Districts = row.Districts.map((dis) => {
      return dis._id;
    });
    if (row) {
      this.setState({
        InterviewBoard: prefill,
        manageModal: !this.state.manageModal,
      });
    }
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllInterviewBoard();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllInterviewBoard();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      InterviewBoard: row,
    });
  };
  addBtnClick = () => {
    this.setState({
      UniqueUserNameValidate: true,
    });
    this.toggleManageModal();
  };
  toggleManageModal = () => {
    this.setState({
      InterviewBoard: {},
      manageModal: !this.state.manageModal,
    });
  };

  deleteInterviewBoard = (id) => {
    CallService(
      interviewboard.Delete + id,
      MethodType.DELETE,
      false,
      "",
      "",
      this.deleteInterviewBoardResponse
    );
  };

  deleteInterviewBoardResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    this.toggleDeleteModal();
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "Interview Board Deleted Successfully");
      this.GetAllInterviewBoard();
    }
  };
  manageInterviewBoard = (values) => {
    debugger;
    var passwordEncrpt = values;

    const serverPublicKey = Base64ToUint8Array(this.state.ServerPublicKey);
    const password = values.Password;
    const { cipherText, nonceText, clientPublicKeyText } = encryptBox(
      password,
      serverPublicKey
    );

    passwordEncrpt.Password = formatEncryptedField(
      this.state.SeedId,
      cipherText,
      nonceText,
      clientPublicKeyText
    );

    CallService(
      values._id ? interviewboard.Update + values._id : interviewboard.Create,
      values._id ? MethodType.PATCH : MethodType.PUT,
      false,
      passwordEncrpt,
      "",
      this.manageInterviewBoardResponse
    );
  };
  manageInterviewBoardResponse = (data) => {
    this.setState({
      buttonAction: false,
    });

    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.state.InterviewBoard._id
          ? "Interview Board Updated Successfully"
          : "Interview Board Created Successfully"
      );
      this.toggleManageModal();

      this.GetAllInterviewBoard();
    } else if (data.statusCode === StatusCodes.Duplicate) {
      toastr.error("", "MobileNumber already exists");
    }
  };
  getUniqueUserName = (unique) => {
    console.log(unique.target.value);

    var UniqueUser = {};
    UniqueUser.User = "IB";
    UniqueUser.Username = unique.target.value;

    CallService(
      user.UserNameValidate,
      MethodType.POST,
      false,
      UniqueUser,
      "",
      this.UniqueUserNameResponse
    );
  };

  UniqueUserNameResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        this.setState({
          UniqueUserNameValidate: data.result.IsUsernameAvailable,
        });
      }
    }
  };

  render() {
    const initialValues = this.state.InterviewBoard;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4" style={{ overflowY: "hidden" }}>
              <CardBody>
                <ListPageHeader
                  heading={`${this.props.t("Home")}.${this.props.t(
                    "Interview Board"
                  )}`}
                  onTextChange={this.SearchQueryChanged}
                  buttonClick={this.addBtnClick}
                  searchValue={this.state.ListRequestModel.searchString}
                  buttonText={this.props.t("Add New")}
                />

                <ListPage
                  columns={this.state.columns}
                  data={this.state.InterviewBoards}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                {this.props.t("Delete Interview Board")}
              </ModalHeader>

              <AvForm
                onSubmit={() =>
                  this.deleteInterviewBoard(this.state.InterviewBoard._id)
                }
              >
                <ModalBody>
                  <Fragment>
                    <Row className="mb-4">
                      <Label className="av-label ml-3">
                        <h5>
                          {this.props.t(
                            "Are you sure you want to delete this InterviewBoard ?"
                          )}
                        </h5>
                      </Label>
                    </Row>
                  </Fragment>
                </ModalBody>

                <ModalFooter>
                  <FormGroup className="float-sm-right ">
                    <Button type="submit" outline color="primary">
                      {this.props.t("Yes")}
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.toggleDeleteModal()}
                    >
                      {this.props.t("No")}
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </AvForm>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.InterviewBoard && this.state.InterviewBoard._id
                  ? this.props.t("Update Interview Board")
                  : this.props.t("Create Interview Board")}
              </ModalHeader>
              <Formik
                initialValues={initialValues}
                validationSchema={InterviewBoardValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, touched, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row>
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            <Label className="requiredField">
                              {" "}
                              {this.props.t("Username")}
                            </Label>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              {this.state.InterviewBoard &&
                              this.state.InterviewBoard._id ? (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  value={this.state.InterviewBoard.Username}
                                  className={
                                    this.state.InterviewBoard &&
                                    this.state.InterviewBoard._id
                                      ? "cls-disabled form-control"
                                      : "form-control"
                                  }
                                  name="Username"
                                  disabled={
                                    this.state.InterviewBoard &&
                                    this.state.InterviewBoard._id
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    values.Username = e.target.value;
                                    this.getUniqueUserName(e);
                                  }}
                                />
                              ) : (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  autoFocus
                                  //   value={this.state.InterviewBoard.Username}
                                  className={
                                    this.state.InterviewBoard &&
                                    this.state.InterviewBoard._id
                                      ? "cls-disabled form-control"
                                      : "form-control"
                                  }
                                  name="Username"
                                  // disabled={
                                  //   this.state.InterviewBoard &&
                                  //   this.state.InterviewBoard._id
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(e) => {
                                    values.Username = e.target.value;
                                    this.getUniqueUserName(e);
                                  }}
                                />
                              )}
                              {errors.Username ? (
                                <div className="invalid-feedback d-block">
                                  {errors.Username}
                                </div>
                              ) : this.state.UniqueUserNameValidate ===
                                false ? (
                                <div className="invalid-feedback d-block">
                                  Username already exists. Try another.
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            <Label className="requiredField">
                              {" "}
                              {this.props.t("Name")}
                            </Label>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="text"
                                className="form-control"
                                name="Name"
                                onKeyPress={(event) => {
                                  if (INVALID_CHARS.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={(event) => {
                                  event.target.value =
                                    event.target.value &&
                                    event.target.value.replace(
                                      INVALID_CHARS_REGEX,
                                      ""
                                    );
                                  setFieldValue("Name", event.target.value);
                                }}
                              />
                              {errors.Name && (
                                <div className="invalid-feedback d-block">
                                  {errors.Name}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>

                        <Row className="mb-10">
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            <Label className="requiredField">
                              {this.props.t("Mobile No")}
                            </Label>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="number"
                                className="form-control"
                                name="MobileNumber"
                                onKeyPress={(event) => {
                                  if (INVALID_CHARS.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {errors.MobileNumber && (
                                <div className="invalid-feedback d-block">
                                  {errors.MobileNumber}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            <Label className="requiredField">
                              {" "}
                              {this.props.t("Email ID")}
                            </Label>
                          </Colxx>

                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="email"
                                className="form-control"
                                name="EmailID"
                              />
                              {errors.EmailID && (
                                <div className="invalid-feedback d-block">
                                  {errors.EmailID}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            {" "}
                            <Label className="requiredField">
                              {" "}
                              {this.props.t("Password")}
                            </Label>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Field
                                type="password"
                                className="form-control"
                                name="Password"
                              />
                              {errors.Password && (
                                <div className="invalid-feedback d-block">
                                  {errors.Password}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="2"></Colxx>
                          <Colxx xxs="2">
                            {" "}
                            <Label className="requiredField">
                              {" "}
                              {this.props.t("Districts")}
                            </Label>
                          </Colxx>
                          <Colxx xxs="6">
                            <FormGroup
                              style={{ marginTop: "-20px" }}
                              className="form-group has-float-label"
                            >
                              <DropDown
                                components={{ Input: CustomSelectInput }}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="District"
                                isMulti
                                isSearchable
                                options={this.state.DistrictList}
                                value={
                                  values &&
                                  values.Districts &&
                                  this.state.DistrictList.filter((a) => {
                                    return values.Districts.some((r) => {
                                      return (
                                        a.value ===
                                        (typeof r === "object"
                                          ? r.value
                                            ? r.value
                                            : r.value
                                          : r)
                                      );
                                    });
                                  }).map(function (o) {
                                    return o;
                                  })
                                }
                                ClearAction={() => {
                                  setFieldValue("Districts", null);
                                }}
                                Action={(entity) => {
                                  values.Districts = entity;
                                  setFieldValue("Districts", entity);
                                }}
                                errors={errors.Districts}
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="2"></Colxx>
                        </Row>
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        {this.state.InterviewBoard._id ? (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            {this.props.t("Update")}
                          </Button>
                        ) : (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            {this.props.t("Create")}
                          </Button>
                        )}

                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleManageModal()}
                        >
                          {this.props.t("Cancel")}
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(InterviewBoard));
