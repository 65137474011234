import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/Common/CustomBootstrap";
import moment from "moment";
import { AvForm } from "availity-reactstrap-validation";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { advocate } from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ListPageHeader from "../../components/custom/ListPageHeader";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { checkIfPdf } from "../../helpers/Utils";
import FileDisplay from "../../components/custom/FileDisplay";

class Notary extends Component {
  constructor(props) {
    super(props);
    this.toggleViewModal = this.toggleViewModal.bind(this);
    this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      Advocates: [],
      tabs: [],
      activeFirstTab: 1,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      // Advocate: {
      //   UserName: "",
      //   Password: "",
      //   District: "",
      // },
      columns: [
        {
          name: <strong>{this.props.t("Name")}</strong>,
          selector: "Name",
          sortable: false,
          cell: (row) => <span>{row.Name ? row.Name : ""}</span>,
        },
        {
          name: <strong>{this.props.t("Mobile Number")}</strong>,
          selector: "MobileNumber",
          sortable: false,
          cell: (row) => (
            <span>{row.MobileNumber ? row.MobileNumber : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Email ID")}</strong>,
          selector: "EmailID",
          sortable: false,
          cell: (row) => <span>{row.EmailID ? row.EmailID : ""}</span>,
        },

        {
          name: <strong>{this.props.t("Bar Council Number")}</strong>,
          selector: "BarCouncilNumber",
          sortable: false,
          cell: (row) => (
            <span>{row.BarCouncilNumber ? row.BarCouncilNumber : ""}</span>
          ),
        },
        {
          name: <strong>{this.props.t("Action")}</strong>,
          selector: "action",
          sortable: false,
          cell: (row) => (
            <Row>
              <Button
                size="sm"
                color="primary"
                outline
                onClick={() => this.toggleViewModal(row)}
              >
                View
              </Button>
            </Row>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.GetAllAdvocate();
    this.setTab(1);
  }
  toggleCustomJustified = (tab) => {
    if (this.state.activeFirstTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };
  setTab = (no) => {
    this.setState({
      tabs: [
        {
          id: 1,
          Name: this.props.t("Personal"),
        },
        {
          id: 2,
          Name: this.props.t("Professional"),
        },
        {
          id: 3,
          Name: this.props.t("Qualification"),
        },
        {
          id: 4,
          Name: this.props.t("Residence Address"),
        },
        {
          id: 5,
          Name: this.props.t("Professional Address"),
        },
        {
          id: 6,
          Name: this.props.t("Remarks"),
        },
      ],
    });
  };
  GetAllAdvocate() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      advocate.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllAdvocateResponse
    );
  }

  GetAllAdvocateResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        Advocates: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };

  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAllAdvocate();
      }
    );
  };

  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllAdvocate();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllAdvocate();
        }
      }
    );
  };

  toggleViewModal = (row) => {
    this.setState({
      viewModal: !this.state.viewModal,
      viewValues: row,
      activeFirstTab: 1,
    });
  };
  addBtnClick = () => {
    this.toggleManageModal();
  };
  togglePhotoViewModal(img, title) {
    if (img) {
      this.setState({
        DocumentView: img,
        DocumentViewModal: !this.state.DocumentViewModal,
        ImageGallery: img,
        Title: title,
      });
    } else {
      this.setState({
        DocumentViewModal: !this.state.DocumentViewModal,
      });
    }
  }
  properties = {
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: false,

    onChange: (oldIndex, newIndex) => {
      this.setState({
        currentImage: this.state.ImageGallery,
      });
    },
  };

  // deleteInterviewBoard = (id) => {
  //   CallService(
  //     advocate.Delete + id,
  //     MethodType.DELETE,
  //     false,
  //     "",
  //     "",
  //     this.deleteInterviewBoardResponse
  //   );
  // };

  // deleteInterviewBoardResponse = (data) => {
  //   this.setState({
  //     buttonAction: false,
  //   });
  //   this.toggleViewModal();
  //   if (data.statusCode === StatusCodes.Success) {
  //     toastr.success("", "Advocate Deleted Successfully");
  //     this.GetAllAdvocate();
  //   }
  // };

  render() {
    const initialValues = this.state.Advocate;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid mt-4">
            <Card>
              <CardBody>
                <ListPageHeader
                  heading={"Applicant"}
                  // heading={`${this.props.t("Advocate")}.${this.props.t(
                  //   "Officer"
                  // )}`}
                  onTextChange={this.SearchQueryChanged}
                  buttonClick={this.addBtnClick}
                  searchValue={this.state.ListRequestModel.searchString}
                  showButton={false}
                />
                <ListPage
                  columns={this.state.columns}
                  data={this.state.Advocates}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>
            <Modal
              size="lg"
              isOpen={this.state.viewModal}
              toggle={this.toggleViewModal}
            >
              <ModalHeader toggle={this.toggleViewModal}>
                View Details
              </ModalHeader>

              <ModalBody>
                <Fragment>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {this.state.tabs.map((t) => {
                      return (
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={{
                              active: this.state.activeFirstTab === t.id,
                            }}
                            onClick={() => {
                              this.toggleCustomJustified(t.id);
                            }}
                          >
                            <span className="d-none d-sm-block">{t.Name}</span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <br></br>

                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId={1}>
                      <Row className="mb-4">
                        <Colxx xxs="12">
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Username")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.Username
                                    ? this.state.viewValues.Username
                                    : "-"
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Name")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.Name
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Name(in tamil)")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.TamilName
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Gender")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.Gender
                                    ? this.state.viewValues.Gender.label
                                    : "-"
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Father Name")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.FatherOrHusbandName
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Date Of Birth")}</label>
                            </Colxx>
                            {":  "}

                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues
                                    ? moment(this.state.viewValues.Dob).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Community")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.Community
                                    ? this.state.viewValues.Community.label
                                    : "-"
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Mobile Number")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.MobileNumber
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Email ID")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.EmailID
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Aadhar Number")}</label>
                            </Colxx>
                            {":  "}
                            <Colxx xxs="5">
                              {this.state.viewValues
                                ? this.state.viewValues
                                  ? this.state.viewValues.AadharNumber
                                  : "-"
                                : "-"}
                            </Colxx>
                          </Row>{" "}
                          <Row>
                            <Colxx xxs="5" className="printLabel">
                              <label>{this.props.t("Photo")}</label>
                            </Colxx>
                            <div>{":  "}</div>
                            {this.state.viewValues && this.state.viewValues ? (
                              <Fragment>
                                <Colxx xxs="4">
                                  {checkIfPdf(this.state.viewValues.Photo) ? (
                                    <FileDisplay
                                      value={this.state.viewValues.Photo}
                                    />
                                  ) : (
                                    <img
                                      alt="noimg"
                                      id="userimg"
                                      style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "contain",
                                      }}
                                      src={
                                        this.state.viewValues
                                          ? this.state.viewValues.Photo
                                          : ""
                                      }
                                      onClick={() =>
                                        this.togglePhotoViewModal(
                                          this.state.viewValues.Photo,
                                          "Photo"
                                        )
                                      }
                                    />
                                  )}
                                </Colxx>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Colxx>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId={2}>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Bar Council Number")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.BarCouncilNumber
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Enrolment Date")}</label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues
                                ? moment(
                                    this.state.viewValues.EnrolmentDate
                                  ).format("DD-MM-YYYY")
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Practicing Since")}</label>
                        </Colxx>
                        {":  "}

                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues
                                ? moment(
                                    this.state.viewValues.PracticingSince
                                  ).format("DD-MM-YYYY")
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Practicing In")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.PracticingIn
                                ? this.state.viewValues.PracticingIn.label
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Landline Number")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.LandlineNumber
                                ? this.state.viewValues.LandlineNumber
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Fax")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.Fax
                                ? this.state.viewValues.Fax
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("PAN Number")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.PANNumber
                                ? this.state.viewValues.PANNumber
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Remarks")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.Remarks
                                ? this.state.viewValues.Remarks
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>
                            {this.props.t("Bar Council Certificate")}
                          </label>
                        </Colxx>
                        {":  "}

                        {this.state.viewValues && this.state.viewValues ? (
                          <Colxx xxs="4">
                            <img
                              alt="noimg"
                              id="userimg"
                              style={{
                                width: "100%",
                                height: "100px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              src={
                                this.state.viewValues
                                  ? this.state.viewValues.BarCouncilCertificate
                                  : ""
                              }
                              onClick={() =>
                                this.togglePhotoViewModal(
                                  this.state.viewValues &&
                                    this.state.viewValues.BarCouncilCertificate,
                                  "Bar Council Certificate"
                                )
                              }
                            />
                          </Colxx>
                        ) : (
                          ""
                        )}
                      </Row>{" "}
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId={3}>
                      {this.state.viewValues &&
                      this.state.viewValues.Degrees &&
                      this.state.viewValues.Degrees.length > 0
                        ? this.state.viewValues.Degrees.map((val, i) => {
                            return (
                              <Fragment>
                                <Row>
                                  <Colxx xxs="5" className="printLabel">
                                    <label>{this.props.t("Institution")}</label>
                                  </Colxx>
                                  {":  "}
                                  <Colxx xxs="5">
                                    {this.state.viewValues
                                      ? this.state.viewValues
                                        ? this.state.viewValues.Degrees[i]
                                          ? this.state.viewValues.Degrees[i]
                                              .Institution
                                          : "-"
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                                <Row>
                                  <Colxx xxs="5" className="printLabel">
                                    <label>{this.props.t("Degree")}</label>
                                  </Colxx>
                                  {":  "}
                                  <Colxx xxs="5">
                                    {this.state.viewValues
                                      ? this.state.viewValues
                                        ? this.state.viewValues.Degrees[i]
                                          ? this.state.viewValues.Degrees[i]
                                              .Name
                                          : "-"
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                                <Row>
                                  <Colxx xxs="5" className="printLabel">
                                    <label>
                                      {this.props.t("Year Of Passing")}
                                    </label>
                                  </Colxx>
                                  {":  "}
                                  <Colxx xxs="5">
                                    {this.state.viewValues
                                      ? this.state.viewValues
                                        ? this.state.viewValues.Degrees[i]
                                          ? this.state.viewValues.Degrees[i]
                                              .YearOfPassing
                                          : "-"
                                        : "-"
                                      : "-"}
                                  </Colxx>
                                </Row>
                                <Row>
                                  <Colxx xxs="5" className="printLabel">
                                    <label>
                                      {this.props.t("Copy of Degree")}
                                    </label>
                                  </Colxx>
                                  {":  "}

                                  {this.state.viewValues &&
                                  this.state.viewValues.Degrees ? (
                                    <Colxx xxs="4">
                                      <img
                                        alt="noimg"
                                        id="userimg"
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                        }}
                                        src={
                                          this.state.viewValues &&
                                          this.state.viewValues
                                            ? this.state.viewValues.Degrees[i]
                                                .Url
                                            : ""
                                        }
                                        onClick={() =>
                                          this.togglePhotoViewModal(
                                            this.state.viewValues &&
                                              this.state.viewValues &&
                                              this.state.viewValues.Degrees[i]
                                                .Url,
                                            "Copy of Degree"
                                          )
                                        }
                                      />
                                    </Colxx>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </Fragment>
                            );
                          })
                        : ""}
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId={4}>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 1")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .Line1
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 2")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .Line2
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 3")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .Line3
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("District")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .District
                                  ? this.state.viewValues.ResidencyAddress
                                      .Address.District.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("State")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .State
                                  ? this.state.viewValues.ResidencyAddress
                                      .Address.State.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Taluk")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .Taluk
                                  ? this.state.viewValues.ResidencyAddress
                                      .Address.Taluk.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Pincode")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress.Address
                                    .Pincode
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Proof Type")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress
                                    .ProofType
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Proof ID Number")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ResidencyAddress
                                ? this.state.viewValues.ResidencyAddress
                                    .IDProofNumber
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Copy of Proof")}</label>
                        </Colxx>
                        {":  "}
                        {this.state.viewValues && this.state.viewValues ? (
                          <Colxx xxs="4">
                            <img
                              alt="noimg"
                              id="userimg"
                              style={{
                                width: "100%",
                                height: "100px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              src={
                                this.state.viewValues
                                  ? this.state.viewValues &&
                                    this.state.viewValues.ResidencyAddress &&
                                    this.state.viewValues.ResidencyAddress
                                      .ProofUrl
                                  : ""
                              }
                              onClick={() =>
                                this.togglePhotoViewModal(
                                  this.state.viewValues &&
                                    this.state.viewValues &&
                                    this.state.viewValues.ResidencyAddress &&
                                    this.state.viewValues.ResidencyAddress
                                      .ProofUrl,
                                  "Copy of Proof"
                                )
                              }
                            />
                          </Colxx>
                        ) : (
                          ""
                        )}
                      </Row>
                    </TabPane>
                  </TabContent>

                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId={5}>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 1")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.Line1
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 2")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.Line2
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Address Line 3")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.Line3
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("District")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.District
                                  ? this.state.viewValues.ProvisionalAddress
                                      .Address.District.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("State")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.State
                                  ? this.state.viewValues.ProvisionalAddress
                                      .Address.State.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Taluk")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.Taluk
                                  ? this.state.viewValues.ProvisionalAddress
                                      .Address.Taluk.Name
                                  : "-"
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Pincode")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .Address.Pincode
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Proof Type")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .ProofType
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>{" "}
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Proof ID Number")}</label>
                        </Colxx>
                        {":  "}
                        <Colxx xxs="5">
                          {this.state.viewValues
                            ? this.state.viewValues
                              ? this.state.viewValues.ProvisionalAddress
                                ? this.state.viewValues.ProvisionalAddress
                                    .IDProofNumber
                                : "-"
                              : "-"
                            : "-"}
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xxs="5" className="printLabel">
                          <label>{this.props.t("Copy of Proof")}</label>
                        </Colxx>
                        {":  "}

                        {this.state.viewValues && this.state.viewValues ? (
                          <Colxx xxs="4">
                            <img
                              alt="noimg"
                              id="userimg"
                              style={{
                                width: "100%",
                                height: "100px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              src={
                                this.state.viewValues
                                  ? this.state.viewValues &&
                                    this.state.viewValues.ProvisionalAddress &&
                                    this.state.viewValues.ProvisionalAddress
                                      .ProofUrl
                                  : ""
                              }
                              onClick={() =>
                                this.togglePhotoViewModal(
                                  this.state.viewValues &&
                                    this.state.viewValues &&
                                    this.state.viewValues.ProvisionalAddress &&
                                    this.state.viewValues.ProvisionalAddress
                                      .ProofUrl,
                                  "Copy of Proof"
                                )
                              }
                            />
                          </Colxx>
                        ) : (
                          ""
                        )}
                      </Row>
                    </TabPane>
                  </TabContent>
                </Fragment>
              </ModalBody>

              <ModalFooter>
                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleViewModal()}
                  >
                    Close
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>{" "}
            <Modal
              className="viewDocumentModal"
              isOpen={this.state.DocumentViewModal}
              size="lg"
              toggle={this.togglePhotoViewModal}
            >
              <ModalHeader toggle={this.togglePhotoViewModal}>
                <b>{this.state.Title}</b>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12">
                    <Fragment>
                      <div className="each-slide">
                        <img
                          alt="noimg"
                          id="userimg"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          src={this.state.ImageGallery}
                        />
                      </div>
                    </Fragment>
                  </Colxx>
                </Row>
              </ModalBody>
              <ModalFooter>
                <FormGroup>
                  <a href={this.state.ImageGallery}>
                    <Button
                      type="submit"
                      className="default   btn-sm mb-2 btn-addon"
                      color="primary"
                    >
                      Download
                    </Button>{" "}
                  </a>

                  <Button
                    className="default   btn-sm mb-2 btn-addon"
                    color="danger"
                    onClick={() => this.togglePhotoViewModal()}
                  >
                    Close
                  </Button>
                </FormGroup>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Notary));
