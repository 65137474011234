import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Colxx } from "../../components/Common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Card,
  CardBody,
  CustomInput,
} from "reactstrap";
import { CallService } from "../../helpers/ServiceCall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { cms, documentMaster } from "../../constants/config";
import ListPage from "../../components/custom/ListPage";
//import { CMSValidation } from "../../helpers/validations";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ListPageHeader from "../../components/custom/ListPageHeader";
import JoditEditor from "jodit-react";
import FileDisplay from "../../components/custom/FileDisplay";

class CMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAction: false,
      isLoading: true,
      pageSizeOptions: [10, 50, 100],
      pageLimit: 10,
      totalCount: 0,
      keyField: "_id",
      CMSs: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },

      CMS: {
        Name: "",
        Description: "",
        FriendlyUrl: "",
        Images: [
          {
            Url: "",
          },
        ],
      },
      columns: [
        {
          name: "Title",
          selector: "Title",
          sortable: false,
          cell: (row) => <span>{row.Title ? row.Title : ""}</span>,
        },
        // {
        //   name: "View",
        //   selector: "view",
        //   sortable: false,
        //   cell: (row) => (
        //     <Row>

        //       <Link
        //         to={{
        //           pathname: "/LoginHome",
        //           data: row,
        //         }}
        //       >

        //         <Button
        //           size="sm"
        //           color="primary"
        //           onClick={() => this.toggleView(row)}
        //         >
        //           View
        //         </Button>
        //       </Link>
        //     </Row>
        //   ),
        // },
        // {
        //   name: "Action",
        //   selector: "action",
        //   sortable: false,
        //   cell: (row) => (
        //     <Row>
        //       <Button
        //         size="sm"
        //         color="danger"
        //         onClick={() => this.toggleDeleteModal(row)}
        //       >
        //         Delete
        //       </Button>
        //     </Row>
        //   ),
        // },
      ],
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  toggleView = (row) => {
    console.log(row);
  };
  componentDidMount() {
    if (sessionStorage.getItem("user_token")) {
      this.GetAllCMS();
    } else {
      window.location.replace("");
    }
  }
  GetAllCMS() {
    this.setState({
      isLoading: true,
    });
    const { ListRequestModel } = this.state;
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetAllCMSResponse
    );
  }

  GetAllCMSResponse = (data) => {
    if (data.pagination && data.result) {
      this.setState({
        isLoading: false,
        totalPage: data.pagination.totalCount / data.pagination.pageLimit,
        CMSs: data.result,
        totalCount: data.pagination.totalCount,
      });
    } else
      this.setState({
        isLoading: false,
      });
  };
  handleSubmit = (values, errors) => {
    this.setState({
      details: values,
    });
    var hasfile = false;
    let formData = new FormData();
    for (var i = 0; i < values.Images.length; i++) {
      if (values.Images[i])
        if (JSON.stringify(values.Images[i].Url) === "{}") {
          hasfile = true;
          formData.append("images" + i, values.Images[i].Url);
        } else {
          values.Images = [];
        }
    }
    if (hasfile) {
      CallService(
        documentMaster.FileUpload,
        MethodType.POST,
        false,
        formData,
        "",
        this.DocumentUploaded,
        true
      );
    } else {
      this.manageCMS(values);
    }
  };
  DocumentUploaded = (data) => {
    if (data.result) {
      // var obj = Object.entries(data.result);
      // var list = obj.map((o) =>
      //   o.filter((obj, index) => {
      //     if (index === 1) {
      //       return obj;
      //     }
      //   })
      // );
      var details = this.state.details;
      //  details.Images = [];
      // for (var i = 0; i < this.state.details && this.state.details.Images.length; i++) {
      //   details.Images.push(list[i].toString());
      // }

      for (var i = 0; i < this.state.details.Images.length; i++) {
        details.Images[i] = data.result["images" + i];
      }
      this.manageCMS(details);
    } else {
      this.setState({
        loading: false,
      });
      toastr.error("", "Document Upload Failed");
    }
  };
  handleSort = (column, sortDirection) => {
    var sortObj = {};
    sortObj[column.selector] = sortDirection === "asc" ? 1 : -1;
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          sortCondition: sortObj,
        },
      },
      function () {
        this.GetAllCMS();
      }
    );
  };
  handlePerRowsChange = async (perPage) => {
    this.setState(
      {
        pageLimit: perPage,
        pageNumber: 1,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageLimit: perPage,
          pageNumber: 1,
        },
      },
      async function () {
        this.GetAllCMS();
      }
    );
  };

  HandleRowClicked = (row) => {
    console.log(row);
    var prefillValues = row;
    prefillValues.Title = row.Title;
    prefillValues.FriendlyUrl = row.FriendlyUrl;
    //prefillValues.Images = row.Images.length < 0 ? row.Images : [{}];
    prefillValues.Images = row.Images;
    prefillValues.Content = row.Content;

    if (row) {
      this.setState(
        {
          CMS: prefillValues,
          // CMS: {
          //   Title: row.Title,
          //   FriendlyUrl: row.FriendlyUrl,
          //   Images: [{}],
          // },
          text: row.Content,
          manageModal: !this.state.manageModal,
        },
        () => {
          console.log(this.state.CMS);
        }
      );
    }
  };
  handlePageChange = async (page) => {
    this.setState(
      {
        pageNumber: page,
        ListRequestModel: {
          ...this.state.ListRequestModel,
          pageNumber: page,
        },
      },
      function () {
        this.GetAllCMS();
      }
    );
  };
  SearchQueryChanged = (search) => {
    this.setState(
      {
        ListRequestModel: {
          ...this.state.ListRequestModel,
          searchString: search.target.value,
        },
      },
      function () {
        if (
          this.state.ListRequestModel.searchString === "" ||
          this.state.ListRequestModel.searchString.length > 2
        ) {
          this.GetAllCMS();
        }
      }
    );
  };

  toggleDeleteModal = (row) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      CMS: row,
    });
  };
  addBtnClick = () => {
    this.toggleManageModal();
  };
  toggleManageModal = () => {
    this.setState({
      CMS: {
        Title: "",
        Description: "",
        FriendlyUrl: "",
        Images: [
          {
            Url: "",
          },
        ],
      },
      text: "",
      defaultTemplateResult: "",
      manageModal: !this.state.manageModal,
    });
  };

  deleteCMS = (id) => {
    CallService(
      cms.Delete + id,
      MethodType.DELETE,
      false,
      "",
      "",
      this.deleteCMSResponse
    );
  };

  deleteCMSResponse = (data) => {
    this.setState({
      buttonAction: false,
    });
    this.toggleDeleteModal();
    if (data.statusCode === StatusCodes.Success) {
      toastr.success("", "CMS Deleted Successfully");
      this.GetAllCMS();
    }
  };

  manageCMS = (values) => {
    CallService(
      this.state.CMS._id ? cms.Update + this.state.CMS._id : cms.Create,
      this.state.CMS._id ? MethodType.PATCH : MethodType.PUT,
      false,
      values,
      "",
      this.manageCMSResponse
    );
  };

  manageCMSResponse = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      toastr.success(
        "",
        this.state.CMS && this.state.CMS._id
          ? "CMS Updated Successfully"
          : "CMS Created Successfully"
      );
      this.GetAllCMS();
    }
    this.toggleManageModal();

    this.setState({
      buttonAction: false,
    });
  };
  showPreview = () => {
    var TemplateResult = this.state.text;
    TemplateResult = TemplateResult.replace(/&lt;/g, "<");
    TemplateResult = TemplateResult.replace(/&gt;/g, ">");
    this.setState({
      defaultTemplateResult: TemplateResult,
    });
    this.setState({
      IsPreview: !this.state.IsPreview,
    });
  };
  render() {
    const initialValues = this.state.CMS;

    return (
      <Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Card className="mt-4">
              <CardBody>
                <ListPageHeader
                  heading="Home.CMS"
                  onTextChange={this.SearchQueryChanged}
                  buttonClick={this.addBtnClick}
                  buttonText={this.props.t("Add New")}
                  searchValue={this.state.ListRequestModel.searchString}
                />

                <ListPage
                  columns={this.state.columns}
                  data={this.state.CMSs}
                  keyField={this.state.keyField}
                  totalCount={this.state.totalCount}
                  rowClicked={this.HandleRowClicked}
                  rowsPerPageOnChange={this.handlePerRowsChange}
                  pageChange={this.handlePageChange}
                  isDataLoading={this.state.isLoading}
                  overFlowXRemoval={true}
                />
              </CardBody>
            </Card>

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
            >
              <ModalHeader toggle={this.toggleDeleteModal}>
                Delete CMS
              </ModalHeader>

              <AvForm onSubmit={() => this.deleteCMS(this.state.CMS._id)}>
                <ModalBody>
                  <Fragment>
                    <Row className="mb-4">
                      <Label className="av-label ml-3">
                        <h5>Are you sure you want to delete this CMS?</h5>
                      </Label>
                    </Row>
                  </Fragment>
                </ModalBody>

                <ModalFooter>
                  <FormGroup className="float-sm-right ">
                    <Button type="submit" outline color="primary">
                      Yes
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.toggleDeleteModal()}
                    >
                      No
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </AvForm>
            </Modal>
            <Modal
              isOpen={this.state.manageModal}
              toggle={this.toggleManageModal}
              size={"lg"}
            >
              <ModalHeader toggle={this.toggleManageModal}>
                {this.state.CMS && this.state.CMS._id
                  ? "Update CMS"
                  : "Create CMS"}
              </ModalHeader>
              <Formik
                initialValues={initialValues}
                //  validationSchema={CMSValidation}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, setFieldValue, values }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <ModalBody>
                      <Fragment>
                        <Row className="mb-10">
                          <Colxx xxs="12">
                            <FormGroup className="form-group has-float-label">
                              <Label>Title</Label>
                              <Field className="form-control" name="Title" />
                              {errors.Title && (
                                <div className="invalid-feedback d-block">
                                  {errors.Title}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                        </Row>
                        {this.state.text && (
                          <Row className="mb-10">
                            <Colxx xxs="12">
                              {" "}
                              <Button
                                type="button"
                                outline
                                color="primary"
                                onClick={this.showPreview}
                              >
                                {this.state.IsPreview ? "Edit" : "Preview"}
                              </Button>
                            </Colxx>
                          </Row>
                        )}
                        <Row className="mb-10">
                          <Colxx xxs="12">
                            <FormGroup className="form-group has-float-label">
                              <Label>Content</Label>
                              {this.state.IsPreview ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.defaultTemplateResult,
                                  }}
                                ></div>
                              ) : (
                                <JoditEditor
                                  ref={this.editorRef}
                                  name="Content"
                                  value={this.state.text}
                                  onChange={(newContent) => {
                                    this.setState({ text: newContent });
                                    setFieldValue("Content", newContent);
                                  }}
                                />
                              )}
                              {errors.Content && (
                                <div className="invalid-feedback d-block">
                                  {errors.Content}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>
                        </Row>
                        <Row className="mb-10">
                          <Colxx xxs="6">
                            <FormGroup className="form-group has-float-label">
                              <Label>Friendly Url</Label>
                              <Field
                                className="form-control"
                                name="FriendlyUrl"
                              />
                              {errors.FriendlyUrl && (
                                <div className="invalid-feedback d-block">
                                  {errors.FriendlyUrl}
                                </div>
                              )}
                            </FormGroup>
                          </Colxx>

                          {this.state.CMS.Images
                            ? this.state.CMS.Images.length > 0
                              ? this.state.CMS.Images.map((document, Index) => {
                                  return (
                                    <Fragment>
                                      <Colxx xxs="5">
                                        <FormGroup>
                                          <Label> Document</Label>
                                          <div
                                            className="custom-file"
                                            style={{ zIndex: "0" }}
                                          >
                                            <CustomInput
                                              type="file"
                                              name="Images"
                                              onChange={(event) => {
                                                var value =
                                                  event.target.files[0];
                                                if (value) {
                                                  if (
                                                    !(value.size / 1024 <= 500)
                                                  ) {
                                                    toastr.warning(
                                                      "",
                                                      "File too large"
                                                    );
                                                    value = "";
                                                  } else if (
                                                    ![
                                                      "doc",
                                                      "docx",
                                                      "pdf",
                                                      "png",
                                                      "jpg",
                                                      "jpeg",
                                                    ].includes(
                                                      value.name
                                                        .toLowerCase()
                                                        .split(".")[
                                                        value.name
                                                          .toLowerCase()
                                                          .split(".").length - 1
                                                      ]
                                                    )
                                                  ) {
                                                    toastr.warning(
                                                      "",
                                                      "File format not allowed"
                                                    );
                                                    value = "";
                                                  }
                                                }
                                                setFieldValue(
                                                  `Images[${Index}].Url`,
                                                  value
                                                );
                                              }}
                                            />
                                            {this.state.CMS &&
                                            this.state.CMS.Images ? (
                                              <FileDisplay
                                                Value={
                                                  this.state.CMS.Images[Index]
                                                }
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </FormGroup>
                                      </Colxx>
                                      <div
                                        style={{
                                          marginTop: "28px",
                                        }}
                                      >
                                        {this.state.CMS.Images.length > 1 && (
                                          <Button
                                            color="danger"
                                            // className="simple-icon-minus cursorPointer"
                                            onClick={() => {
                                              var selectedProduct = this.state
                                                .CMS.Images;

                                              selectedProduct.splice(Index, 1);
                                              this.setState({
                                                selectedProduct: selectedProduct,
                                              });
                                            }}
                                          >
                                            -
                                          </Button>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        {this.state.CMS.Images.length ===
                                          Index + 1 && (
                                          <Button
                                            color="primary"
                                            //   className="simple-icon-plus cursorPointer"
                                            onClick={() => {
                                              var selectedProduct = this.state
                                                .CMS.Images;

                                              selectedProduct.push({
                                                Url: "",
                                              });
                                              this.setState({
                                                selectedProduct: selectedProduct,
                                              });
                                            }}
                                          >
                                            {" "}
                                            +{" "}
                                          </Button>
                                        )}
                                      </div>
                                    </Fragment>
                                  );
                                })
                              : ""
                            : ""}
                        </Row>
                      </Fragment>
                    </ModalBody>

                    <ModalFooter>
                      <FormGroup className="float-sm-right ">
                        {this.state.CMS._id ? (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            {this.props.t("Update")}
                          </Button>
                        ) : (
                          <Button
                            className={
                              this.state.buttonAction ? "disabled" : ""
                            }
                            type="submit"
                            outline
                            color="primary"
                          >
                            {this.props.t("Create")}
                          </Button>
                        )}

                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => this.toggleManageModal()}
                        >
                          {this.props.t("Cancel")}
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withNamespaces()(CMS));
